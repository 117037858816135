import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Card,
  DropZone,
  FormLayout,
  SkeletonDisplayText,
  Spinner,
  Tag,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";
import { validImageTypes } from "../../utils/constants";
import ApiServiceConfig from "../../services/ApiServiceConfig";
import "./SectionsEditors.css";
import useGetCollections from "../../hooks/useGetCollections";
import useDebounce from "../../services/useDebounce";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import _ from "lodash";

export default function CollectionsListItem({
  item = {},
  subItem = {},
  handleMainChange = () => {},
  handleDeleteSubSection = () => {},
}) {
  const inputValueRef = useRef(null);
  const deselectedOptions = useRef([]);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [isAddingFiles, setIsAddingFiles] = useState(false);
  const [collectionItem, setCollectionItem] = useState({
    handle: "",
    name: "",
    src: "",
  });
  const { collections, loading, fetchCollections } = useGetCollections({
    query: "",
  });
  const debounceInputValue = useDebounce(inputValue, 900);

  useEffect(() => {
    inputValueRef.current = inputValue;
  });

  useDidMountEffect(() => {
    fetchCollections(inputValueRef.current);
  }, [debounceInputValue]);

  useEffect(() => {
    const optionsToAdd = collections.map((collection) => ({
      label: collection.name,
      value: collection.handle,
    }));
    let merged = _.unionBy(
      [...optionsToAdd, ...deselectedOptions.current],
      "value"
    );
    const filterRegex = new RegExp(inputValueRef.current, "i");

    merged = merged.filter((item) => item.label.match(filterRegex));

    setOptions(merged);
    deselectedOptions.current = merged;
  }, [collections]);

  useEffect(() => {
    setCollectionItem((prevState) => ({ ...prevState, ...subItem }));
  }, [subItem]);

  const updateText = useCallback(
    (value) => {
      setInputValue(value);

      if (value === "") {
        setOptions(options);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = options.filter((option) =>
        option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [options]
  );

  const handleChange = useCallback(
    (field, value, obj = null) => {
      let objToUpdate = {};
      if (obj) {
        objToUpdate = { ...obj };
      } else {
        objToUpdate = { [field]: value };
      }

      const subNavigationItems = item.subNavigationItems.map((subNavItem) =>
        subNavItem.id === subItem.id
          ? { ...collectionItem, ...objToUpdate }
          : subNavItem
      );
      handleMainChange("subNavigationItems", subNavigationItems, item.id);
      setCollectionItem((prevState) => ({ ...prevState, ...objToUpdate }));
    },
    [
      collectionItem,
      handleMainChange,
      item.id,
      item.subNavigationItems,
      subItem.id,
    ]
  );

  const updateSelection = useCallback(
    (selected) => {
      const selectedOption = deselectedOptions.current.find(
        (opt) => opt.value === selected[0]
      );
      const obj = {
        handle: selectedOption.value,
        name: selectedOption.label,
      };
      handleChange("handle", selected[0], obj);
      setInputValue("");
    },
    [handleChange]
  );

  const handleDropZone = (
    _dropFiles = [],
    acceptedFiles = [],
    _rejectedFiles = []
  ) => {
    const formData = new FormData();
    if (acceptedFiles.length > 0) {
      setIsAddingFiles(true);
      acceptedFiles.forEach((file) => {
        formData.append("files", file);
      });

      ApiServiceConfig.uploadFile(formData)
        .then(({ files = [] }) => {
          const src = files[0].url;
          handleChange("src", src);
        })
        .finally(() => setIsAddingFiles(false));
    }
  };

  const onRemoveTag = () => {
    handleChange("handle", "");
  };

  const { handle, src } = collectionItem;

  const tagsMarkup = handle ? (
    <div className="flex mr-4 mb-4" key={`option${handle}`}>
      <Tag onRemove={onRemoveTag}>
        {
          deselectedOptions.current.find(
            (desOption) => desOption.value === handle
          )?.label
        }
      </Tag>
    </div>
  ) : null;

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      value={inputValue}
      placeholder="Selecciona una colección"
      autoComplete="off"
    />
  );

  return (
    <>
      <Card
        primaryFooterAction={{
          content: "Eliminar sección",
          destructive: true,
          outline: true,
          onAction: () => handleDeleteSubSection(item.id, subItem.id),
        }}
        footerActionAlignment="left"
      >
        <Card.Section>
          <FormLayout>
            <TextStyle variation="strong">{collectionItem.label}</TextStyle>
            <Autocomplete
              options={options}
              selected={handle ? [handle] : []}
              onSelect={updateSelection}
              textField={textField}
              loading={loading}
              willLoadMoreResults
              listTitle="Colecciones sugeridas"
            />
            {loading ? (
              <div className="mt-8">
                <SkeletonDisplayText size="small" />
              </div>
            ) : (
              <div className="flex flex-wrap my-8">{tagsMarkup}</div>
            )}
            <div className="flex justify-between items-end">
              <div
                className="BannerSliderItem w-full mb-10 "
                style={{ height: 70 }}
              >
                <DropZone
                  label={
                    <div>
                      <TextStyle>Imagen</TextStyle>
                    </div>
                  }
                  labelAction={{
                    content: isAddingFiles ? <Spinner size="small" /> : "",
                  }}
                  accept={validImageTypes.join(",")}
                  onDrop={handleDropZone}
                  disabled={isAddingFiles}
                >
                  <DropZone.FileUpload
                    actionTitle={src ? "Cambiar imagen" : "Seleccionar imagen"}
                  />
                </DropZone>
              </div>
              {src && (
                <div className="ml-4 mb-2">
                  <Thumbnail source={src} size="medium" />
                </div>
              )}
            </div>
          </FormLayout>
        </Card.Section>
      </Card>
    </>
  );
}
