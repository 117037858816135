import { Badge, Button } from "@shopify/polaris";
import { CartMajor } from "@shopify/polaris-icons";
import React from "react";

export default function ShoppingCardButton({
  badge = 0,
  onClick,
  icon = null,
}) {
  return (
    <div className="relative cursor-pointer" onClick={onClick}>
      <Button icon={icon ? icon : CartMajor} monochrome plain />
      <div className="absolute custom-badge-cart z-20">
        <Badge>{badge > 99 ? "99+" : badge}</Badge>
      </div>
    </div>
  );
}
