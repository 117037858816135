import React from "react";
import { Card, FormLayout, Modal, TextField } from "@shopify/polaris";
import TagsAutocomplete from "../TagsAutocomplete";

export default function EditFiltroModal({
  isOpenAddFilter = false,
  handleOnClose = () => {},
  isOpen = false,
  handleSetFilter = () => {},
  handleSaveById = () => {},
  title = "",
  setTitle = "",
  tags = [],
  setTags = () => {},
}) {
  return (
    <Modal
      title={isOpenAddFilter ? "Agregar Filtro" : "Editar Filtro"}
      onClose={handleOnClose}
      open={isOpen}
      primaryAction={{
        content: "Guardar",
        disabled: !title || tags.length === 0,
        onAction: isOpenAddFilter ? handleSetFilter : handleSaveById,
      }}
      secondaryActions={[
        {
          content: "Cancelar",
          onAction: handleOnClose,
        },
      ]}>
      <Modal.Section>
        <Card.Section>
          <FormLayout>
            <TextField
              label="Título"
              placeholder="Marcas"
              value={title}
              onChange={(title) => setTitle(title)}
            />
            <TagsAutocomplete
              title="Etiquetas"
              subdued
              selected={tags}
              onSelect={(opts) => setTags(opts)}
              module="Artículos"
              useCard={false}
              placeholder="Nike, Reebok, Adidas"
            />
          </FormLayout>
        </Card.Section>
      </Modal.Section>
    </Modal>
  );
}
