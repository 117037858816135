import React, { useEffect, useState } from "react";
import { Button, Select, TextField } from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";

import {
  FirstCollectionCondition as firstCondition,
  SecondCollectionCondition as secondCondition,
} from "../../utils/SelectsItems";

import { AutocompleteCondition } from "./AutocompleteCondition";

const toAutocomplete = ["marca", "linea_articulo", "etiquetas"];

const inputType = {
  precios: "number",
  existencia: "number",
  createdDate: "date",
};

export const ConditionOptions = ({
  conditions = [],
  setConditions,
  handleBloking,
}) => {
  const [inputsFocused, setInputsFocused] = useState([]);

  useEffect(() => {
    // If conditions array is empty, add first data
    if (conditions.length === 0) {
      setConditions([
        {
          fieldName: "nombre",
          type: "equalsTo",
          fieldValue: "",
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Disables some options depending on the first select
  const secondOptionCondition = (option) => {
    const disableds = {
      nombre: ["greaterThan", "lowerThan"],
      marca: ["greaterThan", "lowerThan"],
      linea_articulo: ["greaterThan", "lowerThan"],
      etiquetas: [
        "greaterThan",
        "lowerThan",
        "notContains",
        "notEquals",
        "startsWith",
        "endsWith",
        "contains",
      ],
      precios: ["contains", "notContains", "endsWith", "startsWith"],
      existencia: [
        "notEquals",
        "startsWith",
        "endsWith",
        "contains",
        "notContains",
      ],
      createdDate: [
        "equalsTo",
        "notEquals",
        "startsWith",
        "endsWith",
        "contains",
        "notContains",
      ],
    };

    const newCondition = secondCondition.map((item) => ({
      ...item,
      disabled: disableds[option].includes(item.value),
    }));

    return newCondition;
  };

  // There are two handlers because the first resets the values
  const handleFirstConditionChange = (conditionIndex, value) => {
    handleBloking();
    setConditions(
      conditions.map((item, index) => {
        const newData = {
          ...item,
          fieldName: value,
          fieldValue: "",
        };

        return index === conditionIndex ? newData : item;
      })
    );
  };

  const handleChange = (conditionIndex, name, data) => {
    handleBloking();
    setConditions(
      conditions.map((item, index) => {
        const newData = {
          ...item,
          [name]: data,
        };

        return index === conditionIndex ? newData : item;
      })
    );
  };

  const handleInputsFocused = (input) => {
    if (!inputsFocused.includes(input)) {
      setInputsFocused([...inputsFocused, input]);
    }
  };

  const deleteCondition = (index) => {
    setConditions(conditions.filter((_item, i) => i !== index));
    setInputsFocused(inputsFocused.filter((item) => item !== index));
    handleBloking();
  };

  return (
    <div>
      {conditions.map((item, index) => (
        <div className="flex gap-3 pb-3" key={index}>
          <div className="flex-1">
            <Select
              value={item?.fieldName}
              options={firstCondition}
              onChange={(value) => handleFirstConditionChange(index, value)}
            />
          </div>

          <div className="flex-1">
            <Select
              value={item?.type}
              options={secondOptionCondition(item?.fieldName)}
              onChange={(value) => handleChange(index, "type", value)}
            />
          </div>

          <div className="flex-1">
            {toAutocomplete.includes(item?.fieldName) ? (
              <AutocompleteCondition
                index={index}
                value={item?.fieldValue}
                handleChange={handleChange}
                typeCondition={item?.fieldName}
              />
            ) : (
              <TextField
                id={index}
                type={inputType[item?.fieldName] || "text"}
                value={item?.fieldValue}
                onChange={(value) => handleChange(index, "fieldValue", value)}
                onBlur={() => handleInputsFocused(index)}
                error={
                  inputsFocused.includes(index) &&
                  !item?.fieldValue &&
                  "Valor requerido"
                }
              />
            )}
          </div>

          {conditions.length > 1 && (
            <div>
              <Button
                icon={DeleteMajor}
                onClick={() => deleteCondition(index)}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
