export const TabsComerciosClientesScreen = [
  {
    id: "clientes_activos",
    content: "Activos",
    panelID: "Activo",
  },
  {
    id: "todos_clientes",
    content: "Todos",
    panelID: "Todos",
  },
  {
    id: "clientes_inactivos",
    content: "Inactivos",
    panelID: "Inactivo",
  },
  {
    id: "clientes_invitados",
    content: "Invitados",
    panelID: "Invitado",
  },
];

export const TabsEquipoCard = [
  {
    id: 1,
    content: "Activos",
    panelID: "p-admin-activos",
  },
  {
    id: 2,
    content: "Invitados",
    panelID: "p-admin-invitados",
  },
];

export const TabsPedidosScreen = [
  {
    id: "todos_pedidos",
    content: "Todos",
    panelID: "Todos",
  },
  {
    id: "no_enviados",
    content: "No Enviados",
    panelID: "No Enviado",
  },
  {
    id: "abiertos",
    content: "Abiertos",
    panelID: "Abierto",
  },
  {
    id: "cerrados",
    content: "Cerrados",
    panelID: "Cerrado",
  },
];

export const TabsAnunciosScren = [
  {
    id: "todos_pedidos",
    content: "Todos",
    panelID: "Todos",
  },
  {
    id: "visible",
    content: "Visible",
    panelID: "Visible",
  },
  {
    id: "no_visible",
    content: "No visible",
    panelID: "No visible",
  },
];

export const TabsArticulosScreen = [
  {
    id: "todos_pedidos",
    content: "Todos",
    panelID: "Todos",
  },
  {
    id: "existencia",
    content: "Con existencia",
    panelID: "existencia",
  },
];

export const TabsFacturasScreen = [
  {
    id: "todos_facturas",
    content: "Todos",
    panelID: "Todos",
  },
  {
    id: "pendiente",
    content: "Pendientes",
    panelID: "Pendiente",
  },
  {
    id: "pagados",
    content: "Pagados",
    panelID: "Pagado",
  },
  {
    id: "atrasados",
    content: "Atrasados",
    panelID: "Atrasado",
  },
  {
    id: "cancelado",
    content: "Cancelados",
    panelID: "Cancelado",
  },
  {
    id: "devuelto",
    content: "Devueltos",
    panelID: "Devuelto",
  },
];

export const TabsHistorialScreen = [
  {
    id: "all",
    content: "Todo",
    panelID: "Todo",
  },
  {
    id: "success",
    content: "Exitosas",
    panelID: "successful",
  },
  {
    id: "failed",
    content: "Fallidas",
    panelID: "failed",
  },
];

export const TabsPayments = [
  {
    id: "all",
    content: "Todos",
    panelID: "0",
  },
  {
    id: "succeeded",
    content: "Pagado",
    panelID: "succeeded",
  },
  {
    id: "pending",
    content: "Pendiente",
    panelID: "pending",
  },
  {
    id: "requires_payment_method",
    content: "Fallido",
    panelID: "requires_payment_method",
  },
];

export const TabsPedidosClienteScreen = [
  {
    id: "pendientes",
    content: "Pendientes",
    panelID: "Pendiente",
  },
  {
    id: "Todos",
    content: "Todos",
    panelID: "Todos",
  },
  {
    id: "Surtido",
    content: "Surtidos",
    panelID: "Surtido",
  },
  {
    id: "Cancelados",
    content: "Cancelados",
    panelID: "Cancelado",
  },
  {
    id: "Cerrados",
    content: "Cerrados",
    panelID: "Cerrado",
  },
];

export const TabsPedidosPreliminaresClienteScreen = [
  {
    id: "pendientes",
    content: "Pendientes",
    panelID: "Pendiente",
  },
  {
    id: "Todos",
    content: "Todos",
    panelID: "Todos",
  },
  {
    id: "Completados",
    content: "Completados",
    panelID: "Completado",
  },
  {
    id: "Cancelados",
    content: "Cancelados",
    panelID: "Cancelado",
  },
];

export const TabsPedidosPreliminaresScreen = [
  {
    id: "pendientes",
    content: "Pendientes",
    panelID: "Pendiente",
  },
  {
    id: "Todos",
    content: "Todos",
    panelID: "Todos",
  },
  {
    id: "Completados",
    content: "Completados",
    panelID: "Completado",
  },
  {
    id: "Cancelados",
    content: "Cancelados",
    panelID: "Cancelado",
  },
];

export const TabsDescuentosScreen = [
  {
    id: "todos_descuentos",
    content: "Todos",
    panelID: "",
  },
  {
    id: "por_cliente",
    content: "Por cliente",
    panelID: "Por cliente",
  },
  {
    id: "por_producto",
    content: "Por producto",
    panelID: "Por producto",
  },
  {
    id: "por_promocion",
    content: "Por promoción",
    panelID: "Por promoción",
  },
  {
    id: "por_volumen",
    content: "Por volumen",
    panelID: "Por volumen",
  },
  {
    id: "maximos",
    content: "Máximos",
    panelID: "Máximos",
  },
];

export const TabsCotizacionesClienteScreen = [
  {
    id: "todos_cotizationes",
    content: "Todos",
    panelID: "Todos",
  },
  {
    id: "pendientes",
    content: "Pendientes",
    panelID: "Pendiente",
  },
  {
    id: "aprobados",
    content: "Aprobados",
    panelID: "Aprobado",
  },
  {
    id: "cancelados",
    content: "Cancelados",
    panelID: "Cancelado",
  },
  {
    id: "Cerrados",
    content: "Cerrados",
    panelID: "Cerrado",
  },
];

export const TabsOportunidadesScreen = [
  {
    id: "pendientes",
    content: "Pendientes",
    panelID: "Pendientes",
  },
  {
    id: "todas_oportunidades",
    content: "Todas",
    panelID: "Todas",
  },
  {
    id: "ganadas",
    content: "Ganadas",
    panelID: "Ganadas",
  },
  {
    id: "perdidas",
    content: "Perdidas",
    panelID: "Perdidas",
  },
];

export const TabsCollectionsScreen = [
  {
    content: "Todas",
    panelID: "all",
    id: "all",
  },
];
