import ApiServiceUsers from "../services/ApiServiceUsers";
import { addToken, clearAuth } from "./AuthActions";

export const USER_BEGIN = "USER_BEGIN";
export const ADD_USERS = "ADD_USERS";
export const USER_FAILED = "USER_FAILED";
export const CLEAR_USERS = "CLEAR_USERS";
export const DELETE_USER = "DELETE_USER";

export function validaInvitacion(token, isClienteComercio) {
  return async (dispatch) => {
    dispatch(addToken(token));
    return ApiServiceUsers.validaInvitacion(isClienteComercio)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  };
}

export function updateUsuario(params) {
  return async (dispatch) => {
    return ApiServiceUsers.updateUsuario(params)
      .then((res) => {
        if (res.ok) {
          dispatch(clearAuth());
        }
        return res;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export const clearUsers = () => ({
  type: CLEAR_USERS,
});
