import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Page,
  Layout,
  ContextualSaveBar,
  Button,
  EmptyState,
  ButtonGroup,
  Banner,
  TextField,
  SkeletonBodyText,
  SkeletonDisplayText,
  Icon,
  Card,
  List,
} from "@shopify/polaris";
import moment from "moment";
import { SearchMinor } from "@shopify/polaris-icons";
import _, { sum } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Layer,
  CustomBadge,
  ComercioDetalleSkeleton,
  Timeline,
  BuscarProductoModal,
  PreventTransitionPrompt,
  ClientSection,
  TagsAutocomplete,
  ApproveCard,
  ArticuloItem,
  HelpFooter,
  CustomerStatusBanner,
} from "../../components";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import {
  setIsLoadingProgressBar,
  toggleToast,
} from "../../actions/InteractiveActions";
import { getPaginationPreliminares, getUserInfo } from "../../reducers";
import "./PedidosPreliminaresDetalleScreen.css";
import { extractInitials } from "../../utils/stringModifiers";
import useGetCurrencies from "../../hooks/useGetCurrencies";
import useDebounce from "../../services/useDebounce";
import { clearArticulos } from "../../actions/ArticulosActions";
import { VendorAutocomplete } from "../../components/common/Index";
import { getDiscounts, getShippingFees } from "../../utils/orderValidations";

export default function PedidosClienteDetalleScreen({ forbidden }) {
  const usuario = useSelector(getUserInfo);
  const [pedidopreliminar, setPedidoPreliminar] = useState(null);
  const pageState = useSelector((state) => state.Preliminares.allIdsDB);
  const pagination = useSelector(getPaginationPreliminares);
  const [showHistorial, setShowHistorial] = useState(true);

  const { id } = useParams();

  const router = useHistory();
  const dispatch = useDispatch();
  const selectArticulosRef = useRef(null);
  const handleModalChangeRef = useRef(null);

  const [isBlocking, setIsBlocking] = useState(false);
  const [isModalActive, setIsModalActive] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [vendorInputValue, setVendorInputValue] = useState("");

  const [selectedArticulos, setSelectedArticulos] = useState([]);
  const [articleCount, setArticleCount] = useState([]);
  const [initialSearch, setInitialSearch] = useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const [errorArticulos, setErrorArticulos] = useState({
    message: "",
    articulos: [],
  });

  const [mostrarExistencia, setMostrarExistencia] = useState(false);
  const [note, setNote] = useState("");
  const [clienteDB, setCliente] = useState(null);
  const [impuestoDB, setImpuestoDB] = useState(null);
  const [totales, setTotales] = useState([]);

  const [porPromosGen, setPorPromosGen] = useState(null);
  const [porVolumen, setPorVolumen] = useState(null);
  const [porMaximo, setPorMaximo] = useState(null);
  const [verifyLimitExceeded, setVerifyLimitExceeded] = useState(false);
  const [quantityError, setQuantityError] = useState([]);
  const [shippingFees, setShippingFees] = useState([]);
  const [selectedShippmentFee, setSelectedShippmentFee] = useState();
  const [isShowingFees, setIsShowingFees] = useState(false);
  const [productDiscounts, setProductDiscounts] = useState([]);
  const [priceToUseId, setPriceToUseId] = useState(null);
  const { currencies } = useGetCurrencies();

  const [isDirty, setIsDirty] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [tagsSelected, setTags] = useState([]);

  const handleChangeNote = useCallback((newValue) => {
    setIsDirty(true);
    setNote(newValue);
    setIsBlocking(true);
  }, []);
  const updateText = useCallback((value) => setInputValue(value), []);

  const debouncedSearchTerm2 = useDebounce(inputValue, 900);

  useEffect(() => {
    window.onbeforeunload = (e) => e;
    return () => (window.onbeforeunload = null);
  }, []);

  function selectArticulos(items = [], block = true) {
    block && setIsBlocking(true);
    let merged = _.unionBy(selectedArticulos, items, "_id");
    merged = merged.filter((i) => i.precios.length > 0);
    items = items.filter((i) => i.precios.length > 0);
    dispatch(clearArticulos());
    setSelectedArticulos(merged);
    setArticleCount((state) => [
      ...new Set(
        state.concat(
          items.map((item) => ({
            id: item._id,
            precio: item.precios[0]?.precio || 0,
            cantidad: item.cantidad ? item.cantidad : "1",
            total:
              (item.cantidad ? item.cantidad : 1) *
              (item.precios[0]?.precio || 1),
          }))
        )
      ),
    ]);
  }

  useEffect(() => {
    selectArticulosRef.current = selectArticulos;
    handleModalChangeRef.current = handleModalChange;
  });

  function handleModalChange() {
    if (!initialSearch) {
      setIsModalActive(!isModalActive);
    }
  }

  function selectTags(values) {
    setIsBlocking(true);
    setIsDirty(true);
    setTags(values);
  }

  useEffect(() => {
    handleModalChangeRef.current();
  }, [debouncedSearchTerm2]);

  function updateSelection(selected, updateAndLoading = true) {
    updateAndLoading && setIsLoading(true);
    ApiServiceComercios.obtenerPedidoDatos(selected[0])
      .then(
        ({
          ok,
          cliente,
          descuentos,
          impuesto,
          mostrarExistenciaProducto,
          verifyLimitExceeded,
        }) => {
          if (ok) {
            let currency = cliente.moneda;

            let shippingFees = getShippingFees(
              cliente?.envio?.shippingFees,
              currency
            );

            setCliente(cliente);
            setImpuestoDB(impuesto);
            setMostrarExistencia(mostrarExistenciaProducto);
            setVerifyLimitExceeded(verifyLimitExceeded);
            setShippingFees(shippingFees);

            const { promos, maximos, volumenes, productsDiscount, priceToUse } =
              getDiscounts(descuentos, cliente);
            setPorPromosGen(promos);
            setPorVolumen(volumenes);
            setPorMaximo(maximos);
            setProductDiscounts(productsDiscount);
            setPriceToUseId(priceToUse);
          }
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  }

  function onChangeArticleValue(value, id, total) {
    let articles = [...articleCount];
    articles.forEach((obj) => {
      if (obj.id === id) {
        obj.cantidad = value;
      }
    });
    setIsBlocking(true);
    setIsDirty(true);
    setArticleCount(articles);
    setTotales(totales.map((i) => (i.articuloId === id ? { ...i, total } : i)));
  }

  function onRemoveArticulo(id) {
    let articulosIds = articleCount.filter((item) => item.id !== id);
    let articulos = selectedArticulos.filter((item) => item._id !== id);
    setSelectedArticulos(articulos);
    setArticleCount(articulosIds);
    setTotales(totales.filter((i) => i.articuloId !== id));
  }

  function addNoteToArticle(note, id) {
    const selectedArt = [...selectedArticulos];
    selectedArt.forEach((obj) => {
      if (obj.id === id) {
        obj["notas"] = note;
      }
    });
    setSelectedArticulos(selectedArticulos);
  }

  function dismissWarning() {
    setErrorArticulos({
      message: "",
      articulos: [],
    });
  }

  const willReturnInitialData = useCallback((totalArt, articuloId) => {
    if (totalArt && totalArt > 0) {
      setTotales((prevState) =>
        prevState.find((i) => i.articuloId === articuloId)
          ? prevState.map((i) =>
              i.articuloId === articuloId ? { ...i, total: totalArt } : i
            )
          : [...prevState, { total: totalArt, articuloId }]
      );
    }
  }, []);

  function handleSubTotalProductos() {
    return totales.reduce((prev, current) => prev + current.total, 0);
  }

  function handleImpuesto() {
    // Precio de envío
    const clientePrecioEnvio = clienteDB?.envio?.precio || 0;

    // Impuesto del comercio
    const impuesto = impuestoDB?.impuesto || 16;

    // Valida si se va a cobrar impuesto en el costo de envío
    const cobrarEnvio = impuestoDB?.cobrarEnvio || false;

    // Obtiene los impuestos de los artículos seleccionados
    const totalesWithArticle = totales?.map((i) => ({
      ...i,
      impuestos: selectedArticulos?.find((j) => j.id === i.articuloId)
        ?.impuestos,
    }));

    // Calcula los impuestos al producto y los agrega a subTotalEachProduct
    let subTotalEachProduct = [];
    totalesWithArticle.forEach((i) => {
      const total = i.total;
      const impuestos = i.impuestos?.filter((j) => j.porcentaje > 0) || [];
      let summa = 0;
      impuestos.forEach((j) => (summa = summa + total * (j.porcentaje / 100)));
      subTotalEachProduct.push(
        Math.round((summa + Number.EPSILON) * 100) / 100
      );
    });

    // Calcula el impuesto aplicado al precio de envío
    let precioEnvio = 0;
    if (selectedShippmentFee) {
      const priceShippingFee = shippingFees.find(
        (i) => i._id === selectedShippmentFee
      ).price;
      precioEnvio = cobrarEnvio
        ? priceShippingFee * (impuesto / 100)
        : priceShippingFee - handlePrecioEnvio();
    } else {
      // Valida el impuesto aplicado al precio de enío
      precioEnvio = cobrarEnvio
        ? clientePrecioEnvio * (impuesto / 100)
        : clientePrecioEnvio - handlePrecioEnvio();
    }

    // Regresa la suma de los impuestos de los productos y el precio de envío
    return sum(subTotalEachProduct) + precioEnvio;
  }

  function handlePrecioEnvio() {
    // Precio de envío
    const clientePrecioEnvio = clienteDB?.envio?.precio || 0;
    // Impuesto del comercio
    const impuesto = impuestoDB?.impuesto || 16;
    // Valida si se va a cobrar impuesto en el costo de envío
    const cobrarEnvio = impuestoDB?.cobrarEnvio || false;
    // Calcula el costo de envío sin impuesto aplicado

    let costoEnvio = 0;
    if (selectedShippmentFee) {
      const priceShippingFee = shippingFees.find(
        (i) => i._id === selectedShippmentFee
      ).price;

      costoEnvio = cobrarEnvio
        ? priceShippingFee
        : (1 * priceShippingFee) / (1 + impuesto / 100);
    } else {
      costoEnvio = cobrarEnvio
        ? clientePrecioEnvio
        : (1 * clientePrecioEnvio) / (1 + impuesto / 100);
    }

    return costoEnvio;
  }

  function handleTotal() {
    const subTotalPedido = handleSubTotalProductos();
    const precioEnvio = handlePrecioEnvio();
    const impuesto = handleImpuesto();
    const sumTotal = sum([subTotalPedido, precioEnvio, impuesto]);

    return sumTotal;
  }

  function calculateAvailable() {
    return (
      (clienteDB.limite_credito.limite_credito || 0) -
      (clienteDB.limite_credito.saldo?.actual || 0) -
      handleTotal()
    );
  }

  //Funcion para obtener los datos del pedido preliminar
  const getPedidoPreliminar = () => {
    setInitialSearch(false);
    dispatch(setIsLoadingProgressBar({ active: false }));
    ApiServiceComercios.preliminarDetalle(id)
      .then(({ ok, draftOrder }) => {
        if (ok) {
          const articulos =
            draftOrder.articuloFlete && draftOrder.articuloFlete.id
              ? draftOrder.articulos.filter(
                  (articulo) =>
                    articulo.articulo._id !== draftOrder.articuloFlete.id
                )
              : draftOrder.articulos;
          selectArticulosRef.current(
            articulos.map((i) => ({
              ...i.articulo,
              id: i.articulo._id,
              cantidad: i.cantidad.toString(),
              notas: i.notas || "",
              etiquetas: i.etiquetas || [],
            })),
            false
          );
          setTags(draftOrder.etiquetas || []);
          setVendorInputValue(
            draftOrder?.vendor ? draftOrder?.vendor._id : null
          );
          setNote(draftOrder?.notas);
          setPedidoPreliminar({
            ...draftOrder,
            articulos:
              draftOrder.articuloFlete && draftOrder.articuloFlete.nombre
                ? draftOrder.articulos.filter(
                    (i) => i.articulo._id !== draftOrder.articuloFlete.id
                  )
                : draftOrder.articulos.filter(
                    (i) => i.articulo.nombre.indexOf("Flete") < 0
                  ),
          });

          if (!_.isEmpty(draftOrder.cliente)) {
            updateSelection([draftOrder?.cliente?._id], true);
          }
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getPedidoPreliminar(), []);
  //Fin de la funcion para obtener los datos del pedido preliminar

  if (isLoading) {
    return (
      <Layer title={`Comercio`} forbidden={forbidden}>
        <ComercioDetalleSkeleton fullWidth />
      </Layer>
    );
  }

  if (pedidopreliminar === null) {
    return (
      <Layer title={`Comercio`} forbidden={forbidden}>
        <EmptyState
          heading="Oops! Hubo un problema al intentar obtener este pedido"
          action={{
            content: "Volver a intentar",
            onAction: () => window.location.reload(),
          }}
          secondaryAction={{
            content: "Regresar a pedidos",
            onAction: () => router.goBack(),
          }}
          image="/Ad.svg"
        >
          <p>Intenta de nuevo más tarde</p>
        </EmptyState>
      </Layer>
    );
  }

  /*Funcion de crear pedido*/
  function crearPedido() {
    router.push("/admin/pedidos/agregar", {
      articulos: pedidopreliminar.articulos.map((i) => ({
        ...i,
        ...i.articulo,
      })),
      clienteId: pedidopreliminar.cliente._id,
      vendorId: pedidopreliminar.vendor?._id,
      notas: pedidopreliminar.notas,
      draftOrderId: id,
      almacenId: clienteDB.almacen.id,
      shippingFeeId: pedidopreliminar?.dataForOrder?.shippingFeeId,
    });
  }
  /*Fin de la funcion de crear pedido*/

  /*Funcion de duplicar pedidos preliminares*/
  function duplicarPreliminar() {
    router.push("/admin/pedidos/preliminares/agregar", {
      articulos: pedidopreliminar.articulos.map((i) => ({
        ...i,
        ...i.articulo,
      })),
      clienteId: pedidopreliminar.cliente._id,
      vendorId: pedidopreliminar.vendor?._id,
      notas: pedidopreliminar.notas,
      almacenId: clienteDB.almacen.id,
      shippingFeeId: pedidopreliminar?.dataForOrder?.shippingFeeId,
    });
  }
  /*Fin de la funcion de duplicar pedidos preliminares*/

  /*Funcion de actualizar pedidos preliminares*/

  function actualizarPreliminar() {
    setIsBlocking(true);
    let shippingFeeId = null;

    if (selectedShippmentFee) {
      const priceShippingFee = shippingFees.find(
        (i) => i._id === selectedShippmentFee
      );
      shippingFeeId = priceShippingFee._id;
    }

    const articulos = selectedArticulos.map((ar) => ({
      articulo: ar._id,
      notas: ar.notas || "",
      cantidad: Number(articleCount.find((art) => art.id === ar._id).cantidad),
    }));

    const data = {
      articulos,
      notas: note,
      vendorId: vendorInputValue,
      shippingFeeId,
      etiquetas: tagsSelected,
    };

    setIsUpdating(true);
    ApiServiceComercios.actualizarPreliminar(data, id)
      .then(({ message }) => {
        dispatch(toggleToast({ message }));
        setIsBlocking(false);
        window.location.reload();
      })
      .finally(() => {
        setIsUpdating(false);
        setIsDirty(false);
      });
  }

  function cancelDraftOrder() {
    setIsUpdating(true);
    setIsBlocking(false);
    ApiServiceComercios.cancelDraftOrder(id)
      .then(({ message }) => {
        dispatch(toggleToast({ message }));
        router.push(`/admin/pedidos/preliminares`);
      })
      .catch((err) => {});
  }

  /*Fin de la funcion de actualizar pedidos preliminares*/

  /*Datos del encabezado de pedidos*/
  const titleMetada = (
    <div className="flex items-center">
      <CustomBadge status={pedidopreliminar.estatus.nombre} />
    </div>
  );
  const subtitleMetada = (
    <>
      {moment(pedidopreliminar.createdDate).format("D ")} de{" "}
      {moment(pedidopreliminar.createdDate).format("MMMM YYYY, HH:mm")}
      {pedidopreliminar.contact
        ? `, ${pedidopreliminar.contact?.name} ${pedidopreliminar.contact?.last_name}`
        : ""}
    </>
  );

  const actionGroups = {
    title: "Más acciones",
    accessibilityLabel: "Action group label",
    actions: [
      {
        content: "Duplicar",
        outline: true,
        onAction: duplicarPreliminar,
      },
      {
        content: "Cancelar",
        outline: true,
        onAction: cancelDraftOrder,
      },
    ],
  };
  /*Fin de los datos del encabezado de pedidos*/

  //Funcion de botones de siguiente pedido y anterior//
  function previousPage() {
    const prevId = pageState[pageState.indexOf(id) - 1];
    router.push(`/admin/pedidos/preliminares/${prevId}`);
  }

  function nextPage() {
    const nextId = pageState[pageState.indexOf(id) + 1];
    router.push(`/admin/pedidos/preliminares/${nextId}`);
  }
  //Fin de funcion de botones de siguiente pedido y anterior//

  /*Barra de actualizacion de datos*/
  const handleDiscard = () => {
    router.push(`/admin/pedidos/preliminares/${id}`);
  };

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      fullWidth
      message="Cambios no guardados"
      saveAction={{
        content: "Actualizar preliminar",
        onAction: actualizarPreliminar,
        disabled:
          !isDirty ||
          selectedArticulos.length === 0 ||
          !clienteDB ||
          (verifyLimitExceeded && calculateAvailable() < 0) ||
          !clienteDB.almacen ||
          quantityError.length ||
          (isShowingFees && !selectedShippmentFee),
        loading: isUpdating,
      }}
      discardAction={{
        onAction: handleDiscard,
        disabled: isUpdating,
      }}
    />
  ) : null;
  /*Fin de barra de actualizacion de datos*/
  return (
    <Layer
      title={`Detalle pedido ${pedidopreliminar.folio}`}
      forbidden={forbidden}
      scrollToTop
    >
      <Page
        fullWidth
        title={`${pedidopreliminar.folio}`}
        titleMetadata={titleMetada}
        subtitle={subtitleMetada}
        breadcrumbs={[{ content: "Pedidos", onAction: () => router.goBack() }]}
        pagination={{
          hasPrevious:
            pageState.indexOf(id) !== 0 ||
            pagination.currentPage * pagination.sizePerPage !== 0,
          onPrevious: previousPage,
          nextTooltip: "Siguiente pedido",
          hasNext: pageState.indexOf(id) + 1 < pageState.length,
          onNext: nextPage,
          previousTooltip: "Pedido anterior",
        }}
        secondaryActions={[
          {
            content: "Crear pedido",
            onAction: crearPedido,
            disabled: clienteDB?.status_microsip !== "A",
          },
        ]}
        actionGroups={[actionGroups]}
      >
        <PreventTransitionPrompt
          when={isBlocking}
          setIsBlocking={setIsBlocking}
          router={router}
          title="Tienes cambios sin guardar en esta página"
          message="Si abandonas esta página, perderás todos los cambios no guardados. ¿Estás seguro de que quieres salir de esta página?"
        />
        {contextualSaveBarMarkup}
        {isModalActive && (
          <BuscarProductoModal
            primaryActionTitle="Agregar al pedido"
            selected={articleCount.map((a) => a.id)}
            selectArticulos={selectArticulos}
            updateText={updateText}
            value={inputValue}
            isActive={isModalActive}
            handleChange={handleModalChange}
            idCliente={clienteDB?._id}
            mostrarExistenciaProducto={mostrarExistencia}
            almacenId={clienteDB?.almacen?.id}
          />
        )}
        <Layout>
          <Layout.Section>
            <CustomerStatusBanner status={clienteDB?.status_microsip} />
            {errorArticulos.message && (
              <div className="mb-8">
                <Banner
                  title={errorArticulos.message}
                  status="warning"
                  onDismiss={dismissWarning}
                >
                  <List>
                    {errorArticulos.articulos?.map((i, key) => (
                      <List.Item key={key}>{i.nombre}</List.Item>
                    ))}
                  </List>
                </Banner>
              </div>
            )}
            <div className="selectClientSmall">
              <ClientSection
                clienteDB={clienteDB}
                isLoading={isLoading}
                setIsBlocking={isBlocking}
              />
            </div>
            <Card title="Productos">
              <Card.Section>
                <TextField
                  type="search"
                  onChange={updateText}
                  value={inputValue}
                  prefix={<Icon source={SearchMinor} color="inkLighter" />}
                  placeholder="Buscar productos"
                  disabled={!clienteDB || clienteDB?.status_microsip !== "A"}
                  connectedRight={
                    <Button
                      disabled={
                        !clienteDB || clienteDB?.status_microsip !== "A"
                      }
                      onClick={handleModalChange}
                    >
                      Buscar
                    </Button>
                  }
                />
              </Card.Section>
              {selectedArticulos.length > 0 ? (
                <Card.Section>
                  {selectedArticulos.map((articulo, idx) => {
                    return (
                      <div key={articulo._id}>
                        <ArticuloItem
                          descMaximos={porMaximo}
                          descVolumenAplicada={porVolumen}
                          runOnInsertBegin={willReturnInitialData}
                          descPromoAplicada={porPromosGen}
                          selectNote={addNoteToArticle}
                          articulo={articulo}
                          quantity={Number(
                            articleCount.find((ar) => ar.id === articulo._id)
                              ?.cantidad || 1
                          )}
                          handleChange={onChangeArticleValue}
                          onRemove={onRemoveArticulo}
                          showTax={impuestoDB?.mostrarPrecioConImpuesto}
                          setQuantityError={(value, id) =>
                            setQuantityError((prevState) =>
                              value
                                ? prevState.some((errorId) => errorId === id)
                                  ? prevState
                                  : [...prevState, id]
                                : prevState.filter((errorId) => errorId !== id)
                            )
                          }
                          clientCurrency={clienteDB?.moneda}
                          currencies={currencies}
                          isLast={idx === selectArticulos.length + 1}
                          discounts={productDiscounts}
                          priceToUse={priceToUseId}
                        />
                      </div>
                    );
                  })}
                </Card.Section>
              ) : null}
            </Card>
            {isLoading ? (
              <div className="selectClientLarge">
                <SkeletonDisplayText size="small" />
                <div className="my-10" />
                <SkeletonBodyText lines={2} />
                <SkeletonBodyText lines={2} />
              </div>
            ) : (
              <div className="mt-8 rounded">
                <ApproveCard
                  cantidad={selectedArticulos.length}
                  currency={clienteDB?.moneda}
                  envio={{
                    cantidad: handlePrecioEnvio(),
                    datos: clienteDB?.envio,
                  }}
                  impuesto={handleImpuesto()}
                  limiteCredito={clienteDB?.limite_credito || {}}
                  selectedShippmentFee={selectedShippmentFee}
                  setIsShowingFees={setIsShowingFees}
                  setSelectedShippmentFee={setSelectedShippmentFee}
                  shippingFees={shippingFees}
                  showLimit={verifyLimitExceeded}
                  subtotal={handleSubTotalProductos()}
                  total={handleTotal()}
                  weight={pedidopreliminar?.weight}
                />
              </div>
            )}
            <div className="my-8">
              <Timeline
                visible={showHistorial}
                onChange={setShowHistorial}
                initials={extractInitials(usuario.name, usuario.last_name)}
                parentMessageId={id}
                subtitle="Todos pueden ver los comentarios."
              />
            </div>
          </Layout.Section>
          <Layout.Section secondary>
            <div className="selectClientLarge">
              <ClientSection
                clienteDB={clienteDB}
                isLoading={isLoading}
                setIsBlocking={isBlocking}
                hasRemoveItem={false}
              />
            </div>
            <Card title="Notas">
              <Card.Section>
                <TextField
                  type="text"
                  placeholder="Agrega una nota al pedido"
                  value={note}
                  onChange={handleChangeNote}
                  showCharacterCount
                  maxLength={150}
                />
              </Card.Section>
            </Card>
            <Card title="Vendedores">
              <Card.Section>
                <VendorAutocomplete
                  vendorInputValue={vendorInputValue}
                  setVendorInputValue={(value) => {
                    setVendorInputValue(value);
                    setIsDirty(true);
                    setIsBlocking(true);
                  }}
                  placeholder="Buscar vendedores"
                />
              </Card.Section>
            </Card>
            <TagsAutocomplete
              className="bg-tag"
              title="Etiquetas"
              subdued
              selected={tagsSelected}
              onSelect={selectTags}
              module="Preliminares"
            />
          </Layout.Section>
        </Layout>

        <div className="flex justify-end mt-8">
          <ButtonGroup>
            <Button
              onClick={() => {
                router.push("/admin/pedidos/preliminares");
              }}
            >
              Descartar
            </Button>
            <Button
              primary
              onClick={actualizarPreliminar}
              disabled={
                selectedArticulos.length === 0 ||
                !clienteDB ||
                (verifyLimitExceeded && calculateAvailable() < 0) ||
                !clienteDB.almacen ||
                quantityError.length ||
                (isShowingFees && !selectedShippmentFee)
              }
              loading={isUpdating}
            >
              Actualizar preliminar
            </Button>
          </ButtonGroup>
        </div>
        <HelpFooter
          title="Pedidos Preliminares"
          url="https://help.b2bgo.mx/preliminares"
        />
      </Page>
    </Layer>
  );
}
