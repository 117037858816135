import React, { useState, useEffect, useCallback } from "react";
import {
  Page,
  TextStyle,
  Card,
  DataTable,
  Badge,
  Tabs,
  Spinner,
  Banner,
  Select,
} from "@shopify/polaris";
import { RefreshMajor } from "@shopify/polaris-icons";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { AntPagination, Layer } from "../../components";
import "./HistorialScreenStyles.css";
import { TabsHistorialScreen as tabs } from "../../utils/Tabs";
import {
  getHistorial,
  getHistorialMetrics,
  getPaginationHistorial,
  isLoadingHistorial,
} from "../../reducers";
import {
  fecthHistorial,
  changePage,
} from "../../actions/SincronizacionActions";
import { obtenerComercioConfigMicrosip } from "../../actions/ComerciosActions";
import { NumberFormat } from "../../utils/Formats";
import useToggle from "../../hooks/useToggle";
import SelectHistorialModal from "./SelectHistorialModal";

export default function HistorialScreen({ forbidden }) {
  const { id } = useParams();
  const { comercio = {} } = useLocation().state;
  const dispatch = useDispatch();
  const [activeModal, toggleModal] = useToggle();
  const [tabSelected, setTabSelected] = useState(0);
  const isLoading = useSelector(isLoadingHistorial);
  const data = useSelector(getHistorial);
  const {
    successful = {},
    failed = {},
    lastSync = {},
  } = useSelector(getHistorialMetrics);
  const pagination = useSelector(getPaginationHistorial);
  const [isSync, setIsSync] = useState(false);
  const [isEnabled, setIsEnabled] = useState(null);
  const [isCheckingIntegration, setisCheckingIntegration] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [dateSelected, setDateSelected] = useState("today");

  const validateSync = useCallback(() => {
    ApiServiceComercios.synchronizeValidate().then(({ isActive }) => {
      setIsActive(isActive);
      setIsSync(isActive);
    });
  }, []);

  useEffect(() => {
    const fetchData = () => {
      dispatch(obtenerComercioConfigMicrosip({ isEnabled: true }))
        .then(({ enabled }) => {
          setIsEnabled(enabled);
        })
        .finally(() => {
          setisCheckingIntegration(false);
        });
      validateSync();
    };
    fetchData();
  }, [dispatch, validateSync]);

  useEffect(() => {
    dispatch(
      fecthHistorial(id, {
        date: dateSelected,
        status: tabSelected === 0 ? "" : tabs[tabSelected].panelID,
      })
    );
    validateSync();

    return () => null;
  }, [dispatch, id, dateSelected, tabSelected, validateSync]);

  function handleTabSelected(value) {
    setTabSelected(value);
  }

  function handleChangePage(page) {
    dispatch(changePage(page));
    dispatch(
      fecthHistorial(id, {
        date: dateSelected,
        status: tabSelected === 0 ? "" : tabs[tabSelected].panelID,
      })
    );
    validateSync();
  }

  function sincronizar(values = []) {
    setIsSync(true);
    toggleModal();
    ApiServiceComercios.synchronizeCollections(id, values);
    dispatch(obtenerComercioConfigMicrosip({ isEnabled: true }))
      .then(({ enabled }) => {
        setIsEnabled(enabled);
        dispatch(
          fecthHistorial(id, {
            date: dateSelected,
            status: tabSelected === 0 ? "" : tabs[tabSelected].panelID,
          })
        );
      })
      .finally(() => {
        setisCheckingIntegration(false);
      });
    setTimeout(() => {
      validateSync();
    }, 2500);
  }

  const switchTipo = {
    1: "Clientes",
    2: "Artículos",
    3: "Pedidos",
    4: "Descuentos",
    5: "Cotizaciones",
    6: "Listas",
    7: "Variantes",
    8: "Vendedores",
    9: "Imágenes",
    10: "Cobradores",
  };

  const buttonOption = (
    <Select
      label="Date"
      labelHidden
      options={[
        { label: "Hoy", value: "today" },
        { label: "Últimas 24 horas", value: "lastOneDay" },
        { label: "Últimas 48 horas", value: "lastTwoDays" },
        { label: "Últimos 7 días", value: "lastSevenDays" },
      ]}
      value={dateSelected}
      onChange={setDateSelected}
    />
  );

  return (
    <Layer title="Historial de sincronizaciones" forbidden={forbidden}>
      <Page
        title={`Historial de sincronizaciones ${
          comercio ? comercio?.nombre : ""
        }`}
        breadcrumbs={[
          {
            content: id ? "Regresar a comercio" : "Integraciones",
            url: id
              ? `/admin/comercios/${id}`
              : "/admin/configuracion/integraciones",
          },
        ]}
        primaryAction={{
          content: "Sincronizar",
          onAction: toggleModal,
        }}
        secondaryActions={[
          {
            content: isSync || isActive ? <Spinner size="small" /> : "",
            disabled: isActive || isSync || !isEnabled,
          },
        ]}
        fullWidth
      >
        {isCheckingIntegration ? (
          <div className="mt-4 mr-4 mb-6">
            <Spinner size="small" />
          </div>
        ) : !isEnabled ? (
          <div className="my-6">
            <Banner
              title="Datos desactualizados"
              icon={RefreshMajor}
              status="warning"
              onDismiss={() => {}}
            >
              <p>
                Verifica tu integración con microsip para empezar a sincronizar
                datos
              </p>
            </Banner>
          </div>
        ) : null}

        {isSync && (
          <div className="pb-8 pt-5">
            <Banner title="Sincronización en progreso" status="warning">
              Una sincronización está en progreso, este proceso puede demorarse
              unos minutos, sin embargo puedes continuar trabajando.
            </Banner>
          </div>
        )}
        {activeModal && (
          <SelectHistorialModal
            open={activeModal}
            onClose={toggleModal}
            onSync={sincronizar}
          />
        )}

        <Card>
          <div className="flex p-3 gap-12">
            <div className="flex flex-col">{buttonOption}</div>
            <div className="flex flex-col">
              <h1>Exitosas</h1>
              <div className="flex gap-3">
                <TextStyle variation="strong">
                  {isLoading ? "--" : successful?.total || 0}
                </TextStyle>
                <TextStyle variation="positive">
                  {isLoading
                    ? "--"
                    : Number(successful?.percentage || 0).toLocaleString(
                        "en",
                        NumberFormat
                      ) || "0"}
                  %
                </TextStyle>
              </div>
            </div>
            <div className="flex flex-col">
              <h1>Fallidas</h1>
              <div className="flex gap-3">
                <TextStyle variation="strong">
                  {isLoading ? "--" : failed?.total || 0}
                </TextStyle>
                <TextStyle variation="negative">
                  {isLoading
                    ? "--"
                    : Number(failed?.percentage || 0).toLocaleString(
                        "en",
                        NumberFormat
                      )}
                  %
                </TextStyle>
              </div>
            </div>
            <div className="flex flex-col">
              <h1>Última sincronización:</h1>
              <p>
                {lastSync
                  ? !lastSync.fecha_fin
                    ? "En progreso"
                    : `${
                        lastSync.tipo_sincronizacion === 1
                          ? "Manual"
                          : "Automática"
                      }, ${moment(lastSync.fecha_fin).fromNow()}`
                  : ""}
              </p>
            </div>
          </div>
        </Card>

        <Card>
          <Tabs tabs={tabs} selected={tabSelected} onSelect={handleTabSelected}>
            <DataTable
              columnContentTypes={[
                "text",
                "text",
                "text",
                "text",
                "text",
                "text",
                "text",
              ]}
              headings={[
                "Catálogo",
                "Mensaje",
                "Tipo",
                "Inicio",
                "Fin",
                "Estatus",
              ]}
              rows={data.map((item) => {
                const {
                  catalogo,
                  tipo_sincronizacion,
                  mensaje,
                  fecha_inicio,
                  fecha_fin,
                  estatus_code,
                } = item;

                const catalogoI = (
                  <TextStyle variation="strong">
                    {switchTipo[catalogo]}
                  </TextStyle>
                );
                const message = (
                  <div className="overflow-hidden whitespace-nowrap">
                    <p className="text-left text-gray-700">{mensaje}</p>
                  </div>
                );
                const tipo = (
                  <TextStyle>
                    {tipo_sincronizacion === 1 ? "Manual" : "Automática"}
                  </TextStyle>
                );
                const fechaIni = (
                  <p>{moment(fecha_inicio).format("MMM D, HH:mm:ss")}</p>
                );
                const fechaFin = fecha_fin ? (
                  <p>{moment(fecha_fin).format("MMM D, HH:mm:ss")}</p>
                ) : (
                  <TextStyle variation="subdued">--</TextStyle>
                );
                const estatus = (
                  <Badge
                    status={
                      !fecha_fin
                        ? "attention"
                        : estatus_code === 200
                        ? "success"
                        : "critical"
                    }
                    progress={
                      !fecha_fin
                        ? "partiallyComplete"
                        : estatus_code === 200
                        ? "incomplete"
                        : "complete"
                    }
                  >
                    {!fecha_fin
                      ? "En progreso"
                      : estatus_code === 200
                      ? "Exitosa"
                      : "Fallida"}
                  </Badge>
                );
                return [catalogoI, message, tipo, fechaIni, fechaFin, estatus];
              })}
            />
            {isLoading && (
              <div className="flex justify-center">
                <Spinner size="large" />
              </div>
            )}
            <div className="flex justify-center py-6 border pagination px-10">
              <AntPagination
                total={pagination.total}
                current={pagination.currentPage + 1}
                pageSize={pagination.sizePerPage}
                onChange={(page) => {
                  handleChangePage(page - 1);
                }}
              />
            </div>
          </Tabs>
        </Card>
      </Page>
    </Layer>
  );
}
