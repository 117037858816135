import React from "react";
import {
  PhoneMajor,
  EmailMajor,
  // CashDollarMajor,
} from "@shopify/polaris-icons";
import { Icon, TextStyle } from "@shopify/polaris";

export default function HeaderSectionStyled({ section }) {
  const styles = {
    container: {
      height: 40,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "white",
      paddingLeft: 10,
    },
  };
  return (
    <div style={styles.container}>
      <div className="flex flex-wrap items-center" style={{ height: 40 }}>
        <div className="flex mr-10">
          <div className="mr-6">
            <Icon source={PhoneMajor} color="base" />
          </div>
          <TextStyle>{section?.properties?.phone}</TextStyle>
        </div>
        <div className="flex">
          <div className="mr-6">
            <Icon source={EmailMajor} color="base" />
          </div>
          <TextStyle>{section?.properties?.email}</TextStyle>
        </div>
      </div>
      <div className="flex mr-8">
        {/* <div className="mr-6">
          <Icon source={CashDollarMajor} color="base" />
        </div>
        <TextStyle>TC: 20.21</TextStyle> */}
      </div>
    </div>
  );
}
