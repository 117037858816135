import React from 'react'
import { 
   Card, 
   FormLayout, 
   TextStyle,
 } from "@shopify/polaris";
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

export default function PaginasCard({titulo, descripcion, contenido, handleFieldChange}) {

   return (
      <Card
         sectioned
         title={titulo}
      >
         <FormLayout>
            <TextStyle>{descripcion}</TextStyle>
            <SunEditor 
               lang="es"
               setContents={contenido}
               placeholder="Escribe política de términos y condiciones..."
               height="350"
               setOptions={{
                  buttonList: buttonList.complex,
                  //callBackSave: (content) => { handleFieldChange(content, titulo) },
               }}
               onBlur={()=>{}}
               autoFocus={false}
               onFocus={()=>{}}
               onClick={()=>{}}
               onChange={(content) => { handleFieldChange(content, titulo); }}
            />
         </FormLayout>
      </Card>
   )
}
