export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// Function to extract the first 3 letters of a string
export function extractFirst3Letters(string) {
  return string.substring(0, 3);
}

// Function to extract initials from a string given name and last name
export function extractInitials(name = "", lastname = "") {
  return `${name[0] || ""}${lastname[0] || ""}`;
}
