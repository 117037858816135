import { useEffect, useState } from "react";
import ApiServiceComercios from "../services/ApiServiceComercios";

const useGetCurrencies = () => {
  const [currencies, setCurrencies] = useState([]);
  const [isLoadingCurrencies, setIsLoading] = useState(false);
  useEffect(() => {
    const getCurrencies = () => {
      try {
        setIsLoading(true);
        ApiServiceComercios.getCurrencies()
          .then(({ monedas }) => {
            //add values to autocomplete list
            const currenciesItems = monedas.map(
              ({ claveFiscal, _id, ...props }) => ({
                label: claveFiscal,
                value: _id,
                ...props,
              })
            );

            setCurrencies(currenciesItems);
          })
          .finally(() => setIsLoading(false));
      } catch (error) {}
    };
    getCurrencies();
  }, []);

  return { currencies, isLoadingCurrencies, setCurrencies };
};

export default useGetCurrencies;
