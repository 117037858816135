import { useState, useCallback, useEffect } from "react";
import { Autocomplete, Button, Icon, Spinner } from "@shopify/polaris";
import { CirclePlusMajor } from "@shopify/polaris-icons";
import _ from "lodash";

export default function CreateCollectionAutocomplete({
  listTitle = "",
  loading = false,
  label = "",
  placeholder = "",
  deselectedOptions = [],
  setSelectedOption = () => {},
  selectedOptions = [],
  requiredIndicator = false,
  onAddNew = () => {},
  value = "",
}) {
  const [inputValue, setInputValue] = useState(value);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (deselectedOptions.length > 0) {
      setOptions(_.orderBy(deselectedOptions, "label", "asc"));
      if (selectedOptions.length > 0) {
        const selectedInputValue = deselectedOptions.find(
          (option) => option.value === selectedOptions[0]
        )?.label;
        setInputValue(selectedInputValue);
      } else if (value) {
        setInputValue(value);
      }
    }
  }, [deselectedOptions, selectedOptions, setInputValue, value]);

  const updateText = useCallback(
    (value) => {
      setInputValue(value);

      if (value === "") {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const updateSelection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = options.find((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption && matchedOption.label;
      });

      setSelectedOption(selected);
      setInputValue(selectedValue[0]);
    },
    [options, setSelectedOption]
  );

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label={label}
      value={inputValue}
      placeholder={placeholder}
      autoComplete="off"
      requiredIndicator={requiredIndicator}
      suffix={loading && <Spinner size="small" />}
      clearButton
      onClearButtonClick={() => updateText("")}
    />
  );

  const emptyState = inputValue && inputValue.trim().length > 0 && (
    <div
      className="flex flex-row items-center px-6 py-2 cursor-pointer"
      onClick={() => onAddNew(inputValue.trim())}
    >
      <div className="mr-2">
        <Icon source={CirclePlusMajor} color="interactive" />
      </div>
      <Button plain>Agregar {inputValue}</Button>
    </div>
  );

  return (
    <Autocomplete
      listTitle={listTitle}
      loading={loading}
      options={options}
      selected={selectedOptions}
      onSelect={updateSelection}
      textField={textField}
      willLoadMoreResults
      allowMultiple={false}
      emptyState={emptyState}
    />
  );
}
