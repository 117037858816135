import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Autocomplete,
  DataTable,
  Banner,
  Card,
  Select,
  FormLayout,
  Icon,
  Modal,
  Stack,
  TextField,
  Button,
  TextStyle,
} from "@shopify/polaris";
import {
  SearchMajor,
  EditMajor,
  DeleteMajor,
  MobileHorizontalDotsMajor,
} from "@shopify/polaris-icons";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import CustomPopover from "../CustomPopover";
import _ from "lodash";
import { onBlurTextField } from "../../utils/TextFieldValidations";
import useDebounce from "../../services/useDebounce";

export default function DescuentoProductoPromoModal({
  precioLista = [],
  selected = [],
  onAddProducto = () => {},
  togleModal = () => {},
  isActive = false,
}) {
  const queryValueRef = useRef(null);
  const productosRef = useRef(null);
  const [producto, setProducto] = useState({
    porcentaje: "1",
    nombre: "",
    id: null,
    precioLista: { nombre: "", id: "" },
  });
  const [productos, setProductos] = useState([]);
  const deselectedOptions = useRef([]);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [queryValue, setQueryValue] = useState("");
  const [preciosLista, setPreciosLista] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const debouncedSearchTerm = useDebounce(queryValue, 900);

  useEffect(() => {
    queryValueRef.current = queryValue;
    productosRef.current = productos;
  });

  useEffect(() => {
    setPreciosLista(precioLista);
    if (precioLista.length > 0)
      setProducto((s) => ({
        ...s,
        precioLista: {
          nombre: precioLista[0].label,
          id: precioLista[0].value,
        },
      }));
    const updatingLine = selected.find((s) => s.editing === true);
    if (updatingLine) {
      setProductos((s) => [
        ...s,
        {
          productPrecio: updatingLine.productPrecio,
          porcentaje: updatingLine.porcentaje,
          nombre: updatingLine.nombre,
          id: updatingLine.id,
          precioLista: updatingLine.precioLista,
        },
      ]);
      setProducto((s) => ({ ...s, ...updatingLine }));
      setIsEditing(true);
    }
    return () => null;
  }, [precioLista, selected]);

  useEffect(() => {
    function onQueryEndChange() {
      setIsLoading(true);
      ApiServiceComercios.obtenerArticulos({
        sort: "nombre",
        query: queryValueRef.current,
        limit: 20,
        skip: 0,
        existencia: -1,
      })
        .then(({ ok, articulos }) => {
          if (ok) {
            let willAdd = articulos.map((i) => ({
              label: i.nombre,
              value: i._id,
            }));
            deselectedOptions.current = willAdd;
            if (precioLista.length > 0 || selected.length > 0) {
              const precioListaDraftId = producto.precioLista.id;
              const filteredSelected = selected.concat(productosRef.current);
              willAdd = willAdd.filter(
                (i) =>
                  !filteredSelected
                    .map((i) => i.productPrecio)
                    .includes(precioListaDraftId + i.value)
              );
            }
            setOptions(willAdd);
          }
        })
        .finally(() => setIsLoading(false));
    }

    onQueryEndChange();
  }, [precioLista, producto.precioLista.id, selected, debouncedSearchTerm]);

  function updateText(value) {
    setQueryValue(value);
    if (value === "") {
      // setOptions(deselectedOptions);
      return;
    }

    const filterRegex = new RegExp(value, "i");
    const resultOptions = deselectedOptions.current
      .filter(
        (i) =>
          !productos
            .map((l) => l.productPrecio)
            .includes(producto.precioLista.id + i.value)
      )
      .filter((option) => option.label.match(filterRegex));
    setOptions(resultOptions);
  }

  const updateSelection = useCallback((selected) => {
    setSelectedOptions(selected);
    const art = deselectedOptions.current.find((i) => i.value === selected[0]);
    setProducto((state) => ({ ...state, nombre: art.label, id: art.value }));
  }, []);

  function changeState(value, key) {
    setProducto((state) => ({ ...state, [key]: value }));
  }

  function addProductos() {
    onAddProducto(productos);
    togleModal();
  }

  function addProducto() {
    setOptions(options.filter((i) => i.value !== producto.id));
    setProductos((s) => [
      ...s,
      {
        ...producto,
        editing: false,
        productPrecio: producto.precioLista.id + producto.id,
      },
    ]);
    setProducto({
      porcentaje: "0",
      nombre: "",
      id: null,
      precioLista: producto.precioLista,
    });
  }

  function onSetEdit() {
    let arr = [...productos];
    const index = _.findIndex(arr, {
      id: producto.id,
      precioLista: { id: producto.precioLista.id },
    });
    arr.splice(index, 1, { ...producto, editing: false });
    setProductos(arr);
    setProducto({
      porcentaje: "0",
      nombre: "",
      id: null,
      precioLista: producto.precioLista,
    });
    setOptions(options.filter((i) => i.value !== producto.id));
    setIsEditing(false);
  }

  function onRemoveItem(item, productPrecio) {
    setProductos(productos.filter((i) => i.productPrecio !== productPrecio));
    const popDeselected = [...options];
    if (
      productos.find((i) => i.productPrecio === productPrecio)["precioLista"][
        "id"
      ] === producto.precioLista.id
    ) {
      popDeselected.push({
        label: productos.find((i) => i.id === item)["nombre"],
        value: item,
      });
    }
    setOptions(_.orderBy(popDeselected, ["label"], ["asc"]));
  }

  function onEditItem(item, precioListaId) {
    setIsEditing(true);
    setProducto((s) => ({
      ...s,
      nombre: productos.find(
        (i) => i.id === item && i.precioLista.id === precioListaId
      )["nombre"],
      id: productos.find(
        (i) => i.id === item && i.precioLista.id === precioListaId
      )["id"],
      porcentaje: productos.find(
        (i) => i.id === item && i.precioLista.id === precioListaId
      )["porcentaje"],
      precioLista: productos.find(
        (i) => i.id === item && i.precioLista.id === precioListaId
      )["precioLista"],
      productPrecio: productos.find(
        (i) => i.id === item && i.precioLista.id === precioListaId
      )["productPrecio"],
    }));
  }

  function onSetPrecioLista(preListId) {
    const prodFiltered = productos
      .filter((l) => l.precioLista.id === preListId)
      .map((s) => s.id);
    setOptions(
      deselectedOptions.current.filter((i) => !prodFiltered.includes(i.value))
    );
    setProducto((s) => ({
      ...s,
      precioLista: {
        nombre: preciosLista.find((i) => i.value === preListId)["label"],
        id: preListId,
      },
    }));
  }

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      prefix={<Icon source={SearchMajor} />}
      label="Productos"
      value={queryValue}
      disabled={isEditing}
      placeholder="Buscar producto"
      helpText={producto.nombre}
    />
  );

  return (
    <Modal
      title="Agregar productos"
      open={isActive}
      onClose={togleModal}
      secondaryActions={[{ content: "Cancelar", onAction: togleModal }]}
      primaryAction={{
        content: "Continuar",
        onAction: addProductos,
        disabled: productos.length === 0,
      }}
    >
      <Modal.Section>
        <Card sectioned>
          <FormLayout>
            <div className="stack-fillEvenly">
              <Stack distribution="fillEvenly" wrap>
                <Select
                  label="Lista de precio"
                  options={preciosLista}
                  disabled={isLoading || isEditing}
                  onChange={onSetPrecioLista}
                  value={producto.precioLista.id}
                />
                <Autocomplete
                  options={options}
                  selected={selectedOptions}
                  onSelect={updateSelection}
                  textField={textField}
                  willLoadMoreResults
                  listTitle="Productos existentes"
                  loading={isLoading}
                  emptyState="No hay productos"
                />
              </Stack>
            </div>
            <Stack distribution="fillEvenly" wrap>
              <TextField
                min={1}
                max={100}
                align="right"
                suffix="%"
                type="number"
                label="Valor del descuento"
                value={producto.porcentaje}
                onChange={(value) => changeState(value, "porcentaje")}
                onBlur={() =>
                  onBlurTextField(
                    producto.porcentaje,
                    changeState,
                    100,
                    "porcentaje"
                  )
                }
              />
              <Button
                monochrome
                onClick={isEditing ? onSetEdit : addProducto}
                disabled={Number(producto.porcentaje) === 0 || !producto.nombre}
              >
                {isEditing ? "Editar" : "Agregar"}
              </Button>
            </Stack>
          </FormLayout>
          {productos.length > 0 && (
            <DataTable
              headings={["Producto", "Lista de precios", "Descuento"]}
              columnContentTypes={["text", "text"]}
              rows={productos.map(
                ({ nombre, porcentaje, id, precioLista, productPrecio }) => {
                  const Activator = ({ onClick }) => {
                    return (
                      <div className="flex items-center">
                        <div className="mr-4">
                          <TextStyle>{porcentaje} % </TextStyle>
                        </div>
                        <Button
                          plain
                          icon={MobileHorizontalDotsMajor}
                          onClick={onClick}
                        />
                      </div>
                    );
                  };
                  const name = (
                    <TextStyle>
                      {nombre}{" "}
                      {producto.nombre === nombre &&
                      producto.precioLista.id === precioLista.id &&
                      isEditing ? (
                        <TextStyle variation="subdued">...editando</TextStyle>
                      ) : null}
                    </TextStyle>
                  );
                  const pre = <TextStyle>{precioLista.nombre}</TextStyle>;

                  const prcntj = (
                    <CustomPopover
                      items={[
                        {
                          content: "Editar",
                          onAction: () => onEditItem(id, precioLista.id),
                          icon: EditMajor,
                        },
                        {
                          content: "Quitar",
                          onAction: () => onRemoveItem(id, productPrecio),
                          icon: DeleteMajor,
                          destructive: true,
                        },
                      ]}
                      activator={Activator}
                    />
                  );
                  return [name, pre, prcntj];
                }
              )}
            />
          )}
        </Card>
        <Banner status="info">
          <p>
            Los clientes pueden combinar descuentos por promoción, por cliente,
            por artículo y por volumen.
          </p>
        </Banner>
      </Modal.Section>
    </Modal>
  );
}
