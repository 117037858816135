import React, { useCallback, useEffect, useState, useRef } from "react";
import { Page, Stack } from "@shopify/polaris";
import { AnalyticsMajor } from "@shopify/polaris-icons";
import jwt from "jsonwebtoken";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentVisitors, getUserInfo } from "../../reducers";
import { HelpFooter, Layer, ReportSheet, TodayMetrics } from "../../components";
import Colors from "../../styles/Colors";
import { NumberFormat } from "../../utils/Formats";
import "./DashboardComercioScreenStyles.css";
import ApiServiceReportes from "../../services/ApiServiceReportes";
import NormalizeById from "../../utils/NormalizedById";
import GroupDataByDate from "../../utils/GroupDataByDate";
import { clearAll } from "../../actions/AuthActions";

const classes =
  "px-8 py-16 flex flex-col text-center justify-center items-center";

export default function DashboardComercioScreeen({ forbidden }) {
  const dispatch = useDispatch();
  const { name, last_name } = useSelector(getUserInfo);
  const [sheetActive, setSheetActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pedidos, setPedidos] = useState([]);
  const [grouped, setGrouped] = useState([]);
  const [activeFilter, setActiveFilter] = useState("Hoy");
  const [sesiones, setSesiones] = useState(0);
  const [articulos, setArticulos] = useState([]);

  const grant_type = useSelector((state) => state.Auth.grant_type);
  const visitors = useSelector(getCurrentVisitors);
  const selectedDatesRef = useRef();

  const [selectedDates, setSelectedDates] = useState({
    start: moment().format(),
    end: moment().format(),
  });

  let token = useSelector((state) => state.Auth.access_token);

  useEffect(() => {
    selectedDatesRef.current = selectedDates;
  });

  useEffect(() => {
    if (token) {
      jwt.verify(token, process.env.REACT_APP_SECRET_SEED, (error, decoded) => {
        if (error) {
          dispatch(clearAll());
        }
      });
    }

    return () => null;
  }, [grant_type, token, dispatch]);

  useEffect(() => {
    ApiServiceReportes.obtenerDashboardComercio({
      fecha: selectedDatesRef.current,
    })
      .then(({ pedidos, sesiones, articulos }) => {
        setArticulos(articulos);
        setPedidos(pedidos);
        setSesiones(sesiones);
        let data = NormalizeById(pedidos, "_id");
        let group = GroupDataByDate(data);
        setGrouped(group);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const toggleSheetActive = useCallback(
    () => setSheetActive((sheetActive) => !sheetActive),
    []
  );

  function selectDates(values) {
    setSelectedDates(values);
    setIsLoading(true);
    ApiServiceReportes.obtenerDashboardComercio({ fecha: values })
      .then(({ ok, pedidos, sesiones, articulos }) => {
        if (ok) {
          setArticulos(articulos);
          setPedidos(pedidos);
          setSesiones(sesiones);
          let data = NormalizeById(pedidos, "_id");
          let group = GroupDataByDate(data);
          setGrouped(group);
        }
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <Layer title="Inicio" forbidden={forbidden}>
      <Page
        title={`Buen día, ${name} ${last_name}.`}
        subtitle={`Esto es lo que ha pasado en tu comercio ${activeFilter.toLowerCase()}.`}
        secondaryActions={[
          {
            icon: AnalyticsMajor,
            content: "Ver reporte",
            target: "REMOTE",
            onAction: toggleSheetActive,
          },
        ]}
      >
        {sheetActive && (
          <ReportSheet
            isLoading={isLoading}
            setSelectedDates={selectDates}
            subtitle={`${moment(selectedDates.start).format(
              "MMM D"
            )} - ${moment(selectedDates.end).format("D")}`}
            graphics={[
              {
                data: grouped.map((i) => ({
                  name: moment(i.date).format("MMM D"),
                  pedidos: i.collection.length,
                  total: i.collection
                    .map((i) => i.total)
                    .reduce((total, cu) => total + cu, 0),
                })),
                title: "Total de ventas",
                total: `$${Number(
                  pedidos
                    .map((i) => i.total)
                    .reduce((total, cu) => total + cu, 0) || 0
                ).toLocaleString("en", NumberFormat)}`,
                value: `${pedidos.length} pedidos`,
                color: "greenCake",
                variant: "number",
                dataKey: "pedidos",
              },
            ]}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            sheetActive={sheetActive}
            toggleSheetActive={toggleSheetActive}
            title="Top Artículos"
            topArticulos={articulos}
          />
        )}
        <div className="mx-8 sm:mx-0 md:mx-0">
          <Stack distribution="fillEvenly" wrap spacing="none">
            <TodayMetrics
              classes={classes}
              heading={`Ventas ${activeFilter.toLowerCase()}`}
              isLoading={isLoading}
              metric={`$${Number(
                pedidos
                  .map((i) => i.total)
                  .reduce((total, cu) => total + cu, 0) || 0
              ).toLocaleString("en", NumberFormat)} MXN`}
            />
            <TodayMetrics
              classes={classes}
              heading={`Pedidos ${activeFilter.toLowerCase()}`}
              isLoading={isLoading}
              metric={pedidos.length}
            />
            <TodayMetrics
              classes={classes}
              heading={`Visitas ${activeFilter.toLowerCase()}`}
              isLoading={isLoading}
              metric={sesiones}
            />
            <TodayMetrics
              classes={classes}
              heading="Visitantes ahora"
              metric={visitors}
              isLast
              color={Colors.purple}
            />
          </Stack>
        </div>
        <HelpFooter
          title="inicio"
          url="https://help.b2bgo.mx/guia-de-uso/inicio"
        />
      </Page>
    </Layer>
  );
}
