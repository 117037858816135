import React, { useEffect, useState } from 'react'
import { Card, Checkbox, FormLayout, Layout, Page, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, SkeletonThumbnail, Stack } from '@shopify/polaris'
import { ConfigButton, HelpFooter, Layer } from '../../components'
import ApiServiceComercios from '../../services/ApiServiceComercios'
import { useDispatch } from 'react-redux'
import { toggleToast } from '../../actions/InteractiveActions'
import { useHistory } from 'react-router-dom'

export default function ImpuestosScreen({ forbidden }) {
   const dispatch = useDispatch();
   const router = useHistory();
   const [isLoading, setIsLoading] = useState(true);
   const [isEditing, setIsEditing] = useState(false);
   const [impuestoDB, setImpuesto] = useState({ nombre: "", cobrarEnvio: false, mostrarPrecioConImpuesto: false, impuesto: 0 });
   useEffect(() => {
      ApiServiceComercios.obtenerImpuestoComercio()
         .then(({ ok, impuesto }) => {
            if (ok) {
               setImpuesto({
                  nombre: impuesto.nombre,
                  cobrarEnvio: impuesto.cobrarEnvio,
                  mostrarPrecioConImpuesto: impuesto.mostrarPrecioConImpuesto,
                  impuesto: impuesto.impuesto
               });
            }
         }).finally(() => {
            setIsLoading(false);
         });
   }, []);

   function onChangeState(value, key) {
      setIsEditing(true);
      ApiServiceComercios.actualizarImpuestoComercio({ ...impuestoDB, [key]: value })
         .then(({ ok, message }) => {
            if (ok) {
               dispatch(toggleToast({ message }));
               setImpuesto((s) => ({ ...s, [key]: value }));
            }
         }).finally((err) => {
            setIsEditing(false);
         });
   }

   if (isLoading) {
      return (
         <Layer title="Envío y entrega" forbidden={forbidden}>
            <SkeletonPage
               breadcrumbs
               title={<SkeletonDisplayText size="medium" />}
               primaryAction={<SkeletonDisplayText size="medium" />}
            >
               <Layout>
                  <Layout.AnnotatedSection title={<SkeletonDisplayText size="medium" />} description={<SkeletonBodyText lines={2} />} >
                     {Array.from(Array(1).keys()).map((item) => {
                        return (
                           <div key={item} className="mb-8">
                              <Card sectioned  >
                                 <Stack>
                                    <SkeletonThumbnail size="small" />
                                    <div style={{ minWidth: 300 }}>
                                       <SkeletonBodyText lines={2} />
                                    </div>
                                 </Stack>
                              </Card>
                           </div>
                        )
                     })}
                  </Layout.AnnotatedSection>
               </Layout>
            </SkeletonPage>
         </Layer>
      );
   }

   return (
      <Layer title="Impuestos" forbidden={forbidden}>
         <Page
            separator
            title="Impuestos"
            breadcrumbs={[{ content: "Configuración", url: '/admin/configuracion' }]}
         >
            <Layout>
               <Layout.AnnotatedSection
                  title="Impuestos"
                  description="Registra los diferentes impuestos que se aplican a los productos y servicios al venderlos."
               >
                  <Card>
                     <ConfigButton
                        onClick={() => router.push("/admin/configuracion/impuestos/detalle")}
                        fullWidth
                        text={impuestoDB.nombre}
                        secondary={`${impuestoDB.impuesto}%`}
                        icon="BillingStatementDollarMajor"
                     />
                  </Card>
               </Layout.AnnotatedSection>
               <Layout.AnnotatedSection
                  title="Cálculos de impuestos"
                  description="Administra la forma de calcular y de mostrar los impuestos en tu tienda."
               >
                  <Card sectioned>
                     <FormLayout>
                        <Checkbox
                           label="Mostrar los precios con los impuestos incluidos"
                           helpText="Los impuestos están incluidos en el precio que ve el cliente"
                           disabled={isEditing}
                           onChange={(value) => onChangeState(value, "mostrarPrecioConImpuesto")}
                           checked={impuestoDB.mostrarPrecioConImpuesto}
                        />
                        <Checkbox
                           label="Cobrar impuestos en tarifas de envío"
                           helpText="Incluye las tarifas de envío en el cálculo de impuestos"
                           disabled={isEditing}
                           onChange={(value) => onChangeState(value, "cobrarEnvio")}
                           checked={impuestoDB.cobrarEnvio}
                        />
                     </FormLayout>
                  </Card>
               </Layout.AnnotatedSection>
            </Layout>
            <HelpFooter
               title="impuestos"
               url="https://help.b2bgo.mx/configuracion/impuestos"
            />
         </Page>
      </Layer>
   )
}
