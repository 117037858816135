import { AppProvider } from "@shopify/polaris";
import esTranslations from "@shopify/polaris/locales/es.json";
import "antd/dist/antd.css";
import dayjs from "dayjs";
import "dayjs/locale/es-mx";
import React from "react";
import { Provider } from "react-redux";
import "react-sortable-tree/style.css";
import { PersistGate } from "redux-persist/lib/integration/react";
import ApiInitializerContainer from "./app/containers/ApiInitializerContainer";
import Router from "./app/routers/Router";
import store, { persistor } from "./app/Store";
import Link from "./app/utils/Link";
import "./locales/moment-es";
dayjs.locale("es-mx");

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor ? persistor : {}}>
        <ApiInitializerContainer>
          {({ theme }) => {
            return (
              <AppProvider
                linkComponent={Link}
                theme={theme}
                i18n={esTranslations}
              >
                <Router />
              </AppProvider>
            );
          }}
        </ApiInitializerContainer>
      </PersistGate>
    </Provider>
  );
}

export default App;
