import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  FormLayout,
  Heading,
  Icon,
  Modal,
  Select,
  SkeletonBodyText,
  Stack,
  TextContainer,
  TextStyle,
  TextField,
  Thumbnail,
  Tooltip,
} from "@shopify/polaris";
import {
  CircleInformationMajor,
  TickMinor,
  MobileCancelMajor,
} from "@shopify/polaris-icons";
import ApiServiceComercios from "../../../services/ApiServiceComercios";
import { useDispatch, useSelector } from "react-redux";
import { NumberFormat } from "../../../utils/Formats";
import { addShoppingCartArticles } from "../../../actions/ShoppingCartActions";
import { notification } from "antd";
import {
  getMaxSelectabledProducts,
  getProductValidations,
} from "../../../utils/orderValidations";
import { getDiscountsShoppingCart } from "../../../reducers";
import { getImageVideoUrl } from "../../../utils/productValidators";

export default function AddVariantItemModal({
  isOpen,
  onClose,
  id,
  almacenId,
  showTax,
  discounts,
  canShowStock = true,
}) {
  const dispatch = useDispatch();
  const { discounts: discountsForProduct = [], priceToUse = null } =
    useSelector(getDiscountsShoppingCart);
  const onChangeOptionRef = useRef(null);
  const [itemData, setItemData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [percentage, setPercentage] = useState(0);
  const [precio, setPrecio] = useState(itemData?.precios[0]?.precio);
  const [quantity, setQuantity] = useState("1");
  const [variantSelected, setVariantSelected] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    option5: "",
    option6: "",
    option7: "",
    option8: "",
    option9: "",
    option10: "",
  });
  const [tooltipVisible, setTooltipVisible] = useState(true);

  const { descuentoPromocion, descuentoVolumen, descuentoMaximo } = discounts;

  function onChangeOption(value, option, item) {
    const itemDat = item ? item : itemData;
    let {
      existencia = [],
      imagen = [],
      precios,
      unidadmed = "",
      nombre = "",
      _id,
      linea_articulo = "",
      options = [],
      variants = [],
      isParent,
      impuestos = [],
      sku = "",
      factor_venta = 1,
      minimum = 1,
      maximum = 1,
    } = itemDat;

    // VALIDATION FOR VARIANTS
    if (isParent) {
      let firstOptions = {};

      if (value && option) {
        firstOptions = { ...variantSelected };
        firstOptions[option] = value;
      } else {
        options.forEach(
          (i, idx) => (firstOptions[`option${idx + 1}`] = i.values[0])
        );
      }

      setVariantSelected(firstOptions);

      let matches = [];

      for (const key in firstOptions) {
        if (Object.hasOwnProperty.call(firstOptions, key)) {
          const element = firstOptions[key];
          let matched = [];
          if (matches.length === 0) {
            matched = variants.filter(
              (i) => i.hasOwnProperty(key) && i[key] === element
            );
          } else {
            matched = matches.filter(
              (i) => i.hasOwnProperty(key) && i[key] === element
            );
          }
          matches = matched;
        }
      }

      const {
        variantId: {
          _id: v_Id = "",
          nombre: vNombre = "",
          existencia: vExistencia = [],
          imagen: vImagen = [],
          precios: vPrecios = [],
          unidadmed: vUnidadmed = "",
          linea_articulo: vLinea_articulo = "",
          impuestos: vImpuestos = [],
          sku: vSku = "",
          factor_venta: vFactor_venta = 1,
          minimum: vMinimum = 1,
          maximum: vMaximum = 1,
        },
      } = matches[0];

      _id = v_Id;
      nombre = vNombre;
      existencia = vExistencia;
      imagen = vImagen;
      precios = vPrecios;
      unidadmed = vUnidadmed;
      linea_articulo = vLinea_articulo;
      impuestos = vImpuestos;
      sku = vSku;
      factor_venta = vFactor_venta;
      minimum = vMinimum;
      maximum = vMaximum;

      // Validate existence for assigned almacen_id
      existencia = existencia.filter(
        (i) => Number(i.almacen_id) === Number(almacenId)
      );

      setItemData({
        _id,
        id: _id,
        linea_articulo,
        isParent: true,
        nombre: nombre,
        existencia: existencia,
        imagen: imagen,
        precios: precios,
        unidadmed: unidadmed,
        impuestos: impuestos,
        variants,
        options,
        sku,
        factor_venta,
        minimum,
        maximum,
      });
    }

    const { percentDefault, price } = getProductValidations({
      product: {
        _id,
        precios,
        linea_articulo,
      },
      descMaximos: descuentoMaximo,
      descPromoAplicada: descuentoPromocion,
      descVolumenAplicada: descuentoVolumen,
      discounts: discountsForProduct,
      priceToUse,
      quantity,
    });

    setPrecio(price);
    setPercentage(percentDefault);
    validateMinimumField({ factor_venta, minimum, maximum });
    setIsLoading(false);
  }

  useEffect(() => {
    onChangeOptionRef.current = onChangeOption;
  });

  useEffect(() => {
    function fetchArticle() {
      ApiServiceComercios.obtenerArticulo(id)
        .then(({ articulo }) => {
          try {
            const obj = { ...articulo };
            if (obj.isParent) {
              obj["variants"] = obj.variants.filter((j) => {
                if (j) {
                  const { variantId = {} } = j;
                  let {
                    existencia: existenciaVar = [],
                    continuar_vendiendo = false,
                    sales_channels = {},
                  } = variantId || {};
                  const { online_store = false } = sales_channels;

                  existenciaVar = existenciaVar.filter(
                    (i) => i.almacen_id === Number(almacenId)
                  );

                  let ex = existenciaVar.reduce(
                    (prev, current) => prev + current.existencia,
                    0
                  );

                  ex = ex > 0;
                  return online_store && (ex || continuar_vendiendo);
                } else {
                  return false;
                }
              });
              if (obj["variants"].length === 0) return null;
              const optionValues = [];

              obj.variants.forEach((variant) => {
                Array.from({ length: 10 }, (_, index) => index + 1).forEach(
                  (value) => {
                    if (variant.hasOwnProperty(`option${value}`)) {
                      optionValues.push(variant[`option${value}`]);
                    }
                  }
                );
              });

              const newOptions = obj.options.map((opt) => ({
                ...opt,
                values: opt.values.filter((i) => optionValues.includes(i)),
              }));
              obj["options"] = newOptions;
            }

            onChangeOptionRef.current(null, null, obj);
          } catch (error) {}
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }

    fetchArticle();
  }, [descuentoMaximo, descuentoPromocion, descuentoVolumen, almacenId, id]);

  // Valida si el articulo tiene un mínimo y un factor de venta y asi mostrar u ocultar el tooltip
  function validateMinimumField(itemData) {
    if (!itemData?.minimum) {
      setTooltipVisible(false);
    } else {
      if (itemData?.minimum === 1 && itemData?.factor_venta === 1) {
        setTooltipVisible(false);
      } else {
        setTooltipVisible(true);
      }
    }
  }

  function getPercentage() {
    // Porcentaje de descuento, sólo para mostrarlo al cliente en la interfaz
    let percent = (1 - getDiscount() / precio) * 100;
    return Math.round((percent + Number.EPSILON) * 100) / 100;
  }

  function validatePriceWithTaxMain() {
    let price = precio;
    let impuestos = itemData?.impuestos?.filter((j) => j.porcentaje > 0) || [];
    let summary = 0;
    impuestos.forEach((j) => (summary = summary + 1 * (j.porcentaje / 100)));
    const tax = 1 + summary;
    price = showTax ? price * tax : price;
    price = Math.round((price + Number.EPSILON) * 100) / 100;

    return Number(price || 0).toLocaleString("en", NumberFormat);
  }

  const onBlurTextField = () => {
    const existencia = itemData?.existencia.reduce(
      (prev, current) => prev + current.existencia,
      0
    );
    if (String(quantity) === "" || Number(quantity) <= 0) {
      setQuantity("1");
      return;
    }

    if (existencia > 0 && !itemData?.continuar_vendiendo) {
      if (Number(quantity) > existencia) setQuantity(String(existencia));
      return;
    }
  };

  function handleFieldChange(value) {
    setQuantity(value);
  }

  function getDiscount() {
    let discount =
      percentage > 0 ? precio - precio * (percentage / 100) : precio;
    return discount;
  }

  function validatePriceWithTax() {
    let price = getDiscount();
    let impuestos = itemData?.impuestos?.filter((j) => j.porcentaje > 0) || [];
    let summary = 0;
    impuestos.forEach((j) => (summary = summary + 1 * (j.porcentaje / 100)));
    const tax = 1 + summary;
    price = showTax ? price * tax : price;
    price = Math.round((price + Number.EPSILON) * 100) / 100;

    return Number(price || 0).toLocaleString("en", NumberFormat);
  }

  function onAddArticle() {
    const itemToAdd = {
      article: itemData,
      cantidad: Number(quantity),
      descuentoMaximo,
      descuentoVolumen,
      descuentoPromocion,
      showTax,
      discounts: discountsForProduct,
      priceToUse,
    };

    notification.open({
      message: <TextStyle>Producto agregado en tu pedido</TextStyle>,
      description: (
        <div className="flex flex-row mt-4">
          <img
            alt=""
            style={{ width: 70, height: 70 }}
            src={
              getImageVideoUrl(itemData?.imagen, "image", false) ||
              "/Default Photo.png"
            }
          />
          <div className="flex flex-col ml-6">
            <TextStyle>{itemData?.nombre}</TextStyle>
            <TextStyle>
              {itemData.isParent ? itemData.sku || "" : itemData.clave}
            </TextStyle>
          </div>
        </div>
      ),
      duration: 1.5,
      icon: <Icon source={TickMinor} />,
      closeIcon: <Icon source={MobileCancelMajor} />,
    });

    setQuantity("1");
    dispatch(addShoppingCartArticles(itemToAdd));
    onClose();
  }

  if (isLoading) {
    return (
      <Modal open={isOpen} title="Seleccionar productos">
        <Card subdued>
          <Card.Section>
            <TextContainer>
              <SkeletonBodyText lines={3} />
            </TextContainer>
          </Card.Section>
        </Card>
      </Modal>
    );
  }

  let {
    existencia = [],
    unidadmed = "",
    continuar_vendiendo,
    options = [],
    maximum = undefined,
  } = itemData || {};

  return (
    <Modal
      size="Small"
      title="Seleccionar productos"
      open={isOpen}
      onClose={onClose}
      primaryAction={{ content: "Agregar al pedido", onAction: onAddArticle }}
      secondaryActions={[{ content: "Cancelar", onAction: onClose }]}
    >
      <Card sectioned>
        <FormLayout>
          <div className="flex">
            <div className="mr-8">
              <Thumbnail
                size="large"
                source={
                  getImageVideoUrl(itemData?.imagen, "image", false) ||
                  "/Default Photo.png"
                }
              />
            </div>
            <Stack vertical>
              <Heading>{itemData?.nombre}</Heading>
              <Stack>
                <TextStyle variation={getPercentage() > 0 ? "negative" : null}>
                  ${validatePriceWithTax()}
                </TextStyle>
                {getPercentage() > 0 ? (
                  <div className="flex">
                    <div className="mr-6 line-through">
                      <TextStyle variation="subdued">
                        ${validatePriceWithTaxMain()}
                      </TextStyle>
                    </div>
                    <TextStyle variation="subdued">
                      {" "}
                      {`(${getPercentage()}%)`}
                    </TextStyle>
                  </div>
                ) : null}
              </Stack>
              {existencia
                .filter((i) => String(i.almacen_id) === String(almacenId))
                .map((i, index) => (
                  <div key={index}>
                    {i?.existencia > 0 ? (
                      <TextStyle>
                        {canShowStock
                          ? `${i?.existencia.toLocaleString(
                              "es-US"
                            )} disponibles en `
                          : "Disponible en "}
                      </TextStyle>
                    ) : (
                      <TextStyle>Bajo pedido en </TextStyle>
                    )}
                    <TextStyle variation="strong">
                      {i?.nombre_almacen}
                    </TextStyle>
                  </div>
                ))}
            </Stack>
          </div>

          <Stack vertical>
            {options.map((i, idx) => (
              <Select
                key={idx}
                label={i.name}
                options={i.values.map((j) => ({ label: j, value: j }))}
                onChange={(value) => {
                  onChangeOption(value, `option${idx + 1}`);
                }}
                value={variantSelected[`option${idx + 1}`]}
              />
            ))}
            <TextField
              type="number"
              min={1}
              max={getMaxSelectabledProducts(
                existencia,
                maximum,
                continuar_vendiendo
              )}
              value={quantity}
              onChange={handleFieldChange}
              onBlur={onBlurTextField}
              suffix={
                <div className="flex items-center">
                  {unidadmed}
                  {tooltipVisible && (
                    <div className="tooltip-space cursor-pointer">
                      <Tooltip
                        content={
                          <>
                            <p>{`Mínimo: ${itemData?.minimum}`}</p>
                            <p>{`Máximo: ${itemData?.maximum}`}</p>
                            <p>{`Factor: ${itemData?.factor_venta}`}</p>
                          </>
                        }
                        preferredPosition="mostSpace"
                      >
                        <Icon
                          source={CircleInformationMajor}
                          color="inkLightest"
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
              }
            />
          </Stack>
        </FormLayout>
      </Card>
    </Modal>
  );
}
