import React, { useCallback, useEffect, useState } from "react";
import {
  ButtonGroup,
  Button,
  Layout,
  Page,
  ContextualSaveBar,
  Card,
  Checkbox,
  Collapsible,
  TextStyle,
  Link,
  Banner,
  FormLayout,
} from "@shopify/polaris";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { HelpFooter, Layer } from "../../components";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { toggleToast } from "../../actions/InteractiveActions";
import { Timezone } from "../../utils/Timezone";
import ScheduleItem from "./ScheduleItem";
import { getZonaHoraria } from "../../reducers";

const PreferencesScreen = ({ forbidden }) => {
  const router = useHistory();
  const dispatch = useDispatch();

  const timezoneSelector = useSelector(getZonaHoraria) || "America/Mexico_City";
  const store_timezone = Timezone.find(
    (timezone) => timezone.id === timezoneSelector
  );

  const [wasEdited, setWasEdited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [preferences, setPreferences] = useState({
    verifyLimitExceeded: false,
    showReferrals: false,
    useSchedule: false,
    schedules: [],
    allowDownloadAccountStatement: false,
    allowDownloadBalanceAge: false,
  });

  const handleChange = useCallback((value, field) => {
    setWasEdited(true);
    setPreferences((prevState) => ({ ...prevState, [field]: value }));
  }, []);

  const handleChangeDate = useCallback(
    (value, schedule, index) => {
      const updateSchedules = preferences.schedules.map((item) => {
        if (item.dayOfWeek === schedule.dayOfWeek) {
          return { ...item, [index]: value };
        }
        return item;
      });
      setPreferences((prevState) => ({
        ...prevState,
        schedules: updateSchedules,
      }));
    },
    [preferences]
  );

  useEffect(() => {
    ApiServiceComercios.obtenerPreferenciasComercio().then(
      ({ preferencia }) => {
        const {
          verifyLimitExceeded = false,
          showReferrals = false,
          useSchedule = false,
          schedules = [],
          allowDownloadAccountStatement = false,
          allowDownloadBalanceAge = false,
        } = preferencia;
        setPreferences({
          verifyLimitExceeded,
          showReferrals,
          useSchedule,
          schedules,
          allowDownloadAccountStatement,
          allowDownloadBalanceAge,
        });
      }
    );
  }, []);

  const handleSavePreferences = () => {
    setIsLoading(true);
    ApiServiceComercios.setPreferenciasComercio(preferences).then(() => {
      setIsLoading(true);
      dispatch(toggleToast({ message: "Preferencias actualizadas" }));
      handleDiscard();
    });
  };

  const onAdd = useCallback((dayOfWeek) => {
    setPreferences((prevState) => ({
      ...prevState,
      schedules: prevState.schedules.map((schedule) =>
        schedule.dayOfWeek === dayOfWeek
          ? { ...schedule, start: "00:00", end: "23:59" }
          : schedule
      ),
    }));
  }, []);
  const onRemove = useCallback((dayOfWeek) => {
    setPreferences((prevState) => ({
      ...prevState,
      schedules: prevState.schedules.map((schedule) =>
        schedule.dayOfWeek === dayOfWeek
          ? { ...schedule, start: "", end: "" }
          : schedule
      ),
    }));
  }, []);
  const onApply = useCallback(
    (dayOfWeek) => {
      const currentSchedule = preferences.schedules.find(
        (schedule) => schedule.dayOfWeek === dayOfWeek
      );
      let schedules = preferences.schedules.map((schedule) => ({
        ...schedule,
        start: currentSchedule.start,
        end: currentSchedule.end,
      }));
      setPreferences((prevState) => ({
        ...prevState,
        schedules,
      }));
    },
    [preferences]
  );

  function handleDiscard() {
    router.push("/admin/configuracion");
  }

  const contextualSave = wasEdited ? (
    <ContextualSaveBar
      message="Cambios no guardados"
      saveAction={{
        onAction: () => handleSavePreferences(),
        disabled:
          isLoading ||
          (preferences.useSchedule &&
            !preferences.schedules.some(({ start }) => start)),
        loading: isLoading,
      }}
      discardAction={{
        onAction: handleDiscard,
        disabled: isLoading,
      }}
    />
  ) : null;

  return (
    <Layer title="Preferencias" forbidden={forbidden}>
      <Page
        title="Preferencias"
        breadcrumbs={[
          { content: "Configuración", url: "/admin/configuracion" },
        ]}
        separator
      >
        {contextualSave}
        <Layout>
          <Layout.AnnotatedSection
            title="Políticas de crédito"
            description="Gestiona las opciones de crédito de tus clientes."
          >
            <Card sectioned>
              <Checkbox
                label="Verificar el límite de crédito excedido"
                helpText="Verifica el límite de crédito de los clientes al registrar los pedidos."
                checked={preferences.verifyLimitExceeded}
                onChange={(value) => handleChange(value, "verifyLimitExceeded")}
              />
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title="Descarga de facturas"
            description="Gestiona los tipos de documentos que pueden descargar tus clientes."
          >
            <Card sectioned>
              <FormLayout>
                <Checkbox
                  label="Permite descargar remisiones"
                  helpText="Incluye las remisiones en las descargas en formato PDF en el portal del cliente."
                  checked={preferences.showReferrals}
                  onChange={(value) => handleChange(value, "showReferrals")}
                />
                <Checkbox
                  label="Permite descargar estado de cuenta"
                  helpText="Tus clientes pueden descargar una versión detallada de su estado de cuenta en formato PDF."
                  checked={preferences.allowDownloadAccountStatement}
                  onChange={(value) =>
                    handleChange(value, "allowDownloadAccountStatement")
                  }
                />
                <Checkbox
                  label="Permite descargar antigüedad de saldo"
                  helpText="Tus clientes pueden descargar un reporte con información de los saldos vencidos en formato PDF."
                  checked={preferences.allowDownloadBalanceAge}
                  onChange={(value) =>
                    handleChange(value, "allowDownloadBalanceAge")
                  }
                />
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title="Disponibilidad"
            description="Configura el horario de disponibilidad de tu tienda en línea de mayoreo. De forma predeterminada, dicho horario se establece como siempre disponible."
          >
            <Card sectioned>
              <FormLayout>
                <Checkbox
                  id="collapsible"
                  name="collapsible"
                  label="Configurar disponibilidad"
                  helpText="Indica el horario de disponibilidad por cada día"
                  checked={preferences.useSchedule}
                  onChange={(value) => handleChange(value, "useSchedule")}
                />

                <Collapsible
                  open={preferences.useSchedule}
                  id="basic-collapsible"
                  transition={{
                    duration: "500ms",
                    timingFunction: "ease-in-out",
                  }}
                  expandOnPrint
                >
                  {preferences.schedules.map((schedule, key) => (
                    <ScheduleItem
                      schedule={schedule}
                      key={key}
                      handleChangeDate={handleChangeDate}
                      onAdd={onAdd}
                      onApply={onApply}
                      onRemove={onRemove}
                    />
                  ))}
                  <Banner>
                    <TextStyle>
                      La{" "}
                      <Link url="/admin/configuracion/general">
                        zona horaria
                      </Link>{" "}
                      de tu tienda es {store_timezone?.codigo_zona}{" "}
                      {store_timezone?.location}
                    </TextStyle>
                  </Banner>
                </Collapsible>
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection>
            <div className="flex justify-end">
              <ButtonGroup fullWidth={false} connectedTop={false}>
                <Button onClick={handleDiscard} disabled={isLoading}>
                  Descartar
                </Button>
                <Button
                  primary
                  disabled={
                    isLoading ||
                    (preferences.useSchedule &&
                      !preferences.schedules.some(({ start }) => start))
                  }
                  isLoading={isLoading}
                  onClick={handleSavePreferences}
                >
                  Guardar
                </Button>
              </ButtonGroup>
            </div>
          </Layout.AnnotatedSection>
        </Layout>
        <HelpFooter
          title="preferencias"
          url="https://help.b2bgo.mx/configuracion/preferencias"
        />
      </Page>
    </Layer>
  );
};

export default PreferencesScreen;
