import { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import useGetElementSizes from "../../hooks/useGetElementSizes";
import "./SectionsStyled.css";
import _ from "lodash";

export default function BannerSliderStyled({ section }) {
  const [isMobile, setIsMobile] = useState(false);
  const { properties } = section;
  const { measuredRef, width } = useGetElementSizes();
  const containerSize = {
    small: "320px",
    medium: "460px",
    large: "620px",
  };

  useEffect(() => {
    setIsMobile(width <= 768);
  }, [width]);

  const getStyles = (item, field) => {
    const styles = {
      "text-banner-container": {
        background: item.backgroundColor,
        color: item.textColor,
        opacity:
          Number(item.opacity && !_.isNaN(item.opacity) ? item.opacity : 100) /
          100,
      },
      "banner-title": {
        color: item.textColor,
      },
      "banner-button": {
        background: item.buttonColor,
        color: item.textButtonColor,
      },
      upToLeft: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
      },
      upToCenter: {
        justifyContent: "flex-start",
        alignItems: "center",
      },
      upToRight: {
        justifyContent: "flex-start",
        alignItems: "flex-end",
      },
      centerToLeft: {
        justifyContent: "center",
        alignItems: "flex-start",
      },
      centerToCenter: {
        justifyContent: "center",
        alignItems: "center",
      },
      centerToRight: {
        justifyContent: "center",
        alignItems: "flex-end",
      },
      bottomToLeft: {
        justifyContent: "flex-end",
        alignItems: "flex-start",
      },
      bottomToCenter: {
        justifyContent: "flex-end",
        alignItems: "center",
      },
      bottomToRight: {
        justifyContent: "flex-end",
        alignItems: "flex-end",
      },
    };

    return styles[field];
  };

  return (
    <div
      className="flex w-full"
      style={{ height: isMobile && properties.hideOnMobile ? 1 : "auto" }}
      ref={measuredRef}
    >
      <div
        className="flex justify-center"
        style={{
          display: isMobile && properties.hideOnMobile ? "none" : "flex",
        }}
      >
        {section.subNavigationItems.filter(({ visible }) => visible).length >
          0 && (
          <div
            className="flex justify-center w-full text-center"
            style={{
              minHeight: containerSize[section.properties.sliderHeight],
              height: containerSize[section.properties.sliderHeight],
              maxWidth: "100vw",
            }}
          >
            <Carousel
              showThumbs={false}
              infiniteLoop
              interval={section.properties.sliderTime * 1000}
              autoPlay={section.properties.rotateSliders}
              transitionTime={500}
              showStatus={false}
              emulateTouch
            >
              {section.subNavigationItems
                .filter(({ visible }) => visible)
                .map((item, key) => (
                  <div key={key} className="h-full flex relative">
                    <img
                      className="object-cover"
                      src={item.src}
                      alt={item.title}
                    />
                    <div
                      className="text-banner-container relative flex flex-row"
                      style={{ ...getStyles(item, item.position) }}
                    >
                      <div
                        className="absolute inset-0"
                        style={{ ...getStyles(item, "text-banner-container") }}
                      />
                      <div className="block flex-col z-10 m-10">
                        <p
                          className="banner-title z-10"
                          style={{ ...getStyles(item, "banner-title") }}
                        >
                          {item.title}
                        </p>
                        <p
                          className="my-10 banner-subtitle z-10"
                          style={{ ...getStyles(item, "banner-title") }}
                        >
                          {item.subtitle}
                        </p>
                        {item.textButton && (
                          <button
                            className="banner-button z-10 font-semibold"
                            onClick={() =>
                              window.open(item.urlButton, "_blank")
                            }
                            style={{ ...getStyles(item, "banner-button") }}
                          >
                            {item.textButton}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </Carousel>
          </div>
        )}
      </div>
    </div>
  );
}
