import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  EmptyState,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonPage,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { FooterCliente, Layer } from "../../components";
import ApiServiceComercios from "../../services/ApiServiceComercios";

export default function PoliticasScreen({ forbidden }) {
  const [isLoading, setIsLoading] = useState(true);
  const [contenido, setContenido] = useState({
    titulo: "",
    contenido: "",
    descripcion: "",
  });
  const { id } = useParams();
  const pages = useRef({
    "terminos-y-condiciones": {
      title: "Términos y condiciones",
    },
    "politica-de-envios": {
      title: "Política de envíos",
    },
    "politica-de-privacidad": {
      title: "Política de privacidad",
    },
    "politica-de-cobros": {
      title: "Política de cobros",
    },
  });

  useEffect(() => {
    function obtenerPagina() {
      ApiServiceComercios.obtenerPagina(pages.current[id].title)
        .then(({ pagina: { titulo, contenido, descripcion } }) => {
          setContenido({ ...contenido, titulo, contenido, descripcion });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    if (pages.current.hasOwnProperty(id)) {
      obtenerPagina();
    } else {
      setIsLoading(false);
    }
  }, [id]);

  if (!pages.current.hasOwnProperty(id)) {
    return (
      <Layer title={`404 No encontrado - B2BGo`} forbidden={forbidden}>
        <EmptyState
          heading="Oops! Hubo un problema al intentar obtener esta política"
          action={{
            content: "Volver a intentar",
            onAction: () => window.location.reload(),
          }}
          image="/Ad.svg"
        >
          <p>Intenta de nuevo más tarde</p>
        </EmptyState>
      </Layer>
    );
  }

  if (isLoading) {
    return (
      <Layer title={pages.current[id]?.title} forbidden={forbidden}>
        <SkeletonPage fullWidth>
          <Layout>
            <Layout.Section>
              <Card sectioned>
                <SkeletonBodyText lines={10} />
              </Card>
            </Layout.Section>
          </Layout>
        </SkeletonPage>
      </Layer>
    );
  }

  return (
    <Layer title={pages.current[id]?.title} forbidden={forbidden}>
      <Page fullWidth title={pages.current[id]?.title}>
        <Card sectioned>
          <div>{parse(contenido.contenido)}</div>
        </Card>
      </Page>
      <FooterCliente />
    </Layer>
  );
}
