import { useEffect, useState } from "react";
import ApiServiceComercios from "../../services/ApiServiceComercios";

export default function PaymentsContainer({
  children,
  setIsPaymentOnOrder = null,
  setOverdueBillsPaid = null,
  setOverDueBalancePaid = null,
  setPublicKey = null,
  setPrivateKey = null,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const [paymentConfig, setPaymentConfig] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    ApiServiceComercios.getPaymentsConfig()
      .then(({ paymentConfiguration }) => {
        const {
          publicKey,
          secretKey,
          usePaymentsInOrders,
          usePaymentsInBills,
          usePaymentsInDueBalance,
          isActive,
        } = paymentConfiguration;
        setPaymentConfig(paymentConfiguration);
        setIsActive(isActive);
        if (!!setIsPaymentOnOrder) {
          setIsPaymentOnOrder(usePaymentsInOrders);
          setOverdueBillsPaid(usePaymentsInBills);
          setOverDueBalancePaid(usePaymentsInDueBalance);
        }
        setPublicKey(publicKey);
        setPrivateKey(secretKey);
      })
      .finally(() => setIsLoading(false));
  }, [
    setPaymentConfig,
    setIsPaymentOnOrder,
    setOverdueBillsPaid,
    setOverDueBalancePaid,
    setIsActive,
    setPublicKey,
    setPrivateKey,
  ]);

  return (
    <>
      {children({
        isLoading,
        isActive,
        paymentConfig,
      })}
    </>
  );
}
