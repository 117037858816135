import { rgbToHsb, hsbToHex } from "@shopify/polaris";

function makeQueryParams(params) {
  let queries = "";
  for (var key in params) {
    queries += `${key}=${params[key]}&`;
  }
  return queries;
}

function rgbToObj(rgb) {
  if (rgb) {
    rgb.trim();
    let colors = ["red", "green", "blue", "alpha"];
    let colorArr = rgb.slice(rgb.indexOf("(") + 1, rgb.indexOf(")")).split(",");
    let obj = {};

    colorArr.forEach((k, i) => {
      obj[colors[i]] = Number(k);
    });
    return obj;
  }
}

function hexToHSL(H) {
  const rgb = hexToRGB(H);
  const hsb = rgbToHsb(rgb);
  return hsb;
}

function hexToRGB(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16),
      }
    : null;
}

const convertHsbToHex = ({ hue = 0, saturation = 0, brightness = 0 }) =>
  hsbToHex({ hue, saturation, brightness });

export { makeQueryParams, rgbToObj, hexToHSL, convertHsbToHex };
