import React from "react";
import { Button, Collapsible } from "@shopify/polaris";
import useToggle from "../../hooks/useToggle";

export default function CollapseAction({
  children,
  title = "",
  active = false,
  showDeleteAction = false,
}) {
  const [open, toggleOpen] = useToggle(active);

  return (
    <div className="flex flex-col">
      <div
        className={`Polaris-Filters__FilterTriggerContainer Polaris-Filters--first ${
          open ? "Polaris-Filters--open" : ""
        }`}
      >
        <button
          className="Polaris-Filters__FilterTrigger"
          type="button"
          id={title.split(" ").join("")}
          aria-controls={title.split(" ").join("") + "Collapsible"}
          aria-expanded={String(open)}
          onClick={toggleOpen}
        >
          <div
            className="Polaris-Filters__FilterTriggerLabelContainer"
            style={{ minWidth: 200 }}
          >
            <h2 className="mr-10">{title}</h2>
            <span>
              <span className="Polaris-Icon Polaris-Icon--colorInkLightest Polaris-Icon--isColored">
                {open ? (
                  <svg
                    viewBox="0 0 20 20"
                    className="Polaris-Icon__Svg"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <path
                      d="M15 13a.997.997 0 0 1-.707-.293L10 8.414l-4.293 4.293a.999.999 0 1 1-1.414-1.414l5-5a.999.999 0 0 1 1.414 0l5 5A.999.999 0 0 1 15 13"
                      fillRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    viewBox="0 0 20 20"
                    className="Polaris-Icon__Svg"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <path
                      d="M10 14a.997.997 0 0 1-.707-.293l-5-5a.999.999 0 1 1 1.414-1.414L10 11.586l4.293-4.293a.999.999 0 1 1 1.414 1.414l-5 5A.997.997 0 0 1 10 14"
                      fillRule="evenodd"
                    />
                  </svg>
                )}
              </span>
            </span>
          </div>
        </button>
      </div>
      <Collapsible
        open={open}
        transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
      >
        <div className="Polaris-Filters__FilterNodeContainer">
          {children}
          {showDeleteAction && (
            <Button plain disabled={true}>
              Borrar
            </Button>
          )}
        </div>
      </Collapsible>
    </div>
  );
}
