import React, { useState, useCallback } from "react";
import {
  Modal,
  Link,
  TextStyle,
  DropZone,
  Stack,
  Thumbnail,
  Caption,
  Card,
  Banner,
  List,
} from "@shopify/polaris";
import { Table } from "antd";
import * as XLSX from "xlsx";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { useHistory } from "react-router";
import _ from "lodash";

/**
 * Import status
 * 1: Initial with dropdown
 * 2: With file and status success
 * 3: With file and status failed
 */

export default function ImportarPedidos({
  isActive,
  handleChange,
  type = "cliente",
}) {
  const router = useHistory();
  const [articulos, setArticulos] = useState([]);
  const [articulosImport, setArticulosImport] = useState([]);
  const [file, setFile] = useState();
  const [importStatus, setImportStatus] = useState(1);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [cliente, setCliente] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(acceptedFiles[0]);
        reader.onloadend = (e) => {
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: "array" });

          workbook.SheetNames.forEach((sheetName) => {
            let xlRowJson = XLSX.utils.sheet_to_json(
              workbook.Sheets[sheetName]
            );

            if (xlRowJson.length > 0) {
              setArticulosImport(
                xlRowJson
                  .map(({ sku, cantidad }) => ({
                    clave: sku,
                    cantidad: Number(cantidad),
                  }))
                  .filter((i) => i.clave)
              );

              if (type !== "cliente")
                setClientes([
                  ...new Set(
                    xlRowJson.map(({ cliente }) => cliente).filter((i) => i)
                  ),
                ]);
            }
          });
          setFile(() => acceptedFiles[0]);
        };
      }
      setRejectedFiles(_rejectedFiles);
    },
    [type]
  );

  function onImportFile() {
    setIsLoading(true);
    const data = {
      articlesWithClave: articulosImport
        .map((i) => ({
          ...i,
          clave: String(i.clave).replace(new RegExp('"', "ig"), ""),
        }))
        .filter((i) => i.clave && i.clave !== "undefined"),
      clave:
        type !== "cliente"
          ? String(clientes[0].replace(new RegExp('"', "ig"), ""))
          : null,
    };
    ApiServiceComercios.getArticlesForCSV(data)
      .then(({ articles = [], client = null }) => {
        articles = articles.filter(({ isParent }) => !isParent);
        setArticulos(_.sortBy(articles, "nombre"));
        if (client) {
          setImportStatus(2);
          setCliente(client);
        } else {
          setImportStatus(3);
        }
      })
      .catch((err) => {
        setImportStatus(3);
      })
      .finally(() => setIsLoading(false));
  }

  function createOrder() {
    if (type === "cliente") {
      router.push("/cliente/pedidos/agregar", {
        articulos,
        notas: "",
      });
    } else {
      router.push("/admin/pedidos/agregar", {
        articulos,
        notas: "",
        clienteId: cliente._id,
      });
    }
  }

  function retry() {
    setImportStatus(1);
  }

  const validFileTypes = [".xlsx", ".xls"];
  const fileUpload = !file && <DropZone.FileUpload />;

  const uploadedFile = file && (
    <Stack>
      <Thumbnail
        size="small"
        alt={file.name}
        source={
          validFileTypes.indexOf(file.type) > 0
            ? window.URL.createObjectURL(file)
            : "/newPost.png"
        }
      />
      <div>
        {file.name} <Caption>{file.size} bytes</Caption>
      </div>
    </Stack>
  );

  const errorMessage = rejectedFiles.length > 0 && (
    <Banner
      title="Las siguientes imágenes no pudieron ser seleccionadas"
      status="critical"
    >
      <List type="bullet">
        {rejectedFiles.map((file, index) => (
          <List.Item key={index}>
            {`"${file.name}" no es soportado. El archivo debería de ser .xlsx`}
          </List.Item>
        ))}
      </List>
    </Banner>
  );

  return (
    <Modal
      open={isActive}
      title="Importar pedido con un archivo XLSX"
      onClose={!isLoading ? handleChange : null}
      secondaryActions={[
        {
          content: "Cancelar",
          onAction: handleChange,
          disabled: isLoading,
        },
      ]}
      primaryAction={{
        content:
          importStatus === 1
            ? "Subir y continuar"
            : importStatus === 2
            ? "Continuar con el pedido"
            : "Intentar nuevamente",
        onAction:
          importStatus === 1
            ? onImportFile
            : importStatus === 2
            ? createOrder
            : retry,
        disabled:
          (!file && importStatus === 1) ||
          (importStatus === 2 && articulos.length === 0),
        loading: isLoading,
      }}
      footer={
        <Link url="https://help.b2bgo.mx/pedidos/crear-pedido#importar-un-pedido">
          ¿Necesitas ayuda para importar un pedido?
        </Link>
      }
    >
      <Card sectioned>
        <div className="my-4">
          <TextStyle>
            Descarga una{" "}
            <Link
              url={
                type !== "cliente"
                  ? "https://b2bgo.blob.core.windows.net/ecohete-resources/PedidoConClave.xlsx"
                  : "https://b2bgo.blob.core.windows.net/ecohete-resources/Pedido.xlsx"
              }
            >
              plantilla XLSX de muestra
            </Link>{" "}
            para ver un ejemplo del formato requerido. Los campos deben de ser
            texto sin formato
          </TextStyle>
        </div>

        {errorMessage}

        {importStatus === 1 && (
          <DropZone
            type="file"
            accept={validFileTypes.join(",")}
            onDrop={handleDropZoneDrop}
            allowMultiple={false}
          >
            {uploadedFile}
            {fileUpload}
          </DropZone>
        )}

        {importStatus === 2 && (
          <div>
            <Banner status="info">
              <TextStyle>
                Estás importando un pedido con B2Bgo. Te mostramos una vista
                previa de tu pedido para tu revisión. Se validaron las
                existencias de los artículos, los artículos que no tengan
                existencia no aparecerán en el listado
              </TextStyle>
            </Banner>
            <div className="my-8">
              <TextStyle>
                Estás importando{" "}
                <span className="font-bold">
                  {type !== "cliente" && `${cliente && cliente.nombre} `}
                  {articulos.length} productos
                </span>
                . Se creará un pedido en el portal de B2Bgo.
              </TextStyle>
            </div>
            <Table
              rowKey="_id"
              rowClassName="whitespace-nowrap"
              dataSource={articulos}
              scroll={{ scrollToFirstRowOnChange: true, x: "100%" }}
              pagination={false}
              locale={{ emptyText: "No hay artículos" }}
              columns={[
                {
                  title: "Clave",
                  dataIndex: "clave",
                  render: (clave = "") => <TextStyle>{clave}</TextStyle>,
                },
                {
                  title: "Nombre",
                  dataIndex: "nombre",
                  render: (nombre = "") => <TextStyle>{nombre}</TextStyle>,
                },
                {
                  title: "Cantidad",
                  dataIndex: "cantidad",
                  render: (cantidad = "") => <TextStyle>{cantidad}</TextStyle>,
                },
              ]}
            />
          </div>
        )}
        {importStatus === 3 && (
          <div>
            <Banner status="warning">
              <TextStyle>
                Se produjo un error al importar tu archivo XLSX. Corrige el
                error e intenta importar el archivo XLSX nuevamente.
              </TextStyle>
            </Banner>
            <div className="my-8">
              <TextStyle>Estos son algunos posibles errores:</TextStyle>
            </div>
            <List>
              <List.Item>
                El formato del archivo no corresponden a la estructura de{" "}
                {type !== "cliente" && "clave del cliente, "}clave de producto,
                cantidad. Descarga una{" "}
                <Link
                  url={
                    type !== "cliente"
                      ? "https://b2bgo.blob.core.windows.net/ecohete-resources/PedidoConClave.xlsx"
                      : "https://b2bgo.blob.core.windows.net/ecohete-resources/Pedido.xlsx"
                  }
                >
                  plantilla XLSX de muestra
                </Link>{" "}
                para ver un ejemplo del formato requerido.
              </List.Item>
              {type !== "cliente" && (
                <>
                  <List.Item>
                    Existen dos o más claves de clientes en el archivo.
                  </List.Item>
                  <List.Item>
                    Existen claves de clientes no resgitradas en B2Bgo.
                  </List.Item>
                </>
              )}
              <List.Item>Existen claves no resgitradas en B2Bgo.</List.Item>
              <List.Item>Existen artículos sin cantidad indicada.</List.Item>
            </List>
          </div>
        )}
      </Card>
    </Modal>
  );
}
