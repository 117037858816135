const Metrics = {
  doubleBaseMargin: 32,
  baseMargin: 16,
  smallMargin: 8,
  tinyMargin: 4,
  buttonRadius: 10,
};

const ApplicationStyles = {
  screen: {
    imageFill: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      width: null,
      height: null,
    },
    fill: {
      width: "100%",
      height: "100%",
    },
    container: {
      flex: 1,
    },
    absolute: {
      position: "absolute",
    },
    setToBot: {
      bottom: 0,
      right: 0,
      left: 0,
    },
    behind: {
      zIndex: -1,
    },
    bottomView: {
      position: "absolute",
      bottom: 0,
      width: "100%",
      zIndex: 9999,
    },
    bottomViewHeight: {
      height: 64,
      //height 64 + 16margin
    },
    alert: {
      zIndex: 9999,
    },
  },
  margins: {
    doubleBaseMargin: {
      marginTop: Metrics.doubleBaseMargin,
    },
    doubleBaseVerticalMargin: {
      marginVertical: Metrics.doubleBaseMargin,
    },
    tinyVerticalMargin: {
      marginVertical: Metrics.tinyMargin,
    },
    smallVerticalMargin: {
      marginVertical: Metrics.smallMargin,
    },
    baseVerticalMargin: {
      marginVertical: Metrics.baseMargin,
    },
    smallHorizontalMargin: {
      marginHorizontal: Metrics.smallMargin,
    },
    baseHorizontalMargin: {
      marginHorizontal: Metrics.baseMargin,
    },
    smallTopMargin: {
      marginTop: Metrics.smallMargin,
    },
    baseTopMargin: {
      marginTop: Metrics.baseMargin,
    },
    tinyRightMargin: {
      marginRight: Metrics.tinyMargin,
    },
    smallRightMargin: {
      marginRight: Metrics.smallMargin,
    },
    smallLeftMargin: {
      marginLeft: Metrics.smallMargin,
    },
    baseLeftMargin: {
      marginLeft: Metrics.baseMargin,
    },
    smallBottomMargin: {
      marginBottom: Metrics.smallMargin,
    },
    baseBottomMargin: {
      marginBottom: Metrics.baseMargin,
    },
    baseBottomPadding: {
      paddingBottom: Metrics.baseMargin,
    },
    doubleBaseBottomPadding: {
      paddingBottom: Metrics.doubleBaseMargin,
    },
    baseTopPadding: {
      paddingTop: Metrics.baseMargin,
    },
    tinyVerticalPadding: {
      paddingVertical: Metrics.tinyMargin,
    },
    smallVerticalPadding: {
      paddingVertical: Metrics.smallMargin,
    },
    baseVerticalPadding: {
      paddingVertical: Metrics.baseMargin,
    },
    smallHorizontalPadding: {
      paddingHorizontal: Metrics.smallMargin,
    },
    baseHorizontalPadding: {
      paddingHorizontal: Metrics.baseMargin,
    },
  },
  flexBox: {
    column: {
      flexDirection: "column",
    },
    row: {
      flexDirection: "row",
    },
    antirow: {
      flexDirection: "row-reverse",
    },
    flex1: {
      flex: 1,
    },
    flex2: {
      flex: 2,
    },
    flex: {
      display: "flex",
    },
    justifyContentFlexStart: {
      justifyContent: "flex-start",
    },
    justifyContentFlexEnd: {
      justifyContent: "flex-end",
    },
    justifyContentCenter: {
      justifyContent: "center",
    },
    justifyContentSpaceBetween: {
      justifyContent: "space-between",
    },
    justifyContentSpaceAround: {
      justifyContent: "space-around",
    },
    justifyContentSpaceEvenly: {
      justifyContent: "space-evenly",
    },
    alignItemFlexStart: {
      alignItems: "flex-start",
    },
    alignItemsFlexEnd: {
      alignItems: "flex-end",
    },
    alignItemsCenter: {
      alignItems: "center",
    },
    alignItemsStretch: {
      alignItems: "stretch",
    },
    centerObjects: {
      justifyContent: "center",
      alignItems: "center",
    },
    alignSelfCenter: {
      alignSelf: "center",
    },
    alignSelfFlexStart: {
      alignSelf: "flex-start",
    },
    wrap: {
      flexWrap: "wrap",
    },
    alignContentFlexStart: {
      alignContent: "flex-start",
    },
    alignContentFlexEnd: {
      alignContent: "flex-end",
    },
    alignContentCenter: {
      alignContent: "center",
    },
    alignContentSpaceBetween: {
      alignContent: "space-between",
    },
    alignContentSpaceAround: {
      alignContent: "space-around",
    },
    fullWidth: {
      width: "100%",
    },
    fullHeigth: {
      height: "100%",
    },
    heightAuto: {
      height: "auto",
    },
  },
  pdfStyle: {
    page: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#FFFFFF",
      marginTop: "10mm",
      paddingHorizontal: "10mm",
      paddingBottom: "4cm",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 7,
      bottom: 40,
      left: 0,
      right: 0,
      textAlign: "center",
    },
    footerBorder: {
      position: "absolute",
      bottom: 73,
      borderTop: "0.26mm",
      borderBottom: "0.26mm",
      borderColor: "#DFE3E8",
      paddingTop: "4mm",
      marginLeft: "10mm",
      marginRight: "-5mm",
    },
    footerLeft: {
      marginBottom: "2mm",
      fontFamily: "Tahoma",
      position: "absolute",
      fontSize: 7,
      bottom: 48,
      left: 80,
      right: 0,
      textAlign: "left",
    },
    footerCenter: {
      marginBottom: "2mm",
      fontFamily: "Tahoma",
      position: "absolute",
      fontSize: 7,
      bottom: 48,
      left: 0,
      right: 0,
      textAlign: "center",
    },
    footerRight: {
      marginBottom: "2mm",
      fontFamily: "Tahoma",
      position: "absolute",
      fontSize: 7,
      bottom: 48,
      left: 0,
      right: 80,
      textAlign: "right",
    },
    bigTextHeader: {
      fontFamily: "Tahoma-Bold",
      fontSize: "0.6cm",
      color: "#637381",
    },
    bigTextHeaderDocument: {
      fontFamily: "Tahoma-Bold",
      fontSize: 16,
      color: "#000000",
    },
    productImage: {
      objectFit: "cover",
      height: "90%",
      width: "1.8cm",
      padding: "3mm",
      borderColor: "#E2DDDD",
      borderWidth: "0.4mm",
    },
    merchantImage: {
      width: "3cm",
      height: "auto",
      padding: "1mm",
      margin: "2mm",
    },
    bodyData: {
      marginTop: "2mm",
      fontSize: 7,
      textAlign: "left",
      fontFamily: "Tahoma",
    },
    bodyDirection: {
      marginBottom: "1mm",
      fontSize: 7,
      textAlign: "left",
      fontFamily: "Tahoma",
    },
    bodyProduct: {
      paddingTop: "4mm",
      marginRight: "1mm",
      fontSize: 7,
      fontFamily: "Tahoma",
      textAlign: "right",
    },
    bodyProductLeft: {
      paddingTop: "4mm",
      fontSize: 7,
      fontFamily: "Tahoma",
      textAlign: "left",
    },
    spaceProducts: {
      marginBottom: "0.1cm",
      height: "2cm",
      marginTop: "0.2cm",
    },
    titleBodyData: {
      marginTop: "2mm",
      fontSize: 11,
      textAlign: "left",
      fontFamily: "Tahoma-Bold",
    },
    bodyDataRight: {
      marginTop: "2mm",
      fontSize: 7,
      textAlign: "right",
      fontFamily: "Tahoma",
    },
    directionName: {
      marginTop: "3mm",
      marginBottom: "1.5mm",
      fontSize: 9,
      textAlign: "left",
      fontFamily: "Tahoma",
    },
    tableHeader: {
      color: "black",
      backgroundColor: "white",
      textAlign: "center",
      borderColor: "black",
      borderWidth: "0.26mm",
      marginRight: "0.8mm",
      height: "0.6cm",
      fontSize: 9,
      paddingVertical: "0.6mm",
      fontFamily: "Tahoma-Bold",
    },
  },
};

export default ApplicationStyles;
