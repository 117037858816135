import React, { useEffect, useState } from "react";
import { Button, Page, Stack } from "@shopify/polaris";
import moment from "moment";
import { useSelector } from "react-redux";
import { PDFDownloadLink } from "@react-pdf/renderer";

import {
  getComercioData,
  getNombreComercio,
  getUserInfo,
} from "../../reducers";
import {
  Layer,
  TodayMetrics,
  LimitCreditCard,
  FooterCliente,
  AnuncioSkeleton,
  AnuncioCard,
  PdfAccountStatus,
  PdfAccountStatusOld,
} from "../../components";
import "./ClienteEstadoCuentaScreenStyles.css";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { NumberFormat } from "../../utils/Formats";
import { getMovimientos } from "../../utils/generateData";

const classes =
  "px-8 py-16 flex flex-col text-center justify-center items-center";

export default function DashboardClienteScreeen({ forbidden }) {
  const { name = "", last_name = "", nombre } = useSelector(getUserInfo);
  const [cliente, setCliente] = useState({
    limiteCredito: {},
    lastSync: "",
    allowDownloadAccountStatement: false,
    allowDownloadBalanceAge: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingAnuncios, setIsLoadingAnuncios] = useState(true);
  const [isDownloadingAccountData, setIsDownloadingAccountData] =
    useState(true);
  const [anuncios, setAnuncios] = useState([]);
  const [accountData, setAccountData] = useState(null);
  const comercio = useSelector(getNombreComercio);
  const comercioData = useSelector(getComercioData);
  const url_imagen = useSelector((state) => state.Auth.url_imagen);
  const [usePaymentsInBills, setUsePaymentsInBills] = useState(false);

  var date = `${moment().format("DD-MMMM-YYYY")}`;

  useEffect(() => {
    ApiServiceComercios.obtenerAnunciosClientes()
      .then(({ anuncios }) => {
        setAnuncios(anuncios);
      })
      .finally(() => setIsLoadingAnuncios(false));

    ApiServiceComercios.obtenerEstadoDeCuenta()
      .then(
        ({
          cliente,
          lastSync,
          allowDownloadAccountStatement,
          allowDownloadBalanceAge,
        }) => {
          setCliente({
            limiteCredito: cliente.limite_credito,
            lastSync,
            allowDownloadAccountStatement,
            allowDownloadBalanceAge,
          });
        }
      )
      .finally(() => setIsLoading(false));

    ApiServiceComercios.getPaymentConfigurationDetails().then(
      ({ usePaymentsInBills }) => {
        setUsePaymentsInBills(usePaymentsInBills);
      }
    );

    ApiServiceComercios.descargarEstadoDeCuenta()
      .then(({ data }) => {
        setAccountData(data);
      })
      .finally(() => setIsDownloadingAccountData(false));
  }, []);

  const dataAccount = accountData
    ? { ...accountData, comercio, comercioData, url_imagen }
    : {};

  const dataAccountOld = {
    ...dataAccount,
    limite: cliente.limiteCredito,
    movimientos: getMovimientos(
      dataAccount.cargosVencidos,
      dataAccount.cargosPorVencer
    ),
  };

  function fechaActualizado() {
    // actualizado el 5 Agosto a las 10:03 am.
    if (cliente.lastSync) {
      return `actualizado el ${moment(cliente.lastSync).format(
        "D"
      )} de ${moment(cliente.lastSync).format("MMMM")} a las ${moment(
        cliente.lastSync
      ).format("HH:mm a")}`;
    } else return "";
  }

  function onRead(id) {
    setAnuncios((s) => s.filter((s) => s._id !== id));
  }

  return (
    <Layer title="Inicio" forbidden={forbidden}>
      <Page
        title={
          isLoading
            ? "Buen día"
            : `Buen día, ${nombre ? nombre + " " : ""}${
                name ? name + " " : ""
              }${last_name}.`
        }
        subtitle={
          isLoading ? "" : `Este es tu estado de cuenta, ${fechaActualizado()}.`
        }
      >
        <div className="mb-8 flex gap-2">
          {cliente.allowDownloadAccountStatement && (
            <>
              {isDownloadingAccountData || isLoading ? (
                <Button
                  loading
                  disabled={
                    !accountData || !cliente.allowDownloadAccountStatement
                  }
                >
                  Descargar
                </Button>
              ) : (
                <PDFDownloadLink
                  document={
                    accountData ? (
                      <PdfAccountStatus data={dataAccount} />
                    ) : (
                      <></>
                    )
                  }
                  fileName={`${comercio} - Estado de cuenta al ${date}`}
                >
                  {({ loading }) =>
                    loading ? (
                      <Button loading disabled>
                        Descargar estado de cuenta
                      </Button>
                    ) : (
                      <div className="inline-flex">
                        <Button
                          disabled={
                            loading ||
                            !accountData ||
                            !cliente.allowDownloadAccountStatement
                          }
                          outline
                        >
                          Descargar estado de cuenta
                        </Button>
                      </div>
                    )
                  }
                </PDFDownloadLink>
              )}
            </>
          )}
          {cliente.allowDownloadBalanceAge && (
            <>
              {isLoading || isDownloadingAccountData ? (
                <Button
                  loading
                  disabled={!accountData || !cliente.allowDownloadBalanceAge}
                >
                  Descargar
                </Button>
              ) : (
                <PDFDownloadLink
                  document={
                    accountData ? (
                      <PdfAccountStatusOld data={dataAccountOld} />
                    ) : (
                      <></>
                    )
                  }
                  fileName={`${comercio} - Antigüedad de cuentas por cobrar de ${name} ${last_name} al ${date}`}
                >
                  {({ loading }) =>
                    loading ? (
                      <Button loading disabled>
                        Descargar antigüedad del saldo
                      </Button>
                    ) : (
                      <div className="inline-flex">
                        <Button
                          disabled={
                            loading ||
                            !accountData ||
                            !cliente.allowDownloadBalanceAge
                          }
                          outline
                        >
                          Descargar antigüedad del saldo
                        </Button>
                      </div>
                    )
                  }
                </PDFDownloadLink>
              )}
            </>
          )}
        </div>
        <div className="mx-8 sm:mx-0 md:mx-0">
          <Stack distribution="fillEvenly" wrap spacing="none">
            <TodayMetrics
              classes={classes}
              heading="Saldo total"
              isLoading={isLoading}
              metric={`$${Number(
                cliente.limiteCredito?.saldo?.actual || 0
              ).toLocaleString("en", NumberFormat)}`}
              redirect={
                usePaymentsInBills && {
                  title: "Pagar",
                  url: "/cliente/facturas",
                }
              }
            />
            <TodayMetrics
              classes={classes}
              heading={`Vencido ${
                cliente.limiteCredito?.vencido?.prctjactual || 0
              }%`}
              isLoading={isLoading}
              metric={`$${Number(
                cliente.limiteCredito?.vencido?.actual || 0
              ).toLocaleString("en", NumberFormat)}`}
              redirect={
                usePaymentsInBills && {
                  title: "Pagar",
                  url: '/cliente/facturas?status=["Atrasado"]',
                }
              }
            />
            <TodayMetrics
              classes={classes}
              heading={`Por vencer ${
                cliente.limiteCredito?.porvencer?.prctjactual || 0
              }%`}
              isLoading={isLoading}
              metric={`$${Number(
                cliente.limiteCredito?.porvencer?.actual || 0
              ).toLocaleString("en", NumberFormat)}`}
              redirect={
                usePaymentsInBills && {
                  title: "Pagar",
                  url: '/cliente/facturas?status=["Pendiente"]',
                }
              }
            />
          </Stack>
        </div>
        <LimitCreditCard data={isLoading ? {} : cliente.limiteCredito} />
        {/* <ItemButton 
               icon="OrdersMajor" 
               onClick={()=>{}} 
               textStrong="6 pedido" 
               text="por enviar" 
               hasBorder
            />
            <ItemButton 
               icon="PaymentsMajor" 
               onClick={()=>{}} 
               textStrong="6 pedido" 
               text="por enviar" 
            /> */}
        {isLoadingAnuncios &&
          Array.from(Array(3).keys()).map((item) => {
            return (
              <div key={item}>
                <AnuncioSkeleton />
              </div>
            );
          })}
        {anuncios.map((anuncio) => {
          return (
            <div key={anuncio._id}>
              <AnuncioCard anuncio={anuncio} onRead={onRead} />
            </div>
          );
        })}
      </Page>
      <FooterCliente />
    </Layer>
  );
}
