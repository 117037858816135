import React from 'react';
import { useSelector } from 'react-redux';
import { getPedidos, isLoadingPedidos, getPaginationPedidos } from '../../reducers';

export default function PedidosContainer({ children }) {
   const isLoading = useSelector(isLoadingPedidos);
   const pedidos = useSelector(getPedidos)
      .map(item => ({
         ...item,
         id: item._id,
         articulos: item.articuloFlete && item.articuloFlete.nombre
            ? item.articulos.filter(i => i.articulo._id !== item.articuloFlete.id)
            : item.articulos.filter(i => i.articulo && i.articulo.nombre.indexOf("Flete") < 0)
      }));

   const pagination = useSelector(getPaginationPedidos);

   return <>{children({ isLoading, pedidos, pagination })}</>
}
