import ApiServiceComercios from "../services/ApiServiceComercios";
import NormalizeById from "../utils/NormalizedById";
import { getPaginationHistorial } from "../reducers";

export const HISTORIAL_BEGIN = "HISTORIAL_BEGIN";
export const ADD_HISTORIAL = "ADD_HISTORIAL";
export const HISTORIAL_FAILED = "HISTORIAL_FAILED";
export const CLEAR_HISTORIAL = "CLEAR_HISTORIAL";
export const RESET_PAGE = "RESET_PAGE";
export const CHANGE_PAGE = "CHANGE_PAGE";

export function fecthHistorial(id, params) {
  return async (dispatch, getState) => {
    dispatch(clearHistorial());
    dispatch(historialBegin());
    let pagination = getPaginationHistorial(getState());
    return ApiServiceComercios.obtenerHistorialComercios(
      {
        limit: pagination.sizePerPage,
        skip: pagination.currentPage * pagination.sizePerPage,
        ...params,
      },
      id
    )
      .then((result) => {
        let data = NormalizeById(result.sincronizaciones, "_id");
        data["total"] = result.total;
        data["successful"] = result.successful;
        data["failed"] = result.failed;
        data["lastSync"] = result.lastSync;
        dispatch(addHistorial(data));
        return result;
      })
      .catch((error) => {
        dispatch(historialFailed());
        throw error;
      });
  };
}

export const historialBegin = () => ({
  type: HISTORIAL_BEGIN,
});

export const addHistorial = (data) => ({
  type: ADD_HISTORIAL,
  payload: { data },
});

export const historialFailed = () => ({
  type: HISTORIAL_FAILED,
});

export const clearHistorial = () => ({
  type: CLEAR_HISTORIAL,
});

export const changePage = (currentPage) => ({
  type: CHANGE_PAGE,
  payload: { data: { currentPage } },
});

export const resetPage = () => ({
  type: RESET_PAGE,
});
