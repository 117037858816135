import { Button, ButtonGroup, Card, Collapsible, Icon } from "@shopify/polaris";
import * as Icons from "@shopify/polaris-icons";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { Skeleton } from "antd";
import "./CustomSidebar.css";
import AddSection from "./AddSection";
import AddSubItem from "./AddSubItem";
import { DragButton, DragHandle, DragHandleSubItem } from "./DragableButtons";

export default function CustomSidebar({
  title = "",
  onClick = () => {},
  selectedSection = null,
  selectedSubSection = null,
  handleMainChange = () => {},
  onAddItem = () => {},
  onAddSubItem = () => {},
  onSort = () => {},
  heading = [],
  body = [],
  footer = [],
  showAddSection = true,
  currentPage = "",
  loading = true,
}) {
  if (loading) {
    return (
      <Card sectioned>
        {Array.from(Array(10).keys()).map((key) => (
          <div className="flex my-4 w-full" style={{ height: 33 }} key={key}>
            <Skeleton.Button
              active
              size="small"
              shape="square"
              className="mr-2"
            />
            <Skeleton.Input active size="small" className="flex" block />
            <Skeleton.Button
              active
              size="small"
              shape="square"
              className="mx-2"
            />
            <Skeleton.Button active size="small" shape="square" />
          </div>
        ))}
      </Card>
    );
  }
  const SortableSubItem = SortableElement(({ item, subItem }) => {
    return (
      <li className="Polaris-Navigation__ListItem main-sub-button">
        <button
          type="button"
          className={`Polaris-Navigation__Item ${
            subItem.id === selectedSubSection?.id
              ? "Polaris-Navigation__Item--selected"
              : ""
          }`}
          onClick={() => onClick(item, subItem)}
        >
          <span
            className={`Polaris-Navigation__Text ${
              subItem.visible ? "" : " text-gray-400"
            }`}
          >
            {subItem.label}
          </span>
        </button>
        <div className="actions-group-subItems invisible flex mr-4">
          <ButtonGroup segmented>
            <Button
              icon={item.visible ? Icons["ViewMajor"] : Icons["HideMinor"]}
              plain
              onClick={() => onHideSubItem(!subItem.visible, subItem.id)}
            />
            <DragHandleSubItem />
          </ButtonGroup>
        </div>
      </li>
    );
  });

  const SortableItem = SortableElement(({ item }) => {
    return (
      <li className="Polaris-Navigation__ListItem flex-col">
        <ItemButton item={item} />
        <div
          className={`Polaris-Navigation__SecondaryNavigation ${
            item.id === selectedSection?.id &&
            item.subNavigationItems?.length > 0
              ? "Polaris-Navigation--isExpanded"
              : ""
          }`}
        >
          {item.id === selectedSection?.id &&
            (item.subNavigationItems?.length > 0 || item.showAddButton) && (
              <Collapsible
                open={item.id === selectedSection?.id || item.showAddButton}
              >
                <SortableSubItemList
                  items={item.subNavigationItems}
                  item={item}
                  onSortEnd={onSortEndSubItem}
                  useDragHandle
                />
              </Collapsible>
            )}
        </div>
      </li>
    );
  });

  const ItemButton = ({ item, showActions = true }) => {
    return (
      <div className="flex main-button">
        <button
          type="button"
          className={`relative Polaris-Navigation__Item ${
            item?.id === selectedSection?.id
              ? item?.subNavigationItems?.length > 0 && selectedSubSection
                ? "Polaris-Navigation--subNavigationActive"
                : "Polaris-Navigation__Item--selected"
              : ""
          }`}
          onClick={() => onClick(item, undefined)}
        >
          <div
            style={{
              marginTop: "0.6rem",
              marginRight: "1.6rem",
              marginBottom: "0.6rem",
            }}
          >
            <Icon source={Icons[item.icon]} color="base" />
          </div>
          <span
            className={`Polaris-Navigation__Text ${
              item.visible ? "" : "text-gray-400"
            }`}
          >
            {item.label}
          </span>
        </button>
        {showActions && (
          <div className="actions-group invisible flex mr-4">
            <ButtonGroup segmented>
              <Button
                icon={item.visible ? Icons["ViewMajor"] : Icons["HideMinor"]}
                plain
                onClick={() => onHide(!item.visible, item.id)}
              />
              {item.sortable ? (
                <DragHandle />
              ) : (
                <DragButton disabled={item.sortable} />
              )}
            </ButtonGroup>
          </div>
        )}
      </div>
    );
  };

  const SortableList = SortableContainer(({ items }) => (
    <ul>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} item={value} />
      ))}
    </ul>
  ));

  const SortableSubItemList = SortableContainer(({ items, item }) => (
    <ul className="Polaris-Navigation__List">
      {items.map((subItem, index) => (
        <SortableSubItem
          key={`item-${index}`}
          index={index}
          item={item}
          subItem={subItem}
        />
      ))}
      <AddSubItem onAddSubItem={onAddSubItem} item={item} />
    </ul>
  ));

  const onHide = (visible, id) => {
    handleMainChange("visible", visible, id);
  };

  const onHideSubItem = (visible, id) => {
    const subNavigationItems = selectedSection.subNavigationItems.map(
      (subNavItem) =>
        subNavItem.id === id ? { ...subNavItem, visible } : subNavItem
    );
    handleMainChange(
      "subNavigationItems",
      subNavigationItems,
      selectedSection.id
    );
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    onSort((prevItems) =>
      prevItems.map((item) =>
        item.type === currentPage
          ? {
              ...item,
              sections: arrayMove(item.sections, oldIndex, newIndex),
            }
          : item
      )
    );
  };

  const onSortEndSubItem = ({ oldIndex, newIndex }) => {
    onSort((prevItems) =>
      prevItems.map((item) =>
        item.type === currentPage
          ? {
              ...item,
              sections: item.sections.map((section) =>
                section.id === selectedSection.id
                  ? {
                      ...section,
                      subNavigationItems: arrayMove(
                        section.subNavigationItems,
                        oldIndex,
                        newIndex
                      ),
                    }
                  : section
              ),
            }
          : item
      )
    );
  };

  return (
    <>
      <Card>
        <ul
          className="Polaris-Navigation__Section mb-0 !pb-0"
          style={{ paddingBottom: 0 }}
        >
          <li className="Polaris-Navigation__SectionHeading">
            <span className="Polaris-Navigation__Text">{title}</span>
          </li>
        </ul>
        <ul
          className="Polaris-Navigation__Section pb-0"
          style={{ paddingBottom: 4 }}
        >
          <div className="pb-2 border-b border-gray-300">
            {heading.map((item, index) => (
              <li key={index} className="Polaris-Navigation__ListItem flex-col">
                <ItemButton item={item} showActions={false} />
              </li>
            ))}
          </div>
          <div className="py-2">
            <SortableList items={body} onSortEnd={onSortEnd} useDragHandle />
            {showAddSection && <AddSection onAddItem={onAddItem} />}
          </div>
          <div className="pt-2 border-t border-gray-300">
            {footer.map((item, index) => (
              <li key={index} className="Polaris-Navigation__ListItem flex-col">
                <ItemButton item={item} showActions={false} />
              </li>
            ))}
          </div>
        </ul>
      </Card>
    </>
  );
}
