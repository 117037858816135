import { Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/AuthActions";
import { getAuthData, getUserInfo, isAuthenticated } from "../../reducers";
import useSchedule from "../../hooks/useSchedule";

export default function StoreValidatorScreen() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const userData = useSelector(getUserInfo);
  const isLoggedIn = useSelector(isAuthenticated);
  const { grant_type } = useSelector(getAuthData);

  const { shouldUseSchedule, exists, isLoginAllowed, loading } = useSchedule();

  if (loading) {
    return <></>;
  }

  if (!exists) {
    if (!isLoggedIn) {
      return <Redirect to="/not-store" />;
    } else {
      dispatch(logout(userData._id));
      return <Redirect to="/not-store" />;
    }
  } else {
    if (pathname === "/not-store") {
      return <Redirect to="/login" />;
    } else if (isLoggedIn) {
      if (["CLIENTE", "CONTACT"].includes(grant_type)) {
        if (!shouldUseSchedule) {
          return <></>;
        } else {
          if (isLoginAllowed) {
            return <></>;
          } else {
            return <Redirect to="/not-service" />;
          }
        }
      } else {
        return <></>;
      }
    }
  }

  return <></>;
}
