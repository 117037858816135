import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Thumbnail,
  TextStyle,
  Button,
  Checkbox,
  Icon,
  EmptyState,
  Scrollable,
  TextField,
} from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import ItemButton from "../../ItemButton";
import {
  changePage,
  fecthArticulos,
  resetPage,
} from "../../../actions/ArticulosActions";
import useDebounce from "../../../services/useDebounce";
import { getArticulos, getPaginationArticulos } from "../../../reducers";
import SkeletonTable from "../../SkeletonTable";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import AntPagination from "../../AntPagination";
import { getImageVideoUrl } from "../../../utils/productValidators";

const ArticleModal = ({
  selectArticulos = [],
  isActive = false,
  handleChange = () => {},
  primaryActionTitle = "Agregar",
  value = [],
  selected = [],
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [queryValue, setQueryValue] = useState("");
  const [selectedArticulos, setSelectedArticulos] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showItems, setShowItems] = useState(true);
  const pagination = useSelector(getPaginationArticulos);
  const dispatch = useDispatch();
  let articulos = useSelector(getArticulos) || [];

  const queryValueRef = useRef();
  const selectedRef = useRef();
  const debouncedSearchTerm = useDebounce(queryValue, 800);

  articulos = articulos
    .map((item) => ({ ...item, id: item._id }))
    .filter((i) => !selected.includes(i._id))
    .map((i) => {
      const obj = { ...i };
      if (i.isParent) {
        obj["children"] = [
          ...i.variants
            .map((j) => ({ ...j.variantId }))
            .map((k) => ({ ...k, id: k._id, parentId: i._id })),
        ];
        delete obj.variants;
      }
      return obj;
    });

  useEffect(() => {
    queryValueRef.current = queryValue;
    selectedRef.current = selected;
  });

  useEffect(() => {
    dispatch(resetPage());
    function fetchProductos() {
      if (value) {
        setShowItems(false);
        setQueryValue(value);
      }
    }
    fetchProductos();
  }, [dispatch, value]);

  useEffect(() => {
    function fetchData() {
      dispatch(resetPage());
      setIsLoading(true);
      dispatch(
        fecthArticulos(
          {
            notIn: JSON.stringify(selectedRef.current),
            sort: "nombre",
            query: queryValueRef.current,
            existencia: -1,
            orden: 1,
            linea: [],
            sucursal: [],
          },
          false
        )
      ).finally(() => {
        setIsLoading(false);
      });
    }
    fetchData();
  }, [debouncedSearchTerm, dispatch]);

  const chooseItems = () => {
    selectArticulos(selectedArticulos);
    handleChange();
  };

  const handleChangeText = (val) => {
    setShowItems(false);
    setQueryValue(val);
  };

  const handleSelect = (selected, val, isVariant = false) => {
    if (selected) {
      const article = isVariant
        ? articulos
            .filter((i) => i.isParent)
            .find((i) => i.children.some((i) => i.id === val))
            ["children"].find((i) => i.id === val)
        : articulos.filter((ar) => ar._id === val)[0];
      setSelectedArticulos((state) => [...state, article]);

      setSelectedItems((state) => [...new Set(state.concat(val))]);
    } else {
      setSelectedArticulos(selectedArticulos.filter((ar) => ar._id !== val));
      setSelectedItems(selectedItems.filter((sel) => val !== sel));
    }
  };

  const onQueryEndChange = (reset = true) => {
    if (reset) dispatch(resetPage());
    setIsLoading(true);
    dispatch(
      fecthArticulos(
        {
          notIn: JSON.stringify(selected),
          sort: "nombre",
          query: queryValue,
          existencia: -1,
          orden: 1,
          linea: [],
          sucursal: [],
        },
        false
      )
    ).finally(() => {
      setIsLoading(false);
    });
  };

  const searchMoreArticles = () => {
    onQueryEndChange();
    setShowItems(false);
  };

  const emptyStateMarkup =
    !showItems && !isLoading && articulos.length === 0 ? (
      <EmptyState
        heading="¡Oops! no hay artículos"
        image="/emptystate-files.png"
      />
    ) : undefined;

  const searchButton = (
    <Button onClick={() => onQueryEndChange()}>Buscar</Button>
  );

  const textField = (
    <TextField
      onChange={handleChangeText}
      value={queryValue}
      clearButton
      prefix={<Icon source={SearchMinor} color="inkLighter" />}
      placeholder="Buscar productos"
      onClearButtonClick={() => {
        handleChangeText("");
      }}
      connectedRight={searchButton}
    />
  );

  const loadingState = isLoading ? (
    <SkeletonTable headingsCount={4} rowsCount={3} />
  ) : null;

  return (
    <Modal
      title="Seleccionar productos"
      open={isActive}
      onClose={handleChange}
      secondaryActions={[
        {
          content: "Cancelar",
          onAction: handleChange,
        },
      ]}
      footer={
        <AntPagination
          total={pagination.total}
          current={pagination.currentPage + 1}
          pageSize={pagination.sizePerPage}
          onChange={(page) => {
            onQueryEndChange(false);
            dispatch(changePage(page - 1));
          }}
        />
      }
      primaryAction={{
        content: primaryActionTitle,
        onAction: chooseItems,
        disabled: selectedItems.length === 0,
      }}
    >
      <Scrollable style={{ height: "100%" }}>
        <div className="my-4 products-modal">
          <div className="mx-4">{textField}</div>
          {loadingState}
          {!showItems && !isLoading ? (
            <div className="mt-8 ant-table-styles">
              <Table
                onRow={(record) => ({
                  onClick: () => {
                    if (!record.isParent) {
                      handleSelect(
                        !Boolean(selectedItems.indexOf(record.id) >= 0),
                        record.id,
                        record.isVariant
                      );
                    }
                  },
                })}
                rowKey="id"
                dataSource={articulos}
                scroll={{ scrollToFirstRowOnChange: true, x: "100%" }}
                pagination={false}
                locale={{ emptyText: emptyStateMarkup }}
                columns={[
                  {
                    title: "Clave",
                    dataIndex: "clave",
                    render: (clave, record) => (
                      <div className="flex flex-row items-center pointer-events-none p-8">
                        {!record.isParent && (
                          <Checkbox
                            value={record.id}
                            checked={selectedItems.indexOf(record._id) >= 0}
                            onChange={(val) => handleSelect(val, record._id)}
                          />
                        )}
                        <div className="sm:flex flex-col md:flex-row lg:flex-row">
                          <div className="mr-4 flex justify-start">
                            <Thumbnail
                              source={
                                getImageVideoUrl(
                                  record?.imagen,
                                  "image",
                                  false
                                ) || "/Default Photo.png"
                              }
                              size="small"
                            />
                          </div>
                          <div className="xs:flex text-xl lg:whitespace-no-wrap">
                            <TextStyle>{clave}</TextStyle>
                          </div>
                        </div>
                      </div>
                    ),
                  },
                  {
                    title: "Artículo",
                    dataIndex: "nombre",
                    width: "35%",
                    render: (nombre, { clave }) => (
                      <div className="div-left p8">
                        <Button plain>{nombre || clave}</Button>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          ) : null}
          {!isLoading && showItems ? (
            <ItemButton
              onClick={searchMoreArticles}
              text="Todos los productos"
              hasBorder
            />
          ) : null}
        </div>
      </Scrollable>
    </Modal>
  );
};

export default ArticleModal;
