export default function NotStoreFoundScreen() {
  return (
    <div className="h-screen flex flex-col justify-center items-center h-full pb-20 px-10 bg-white">
      <p
        style={{
          fontSize: 28,
          fontWeight: 800,
          color: "#212B36",
          textAlign: "center",
        }}
      >
        Lo sentimos, esta tienda no existe
      </p>
      <p
        style={{
          fontSize: 20,
          color: "#202123",
          marginTop: 20,
          marginBottom: 125,
          textAlign: "center",
        }}
      >
        Verifica que la dirección sea la correcta o intenta en ponerte en
        contacto con la tienda directamente.
      </p>
      <img
        style={{
          width: 534,
          maxHeight: 200,
        }}
        src="/not-store-page.png"
        alt="not-store-page"
      />
    </div>
  );
}
