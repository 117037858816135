/* eslint-disable react/style-prop-object */
import {
  Banner,
  Card,
  Layout,
  List,
  Modal,
  Page,
  Stack,
  TextField,
} from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleToast } from "../../actions/InteractiveActions";
import { Layer } from "../../components";
import { AnnotatedSkeleton } from "../../components/common/AnnotatedSkeleton/AnnotatedSkeleton";
import PaymentsContainer from "../../containers/PaymentsContainer";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import "./PaymentsDetail.styles.css";

const PaymentDetailScreen = ({ forbidden }) => {
  const dispatch = useDispatch();

  const [publicKey, setPublicKey] = useState("");
  const [secretKey, setPrivateKey] = useState("");
  const [activeModal, setActiveModal] = useState(false);
  const [validatedKey, setvalidatedKey] = useState(false);

  const handleChange = useCallback(
    () => setActiveModal(!activeModal),
    [activeModal]
  );

  const handleValidateKey = useCallback(() => {
    ApiServiceComercios.validateKey({ secretKey })
      .then(({ ok }) => {
        if (ok) {
          setvalidatedKey(true);
          handleChange();
        }
      })
      .catch(() => {
        setvalidatedKey(false);
        handleChange();
      });
  }, [secretKey, setvalidatedKey, handleChange]);

  const onSaveKeys = useCallback(() => {
    const params = {
      publicKey,
      secretKey,
    };
    ApiServiceComercios.setPaymentsConfig(params).then(({ ok }) => {
      if (ok)
        dispatch(
          toggleToast({
            message: "Información actualizadas correctamente",
          })
        );
    });
  }, [publicKey, secretKey, dispatch]);

  const ActionMessage = ({ children, type, title }) => {
    return (
      <Modal
        title="Reach more shoppers with Instagram product tags"
        titleHidden
        open={activeModal}
        onClose={handleChange}
      >
        <Modal.Section>
          <Banner
            title={title}
            action={{ content: "Cerrar", onAction: handleChange }}
            status={type}
          >
            <p>{children}</p>
          </Banner>
        </Modal.Section>
      </Modal>
    );
  };

  return (
    <PaymentsContainer
      setPublicKey={setPublicKey}
      setPrivateKey={setPrivateKey}
    >
      {({ isLoading, paymentConfig, isActive }) => {
        if (isLoading) {
          return <AnnotatedSkeleton rowsQuantity={1} />;
        }
        return (
          <Layer title="Pagos con Stripe" forbidden={forbidden}>
            <Page
              title="Stripe"
              fullWidth
              breadcrumbs={[
                { content: "Configuración", url: "/admin/configuracion/pagos" },
              ]}
            >
              <Layout>
                <Layout.AnnotatedSection
                  title="Stripe"
                  description="Millones de empresas de todos los tamaños, desde startups hasta grandes corporaciones, usan Stripe para aceptar pagos, hacer transferencias y gestionar sus negocios en Internet."
                >
                  <Card
                    sectioned
                    primaryFooterAction={{
                      content: "Guardar",
                      onAction: onSaveKeys,
                    }}
                    secondaryFooterActions={[
                      {
                        content: "Probar",
                        onAction: handleValidateKey,
                      },
                    ]}
                  >
                    <Stack vertical>
                      <TextField
                        type="password"
                        autoComplete="off"
                        label="Llave privada"
                        value={secretKey}
                        onChange={setPrivateKey}
                      />
                      <TextField
                        type="password"
                        autoComplete="off"
                        label="Llave pública"
                        value={publicKey}
                        onChange={setPublicKey}
                      />
                    </Stack>
                  </Card>
                </Layout.AnnotatedSection>
              </Layout>
              <ActionMessage
                title={
                  validatedKey
                    ? "Autenticación exitosa"
                    : "Error en la autenticación"
                }
                type={validatedKey ? "success" : "critical"}
              >
                {validatedKey ? (
                  <p>
                    Otorgaste a B2BGo el acceso a tu perfil de Stripe. Los
                    detalles de tu cuenta se guardarán y podrás empezar a cobrar
                    a tus clientes.
                  </p>
                ) : (
                  <p>
                    Stripe no puede autenticarte con la información
                    proporcionada. Algunas soluciones son:
                    <List type="bullet">
                      <List.Item> Usa la clave de API correcta.</List.Item>
                      <List.Item>
                        Asegúrate de no estar usando una clave que hayas
                        cambiado o anulado.
                      </List.Item>
                    </List>
                  </p>
                )}
              </ActionMessage>
            </Page>
          </Layer>
        );
      }}
    </PaymentsContainer>
  );
};

export default PaymentDetailScreen;
