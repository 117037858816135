import React from "react";
import { Layer } from "..";
import {
  Card,
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  SkeletonThumbnail,
  Stack,
} from "@shopify/polaris";

export default function AnnotatedSectionSkeleton() {
  return (
    <Layer title="Monedas">
      <SkeletonPage
        breadcrumbs
        title={<SkeletonDisplayText size="medium" />}
        primaryAction={<SkeletonDisplayText size="medium" />}
      >
        <Layout>
          <Layout.AnnotatedSection
            title={<SkeletonDisplayText size="medium" />}
            description={<SkeletonBodyText lines={2} />}
          >
            {Array.from(Array(3).keys()).map((item) => {
              return (
                <div key={item} className="mb-8">
                  <Card sectioned>
                    <Stack>
                      <SkeletonThumbnail size="small" />
                      <div style={{ minWidth: 300 }}>
                        <SkeletonBodyText lines={2} />
                      </div>
                    </Stack>
                  </Card>
                </div>
              );
            })}
          </Layout.AnnotatedSection>
        </Layout>
      </SkeletonPage>
    </Layer>
  );
}

export const AnnotatedSectionSkeletonBody = ({
  title = "",
  forbidden,
  numberOfSections = 1,
}) => {
  return (
    <Layer title={title} forbidden={forbidden}>
      <SkeletonPage>
        <Layout>
          {Array.from(Array(numberOfSections).keys()).map((key) => (
            <Layout.AnnotatedSection
              key={key}
              title={<SkeletonDisplayText size="small" />}
              description={<SkeletonBodyText lines={2} />}
            >
              <Card sectioned>
                <SkeletonDisplayText size="small" />
                <div className="my-6" />
                <SkeletonBodyText lines={6} />
              </Card>
            </Layout.AnnotatedSection>
          ))}
        </Layout>
      </SkeletonPage>
    </Layer>
  );
};
