import React, { useEffect } from "react";
import { DataTable } from "@shopify/polaris";
import { Skeleton } from "antd";

export default function SkeletonTable({
  headingsCount,
  rowsCount,
  hasCheckbox,
}) {
  useEffect(() => {
    return () => null;
  }, []);

  const heading = <Skeleton.Input active style={{ width: 100 }} size="small" />;

  const rowItem = (
    <Skeleton.Input
      active
      style={{ width: 100 }}
      size="small"
      className="rounded-full "
    />
  );

  const checkbox = <Skeleton.Input active style={{ width: 30 }} size="small" />;

  let headingList = Array.from(Array(headingsCount).keys()).map((i) => heading);
  hasCheckbox && headingList.unshift(checkbox);

  let rows = Array.from(Array(rowsCount).keys()).map((i) => {
    let rowInternal = Array.from(Array(headingsCount).keys()).map(
      (i) => rowItem
    );
    hasCheckbox && rowInternal.unshift(checkbox);
    return rowInternal;
  });

  return (
    <div>
      <div className="flex flex-row mx-8 mb-10 mt-6">
        {checkbox}
        <div className="mx-6" />
        <Skeleton.Input active style={{ width: 150 }} size="small" />
      </div>
      <DataTable
        headings={headingList}
        columnContentTypes={["text"]}
        rows={rows}
      />
    </div>
  );
}
