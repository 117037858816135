import React from "react";
import moment from "moment";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import ApplicationStyles from "../../styles/ApplicationStyles";
import Tahoma from "../../assets/fonts/Tahoma-Regular.ttf";
import TahomaBold from "../../assets/fonts/Tahoma-Bold.ttf";

const { flexBox } = ApplicationStyles;

export default function PdfAccountStatus({ data }) {
  Font.register({ family: "Tahoma", src: Tahoma, format: "truetype" });
  Font.register({ family: "Tahoma-Bold", src: TahomaBold, format: "truetype" });

  if (!data) {
    return null;
  }

  // obtener el nombre del mes, día del mes, año, hora
  var date = `${moment().format("DD")} de ${moment().format(
    "MMMM"
  )} del ${moment().format("YYYY")} a las ${moment().format("HH:mm a")}`;

  const BlueTable = ({ title, children, tiny = false, useBorder = true }) => {
    return (
      <View
        style={[
          {
            border: useBorder ? "0.3mm solid black" : "0 solid black",
          },
        ]}
      >
        <View
          style={[
            {
              color: "white",
              backgroundColor: "#020080",
              textAlign: "center",
            },
          ]}
        >
          <Text
            style={[
              {
                fontSize: tiny ? 7 : 9,
                paddingVertical: "0.6mm",
                fontFamily: "Tahoma-Bold",
              },
            ]}
          >
            {title}
          </Text>
        </View>
        {children}
      </View>
    );
  };

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View
          style={[
            styles.headerData,
            flexBox.row,
            flexBox.justifyContentSpaceBetween,
          ]}
        >
          <View style={[flexBox.flex, flexBox.column, { width: "40%" }]}>
            <Text
              style={[
                {
                  fontSize: 10,
                  lineHeight: "0.6mm",
                  textAlign: "left",
                  marginTop: "2mm",
                  fontFamily: "Tahoma-Bold",
                },
              ]}
            >
              {data.empresa.nombre}
            </Text>
            <Text style={styles.textHeader}>{data.empresa.calle}</Text>
            <Text style={styles.textHeader}>
              {data.empresa.ciudad}, {data.empresa.estado} {data.empresa.pais}{" "}
              CP. {data.empresa.cp}
            </Text>
          </View>
          <View
            style={[
              flexBox.flex,
              flexBox.column,
              flexBox.alignItemsCenter,
              { width: "20%" },
            ]}
          >
            <Image
              style={styles.image}
              src={data.url_imagen ? data.url_imagen : "/b2bgologo.png"}
            />
          </View>
          <View
            style={[
              flexBox.flex,
              flexBox.column,
              flexBox.alignItemsFlexEnd,
              { width: "40%" },
            ]}
          >
            <Text
              style={[
                styles.bigTextHeader,
                { fontFamily: "Tahoma-Bold", fontSize: "21px" },
              ]}
            >
              Estado de cuenta
            </Text>
            <Text
              style={[
                {
                  fontSize: 8,
                  lineHeight: "1mm",
                  marginTop: "2mm",
                  textAlign: "left",
                },
              ]}
            >
              Al {date}
            </Text>
          </View>
        </View>
        <View style={[flexBox.flex, flexBox.row, { marginBottom: "5mm" }]}>
          <View style={{ width: "50%", height: "auto" }}>
            <BlueTable title="Cliente">
              <View style={[{ marginTop: "3mm", marginLeft: "2mm" }]}>
                <Text
                  style={[
                    {
                      fontSize: 11,
                      lineHeight: "0.6mm",
                      fontFamily: "Tahoma-Bold",
                    },
                  ]}
                >
                  {data.cliente.nombre}
                </Text>
                <Text style={[{ fontSize: 10, lineHeight: "0.5mm" }]}>
                  {data.cliente.domicilio}
                </Text>
                <Text
                  style={[
                    { fontSize: 9, lineHeight: "0.5mm", marginBottom: "2mm" },
                  ]}
                >
                  {data.cliente.ciudad} CP: {data.cliente.cp}
                </Text>
              </View>
            </BlueTable>
          </View>
          <View style={{ width: "15%" }} />
          <View style={{ width: "35%" }}>
            <BlueTable title="Periodo">
              <View style={[{ marginTop: "3mm", marginLeft: "2mm" }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      lineHeight: "0.6mm",
                      fontWeight: 800,
                      textAlign: "center",
                    },
                  ]}
                >
                  {data.periodo}
                </Text>
              </View>
            </BlueTable>
          </View>
        </View>

        {/* RESUMEN DE LA CUENTA */}
        <BlueTable title="Resumen de la cuenta" useBorder={false}>
          <View
            style={[
              flexBox.flex,
              flexBox.row,
              { marginBottom: "5mm", marginTop: "0.8mm" },
            ]}
          >
            <View style={{ width: "105.5px", marginHorizontal: "0.8mm" }}>
              <Text style={styles.sectionTextTableLeft}>Saldo anterior</Text>
              <Text style={styles.sectionResumenDetailLeft}>
                {data.saldo.saldoanterior || `  `}
              </Text>
            </View>

            <View style={{ width: "105.5px", marginHorizontal: "0.8mm" }}>
              <Text style={styles.sectionTextTableLeft}>Cargos</Text>
              <Text style={styles.sectionResumenDetailLeft}>
                {data.saldo.cargos || `  `}
              </Text>
            </View>

            <View style={{ width: "105.5px", marginHorizontal: "0.8mm" }}>
              <Text style={styles.sectionTextTableLeft}>Créditos</Text>
              <Text style={styles.sectionResumenDetailLeft}>
                {data.saldo.creditos || `  `}
              </Text>
            </View>

            <View style={{ width: "106.5px", marginHorizontal: "0.8mm" }}>
              <Text style={styles.sectionTextTableLeft}>Saldo actual</Text>
              <Text style={styles.sectionResumenDetailLeft}>
                {data.saldo.saldoactual || `  `}
              </Text>
            </View>

            <View style={{ width: "106.5px", marginHorizontal: "0.8mm" }}>
              <Text style={styles.sectionTextTableLeft}>Anticipos</Text>
              <Text style={styles.sectionResumenDetailLeft}>
                {data.saldo.anticipos || `  `}
              </Text>
            </View>

            <View style={{ width: "106.5px", marginHorizontal: "0.8mm" }}>
              <Text style={styles.sectionTextTableRight}>Saldo vencido</Text>
              <Text style={styles.sectionResumenDetailRight}>
                {data.saldo.saldovencido || `  `}
              </Text>
            </View>

            <View style={{ width: "106.5px", marginHorizontal: "0.8mm" }}>
              <Text style={styles.sectionTextTableRight}>Saldo por vencer</Text>
              <Text style={styles.sectionResumenDetailRight}>
                {data.saldo.porVencer || `  `}
              </Text>
            </View>
          </View>
        </BlueTable>
        {/* FIN RESUMEN DE LA CUENTA */}

        {/* MOVIMIENTOS DEL PERÍODO */}
        <BlueTable title="Movimientos del período" useBorder={false}>
          <View
            style={[
              flexBox.flex,
              flexBox.row,
              { marginBottom: "5mm", marginTop: "0.8mm" },
            ]}
          >
            <View style={{ width: "106.5px", marginHorizontal: "0.8mm" }}>
              <Text style={styles.sectionTextTableLeft}>Fecha</Text>
              {data.detalles.map((item) => (
                <Text style={styles.descriptionTextTableLeft}>
                  {item.fecha || `  `}
                </Text>
              ))}
            </View>
            <View style={{ width: "213px", marginHorizontal: "0.8mm" }}>
              <Text style={styles.sectionTextTableLeft}>Concepto</Text>
              {data.detalles.map((item) => (
                <Text style={styles.descriptionTextTableLeft}>
                  {item.concepto || `  `}
                </Text>
              ))}
            </View>
            <View style={{ width: "108.47px", marginHorizontal: "0.8mm" }}>
              <Text style={styles.sectionTextTableLeft}>Folio</Text>
              {data.detalles.map((item) => (
                <Text style={styles.descriptionTextTableLeft}>
                  {item.folio || `  `}
                </Text>
              ))}
            </View>
            <View style={{ width: "108.47px", marginHorizontal: "0.8mm" }}>
              <Text style={styles.sectionTextTableLeft}>Vence</Text>
              {data.detalles.map((item) => (
                <Text style={styles.descriptionTextTableLeft}>
                  {item.vence || `  `}
                </Text>
              ))}
            </View>
            <View style={{ width: "108.47px", marginHorizontal: "0.8mm" }}>
              <Text style={styles.sectionTextTableRight}>Cargo</Text>
              {data.detalles.map((item) => (
                <Text style={styles.descriptionTextTableRight}>
                  {item.cargos || `  `}
                </Text>
              ))}
            </View>
            <View style={{ width: "108.47px", marginLeft: "0.8mm" }}>
              <Text style={styles.sectionTextTableRight}>Crédito</Text>
              {data.detalles.map((item) => (
                <Text style={styles.descriptionTextTableRight}>
                  {item.creditos || `  `}
                </Text>
              ))}
            </View>
          </View>
        </BlueTable>
        {/* FIN MOVIMIENTOS DEL PERÍODO */}

        {/* CARGOS VENCIDOS */}
        <BlueTable title="Cargos vencidos" useBorder={false}>
          <View
            style={[
              flexBox.flex,
              flexBox.row,
              { marginBottom: "5mm", marginTop: "0.8mm" },
            ]}
          >
            <View style={{ width: "106.5px", marginHorizontal: "0.8mm" }}>
              <Text style={styles.sectionTextTableLeft}>Fecha</Text>
              {data.cargosVencidos.map((item) => (
                <Text style={styles.descriptionTextTableLeft}>
                  {item.fecha || `  `}
                </Text>
              ))}
            </View>
            <View style={{ width: "213px", marginHorizontal: "0.8mm" }}>
              <Text style={styles.sectionTextTableLeft}>Concepto</Text>
              {data.cargosVencidos.map((item) => (
                <Text style={styles.descriptionTextTableLeft}>
                  {item.concepto || `  `}
                </Text>
              ))}
            </View>
            <View style={{ width: "108.47px", marginHorizontal: "0.8mm" }}>
              <Text style={styles.sectionTextTableLeft}>Folio</Text>
              {data.cargosVencidos.map((item) => (
                <Text style={styles.descriptionTextTableLeft}>
                  {item.folio || `  `}
                </Text>
              ))}
            </View>
            <View style={{ width: "108.47px", marginHorizontal: "0.8mm" }}>
              <Text style={styles.sectionTextTableLeft}>Vence</Text>
              {data.cargosVencidos.map((item) => (
                <Text style={styles.descriptionTextTableLeft}>
                  {item.vence || `  `}
                </Text>
              ))}
            </View>
            <View style={{ width: "108.47px", marginHorizontal: "0.8mm" }}>
              <Text style={styles.sectionTextTableRight}>Atraso</Text>
              {data.cargosVencidos.map((item) => (
                <Text style={styles.descriptionTextTableRight}>
                  {item.atraso || `  `}
                </Text>
              ))}
            </View>
            <View style={{ width: "108.47px", marginLeft: "0.8mm" }}>
              <Text style={styles.sectionTextTableRight}>Saldo</Text>
              {data.cargosVencidos.map((item) => (
                <Text style={styles.descriptionTextTableRight}>
                  {item.saldo || `  `}
                </Text>
              ))}
            </View>
          </View>
        </BlueTable>
        {/* FIN CARGOS VENCIDOS */}

        {/* CARGOS POR VENCER */}
        <BlueTable title="Cargos por vencer" useBorder={false}>
          <View
            style={[
              flexBox.flex,
              flexBox.row,
              { marginBottom: "5mm", marginTop: "0.8mm" },
            ]}
          >
            <View style={{ width: "106.5px", marginHorizontal: "0.8mm" }}>
              <Text style={styles.sectionTextTableLeft}>Fecha</Text>
              {data.cargosPorVencer.map((item) => (
                <Text style={styles.descriptionTextTableLeft}>
                  {item.fecha || `  `}
                </Text>
              ))}
            </View>
            <View style={{ width: "213px", marginHorizontal: "0.8mm" }}>
              <Text style={styles.sectionTextTableLeft}>Concepto</Text>
              {data.cargosPorVencer.map((item) => (
                <Text style={styles.descriptionTextTableLeft}>
                  {item.concepto || `  `}
                </Text>
              ))}
            </View>
            <View style={{ width: "108.47px", marginHorizontal: "0.8mm" }}>
              <Text style={styles.sectionTextTableLeft}>Folio</Text>
              {data.cargosPorVencer.map((item) => (
                <Text style={styles.descriptionTextTableLeft}>
                  {item.folio || `  `}
                </Text>
              ))}
            </View>
            <View style={{ width: "108.47px", marginHorizontal: "0.8mm" }}>
              <Text style={styles.sectionTextTableLeft}>Vence</Text>
              {data.cargosPorVencer.map((item) => (
                <Text style={styles.descriptionTextTableLeft}>
                  {item.vence || `  `}
                </Text>
              ))}
            </View>
            <View style={{ width: "108.47px", marginHorizontal: "0.8mm" }}>
              <Text style={styles.sectionTextTableRight}>Días</Text>
              {data.cargosPorVencer.map((item) => (
                <Text style={styles.descriptionTextTableRight}>
                  {item.dias || `  `}
                </Text>
              ))}
            </View>
            <View style={{ width: "108.47px", marginLeft: "0.8mm" }}>
              <Text style={styles.sectionTextTableRight}>Saldo</Text>
              {data.cargosPorVencer.map((item) => (
                <Text style={styles.descriptionTextTableRight}>
                  {item.saldo || `  `}
                </Text>
              ))}
            </View>
          </View>
        </BlueTable>
        {/* FIN CARGOS POR VENCER */}

        {/* FOOTER */}
        <View style={styles.pageNumber} fixed>
          <hr style={styles.line} />

          <hr style={styles.line} />

          <View
            style={[
              flexBox.flex,
              flexBox.row,
              { marginBottom: "2mm", marginTop: "2mm" },
            ]}
          >
            <View style={styles.sectionFooter}>
              <Text style={styles.sectionFooterDescription}>
                {data.comercioData.telefono}
              </Text>
            </View>
            <View style={styles.sectionFooter}>
              <Text style={styles.sectionFooterDescription}>
                {data.comercioData.email}
              </Text>
            </View>
            <View style={styles.sectionFooter}>
              <Text style={styles.sectionFooterDescription}>
                {process.env.NODE_ENV === "development"
                  ? "comercio-dev.b2bgo.com.mx"
                  : window.location.hostname}
              </Text>
            </View>
          </View>

          <Text
            render={({ pageNumber, totalPages }) =>
              `Pág: ${pageNumber} de ${totalPages}`
            }
            fixed
          />
          <Text style={[{ fontSize: 8, lineHeight: "1mm", marginTop: "4mm" }]}>
            Estado de cuenta emitido el {date} con tecnología de b2bgo.mx
          </Text>
        </View>
        {/* FIN FOOTER */}
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 100,
    paddingHorizontal: 35,
  },
  page: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    marginTop: "8mm",
    marginBottom: "8.5mm",
    paddingHorizontal: "10mm",
  },
  headerData: {
    width: "100%",
    display: "flex",
    marginBottom: "5mm",
  },
  textHeader: {
    fontSize: "0.30cm",
    marginVertical: "0.13cm",
    lineHeight: "0.4mm",
    fontWeight: 800,
  },
  bigTextHeader: {
    fontWeight: 900,
    fontSize: "0.85cm",
    color: "#212B36",
    alignSelf: "flex-end",
  },
  body1: {
    marginHorizontal: "0.31cm",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  body1Section1: {
    border: "1px solid #000000",
  },
  sectionBlue: {
    borderBottom: "1px solid #000000",
    color: "#FFFFFF",
    textAlign: "center",
  },
  sectionBlueText: {
    fontSize: "0.6cm",
    fontWeight: "bold",
  },
  sectionResumenDetailRight: {
    marginTop: "2mm",
    fontSize: 9,
    textAlign: "right",
  },
  sectionResumenDetailLeft: {
    marginTop: "2mm",
    fontSize: 9,
    textAlign: "left",
  },
  sectionTextTableRight: {
    marginTop: "0.5mm",
    fontSize: 8,
    color: "#212B36",
    fontFamily: "Tahoma-Bold",
    marginBottom: "3mm",
    textAlign: "right",
  },
  sectionTextTableLeft: {
    marginTop: "0.5mm",
    fontSize: 8,
    color: "#212B36",
    fontFamily: "Tahoma-Bold",
    marginBottom: "3mm",
    textAlign: "left",
  },
  descriptionTextTableRight: {
    marginTop: "2mm",
    fontSize: 9,
    textAlign: "right",
  },
  descriptionTextTableLeft: {
    marginTop: "2mm",
    fontSize: 9,
    textAlign: "left",
  },
  sectionFooter: {
    width: "33.333333%",
    marginHorizontal: "0.8mm",
  },
  sectionFooterDescription: {
    marginTop: "0.5mm",
    fontSize: "8px",
    color: "#212B36",
  },
  pageNumber: {
    position: "absolute",
    fontSize: "8px",
    bottom: 35,
    left: "4%",
    right: 0,
    textAlign: "center",
    color: "#212B36",
  },
  line: {
    width: "96%",
    borderBottom: "1px solid #DFE3E8",
  },
  image: {
    width: "50%",
    height: "auto",
    top: "2px",
  },
});
