import {
  ADD_ARTICULOS,
  ARTICULOS_BEGIN,
  ARTICULOS_FAILED,
  CLEAR_ARTICULOS,
  RESET_PAGE,
  ADD_FILTERED_ITEMS,
  FILTERED_ITEMS_FAILED,
  FILTERED_CLEAR_ITEMS,
  ITEMS_BEGIN,
  CHANGE_PAGE,
} from "../actions/ArticulosActions";

const initialState = {
  byId: {},
  allIds: [],
  allIdsDB: [],
  isLoading: false,
  currentPage: 0,
  sizePerPage: 20,
  total: 0,
  tags: [],
  productTypes: [],
  brands: [],
};

export default function ArticulosReducer(state = initialState, action) {
  switch (action.type) {
    case ITEMS_BEGIN:
    case ARTICULOS_BEGIN: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ADD_FILTERED_ITEMS:
    case ADD_ARTICULOS: {
      let allIds = [
        ...new Set(state.allIds.concat(action.payload.data.allIds)),
      ];
      let byId = {
        ...state.byId,
        ...action.payload.data.byId,
      };
      let allIdsDB = action.payload.data.allIdsDB;
      let {
        tags = [],
        productTypes = [],
        brands = [],
        options = [],
      } = action.payload.data;
      return {
        ...state,
        allIds,
        byId,
        allIdsDB,
        total: action.payload.data.total,
        isLoading: false,
        tags,
        productTypes,
        brands,
        options,
      };
    }

    case CLEAR_ARTICULOS: {
      return {
        ...state,
        byId: {},
        allIds: [],
        allIdsDB: [],
        isLoading: false,
      };
    }
    case FILTERED_CLEAR_ITEMS: {
      return {
        ...state,
        byId: {},
        allIds: [],
        allIdsDB: [],
        isLoading: false,
      };
    }
    case ARTICULOS_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case FILTERED_ITEMS_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case CHANGE_PAGE: {
      const currentPage = action.payload.data.currentPage;
      return {
        ...state,
        currentPage,
      };
    }
    case RESET_PAGE: {
      return {
        ...state,
        currentPage: 0,
        total: 0,
      };
    }
    default: {
      return state;
    }
  }
}
