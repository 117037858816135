import React, { useState, useEffect } from 'react';
import {
   Page,
   Layout,
   ContextualSaveBar,
   Button,
   ButtonGroup,
} from "@shopify/polaris";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { obtenerPaginasComercio, actualizarPaginasComercio } from '../../actions/ComerciosActions';
import { HelpFooter, Layer, PaginasCard, PaginasScreenSkeleton } from '../../components';
import { toggleToast } from '../../actions/InteractiveActions';


function ComerciosPaginasScreen({ forbidden }) {
   const dispatch = useDispatch();
   const router = useHistory();
   const [paginas, setPaginas] = useState([]);
   const [isDirty, setIsDirty] = useState(false);
   const [isLoading, setIsLoading] = useState(true);
   const [isEditing, setIsEditing] = useState(false);

   useEffect(() => {
      dispatch(obtenerPaginasComercio()).then((res) => {
         setPaginas([...res.allIds.map((id) => res.byId[id])]);
      }).catch((error) => {
      }).finally(() => setIsLoading(false));
   }, [dispatch]);

   function handleSave() {
      setIsEditing(true);
      dispatch(actualizarPaginasComercio(paginas)).then(({ message }) => {
         setIsDirty(false);
         dispatch(toggleToast({ message }))
      }).catch((error) => {
      }).finally(() => setIsEditing(false));
   }

   function handleDiscard() {
      router.push('/admin/configuracion');
   }

   function handleFieldChange(value = "", key) {
      let paginasPrev = paginas;
      paginasPrev.forEach((pagina, index) => {
         if (pagina['titulo'] === key) {
            paginasPrev[index].contenido = value;
         }
      });
      value && setIsDirty(true);
      setPaginas(paginasPrev);
   }

   const contextualSaveBarMarkup = isDirty ? (
      <ContextualSaveBar
         message="Cambios no guardados"
         saveAction={{
            onAction: handleSave,
            disabled: !isDirty || isEditing,
            loading: isEditing
         }}
         discardAction={{
            onAction: handleDiscard,
            disabled: isEditing
         }}
      />
   ) : null;

   if (isLoading) {
      return (
         <Layer title="Páginas" forbidden={forbidden}>
            <PaginasScreenSkeleton />
         </Layer>
      );
   }

   return (
      <Layer title="Páginas" forbidden={forbidden}>
         <Page
            title="Páginas"
            breadcrumbs={[{ content: 'Configuración', url: '/admin/configuracion' }]}
            separator
         >
            {contextualSaveBarMarkup}
            <Layout>
               <Layout.AnnotatedSection
                  title="Páginas legales"
                  description="Puedes crear tus propias páginas legales, se mostrarán en el pié de tu sitio web."
               >
                  {paginas.map((pagina, index) => {
                     const { titulo, descripcion, contenido } = pagina;
                     return (
                        <div key={index} className="my-8">
                           <PaginasCard
                              titulo={titulo}
                              descripcion={descripcion}
                              contenido={contenido}
                              handleFieldChange={handleFieldChange}
                           />
                        </div>
                     );
                  })}
               </Layout.AnnotatedSection>
               <Layout.AnnotatedSection>
                  <div className="flex justify-end">
                     <ButtonGroup fullWidth={false} connectedTop={false}>
                        <Button disabled={isEditing} onClick={handleDiscard}>Descartar</Button>
                        <Button primary onClick={handleSave} disabled={!isDirty || isEditing}>Guardar</Button>
                     </ButtonGroup>
                  </div>
               </Layout.AnnotatedSection>
            </Layout>
            <HelpFooter
               title="páginas"
               url="https://help.b2bgo.mx/configuracion/paginas"
            />
         </Page>
      </Layer>
   )
}

export default ComerciosPaginasScreen;
