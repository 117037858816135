import React, { useState, useEffect } from "react";
import {
  Page,
  Card,
  Badge,
  Layout,
  TextStyle,
  Link,
  FooterHelp,
  EmptyState,
  Spinner,
  Button,
} from "@shopify/polaris";

import moment from "moment";

import {
  Layer,
  ArticuloItem,
  ApproveCard,
  CustomBadge,
  ComercioDetalleSkeleton,
  Timeline,
} from "../../components";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { setIsLoadingProgressBar } from "../../actions/InteractiveActions";
import {
  getPaginationPedidos,
  getUserInfo,
  getNombreComercio,
  getComercioData,
} from "../../reducers";
import { extractInitials } from "../../utils/stringModifiers";
import PedidoPDF from "../../components/common/PedidoPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import useGetCurrencies from "../../hooks/useGetCurrencies";
import iconPending from "../PedidosDetalleScreen/pending.svg";
import iconSupplied from "../PedidosDetalleScreen/supplied.svg";

export default function PedidosClienteDetalleScreen({ forbidden }) {
  const { id } = useParams();
  const router = useHistory();
  const dispatch = useDispatch();
  const grant_type = useSelector((state) => state.Auth.grant_type);
  const usuario = useSelector(getUserInfo);
  const [pedidoDB, setPedido] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const pageState = useSelector((state) => state.Pedidos.allIdsDB);
  const pagination = useSelector(getPaginationPedidos);
  const [showHistorial, setShowHistorial] = useState(true);
  const comercio = useSelector(getNombreComercio);
  const comercioData = useSelector(getComercioData);
  const url_imagen = useSelector((state) => state.Auth.url_imagen);
  const { currencies } = useGetCurrencies();
  const [showTax, setShowTax] = useState(false);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      dispatch(setIsLoadingProgressBar({ active: false }));
      ApiServiceComercios.pedidoDetalle(id)
        .then(({ ok, pedido }) => {
          if (ok) {
            setPedido({
              ...pedido,
              articulos:
                pedido.articuloFlete && pedido.articuloFlete.nombre
                  ? pedido.articulos.filter(
                      (i) => i.articulo._id !== pedido.articuloFlete.id
                    )
                  : pedido.articulos.filter(
                      (i) => i.articulo.nombre.indexOf("Flete") < 0
                    ),
            });
          }
        })
        .catch((err) => {})
        .finally(() => setIsLoading(false));
      ApiServiceComercios.obtenerPreferenciasComercio().then(({ impuesto }) => {
        setShowTax(impuesto.mostrarPrecioConImpuesto);
      });
    };
    fetchData();
    return () => null;
  }, [id, dispatch]);

  function duplicarPedido() {
    router.push("/cliente/pedidos/agregar", {
      articulos: pedidoDB.articulos.map((i) => ({ ...i, ...i.articulo })),
      almacenId: pedidoDB.cliente.almacen.id,
      notas: pedidoDB.notas,
    });
  }

  if (isLoading) {
    return (
      <Layer title={`Pedido`} forbidden={forbidden}>
        <ComercioDetalleSkeleton fullWidth />
      </Layer>
    );
  }

  if (pedidoDB === null) {
    return (
      <Layer title={`Pedido`} forbidden={forbidden}>
        <EmptyState
          heading="Oops! Hubo un problema al intentar obtener este pedido"
          action={{
            content: "Volver a intentar",
            onAction: () => window.location.reload(),
          }}
          secondaryAction={{
            content: "Regresar a pedidos",
            onAction: () => router.goBack(),
          }}
          image="/Ad.svg"
        >
          <p>Intenta de nuevo más tarde</p>
        </EmptyState>
      </Layer>
    );
  }

  function setStatus(envio) {
    switch (envio) {
      case "Enviado":
        return "complete";
      case "Pendiente":
        return "incomplete";
      case "En ruta":
        return "partiallyComplete";
      default:
        return null;
    }
  }

  function previousPage() {
    const prevId = pageState[pageState.indexOf(id) - 1];
    router.push(`/cliente/pedidos/${prevId}`);
  }

  function nextPage() {
    const nextId = pageState[pageState.indexOf(id) + 1];
    router.push(`/cliente/pedidos/${nextId}`);
  }

  function readDirectionEnvio(direction) {
    return (
      <div className="flex flex-col">
        <TextStyle>
          {direction.direccion && direction.direccion + ", "}
          {direction.ciudad && direction.ciudad + ", "}
          {direction.estado && direction.estado + ", "}
          {direction.codigo_postal && "C.P. " + direction.codigo_postal}.
        </TextStyle>
        <TextStyle variation="subdued">{direction.telefono}</TextStyle>
      </div>
    );
  }

  function readDirectionFacturacion(direction) {
    if (
      pedidoDB.direccionEnvioId &&
      pedidoDB.direccionEnvioId === pedidoDB.direccionFacturacionId
    ) {
      return <TextStyle>La misma dirección de envío</TextStyle>;
    } else {
      return (
        <div className="flex flex-col">
          <TextStyle>
            {direction.direccion && direction.direccion + ", "}
            {direction.ciudad && direction.ciudad + ", "}
            {direction.estado && direction.estado + ", "}
            {direction.codigo_postal && "C.P. " + direction.codigo_postal}.
          </TextStyle>
          <TextStyle variation="subdued">{direction.telefono}</TextStyle>
        </div>
      );
    }
  }

  const titleMetada = (
    <div className="flex items-center">
      <CustomBadge
        progress={setStatus(pedidoDB.estatus.nombre)}
        status={pedidoDB.estatus.nombre}
      />
      <Badge status={pedidoDB.isInExsim ? "success" : "warning"}>
        {pedidoDB.isInExsim ? "Sincronizado" : "No sincronizado"}
      </Badge>
    </div>
  );

  const subtitleMetada = (
    <>
      {moment(pedidoDB.createdDate).format("D ")} de{" "}
      {moment(pedidoDB.createdDate).format("MMMM YYYY, HH:mm")}
      {pedidoDB.contact
        ? `, ${pedidoDB.contact?.name} ${pedidoDB.contact?.last_name}`
        : ""}
      {pedidoDB.isFromMicrosip ? ", Microsip" : ", B2BGo"}
    </>
  );

  const data = { ...pedidoDB, comercio, comercioData, url_imagen };

  function validateIcon() {
    return pedidoDB?.estatus?.nombre === "Surtido" ? iconSupplied : iconPending;
  }

  function validateTitle() {
    return pedidoDB?.estatus?.nombre || "";
  }

  const statusSeguimiento = (
    <div className="flex items-center gap-4">
      <div>
        <img src={validateIcon()} alt="iconStatus" width="32" />
      </div>
      <TextStyle variation="strong">{validateTitle()}</TextStyle>
    </div>
  );

  return (
    <Layer title={`Detalle pedido ${pedidoDB.folio}`} forbidden={forbidden}>
      <Page
        fullWidth
        title={`${pedidoDB.folio}`}
        titleMetadata={titleMetada}
        subtitle={subtitleMetada}
        breadcrumbs={[{ content: "Pedidos", onAction: () => router.goBack() }]}
        pagination={{
          hasPrevious:
            pageState.indexOf(id) !== 0 ||
            pagination.currentPage * pagination.sizePerPage !== 0,
          onPrevious: previousPage,
          nextTooltip: "Siguiente pedido",
          hasNext: pageState.indexOf(id) + 1 < pageState.length,
          onNext: nextPage,
          previousTooltip: "Pedido anterior",
        }}
        secondaryActions={[
          {
            content: "Duplicar",
            onAction: duplicarPedido,
          },
          {
            content: (
              <div className="relative z-50">
                <PDFDownloadLink
                  document={<PedidoPDF data={data} />}
                  fileName={`${comercio} - Pedido ${pedidoDB.folio}`}
                >
                  <div className="text-gray-800">
                    <TextStyle>Descargar</TextStyle>
                  </div>
                  {({ loading }) => {
                    return (
                      <>
                        {loading ? (
                          <div className="inline-flex mx-8">
                            <Spinner size="small" />
                          </div>
                        ) : (
                          <div className="flex text-gray-600 no-underline hover:text-gray-800">
                            <TextStyle variation="strong">Descargar</TextStyle>
                          </div>
                        )}
                      </>
                    );
                  }}
                </PDFDownloadLink>
              </div>
            ),
          },
        ]}
      >
        <Layout>
          <Layout.Section>
            <Card title={statusSeguimiento}>
              <Card.Section>
                <div className="flex flex-col gap-3">
                  <div className="flex flex-col gap-1">
                    <TextStyle variation="subdued">Sucursal</TextStyle>
                    <TextStyle>
                      {pedidoDB?.cliente?.almacen?.nombre || "--"}
                    </TextStyle>
                  </div>
                  <div className="flex flex-col gap-1">
                    <TextStyle variation="subdued">
                      {pedidoDB?.tracking?.id?.name}
                    </TextStyle>

                    <div className="flex">
                      <Link
                        url={
                          pedidoDB?.tracking && pedidoDB?.tracking?.url
                            ? `${pedidoDB?.tracking?.url}${
                                pedidoDB?.tracking?.url.charAt(
                                  pedidoDB?.tracking?.url.length - 1
                                ) === "/"
                                  ? ""
                                  : "/"
                              }${pedidoDB?.tracking?.key}`
                            : ""
                        }
                      >
                        {pedidoDB?.tracking?.key}
                      </Link>
                    </div>
                  </div>
                </div>

                {pedidoDB.articulos
                  .slice(
                    0,
                    showAll && pedidoDB.articulos.length > 5
                      ? pedidoDB.articulos.length
                      : 5
                  )
                  .map((articulo, index) => {
                    return (
                      <div
                        key={
                          articulo._id ||
                          "_" + Math.random().toString(36).substr(2, 9)
                        }
                      >
                        {articulo.articulo ? (
                          <ArticuloItem
                            disabled
                            articulo={{
                              ...articulo.articulo,
                              precioDeLista: articulo.precioDeLista,
                              totalPorcentajeDescuento:
                                articulo.totalPorcentajeDescuento,
                              totalPrecioConDescuento:
                                articulo.totalPrecioConDescuento,
                              notas: articulo.notas,
                            }}
                            showAsDetail
                            quantity={Number(articulo.cantidad)}
                            handleChange={() => {}}
                            onRemove={() => {}}
                            isLast={pedidoDB.articulos.length - 1 === index}
                            currencies={currencies}
                            clientCurrency={pedidoDB?.moneda}
                            showTax={showTax}
                          />
                        ) : null}
                      </div>
                    );
                  })}
              </Card.Section>
              {pedidoDB.articulos.length > 5 && (
                <Card.Section>
                  <div className="flex justify-center">
                    <Button plain onClick={() => setShowAll((s) => !s)}>
                      {showAll
                        ? "Ver menos"
                        : `Ver todos los artículos (${pedidoDB.articulos.length})`}
                    </Button>
                  </div>
                </Card.Section>
              )}
            </Card>
            <ApproveCard
              subtotal={pedidoDB.subtotalPedido}
              impuesto={pedidoDB.impuestoSubtotalPedido}
              envio={{
                cantidad: pedidoDB.envio.precio,
                datos: { ...pedidoDB.envio },
              }}
              cantidad={pedidoDB.articulos.length}
              total={pedidoDB.total}
              weight={pedidoDB?.weight}
              showLimit={false}
              currency={pedidoDB.moneda ? pedidoDB.moneda : undefined}
            />
            {/* Feature */}
            <div className="my-8">
              <Timeline
                visible={showHistorial}
                onChange={setShowHistorial}
                initials={extractInitials(
                  grant_type === "CONTACT"
                    ? usuario.name
                    : usuario.nombre.split(" ")[0],
                  grant_type === "CONTACT"
                    ? usuario.last_name
                    : usuario.nombre.split(" ")[1]
                )}
                parentMessageId={id}
                subtitle="Todos pueden ver los comentarios."
              />
            </div>
          </Layout.Section>
          <Layout.Section secondary>
            <Card fullWidth>
              <Card.Section title="Contacto">
                <div className="flex flex-col">
                  <TextStyle variation="subdued">
                    {pedidoDB.cliente.nombre || ""}
                  </TextStyle>
                  <Link>{pedidoDB.cliente.email || ""}</Link>
                </div>
              </Card.Section>
              {!pedidoDB.direccionEnvioId ? (
                <Card.Section title="Dirección">
                  <div className="flex flex-col">
                    <TextStyle variation="subdued">
                      No se suministró la dirección de envío
                    </TextStyle>
                  </div>
                </Card.Section>
              ) : (
                <Card.Section title="Dirección de envío">
                  {pedidoDB.direccionEnvioId &&
                  pedidoDB.cliente.direcciones.some(
                    (i) => i.consignatario_id === pedidoDB.direccionEnvioId
                  ) ? (
                    readDirectionEnvio(
                      pedidoDB.cliente.direcciones.find(
                        (i) => i.consignatario_id === pedidoDB.direccionEnvioId
                      )
                    )
                  ) : (
                    <TextStyle>
                      Aún no hay una dirección de envío asignada
                    </TextStyle>
                  )}
                </Card.Section>
              )}
              {!pedidoDB.direccionFacturacionId ? (
                <Card.Section title="Dirección">
                  <div className="flex flex-col">
                    <TextStyle variation="subdued">
                      No se suministró la dirección de facturación
                    </TextStyle>
                  </div>
                </Card.Section>
              ) : (
                <Card.Section title="Dirección de facturación">
                  {pedidoDB.direccionFacturacionId &&
                  pedidoDB.cliente.direcciones.some(
                    (i) =>
                      i.consignatario_id === pedidoDB.direccionFacturacionId
                  ) ? (
                    readDirectionFacturacion(
                      pedidoDB.cliente.direcciones.find(
                        (i) =>
                          i.consignatario_id === pedidoDB.direccionFacturacionId
                      )
                    )
                  ) : (
                    <TextStyle>
                      Aún no hay una dirección de facturación asignada
                    </TextStyle>
                  )}
                </Card.Section>
              )}
              {pedidoDB.moneda ? (
                <Card.Section title="Moneda">
                  <TextStyle>
                    {pedidoDB.moneda.nombre}
                    {" ("}
                    {pedidoDB.moneda.claveFiscal}
                    {")"}
                  </TextStyle>
                </Card.Section>
              ) : null}
            </Card>
            <Card sectioned title="Notas">
              <TextStyle>{pedidoDB.notas}</TextStyle>
            </Card>
            <Card sectioned title="Vendedor">
              <TextStyle>{pedidoDB?.vendedor?.name}</TextStyle>
            </Card>
          </Layout.Section>
        </Layout>
        <FooterHelp>
          Más información de <Link>pedidos</Link>
        </FooterHelp>
      </Page>
    </Layer>
  );
}
