import ApiServiceComercios from "../services/ApiServiceComercios";
import NormalizeById from "../utils/NormalizedById";
import { getPaginationArticulos } from "../reducers";

export const ARTICULOS_BEGIN = "ARTICULOS_BEGIN";
export const ADD_ARTICULOS = "ADD_ARTICULOS";
export const ARTICULOS_FAILED = "ARTICULOS_FAILED";
export const CLEAR_ARTICULOS = "CLEAR_ARTICULOS";

export const ITEMS_BEGIN = "ITEMS_BEGIN";
export const ADD_FILTERED_ITEMS = "ADD_FILTERED_ITEMS";
export const FILTERED_ITEMS_FAILED = "FILTERED_ITEMS_FAILED";
export const FILTERED_CLEAR_ITEMS = "FILTERED_CLEAR_ITEMS";

export const RESET_PAGE = "RESET_PAGE";
export const CHANGE_PAGE = "CHANGE_PAGE";

export function getItemsByFilters(params) {
  return async (dispatch, getState) => {
    dispatch(clearFilteredItems());
    dispatch(itemsBegin());
    let pagination = getPaginationArticulos(getState());
    return ApiServiceComercios.getItemsByFilters({
      ...params,
      limit: pagination.sizePerPage,
      skip: pagination.currentPage * pagination.sizePerPage,
    })
      .then((result) => {
        let data = NormalizeById(result.articulos, "_id");
        data["total"] = result.total;
        data["allIdsDB"] = result.articulos.map((i) => i._id);
        data["tags"] = result.tags;
        data["productTypes"] = result.productTypes || [];
        data["brands"] = result.brands || [];
        data["tags"] = result.tags || [];
        data["options"] = result.options || [];
        dispatch(addFilteredItems(data));
        return result;
      })
      .catch((err) => {
        dispatch(filteredItemsFailed());
      });
  };
}

export function fecthArticulos(params, toCreateOrder = false) {
  return async (dispatch, getState) => {
    dispatch(clearArticulos());
    dispatch(articulosBegin());
    let pagination = getPaginationArticulos(getState());
    return ApiServiceComercios.obtenerArticulos(
      {
        ...params,
        limit: pagination.sizePerPage,
        skip: pagination.currentPage * pagination.sizePerPage,
      },
      toCreateOrder
    )
      .then((result) => {
        let data = NormalizeById(result.articulos, "_id");
        data["total"] = result.total;
        data["allIdsDB"] = result.allIds;
        dispatch(addArticulos(data));
        return result;
      })
      .catch((err) => {
        dispatch(articulosFailed());
      });
  };
}

export const articulosBegin = () => ({
  type: ARTICULOS_BEGIN,
});

export const addArticulos = (data) => ({
  type: ADD_ARTICULOS,
  payload: { data },
});

export const articulosFailed = () => ({
  type: ARTICULOS_FAILED,
});

export const clearArticulos = () => ({
  type: CLEAR_ARTICULOS,
});

export const changePage = (currentPage) => ({
  type: CHANGE_PAGE,
  payload: { data: { currentPage } },
});

export const resetPage = () => ({
  type: RESET_PAGE,
});

export const itemsBegin = () => ({
  type: ITEMS_BEGIN,
});

export const addFilteredItems = (data) => ({
  type: ADD_FILTERED_ITEMS,
  payload: { data },
});

export const filteredItemsFailed = () => ({
  type: FILTERED_ITEMS_FAILED,
});

export const clearFilteredItems = () => ({
  type: FILTERED_CLEAR_ITEMS,
});
