import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Card,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextStyle,
} from "@shopify/polaris";
import { ChatIntegration, HelpFooter, Layer } from "../../components";
import { useDispatch } from "react-redux";
import { toggleToast } from "../../actions/InteractiveActions";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import "./ChatIntegracionScreenStyles.css";

export default function ChatIntegracionScreen({ forbidden }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState("GORGIAS");
  const [openModal, setOpenModal] = useState(false);
  const [chatError, setChatError] = useState("");
  const [isSavingChat, setIsSavingChat] = useState(false);
  const [chat, setChat] = useState("");
  const defaultChat = useRef({ chat: "", script: "" });

  const handleModal = useCallback(() => setOpenModal(!openModal), [openModal]);
  const handleSelectChange = useCallback((value) => setSelected(value), []);

  useEffect(() => {
    ApiServiceComercios.obtenerChatIntegracion()
      .then((result) => {
        if (result.ok) {
          const { comercio } = result;
          if (comercio.chat) {
            defaultChat.current = {
              chat: comercio.chat.tipo,
              script: comercio.chat.script,
            };
            setChat(comercio.chat.script);
            setSelected(comercio.chat.tipo);
          }
        }
      })
      .catch((err) => {})
      .finally(() => setIsLoading(false));
  }, []);

  function handleSaveChat() {
    if (validateChatScript()) {
      setIsSavingChat(true);
      ApiServiceComercios.actualizarChatIntegracion({
        tipo: selected,
        script: chat,
      })
        .then((result) => {
          if (result.ok) {
            dispatch(toggleToast({ message: result.message }));
            defaultChat.current = { chat: selected, script: chat };
          }
        })
        .catch((error) => {
          setSelected(defaultChat.current.chat);
          setChat(defaultChat.current.script);
        })
        .finally(() => {
          setIsSavingChat(false);
          handleModal();
          setChatError("");
        });
    } else {
      setChatError(
        "El script que ha agregado no es válido para el tipo de chat seleccionado"
      );
    }
  }

  function validateChatScript() {
    const regExTawkto = new RegExp("tawk", "i");
    const regExGorgias = new RegExp("gorgias", "i");
    switch (selected) {
      case "GORGIAS":
        return chat.match(regExGorgias);
      case "TAWKTO":
        return chat.match(regExTawkto);
      default:
        break;
    }
  }

  function onOpenModal(value) {
    setSelected(value);
    handleSelectChange(value);
    handleModal();
  }

  function onClose() {
    setChatError("");
    setSelected(defaultChat.current.chat);
    setChat(defaultChat.current.script);
    handleModal();
  }

  if (isLoading) {
    return (
      <Layer title="Integraciones" forbidden={forbidden}>
        <SkeletonPage breadcrumbs>
          <Layout>
            <Layout.AnnotatedSection
              description={<SkeletonBodyText lines={2} />}
              title={<SkeletonDisplayText size="small" />}
            >
              <Card>
                <Card.Section>
                  <SkeletonDisplayText size="small" />
                  <div className="my-8" />
                  <SkeletonBodyText lines={2} />
                </Card.Section>
              </Card>
            </Layout.AnnotatedSection>
          </Layout>
        </SkeletonPage>
      </Layer>
    );
  }
  return (
    <Layer title="Chat" forbidden={forbidden}>
      <Page
        title="Chats"
        breadcrumbs={[
          { content: "Configuración", url: "/admin/configuracion" },
        ]}
      >
        <Layout>
          <Layout.AnnotatedSection
            title="Proveedores de chats"
            description="Este botón aparece en tu tienda online y permite a los clientes contactarte directamente"
          >
            <Card
              sectioned
              secondaryFooterActions={[
                {
                  content:
                    defaultChat.current.chat === "GORGIAS"
                      ? "Activo"
                      : "Activar Gorgias",
                  onAction: () => onOpenModal("GORGIAS"),
                },
              ]}
              title={
                <div className="flex">
                  <img className="chat-logo" src="/gorgias-logo.png" alt="" />
                </div>
              }
            >
              <TextStyle>
                Gorgias es centro de atención al cliente para tiendas de
                comercio electrónico que le permite a tu equipo de servicio al
                cliente administrar todo su soporte, servicio al cliente en un
                solo lugar.
              </TextStyle>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection>
            <Card
              sectioned
              secondaryFooterActions={[
                {
                  content:
                    defaultChat.current.chat === "TAWKTO"
                      ? "Activo"
                      : "Activar Tawk.to",
                  onAction: () => onOpenModal("TAWKTO"),
                },
              ]}
              title={
                <div className="flex">
                  <img className="chat-logo" src="/tawk-to.png" alt="" />
                </div>
              }
            >
              <TextStyle>
                tawk.to es una aplicación de mensajería gratuita que te permite
                monitorear y conversar con tus clientes.
              </TextStyle>
            </Card>
          </Layout.AnnotatedSection>
          {openModal && (
            <ChatIntegration
              onClose={onClose}
              chatError={chatError}
              isLoading={isSavingChat}
              handleSave={handleSaveChat}
              selected={selected}
              chat={chat}
              openModal={openModal}
              setChat={setChat}
            />
          )}
        </Layout>
        <HelpFooter
          title="chats"
          url="https://help.b2bgo.mx/configuracion/chat"
        />
      </Page>
    </Layer>
  );
}
