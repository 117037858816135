import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  EmptyState,
  Filters,
  Pagination,
  TextStyle,
} from "@shopify/polaris";
import { Table } from "antd";

import img from "./img.png";
import SkeletonTable from "../SkeletonTable";

export default function CollectionsList({
  collections = [],
  filters,
  query = "",
  handleQuery,
  collectionsSelected = [],
  clearCollectionTypes,
  isLoading = false,
  pagination,
  handlePagination,
}) {
  const history = useHistory();

  const navigate = (id) => history.push(`/admin/articulos/colecciones/${id}`);

  const typeCollections = {
    manual: "Manual",
    auto: "Automática",
  };

  const emptyState = (
    <EmptyState
      heading="Agrupa tus productos en colecciones"
      action={{
        content: "Crear colección",
        onAction: () => history.push("/admin/articulos/colecciones/agregar"),
      }}
      image={img}
      imageContained={false}
    >
      <p>
        Utiliza colecciones para organizar tus productos por categorías, podrás
        usarla en el diseño de tu navegación
      </p>
    </EmptyState>
  );

  const handleQueryClear = useCallback(() => {
    handleQuery("");
  }, [handleQuery]);

  const clearAllFilters = useCallback(() => {
    handleQueryClear();
    clearCollectionTypes();
  }, [handleQueryClear, clearCollectionTypes]);

  let appliedFilters = [];
  if (!isEmpty(collectionsSelected)) {
    const key = "collectionTypes";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, collectionsSelected),
      onRemove: clearCollectionTypes,
    });
  }

  const onNext = () => {
    handlePagination(({ skip, limit }) => ({
      limit,
      skip: skip + limit,
    }));
  };

  const onPrev = () => {
    handlePagination(({ skip, limit }) => ({
      limit,
      skip: skip - limit,
    }));
  };

  return (
    <Card.Section>
      <Filters
        queryPlaceholder="Filtrar colecciones"
        filters={filters}
        queryValue={query}
        onQueryChange={handleQuery}
        onQueryClear={handleQueryClear}
        appliedFilters={appliedFilters}
        onClearAll={clearAllFilters}
      />

      {isLoading ? (
        <SkeletonTable rowsCount={4} headingsCount={4} />
      ) : (
        <Table
          dataSource={collections}
          locale={{ emptyText: emptyState }}
          pagination={false}
          columns={[
            {
              title: "Nombre",
              dataIndex: "name",
              render: (name, { key }) => (
                <div
                  className="w-full cursor-pointer whitespace-nowrap"
                  onClick={() => navigate(key)}
                >
                  <TextStyle>{name}</TextStyle>
                </div>
              ),
            },
            {
              title: "Artículos",
              dataIndex: "articles",
              render: (articles, { key }) => (
                <div
                  className="w-full cursor-pointer whitespace-nowrap"
                  onClick={() => navigate(key)}
                >
                  <TextStyle>{articles}</TextStyle>
                </div>
              ),
            },
            {
              title: "Tipo de colección",
              dataIndex: "collectionTypes",
              render: (type, { key }) => (
                <div
                  className="w-full cursor-pointer whitespace-nowrap"
                  onClick={() => navigate(key)}
                >
                  <TextStyle>{typeCollections[type]}</TextStyle>
                </div>
              ),
            },
          ]}
        />
      )}
      <div className="flex justify-center pt-4">
        <Pagination
          hasPrevious={pagination?.skip > 0}
          onPrevious={onPrev}
          hasNext={collections.length >= pagination?.limit}
          onNext={onNext}
        />
      </div>
    </Card.Section>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "collectionTypes":
        return value
          .map((val) => `Colección ${typeCollections[val]}`)
          .join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
}
