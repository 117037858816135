import React, { useState, useCallback } from "react";
import {
  Badge,
  Card,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  SkeletonThumbnail,
  TextField,
  Stack,
  EmptyState,
  FormLayout,
  DataTable,
} from "@shopify/polaris";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { toggleToast } from "../../actions/InteractiveActions";
import { HelpFooter, Layer } from "../../components";
import FeeModal from "./components/FeeModal";
import { formatTo2Digits } from "../../utils/Formats";
import useToggle from "../../hooks/useToggle";

export default function EnvioDetalleScreen({ forbidden }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [active, toggleActive] = useToggle();
  const [isLoading, setIsLoading] = useState(true);
  const [envio, setEnvio] = useState(null);
  const [isEditingEnvio, setIsEditingEnvio] = useState(false);
  const [error, setError] = useState("");
  const [hasShipmentFees, setHasShipmentFees] = useState(false);
  const [currentShippingFee, setCurrentShippingFee] = useState();
  const [currentIndex, setCurrentIndex] = useState(null);

  useEffect(() => {
    ApiServiceComercios.obtenerEnvio(id)
      .then(({ ok, envio }) => {
        if (ok) {
          setEnvio(envio);
          setHasShipmentFees(envio?.shippingFees);
        }
      })
      .finally(() => setIsLoading(false));
  }, [id]);

  const handleFieldChange = useCallback((value, key) => {
    if (key === "precio" && !isNaN(value)) {
      setEnvio((state) => ({ ...state, [key]: value }));
    } else {
      setEnvio((state) => ({ ...state, [key]: value }));
    }
  }, []);

  function handleSaveFee(fees = {}, index = null, toDelete = false) {
    let newfees = envio?.shippingFees ?? [];
    setIsEditingEnvio(true);
    if (index !== null && !toDelete) {
      newfees[index] = fees;
    } else if (toDelete) {
      newfees.splice(index, 1);
    } else if (Object.keys(fees).length > 0) {
      newfees.push(fees);
    }

    ApiServiceComercios.actualizarEnvio(
      { ...envio, precio: Number(envio.precio), shippingFees: newfees },
      id
    )
      .then(({ ok, message }) => {
        setIsEditingEnvio(false);
        if (ok) dispatch(toggleToast({ message }));

        active && toggleActive();
      })
      .catch((err) => {
        setIsEditingEnvio(false);
        if (err.response.data) {
          setError(err.response.data.message);
        }
      })
      .finally(() => {
        setCurrentShippingFee(null);
        setCurrentIndex(null);
      });
  }

  const onHandleEdit = useCallback(
    (params, index) => {
      setCurrentShippingFee(params);
      setCurrentIndex(index);
      toggleActive();
    },
    [setCurrentShippingFee, setCurrentIndex, toggleActive]
  );

  const handleOnBlur = (value, key) => {
    if (!value) {
      setEnvio((state) => ({ ...state, [key]: "0" }));
    }
  };

  if (isLoading) {
    return (
      <Layer title="Envío y entrega" forbidden={forbidden}>
        <SkeletonPage
          breadcrumbs
          title={<SkeletonDisplayText size="medium" />}
          primaryAction={<SkeletonDisplayText size="medium" />}
        >
          <Layout>
            <Layout.AnnotatedSection
              title={<SkeletonDisplayText size="medium" />}
              description={<SkeletonBodyText lines={2} />}
            >
              {Array.from(Array(1).keys()).map((item) => {
                return (
                  <div key={item} className="mb-8">
                    <Card sectioned>
                      <Stack>
                        <SkeletonThumbnail size="small" />
                        <div style={{ minWidth: 300 }}>
                          <SkeletonBodyText lines={2} />
                        </div>
                      </Stack>
                    </Card>
                  </div>
                );
              })}
            </Layout.AnnotatedSection>
          </Layout>
        </SkeletonPage>
      </Layer>
    );
  }

  const EmptyStateMarkup = () => {
    return (
      <EmptyState
        heading="No hay tarifas registradas"
        image=""
        action={{ content: "Agregar tarifa", onAction: toggleActive }}
      >
        <p>Configura tus propias tarifas para tus pedidos</p>
      </EmptyState>
    );
  };

  if (envio === null) {
    return (
      <Layer title={`Envio`} forbidden={forbidden}>
        <EmptyState
          heading="Oops! Hubo un problema al intentar obtener este envío"
          action={{
            content: "Volver a intentar",
            onAction: () => window.location.reload(),
          }}
          secondaryAction={{
            content: "Regresar a envíos",
            url: "/admin/configuracion/envio-entregas",
          }}
          image="/Ad.svg"
        >
          <p>Intenta de nuevo más tarde</p>
        </EmptyState>
      </Layer>
    );
  }

  return (
    <Layer title="Envio" forbidden={forbidden}>
      <Page
        title="Envio y entrega"
        breadcrumbs={[
          {
            content: "Envío y entrega",
            url: "/admin/configuracion/envio-entregas",
          },
        ]}
        separator
        primaryAction={{
          content: "Guardar",
          onAction: () => handleSaveFee(),
          disabled: isEditingEnvio || !envio.nombre,
          loading: isEditingEnvio,
        }}
      >
        <Layout>
          <Layout.AnnotatedSection
            title="Detalles del envío"
            description="Dale a este envío un nombre para identificarla fácilmente y un precio. Los clientes verán esta información en sus pedidos. Para cobrar tarifas diferentes, según el precio del pedido, crea una nueva tarifa."
          >
            <Card sectioned>
              <FormLayout>
                <TextField
                  label="Nombre del envío"
                  type="text"
                  autoComplete="off"
                  placeholder="Envio"
                  value={envio.nombre}
                  onChange={(text) => handleFieldChange(text, "nombre")}
                  error={error}
                />
                <TextField
                  label="Precio"
                  type="number"
                  min={0}
                  step={1}
                  value={String(envio.precio)}
                  onChange={(value) => handleFieldChange(value, "precio")}
                  onBlur={() => handleOnBlur(envio.precio, "precio")}
                  prefix="$"
                  suffix={Number(envio.precio) === 0 && <Badge>Gratis</Badge>}
                />
              </FormLayout>
            </Card>
            <Card
              sectioned
              title="Tarifas"
              actions={[{ content: "Agregar", onAction: toggleActive }]}
            >
              {!hasShipmentFees?.length ? (
                EmptyStateMarkup()
              ) : (
                <DataTable
                  hasZebraStripingOnData
                  headings={[
                    "Nombre",
                    "Precio de envío",
                    "Precio mínimo",
                    "Precio máximo",
                  ]}
                  columnContentTypes={["text", "numeric", "numeric", "numeric"]}
                  rows={hasShipmentFees?.map(
                    (
                      { feeTitle = "", price = 0, minPrice = 0, maxPrice = 0 },
                      index
                    ) => {
                      const feeTitleLabel = (
                        <div
                          className="cursor-pointer"
                          onClick={() =>
                            onHandleEdit(
                              { feeTitle, maxPrice, minPrice, price },
                              index
                            )
                          }
                        >
                          {feeTitle}
                        </div>
                      );
                      const priceLabel = (
                        <div
                          className="cursor-pointer"
                          onClick={() =>
                            onHandleEdit(
                              { feeTitle, maxPrice, minPrice, price },
                              index
                            )
                          }
                        >
                          {Number(price) === 0
                            ? "Gratis"
                            : formatTo2Digits(price)}
                        </div>
                      );

                      const minPriceLabel = (
                        <div
                          className="cursor-pointer"
                          onClick={() =>
                            onHandleEdit(
                              { feeTitle, maxPrice, minPrice, price },
                              index
                            )
                          }
                        >
                          {formatTo2Digits(minPrice)}
                        </div>
                      );

                      const maxPriceLabel = (
                        <div
                          className="cursor-pointer"
                          onClick={() =>
                            onHandleEdit(
                              { feeTitle, maxPrice, minPrice, price },
                              index
                            )
                          }
                        >
                          {Number(maxPrice) === 0
                            ? "Sin límite"
                            : formatTo2Digits(maxPrice)}
                        </div>
                      );

                      return [
                        feeTitleLabel,
                        priceLabel,
                        minPriceLabel || 0,
                        maxPriceLabel || 0,
                      ];
                    }
                  )}
                />
              )}
              {active ? (
                <FeeModal
                  active={active}
                  currentShippingFee={currentShippingFee}
                  currentIndex={currentIndex}
                  onClose={() => {
                    toggleActive();
                    setCurrentIndex(null);
                    setCurrentShippingFee(null);
                  }}
                  handleSaveFee={handleSaveFee}
                />
              ) : null}
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
        <HelpFooter
          title="envío y entrega"
          url="https://help.b2bgo.mx/configuracion/envio-y-entrega"
        />
      </Page>
    </Layer>
  );
}
