import React from "react";
import { Button, ChoiceList, Icon, Popover, TextStyle } from "@shopify/polaris";
import useToggle from "../../hooks/useToggle";
import { ChevronDownMinor } from "@shopify/polaris-icons";

export default function PopoverOptions({
  title = "",
  handleClear = () => {},
  options = [],
  selectedOptions = [],
  handleChange = () => {},
  children = null,
  useClearButton = true,
  useTitleInButton = true,
}) {
  const [open, toggleOpen] = useToggle();

  const activator = (
    <Button plain removeUnderline disclosure="" monochrome onClick={toggleOpen}>
      <div className="flex items-center">
        {useTitleInButton && title}
        <Icon source={ChevronDownMinor} />
      </div>
    </Button>
  );

  return (
    <div className="mx-6">
      <Popover activator={activator} active={open} onClose={toggleOpen}>
        <Popover.Pane fixed sectioned>
          <Popover.Section>
            <div className="flex justify-between">
              <TextStyle>{title}</TextStyle>
              {useClearButton && (
                <Button plain onClick={() => handleClear([])}>
                  Limpiar
                </Button>
              )}
            </div>
          </Popover.Section>
        </Popover.Pane>
        <Popover.Pane sectioned>
          <Popover.Section>
            <div
              className="overflow-scroll"
              style={{ maxHeight: "calc(100vh / 2)", width: 300 }}>
              {children && <div className="my-2">{children}</div>}
              <ChoiceList
                choices={options}
                selected={selectedOptions}
                onChange={handleChange}
                allowMultiple
              />
            </div>
          </Popover.Section>
        </Popover.Pane>
      </Popover>
    </div>
  );
}
