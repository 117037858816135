import React, { useState, useRef, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Autocomplete,
  Banner,
  Button,
  Card,
  FormLayout,
  Icon,
  Modal,
  SkeletonBodyText,
  Stack,
  TextField,
  DataTable,
  TextStyle,
} from "@shopify/polaris";
import {
  SearchMajor,
  MobileHorizontalDotsMajor,
  EditMajor,
  DeleteMajor,
} from "@shopify/polaris-icons";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import CustomPopover from "../CustomPopover";
import { onBlurTextField } from "../../utils/TextFieldValidations";

export default function LineaArticuloModal(props) {
  const [lineaArt, setLinea] = useState({ porcentaje: "1", linea: "" });
  const [lineas, setLineas] = useState([]);
  const [line, setLine] = useState("");
  const deselectedOptions = useRef([]);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    ApiServiceComercios.obtenerLineas()
      .then(({ ok, lineas }) => {
        if (ok) {
          const willAdd = lineas
            .map((i) => ({ label: i.nombre, value: i.nombre }))
            .filter(
              (i) => !props.selected.map((i) => i.linea).includes(i.value)
            );
          deselectedOptions.current = willAdd;
          setOptions(willAdd);
        }
      })
      .finally(() => setIsLoading(false));
    const updatingLine = props.selected.find((s) => s.editing === true);
    if (updatingLine) {
      setLineas((s) => [
        ...s,
        {
          porcentaje: updatingLine.porcentaje,
          linea: updatingLine.linea,
          isEditing: false,
        },
      ]);
      setLinea((s) => ({
        ...s,
        linea: updatingLine.linea,
        porcentaje: updatingLine.porcentaje,
      }));
      setIsEditing(true);
      setLine(updatingLine.linea);
    }
    return () => null;
  }, [props.selected]);

  const updateSelection = useCallback((selected) => {
    setSelectedOptions(selected);
    changeState(selected[0], "linea");
    setLine(selected[0]);
  }, []);

  function updateText(value) {
    changeState(value, "linea");

    if (value === "") {
      setOptions(deselectedOptions);
      return;
    }
    const filterRegex = new RegExp(value, "i");
    const resultOptions = deselectedOptions.current
      .filter((i) => !lineas.map((l) => l.linea).includes(i.label))
      .filter((option) => option.label.match(filterRegex));
    setOptions(resultOptions);
  }

  function changeState(value, key) {
    setLinea((state) => ({ ...state, [key]: value }));
  }

  function addLineas() {
    props.onAddLinea(lineas);
    props.togleModal();
  }

  function addLinea() {
    setOptions(options.filter((i) => i.label !== line));
    setLineas((s) => [
      ...s,
      { porcentaje: lineaArt.porcentaje, linea: line, editing: false },
    ]);
    setLinea((s) => ({ ...s, linea: "", porcentaje: "0" }));
    setLine("");
  }

  function onRemoveItem(item) {
    setLineas(lineas.filter((i) => i.linea !== item));
    setOptions(
      _.orderBy([...options, { label: item, value: item }], ["label"], ["asc"])
    );
  }

  function onEditItem(item) {
    setIsEditing(true);
    setLinea((s) => ({
      ...s,
      linea: item,
      porcentaje: lineas.find((i) => i.linea === item)["porcentaje"],
    }));
    setLine(item);
  }

  function onSetEdit() {
    let arr = [...lineas];
    const index = _.findIndex(arr, { linea: line });
    arr.splice(index, 1, {
      porcentaje: lineaArt.porcentaje,
      linea: line,
      editing: false,
    });
    setLineas(arr);
    setLinea((s) => ({ ...s, linea: "", porcentaje: "0" }));
    setOptions(options.filter((i) => i.label !== line));
    setLine("");
    setIsEditing(false);
  }

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      prefix={<Icon source={SearchMajor} />}
      label="Línea de producto"
      value={lineaArt.linea}
      placeholder="Buscar tipo de producto"
      helpText={line}
      disabled={isEditing}
    />
  );

  if (isLoading) {
    return (
      <Modal
        title="Agregar línea de producto"
        open={props.isActive}
        onClose={props.togleModal}
      >
        <Modal.Section>
          <Card sectioned>
            <SkeletonBodyText lines={2} />
          </Card>
        </Modal.Section>
      </Modal>
    );
  }

  return (
    <Modal
      title="Agregar línea de producto"
      open={props.isActive}
      onClose={props.togleModal}
      secondaryActions={[{ content: "Cancelar", onAction: props.togleModal }]}
      primaryAction={{
        content: "Continuar",
        onAction: addLineas,
        disabled: lineas.length === 0,
      }}
    >
      <Modal.Section>
        <Card sectioned>
          <FormLayout>
            <Autocomplete
              options={options}
              selected={selectedOptions}
              onSelect={updateSelection}
              textField={textField}
              willLoadMoreResults
              listTitle="Líneas de productos existentes"
              loading={isLoading}
            />
            <Stack distribution="fillEvenly">
              <TextField
                min={1}
                max={100}
                align="right"
                suffix="%"
                type="number"
                label="Valor del descuento"
                value={lineaArt.porcentaje}
                onChange={(value) => changeState(value, "porcentaje")}
                onBlur={() =>
                  onBlurTextField(
                    lineaArt.porcentaje,
                    changeState,
                    100,
                    "porcentaje"
                  )
                }
              />
              <Button
                monochrome
                onClick={isEditing ? onSetEdit : addLinea}
                disabled={Number(lineaArt.porcentaje) === 0 || !line}
              >
                {isEditing ? "Editar" : "Agregar"}
              </Button>
            </Stack>
          </FormLayout>
          {lineas.length > 0 && (
            <DataTable
              headings={["Líneas de productos", "Descuento"]}
              columnContentTypes={["text", "text"]}
              rows={lineas.map(({ linea, porcentaje }) => {
                const Activator = ({ onClick }) => {
                  return (
                    <div className="flex items-center">
                      <div className="mr-4">
                        <TextStyle>{porcentaje} % </TextStyle>
                      </div>
                      <Button
                        plain
                        icon={MobileHorizontalDotsMajor}
                        onClick={onClick}
                      />
                    </div>
                  );
                };
                const lin = (
                  <TextStyle>
                    {linea}{" "}
                    {linea === line ? (
                      <TextStyle variation="subdued">...editando</TextStyle>
                    ) : null}{" "}
                  </TextStyle>
                );
                const porc = (
                  <CustomPopover
                    items={[
                      {
                        content: "Editar",
                        onAction: () => onEditItem(linea),
                        icon: EditMajor,
                      },
                      {
                        content: "Quitar",
                        onAction: () => onRemoveItem(linea),
                        icon: DeleteMajor,
                        destructive: true,
                      },
                    ]}
                    activator={Activator}
                  />
                );
                return [lin, porc];
              })}
            />
          )}
        </Card>
        <Banner status="info">
          <p>
            Los clientes pueden combinar descuentos por promoción, por cliente,
            por artículo y por volumen.
          </p>
        </Banner>
      </Modal.Section>
    </Modal>
  );
}

LineaArticuloModal.propTypes = {
  isActive: PropTypes.bool,
  togleModal: PropTypes.func,
  onAddLinea: PropTypes.func,
  selected: PropTypes.array,
};

LineaArticuloModal.defaultProps = {
  isActive: false,
  togleModal: () => {},
  onAddLinea: () => {},
  selected: [],
};
