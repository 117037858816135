export const INDUSTRIES_OPTIONS = [
   { label: "Agricultura", value: "Agricultura" },
   { label: "Alimentos y bebidas", value: "Alimentos y bebidas" },
   { label: "Automóviles y motocicletas", value: "Automóviles y motocicletas" },
   { label: "Belleza y cuidado personal", value: "Belleza y cuidado personal" },
   { label: "Bolsos, maletas y fundas", value: "Bolsos, maletas y fundas" },
   { label: "Calzado y accesorios", value: "Calzado y accesorios" },
   { label: "Componentes electrónicos y suministros", value: "Componentes electrónicos y suministros" },
   { label: "Construcción e inmobiliaria", value: "Construcción e inmobiliaria" },
   { label: "Deporte y entretenimiento", value: "Deporte y entretenimiento" },
   { label: "Electrodomésticos", value: "Electrodomésticos" },
   { label: "Electrónica de consumo", value: "Electrónica de consumo" },
   { label: "Embalaje, publicitarios y oficina ", value: "Embalaje, publicitarios y oficina " },
   { label: "Empaquetado e impresión", value: "Empaquetado e impresión" },
   { label: "Energía", value: "Energía" },
   { label: "Equipamiento electrónico, componentes y telecomunicaciones", value: "Equipamiento electrónico, componentes y telecomunicaciones" },
   { label: "Equipamiento eléctrico y suministros", value: "Equipamiento eléctrico y suministros" },
   { label: "Hardware", value: "Hardware" },
   { label: "Herramientas", value: "Herramientas" },
   { label: "Hogar y jardín", value: "Hogar y jardín" },
   { label: "Informática hardware y software", value: "Informática hardware y software" },
   { label: "Juguetes y hobbies", value: "Juguetes y hobbies" },
   { label: "Luces e iluminación", value: "Luces e iluminación" },
   { label: "Maquinaria", value: "Maquinaria" },
   { label: "Medición e instrumentos de análisis", value: "Medición e instrumentos de análisis" },
   { label: "Medio ambiente", value: "Medio ambiente" },
   { label: "Metalurgia y minería", value: "Metalurgia y minería" },
   { label: "Mobiliario", value: "Mobiliario" },
   { label: "Oficina y colegios suministros", value: "Oficina y colegios suministros" },
   { label: "Partes industriales y servicios de fabricación", value: "Partes industriales y servicios de fabricación" },
   { label: "Piel y textil", value: "Piel y textil" },
   { label: "Plásticos, gomas y cauchos", value: "Plásticos, gomas y cauchos" },
   { label: "Productos químicos", value: "Productos químicos" },
   { label: "Regalos y artesanías", value: "Regalos y artesanías" },
   { label: "Relojería, joyería y lentes", value: "Relojería, joyería y lentes" },
   { label: "Ropa", value: "Ropa" },
   { label: "Salud y medicina", value: "Salud y medicina" },
   { label: "Seguridad y protección", value: "Seguridad y protección" },
   { label: "Telecomunicación", value: "Telecomunicación" },
   { label: "Transporte", value: "Transporte" },
];