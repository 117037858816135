import React, { useEffect, useState } from "react";
import { Card, ChoiceList, Form, FormLayout, Modal, TextField } from "@shopify/polaris";

import "./style.css";
import { EmailEx, PhoneEx } from "../../utils/RegEx";
import { countryCodes } from "../../utils/CountryCodes";
import ApiServiceComercios from "../../services/ApiServiceComercios";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleToast } from "../../actions/InteractiveActions";
import CountrySelect from "../CountrySelect";

const initialState = {
  name: "",
  last_name: "",
  phone: "",
  email: "",
  department: "",
};

// data - datos a mandar en el modal a editar
// refresh - función que refrescara
export const AddContactModal = ({
  isActive = false,
  onClose: onCloseModal,
  data,
  modules = [],
  refresh,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showCountry, setShowCountry] = useState(false);

  const dispatch = useDispatch();
  const idAuth = useSelector((state) => state.Auth.userData._id);
  const { id: idClient } = useParams();

  const [contactData, setContactData] = useState(initialState);
  const [countryCode, setCountryCode] = useState({
    code: "MX",
    phone_code: "52",
  });
  const [moduleList, setModuleList] = useState([]);
  const [permissionsSelected, setPermissionsSelected] = useState([]);
  const [errors, setErrors] = useState({
    email: "",
    name: "",
    last_name: "",
  });

  // Carga los datos por primera vez
  useEffect(() => {
    if (data) {
      setIsEditing(true);

      // Obtiene el country code del numero
      let phone = data.phone.split(" ");

      let country = countryCodes.find((item) => item["COUNTRY CODE"] === phone[0]);

      if (country) {
        setCountryCode({
          code: country["ISO CODES"].split(" / ")[0],
          phone_code: country["COUNTRY CODE"],
        });
      }

      setContactData({
        ...data,
        phone: phone[1],
      });

      // Filtra los módulos en status active y luego obtiene los ids
      // para mandar a los seleccionados
      let selected = data.modules.filter((item) => item.active);
      setPermissionsSelected(selected.map((item) => item.id));
    }
  }, [data]);

  // Carga el listado de los módulos del usuario cliente
  useEffect(() => {
    let formatead = modules.map((item) => ({
      label: item.name,
      value: item._id,
    }));

    setModuleList(formatead);
  }, [modules]);

  // Manipula los cambios en los input
  const inputChange = (name = "", value = "") => {
    setContactData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Función para controlar el input del email
  const handleInputEmail = (value = "") => {
    inputChange("email", value);

    if (EmailEx.test(value)) {
      setErrorMessage("email", "");
    } else {
      setErrorMessage("email", "El correo no tiene un formato valido");
    }
  };

  // Función para mandar los errores por input
  const setErrorMessage = (name = "", msj = "") => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: msj,
    }));
  };

  // Función para actualizar información del contacto
  const updateContact = () => {
    let { email, phone, ...rest } = contactData;
    let __phone = `${countryCode.phone_code} ${phone}`;

    const data = {
      ...rest,
      phone: phone ? __phone : "",
      modules: getModulesSelected(),
    };

    setIsLoading(true);
    ApiServiceComercios.updateClientContact(contactData?._id, data)
      .then(({ message }) => {
        dispatch(toggleToast({ message }));
      })
      .finally(fetchFinished);
  };

  // Función para agregar nuevo contacto
  const addNewContact = () => {
    // Verifica si el id sera del cliente por parámetro (vista desde admin) o iniciado sesión
    let id = idClient || idAuth;
    let phone = `${countryCode.phone_code} ${contactData.phone}`;

    let data = {
      ...contactData,
      phone: contactData.phone ? phone : "",
      modules: getModulesSelected(),
    };

    setIsLoading(true);
    ApiServiceComercios.createContact(id, data)
      .then(({ message }) => {
        dispatch(toggleToast({ message }));
        fetchFinished();
      })
      .catch((err) => {
        setIsLoading(false);
        let message = err.response.data.message;
        setErrorMessage("email", message);
      });
    // .finally(fetchFinished);
  };

  // Formatea los módulos seleccionados para mandar en la api
  const getModulesSelected = () => {
    let arr = modules.map(({ _id }) => ({
      id: _id,
      active: permissionsSelected.includes(_id),
    }));

    return arr;
  };

  // Si esta editando, se habilita la función de eliminar
  const onDeleteContact = () => {
    setIsDeleting(true);
    ApiServiceComercios.deleteContact(contactData._id)
      .then(({ message }) => {
        dispatch(toggleToast({ message }));
      })
      .finally(fetchFinished);
  };

  // función al terminar las peticiones
  const fetchFinished = () => {
    setIsLoading(false);
    setIsDeleting(false);
    refresh();
    onCloseModal();
  };

  // Función para seleccionar el country code
  const onSelectCountryCode = (data) => {
    setCountryCode({
      code: data["ISO CODES"].split(" / ")[0],
      phone_code: data["COUNTRY CODE"],
    });

    setShowCountry(false);
  };

  const tittle_permission = () => {
    let selected = permissionsSelected.length;
    if (selected === 0) {
      return "Este contacto no tendrá ningún permiso.";
    }
    if (selected === modules.length) {
      return "Este contacto tendrá todos los permisos.";
    }
    if (selected > 0) {
      return "Este contacto tendrá permisos limitados.";
    }
  };

  return (
    <Modal
      open={isActive}
      onClose={onCloseModal}
      title={`${isEditing ? "Editar" : "Agregar"} contacto`}
      primaryAction={[
        {
          content: isEditing ? "Guardar" : "Enviar invitación",
          disabled:
            !contactData.name ||
            !contactData.last_name ||
            !EmailEx.test(contactData.email) ||
            !permissionsSelected.length ||
            isDeleting,
          onAction: isEditing ? updateContact : addNewContact,
          loading: isLoading,
        },
      ]}
      secondaryActions={[
        {
          content: isEditing ? "Eliminar" : "Cancelar",
          onAction: isEditing ? onDeleteContact : onCloseModal,
          destructive: isEditing,
          outline: isEditing,
          loading: isDeleting,
        },
      ]}
    >
      <Modal.Section>
        <Card.Section>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  label="Nombre"
                  value={contactData.name}
                  requiredIndicator
                  onChange={(val) => inputChange("name", val)}
                  error={!contactData.name && errors.name}
                  onBlur={() => setErrorMessage("name", "El nombre es necesario")}
                />
                <TextField
                  label="Apellido"
                  value={contactData.last_name}
                  onChange={(val) => inputChange("last_name", val)}
                  requiredIndicator
                  error={!contactData.last_name && errors.last_name}
                  onBlur={() => setErrorMessage("last_name", "El apellido es necesario")}
                />
                <CountrySelect
                  title="Teléfono"
                  active={showCountry}
                  selectedFlag={countryCode}
                  toggleActive={setShowCountry}
                  handleSelect={onSelectCountryCode}
                  showCode
                >
                  <TextField
                    value={contactData.phone}
                    type="tel "
                    maxLength={10}
                    onChange={(val) => {
                      if (PhoneEx.test(val)) {
                        inputChange("phone", val);
                      }
                    }}
                  />
                </CountrySelect>
                <TextField
                  label="Departamento"
                  value={contactData.department}
                  autoComplete="off"
                  onChange={(val) => inputChange("department", val)}
                />
              </FormLayout.Group>
              <TextField
                label="Correo electrónico"
                value={contactData.email}
                type="email"
                requiredIndicator
                disabled={isEditing}
                error={(!contactData.email || errors.email) && errors.email}
                onBlur={() => {
                  if (!contactData.email) {
                    setErrorMessage("email", "El correo es necesario");
                  }
                }}
                onChange={handleInputEmail}
              />
            </FormLayout>
          </Form>
        </Card.Section>

        {/* Permissions section */}
        <Card.Section>
          <ChoiceList
            title={tittle_permission()}
            allowMultiple
            choices={moduleList}
            selected={permissionsSelected}
            onChange={setPermissionsSelected}
          />
        </Card.Section>
      </Modal.Section>
    </Modal>
  );
};
