import moment from "moment";

export const timelineTraslation = {
  search: "Buscar",
  notfound: "No hay emojis",
  categories: {
    search: "Resultados de la búsqueda",
    recent: "Usado frecuentemente",
    smileys: "Emoticonos y Emoción",
    people: "Personas y cuerpo",
    nature: "Animales y naturaleza",
    foods: "Comida y bebida",
    activity: "Actividad",
    places: "Viajes y lugares",
    objects: "Objetos",
    symbols: "Símbolos",
    flags: "Banderas",
    custom: "Custom",
  },
};

export const formatDateCalendar = (date) => {
  // return moment(date).calendar(null, {
  //   sameDay: "[Hoy]",
  //   nextDay: "[Mañana]",
  //   nextWeek: "dddd",
  //   lastDay: "[Ayer]",
  //   lastWeek: "dddd [pasado]",
  //   sameElse: "[el] DD [de] MMMM [del] YYYY",
  // });
  return moment(date).fromNow();
};
