import React, { useState, useEffect, useCallback } from "react";
import {
  Page,
  Layout,
  Card,
  FormLayout,
  TextField,
  ChoiceList,
  Button,
  ContextualSaveBar,
  EmptyState,
} from "@shopify/polaris";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  ComercioDetalleSkeleton,
  DatePicker,
  DeleteModal,
  HelpFooter,
  Layer,
} from "../../components";
import { toggleToast } from "../../actions/InteractiveActions";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import "./AgregarAnuncioScreen.css";
import { getZonaHoraria } from "../../reducers";

export default function AgregarAnuncioScreen({ forbidden }) {
  const { id } = useParams();
  const zonaHoraria = useSelector(getZonaHoraria);
  const dispatch = useDispatch();
  const router = useHistory();

  const [anuncio, setAnuncio] = useState({
    titulo: "",
    descripcion: "",
    textButton: "",
    enlace: "",
    estatus: "Visible",
    fecha_inicio: new Date(),
    horaInicio: "00:00",
    fecha_fin: new Date(),
    horaFin: "00:00",
  });
  const [isCustom, setIsCustom] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [customErrors, setCustomErrors] = useState({
    titulo: "",
    enlace: "",
    fecha: "",
  });
  const [isNew, setIsNew] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [active, setActive] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleModalChange = useCallback(() => setActive(!active), [active]);

  useEffect(() => {
    function obtenerAnuncio() {
      ApiServiceComercios.obtenerAnuncio(id)
        .then(({ anuncio }) => {
          const {
            descripcion,
            enlace,
            estatus,
            fecha_fin,
            fecha_inicio,
            textButton,
            titulo,
          } = anuncio;

          const horaInicio = !fecha_inicio
            ? "00:00"
            : moment(fecha_inicio).format("HH:mm");
          const horaFin = !fecha_fin
            ? "00:00"
            : moment(fecha_fin).format("HH:mm");

          setAnuncio((prevState) => ({
            ...prevState,
            titulo,
            descripcion,
            textButton,
            enlace,
            estatus,
            fecha_inicio: fecha_inicio ? new Date(fecha_inicio) : new Date(),
            fecha_fin: fecha_inicio ? new Date(fecha_fin) : new Date(),
            horaInicio,
            horaFin,
          }));

          if (fecha_inicio) {
            if (moment(fecha_fin).isBefore(moment().format())) {
              setIsCustom(false);
            } else {
              setIsCustom(true);
            }
          }
        })
        .finally(() => setIsLoading(false));
    }
    if (id !== "agregar") {
      setIsNew(false);
      obtenerAnuncio();
    } else {
      setIsLoading(false);
      setIsCustom(true);
    }
  }, [id]);

  function handleFieldChange(value, key) {
    if (key === "estatus" && value[0] === "No visible") setIsCustom(false);
    if (key === "estatus" && value[0] === "Visible") setIsCustom(true);
    value && setIsDirty(true);
    if (["fecha_inicio", "fecha_fin"].includes(key)) {
      const dates = runDateTimeValidator(value, key);
      setAnuncio((state) => ({
        ...state,
        ...dates,
      }));
    } else {
      setAnuncio((state) => ({
        ...state,
        [key]: key === "estatus" ? value[0] : value,
      }));
    }
  }

  function runDateTimeValidator(value, key) {
    let dates = {
      fecha_inicio: anuncio.fecha_inicio,
      fecha_fin: anuncio.fecha_fin,
      horaInicio: anuncio.horaInicio,
      horaFin: anuncio.horaFin,
    };
    dates[key] = value;

    let dateStart =
      moment(dates.fecha_inicio).format("YYYY-MM-DD") + "T" + dates.horaInicio;
    dateStart = moment(dateStart).format();
    let dateEnd =
      moment(dates.fecha_fin).format("YYYY-MM-DD") + "T" + dates.horaFin;
    dateEnd = moment(dateEnd).format();

    if (key === "fecha_inicio") {
      if (moment(dateStart).isAfter(dateEnd)) {
        dateEnd = moment(dateStart).add(1, "day").format();
        dates["fecha_fin"] = dateEnd;
      }
    }
    if (key === "fecha_fin") {
      if (moment(dateStart).isAfter(dateEnd)) {
        dateStart = moment(dateEnd).subtract(1, "day").format();
        dates["fecha_inicio"] = dateStart;
      }
    }
    return dates;
  }

  function handleSave() {
    if (runValidations()) {
      setIsCreating(true);
      let fecha_inicio =
        moment(anuncio.fecha_inicio).format("YYYY-MM-DD") +
        "T" +
        anuncio.horaInicio;
      fecha_inicio = moment(fecha_inicio).format();

      let fecha_fin =
        moment(anuncio.fecha_fin).format("YYYY-MM-DD") + "T" + anuncio.horaFin;
      fecha_fin = moment(fecha_fin).format();
      ApiServiceComercios.crearAnuncio({
        ...anuncio,
        fecha_inicio: isCustom ? fecha_inicio : null,
        fecha_fin: isCustom ? fecha_fin : null,
        zona: zonaHoraria,
      })
        .then((result) => {
          if (result.ok) {
            handleDiscard();
            dispatch(toggleToast({ message: result.message }));
          } else {
            setIsCreating(false);
          }
        })
        .catch((err) => {});
    }
  }

  function handleUpdate() {
    if (runValidations()) {
      setIsCreating(true);
      let fecha_inicio =
        moment(anuncio.fecha_inicio).format("YYYY-MM-DD") +
        "T" +
        anuncio.horaInicio;
      fecha_inicio = moment(fecha_inicio).format();

      let fecha_fin =
        moment(anuncio.fecha_fin).format("YYYY-MM-DD") + "T" + anuncio.horaFin;
      fecha_fin = moment(fecha_fin).format();

      ApiServiceComercios.actualizarAnuncio(id, {
        ...anuncio,
        fecha_inicio: isCustom ? fecha_inicio : null,
        fecha_fin: isCustom ? fecha_fin : null,
        zona: zonaHoraria,
      }).then((result) => {
        if (result.ok) {
          handleDiscard();
          dispatch(toggleToast({ message: result.message }));
        } else {
          setIsCreating(false);
        }
      });
    }
  }

  function handleDiscard() {
    router.goBack();
  }

  function handleCustomDate() {
    setIsCustom(!isCustom);
    handleFieldChange(["Visible"], "estatus");
  }

  function onBlurTitle() {
    setCustomErrors((state) => ({
      ...state,
      titulo: anuncio.titulo.length === 0 ? "El titulo es requerido" : "",
    }));
  }

  function runValidations() {
    if (anuncio.textButton.length > 0 && anuncio.enlace.length === 0) {
      setCustomErrors((state) => ({ ...state, enlace: "Enlace es requerido" }));
      return false;
    }
    if (anuncio.titulo.length === 0) {
      setCustomErrors((state) => ({
        ...state,
        titulo: "El titulo es requerido",
      }));
      return false;
    }
    return true;
  }

  function eliminarAnuncio() {
    setIsDeleting(true);
    ApiServiceComercios.eliminarAnuncio(id)
      .then((result) => {
        if (result.ok) {
          dispatch(toggleToast({ message: result.message }));
          handleDiscard();
        }
      })
      .catch((err) => {});
  }

  if (isLoading) {
    return (
      <Layer title={`Anuncio`} forbidden={forbidden}>
        <ComercioDetalleSkeleton />
      </Layer>
    );
  }

  if (!isNew && !anuncio.titulo) {
    return (
      <Layer title={`Anuncio`} forbidden={forbidden}>
        <EmptyState
          heading="Oops! Hubo un problema al intentar obtener este anuncio"
          action={{
            content: "Volver a intentar",
            onAction: () => window.location.reload(),
          }}
          secondaryAction={{
            content: "Regresar a anuncios",
            onAction: handleDiscard(),
          }}
          image="/Ad.svg"
        >
          <p>Intenta de nuevo más tarde</p>
        </EmptyState>
      </Layer>
    );
  }

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Cambios no guardados"
      saveAction={{
        onAction: isNew ? handleSave : handleUpdate,
        disabled: isCreating,
        loading: isCreating,
      }}
      discardAction={{
        onAction: handleDiscard,
        disabled: isCreating,
      }}
    />
  ) : null;

  return (
    <Layer title="Agregar anuncio" forbidden={forbidden}>
      <Page
        breadcrumbs={[{ content: "Anuncios", onAction: handleDiscard }]}
        title="Agregar anuncio"
      >
        {contextualSaveBarMarkup}
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <FormLayout>
                <TextField
                  label="Título"
                  placeholder="por ejemplo: Promoción del mes, Aviso importante."
                  value={anuncio.titulo}
                  onChange={(val) => handleFieldChange(val, "titulo")}
                  onBlur={() => onBlurTitle()}
                  error={customErrors.titulo}
                />
                <TextField
                  label="Descripción"
                  value={anuncio.descripcion}
                  onChange={(val) => handleFieldChange(val, "descripcion")}
                  multiline={8}
                />
              </FormLayout>
            </Card>
            <Card sectioned title="Enlace">
              <FormLayout>
                <TextField
                  label="Texto del botón"
                  placeholder="por ejemplo: Descarga archivo"
                  value={anuncio.textButton}
                  onChange={(val) => handleFieldChange(val, "textButton")}
                />
                <TextField
                  label="Enlace a"
                  placeholder="http://"
                  value={anuncio.enlace}
                  onChange={(val) => handleFieldChange(val, "enlace")}
                  error={customErrors.enlace}
                />
              </FormLayout>
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <Card sectioned title="Visibilidad">
              <FormLayout>
                <div className="mb-10">
                  <ChoiceList
                    selected={anuncio.estatus}
                    choices={[
                      { label: "Visible", value: "Visible" },
                      { label: "No visible", value: "No visible" },
                    ]}
                    onChange={(val) => {
                      handleFieldChange(val, "estatus");
                    }}
                  />
                </div>
                {isCustom || anuncio.estatus === "Visible" ? (
                  <>
                    <DatePicker
                      date={anuncio.fecha_inicio}
                      title="Fecha de inicio"
                      onSelectDate={({ start }) =>
                        handleFieldChange(start, "fecha_inicio")
                      }
                      disableBefore={moment().subtract(1, "day").toDate()}
                    />
                    <div className="timepicker-field mt-2">
                      <TextField
                        type="time"
                        value={anuncio.horaInicio}
                        onChange={(value) =>
                          handleFieldChange(value, "horaInicio")
                        }
                        prefix="GTM-5"
                      />
                    </div>
                    <div className="mt-4">
                      <DatePicker
                        date={anuncio.fecha_fin}
                        title="Fecha de fin"
                        onSelectDate={({ start }) =>
                          handleFieldChange(start, "fecha_fin")
                        }
                      />
                      <div className="timepicker-field mt-2">
                        <TextField
                          type="time"
                          value={anuncio.horaFin}
                          onChange={(value) =>
                            handleFieldChange(value, "horaFin")
                          }
                          prefix="GTM-5"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <Button plain onClick={handleCustomDate}>
                    Fijar fecha de visibilidad
                  </Button>
                )}
              </FormLayout>
            </Card>
          </Layout.Section>
        </Layout>
        <div className="flex flex-row flex-wrap justify-between my-10">
          {!isNew && (
            <Button destructive onClick={handleModalChange}>
              Eliminar anuncio
            </Button>
          )}
          <div className="flex flex-row">
            <Button disabled={isCreating} onClick={handleDiscard}>
              Descartar
            </Button>
            <div className="w-4" />
            <Button
              disabled={isCreating}
              loading={isCreating}
              primary
              onClick={isNew ? handleSave : handleUpdate}
            >
              Guardar
            </Button>
          </div>
        </div>
        <DeleteModal
          handleChange={handleModalChange}
          isLoading={isDeleting}
          handleDelete={eliminarAnuncio}
          active={active}
          title="Eliminar anuncio"
          body="¿Estás seguro que quieres eliminar este anuncio?"
        />
        <HelpFooter title="anuncios" url="https://help.b2bgo.mx/anuncios" />
      </Page>
    </Layer>
  );
}
