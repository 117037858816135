import React, { useState, useRef, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Banner,
  Card,
  Select,
  FormLayout,
  Icon,
  Modal,
  SkeletonBodyText,
  Stack,
  TextField,
  Button,
  DataTable,
  TextStyle,
} from "@shopify/polaris";
import {
  MobileHorizontalDotsMajor,
  SearchMajor,
  EditMajor,
  DeleteMajor,
} from "@shopify/polaris-icons";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import CustomPopover from "../CustomPopover";
import _ from "lodash";
import { onBlurTextField } from "../../utils/TextFieldValidations";

export default function LineaArticuloVolumenModal(props) {
  const deselectedOptions = useRef([]);
  const [options, setOptions] = useState([]);
  const [lineas, setLineas] = useState([]);
  const [lineaArt, setLinea] = useState({
    porcentaje: "1",
    linea: "",
    minimo: "1",
    precioLista: { nombre: "", id: "" },
    lineaPrecio: "_" + Math.random().toString(36).substr(2, 9),
  });
  const [line, setLine] = useState("");
  const [preciosLista, setPreciosLista] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setPreciosLista(props.precioLista);
    if (props.precioLista.length > 0)
      setLinea((s) => ({
        ...s,
        precioLista: {
          nombre: props.precioLista[0].label,
          id: props.precioLista[0].value,
        },
      }));
  }, [props.precioLista]);

  useEffect(() => {
    ApiServiceComercios.obtenerLineas()
      .then(({ ok, lineas }) => {
        if (ok) {
          let willAdd = lineas.map((i) => ({
            label: i.nombre,
            value: i.nombre,
          }));
          deselectedOptions.current = willAdd;
          if (props.precioLista.length > 0 && props.selected.length > 0) {
            const precioListaDraftId = props.precioLista[0]["value"];
            const filteredSelected = props.selected
              .filter((i) => i.precioLista.id === precioListaDraftId)
              .map((i) => i.linea);
            willAdd = willAdd.filter(
              (i) => !filteredSelected.includes(i.value)
            );
          }
          setOptions(willAdd);
        }
      })
      .finally(() => setIsLoading(false));

    const updatingLine = props.selected.find((s) => s.editing === true);
    if (updatingLine) {
      const updLine = {
        porcentaje: updatingLine.porcentaje,
        precioLista: updatingLine.precioLista,
        linea: updatingLine.linea,
        isEditing: false,
        minimo: updatingLine.minimo,
        lineaPrecio: updatingLine.lineaPrecio,
      };
      setLineas((s) => [...s, updLine]);
      setLinea((s) => ({ ...s, ...updLine }));
      setIsEditing(true);
      setLine(updatingLine.linea);
    }
    return () => null;
  }, [props.precioLista, props.selected]);

  const updateText = useCallback(
    (value) => {
      changeState(value, "linea");

      if (value === "") {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.current.filter((option) =>
        option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const updateSelection = useCallback((selected) => {
    setSelectedOptions(selected);
    changeState(selected[0], "linea");
    setLine(selected[0]);
  }, []);

  function changeState(value, key) {
    setLinea((state) => ({ ...state, [key]: value }));
  }

  function addLineas() {
    props.onAddLinea(lineas);
    props.togleModal();
  }

  function addLinea() {
    // setOptions(options.filter(i => i.label !== line));
    let lineDraft = [
      ...lineas,
      {
        porcentaje: lineaArt.porcentaje,
        lineaPrecio: lineaArt.lineaPrecio,
        precioLista: lineaArt.precioLista,
        linea: line,
        minimo: lineaArt.minimo,
        editing: false,
      },
    ];
    // setOptions(deselectedOptions.current.filter((i) => !lineDraft.map(i =>i.linea).includes(i.label)));
    setLineas(lineDraft);
    setLinea((s) => ({
      ...s,
      lineaPrecio: "_" + Math.random().toString(36).substr(2, 9),
      porcentaje: "0",
      minimo: "0",
      linea: "",
    }));
    setLine("");
  }

  function onRemoveItem(index) {
    let arr = [...lineas];
    arr.splice(index, 1);
    setLineas(arr);
  }

  function onEditItem(index) {
    const willEdit = lineas[index];
    setLine(willEdit.linea);
    setIsEditing(true);
    setLinea((s) => ({
      ...s,
      linea: willEdit.linea,
      precioLista: willEdit.precioLista,
      minimo: willEdit.minimo,
      porcentaje: willEdit.porcentaje,
    }));

    let arr = [...lineas];
    arr.splice(index, 1, { ...willEdit, editing: true });
    setLineas(arr);
  }

  function onSetEdit() {
    let arr = [...lineas];
    const index = _.findIndex(arr, { editing: true });
    arr.splice(index, 1, {
      porcentaje: lineaArt.porcentaje,
      lineaPrecio: lineaArt.lineaPrecio,
      precioLista: lineaArt.precioLista,
      linea: line,
      editing: false,
      minimo: lineaArt.minimo,
    });
    setLineas(arr);
    setLinea((s) => ({ ...s, linea: "", porcentaje: "0", minimo: "0" }));
    // setOptions(options.filter((i) => i.label !== line));
    setLine("");
    setIsEditing(false);
  }

  function onSetPrecioLista(value) {
    // const linFiltered = lineas.filter((l) => l.precioLista.id === value).map(s => s.linea);
    // setOptions(deselectedOptions.current.filter((i) => !linFiltered.includes(i.label)));
    setLinea((s) => ({
      ...s,
      precioLista: {
        nombre: preciosLista.find((i) => i.value === value)["label"],
        id: value,
      },
    }));
  }

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      prefix={<Icon source={SearchMajor} />}
      label="Línea de producto"
      value={lineaArt.linea}
      placeholder="Buscar líneas de productos"
      helpText={line}
      disabled={isEditing}
    />
  );

  if (isLoading) {
    return (
      <Modal
        title="Agregar línea de producto"
        open={props.isActive}
        onClose={props.togleModal}
      >
        <Modal.Section>
          <Card sectioned>
            <SkeletonBodyText lines={2} />
          </Card>
        </Modal.Section>
      </Modal>
    );
  }

  return (
    <Modal
      title="Agregar línea de producto"
      open={props.isActive}
      onClose={props.togleModal}
      secondaryActions={[{ content: "Cancelar", onAction: props.togleModal }]}
      primaryAction={{
        content: "Continuar",
        onAction: addLineas,
        disabled: lineas.length === 0,
      }}
    >
      <Modal.Section>
        <Card sectioned>
          <FormLayout>
            <div className="stack-fillEvenly">
              <Stack distribution="fillEvenly" wrap>
                <Select
                  label="Lista de precio"
                  options={preciosLista}
                  disabled={isLoading || isEditing}
                  onChange={onSetPrecioLista}
                  value={lineaArt.precioLista.id}
                />
                <Autocomplete
                  options={options}
                  selected={selectedOptions}
                  onSelect={updateSelection}
                  textField={textField}
                  willLoadMoreResults
                  listTitle="Líneas de productos existentes"
                  loading={isLoading}
                />
              </Stack>
            </div>
            <Stack distribution="fillEvenly" wrap>
              <TextField
                min={1}
                align="right"
                type="number"
                label="Cantidad mínima"
                value={lineaArt.minimo}
                onChange={(value) => changeState(value, "minimo")}
                onBlur={() =>
                  onBlurTextField(lineaArt.minimo, changeState, null, "minimo")
                }
              />
              <TextField
                min={1}
                max={100}
                align="right"
                suffix="%"
                type="number"
                label="Valor del descuento"
                value={lineaArt.porcentaje}
                onChange={(value) => changeState(value, "porcentaje")}
                onBlur={() =>
                  onBlurTextField(
                    lineaArt.porcentaje,
                    changeState,
                    100,
                    "porcentaje"
                  )
                }
              />
            </Stack>
            <div className="flex justify-end">
              <Button
                monochrome
                onClick={isEditing ? onSetEdit : addLinea}
                disabled={
                  Number(lineaArt.porcentaje) === 0 ||
                  !line ||
                  Number(lineaArt.minimo) === 0 ||
                  (props.selected
                    .concat(lineas)
                    .some(
                      (i) =>
                        i.precioLista.id + i.linea ===
                          lineaArt.precioLista.id + line &&
                        i.minimo === lineaArt.minimo
                    ) &&
                    !isEditing)
                }
              >
                {isEditing ? "Editar" : "Agregar"}
              </Button>
            </div>
          </FormLayout>
          {lineas.length > 0 && (
            <DataTable
              headings={[
                "Lista de precios",
                "Línea de productos",
                "Cantidad mínima",
                "Descuento",
              ]}
              columnContentTypes={["text", "text", "text", "text"]}
              rows={lineas.map(
                (
                  { porcentaje, precioLista, minimo, linea, editing },
                  index
                ) => {
                  const Activator = ({ onClick }) => {
                    return (
                      <div className="flex items-center">
                        <div className="mr-4">
                          <TextStyle>{porcentaje} % </TextStyle>
                        </div>
                        <Button
                          plain
                          icon={MobileHorizontalDotsMajor}
                          onClick={onClick}
                        />
                      </div>
                    );
                  };

                  const prelist = <TextStyle>{precioLista.nombre}</TextStyle>;
                  const min = <TextStyle>{minimo}</TextStyle>;
                  const lineaProd = (
                    <TextStyle>
                      {linea}{" "}
                      {editing && isEditing ? (
                        <TextStyle variation="subdued">...editando</TextStyle>
                      ) : null}
                    </TextStyle>
                  );
                  const porc = (
                    <CustomPopover
                      items={[
                        {
                          content: "Editar",
                          onAction: () => onEditItem(index),
                          icon: EditMajor,
                        },
                        {
                          content: "Quitar",
                          onAction: () => onRemoveItem(index),
                          icon: DeleteMajor,
                          destructive: true,
                        },
                      ]}
                      activator={Activator}
                    />
                  );
                  return [prelist, lineaProd, min, porc];
                }
              )}
            />
          )}
        </Card>
        <Banner status="info">
          <p>
            Los clientes pueden combinar descuentos por promoción, por cliente,
            por artículo y por volumen.
          </p>
        </Banner>
      </Modal.Section>
    </Modal>
  );
}

LineaArticuloVolumenModal.propTypes = {
  isActive: PropTypes.bool,
  togleModal: PropTypes.func,
  onAddLinea: PropTypes.func,
  selected: PropTypes.array,
  precioLista: PropTypes.array,
};

LineaArticuloVolumenModal.defaultProps = {
  isActive: false,
  togleModal: () => {},
  onAddLinea: () => {},
  selected: [],
  precioLista: [],
};
