import React from "react";
import { Scrollable, Button } from "@shopify/polaris";
import { MobileCancelMajor } from "@shopify/polaris-icons";
import { Drawer, Select, Skeleton, Space } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { Colors } from "../../styles";
import ReportBarChar from "../ReportBarChar";
import TopItem from "../TopItem";
import DoubleText from "../DoubleText";
import "./ReportSheetStyles.css";

const { Option } = Select;

function ReportSheet({
  title,
  topArticulos,
  sheetActive,
  toggleSheetActive,
  graphics,
  setSelectedDates,
  isLoading,
  setActiveFilter,
  subtitle,
}) {
  function selectDates(value) {
    let start = moment().format();
    let end = moment().format();

    switch (value) {
      case "Hoy": {
        start = moment(moment().format()).startOf("day").format();
        break;
      }
      case "Ayer": {
        start = moment(moment().subtract(1, "d").format())
          .startOf("day")
          .format();
        end = moment(moment().subtract(1, "d").format()).endOf("day").format();
        break;
      }
      case "Esta semana": {
        start = moment(moment(moment().startOf("week").format()))
          .startOf("day")
          .format();
        break;
      }
      case "Este mes": {
        start = moment(moment(moment().startOf("month").format()))
          .startOf("day")
          .format();
        break;
      }
      default:
        break;
    }
    setSelectedDates({ start, end });
    setActiveFilter(value);
  }

  function handleChange(value) {
    selectDates(value);
  }

  return (
    <Drawer
      placement="right"
      onClose={toggleSheetActive}
      open={sheetActive}
      key="right"
      closable={false}
      className="reportSheet"
    >
      <div>
        <div
          style={{
            alignItems: "center",
            borderBottom: "1px solid #DFE3E8",
            display: "flex",
            justifyContent: "space-between",
            padding: " 0 0 1.6rem 0",
            width: "100%",
          }}
        >
          <Button icon={MobileCancelMajor} onClick={toggleSheetActive} plain />
          <div className="pop-over-sheet">
            <Select
              size="large"
              defaultValue="Hoy"
              onChange={handleChange}
              style={{ width: 200 }}
            >
              <Option key="Hoy">Hoy</Option>
              <Option key="Ayer">Ayer</Option>
              <Option key="Esta semana">Esta semana</Option>
              <Option key="Este mes">Este mes</Option>
            </Select>
          </div>
        </div>
        <Scrollable className="scrollReport">
          {isLoading ? (
            <div className="py-10 px-6">
              <Space className="flex justify-between mb-4">
                <Skeleton.Button className="flex" active />
                <Skeleton.Button className="flex" active />
              </Space>
              <Skeleton.Input
                className="flex"
                style={{ width: "100%", height: 150 }}
                active
                round
              />
              <div className="p-6 mt-4 border-separator">
                <DoubleText title={title} subtitle={subtitle} />
                {Array.from(Array(3).keys()).map((item, index) => {
                  return (
                    <div key={index}>
                      <TopItem isLoading={isLoading} />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <>
              {graphics.map((item) => {
                return (
                  <div key={"_" + Math.random().toString(36).substr(2, 9)}>
                    <ReportBarChar
                      dataKey={item.dataKey}
                      variant={item.variant}
                      data={item.data}
                      title={item.title}
                      subtitle={subtitle}
                      total={item.total}
                      value={item.value}
                      color={Colors[item.color]}
                      classes="pt-4"
                    />
                  </div>
                );
              })}
              <div className="p-6 mt-4">
                <DoubleText title={title} subtitle={subtitle} />
                {topArticulos
                  .filter((i) => i.hasOwnProperty("articulo"))
                  .map((articulo, index) => {
                    return (
                      <div key={index}>
                        <TopItem item={articulo} />
                      </div>
                    );
                  })}
              </div>
            </>
          )}
        </Scrollable>
      </div>
    </Drawer>
  );
}

ReportSheet.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  sheetActive: PropTypes.bool,
  toggleSheetActive: PropTypes.func,
  graphics: PropTypes.array,
  setSelectedDates: PropTypes.func,
  isLoading: PropTypes.bool,
  activeFilter: PropTypes.string,
  setActiveFilter: PropTypes.func,
  topArticulos: PropTypes.array,
};

ReportSheet.defaultProps = {
  title: "",
  subtitle: "",
  sheetActive: false,
  toggleSheetActive: () => {},
  graphics: [],
  setSelectedDates: () => {},
  isLoading: false,
  activeFilter: "Hoy",
  setActiveFilter: () => {},
  topArticulos: [],
};

export default React.memo(ReportSheet);
