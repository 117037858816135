import { useEffect } from "react";
import { useState } from "react";
import ApiServiceComercios from "../services/ApiServiceComercios";
const defaultTypes = ["heading", "footer"];
export default function useGetPageSections(type = "home-page", deeps = []) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await Promise.all(
          [defaultTypes[0], type, defaultTypes[1]].map((type) =>
            ApiServiceComercios.getPageSectionByType(type)
          )
        );

        setData(data.map(({ page }) => page));
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deeps, type]);

  return { data, loading, setData };
}
