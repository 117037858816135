import React, { useEffect, useState } from "react";
import { Icon, Spinner, TextStyle } from "@shopify/polaris";
import { CircleTickMajor, CircleCancelMajor } from "@shopify/polaris-icons";
import "./Checklist.css";

export default function Checklist({
  text,
  isLoading,
  isSuccess,
  useMargin = true,
}) {
  const [entrance, setEntrance] = useState("hidden");
  const [exist, setExist] = useState("");
  useEffect(() => {
    if (!isLoading) {
      setEntrance(" animate__zoomIn block");
      setExist("animate__zoomOut success-progress-animated ");
    }
    return () => null;
  }, [isLoading]);

  return (
    <div className={`flex flex-row justify-start ${useMargin ? "my-8" : ""}`}>
      <div className="relative mr-10">
        <div
          className={` absolute progress-animated animate__animated ${exist}`}
        >
          <Spinner color="inkLightest" size="small" />
        </div>
        <div
          className={` absolute check-progress animate__animated mr-4 ${entrance}`}
        >
          <Icon
            source={isSuccess ? CircleTickMajor : CircleCancelMajor}
            color={isSuccess ? "success" : "critical"}
          />
        </div>
      </div>
      <TextStyle
        variation={
          isSuccess != null ? (isSuccess ? "positive" : "negative") : "subdued"
        }
      >
        {text}
      </TextStyle>
    </div>
  );
}
