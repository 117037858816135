import React from "react";
import { Icon, TextStyle, Checkbox } from "@shopify/polaris";
import * as IconType from "@shopify/polaris-icons";

export default function CheckboxList({
  id,
  label,
  helpText,
  icon,
  disabled,
  checked = true,
  onChange = () => {},
}) {
  return (
    <div className={"flex flex-row justify-start my-8"}>
      <div className="relative mr-4 mt-2">
        <Checkbox
          value={id}
          disabled={disabled}
          checked={checked}
          onChange={(val) => onChange(val, id)}
        />
      </div>
      <div className="relative mr-6 mt-3">
        <Icon source={IconType[icon]} color="subdued" />
      </div>
      <div className="flex flex-col">
        <TextStyle variation="strong">{label}</TextStyle>
        <TextStyle>{helpText}</TextStyle>
      </div>
    </div>
  );
}
