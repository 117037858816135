import {
  Card,
  Checkbox,
  DropZone,
  FormLayout,
  RangeSlider,
  Select,
  TextField,
  TextStyle,
  Thumbnail,
  Spinner,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import ApiServiceConfig from "../../services/ApiServiceConfig";
import { contentPositions, validImageTypes } from "../../utils/constants";
import ChooseColor from "../ChooseColor";

export default function BannerImage({
  item = null,
  handleMainChange = () => {},
  handleDeleteSection = () => {},
}) {
  const [banner, setBanner] = useState({
    hideOnMobile: false,
    design: "",
    sliderHeight: "",
    src: "",
    title: "",
    subtitle: "",
    textButton: "",
    urlButton: "#",
    position: "centerToCenter",
    opacity: "0",
    textColor: "#000000",
    backgroundColor: "#FFFFFF",
    buttonColor: "#1C2260",
    textButtonColor: "#FFFFFF",
  });
  const [isAddingFiles, setIsAddingFiles] = useState(false);

  useEffect(() => {
    setBanner((prevState) => ({
      ...prevState,
      ...item.properties,
    }));
  }, [item]);

  const handleDropZone = (
    _dropFiles = [],
    acceptedFiles = [],
    _rejectedFiles = []
  ) => {
    const formData = new FormData();
    if (acceptedFiles.length > 0) {
      setIsAddingFiles(true);
      acceptedFiles.forEach((file) => {
        formData.append("files", file);
      });

      ApiServiceConfig.uploadFile(formData)
        .then(({ files = [] }) => {
          const src = files[0].url;
          handleChange("src", src);
        })
        .finally(() => setIsAddingFiles(false));
    }
  };

  const handleChange = (field, value) => {
    handleMainChange("properties", { ...banner, [field]: value }, item.id);
    setBanner((prevState) => ({ ...prevState, [field]: value }));
  };

  const {
    design,
    sliderHeight,
    hideOnMobile,
    src,
    title,
    subtitle,
    textButton,
    urlButton,
    position,
    opacity,
    textColor,
    backgroundColor,
    buttonColor,
    textButtonColor,
  } = banner;

  return (
    <Card
      primaryFooterAction={{
        content: "Eliminar sección",
        destructive: true,
        outline: true,
        onAction: () => handleDeleteSection(item.id),
      }}
      footerActionAlignment="left"
    >
      <Card.Section>
        <FormLayout>
          <TextStyle variation="strong">Banner de imagen</TextStyle>
          <Select
            label="Diseño"
            placeholder="Selecciona un diseño"
            options={[
              {
                label: "Ancho completo",
                value: "full-width",
              },
              {
                label: "Ajustado",
                value: "fitted-width",
              },
            ]}
            value={design}
            onChange={(val) => handleChange("design", val)}
          />
          <Select
            label="Altura de la imagen"
            placeholder="Selecciona una altura"
            options={[
              {
                label: "Pequeña",
                value: "small",
              },
              {
                label: "Mediana",
                value: "medium",
              },
              {
                label: "Grande",
                value: "large",
              },
            ]}
            value={sliderHeight}
            onChange={(val) => handleChange("sliderHeight", val)}
          />
          <div className="flex justify-between items-end">
            <div
              className="BannerSliderItem w-full mb-10 "
              style={{ height: 70 }}
            >
              <DropZone
                label={
                  <div>
                    <TextStyle>Imagen</TextStyle>
                  </div>
                }
                labelAction={{
                  content: isAddingFiles ? <Spinner size="small" /> : "",
                }}
                accept={validImageTypes.join(",")}
                onDrop={handleDropZone}
                disabled={isAddingFiles}
              >
                <DropZone.FileUpload
                  actionTitle={src ? "Cambiar imagen" : "Seleccionar imagen"}
                />
              </DropZone>
            </div>
            {src && (
              <div className="ml-4 mb-2">
                <Thumbnail source={src} size="medium" />
              </div>
            )}
          </div>
          <TextField
            label="Título"
            placeholder="Texto del banner"
            value={title}
            onChange={(value) => handleChange("title", value)}
          />
          <TextField
            label="Subtítulo"
            placeholder="Subtítulo"
            value={subtitle}
            onChange={(value) => handleChange("subtitle", value)}
          />
          <TextField
            label="Etiqueta del botón"
            placeholder="Etiqueta del botón"
            value={textButton}
            onChange={(value) => handleChange("textButton", value)}
          />
          <TextField
            label="Enlace del botón"
            placeholder="https://..."
            value={urlButton}
            onChange={(value) => handleChange("urlButton", value)}
          />
          <Select
            label="Posición del contenido"
            placeholder="Selecciona una posición"
            value={position}
            onChange={(value) => handleChange("position", value)}
            options={contentPositions}
          />
          <div className="flex flex-row items-end max-items">
            <RangeSlider
              label="Opacidad de la sobreposición de imagen"
              output
              min={0}
              max={100}
              value={opacity}
              onChange={(val) => handleChange("opacity", val)}
            />
            <div className="ml-6 whitespace-nowrap">
              <TextStyle>{opacity} %</TextStyle>
            </div>
          </div>
          <TextStyle>Esquema de colores de la barra de anuncios</TextStyle>
          <ChooseColor
            handleColorPick={(value) => {
              handleChange("textColor", value);
            }}
            handleTextChange={(value) => handleChange("textColor", value)}
            colorHex={textColor}
            label="Color del texto"
          />
          <ChooseColor
            handleColorPick={(value) => {
              handleChange("backgroundColor", value);
            }}
            handleTextChange={(value) => handleChange("backgroundColor", value)}
            colorHex={backgroundColor}
            label="Color del fondo"
          />
          <ChooseColor
            handleColorPick={(value) => {
              handleChange("buttonColor", value);
            }}
            handleTextChange={(value) => handleChange("buttonColor", value)}
            colorHex={buttonColor}
            label="Color del botón"
          />
          <ChooseColor
            handleColorPick={(value) => {
              handleChange("textButtonColor", value);
            }}
            handleTextChange={(value) => handleChange("textButtonColor", value)}
            colorHex={textButtonColor}
            label="Color del texto del botón"
          />
        </FormLayout>
      </Card.Section>
      <Card.Section title="Diseño para móviles">
        <Checkbox
          label="Ocultar en móviles"
          checked={hideOnMobile}
          onChange={(val) => handleChange("hideOnMobile", val)}
        />
      </Card.Section>
    </Card>
  );
}
