import React from 'react';
import { EmptyState, Page } from "@shopify/polaris";
import { Layer } from '../../components';
import { Redirect } from 'react-router-dom';

export default function Page404Screen(props) {
   return (
      <Layer>
         {props.grant_type === "" 
            ?<Redirect to="/login" />
            : null
         }
         <Page>
            <EmptyState
               heading="¡Oops! 404"
               action={{content: 'Ir a inicio', onAction:() => props.history.replace('/')}}
               image="/404.svg"
            >
               <p>
                  Este contenido no se encuentra disponible, vuelve a intentarlo con otra dirección o vuelve al inicio
               </p>
            </EmptyState>
         </Page>
      </Layer>
   )
}
