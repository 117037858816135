import { Card, FormLayout, TextField, TextStyle } from "@shopify/polaris";
import { useEffect, useState } from "react";
import ChooseColor from "../ChooseColor";

export default function NotFoundCard({
  item = null,
  handleMainChange = () => {},
}) {
  const [notFound, setNotFound] = useState({
    title: "404",
    subtitle: "Página no encontrada",
    textButton: "Seguir comprando",
    urlButton: "",
    textColor: "#000000",
    buttonColor: "#1C2260",
    textButtonColor: "#FFFFFF",
  });

  useEffect(() => {
    setNotFound((prevState) => ({
      ...prevState,
      ...item.properties,
    }));
  }, [item]);

  const handleChange = (field, value) => {
    handleMainChange("properties", { ...notFound, [field]: value }, item.id);
    setNotFound((prevState) => ({ ...prevState, [field]: value }));
  };

  const {
    title,
    subtitle,
    textButton,
    urlButton,
    textColor,
    buttonColor,
    textButtonColor,
  } = notFound;

  return (
    <Card>
      <Card.Section>
        <FormLayout>
          <TextStyle variation="strong">Página 404</TextStyle>
          <TextField
            label="Título"
            placeholder="404"
            value={title}
            onChange={(val) => handleChange("title", val)}
          />
          <TextField
            label="Subtítulo"
            placeholder="Página no encontrada"
            value={subtitle}
            onChange={(val) => handleChange("subtitle", val)}
          />
          <TextField
            label="Etiqueta del botón"
            placeholder="Etiqueta del botón"
            value={textButton}
            onChange={(value) => handleChange("textButton", value)}
          />
          <TextField
            label="Enlace del botón"
            placeholder="https://..."
            value={urlButton}
            onChange={(value) => handleChange("urlButton", value)}
          />
          <TextStyle>Esquema de colores de la barra de anuncios</TextStyle>
          <ChooseColor
            handleColorPick={(value) => {
              handleChange("textColor", value);
            }}
            handleTextChange={(value) => handleChange("textColor", value)}
            colorHex={textColor}
            label="Color del texto"
          />
          <ChooseColor
            handleColorPick={(value) => {
              handleChange("buttonColor", value);
            }}
            handleTextChange={(value) => handleChange("buttonColor", value)}
            colorHex={buttonColor}
            label="Color del botón"
          />
          <ChooseColor
            handleColorPick={(value) => {
              handleChange("textButtonColor", value);
            }}
            handleTextChange={(value) => handleChange("textButtonColor", value)}
            colorHex={textButtonColor}
            label="Color del texto del botón"
          />
        </FormLayout>
      </Card.Section>
    </Card>
  );
}
