import React, { useState, useRef, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Autocomplete,
  Banner,
  Card,
  Select,
  FormLayout,
  Icon,
  Modal,
  Button,
  Stack,
  TextField,
  DataTable,
  TextStyle,
} from "@shopify/polaris";
import {
  SearchMajor,
  MobileHorizontalDotsMajor,
  EditMajor,
  DeleteMajor,
} from "@shopify/polaris-icons";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import CustomPopover from "../CustomPopover";
import { onBlurTextField } from "../../utils/TextFieldValidations";
import useDebounce from "../../services/useDebounce";

export default function DescuentoProductoVolumenModal(props) {
  const [producto, setProducto] = useState({
    porcentaje: "1",
    nombre: "",
    id: null,
    minimo: "1",
    precioLista: { nombre: "", id: "" },
    productPrecio: "_" + Math.random().toString(36).substr(2, 9),
  });
  const queryValueRef = useRef(null);
  const [productos, setProductos] = useState([]);
  const deselectedOptions = useRef([]);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [queryValue, setQueryValue] = useState("");
  const [preciosLista, setPreciosLista] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const debouncedSearchTerm = useDebounce(queryValue, 900);

  useEffect(() => {
    queryValueRef.current = queryValue;
  });

  useEffect(() => {
    setPreciosLista(props.precioLista);
    if (props.precioLista.length > 0)
      setProducto((s) => ({
        ...s,
        precioLista: {
          nombre: props.precioLista[0].label,
          id: props.precioLista[0].value,
        },
      }));
    const updatingLine = props.selected.find((s) => s.editing === true);
    if (updatingLine) {
      setProductos((s) => [
        ...s,
        {
          productPrecio: updatingLine.productPrecio,
          precioLista: updatingLine.precioLista,
          minimo: updatingLine.minimo,
          porcentaje: updatingLine.porcentaje,
          nombre: updatingLine.nombre,
          id: updatingLine.id,
        },
      ]);
      setProducto((s) => ({ ...s, ...updatingLine }));
      setIsEditing(true);
    }
    return () => null;
  }, [props.precioLista, props.selected]);

  useEffect(() => {
    function onQueryEndChange() {
      setIsLoading(true);
      ApiServiceComercios.obtenerArticulos({
        sort: "nombre",
        query: queryValueRef.current,
        limit: 20,
        skip: 0,
        existencia: -1,
      })
        .then(({ ok, articulos }) => {
          if (ok) {
            let willAdd = articulos.map((i) => ({
              label: i.nombre,
              value: i._id,
            }));
            deselectedOptions.current = willAdd;
            setOptions(willAdd);
          }
        })
        .finally(() => setIsLoading(false));
    }

    onQueryEndChange();
  }, [debouncedSearchTerm]);

  const updateText = useCallback(
    (value) => {
      setQueryValue(value);
      if (value === "") {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.current.filter((option) =>
        option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const updateSelection = useCallback((selected) => {
    setSelectedOptions(selected);
    const art = deselectedOptions.current.find((i) => i.value === selected[0]);
    setProducto((state) => ({ ...state, nombre: art.label, id: art.value }));
  }, []);

  function changeState(value, key) {
    setProducto((state) => ({ ...state, [key]: value }));
  }

  function addProductos() {
    props.onAddProducto(productos);
    props.togleModal();
  }

  function addProducto() {
    let productDraft = [...productos, { ...producto, editing: false }];
    // setOptions(deselectedOptions.current.filter((i) => !productDraft.map(i =>i.id).includes(i.value)));
    setProductos(productDraft);
    setProducto((s) => ({
      ...s,
      productPrecio: "_" + Math.random().toString(36).substr(2, 9),
      porcentaje: "0",
      nombre: "",
      id: "",
      minimo: "0",
    }));
  }

  function onSetEdit() {
    let arr = [...productos];
    const index = _.findIndex(arr, { editing: true });
    arr.splice(index, 1, { ...producto, editing: false });
    setProductos(arr);
    setProducto({
      porcentaje: "0",
      nombre: "",
      id: null,
      precioLista: producto.precioLista,
      minimo: "0",
    });
    // setOptions(options.filter((i) => i.value !== producto.id));
    setIsEditing(false);
  }

  function onRemoveItem(index) {
    let arr = [...productos];
    arr.splice(index, 1);
    setProductos(arr);
  }

  function onEditItem(index) {
    const willEdit = productos[index];
    setIsEditing(true);
    setProducto((s) => ({
      ...s,
      nombre: willEdit.nombre,
      id: willEdit.id,
      porcentaje: willEdit.porcentaje,
      precioLista: willEdit.precioLista,
      productPrecio: willEdit.productPrecio,
      minimo: willEdit.minimo,
    }));
    let arr = [...productos];
    arr.splice(index, 1, { ...willEdit, editing: true });
    setProductos(arr);
  }

  function onSetPrecioLista(preListId) {
    // const prodFiltered = productos.filter((l) => l.precioLista.id === preListId).map(s => s.id)
    // setOptions(deselectedOptions.current.filter((i) => !prodFiltered.includes(i.value)))
    setProducto((s) => ({
      ...s,
      precioLista: {
        nombre: preciosLista.find((i) => i.value === preListId)["label"],
        id: preListId,
      },
    }));
  }

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      prefix={<Icon source={SearchMajor} />}
      label="Productos"
      value={queryValue}
      disabled={isEditing}
      placeholder="Buscar producto"
      helpText={producto.nombre}
    />
  );

  return (
    <Modal
      title="Agregar productos"
      open={props.isActive}
      onClose={props.togleModal}
      secondaryActions={[{ content: "Cancelar", onAction: props.togleModal }]}
      primaryAction={{
        content: "Continuar",
        onAction: addProductos,
        disabled: productos.length === 0,
      }}
    >
      <Modal.Section>
        <Card sectioned>
          <FormLayout>
            <div className="stack-fillEvenly">
              <Stack distribution="fillEvenly" wrap>
                <Select
                  label="Lista de precio"
                  options={preciosLista}
                  disabled={isLoading || isEditing}
                  onChange={onSetPrecioLista}
                  value={producto.precioLista.id}
                />
                <Autocomplete
                  options={options}
                  selected={selectedOptions}
                  onSelect={updateSelection}
                  textField={textField}
                  willLoadMoreResults
                  listTitle="Productos existentes"
                  loading={isLoading}
                  emptyState="No hay productos"
                />
              </Stack>
            </div>
            <Stack distribution="fillEvenly" wrap>
              <TextField
                min={1}
                align="right"
                type="number"
                label="Cantidad mínima"
                value={producto.minimo}
                onChange={(value) => changeState(value, "minimo")}
                onBlur={() =>
                  onBlurTextField(producto.minimo, changeState, null, "minimo")
                }
              />
              <TextField
                min={1}
                max={100}
                align="right"
                suffix="%"
                type="number"
                label="Valor del descuento"
                value={producto.porcentaje}
                onChange={(value) => changeState(value, "porcentaje")}
                onBlur={() =>
                  onBlurTextField(
                    producto.porcentaje,
                    changeState,
                    100,
                    "porcentaje"
                  )
                }
              />
            </Stack>
            <div className="flex justify-end">
              <Button
                monochrome
                onClick={isEditing ? onSetEdit : addProducto}
                disabled={
                  (Number(producto.porcentaje) === 0 ||
                    !producto.nombre ||
                    Number(producto.minimo) === 0 ||
                    props.selected
                      .concat(productos)
                      .find(
                        (i) =>
                          i.precioLista.id + i.id ===
                            producto.precioLista.id + producto.id &&
                          i.minimo === producto.minimo
                      )) &&
                  !isEditing
                }
              >
                {isEditing ? "Editar" : "Agregar"}
              </Button>
            </div>
          </FormLayout>
          {productos.length > 0 && (
            <DataTable
              headings={[
                "Lista de precios",
                "Articulo",
                "Cantidad mínima",
                "Descuento",
              ]}
              columnContentTypes={["text", "text", "text", "text"]}
              rows={productos.map(
                (
                  { precioLista, id, nombre, porcentaje, minimo, editing },
                  index
                ) => {
                  const Activator = ({ onClick }) => {
                    return (
                      <div className="flex items-center">
                        <div className="mr-4">
                          <TextStyle>{porcentaje} % </TextStyle>
                        </div>
                        <Button
                          plain
                          icon={MobileHorizontalDotsMajor}
                          onClick={onClick}
                        />
                      </div>
                    );
                  };
                  const preList = (
                    <TextStyle>
                      {precioLista.nombre}{" "}
                      {editing && isEditing ? (
                        <TextStyle variation="subdued">...editando</TextStyle>
                      ) : null}
                    </TextStyle>
                  );
                  const nombr = <TextStyle>{nombre}</TextStyle>;
                  const min = <TextStyle>{minimo}</TextStyle>;
                  const prcntj = (
                    <CustomPopover
                      items={[
                        {
                          content: "Editar",
                          onAction: () => onEditItem(index),
                          icon: EditMajor,
                        },
                        {
                          content: "Quitar",
                          onAction: () => onRemoveItem(index),
                          icon: DeleteMajor,
                          destructive: true,
                        },
                      ]}
                      activator={Activator}
                    />
                  );
                  return [preList, nombr, min, prcntj];
                }
              )}
            />
          )}
        </Card>
        <Banner status="info">
          <p>
            Los clientes pueden combinar descuentos por promoción, por cliente,
            por artículo y por volumen.
          </p>
        </Banner>
      </Modal.Section>
    </Modal>
  );
}

DescuentoProductoVolumenModal.propTypes = {
  isActive: PropTypes.bool,
  togleModal: PropTypes.func,
  onAddProducto: PropTypes.func,
  selected: PropTypes.array,
  precioLista: PropTypes.array,
};

DescuentoProductoVolumenModal.defaultProps = {
  isActive: false,
  togleModal: () => {},
  onAddProducto: () => {},
  selected: [],
  precioLista: [],
};
