import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  DisplayText,
  TextStyle,
  Form,
  Button,
  Banner,
  Spinner,
} from "@shopify/polaris";
import jwt from "jsonwebtoken";
import "./RegistroScreenStyles.css";
import { validaInvitacion, updateUsuario } from "../../actions/UsuariosActions";
import { clearAll } from "../../actions/AuthActions";
import { PasswordField } from "../../components";
import ApiServiceComercios from "../../services/ApiServiceComercios";

export default function RegistroScreen(props) {
  const dispatch = useDispatch();
  const router = useHistory();
  const [usuario, setUsuario] = useState({
    nombre: null,
    name: null,
    last_name: null,
  });
  const [isValid, setIsValid] = useState(null);
  const [passwordData, setPasswordData] = useState({
    password: "",
    isUpdated: false,
    passwordError: "",
  });
  const [isValidating, setIsValidating] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    const token = new URLSearchParams(props.location.search).get("ticket");
    jwt.verify(token, process.env.REACT_APP_SECRET_SEED, (error, decoded) => {
      if (error) {
        setIsValidating(false);
        dispatch(clearAll());
        return setIsValid(false);
      }
      const { data } = decoded;
      const isClienteComercio = data.hasOwnProperty("id_comercio");
      dispatch(validaInvitacion(token, isClienteComercio))
        .then((res) => {
          if (!res.ok) {
            dispatch(clearAll());
            setIsValid(false);
          }
          setIsValid(true);
          setUsuario(decoded.data);
          setIsValidating(false);
          return;
        })
        .catch((error) => {
          setIsValidating(false);

          setIsValid(false);
        });
    });
    return () => null;
  }, [dispatch, props]);

  function registerSubmit() {
    setIsLoading(true);
    const isClienteComercio = usuario.hasOwnProperty("id_comercio");
    dispatch(
      updateUsuario({
        password: passwordData.password,
        estado: 1,
        id: usuario.id,
        isClienteComercio,
        id_comercio: usuario.id_comercio,
        estatus: { tipo: 1, nombre: "Activo" },
        grant_type: usuario.grant_type ? usuario.grant_type : "",
      })
    )
      .then((res) => {
        setIsLoading(false);
        setPasswordData((state) => ({
          ...state,
          password: "",
          isUpdated: true,
        }));
      })
      .catch((error) => {
        setPasswordData((state) => ({
          ...state,
          passwordError:
            "Lo sentimos, no podemos registrar tu cuenta en este momento, intenta de nuevo más tarde",
        }));
        setIsLoading(false);
      });
  }

  function login() {
    router.replace("/login");
  }

  useEffect(() => {
    document.title = "B2BGO - Invitación";
    ApiServiceComercios.getImageData()
      .then(({ storeDB }) => {
        setImageData(storeDB?.imagen?.url);
      })
      .catch((error) => {
        throw error;
      });
    return () => null;
  }, []);

  if (isValidating === null) {
    return (
      <div className="w-full bg-white h-screen ">
        <div className="flex justify-center items-center h-full">
          <Spinner size="large" />
        </div>
      </div>
    );
  }

  return (
    <div id="bg-gradient-animation" className="w-full h-screen pt-48">
      <div className="login-card w-full sm:h-auto">
        <img
          src={imageData ? imageData : "b2bgologo.png"}
          alt="b2bgo-logo"
          className="mb-10 img-login"
        />
        {!isValid && !passwordData.isUpdated ? (
          <div className="mb-1">
            <DisplayText element="h2" size="large">
              {" "}
              Lo sentimos, esta invitación ya no es válida
            </DisplayText>
            <div className="mt-8">
              <Button primary onClick={login}>
                Ir a login
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div className="mb-1">
              <DisplayText element="h2" size="large">
                {" "}
                Bienvenido {usuario.nombre} {usuario.name} {usuario.last_name}
              </DisplayText>
            </div>
            {!passwordData.isUpdated ? (
              <div>
                <div className="mb-8">
                  <TextStyle element="p" variation="subdued">
                    Ingresa una contraseña para tu cuenta en B2BGO
                  </TextStyle>
                </div>
                <div
                  className={`${
                    passwordData.passwordError.length > 0 ? "block" : "hidden"
                  }`}
                >
                  <Banner title={passwordData.passwordError} status="warning" />
                </div>
                <Form onSubmit={registerSubmit}>
                  <div className="my-8 form-width">
                    <PasswordField
                      value={passwordData.password}
                      onChange={(text) =>
                        setPasswordData((state) => ({
                          ...state,
                          password: text,
                        }))
                      }
                    />
                  </div>
                  <div className="flex flex-row items-center">
                    <Button
                      loading={isLoading}
                      submit
                      primary
                      disabled={!passwordData.password.length > 0}
                    >
                      Registrar
                    </Button>
                    <div className="mx-8">
                      <Button plain primary onClick={login}>
                        Ir a login
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            ) : (
              <div>
                <div className="mb-8">
                  <TextStyle element="p" variation="subdued">
                    Tu cuenta está lista para usarse
                  </TextStyle>
                </div>
                <Button primary onClick={login}>
                  Ir a login
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
