import React from "react";
import {
  Card,
  SkeletonBodyText,
  SkeletonDisplayText,
  Stack,
  TextContainer,
} from "@shopify/polaris";

export default function CardItemSkeleton({ items = 3 }) {
  return (
    <div className="w-full">
      <Stack distribution="fill" wrap>
        {Array.from(Array(items).keys()).map((item) => {
          return (
            <Card sectioned key={item}>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </Card>
          );
        })}
      </Stack>
    </div>
  );
}
