import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Layout,
  Page,
  TextField,
  TextStyle,
} from "@shopify/polaris";

import {
  AddMajor,
  ChevronUpMinor,
  ChevronDownMinor,
  DragHandleMinor,
} from "@shopify/polaris-icons";

import {
  FiltroMenuSkeleton,
  Layer,
  PreventTransitionPrompt,
} from "../../components";

import { GhostButton } from "../../components/common/Index";

import { Table } from "antd";

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";

import arrayMove from "array-move";

import ApiServiceComercios from "../../services/ApiServiceComercios";
import { toggleToast } from "../../actions/InteractiveActions";
import { ExpandedRow } from "./ExpandedRow";
import AddElementMenuModal from "./AddElementMenuModal";

const DragHandle = sortableHandle(() => (
  <DragHandleMinor
    style={{
      cursor: "grab",
      fill: "#5c6ac4",
      inlineSize: "20px",
      margin: "auto",
    }}
  />
));

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

export default function NavegationMenuScreen({ forbidden }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingAll, setIsSavingAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [isExpanable, setIsExpanable] = useState(false);

  const [titleInput, setTitleInput] = useState("Menú principal");
  const [expandOnHover, setExpandOnHover] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);

  const getFiltersData = () => {
    setIsLoading(true);
    ApiServiceComercios.getMenuList().then(
      ({ expandOnHover, menus }) => {
        setExpandOnHover(expandOnHover);

        let newArr = menus.map((item, index) => ({
          ...item,
          index,
          order: index,
          key: index,
        }));

        setDataTable(newArr);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    getFiltersData();
  }, []);

  const columns = [
    {
      title: "",
      dataIndex: "_id",
      width: 50,
      className: "sort-drag-visible",
      ellipsis: true,
      render: () => <DragHandle />,
    },
    {
      title: "Título",
      ellipsis: true,
      dataIndex: "title",
      width: 120,
      className: "whitespace-nowrap",
    },
    {
      title: "Enlaces",
      dataIndex: "items",
      className: "options-drag-visible",
      ellipsis: true,
      render: (item) => item?.map((e) => e?.title)?.join(", "),
    },
    {
      title: () => (
        <GhostButton
          label="Guardando"
          secondLabel="Guardado"
          isSaved={isSaving}
        />
      ),
      width: 110,
      dataIndex: "_id",
      className: "actions-drag-visible",
      render: (id, data) => (
        <div className="flex justify-end">
          <ButtonGroup className="filter-group" segmented>
            <Button onClick={() => onHandleEdit(data)} size="slim">
              Editar
            </Button>
            <Button onClick={() => onHandleDelete(id)} size="slim">
              Eliminar
            </Button>
          </ButtonGroup>
        </div>
      ),
    },
  ];

  if (isLoading) {
    return (
      <Layer title="Filtros">
        <FiltroMenuSkeleton />
      </Layer>
    );
  }

  const handleModal = () => {
    setShowModal(!showModal);
    setDataEdit(null);
  };

  const goBack = () => history.push("/admin/tienda_online/navegacion");

  const DraggableContainer = (props) => {
    return (
      <SortableContainer
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={onSortEnd}
        {...props}
      />
    );
  };

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(dataTable, oldIndex, newIndex);
      newData.forEach((data, index) => (data["order"] = index));

      setIsSaving(true);
      await Promise.all(
        newData.map(async ({ _id, order }) => {
          ApiServiceComercios.putMenuList(_id, { order }).then(
            ({ message }) => {
              setIsSaving(false);
              dispatch(
                toggleToast({
                  message,
                })
              );
            }
          );
        })
      );
      setDataTable(newData);
    }
  };

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataTable.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const onHandleEdit = (data) => {
    setShowModal(true);
    setDataEdit(data);
  };

  const onHandleDelete = (id) => {
    ApiServiceComercios.deleteMenuList(id).then((_) => {
      getFiltersData();
      dispatch(
        toggleToast({
          message: "Menú eliminado correctamente",
        })
      );
    });
  };

  const saveChanges = () => {
    goBack();
    setIsSavingAll(true);
    ApiServiceComercios.actualizarFiltroPreferencias({
      expandOnHover
    }).then(({ message }) => {
      dispatch(
        toggleToast({
          message,
        })
      );
    })
  };

  const emptyState = (
    <div className="flex justify-center py-4">
      <TextStyle variation="subdued">Este menú no tiene ningún elemento.</TextStyle>
    </div>
  )

  return (
    <Layer title="Menús" forbidden={forbidden}>
      <Page
        title="Menú principal"
        breadcrumbs={[
          { content: "Atrás", url: "/admin/tienda_online/navegacion" },
        ]}
      >
        <PreventTransitionPrompt
          when={isBlocking}
          setIsBlocking={setIsBlocking}
          router={history}
          title="Tienes cambios sin guardar en esta página"
          message="Si abandonas esta página, perderás todos los cambios no guardados. ¿Estás seguro de que quieres salir de esta página?"
        />

        <Layout>
          <Layout.AnnotatedSection
            title="Título"
            description="Se utiliza un identificador para hacer referencia a un menú en en tu tienda."
          >
            <Card sectioned>
              <TextField
                label="Título"
                placeholder="Menú principal"
                value={titleInput}
                disabled
                onChange={(val) => {
                  setTitleInput(val);
                  setIsBlocking(true);
                }}
              />
            </Card>
          </Layout.AnnotatedSection>

          <Layout.AnnotatedSection
            title="Elementos del menú"
            description="Puedes crear menús anidados para mostrar menús desplegables."
          >
            <Card title="Elementos del menú">
              <Table
                pagination={false}
                showHeader={false}
                locale={{
                  emptyText: emptyState
                }}
                columns={columns}
                dataSource={dataTable}
                components={
                  !isExpanable && {
                    body: {
                      wrapper: DraggableContainer,
                      row: DraggableBodyRow,
                    },
                  }
                }
                expandable={{
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <Button
                        plain
                        icon={ChevronUpMinor}
                        onClick={(e) => onExpand(record, e)}
                      />
                    ) : (
                      <Button
                        plain
                        icon={ChevronDownMinor}
                        onClick={(e) => onExpand(record, e)}
                      />
                    ),
                  expandedRowRender: (data) => (
                    <ExpandedRow data={data} handleReload={getFiltersData} />
                  ),
                  onExpandedRowsChange: () =>
                    setIsExpanable((prevState) => !prevState),
                }}
                footer={() => (
                  <div className="flex flex-row pl-16 items-center">
                    <div className="px-2 self-center">
                      <Button plain onClick={handleModal} icon={AddMajor}>
                        Agregar elemento del menú
                      </Button>
                    </div>
                  </div>
                )}
              />
            </Card>
          </Layout.AnnotatedSection>

          <Layout.AnnotatedSection
            title="Presentación del menú"
            description="Los clientes podrán ver desplegados los menús al pasar el cursor encima."
          >
            <Card sectioned>
              <Checkbox
                label="Desplegar al pasar el cursor."
                checked={expandOnHover}
                onChange={setExpandOnHover}
              />
            </Card>
          </Layout.AnnotatedSection>
        </Layout>

        <div className="flex justify-end my-5">
          <ButtonGroup>
            <Button onClick={goBack}>Descartar</Button>
            <Button primary loading={isSavingAll} onClick={saveChanges}>
              Guardar
            </Button>
          </ButtonGroup>
        </div>

        {showModal && (
          <AddElementMenuModal
            active={showModal}
            handleModal={handleModal}
            handleReload={getFiltersData}
            dataEdit={dataEdit}
            lastCount={dataTable.length + 1}
          />
        )}
      </Page>
    </Layer>
  );
}
