import "./SectionsStyled.css";
export default function NotFoundStyled({ section }) {
  const { properties } = section;

  const getStyles = (field) => {
    const styles = {
      header: {
        color: properties.textColor,
        fontSize: 32,
      },
      content: {
        color: properties.textColor,
      },
      "banner-button": {
        background: properties.buttonColor,
        color: properties.textButtonColor,
      },
    };
    return styles[field];
  };

  return (
    <div
      className="h-full flex flex-col bg-white items-center py-20"
      style={{ ...getStyles("container") }}
    >
      <p className="footer-heading" style={{ ...getStyles("header") }}>
        {properties.title}
      </p>
      <p className="footer-content" style={{ ...getStyles("content") }}>
        {properties.subtitle}
      </p>
      {properties.textButton && (
        <button
          className="banner-button z-10 font-semibold"
          onClick={() => window.open(properties.urlButton, "_blank")}
          style={{ ...getStyles("banner-button") }}
        >
          {properties.textButton}
        </button>
      )}
    </div>
  );
}
