import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Page, Layout } from "@shopify/polaris";
import {
  Layer,
  HelpFooter,
  UsersByRol,
  AnnotatedSectionSkeletonBody,
} from "../../components";
import ApiServiceUsers from "../../services/ApiServiceUsers";

export default function PlanPermisosScreen({ forbidden }) {
  const dispatch = useDispatch();
  const [plan, setPlan] = useState({
    maxCollaborators: 0,
    currentUsers: 0,
  });
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const getRoles = () => {
      ApiServiceUsers.getMerchantRoles()
        .then(({ roles = [] }) => {
          setRoles(roles);
        })
        .finally(() => setLoading(false));
    };
    getRoles();
  }, []);

  useEffect(() => {
    function fetchCollaborators() {
      ApiServiceUsers.getMaxCollaborators().then(
        ({ maxCollaborators, currentUsers }) => {
          setPlan({ maxCollaborators, currentUsers });
        }
      );
    }

    fetchCollaborators();
  }, [dispatch]);

  if (loading) {
    return (
      <AnnotatedSectionSkeletonBody
        title="Usuarios y permisos"
        forbidden={forbidden}
        numberOfSections={2}
      />
    );
  }

  return (
    <Layer title="Usuarios y permisos" forbidden={forbidden}>
      <Page
        title="Usuarios y permisos"
        breadcrumbs={[
          { content: "Configuración", url: "/admin/configuracion" },
        ]}
        separator
      >
        <Layout>
          {roles.map((role, idx) => (
            <UsersByRol
              key={idx}
              role={role}
              canInviteUsers={plan.maxCollaborators > plan.currentUsers}
            />
          ))}
        </Layout>
        <HelpFooter
          title="usuarios y permisos"
          url="https://help.b2bgo.mx/configuracion/usuarios-y-permisos"
        />
      </Page>
    </Layer>
  );
}
