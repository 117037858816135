import { useState, useEffect } from "react";
import {
  Card,
  FormLayout,
  Select,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import ChooseColor from "../ChooseColor";
import { rickTextPosition } from "../../utils/constants";

export default function RichTextCard({
  item,
  handleMainChange = () => {},
  handleDeleteSection = () => {},
}) {
  const [richText, setRichText] = useState({
    title: "Habla de tu marca",
    subtitle: "Comparte información sobre tu marca con tus clientes.",
    textButton: "Comprar ahora",
    urlButton: "#",
    textColor: "#000000",
    backgroundColor: "#FFFFFF",
    buttonColor: "#1C2260",
    textButtonColor: "#FFFFFF",
    position: "center",
  });

  useEffect(() => {
    setRichText((prevState) => ({ ...prevState, ...item.properties }));
  }, [item]);

  const handleChange = (field, value) => {
    handleMainChange("properties", { ...richText, [field]: value }, item.id);
    setRichText((prevState) => ({ ...prevState, [field]: value }));
  };

  const {
    title,
    subtitle,
    textButton,
    urlButton,
    textColor,
    backgroundColor,
    buttonColor,
    textButtonColor,
    position,
  } = richText;

  return (
    <Card
      sectioned
      primaryFooterAction={{
        content: "Eliminar sección",
        destructive: true,
        outline: true,
        onAction: () => handleDeleteSection(item.id),
      }}
      footerActionAlignment="left"
    >
      <FormLayout>
        <TextField
          label="Título"
          placeholder="Título"
          value={title}
          onChange={(value) => handleChange("title", value)}
        />
        <TextField
          label="Subtítulo"
          placeholder="Subtítulo"
          value={subtitle}
          onChange={(value) => handleChange("subtitle", value)}
        />
        <TextField
          label="Etiqueta del botón"
          placeholder="Etiqueta del botón"
          value={textButton}
          onChange={(value) => handleChange("textButton", value)}
        />
        <TextField
          label="Enlace del botón"
          placeholder="https://..."
          value={urlButton}
          onChange={(value) => handleChange("urlButton", value)}
        />
        <Select
          label="Posición del contenido"
          placeholder="Selecciona una posición"
          value={position}
          onChange={(value) => handleChange("position", value)}
          options={rickTextPosition}
        />
        <TextStyle>Esquema de colores de la barra de anuncios</TextStyle>
        <ChooseColor
          handleColorPick={(value) => {
            handleChange("textColor", value);
          }}
          handleTextChange={(value) => handleChange("textColor", value)}
          colorHex={textColor}
          label="Color del texto"
        />
        <ChooseColor
          handleColorPick={(value) => {
            handleChange("backgroundColor", value);
          }}
          handleTextChange={(value) => handleChange("backgroundColor", value)}
          colorHex={backgroundColor}
          label="Color del fondo"
        />
        <ChooseColor
          handleColorPick={(value) => {
            handleChange("buttonColor", value);
          }}
          handleTextChange={(value) => handleChange("buttonColor", value)}
          colorHex={buttonColor}
          label="Color del botón"
        />
        <ChooseColor
          handleColorPick={(value) => {
            handleChange("textButtonColor", value);
          }}
          handleTextChange={(value) => handleChange("textButtonColor", value)}
          colorHex={textButtonColor}
          label="Color del texto del botón"
        />
      </FormLayout>
    </Card>
  );
}
