import React from 'react';
import { Layout, Card, TextContainer, SkeletonDisplayText, SkeletonBodyText } from '@shopify/polaris';

export default function AnuncioSkeleton() {
   return (
      <div className="my-10">
         <Layout>
            <Layout.Section>
               <Card sectioned>
                  <TextContainer>
                     <SkeletonDisplayText size="small" />
                     <SkeletonBodyText />
                  </TextContainer>
               </Card>
            </Layout.Section>
         </Layout>
      </div>
   )
}
