import moment from "moment";
import { Button, Icon, Stack, TextStyle } from "@shopify/polaris";
import { CircleTickMajor } from "@shopify/polaris-icons";
import { formatTo2Digits } from "../../../utils/Formats";

export default function SuccessPayment({
  totalAmountToPay,
  confirmPaymentData,
  onCloseModal,
}) {
  const currentDate = new Date();
  const date = `${moment(currentDate).format("LLLL")} `;

  return (
    <div className="p-8 pb-2 flex flex-col text-center items-center">
      <Icon source={CircleTickMajor} color="success" />
      <div className="mb-6">
        <p className="font-bold text-3xl my-6">Tu pago fue exitoso</p>
        <TextStyle variation="subdued">
          Acreditaremos este pago a tu saldo en breve
        </TextStyle>
      </div>
      <div className="flex w-full	 flex-col my-4">
        <div className="flex justify-between">
          <TextStyle>Importe:</TextStyle>
          <TextStyle>{formatTo2Digits(totalAmountToPay)}</TextStyle>
        </div>
        <hr className="my-6" />
        <div className="flex justify-between">
          <TextStyle>Fecha:</TextStyle>
          <TextStyle>{date}</TextStyle>
        </div>
        <hr className="my-6" />
        <div className="flex justify-between">
          <TextStyle>Folio:</TextStyle>
          <TextStyle>{confirmPaymentData?.reference}</TextStyle>
        </div>
        <hr className="my-6" />
        <div className="flex justify-end items-center mt-6 ">
          <Stack alignment="center">
            <Button primary onClick={() => onCloseModal(true)}>
              Aceptar
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
}
