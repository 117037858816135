import { 
    ADD_USERS,
    CLEAR_USERS,
    USER_BEGIN,
    USER_FAILED,
    DELETE_USER,
} from '../actions/UsuariosActions';


const initialState = {
    byId: {},
    allIds: [],
    isLoading: false
};

export default function UserReducer(state = initialState, action) {
    switch (action.type) {
        case USER_BEGIN:
            return {
                ...state,
                isLoading: true
            };
    
        case ADD_USERS:
            let allIds = [...new Set(state.allIds.concat(action.payload.data.allIds))];
            let byId = {
                ...state.byId,
                ...action.payload.data.byId
            }
            return {
                ...state,
                allIds,
                byId,
                isLoading: false
            };
    
        case USER_FAILED:
            return {
                ...state,
                isLoading: false
            };
    
        case CLEAR_USERS:
            
            return {
                ...state,
                byId: {},
                allIds: []
            };
        case DELETE_USER: {
            let allIds = [...state.allIds];
            let byId = {
                ...state.byId
            }
            allIds = allIds.filter((id) => id !== action.payload.id)
            delete byId[action.payload.data];
            return {
                ...state,
                allIds,
                byId,
            }
        }
        default:
            return state;
    }
}

export const isLoadingUsuarios = state => {
    return state.isLoading;
};