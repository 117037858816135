import React, { useState } from 'react';
import { Banner, Card, Checkbox, FormLayout, Modal, TextStyle } from "@shopify/polaris";

export default function InitializeModal({ active = true, handleChange, handleInitialize, isLoading }) {
   const [state, setState] = useState({
      catalogos: false,
      documentos: false,
      reportes: false,
      configuraciones: false
   });

   const handleState = (value, key) => {
      setState((s) => ({ ...s, [key]: value }));
   }

   return (
      <Modal
         open={active}
         onClose={handleChange}
         title="Inicializar información"
         primaryAction={{
            content: 'Inicializar',
            onAction: () => handleInitialize(state),
            destructive: true,
            loading: isLoading,
            disabled: isLoading
         }}
         secondaryActions={[
            {
               content: 'Descartar',
               onAction: handleChange,
               disabled: isLoading
            },
         ]}
      >
         <Banner status="info">
            <TextStyle>Generalmente se utiliza cuando se desea inicializar la información, para empezar a registrar catálogos, documentos y configuraciones de nuevo.</TextStyle>
         </Banner>
         <Card sectioned>
            <FormLayout>
               <TextStyle>Datos a inicializar</TextStyle>
               <Checkbox
                  label="Catálogos"
                  helpText="Artículos, clientes, descuentos y anuncios, creados o sincronizados."
                  checked={state.catalogos}
                  onChange={(value) => handleState(value, "catalogos")}
               />
               <Checkbox
                  label="Documentos"
                  helpText="Cotizaciones, pedidos y facturas, creados o sincronizados."
                  checked={state.documentos}
                  onChange={(value) => handleState(value, "documentos")}
               />
               <Checkbox
                  label="Reportes"
                  helpText="Estadística acumulada de ventas, pedidos y visitas."
                  checked={state.reportes}
                  onChange={(value) => handleState(value, "reportes")}
               />
               <Checkbox
                  label="Configuraciones"
                  helpText="Integración con Microsip, sucursales, envíos y entrega, impuestos e integraciones con chats."
                  checked={state.configuraciones}
                  onChange={(value) => handleState(value, "configuraciones")}
               />
            </FormLayout>
            <Banner status="warning" title="Este proceso no es reversible" >
               <TextStyle>¿Estás seguro(a) de que deseas inicializar la información de tu tienda en línea?</TextStyle>
            </Banner>
         </Card>
      </Modal>
   )
}

InitializeModal.defaultProps = {
   active: true,
   handleChange: () => { },
   handleInitialize: () => { },
   isLoading: false
}