import { Badge, Layout, Page, TextStyle } from "@shopify/polaris";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ClientInfoCard,
  FilesPaymentsDetailCard,
  HelpFooter,
  Layer,
  PaymentDetailCard,
  PaymentDetailSkeleton,
  TransactionsInfoCard,
} from "../../components";
import ApiServiceComercios from "../../services/ApiServiceComercios";
const PaymentsDetailScreen = ({ forbidden }) => {
  const { id } = useParams();
  const [paymentData, setPaymentData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPayment = async () => {
    setLoading(true);
    await ApiServiceComercios.getPaymentById(id)
      .then(({ payment = {} }) => {
        setPaymentData(payment);
      })
      .finally(() => setLoading(false));
  };

  const title__page = (
    <div className="flex flex-col">
      <div className="flex flex-row gap-2">
        <h1 className="font-bold text-3xl">{paymentData?.reference}</h1>
        {paymentData.status === "succeeded" ? (
          <Badge status="new" progress="complete">
            Pagado
          </Badge>
        ) : paymentData.status === "pending" ? (
          <Badge status="warning" progress="partiallyComplete">
            Pendiente
          </Badge>
        ) : (
          <Badge status="critical" progress="complete">
            Fallido
          </Badge>
        )}
      </div>
      <TextStyle variation="subdued">
        {" "}
        {moment(paymentData.createdAt).format(
          "D [de] MMM [de] YYYY [a las] HH:mm a"
        )}
      </TextStyle>
    </div>
  );

  if (loading) {
    return (
      <Layer title="Detalle de pago" forbidden={forbidden}>
        <PaymentDetailSkeleton fullWidth />
      </Layer>
    );
  }

  return (
    <Layer title="Detalle de pago" forbidden={forbidden}>
      <Page
        titleMetadata={title__page}
        fullWidth
        breadcrumbs={[{ content: "Configuración", url: "/admin/pagos" }]}
      >
        <Layout>
          <Layout.Section>
            <TransactionsInfoCard
              origin={paymentData.origin}
              dateBill={paymentData.createdAt}
              data={
                paymentData.origin === "orders"
                  ? paymentData.order
                  : paymentData.bill
              }
            />
            {paymentData?.files?.length !== 0 && (
              <FilesPaymentsDetailCard files={paymentData.files} />
            )}
            <PaymentDetailCard payment={paymentData} />
          </Layout.Section>
          <Layout.Section secondary>
            <ClientInfoCard clientData={paymentData.customerId} />
          </Layout.Section>
        </Layout>
        <HelpFooter title="pagos" url="https://help.b2bgo.mx/pagos" />
      </Page>
    </Layer>
  );
};

export default PaymentsDetailScreen;
