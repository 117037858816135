export default function normalizeById(all = [], attribute = "_id") {
  let allIds = [];
  let byId = {};

  all.forEach((item) => {
    allIds.push(item[attribute]);
    byId[item[attribute]] = item;
  });

  return { allIds, byId, all };
}

export const normalizeByPosition = (data) => {
  let groupedItemsObj = data.reduce((groups, currentItem) => {
    let { sku } = currentItem;

    if (!groups[sku]) {
      groups[sku] = [];
    }
    groups[sku].push(currentItem);
    return groups;
  }, {});

  let groupedItemsArr = Object.keys(groupedItemsObj).map((sku) => {
    return {
      sku,
      collection: groupedItemsObj[sku].sort((a, b) => (a > b ? 1 : -1)),
    };
  });
  groupedItemsArr = groupedItemsArr.sort((a, b) => (a.sku > b.sku ? 1 : -1));
  groupedItemsArr = groupedItemsArr.map((i) => ({
    ...i,
    ...joinItems(i.collection),
    collection: undefined,
  }));

  return groupedItemsArr;
};

const joinItems = (arr) => {
  let obj = {};
  let images = arr.map((i) => i.imagen);
  images = [...new Set(images)];
  arr.forEach((element) => {
    obj = { ...obj, ...element };
  });
  obj = {
    ...obj,
    imagen: images,
    etiquetas: obj.hasOwnProperty("etiquetas")
      ? [...new Set(obj.etiquetas.split(","))]
      : [].filter((i) => i).map((i) => i.trim()),
  };
  return obj;
};
