import {
  Badge,
  Banner,
  Button,
  Card,
  ChoiceList,
  ContextualSaveBar,
  DataTable,
  FormLayout,
  Layout,
  Page,
  Select,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import {
  DeleteMajor,
  EditMajor,
  MobileHorizontalDotsMajor,
} from "@shopify/polaris-icons";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toggleToast } from "../../actions/InteractiveActions";
import {
  ComercioDetalleSkeleton,
  CustomPopover,
  DatePicker,
  DeleteModal,
  DescuentoProductoVolumenModal,
  HelpFooter,
  Layer,
  LineaArticuloVolumenModal,
} from "../../components";
import { getZonaHoraria } from "../../reducers";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { onBlurTextField } from "../../utils/TextFieldValidations";
import { scrollToTop } from "../../utils/windowActions";
import "./AddDiscountByVolumenScreenStyles.css";

const aplicaciones = [
  { label: "Todos los productos", value: "Todos los productos" },
  { label: "Líneas de productos", value: "Líneas de productos" },
  { label: "Productos", value: "Productos" },
];

function AddDiscountByVolumenScreen({ forbidden }) {
  const { id } = useParams();

  const [active, setActive] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const router = useHistory();
  const dispatch = useDispatch();
  const zonaHoraria = useSelector(getZonaHoraria);

  const deselectedPrecios = useRef([]);
  const [descuento, setDescuento] = useState({
    titulo: "",
    aplicacion: ["Todos los productos"],
    porcentaje: "1",
    estatus: "Habilitado",
    descuentos: [],
    precioLista: { nombre: "", id: "" },
    minimo: "1",
    usado: 0,
    fechaInicio: new Date(),
    horaInicio: "00:00",
    fechaFin: new Date(),
    horaFin: "00:00",
  });
  const [isDirty, setIsDirty] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [preciosLista, setPreciosLista] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCustom, setIsCustom] = useState(true);
  const [lineaArticulos, setLineaArticulos] = useState([]);
  const [productosDescuento, setProductosDescuento] = useState([]);
  const [isActiveLineaModal, setIsActiveLineaModal] = useState(false);
  const [isActiveProductoModal, setIsActiveProductoModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [errorSaving, setErrorSaving] = useState("");
  const [initialLoading, setInitialLoading] = useState(false);

  useEffect(() => {
    function obtenerDescuento() {
      setInitialLoading(true);
      ApiServiceComercios.obtenerDescuento(id)
        .then(({ ok, descuento }) => {
          if (ok) {
            const { fechaInicio, fechaFin } = descuento;
            const horaInicio = !fechaInicio
              ? "00:00"
              : moment(fechaInicio).format("HH:mm");
            const horaFin = !fechaFin
              ? "00:00"
              : moment(fechaFin).format("HH:mm");

            setDescuento((s) => ({
              ...s,
              id: descuento.id,
              titulo: descuento.titulo,
              estatus:
                descuento.estatus.tipo === 1 || descuento.estatus.tipo === 3
                  ? "Habilitado"
                  : "No habilitada",
              descuentos: descuento.volumenes.map((i) => ({
                precioLista: i.precioListaId,
                porcentaje: String(i.porcentaje),
                minimo: String(i.cantidadMinima),
              })),
              usado: descuento.usado,
              fechaInicio,
              fechaFin,
              horaInicio,
              horaFin,
            }));
            setIsCustom([1, 3].includes(descuento.estatus.tipo) ? true : false);

            if (descuento.articulos.length > 0) {
              setProductosDescuento((s) => [
                ...s,
                ...descuento.articulos
                  .filter((i) => !_.isEmpty(i.precioListaId))
                  .map((i) => ({
                    editing: false,
                    id: i.articulo._id,
                    nombre: i.articulo.nombre,
                    porcentaje: String(i.porcentaje),
                    minimo: String(i.cantidadMinima),
                    precioLista: {
                      id: i.precioListaId._id,
                      nombre: i.precioListaId.nombre,
                    },
                    productPrecio: i.precioListaId._id + i.articulo._id,
                  })),
              ]);
            }
            if (descuento.lineaArticulos.length > 0) {
              setLineaArticulos((s) => [
                ...s,
                ...descuento.lineaArticulos.map((i) => ({
                  editing: false,
                  linea: i.linea,
                  porcentaje: String(i.porcentaje),
                  minimo: String(i.cantidadMinima),
                  precioLista: {
                    id: i.precioListaId._id,
                    nombre: i.precioListaId.nombre,
                  },
                  lineaPrecio: i.precioListaId._id + i.linea,
                })),
              ]);
            }
          }
        })
        .finally(() => {
          setInitialLoading(false);
        });
    }
    ApiServiceComercios.obtenerPrecioListas()
      .then(({ ok, precioListas }) => {
        if (ok) {
          const preListas = precioListas.map((i) => ({
            label: i.nombre,
            value: i._id,
          }));
          setPreciosLista(preListas);
          deselectedPrecios.current = preListas;
          setDescuento((s) => ({
            ...s,
            precioLista: {
              nombre: precioListas[0].nombre,
              id: precioListas[0]["_id"],
            },
          }));
        }
      })
      .finally(() => setIsLoading(false));
    if (id !== undefined) {
      obtenerDescuento();
    } else {
      setIsCustom(true);
    }
  }, [id]);

  const toggleLineaModal = useCallback(
    () => setIsActiveLineaModal(!isActiveLineaModal),
    [isActiveLineaModal]
  );
  const toggleProductoModal = useCallback(
    () => setIsActiveProductoModal(!isActiveProductoModal),
    [isActiveProductoModal]
  );

  function changeState(value, key) {
    if (key === "aplicacion" && value[0] === "Líneas de productos")
      toggleLineaModal();
    if (key === "aplicacion" && value[0] === "Productos") toggleProductoModal();
    if (key === "estatus" && value[0] === "No habilitada") setIsCustom(false);
    if (key === "estatus" && value[0] === "Habilitado") setIsCustom(true);
    if (["fechaInicio", "fechaFin"].includes(key)) {
      const dates = runDateTimeValidator(value, key);
      setDescuento((state) => ({
        ...state,
        ...dates,
      }));
    } else {
      setDescuento((state) => ({
        ...state,
        [key]: key === "estatus" ? value[0] : value,
      }));
    }
    value && setIsDirty(true);
  }

  function runDateTimeValidator(value, key) {
    let dates = {
      fechaInicio: descuento.fechaInicio,
      fechaFin: descuento.fechaFin,
      horaInicio: descuento.horaInicio,
      horaFin: descuento.horaFin,
    };
    dates[key] = value;

    let dateStart =
      moment(dates.fechaInicio).format("YYYY-MM-DD") + "T" + dates.horaInicio;
    dateStart = moment(dateStart).format();
    let dateEnd =
      moment(dates.fechaFin).format("YYYY-MM-DD") + "T" + dates.horaFin;
    dateEnd = moment(dateEnd).format();

    if (key === "fechaInicio") {
      if (moment(dateStart).isAfter(dateEnd)) {
        dateEnd = moment(dateStart).add(1, "day").format();
        dates["fechaFin"] = dateEnd;
      }
    }
    if (key === "fechaFin") {
      if (moment(dateStart).isAfter(dateEnd)) {
        dateStart = moment(dateEnd).subtract(1, "day").format();
        dates["fechaInicio"] = dateStart;
      }
    }
    return dates;
  }

  function handleSave() {
    let fechaInicio =
      moment(descuento.fechaInicio).format("YYYY-MM-DD") +
      "T" +
      descuento.horaInicio;
    fechaInicio = moment(fechaInicio).format();

    let fechaFin =
      moment(descuento.fechaFin).format("YYYY-MM-DD") + "T" + descuento.horaFin;
    fechaFin = moment(fechaFin).format();

    setIsCreating(true);
    setErrorSaving("");
    ApiServiceComercios.crearDescuento({
      titulo: descuento.titulo,
      fechaInicio: isCustom ? fechaInicio : null,
      fechaFin: isCustom ? fechaFin : null,
      estatus: isCustom ? 3 : 2,
      lineaArticulos: lineaArticulos.map((l) => ({
        porcentaje: Number(l.porcentaje),
        linea: l.linea,
        precioListaId: l.precioLista.id,
        cantidadMinima: Number(l.minimo),
      })),
      articulos: productosDescuento.map((l) => ({
        porcentaje: Number(l.porcentaje),
        articulo: l.id,
        precioListaId: l.precioLista.id,
        cantidadMinima: Number(l.minimo),
      })),
      zona: zonaHoraria,
      tipo: 4,
      volumenes: descuento.descuentos.map((i) => ({
        precioListaId: i.precioLista,
        cantidadMinima: Number(i.minimo),
        porcentaje: Number(i.porcentaje),
      })),
      tipoAplicacion:
        lineaArticulos.length === 0 && productosDescuento.length === 0
          ? 1
          : lineaArticulos.length > 0 && productosDescuento.length === 0
          ? 2
          : lineaArticulos.length === 0 && productosDescuento.length > 0
          ? 3
          : 4,
    })
      .then(({ ok, message }) => {
        if (ok) {
          dispatch(toggleToast({ message }));
          setTimeout(() => {
            handleDiscard();
          }, 100);
        }
      })
      .catch((error) => {
        scrollToTop();
        if (error.response) {
          const { response } = error;
          const { data } = response;
          if (data) {
            setErrorSaving(data.error);
          }
        }
        setIsCreating(false);
      });
  }

  function handleUpdate() {
    let fechaInicio =
      moment(descuento.fechaInicio).format("YYYY-MM-DD") +
      "T" +
      descuento.horaInicio;
    fechaInicio = moment(fechaInicio).format();

    let fechaFin =
      moment(descuento.fechaFin).format("YYYY-MM-DD") + "T" + descuento.horaFin;
    fechaFin = moment(fechaFin).format();

    setIsCreating(true);
    setErrorSaving("");
    ApiServiceComercios.actualizarDescuento(
      {
        titulo: descuento.titulo,
        fechaInicio: isCustom ? fechaInicio : null,
        fechaFin: isCustom ? fechaFin : null,
        estatus: isCustom ? 3 : 2,
        lineaArticulos: lineaArticulos.map((l) => ({
          porcentaje: Number(l.porcentaje),
          linea: l.linea,
          precioListaId: l.precioLista.id,
          cantidadMinima: Number(l.minimo),
        })),
        articulos: productosDescuento.map((l) => ({
          porcentaje: Number(l.porcentaje),
          articulo: l.id,
          precioListaId: l.precioLista.id,
          cantidadMinima: Number(l.minimo),
        })),
        zona: zonaHoraria,
        tipo: 4,
        volumenes: descuento.descuentos.map((i) => ({
          precioListaId: i.precioLista,
          cantidadMinima: Number(i.minimo),
          porcentaje: Number(i.porcentaje),
        })),
        tipoAplicacion:
          lineaArticulos.length === 0 && productosDescuento.length === 0
            ? 1
            : lineaArticulos.length > 0 && productosDescuento.length === 0
            ? 2
            : lineaArticulos.length === 0 && productosDescuento.length > 0
            ? 3
            : 4,
      },
      id
    )
      .then(({ ok, message }) => {
        if (ok) {
          dispatch(toggleToast({ message }));
          setTimeout(() => {
            handleDiscard();
          }, 100);
        }
      })
      .catch((error) => {
        scrollToTop();
        if (error.response) {
          const { response } = error;
          const { data } = response;
          if (data) {
            setErrorSaving(data.error);
          }
        }
        setIsCreating(false);
      });
  }

  function handleDiscard() {
    router.goBack();
  }

  function handleCustomDate() {
    setIsCustom(!isCustom);
    changeState(["Habilitado"], "estatus");
  }

  const handleModalChange = useCallback(() => setActive(!active), [active]);

  function addDescuento() {
    // const selectedDesc = descuento.descuentos.map(i => i.precioLista).concat([descuento.precioLista.id]);
    // const preListFiltered = preciosLista.map((i) => selectedDesc.includes(i.value) ? ({...i, disabled: true}) : i );
    // setPreciosLista(preListFiltered);
    setDescuento((s) => ({
      ...s,
      porcentaje: "0",
      minimo: "0",
      descuentos: [
        ...s.descuentos,
        {
          precioLista: descuento.precioLista.id,
          porcentaje: descuento.porcentaje,
          minimo: descuento.minimo,
        },
      ],
    }));
  }

  function eliminarDescuento() {
    setIsDeleting(true);
    ApiServiceComercios.eliminarDescuento(id)
      .then((result) => {
        if (result.ok) {
          dispatch(toggleToast({ message: result.message }));
          handleDiscard();
        }
      })
      .catch((err) => {});
  }

  function onAddLinea(items) {
    let merged = _.unionBy(items, lineaArticulos, "lineaPrecio");
    setLineaArticulos(merged);
  }

  function onAddProducto(items) {
    let merged = _.unionBy(items, productosDescuento, "productPrecio");
    setProductosDescuento(merged);
  }

  function onRemoveLinea(value) {
    setLineaArticulos((s) => [...s.filter((i) => i.lineaPrecio !== value)]);
  }

  function onRemoveProducto(value) {
    setProductosDescuento((s) => [
      ...s.filter((i) => i.productPrecio !== value),
    ]);
  }

  function onEditProducto(value) {
    let arr = [
      ...productosDescuento.map((i) => {
        return i.productPrecio === value ? { ...i, editing: true } : i;
      }),
    ];
    setProductosDescuento(arr);
    toggleProductoModal();
  }

  function onEditLinea(value) {
    let arr = [
      ...lineaArticulos.map((i) => {
        return i.lineaPrecio === value ? { ...i, editing: true } : i;
      }),
    ];
    setLineaArticulos(arr);
    toggleLineaModal();
  }

  function onEditPreList({ precioLista, minimo, porcentaje }) {
    setIsEditing(true);
    const preList = deselectedPrecios.current.find(
      (i) => i.value === precioLista
    );
    setDescuento((s) => ({
      ...s,
      minimo,
      porcentaje,
      precioLista: { nombre: preList["label"], id: preList["value"] },
    }));
  }

  function onRemovePreList(index) {
    const arr = [...descuento.descuentos];
    arr.splice(index, 1);
    setDescuento((s) => ({
      ...s,
      porcentaje: "0",
      minimo: "0",
      descuentos: arr,
    }));
  }

  function onSetEdit() {
    let arr = [...descuento.descuentos];
    let index = _.findIndex(arr, { precioLista: descuento.precioLista.id });
    arr.splice(index, 1, {
      porcentaje: descuento.porcentaje,
      minimo: descuento.minimo,
      precioLista: descuento.precioLista.id,
    });
    setDescuento((s) => ({
      ...s,
      porcentaje: "0",
      minimo: "0",
      descuentos: arr,
    }));
    setIsEditing(false);
  }

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Cambios no guardados"
      saveAction={{
        content: id ? "Guardar" : "Guardar",
        onAction: id ? handleUpdate : handleSave,
        disabled:
          validateIsMicrosip() ||
          isCreating ||
          !descuento.titulo ||
          (productosDescuento.length === 0 &&
            lineaArticulos.length === 0 &&
            descuento.descuentos.length === 0),
        loading: isCreating,
      }}
      discardAction={{
        onAction: handleDiscard,
        disabled: isCreating,
      }}
    />
  ) : null;

  if (isLoading || initialLoading) {
    return (
      <Layer
        title={id ? "Editar descuento" : "Agregar descuento"}
        forbidden={forbidden}
      >
        <ComercioDetalleSkeleton />
      </Layer>
    );
  }

  function validateIsMicrosip() {
    return id && descuento.id;
  }

  return (
    <Layer
      title={id ? "Editar descuento" : "Agregar descuento"}
      forbidden={forbidden}
    >
      <Page
        breadcrumbs={[{ content: "Descuentos", onAction: handleDiscard }]}
        title={
          id ? "Editar descuento por volumen" : "Agregar descuento por volumen"
        }
        subtitle={
          descuento.exclusive && <Badge status="success">Exclusiva</Badge>
        }
      >
        {contextualSaveBarMarkup}
        {isActiveLineaModal && (
          <LineaArticuloVolumenModal
            precioLista={preciosLista}
            isActive={isActiveLineaModal}
            togleModal={toggleLineaModal}
            onAddLinea={onAddLinea}
            selected={lineaArticulos}
          />
        )}
        {isActiveProductoModal && (
          <DescuentoProductoVolumenModal
            precioLista={preciosLista}
            isActive={isActiveProductoModal}
            togleModal={toggleProductoModal}
            onAddProducto={onAddProducto}
            selected={productosDescuento}
          />
        )}
        {validateIsMicrosip() && (
          <div className="mb-8">
            <Card>
              <Banner
                title="Descuento sincronizado desde Microsip"
                status="warning"
              >
                Para mantener la integridad de la información, los cambios de
                esta política de descuento la deberás hacer en Microsip. En la
                próxima sincronización se reflejarán los cambios.
              </Banner>
            </Card>
          </div>
        )}
        <Layout>
          <Layout.Section>
            {errorSaving && (
              <div className="mb-10">
                <Banner status="critical">{errorSaving}</Banner>
              </div>
            )}
            <Card sectioned>
              <TextField
                disabled={validateIsMicrosip()}
                type="text"
                label="Título"
                value={descuento.titulo}
                onChange={(value) => changeState(value, "titulo")}
                placeholder="Descuentos para mayoristas"
                helpText="Los clientes podrían ver esto al levantar sus pedidos."
              />
            </Card>
            <Card sectioned>
              <FormLayout>
                <TextStyle variation="strong">Condiciones generales</TextStyle>
                <Stack distribution="fillEvenly" wrap>
                  <Select
                    label="Lista de precio general"
                    options={preciosLista}
                    disabled={validateIsMicrosip() || isLoading || isEditing}
                    onChange={(value) => {
                      setDescuento((s) => ({
                        ...s,
                        precioLista: {
                          nombre: preciosLista.find((i) => i.value === value)[
                            "label"
                          ],
                          id: value,
                        },
                      }));
                    }}
                    value={descuento.precioLista.id}
                  />
                  <TextField
                    disabled={validateIsMicrosip()}
                    min={1}
                    align="right"
                    type="number"
                    label="Cantidad mínima"
                    value={descuento.minimo}
                    onChange={(value) => changeState(value, "minimo")}
                    onBlur={() =>
                      onBlurTextField(
                        descuento.minimo,
                        changeState,
                        null,
                        "minimo"
                      )
                    }
                  />
                  <TextField
                    disabled={validateIsMicrosip()}
                    min={1}
                    max={100}
                    align="right"
                    suffix="%"
                    type="number"
                    label="Descuento"
                    value={descuento.porcentaje}
                    onChange={(value) => changeState(value, "porcentaje")}
                    onBlur={() =>
                      onBlurTextField(
                        descuento.porcentaje,
                        changeState,
                        100,
                        "porcentaje"
                      )
                    }
                  />
                  <Button
                    disabled={
                      validateIsMicrosip() ||
                      Number(descuento.porcentaje) === 0 ||
                      Number(descuento.minimo) === 0 ||
                      descuento.descuentos.find(
                        (i) =>
                          i.precioLista === descuento.precioLista.id &&
                          i.minimo === descuento.minimo
                      )
                    }
                    monochrome
                    onClick={isEditing ? onSetEdit : addDescuento}
                  >
                    {" "}
                    {isEditing ? "Editar" : "Agregar"}
                  </Button>
                </Stack>
              </FormLayout>
              {descuento.descuentos.length > 0 && (
                <DataTable
                  headings={[
                    "Lista de precios",
                    "Cantidad mínima",
                    "Descuento",
                  ]}
                  columnContentTypes={["text", "text", "text"]}
                  rows={descuento.descuentos
                    ?.filter((i) => !_.isEmpty(i.precioLista))
                    .map(({ precioLista, porcentaje, minimo }, index) => {
                      const Activator = ({ onClick }) => {
                        return (
                          <div className="flex items-center">
                            <div className="mr-4">
                              <TextStyle>{porcentaje} % </TextStyle>
                            </div>
                            <Button
                              plain
                              icon={MobileHorizontalDotsMajor}
                              onClick={onClick}
                            />
                          </div>
                        );
                      };
                      const name = (
                        <TextStyle>
                          {
                            deselectedPrecios.current.find(
                              (i) => i.value === precioLista
                            )["label"]
                          }
                        </TextStyle>
                      );
                      const min = <TextStyle>{minimo}</TextStyle>;
                      const prcntj = (
                        <CustomPopover
                          items={[
                            {
                              content: "Editar",
                              onAction: () =>
                                onEditPreList({
                                  precioLista,
                                  porcentaje,
                                  minimo,
                                }),
                              icon: EditMajor,
                            },
                            {
                              content: "Quitar",
                              onAction: () => onRemovePreList(index),
                              icon: DeleteMajor,
                              destructive: true,
                            },
                          ]}
                          activator={Activator}
                        />
                      );
                      return [name, min, prcntj];
                    })}
                />
              )}
            </Card>
            <Card>
              <Card.Section>
                <FormLayout>
                  <TextStyle variation="strong">Se aplica a</TextStyle>
                  <ChoiceList
                    title=""
                    titleHidden
                    choices={aplicaciones}
                    selected={descuento.aplicacion}
                    onChange={(value) => changeState(value, "aplicacion")}
                  />
                </FormLayout>
              </Card.Section>
              {descuento.aplicacion[0] !== aplicaciones[0].value && (
                <Card.Section>
                  {descuento.aplicacion[0] === aplicaciones[1].value &&
                    lineaArticulos.length > 0 && (
                      <DataTable
                        headings={[
                          "Líneas de productos",
                          "Lista de precios",
                          "Cantidad mínima",
                          "Descuento",
                        ]}
                        columnContentTypes={["text", "text", "text", "text"]}
                        rows={lineaArticulos.map(
                          ({
                            linea,
                            porcentaje,
                            precioLista,
                            minimo,
                            lineaPrecio,
                          }) => {
                            const Activator = ({ onClick }) => {
                              return (
                                <div className="flex items-center">
                                  <div className="mr-4">
                                    <TextStyle>{porcentaje} % </TextStyle>
                                  </div>
                                  <Button
                                    plain
                                    icon={MobileHorizontalDotsMajor}
                                    onClick={onClick}
                                  />
                                </div>
                              );
                            };
                            const name = <TextStyle>{linea}</TextStyle>;
                            const pre = (
                              <TextStyle>{precioLista.nombre}</TextStyle>
                            );
                            const porc = (
                              <CustomPopover
                                items={[
                                  {
                                    content: "Editar",
                                    onAction: () => onEditLinea(lineaPrecio),
                                    icon: EditMajor,
                                  },
                                  {
                                    content: "Quitar",
                                    onAction: () => onRemoveLinea(lineaPrecio),
                                    icon: DeleteMajor,
                                    destructive: true,
                                  },
                                ]}
                                activator={Activator}
                              />
                            );
                            const min = <TextStyle>{minimo}</TextStyle>;
                            return [name, pre, min, porc];
                          }
                        )}
                      />
                    )}
                  {descuento.aplicacion[0] === aplicaciones[2].value &&
                    productosDescuento.length > 0 && (
                      <DataTable
                        headings={[
                          "Producto",
                          "Lista de precios",
                          "Cantidad mínima",
                          "Descuento",
                        ]}
                        columnContentTypes={["text", "text", "text"]}
                        rows={productosDescuento.map(
                          ({
                            nombre,
                            porcentaje,
                            precioLista,
                            minimo,
                            productPrecio,
                          }) => {
                            const Activator = ({ onClick }) => {
                              return (
                                <div className="flex items-center">
                                  <div className="mr-4">
                                    <TextStyle>{porcentaje} % </TextStyle>
                                  </div>
                                  <Button
                                    plain
                                    icon={MobileHorizontalDotsMajor}
                                    onClick={onClick}
                                  />
                                </div>
                              );
                            };
                            const name = <TextStyle>{nombre}</TextStyle>;
                            const pre = (
                              <TextStyle>{precioLista.nombre}</TextStyle>
                            );
                            const prcntj = (
                              <CustomPopover
                                items={[
                                  {
                                    content: "Editar",
                                    onAction: () =>
                                      onEditProducto(productPrecio),
                                    icon: EditMajor,
                                  },
                                  {
                                    content: "Quitar",
                                    onAction: () =>
                                      onRemoveProducto(productPrecio),
                                    icon: DeleteMajor,
                                    destructive: true,
                                  },
                                ]}
                                activator={Activator}
                              />
                            );
                            const min = <TextStyle>{minimo}</TextStyle>;
                            return [name, pre, min, prcntj];
                          }
                        )}
                      />
                    )}
                  <div className="mt-6">
                    {descuento.aplicacion[0] === aplicaciones[1].value && (
                      <Button monochrome onClick={toggleLineaModal}>
                        Agregar líneas
                      </Button>
                    )}
                    {descuento.aplicacion[0] === aplicaciones[2].value && (
                      <Button monochrome onClick={toggleProductoModal}>
                        Agregar productos
                      </Button>
                    )}
                  </div>
                </Card.Section>
              )}
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <Card sectioned title="Vigencia">
              <FormLayout>
                <div className="mb-10">
                  <ChoiceList
                    selected={descuento.estatus}
                    choices={[
                      { label: "Habilitado", value: "Habilitado" },
                      { label: "No habilitada", value: "No habilitada" },
                    ]}
                    onChange={(val) => changeState(val, "estatus")}
                    disabled={validateIsMicrosip()}
                  />
                </div>
                {isCustom || descuento.estatus === "Habilitado" ? (
                  <>
                    <DatePicker
                      date={descuento.fechaInicio}
                      title="Fecha de inicio"
                      onSelectDate={({ start }) =>
                        changeState(start, "fechaInicio")
                      }
                      disableBefore={moment().subtract(1, "day").toDate()}
                    />
                    <div className="timepicker-field mt-2">
                      <TextField
                        type="time"
                        value={descuento.horaInicio}
                        onChange={(value) => changeState(value, "horaInicio")}
                        prefix="GTM-5"
                      />
                    </div>
                    <div className="mt-4">
                      <DatePicker
                        date={descuento.fechaFin}
                        title="Fecha de fin"
                        onSelectDate={({ start }) =>
                          changeState(start, "fechaFin")
                        }
                        disableBefore={moment().subtract(1, "day").toDate()}
                      />
                      <div className="timepicker-field mt-2">
                        <TextField
                          type="time"
                          value={descuento.horaFin}
                          onChange={(value) => changeState(value, "horaFin")}
                          prefix="GTM-5"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <Button plain onClick={handleCustomDate}>
                    Fijar fecha de vigencia
                  </Button>
                )}
              </FormLayout>
            </Card>
            {id && (
              <Card sectioned title="Rendimiento">
                <TextStyle>
                  Se ha usado {descuento.usado} veces esta política de descuento
                </TextStyle>
              </Card>
            )}
          </Layout.Section>
        </Layout>
        <div className="flex flex-row flex-wrap justify-between my-10">
          {id && (
            <Button destructive onClick={handleModalChange}>
              Eliminar descuento
            </Button>
          )}
          <div className="flex flex-row">
            <Button disabled={isCreating} onClick={handleDiscard}>
              Descartar
            </Button>
            <div className="w-4" />
            <Button
              disabled={
                validateIsMicrosip() ||
                isCreating ||
                !descuento.titulo ||
                (productosDescuento.length === 0 &&
                  lineaArticulos.length === 0 &&
                  descuento.descuentos.length === 0)
              }
              loading={isCreating}
              primary
              onClick={id ? handleUpdate : handleSave}
            >
              {id ? "Guardar" : "Guardar"}
            </Button>
          </div>
        </div>
        <DeleteModal
          handleChange={handleModalChange}
          isLoading={isDeleting}
          handleDelete={eliminarDescuento}
          active={active}
          title="Eliminar descuento"
          body="¿Estás seguro de que deseas eliminar este descuento?"
        />
        <HelpFooter title="Descuentos" url="https://help.b2bgo.mx/descuentos" />
      </Page>
    </Layer>
  );
}

export default AddDiscountByVolumenScreen;
