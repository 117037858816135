import {
  Button,
  Card,
  FormLayout,
  Modal,
  SkeletonBodyText,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import SkeletonInput from "antd/lib/skeleton/Input";
import React, { useEffect, useState } from "react";
import useToggle from "../../hooks/useToggle";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import DeleteModal from "../DeleteModal";

export default function AddManualPaymentModal({
  isActive,
  onClose,
  onAdd,
  onDelete,
  loading,
  isDeleting,
  id,
}) {
  const [loadingData, setLoadingData] = useState(false);
  const [toggleModal, setToggleModal] = useToggle(isActive);
  const [toggleDelete, setToggleDelete] = useToggle(false);
  const [manualPaymentData, setManualPaymentData] = useState({
    name: "",
    instructions: "",
  });

  const onSave = () => {
    onAdd(manualPaymentData, id);
  };

  const onDeletePayment = () => {
    onDelete(id);
  };

  const getPayment = async (ID) => {
    setLoadingData(true);
    await ApiServiceComercios.getManualPaymentById(ID)
      .then(({ manualPayment = {} }) => {
        setManualPaymentData(manualPayment);
      })
      .finally(() => setLoadingData(false));
  };

  useEffect(() => {
    if (id) {
      getPayment(id);
    }
  }, [id]);

  const handleOnchange = () => {
    setToggleModal();
    setToggleDelete();
  };
  return (
    <>
      <Modal
        title="Configurar forma de pago manual"
        open={toggleModal}
        onClose={onClose}
        primaryAction={{
          content: "Guardar",
          onAction: onSave,
          disabled:
            loadingData ||
            loading ||
            !manualPaymentData.name ||
            !manualPaymentData.instructions,
          loading: loading,
        }}
        secondaryActions={[
          {
            content: "Descartar",
            onAction: onClose,
            disabled: loadingData || loading,
          },
        ]}
        footer={
          <>
            {id && (
              <Button
                loading={isDeleting}
                onClick={handleOnchange}
                disabled={loadingData || loading}
                destructive
              >
                Eliminar
              </Button>
            )}
          </>
        }
      >
        <Modal.Section>
          <Card sectioned>
            <FormLayout>
              {loadingData ? (
                <div className="flex flex-col gap-4">
                  <SkeletonBodyText lines={1} />
                  <SkeletonInput size="large" />

                  <SkeletonBodyText lines={1} />
                  <SkeletonInput size="large" />
                  <SkeletonBodyText lines={1} />
                </div>
              ) : (
                <>
                  <FormLayout>
                    <TextField
                      type="text"
                      label="Nombre de la forma de pago personalizada"
                      value={manualPaymentData.name}
                      onChange={(val) =>
                        setManualPaymentData((s) => ({ ...s, name: val }))
                      }
                    />
                    <TextField
                      type="text"
                      label="Instrucciones de pago"
                      multiline={4}
                      value={manualPaymentData.instructions}
                      onChange={(val) =>
                        setManualPaymentData((s) => ({
                          ...s,
                          instructions: val,
                        }))
                      }
                    />
                    <div className="text-xl">
                      <TextStyle variation="subdued">
                        Se muestra a los clientes cuando hacen un pedido con
                        esta forma de pago.
                      </TextStyle>
                    </div>
                  </FormLayout>
                </>
              )}
            </FormLayout>
          </Card>
        </Modal.Section>
      </Modal>
      {toggleDelete && (
        <DeleteModal
          active={toggleDelete}
          title="Eliminar forma de pago manual"
          body={"Esta acción no puede deshacerse."}
          handleChange={handleOnchange}
          handleDelete={onDeletePayment}
          isLoading={isDeleting}
        />
      )}
    </>
  );
}
