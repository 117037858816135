import React from "react";
import {
  Button,
  ColorPicker,
  Popover,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import useToggle from "../../hooks/useToggle";
import { convertHsbToHex, hexToHSL } from "../../utils/formatData";

export default function ChooseColor({
  handleColorPick = () => {},
  handleTextChange = () => {},
  colorHex = "#000000",
  label = "",
}) {
  const [active, setActive] = useToggle(false);

  const activator = (
    <div>
      <Stack alignment="center" spacing="tight">
        <Button plain onClick={setActive}>
          <div
            style={{
              height: "30px",
              width: "30px",
              borderRadius: "50%",
              background: colorHex,
            }}
          />
        </Button>
        <div className="flex flex-col cursor-pointer" onClick={setActive}>
          <TextStyle variation="strong">{label}</TextStyle>
          <TextStyle>{colorHex}</TextStyle>
        </div>
      </Stack>
    </div>
  );

  return (
    <Popover active={active} activator={activator} onClose={setActive}>
      <Popover.Section>
        <ColorPicker
          onChange={(value) => handleColorPick(convertHsbToHex(value))}
          color={colorHex.length === 7 ? hexToHSL(colorHex) : {}}
        />
      </Popover.Section>
      <Popover.Section>
        <TextField value={colorHex} onChange={handleTextChange} maxLength={7} />
      </Popover.Section>
    </Popover>
  );
}
