import React from "react";
import {
  Button,
  Caption,
  Card,
  Icon,
  Stack,
  TextStyle,
  Thumbnail,
  Tooltip,
} from "@shopify/polaris";
import { AttachmentMajor, MobileCancelMajor } from "@shopify/polaris-icons";

export default function DropzoneFiles({
  files = [],
  hasDelete = false,
  onDelete = () => {},
  hasMargin = true,
}) {
  if (files.length === 0) return null;

  return (
    <div className={hasMargin ? "mt-8" : ""}>
      <Stack wrap>
        {files.map((file, idx) => (
          <Stack alignment="center" key={idx}>
            <Card>
              <div className="flex items-center">
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => window.open(file.url, "_blank")}
                >
                  {(file.mimetype || file.type).includes("image") &&
                  (file.mimetype || file.type) !== "image/svg+xml" ? (
                    <Thumbnail
                      size="medium"
                      alt={file.name || ""}
                      source={file.url || window.URL.createObjectURL(file)}
                    />
                  ) : (
                    <div
                      className="flex px-8 border-r items-center"
                      style={{ minHeight: 70 }}
                    >
                      <Icon source={AttachmentMajor} />
                    </div>
                  )}
                  <div
                    className="flex flex-col ml-4 pr-2 justify-center"
                    style={{ minHeight: 70, minWidth: 190, maxWidth: 190 }}
                  >
                    <div className="pb-2 overflow-hidden whitespace-nowrap">
                      <Tooltip content={file.name}>
                        <TextStyle>{file.name}</TextStyle>
                      </Tooltip>
                    </div>
                    <div className="flex items-center w-full">
                      <div className="uppercase px-2 bg-gray-700 text-white rounded mr-2">
                        {file.extention}
                      </div>
                      <Caption>
                        {file.size} {hasDelete ? "bytes" : ""}{" "}
                      </Caption>
                    </div>
                  </div>
                </div>
                {hasDelete && (
                  <div className="timeLineActions" style={{ minWidth: 40 }}>
                    <Button
                      plain
                      onClick={() => onDelete(file)}
                      icon={MobileCancelMajor}
                    />
                  </div>
                )}
              </div>
            </Card>
          </Stack>
        ))}
      </Stack>
    </div>
  );
}
