import React from 'react'
import { FormLayout, Modal, TextField, Card } from '@shopify/polaris'

export default function ChatIntegration({ onClose, chatError, handleSave, isLoading, openModal, chat, setChat, selected }) {
   return (
      <Modal
         title="Coloca tu código aquí para configurar tu chat"
         open={openModal}
         onClose={onClose}
         primaryAction={{content:"Guardar", onAction: handleSave, disabled: isLoading, loading: isLoading}}
         secondaryActions={[{content: "Cancelar", onAction: onClose, disabled: isLoading}]}
      >
         <Modal.Section>
            <Card.Section>
               <FormLayout>
                  <div className="flex">
                     <img className="chat-logo-modal" src={selected === "TAWKTO" ? "/tawk-to.png" : "/gorgias-logo.png"} alt=""/>
                  </div>
                  <TextField
                     placeholder="Pega aquí tu código de chat"
                     error={chatError}
                     disabled={isLoading}
                     label="Pega el código para la integración con el chat"
                     multiline={8}
                     value={chat}
                     onChange={(val) => setChat(val)}
                  />
               </FormLayout>
            </Card.Section>
         </Modal.Section>
      </Modal>
   )
}
