import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Card, FooterHelp, Link, Page, Tabs } from "@shopify/polaris";
import { useDispatch, useSelector } from "react-redux";
import { OportunidadesList, Layer } from "../../components";
import OportunidadesContainer from "../../containers/OportunidadesContainer";
import { TabsOportunidadesScreen as tabs } from "../../utils/Tabs";
import { resetPage } from "../../actions/CotizacionesActions";
import useDebounce from "../../services/useDebounce";
import useAbility from "../../hooks/useAbility";
import { fetchOpportunities } from "../../actions/OportunidadesActions";
import useUrlSearch from "../../hooks/useUrlSearch";
import { getPaginationOpportunities } from "../../reducers";

const objSearch = [
  "query",
  "phase",
  "sort",
  "order",
  "created_at",
  "selected_dates",
  "closing_at",
  "selected_closing_dates",
  "type",
  "origin",
  "vendor",
  "tagged_with",
  "tab_selected",
];
export default function OportunidadesComercioScreen({ forbidden }) {
  const isForbiddenCreate = useAbility("write", "Oportunidades");
  const { pathname } = useLocation();
  const { updateHistory, getSearch } = useUrlSearch({ objSearch, pathname });

  const pagination = useSelector(getPaginationOpportunities);
  const dispatch = useDispatch();
  const queryValueRef = useRef(
    decodeURIComponent(getSearch("query", "", true))
  );
  const taggedRef = useRef();
  const [closingAt, setClosingAt] = useState(
    getSearch("closing_at", "todos", true)
  );
  const [selectedClosingAt, setSelectedClosingAt] = useState(
    getSearch("selected_closing_dates", {
      start: new Date(),
      end: new Date(),
    })
  );
  const [createdAt, setCreatedAt] = useState(
    getSearch("created_at", "todos", true)
  );
  const [selectedCreatedAt, setSelectedCreatedAt] = useState(
    getSearch("selected_dates", {
      start: new Date(),
      end: new Date(),
    })
  );
  const [tabSelected, setTabSelected] = useState(
    Number(getSearch("tab_selected", 0, true))
  );
  const [queryValue, setQueryValue] = useState(
    decodeURIComponent(getSearch("query", "", true))
  );
  const [phaseOpportunity, setPhaseOpportunity] = useState(
    getSearch("phase", [])
  );
  const [opportunityType, setOpportunityType] = useState(getSearch("type", []));
  const [origin, setOrigin] = useState(getSearch("origin", []));
  const [opportunityVendor, setOpportunityVendor] = useState(
    getSearch("vendor", [])
  );

  const [order, setOrder] = useState(getSearch("order", ["-1"]));
  const [sort, setSort] = useState(getSearch("sort", ["folio"]));
  const [selectedItems, setSelectedItems] = useState([]);
  const [taggedWith, setTaggedWith] = useState(
    getSearch("tagged_with", "", true)
  );

  const debouncedSearchTerm = useDebounce(queryValue, 1500);
  const debouncedTaggedTerm = useDebounce(taggedWith, 900);

  useEffect(() => {
    queryValueRef.current = queryValue;
    taggedRef.current = taggedWith;
  });

  useEffect(() => {
    dispatch(
      fetchOpportunities({
        sort: sort[0],
        query: decodeURIComponent(queryValueRef.current),
        orden: order[0],
        phase: JSON.stringify(
          phaseOpportunity.length > 0
            ? phaseOpportunity
            : [
                ...new Set(
                  phaseOpportunity.concat(
                    tabSelected === 1 ? [] : [tabs[tabSelected].panelID]
                  )
                ),
              ]
        ),
        vendors: JSON.stringify(opportunityVendor),
        type: JSON.stringify(opportunityType),
        origin: JSON.stringify(origin),
        closingDate: closingAt === "todos" ? undefined : selectedClosingAt,
        createdDate: createdAt === "todos" ? undefined : selectedCreatedAt,
        taggedWith: taggedRef.current,
      })
    );
  }, [
    dispatch,
    sort,
    order,
    origin,
    phaseOpportunity,
    opportunityType,
    opportunityVendor,
    tabSelected,
    closingAt,
    createdAt,
    debouncedSearchTerm,
    debouncedTaggedTerm,
    selectedClosingAt,
    selectedCreatedAt,
    pagination.currentPage,
  ]);

  function handleChangeStates(
    value,
    setState,
    key,
    useStringify = true,
    resetTab = false
  ) {
    updateHistory(
      {
        [key]: value,
      },
      useStringify
    );
    resetPagination();
    setState(value);
    if (resetTab) {
      setTabSelected(1);
      updateHistory(
        {
          tab_selected: 1,
        },
        false
      );
    }
  }

  function resetPagination() {
    dispatch(resetPage());
  }

  function handleSelectAll(values = []) {
    setSelectedItems(values);
  }
  function handleSelect(selected, val) {
    if (selected) {
      setSelectedItems((state) => [...new Set(state.concat(val))]);
    } else {
      setSelectedItems(selectedItems.filter((sel) => val !== sel));
    }
  }
  return (
    <Layer title="Oportunidades" forbidden={forbidden} scrollToTop>
      <Page
        fullWidth
        title="Oportunidades"
        primaryAction={
          !isForbiddenCreate
            ? {
                content: "Crear oportunidad",
                url: "/admin/oportunidades/agregar",
              }
            : undefined
        }
      >
        <OportunidadesContainer
          selectedItems={selectedItems}
          handleSelect={handleSelect}
        >
          {({ isLoading, pagination, oportunidades, oportunidadesColumns }) => {
            return (
              <Card>
                <Tabs
                  tabs={tabs}
                  selected={tabSelected}
                  onSelect={(value) =>
                    handleChangeStates(
                      value,
                      setTabSelected,
                      "tab_selected",
                      false
                    )
                  }
                >
                  <OportunidadesList
                    selectedItems={selectedItems}
                    handleSelectAll={handleSelectAll}
                    oportunidadesColumns={oportunidadesColumns}
                    oportunidades={oportunidades}
                    phaseOpportunity={phaseOpportunity}
                    origin={origin}
                    opportunityType={opportunityType}
                    opportunityVendor={opportunityVendor}
                    closingAt={closingAt}
                    createdAt={createdAt}
                    orden={order}
                    orderSelected={sort}
                    pagination={pagination}
                    queryValue={decodeURIComponent(queryValue)}
                    selectedClosingAt={{
                      start: new Date(selectedClosingAt.start),
                      end: new Date(selectedClosingAt.end),
                    }}
                    setSelectedClosingAt={(value) =>
                      handleChangeStates(
                        value,
                        setSelectedClosingAt,
                        "selected_closing_dates"
                      )
                    }
                    selectedCreatedAt={{
                      start: new Date(selectedCreatedAt.start),
                      end: new Date(selectedCreatedAt.end),
                    }}
                    setSelectedCreatedAt={(value) =>
                      handleChangeStates(
                        value,
                        setSelectedCreatedAt,
                        "selected_dates"
                      )
                    }
                    taggedWith={taggedWith}
                    handleTaggedWithChange={(value) =>
                      handleChangeStates(
                        value,
                        setTaggedWith,
                        "tagged_with",
                        false
                      )
                    }
                    handleTaggedWithRemove={() =>
                      handleChangeStates("", setTaggedWith, "tagged_with")
                    }
                    isLoading={isLoading}
                    handlePhaseOpportunityChange={(value) =>
                      handleChangeStates(
                        value,
                        setPhaseOpportunity,
                        "phase",
                        true,
                        true
                      )
                    }
                    handlePhaseOpportunityRemove={() =>
                      handleChangeStates(
                        [],
                        setPhaseOpportunity,
                        "phase",
                        true,
                        true
                      )
                    }
                    handleOriginChange={(value) =>
                      handleChangeStates(value, setOrigin, "origin")
                    }
                    handleOpportunityTypeChange={(value) =>
                      handleChangeStates(value, setOpportunityType, "type")
                    }
                    handleOriginRemove={() =>
                      handleChangeStates([], setOrigin, "origin")
                    }
                    handleOpportunityTypeRemove={() =>
                      handleChangeStates([], setOpportunityType, "type")
                    }
                    handleVendorChange={(value) =>
                      handleChangeStates(value, setOpportunityVendor, "vendor")
                    }
                    handleVendorRemove={() =>
                      handleChangeStates([], setOpportunityVendor, "vendor")
                    }
                    handleFiltersQueryChange={(value) =>
                      handleChangeStates(
                        value ? encodeURIComponent(value) : value,
                        setQueryValue,
                        "query",
                        false
                      )
                    }
                    handleOrdenChange={(value) =>
                      handleChangeStates(value, setOrder, "order")
                    }
                    handleOrderSelectedChange={(value) =>
                      handleChangeStates(value, setSort, "sort")
                    }
                    handleOrderSelectedRemove={() =>
                      handleChangeStates(["folio"], setSort, "sort")
                    }
                    handleQueryValueRemove={() =>
                      handleChangeStates("", setQueryValue, "query", false)
                    }
                    handleDateCreatedRemove={() =>
                      handleChangeStates(
                        "todos",
                        setCreatedAt,
                        "created_at",
                        false
                      )
                    }
                    handleCreatedAtChange={(_, value) =>
                      handleChangeStates(
                        value,
                        setCreatedAt,
                        "created_at",
                        false
                      )
                    }
                    handleDateClosingRemove={() =>
                      handleChangeStates(
                        "todos",
                        setClosingAt,
                        "closing_at",
                        false
                      )
                    }
                    handleClosingAtChange={(_, value) =>
                      handleChangeStates(
                        value,
                        setClosingAt,
                        "closing_at",
                        false
                      )
                    }
                  />
                </Tabs>
              </Card>
            );
          }}
        </OportunidadesContainer>
        <FooterHelp>
          Más información de{" "}
          <Link url="https://help.b2bgo.mx/oportunidades">oportunidades</Link>
        </FooterHelp>
      </Page>
    </Layer>
  );
}
