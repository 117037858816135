import React from 'react';
import { FooterHelp, Link } from '@shopify/polaris';

export default function HelpFooter({title, url}) {
   return (
      <FooterHelp>
         Más información de <Link url={url}>{title}</Link>
      </FooterHelp>
   )
}
