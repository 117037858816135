import { b2bgoApiaxios, b2bgoAuthaxios } from "../utils/Axios";

const ApiServiceUsers = {
  API_USERS: process.env.NODE_ENV === "development" ? "3002" : "users",

  /**
   * Usuarios
   */

  async getMerchantUsers(id = "") {
    const method = "GET";
    const url = `/users-comercios/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async getMerchantRoles() {
    const method = "GET";
    const url = "/users/comercios/roles";
    return this.makeAuthorizedRequest({ method, url });
  },

  async getMerchantRole(id = "") {
    const method = "GET";
    const url = `/users/comercios/roles/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerUsuario(id) {
    const method = "GET";
    const url = `user/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async getMaxCollaborators() {
    const method = "GET";
    const url = "/users-max-collaboratos";
    return this.makeAuthorizedRequest({ method, url });
  },

  async crearUsuario(data = {}) {
    const method = "POST";
    const url = `/user`;
    data["estado"] = 2;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async validaInvitacion(isClienteComercio) {
    const method = "GET";
    const url = isClienteComercio
      ? "/user/verify-client-invitation"
      : "/user/verify-invitation";
    return this.makeAuthorizedRequest({ method, url });
  },

  async resendInvitation(id) {
    const method = "POST";
    const url = `/user/resend-invitation/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async updateUsuario(data = {}) {
    const { id, isClienteComercio = false } = data;
    const method = "PUT";
    const url = isClienteComercio
      ? `/user/cliente-comercio/${id}`
      : `/user/${id}`;
    delete data.isClienteComercio;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async eliminarUsuario(id) {
    const method = "DELETE";
    const url = `/user/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  makeQueryParams(params) {
    let queries = "";
    for (var key in params) {
      queries += `${key}=${params[key]}&`;
    }
    return queries;
  },

  async makeRequest(requestData = {}) {
    try {
      let res = await b2bgoApiaxios({
        ...requestData,
        api_uri: this.API_USERS,
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  async makeAuthorizedRequest(requestData = {}) {
    try {
      let res = await b2bgoAuthaxios({
        ...requestData,
        api_uri: this.API_USERS,
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  },
};

export default ApiServiceUsers;
