import {
  Card,
  Checkbox,
  FormLayout,
  RangeSlider,
  Select,
  TextStyle,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import { contentPositions } from "../../utils/constants";
import ChooseColor from "../ChooseColor";

export default function BannerCollection({
  item = null,
  handleMainChange = () => {},
}) {
  const [banner, setBanner] = useState({
    design: "full-width",
    sliderHeight: "small",
    position: "centerToCenter",
    showTitle: true,
    showDescription: true,
    showImage: true,
    opacity: "100",
    textColor: "#000000",
    backgroundColor: "#FFFFFF",
    hideOnMobile: false,
  });

  useEffect(() => {
    setBanner((prevState) => ({
      ...prevState,
      ...item.properties,
    }));
  }, [item]);

  const handleChange = (field, value) => {
    handleMainChange("properties", { ...banner, [field]: value }, item.id);
    setBanner((prevState) => ({ ...prevState, [field]: value }));
  };

  const {
    design,
    sliderHeight,
    position,
    showTitle,
    showDescription,
    showImage,
    opacity,
    textColor,
    backgroundColor,
    hideOnMobile,
  } = banner;

  return (
    <Card>
      <Card.Section>
        <FormLayout>
          <TextStyle variation="strong">Banner de la colección</TextStyle>
          <Select
            label="Diseño"
            placeholder="Selecciona un diseño"
            options={[
              {
                label: "Ancho completo",
                value: "full-width",
              },
              {
                label: "Ajustado",
                value: "fitted-width",
              },
            ]}
            value={design}
            onChange={(val) => handleChange("design", val)}
          />
          <Select
            label="Altura de la imagen"
            placeholder="Selecciona una altura"
            options={[
              {
                label: "Pequeña",
                value: "small",
              },
              {
                label: "Mediana",
                value: "medium",
              },
              {
                label: "Grande",
                value: "large",
              },
            ]}
            value={sliderHeight}
            onChange={(val) => handleChange("sliderHeight", val)}
          />
          <Select
            label="Posición del contenido"
            placeholder="Selecciona una posición"
            value={position}
            onChange={(value) => handleChange("position", value)}
            options={contentPositions}
          />
          <Checkbox
            label="Mostrar nombre"
            checked={showTitle}
            onChange={(val) => handleChange("showTitle", val)}
          />
          <Checkbox
            label="Mostrar descripción"
            checked={showDescription}
            onChange={(val) => handleChange("showDescription", val)}
          />
          <Checkbox
            label="Mostrar la imagen de colección"
            checked={showImage}
            onChange={(val) => handleChange("showImage", val)}
          />
          <div className="flex flex-row items-end max-items">
            <RangeSlider
              label="Opacidad de la sobreposición de imagen"
              output
              min={0}
              max={100}
              value={opacity}
              onChange={(val) => handleChange("opacity", val)}
            />
            <div className="ml-6 whitespace-nowrap">
              <TextStyle>{opacity} %</TextStyle>
            </div>
          </div>
          <TextStyle>Esquema de colores</TextStyle>
          <ChooseColor
            handleColorPick={(value) => {
              handleChange("textColor", value);
            }}
            handleTextChange={(value) => handleChange("textColor", value)}
            colorHex={textColor}
            label="Color del texto"
          />
          <ChooseColor
            handleColorPick={(value) => {
              handleChange("backgroundColor", value);
            }}
            handleTextChange={(value) => handleChange("backgroundColor", value)}
            colorHex={backgroundColor}
            label="Color del fondo"
          />
        </FormLayout>
      </Card.Section>
      <Card.Section title="Diseño para móviles">
        <Checkbox
          label="Ocultar en móviles"
          checked={hideOnMobile}
          onChange={(val) => handleChange("hideOnMobile", val)}
        />
      </Card.Section>
    </Card>
  );
}
