import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPermissions } from "../reducers";
import ability from "../auth/ability";

export default function useAbility(access, can) {
  const [isForbidden, setIsForbidden] = useState(true);
  const permissions = useSelector(getPermissions);

  useEffect(() => {
    setIsForbidden(ability(permissions).cannot(access, can));
  }, [access, can, permissions]);

  return isForbidden;
}
