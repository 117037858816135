import { useState, useEffect } from "react";
import {
  Card,
  Checkbox,
  DropZone,
  FormLayout,
  Select,
  TextField,
  TextStyle,
  Spinner,
  Thumbnail,
} from "@shopify/polaris";
import ApiServiceConfig from "../../services/ApiServiceConfig";
import { validImageTypes } from "../../utils/constants";

export default function VideoSection({
  item,
  handleMainChange = () => {},
  handleDeleteSection = () => {},
}) {
  const [video, setVideo] = useState({
    title: "Video",
    titleSize: "medium",
    src: "",
    poster: "/empty-banner.svg",
    hideOnMobile: false,
  });
  const [isAddingFiles, setIsAddingFiles] = useState(false);

  useEffect(() => {
    setVideo((prevState) => ({
      ...prevState,
      ...item.properties,
    }));
  }, [item]);

  const handleDropZone = (
    _dropFiles = [],
    acceptedFiles = [],
    _rejectedFiles = []
  ) => {
    const formData = new FormData();
    if (acceptedFiles.length > 0) {
      setIsAddingFiles(true);
      acceptedFiles.forEach((file) => {
        formData.append("files", file);
      });

      ApiServiceConfig.uploadFile(formData)
        .then(({ files = [] }) => {
          const poster = files[0].url;
          handleChange("poster", poster);
        })
        .finally(() => setIsAddingFiles(false));
    }
  };

  const handleChange = (field, value) => {
    handleMainChange("properties", { ...video, [field]: value }, item.id);
    setVideo((prevState) => ({ ...prevState, [field]: value }));
  };

  const { title, titleSize, src, poster, hideOnMobile } = video;

  return (
    <Card
      primaryFooterAction={{
        content: "Eliminar sección",
        destructive: true,
        outline: true,
        onAction: () => handleDeleteSection(item.id),
      }}
      footerActionAlignment="left"
    >
      <Card.Section>
        <FormLayout>
          <TextStyle variation="strong">Video</TextStyle>
          <TextField
            label="Encabezado"
            value={title}
            onChange={(val) => handleChange("title", val)}
            placeholder="Video"
            autoComplete="off"
          />
          <Select
            label="Tamaño del título"
            placeholder="Selecciona un tamaño"
            value={titleSize}
            onChange={(val) => handleChange("titleSize", val)}
            options={[
              {
                label: "Pequeño",
                value: "small",
              },
              {
                label: "Mediano",
                value: "medium",
              },
              {
                label: "Grande",
                value: "large",
              },
            ]}
          />
          <div className="flex justify-between items-end">
            <div
              className="BannerSliderItem w-full mb-10 "
              style={{ height: 70 }}
            >
              <DropZone
                label={
                  <div>
                    <TextStyle>Imagen de portada</TextStyle>
                  </div>
                }
                labelAction={{
                  content: isAddingFiles ? <Spinner size="small" /> : "",
                }}
                accept={validImageTypes.join(",")}
                onDrop={handleDropZone}
                disabled={isAddingFiles}
              >
                <DropZone.FileUpload
                  actionTitle={poster ? "Cambiar imagen" : "Seleccionar imagen"}
                />
              </DropZone>
            </div>
            {poster && (
              <div className="ml-4 mb-2">
                <Thumbnail source={poster} size="medium" />
              </div>
            )}
          </div>
          <TextField
            label="URL del video"
            value={src}
            onChange={(val) => handleChange("src", val)}
            placeholder="Video"
            autoComplete="off"
            helpText="El video se reproduce en la página."
          />
        </FormLayout>
      </Card.Section>
      <Card.Section title="DISEÑO PARA MÓVILES">
        <FormLayout>
          <Checkbox
            label="Ocultar en móviles"
            checked={hideOnMobile}
            onChange={(val) => handleChange("hideOnMobile", val)}
          />
        </FormLayout>
      </Card.Section>
    </Card>
  );
}
