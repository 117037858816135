import { Badge, Link, TextStyle } from "@shopify/polaris";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  getPaginationPayments,
  getPayments,
  isLoadingPayments,
} from "../../reducers";
import { formatTo2Digits } from "../../utils/Formats";
import { capitalizeFirstLetter } from "../../utils/stringModifiers";

export default function PaymentListContainer({ children }) {
  const router = useHistory();
  const isLoading = useSelector(isLoadingPayments);
  const pagination = useSelector(getPaginationPayments);
  const payments = useSelector(getPayments).map((item) => ({
    ...item,
    id: item._id,
  }));

  function navigate(id) {
    router.push(`/admin/pagos/${id}`);
  }

  let paymentColumns = [
    {
      title: "Fecha",
      dataIndex: "createdAt",
      render: (createdAt, { id }) => (
        <div className="flex flex-row items-center pl-6 cursor-auto">
          <div className="py-6 pr-6 w-auto h-full no-underline">
            <div className="no-underline">
              <Link removeUnderline onClick={() => navigate(id)}>
                <TextStyle>
                  {moment(createdAt).format(
                    "D [de] MMM [de] YYYY [a las] HH:mm a"
                  )}
                </TextStyle>
              </Link>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Referencia",
      dataIndex: "reference",
      render: (reference, { id }) => (
        <div className="w-full h-full p-6 whitespace-nowrap cursor-auto">
          <div className="no-underline">
            <Link removeUnderline onClick={() => navigate(id)}>
              <TextStyle variation="strong">{reference}</TextStyle>
            </Link>
          </div>
        </div>
      ),
    },
    {
      title: "Estado",
      dataIndex: "status",
      render: (status, { id }) => (
        <Link removeUnderline onClick={() => navigate(id)}>
          <div className="w-full h-full p-6 whitespace-nowrap cursor-auto">
            {status === "succeeded" ? (
              <Badge status="new" progress="complete">
                Pagado
              </Badge>
            ) : status === "pending" ? (
              <Badge status="warning" progress="partiallyComplete">
                Pendiente
              </Badge>
            ) : (
              <Badge status="critical" progress="complete">
                Fallido
              </Badge>
            )}
          </div>
        </Link>
      ),
    },
    {
      title: "Cliente",
      dataIndex: "customerId",
      render: (customerId, { id }) => (
        <Link removeUnderline onClick={() => navigate(id)} monochrome>
          <div className="w-full h-full p-6 whitespace-nowrap cursor-auto">
            <TextStyle>{customerId?.nombre}</TextStyle>
          </div>
        </Link>
      ),
    },
    {
      title: "Forma de pago",
      dataIndex: "paymentMethod",
      render: (paymentMethod, { id }) => (
        <Link removeUnderline onClick={() => navigate(id)} monochrome>
          <div className="w-full h-full p-6 whitespace-nowrap cursor-auto">
            <TextStyle>
              {capitalizeFirstLetter(paymentMethod?.brand) || "Pago manual"}
            </TextStyle>
          </div>
        </Link>
      ),
    },
    {
      title: "Total",
      dataIndex: "amount",
      render: (amount, { id }) => (
        <Link removeUnderline onClick={() => navigate(id)} monochrome>
          <div className="w-full h-full p-6 whitespace-nowrap cursor-auto">
            <TextStyle>{formatTo2Digits(amount, true)}</TextStyle>
          </div>
        </Link>
      ),
    },
  ];

  return <>{children({ isLoading, pagination, payments, paymentColumns })}</>;
}
