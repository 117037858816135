import { useState } from "react";
import { useLocation } from "react-router-dom";

export default function usePagination({ limit = 20 }) {
  const [pagination, setPagination] = useState({
    currentPage:
      Number(new URLSearchParams(useLocation().search).get("currentPage")) || 0,
    sizePerPage:
      Number(new URLSearchParams(useLocation().search).get("sizePerPage")) ||
      limit,
    totalCount: 0,
  });

  const { currentPage, sizePerPage, totalCount } = pagination;

  const onChangeState = (obj) => {
    setPagination((s) => ({
      ...s,
      ...obj,
    }));
  };

  return { onChangeState, currentPage, sizePerPage, totalCount };
}
