import React from "react";

import { DragNDropTree } from "../components";
import {
  ADD_FILTERS,
  FILTERS_BEGIN,
  FILTERS_FAILED,
  CLEAR_FILTERS,
} from "../actions/FiltersActions";

const initialState = {
  byId: {},
  allIds: [],
  allIdsDB: [],
  isLoading: false,
  currentPage: 0,
  sizePerPage: 20,
  total: 0,
};

export default function FiltersReducer(state = initialState, action) {
  switch (action.type) {
    case FILTERS_BEGIN: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ADD_FILTERS: {
      let allIds = [
        ...new Set(state.allIds.concat(action.payload.data.allIds)),
      ];
      let byId = {
        ...state.byId,
        ...action.payload.data.byId,
      };
      let allIdsDB = action.payload.data.allIdsDB;
      const { all } = action.payload.data;

      const allTags = all.map((value, index) => ({
        name: value.title,
        key: value.title + index,
        index: index,
      }));

      all.forEach((filter, index) => {
        const tags = filter.tags.map((value, index) => ({
          name: value,
          key: value + index,
          index,
          value: value,
          label: value,
        }));

        filter["key"] = index;
        filter["index"] = index;
        filter["allTags"] = allTags;
        filter["options"] = tags;
        filter["dragTags"] = () => <DragNDropTree tags={allTags} />;
        filter["dragSubTags"] = () => (
          <DragNDropTree tags={tags} filterData={filter} />
        );
      });
      return {
        ...state,
        allIds,
        byId,
        allIdsDB,
        total: action.payload.data.total,
        isLoading: false,
      };
    }
    case CLEAR_FILTERS: {
      return {
        ...state,
        byId: {},
        allIds: [],
        allIdsDB: [],
        isLoading: false,
        total: 0
      };
    }
    case FILTERS_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const isLoadingFilters = (state) => {
  return state.isLoading;
};
