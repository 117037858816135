import { Banner, TextStyle } from "@shopify/polaris";

export default function CustomerStatusBanner({ status }) {
  if (status === "B") {
    return (
      <div className="mb-8">
        <Banner title="Cliente dado de baja" status="critical">
          <TextStyle>El cliente está dado de baja en Microsip.</TextStyle>
        </Banner>
      </div>
    );
  }
  if (status === "C") {
    return (
      <div className="mb-8">
        <Banner title="Cliente suspendido" status="warning">
          <TextStyle>
            El cliente está suspendido de crédito, solo se aceptan ventas de
            contado.
          </TextStyle>
        </Banner>
      </div>
    );
  }
  if (status === "V") {
    return (
      <div className="mb-8">
        <Banner title="Cliente suspendido" status="warning">
          <TextStyle>
            El cliente está suspendido de ventas en Microsip.
          </TextStyle>
        </Banner>
      </div>
    );
  }

  return <></>;
}
