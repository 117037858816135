import jwt from "jsonwebtoken";
import ApiServiceAuth from "../services/ApiServiceAuth";
import { clearComercios } from "./ComerciosActions";
import { clearUsers } from "./UsuariosActions";
import {
  clearUsuarios,
  resetPage as resetPageClientes,
} from "./ClientesActions";
import { clearPedidos, resetPage as resetPagePedidos } from "./PedidosActions";
import {
  clearArticulos,
  resetPage as resetPageArticulos,
} from "./ArticulosActions";
import {
  clearAnuncios,
  resetPage as resetPageAnuncios,
} from "./AnunciosActions";
import { clearShoppingCart } from "./ShoppingCartActions";
import { getAuthData } from "../reducers";
import { clearCotizaciones } from "./CotizacionesActions";

export const AUTH_DATA_BEGIN = "AUTH_DATA_BEGIN";
export const AUTH_DATA_FAILURE = "AUTH_DATA_FAILURE";
export const ADD_AUTH_DATA = "ADD_AUTH_DATA";
export const ADD_USER_DATA = "ADD_USER_DATA";
export const CLEAR_AUTH = "CLEAR_AUTH";
export const ADD_TOKEN = "ADD_TOKEN";
export const LOGIN_COMERCIO = "LOGIN_COMERCIO";
export const SET_TIMEZONE = "SET_TIMEZONE";
export const ADD_NEW_TOKENS = "ADD_NEW_TOKENS";

export function loginEmail(email) {
  return async (dispatch) => {
    return ApiServiceAuth.loginEmail(email)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function login(credentials) {
  return async (dispatch) => {
    const signed = jwt.sign(
      { data: credentials },
      process.env.REACT_APP_SECRET_SEED,
      { expiresIn: "1h" }
    );
    dispatch(authDataBegin());
    dispatch(addToken(signed));
    return ApiServiceAuth.login()
      .then((res) => {
        dispatch(addAuthData(res));
        return res;
      })
      .catch((error) => {
        throw error.response?.data || error.message;
      });
  };
}

// Thunk to subscribe to get a new token and refresh the token
export function suscribeForRefreshAuth() {
  return async (dispatch, getState) => {
    const { refreshToken } = getAuthData(getState());
    return ApiServiceAuth.suscribeForRefreshAuth(refreshToken)
      .then((result) => {
        dispatch(addNewTokens(result));
        return result;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function logout(id) {
  return async (dispatch) => {
    return ApiServiceAuth.logout(id)
      .then((res) => {
        dispatch(clearAll());
        return res;
      })
      .catch((error) => {
        dispatch(clearAll());
        throw error;
      });
  };
}

export function clearAll() {
  return (dispatch) => {
    dispatch(clearAuth());
    dispatch(clearComercios());
    dispatch(clearUsuarios());
    dispatch(resetPageClientes());
    dispatch(clearUsers());
    dispatch(clearArticulos());
    dispatch(resetPageArticulos());
    dispatch(clearAnuncios());
    dispatch(resetPageAnuncios());
    dispatch(clearPedidos());
    dispatch(resetPagePedidos());
    dispatch(clearShoppingCart());
    dispatch(clearCotizaciones());
  };
}

export function recoveryPassword(params) {
  return async (dispatch) => {
    return ApiServiceAuth.recoveryPassword(params)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  };
}

export function resetPassword(params) {
  return async (dispatch) => {
    return ApiServiceAuth.resetPassword(params)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response.data;
      });
  };
}

export const handleLoginComercio = (data) => ({
  type: LOGIN_COMERCIO,
  payload: { data },
});

export const authDataBegin = () => ({
  type: AUTH_DATA_BEGIN,
});

export const addAuthData = (data) => ({
  type: ADD_AUTH_DATA,
  payload: { data },
});

export const clearAuth = () => ({
  type: CLEAR_AUTH,
});

export const addToken = (data) => ({
  type: ADD_TOKEN,
  payload: { data },
});

export const setTimezone = (timezone) => ({
  type: SET_TIMEZONE,
  payload: { timezone },
});

export const addNewTokens = (data) => ({
  type: ADD_NEW_TOKENS,
  payload: { data },
});
