import { useEffect, useState } from "react";
import {
  Card,
  FormLayout,
  TextStyle,
  Select,
  Checkbox,
  TextField,
} from "@shopify/polaris";
import ChooseColor from "../ChooseColor";

export default function ProductInformation({
  item,
  handleMainChange = () => {},
}) {
  const [productInformation, setProductInformation] = useState({
    design: "carousel-thumbnails",
    titleSize: "medium",
    variantsDesign: "drop-down-list",
    showBrand: true,
    showKey: true,
    showDisccount: true,
    showAvailability: true,
    showDelivery: true,
    deliveryDays: "5",
    textColor: "#121212",
    buttonColor: "#000000",
    textButtonColor: "#FFFFFF",
  });

  useEffect(() => {
    const {
      properties: {
        design,
        titleSize,
        variantsDesign,
        showBrand,
        showKey,
        showDisccount,
        showAvailability,
        showDelivery,
        deliveryDays,
        buttonColor,
        textColor,
        textButtonColor,
      },
    } = item;
    setProductInformation((prevState) => ({
      ...prevState,
      design: design || "carousel-thumbnails",
      titleSize: titleSize || "medium",
      variantsDesign: variantsDesign || "drop-down-list",
      showBrand: showBrand || false,
      showKey: showKey || false,
      showDisccount: showDisccount || false,
      showAvailability: showAvailability || false,
      showDelivery: showDelivery || false,
      deliveryDays: deliveryDays || "5",
      textColor: textColor || "#121212",
      buttonColor: buttonColor || "#000000",
      textButtonColor: textButtonColor || "#FFFFFF",
    }));
  }, [item]);

  const handleChange = (field, value) => {
    handleMainChange(
      "properties",
      { ...productInformation, [field]: value },
      item.id
    );
    setProductInformation((prevState) => ({ ...prevState, [field]: value }));
  };

  const {
    design,
    titleSize,
    variantsDesign,
    showBrand,
    showKey,
    showDisccount,
    showAvailability,
    showDelivery,
    deliveryDays,
    textColor,
    buttonColor,
    textButtonColor,
  } = productInformation;

  return (
    <Card>
      <Card.Section>
        <FormLayout>
          <TextStyle variation="strong">Información de producto</TextStyle>
          <Select
            label="Diseño"
            placeholder="Selecciona un diseño"
            options={[
              {
                label: "Apilado",
                value: "stacked",
              },
              {
                label: "Carrusel de miniaturas",
                value: "carousel-thumbnails",
              },
            ]}
            value={design}
            onChange={(val) => handleChange("design", val)}
          />
          <Select
            label="Tamaño del título"
            placeholder="Selecciona un tamaño"
            value={titleSize}
            onChange={(val) => handleChange("titleSize", val)}
            options={[
              {
                label: "Pequeño",
                value: "small",
              },
              {
                label: "Mediano",
                value: "medium",
              },
              {
                label: "Grande",
                value: "large",
              },
            ]}
          />
          <Select
            label="Diseño para variantes"
            placeholder="Selecciona un diseño"
            value={variantsDesign}
            onChange={(val) => handleChange("variantsDesign", val)}
            options={[
              {
                label: "Lista desplegable",
                value: "drop-down-list",
              },
              {
                label: "Miniaturas",
                value: "thumbnails",
              },
            ]}
          />
          <Checkbox
            label="Mostrar marca"
            checked={showBrand}
            onChange={(val) => handleChange("showBrand", val)}
          />
          <Checkbox
            label="Mostrar clave"
            checked={showKey}
            onChange={(val) => handleChange("showKey", val)}
          />
          <Checkbox
            label="Mostrar descuento"
            checked={showDisccount}
            onChange={(val) => handleChange("showDisccount", val)}
          />
          <Checkbox
            label="Mostrar existencias"
            checked={showAvailability}
            onChange={(val) => handleChange("showAvailability", val)}
          />
          <Checkbox
            label="Mostrar entrega"
            checked={showDelivery}
            onChange={(val) => handleChange("showDelivery", val)}
          />
          <TextField
            label="Días de entrega con existencias"
            value={deliveryDays}
            onChange={(val) => handleChange("deliveryDays", val)}
            placeholder="5"
            type="number"
            min={0}
            autoComplete="off"
          />

          <TextStyle>Esquema de colores de la barra de anuncios</TextStyle>
          <ChooseColor
            handleColorPick={(value) => {
              handleChange("textColor", value);
            }}
            handleTextChange={(value) => handleChange("textColor", value)}
            colorHex={textColor}
            label="Color del texto"
          />
          <ChooseColor
            handleColorPick={(value) => {
              handleChange("buttonColor", value);
            }}
            handleTextChange={(value) => handleChange("buttonColor", value)}
            colorHex={buttonColor}
            label="Color del botón"
          />
          <ChooseColor
            handleColorPick={(value) => {
              handleChange("textButtonColor", value);
            }}
            handleTextChange={(value) => handleChange("textButtonColor", value)}
            colorHex={textButtonColor}
            label="Color del texto del botón"
          />
        </FormLayout>
      </Card.Section>
    </Card>
  );
}
