import React from "react";

export default function AnnouncementBarCardStyled({ section }) {
  const styles = {
    container: {
      height: 40,
      backgroundColor: section?.properties?.backgroundColor,
    },
    text: {
      color: section?.properties?.textColor,
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "20px",
    },
  };

  return (
    <div
      style={styles.container}
      className="flex justify-center items-center w-full py-4"
    >
      <p style={styles.text}>{section?.properties?.text}</p>
    </div>
  );
}
