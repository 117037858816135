import { Col, Row } from "antd";
import {
  ActionList,
  Button,
  Popover,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { MobileVerticalDotsMajor } from "@shopify/polaris-icons";
import moment from "moment";
import useToggle from "../../hooks/useToggle";

export default function ScheduleItem({
  schedule,
  handleChangeDate = () => {},
  onAdd = () => {},
  onRemove = () => {},
  onApply = () => {},
}) {
  const [active, toggleActive] = useToggle();

  const options = [
    {
      id: "add",
      content: "Agregar horarios para este día",
      onAction: () => onAdd(schedule.dayOfWeek),
    },
    {
      id: "remove",
      content: "Quitar horarios para este día",
      onAction: () => onRemove(schedule.dayOfWeek),
    },
    {
      id: "apply",
      content: "Aplicar esta hora a todos los días",
      onAction: () => onApply(schedule.dayOfWeek),
    },
  ];

  const optionsValue = schedule.start
    ? options.filter(({ id }) => ["remove", "apply"].includes(id))
    : options.filter(({ id }) => id === "add");

  const startInput = (
    <TextField
      id="start"
      type="time"
      value={schedule.start}
      onChange={(value, index) => handleChangeDate(value, schedule, index)}
    />
  );
  const endInput = (
    <TextField
      id="end"
      type="time"
      value={schedule.end}
      onChange={(value, index) => handleChangeDate(value, schedule, index)}
    />
  );

  const popover = (
    <>
      <Popover
        active={active}
        onClose={toggleActive}
        activator={
          <Button icon={MobileVerticalDotsMajor} onClick={toggleActive} />
        }
      >
        <ActionList items={optionsValue} />
      </Popover>
    </>
  );
  return (
    <Row gutter={16} align="middle" className="mb-6">
      <Col span={6}>{moment().day(schedule.dayOfWeek).format("dddd")}</Col>
      {!schedule.start ? (
        <Col span={14} className="justify-end" style={{ display: "flex" }}>
          <TextStyle>No hay ninguna hora seleccionada</TextStyle>
        </Col>
      ) : (
        <>
          <Col span={6}>{startInput}</Col>
          <Col span={2} style={{ display: "flex" }} className="justify-center">
            a
          </Col>
          <Col span={6}>{endInput}</Col>
        </>
      )}
      <Col span={3} style={{ display: "flex" }} className="justify-end">
        {popover}
      </Col>
    </Row>
  );
}
