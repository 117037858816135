import {
  Button,
  Page,
  SkeletonBodyText,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { ArrowDownMinor, FilterMajor } from "@shopify/polaris-icons";
import { Col, Row } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  changePage,
  getItemsByFilters,
  resetPage,
} from "../../actions/ArticulosActions";
import { fecthFilters } from "../../actions/FiltersActions";
import { addClientInfo } from "../../actions/ShoppingCartActions";
import {
  AddVariantItemModal,
  AntPagination,
  ArticuloFilterModal,
  CardItemSkeleton,
  ClienteArticulosFiltros,
  Layer,
  ShoppingCardButton,
} from "../../components";
import ArticulosFiltradosContainer from "../../containers/ArticulosFiltradosContainer/ArticulosFiltradosContainer";

import ApiServiceComercios from "../../services/ApiServiceComercios";
import useDebounce from "../../services/useDebounce";

import CardItem from "../../components/common/CardItem";
import ShoppingCart from "../../components/common/ShoppingCart";

import { getPaginationArticulos } from "../../reducers";

import useToggle from "../../hooks/useToggle";
import useUrlSearch from "../../hooks/useUrlSearch";
import useWindowSize from "../../hooks/useWindowSize";
import { getDiscounts, getShippingFees } from "../../utils/orderValidations";
import { scrollToTop } from "../../utils/windowActions";
import "./ClienteArticulosScreen.css";

const objSearch = [
  "query",
  "sort",
  "order",
  "product_type",
  "filters",
  "brand",
  "options",
  "prices",
];
export default function ClienteArticulosScreen({ forbidden }) {
  const { pathname } = useLocation();
  const { updateHistory, getSearch } = useUrlSearch({ objSearch, pathname });
  const router = useHistory();
  const dispatch = useDispatch();
  const size = useWindowSize();

  const [maxPrice, setMaxPrice] = useState(0);
  const [sheetActive, toggleSheetActive] = useToggle();
  const [cartActive, toggleCartActive] = useToggle();
  const [isVariantModalOpen, toggleVariantModal] = useToggle();
  const [isFiltersOpen, toggleFiltersOpen] = useToggle();
  const rangeRef = useRef(getSearch("prices", [0, maxPrice]));
  const queryValueRef = useRef(
    decodeURIComponent(getSearch("query", "", true))
  );

  const [filters, setFilters] = useState([]);
  const [clienteDB, setCliente] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [impuestoDB, setImpuestoDB] = useState(null);
  const [queryValue, setQueryValue] = useState(
    decodeURIComponent(getSearch("query", "", true))
  );
  const [orden, setOrder] = useState(getSearch("order", ["-1"]));
  const [sort, setSort] = useState(getSearch("sort", ["nombre"]));
  const [descuentoMaximo, setPorMaximo] = useState();
  const [descuentoVolumen, setPorVolumen] = useState();
  const [lineaArticulo, setLineaArticulo] = useState(
    getSearch("product_type", [])
  );
  const [descuentoPromocion, setPorPromosGen] = useState();
  const [filtersSelected, setFiltersSelected] = useState(
    getSearch("filters", [])
  );
  const [filterOptionsSelected, setFilterOptionsSelected] = useState(
    getSearch("options", [])
  );
  const [variantSelected, setVariantSelected] = useState(null);
  const [collapseOnEnter, setCollapseOnEnter] = useState(false);
  const [sortByQuantity, setSortByQuantity] = useState(false);
  const [marcasFilter, setMarcasFilter] = useState(getSearch("brand", []));
  const [articleOptions, setArticleOptions] = useState([]);
  const [rangeValue, setRangeValue] = useState(
    getSearch("prices", [0, maxPrice])
  );
  const [showStock, setShowStock] = useState(false);
  const [optionsFilters, setOptionsFilters] = useState({
    useBrand: false,
    usePrice: false,
    useProductType: false,
  });

  const pagination = useSelector(getPaginationArticulos);
  const [currencies, setCurrencies] = useState([]);
  const [isLoadingCurrencies, setIsLoadingCurrencies] = useState(true);

  const debouncedSearchTerm = useDebounce(queryValue, 1500);
  const debounceRangeValue = useDebounce(rangeValue, 900);

  const [productsCount, setProductsCount] = useState(pagination.sizePerPage);

  useEffect(() => {
    function fetchPedidoDatos() {
      ApiServiceComercios.obtenerPedidoDatos().then(
        ({
          ok,
          cliente,
          descuentos,
          impuesto,
          isActivePayment,
          usePaymentsInOrders,
        }) => {
          if (ok) {
            let currency = cliente.moneda;

            let shippingFees = getShippingFees(
              cliente?.envio?.shippingFees,
              currency
            );

            // Valores por default
            setCliente(cliente);
            setImpuestoDB(impuesto);

            const { promos, maximos, volumenes, productsDiscount, priceToUse } =
              getDiscounts(descuentos, cliente);
            setPorPromosGen(promos);
            setPorVolumen(volumenes);
            setPorMaximo(maximos);

            dispatch(
              addClientInfo({
                limiteCredito: cliente?.limite_credito,
                clienteEnvio: cliente?.envio,
                impuesto,
                moneda: currency,
                customerData: cliente,
                shippingFees,
                discounts: productsDiscount,
                priceToUse,
                isActivePayment,
                vendedor: cliente?.vendedor,
                status_microsip: cliente?.status_microsip,
                usePaymentsInOrders,
              })
            );
          }
        }
      );

      ApiServiceComercios.getCurrencies()
        .then(({ monedas }) => {
          const currenciesItems = monedas.map(
            ({ claveFiscal, _id, ...props }) => ({
              label: claveFiscal,
              value: _id,
              ...props,
            })
          );

          setCurrencies(currenciesItems);
        })
        .finally(() => setIsLoadingCurrencies(false));
    }
    fetchPedidoDatos();
  }, [dispatch]);

  const fetchArticulos = useCallback(() => {
    dispatch(
      getItemsByFilters({
        sort: sort[0],
        query: decodeURIComponent(queryValueRef.current),
        existencia: 0,
        order: orden[0],
        linea: JSON.stringify(lineaArticulo),
        filters: JSON.stringify(filtersSelected),
        marcas: JSON.stringify(
          marcasFilter.map((brand) => decodeURIComponent(brand))
        ),
        variants: JSON.stringify(
          filterOptionsSelected.map((i) => decodeURIComponent(i))
        ),
        prices: JSON.stringify(rangeRef.current),
      })
    );
  }, [
    dispatch,
    sort,
    orden,
    lineaArticulo,
    filtersSelected,
    marcasFilter,
    filterOptionsSelected,
  ]);

  useEffect(() => {
    queryValueRef.current = queryValue;
    rangeRef.current = rangeValue;
  });

  useEffect(() => {
    dispatch(fecthFilters())
      .then((response = {}) => {
        const {
          filters = [],
          collapseOnEnter,
          sortByQuantity,
          optionsFilters = {},
          articleOptions = [],
          maxPrice = 0,
        } = response;

        setFilters(filters);
        setOptionsFilters((optFilt) => ({ ...optFilt, ...optionsFilters }));
        setArticleOptions(articleOptions);
        setCollapseOnEnter(collapseOnEnter);
        setSortByQuantity(sortByQuantity);
        setMaxPrice(maxPrice);
        setRangeValue([0, maxPrice]);
      })
      .finally(() => setIsLoading(false));
  }, [dispatch]);

  useEffect(() => {
    fetchArticulos();
  }, [
    dispatch,
    sort,
    orden,
    lineaArticulo,
    filtersSelected,
    pagination.currentPage,
    debouncedSearchTerm,
    marcasFilter,
    filterOptionsSelected,
    fetchArticulos,
    debounceRangeValue,
  ]);

  useEffect(() => {
    ApiServiceComercios.obtenerPreferenciasComercio().then(
      ({ preferencia }) => {
        setShowStock(preferencia?.mostrarExistenciaProducto);
      }
    );
  }, []);

  function resetPagination() {
    dispatch(resetPage());
  }

  function onToggleVariantModal(_id = "") {
    setVariantSelected(_id);
    toggleVariantModal();
  }

  function handleChangeStates(value, setState, key, useStringify = true) {
    updateHistory(
      {
        [key]: value,
      },
      useStringify
    );
    resetPagination();
    setState(value);
  }

  function handleChangePage(products) {
    if (products === pagination.sizePerPage) {
      let page = pagination.currentPage + 1;
      setProductsCount(page * pagination.sizePerPage);
    } else {
      let current = pagination.currentPage * pagination.sizePerPage;
      setProductsCount(current + products);
    }
  }

  return (
    <Layer title="Artículos" forbidden={forbidden} scrollToTop>
      <div className="flex pt-4">
        {isVariantModalOpen ? (
          <AddVariantItemModal
            canShowStock={showStock}
            isOpen={isVariantModalOpen}
            onClose={toggleVariantModal}
            id={variantSelected}
            almacenId={clienteDB?.almacen?.id}
            discounts={{
              descuentoPromocion,
              descuentoVolumen,
              descuentoMaximo,
            }}
            showTax={impuestoDB?.mostrarPrecioConImpuesto}
          />
        ) : null}
        {sheetActive && (
          <ArticuloFilterModal
            handleOrdenChange={(value) =>
              handleChangeStates(value, setOrder, "order")
            }
            handleOrderSelectedChange={(value) =>
              handleChangeStates(value, setSort, "sort")
            }
            isOpen={sheetActive}
            onAction={toggleSheetActive}
            toggleSheetActive={toggleSheetActive}
            orden={orden}
            orderSelected={sort}
          />
        )}

        {cartActive && (
          <ShoppingCart
            isCartVisible={cartActive}
            onCloseCart={toggleCartActive}
            currency={clienteDB?.moneda}
            currencies={currencies}
            modeView="dashboard"
          />
        )}

        <ArticulosFiltradosContainer>
          {({
            articulos,
            isLoading: isLoadingArticulos,
            totalArticles,
            pagination,
            productTypes,
            brands,
            tags,
            options,
            totalShoppingCart,
          }) => {
            return (
              <>
                <div className="class-items">
                  {isLoading ? (
                    <div className="m-4" style={{ minWidth: 200 }}>
                      <SkeletonBodyText lines={5} />
                    </div>
                  ) : (
                    <ClienteArticulosFiltros
                      sortByQuantity={sortByQuantity}
                      tags={tags}
                      options={options}
                      brands={brands}
                      productTypes={productTypes}
                      filters={filters}
                      articleOptions={articleOptions}
                      optionsFilters={optionsFilters}
                      handleFilterChange={(value) =>
                        handleChangeStates(value, setFiltersSelected, "filters")
                      }
                      selected={filtersSelected}
                      handleLineaArticuloChange={(value) =>
                        handleChangeStates(
                          value,
                          setLineaArticulo,
                          "product_type"
                        )
                      }
                      lineaArticulo={lineaArticulo}
                      collapseOnEnter={!collapseOnEnter}
                      marcasSelected={marcasFilter}
                      isOpen={isFiltersOpen}
                      toggleActive={toggleFiltersOpen}
                      filterOptionsSelected={filterOptionsSelected}
                      handleFilterOptionsChange={(value) =>
                        handleChangeStates(
                          value,
                          setFilterOptionsSelected,
                          "options"
                        )
                      }
                      maxPrice={maxPrice}
                      rangeValue={rangeValue}
                      setRangeValue={(value) =>
                        handleChangeStates(value, setRangeValue, "prices")
                      }
                      handleMarcasFilterChange={(value) =>
                        handleChangeStates(value, setMarcasFilter, "brand")
                      }
                    />
                  )}
                </div>
                <div className="w-full">
                  <Page
                    title="Artículos"
                    fullWidth
                    titleMetadata={
                      size.width < 768 ? (
                        <Button
                          plain
                          onClick={toggleFiltersOpen}
                          icon={FilterMajor}
                        />
                      ) : undefined
                    }
                  >
                    <Row align="middle" className="mb-8">
                      <Col
                        span={8}
                        xs={{ span: 16, order: 1 }}
                        lg={{ span: 8, order: 1 }}
                      >
                        <div className="flex align-center">
                          <TextStyle variation="subdued">
                            <Button
                              icon={ArrowDownMinor}
                              monochrome
                              plain
                              onClick={toggleSheetActive}
                            >
                              Ordenar
                            </Button>
                          </TextStyle>
                          {handleChangePage(articulos.length)}
                          <div className="mx-4" />
                          {articulos.length === 0 ? (
                            <TextStyle variation="subdued">
                              No hay productos
                            </TextStyle>
                          ) : (
                            <TextStyle variation="subdued">
                              {productsCount - articulos.length + 1} {" a "}
                              {productsCount} artículos de {totalArticles}
                            </TextStyle>
                          )}
                        </div>
                      </Col>
                      <Col
                        span={10}
                        xs={{ span: 8, order: 3 }}
                        lg={{ span: 14, order: 2 }}
                      >
                        <div className="search-data-container">
                          <TextField
                            value={decodeURIComponent(queryValue)}
                            onChange={(value) =>
                              handleChangeStates(
                                value ? encodeURIComponent(value) : value,
                                setQueryValue,
                                "query",
                                false
                              )
                            }
                            clearButton
                            onClearButtonClick={() =>
                              handleChangeStates(
                                "",
                                setQueryValue,
                                "query",
                                false
                              )
                            }
                            placeholder="Buscar artículos"
                          />
                        </div>
                      </Col>
                      <Col
                        span={4}
                        xs={{ span: 12, order: 2 }}
                        lg={{ span: 2, order: 3 }}
                      >
                        <div className="flex items-center justify-end ml-4">
                          <div className="mx-4" />
                          <ShoppingCardButton
                            badge={totalShoppingCart}
                            onClick={toggleCartActive}
                          />
                        </div>
                      </Col>
                    </Row>
                    {isLoadingArticulos || isLoadingCurrencies ? (
                      <>
                        <CardItemSkeleton />
                      </>
                    ) : (
                      <Row gutter={[16, 16]} align="stretch" justify="start">
                        {articulos.map((item, index) => (
                          <Col
                            span={8}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={8}
                            xl={6}
                            xxl={4}
                            key={index}
                          >
                            <div className="card-item m-auto">
                              <CardItem
                                discounts={{
                                  descuentoPromocion,
                                  descuentoVolumen,
                                  descuentoMaximo,
                                }}
                                itemData={item}
                                almacenId={clienteDB?.almacen?.id}
                                clientCurrency={clienteDB?.moneda}
                                currencies={currencies}
                                showTax={impuestoDB?.mostrarPrecioConImpuesto}
                                onSelectVariant={onToggleVariantModal}
                                canShowStock={showStock}
                                onClickImage={() =>
                                  router.push({
                                    pathname: `/cliente/articulos/${item._id}`,
                                    state: {
                                      almacenId: clienteDB?.almacen?.id,
                                      discounts: {
                                        descuentoPromocion,
                                        descuentoVolumen,
                                        descuentoMaximo,
                                      },
                                      showTax:
                                        impuestoDB?.mostrarPrecioConImpuesto,
                                    },
                                  })
                                }
                              />
                            </div>
                          </Col>
                        ))}
                      </Row>
                    )}

                    {!isLoadingArticulos && articulos.length === 0 && (
                      <div className="w-full flex flex-col my-10 justify-center flex-wrap mt-30 items-center">
                        <img
                          style={{ width: 170 }}
                          src="/empty-results.png"
                          alt="empty-results"
                        />
                        <TextStyle>No se encontraron los Artículos</TextStyle>
                        <TextStyle variation="subdued">
                          Intenta cambiar los filtros o el término de búsqueda
                        </TextStyle>
                      </div>
                    )}
                    <div className="flex flex-col items-center justify-center py-6 pagination px-10">
                      <AntPagination
                        total={pagination.total}
                        current={pagination.currentPage + 1}
                        pageSize={pagination.sizePerPage}
                        onChange={(page) => {
                          dispatch(changePage(page - 1));
                          scrollToTop();
                        }}
                      />
                    </div>
                  </Page>
                </div>
              </>
            );
          }}
        </ArticulosFiltradosContainer>
      </div>
    </Layer>
  );
}
