import { Card, Link, TextStyle } from "@shopify/polaris";

export const ClientInfoCard = ({ clientData }) => {
  return (
    <Card fullWidth>
      <Card.Section>
        <div className="flex justify-between items-center">
          <h3 className="Polaris-Subheading">Cliente</h3>
          <div className="relative" style={{ width: 40, top: -10 }}>
            <img
              className="rounded-full"
              src="/clienteAvatar.png"
              alt="Cliente"
            />
          </div>
        </div>
        <div className="flex flex-col">
          <Link external url={`/admin/clientes/${clientData?._id}`}>
            {clientData?.nombre}
          </Link>
        </div>
      </Card.Section>
      <Card.Section title="Contacto">
        <div className="flex flex-col">
          <TextStyle variation="subdued">{clientData?.nombre || ""}</TextStyle>
          <Link>{clientData?.email}</Link>
          <Link>{clientData?.telefono || ""}</Link>
        </div>
      </Card.Section>
      {clientData?.moneda ? (
        <Card.Section title="Moneda">
          <TextStyle>{clientData?.moneda?.moneda}</TextStyle>
        </Card.Section>
      ) : null}
    </Card>
  );
};
