import React, { useCallback } from "react";
import _ from "lodash";
import moment from "moment";
import { Table } from "antd";
import { useDispatch } from "react-redux";
import {
  Filters,
  ChoiceList,
  EmptyState,
  TextStyle,
  Card,
  ResourceList,
  Checkbox,
  Button,
  // Button,
} from "@shopify/polaris";
import CustomBadge from "../CustomBadge";
import {
  AntPagination,
  ChoiceDate,
  CustomPopover,
  DownloadPDFXML,
  SkeletonTable,
} from "..";
import { formatTo2Digits } from "../../utils/Formats";
import { changePage } from "../../actions/FacturasActions";
import "./FacturasList.css";

export default function FacturasList({
  orderSelected,
  handleOrderSelectedChange,
  handleOrderSelectedRemove,
  pagination,
  data,
  isLoading,
  queryValue,
  orden,
  handleFiltersQueryChange,
  handleQueryValueRemove,
  handleOrdenChange,
  createdAt,
  handleDateRemove,
  handleCreatedAtChange,
  selectedCreatedAt,
  setSelectedCreatedAt,
  handlePaymentStatusChange,
  handlePaymentStatusRemove,
  paymentStatus,
  hasUnpaidBills = false,
  usePaymentsInBills = false,
  onPay = () => {},
  selectedItems = [],
  setSelectedItems,
}) {
  const dispatch = useDispatch();

  const handleFiltersClearAll = useCallback(() => {
    handlePaymentStatusRemove();
    handleQueryValueRemove();
    handleOrderSelectedRemove();
  }, [
    handlePaymentStatusRemove,
    handleQueryValueRemove,
    handleOrderSelectedRemove,
  ]);

  function handleSelectAll(values = []) {
    setSelectedItems(values);
  }

  function handleSelect(selected, val) {
    if (selected) {
      setSelectedItems((state) => [...new Set(state.concat(val))]);
    } else {
      setSelectedItems(selectedItems.filter((sel) => val !== sel));
    }
  }

  function getTotal() {
    const amounts = data
      .filter(
        ({ _id, saldo, isPaid }) =>
          selectedItems.includes(_id) && saldo && !isPaid
      )
      .map(({ saldo }) => saldo);
    const total = amounts.reduce((acc, curr) => acc + curr, 0);
    return total;
  }

  function getTotalAmount() {
    const total = getTotal();
    if (!usePaymentsInBills) {
      return undefined;
    }
    return [
      {
        title: "Pagar: " + formatTo2Digits(total),
        actions: [
          {
            content: "Pago con tarjeta",
            onAction: () => onPay(Number(total), [], false),
            disabled: total === 0,
          },
          {
            content: "Pago manual",
            onAction: () => onPay(Number(total), [], true),
          },
        ],
      },
    ];
  }

  function setStatus(envio) {
    switch (envio) {
      case "Pagado":
        return "complete";
      case "Atrasado":
        return "incomplete";
      case "Pendiente":
        return "partiallyComplete";
      default:
        return null;
    }
  }

  let appliedFilters = [];
  if (!isEmpty(paymentStatus)) {
    const key = "paymentStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, paymentStatus),
      onRemove: handlePaymentStatusRemove,
    });
  }

  if (!isEmpty(createdAt)) {
    const key = "dateStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, createdAt),
      onRemove: handleDateRemove,
    });
  }

  const emptyStateMarkup = (
    <EmptyState
      heading="Tus facturas aparecerán aquí"
      image="/emptystate-files-n.png"
    >
      <p>Aquí podrás ver tus facturas.</p>
    </EmptyState>
  );

  const filters = [
    {
      key: "paymentStatus",
      label: "Estatus de pago",
      filter: (
        <ChoiceList
          title="Estatus de pago"
          titleHidden
          choices={[
            { label: "Pendiente", value: "Pendiente" },
            { label: "Pagado", value: "Pagado" },
            { label: "Atrasado", value: "Atrasado" },
            { label: "Cancelado", value: "Cancelado" },
            { label: "Devuelto", value: "Devuelto" },
          ]}
          selected={paymentStatus || []}
          onChange={handlePaymentStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "orderSelected",
      label: "Ordenar",
      shortcut: false,
      filter: (
        <div className="flex flex-col">
          <ChoiceList
            title="Ordenar"
            titleHidden
            choices={[
              { label: "Folio", value: "folioMicrosip" },
              { label: "Fecha", value: "fecha" },
              { label: "Total", value: "importe" },
              { label: "Estatus", value: "estatus" },
            ]}
            selected={orderSelected || []}
            onChange={handleOrderSelectedChange}
          />
          <div className="flex w-full border border-t-1 border-b-0 border-r-0 border-l-0 border-gray-400">
            <ChoiceList
              title="Ordenar"
              titleHidden
              choices={[
                { label: "Ascendente", value: "1" },
                { label: "Descendente", value: "-1" },
              ]}
              selected={orden}
              onChange={handleOrdenChange}
            />
          </div>
        </div>
      ),
    },
    {
      shortcut: false,
      key: "dateStatus",
      label: "Fecha",
      filter: (
        <ChoiceDate
          value={createdAt}
          handleChange={handleCreatedAtChange}
          selectedDates={selectedCreatedAt}
          setSelectedDates={setSelectedCreatedAt}
          past
        />
      ),
    },
  ];

  let columns = [
    {
      title: "Folio",
      dataIndex: "folioMicrosip",
      render: (folioMicrosip, { _id, saldo, shouldPay, isPaid }) => {
        const isValidNumber =
          saldo && _.isNumber(saldo) && !_.isNaN(saldo) && saldo > 0;
        return (
          <div className="flex flex-row items-center whitespace-nowrap">
            <div className="pl-6">
              <Checkbox
                value={_id}
                checked={selectedItems.indexOf(_id) >= 0}
                onChange={(val) => handleSelect(val, _id)}
                disabled={
                  !isValidNumber || !shouldPay || isPaid || !usePaymentsInBills
                }
              />
            </div>
            <div className="py-6 pr-6 w-full h-full">
              <TextStyle variation="strong">{folioMicrosip}</TextStyle>
            </div>
          </div>
        );
      },
    },
    {
      title: "Fecha",
      dataIndex: "fecha",
      render: (fecha) => (
        <div className="whitespace-nowrap pl-6">
          <TextStyle>{moment(fecha).format("D MMMM [a las] HH:mm")}</TextStyle>
        </div>
      ),
    },
    {
      title: "Fecha de vencimiento",
      dataIndex: "fechaVencimiento",
      render: (fechaVencimiento) => (
        <div className="whitespace-nowrap pl-6">
          <TextStyle>
            {fechaVencimiento
              ? moment(fechaVencimiento).format("D MMMM")
              : "--"}
          </TextStyle>
        </div>
      ),
    },
    {
      title: "Total",
      dataIndex: "importe",
      render: (importe) => (
        <div className="pl-6">
          <TextStyle>{formatTo2Digits(Number(importe))}</TextStyle>
        </div>
      ),
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      render: (descripcion) => (
        <div className="whitespace-nowrap pl-6">
          <TextStyle>{descripcion ? descripcion : "--"}</TextStyle>
        </div>
      ),
    },
    {
      title: "Estatus",
      dataIndex: "estatus",
      render: (estatus) => (
        <div className="whitespace-nowrap pl-6">
          <CustomBadge
            status={estatus.nombre}
            progress={setStatus(estatus.nombre)}
          />
        </div>
      ),
    },
    {
      title: "Saldo",
      dataIndex: "saldo",
      render: (saldo) => (
        <div className="whitespace-nowrap pl-6">
          <div className="pl-6">
            <TextStyle>
              {saldo ? formatTo2Digits(Number(saldo)) : "--"}
            </TextStyle>
          </div>
        </div>
      ),
    },
    {
      title: "Pago",
      dataIndex: "saldo",
      render: (saldo, row) => {
        const isValidNumber =
          saldo && _.isNumber(saldo) && !_.isNaN(saldo) && saldo > 0;

        const items = [
          {
            content: "Pago con tarjeta",
            onAction: () => onPay(Number(saldo), [], false),
          },
          {
            content: "Pago manual",
            onAction: () => onPay(Number(saldo), [], true),
          },
        ];

        const Activator = ({ onClick }) => {
          return (
            <Button
              disabled={!isValidNumber || row.isPaid || saldo === 0}
              onClick={onClick}
              size="slim"
              disclosure="down"
            >
              Pagar
            </Button>
          );
        };

        return (
          <div className="whitespace-nowrap pl-6">
            {row.shouldPay && (
              <>
                {row.isPaid ? (
                  <Button
                    disabled
                    onClick={() => {}}
                    size="slim"
                    disclosure="down"
                  >
                    Pagado
                  </Button>
                ) : (
                  <>
                    <CustomPopover items={items} activator={Activator} />
                  </>
                )}
              </>
            )}
          </div>
        );
      },
    },
    {
      title: "Descargar",
      dataIndex: "pdf",
      render: (pdf, { xml, folioMicrosip, estatus }) => {
        return (
          <DownloadPDFXML
            status={estatus.nombre}
            xml={xml}
            folioMicrosip={folioMicrosip}
            pdf={pdf}
          />
        );
      },
    },
  ];

  if (!hasUnpaidBills || !usePaymentsInBills) {
    columns = columns.filter(({ title }) => title !== "Pago");
  }

  return (
    <div>
      <Card>
        <Card.Section>
          <Filters
            queryPlaceholder="Buscar facturas"
            queryValue={queryValue}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
            filters={filters}
            appliedFilters={appliedFilters}
          />
        </Card.Section>
        <div>
          <div className="selectable-items">
            <ResourceList
              totalItemsCount={pagination.total}
              resourceName={{ singular: "factura", plural: "facturas" }}
              items={data}
              selectable={!_.isEmpty(getTotalAmount())}
              selectedItems={selectedItems}
              onSelectionChange={handleSelectAll}
              renderItem={(_) => null}
              promotedBulkActions={getTotalAmount()}
            />
          </div>
          {isLoading ? (
            <SkeletonTable hasCheckbox headingsCount={6} rowsCount={5} />
          ) : (
            <div className="ant-table-styles-order">
              <Table
                rowKey="_id"
                dataSource={data}
                scroll={{ scrollToFirstRowOnChange: true, x: "100%" }}
                pagination={false}
                locale={{ emptyText: emptyStateMarkup }}
                columns={columns}
              />
            </div>
          )}
        </div>
        <div className="flex justify-center py-6 pagination px-10">
          <AntPagination
            total={pagination.total}
            current={pagination.currentPage + 1}
            pageSize={pagination.sizePerPage}
            onChange={(page) => {
              dispatch(changePage(page - 1));
            }}
          />
        </div>
      </Card>
    </div>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "paymentStatus":
        return value.map((val) => `Estado ${val}`).join(", ");
      case "dateStatus":
        return `${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null || value === "todos";
    }
  }
}
