import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Page,
  Layout,
  Card,
  FormLayout,
  TextField,
  TextStyle,
  Stack,
  DropZone,
  Caption,
  Thumbnail,
  Button,
  ButtonGroup,
  Tag,
} from "@shopify/polaris";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { Layer, PreventTransitionPrompt } from "../../components";
import ApiServiceConfig from "../../services/ApiServiceConfig";
import { toggleToast } from "../../actions/InteractiveActions";

export default function HelpCenterDetailScreen({ forbidden }) {
  const router = useHistory();
  const dispatch = useDispatch();
  const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
  const [isBlocking, setIsBlocking] = useState(false);
  const [incidentsData, setIncidentsData] = useState({
    description: "",
    reference: "",
  });

  const [files, setFiles] = useState([]);
  const [isEditable] = useState(true);
  const [isCreating, setIsCreating] = useState(false);

  //Dropzone

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) => {
      setFiles((files) => [...files, ...acceptedFiles]);
      setIsBlocking(true);
    },
    []
  );

  const handleBack = () => {
    router.push("/admin/centro-de-ayuda");
  };

  const handleSave = () => {
    setIsCreating(true);
    setIsBlocking(false);
    const formData = new FormData();
    let IncidentData = {
      reference: incidentsData.reference,
      description: incidentsData.description,
      module: incidentsData.module,
      process: incidentsData.process,
      status: incidentsData.staust,
      priority: incidentsData.priority,
    };
    if (files.length > 0) {
      files.forEach((file) => formData.append("files", file));
      formData.append("caso", JSON.stringify(IncidentData));
    }

    ApiServiceConfig.createIncident(
      { ...IncidentData },
      files.length > 0 ? formData : null
    )
      .then(({ ok }) => {
        if (ok) {
          dispatch(toggleToast({ message: "Correo envíado correctamente" }));
          setTimeout(() => {
            handleBack();
          }, 100);
        }
      })
      .catch((err) => {
        setIsCreating(false);
      });
  };
  const fileUpload = (
    <DropZone.FileUpload actionHint="Acepta .jpeg, .png y .jpg" />
  );

  const uploadedFiles = files.length > 0 && (
    <Stack vertical>
      {files.map((file, index) => (
        <Stack alignment="center" key={index}>
          <Tag
            onRemove={() =>
              setFiles((prevState) =>
                prevState.filter((i) => i.name !== file.name)
              )
            }
          >
            <div className="flex">
              <Thumbnail
                size="large"
                alt={file.name}
                source={
                  validImageTypes.indexOf(file.type) >= 0
                    ? window.URL.createObjectURL(file)
                    : "/newPost.png"
                }
              />
              <div
                className="ml-2
              "
              >
                {file.name} <Caption>{file.size} bytes</Caption>
              </div>
            </div>
          </Tag>
        </Stack>
      ))}
    </Stack>
  );

  return (
    <Layer title="Correo electrónico" forbidden={forbidden} scrollToTop>
      <Page
        title="Correo electrónico"
        subtitle="Envíanos un correo electrónico explicando tu situación."
      >
        <PreventTransitionPrompt
          when={isBlocking}
          setIsBlocking={setIsBlocking}
          router={router}
          title="Tienes cambios sin guardar en esta página"
          message="Si abandonas esta página, perderás todos los cambios no guardados. ¿Estás seguro de que quieres salir de esta página?"
        />
        <Layout>
          <Layout.Section oneThird>
            <Card sectioned>
              <FormLayout>
                <TextField
                  requiredIndicator
                  label="Referencia"
                  type="text"
                  autoComplete="off"
                  placeholder="Sincronización con Microsip"
                  value={incidentsData.reference}
                  onChange={(text) => {
                    setIsBlocking(true);
                    return setIncidentsData((prevState) => ({
                      ...prevState,
                      reference: text,
                    }));
                  }}
                />
                <div className="flex flex-col">
                  <TextStyle>
                    Descripción <TextStyle variation="negative">*</TextStyle>
                  </TextStyle>
                  <SunEditor
                    lang="es"
                    placeholder="Escribe una descripción para este caso"
                    height="300"
                    onBlur={() => null}
                    autoFocus={false}
                    onFocus={() => null}
                    onClick={() => null}
                    onChange={(content) => {
                      setIsBlocking(true);
                      return setIncidentsData((prevState) => ({
                        ...prevState,
                        description: content,
                      }));
                    }}
                  />
                </div>
                <DropZone
                  disabled={!isEditable}
                  type="image"
                  accept={validImageTypes.join(",")}
                  onDrop={handleDropZoneDrop}
                  allowMultiple={true}
                >
                  {fileUpload}
                </DropZone>
                {uploadedFiles}
              </FormLayout>
            </Card>
          </Layout.Section>
        </Layout>

        <div className="flex justify-end mt-8 mb-8">
          <ButtonGroup>
            <Button onClick={handleBack}>Cancelar</Button>
            <Button
              primary
              onClick={handleSave}
              disabled={!incidentsData.reference || !incidentsData.description}
              loading={isCreating}
            >
              Enviar
            </Button>
          </ButtonGroup>
        </div>
      </Page>
    </Layer>
  );
}
