import {
  Card,
  Icon,
  ResourceItem,
  ResourceList,
  TextStyle,
} from "@shopify/polaris";
import { AttachmentMajor } from "@shopify/polaris-icons";
import { useEffect, useState } from "react";

export default function FilesPaymentsDetailCard({ files }) {
  const [filesData, setFilesData] = useState([]);

  useEffect(() => {
    if (files) {
      setFilesData(files);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const formatFileName = (fileName, type = "name") => {
    const fileNameArray = fileName.split("/");
    const fileNameSplit = fileNameArray[fileNameArray.length - 1];
    const fileFormat = fileNameSplit.split(".");

    if (type === "name") {
      if (fileNameSplit.length > 60) {
        const fileNameReduce =
          fileNameSplit.substring(0, 16) +
          "..." +
          fileNameSplit.substring(fileNameSplit.length - 9);
        return fileNameReduce;
      } else {
        return fileNameSplit;
      }
    } else if (type === "format") {
      return fileFormat[1].toUpperCase();
    }
  };

  const downloadFile = (file) => {
    try {
      window.open(file, "_blank");
    } catch (error) {}
  };

  return (
    <Card fullWidth title="Comprobante">
      <Card.Section>
        <ResourceList
          resourceName={{ singular: "comprobante", plural: "comprobantes" }}
          items={filesData}
          renderItem={(item, index) => {
            return (
              <ResourceItem key={index} onClick={() => downloadFile(item)}>
                <div className="flex flex-row gap-6">
                  <div className="content-end mb-2">
                    <Icon source={AttachmentMajor} />
                  </div>
                  <div className="flex flex-col gap-2">
                    <TextStyle variation="strong">
                      {formatFileName(item)}
                    </TextStyle>
                    <div className="flex justify-center rounded-full bg-black text-white content-center items-center w-20">
                      <TextStyle>{formatFileName(item, "format")}</TextStyle>
                    </div>
                  </div>
                </div>
              </ResourceItem>
            );
          }}
        />
      </Card.Section>
    </Card>
  );
}
