import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Filters,
  ChoiceList,
  EmptyState,
  TextStyle,
  Checkbox,
  Card,
  ResourceList,
} from "@shopify/polaris";
import moment from "moment";
import { AntPagination, CustomBadge, SkeletonTable } from "../../components";
import { changePage } from "../../actions/AnunciosActions";
import "./AnunciosList.css";
import { Table } from "antd";
import { useDispatch } from "react-redux";

export default function AnunciosList({
  orderSelected,
  handleOrderSelectedChange,
  handleOrderSelectedRemove,
  pagination,
  anuncios,
  isLoading,
  queryValue,
  orden,
  handleFiltersQueryChange,
  handleQueryValueRemove,
  estatusAnuncio,
  handleOrdenChange,
  handleEstatusAnuncioChange,
  handleEstatusAnuncioRemove,
}) {
  const router = useHistory();
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);

  const handleFiltersClearAll = useCallback(() => {
    handleEstatusAnuncioRemove();
    handleQueryValueRemove();
    handleOrderSelectedRemove();
  }, [
    handleQueryValueRemove,
    handleEstatusAnuncioRemove,
    handleOrderSelectedRemove,
  ]);

  function handleSelect(selected, val) {
    if (selected) {
      setSelectedItems((state) => [...new Set(state.concat(val))]);
    } else {
      setSelectedItems(selectedItems.filter((sel) => val !== sel));
    }
  }

  function handleSelectAll(values = []) {
    setSelectedItems(values);
  }

  let appliedFilters = [];

  if (!isEmpty(estatusAnuncio)) {
    const key = "estatusAnuncio";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, estatusAnuncio),
      onRemove: handleEstatusAnuncioRemove,
    });
  }

  const emptyStateMarkup =
    !isLoading && anuncios.length === 0 ? (
      <EmptyState
        heading="Tus anuncios aparecerán aquí"
        action={{
          content: "Agregar anuncio",
          url: "/admin/anuncios/agregar",
        }}
        image="/empty-advertisements.png"
      >
        <p>
          Agrega anuncios a tu tienda de mayoreo para mostrar información
          importante sobre tus ofertas y promociones.
        </p>
      </EmptyState>
    ) : undefined;

  const filters = [
    {
      key: "estatusAnuncio",
      label: "Estado",
      filter: (
        <ChoiceList
          title="Estado"
          titleHidden
          choices={[
            { label: "Visible", value: "Visible" },
            { label: "No visible", value: "No visible" },
          ]}
          selected={estatusAnuncio || []}
          onChange={handleEstatusAnuncioChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "orderSelected",
      label: "Ordenar",
      filter: (
        <div className="flex flex-col">
          <ChoiceList
            title="Ordenar"
            titleHidden
            choices={[
              { label: "Titulo", value: "titulo" },
              { label: "Fecha inicio", value: "fecha_inicio" },
              { label: "Fecha fin", value: "fecha_fin" },
              { label: "Estatus", value: "estatus" },
              { label: "Clics", value: "clicks" },
            ]}
            selected={orderSelected || []}
            onChange={handleOrderSelectedChange}
          />
          <div className="flex w-full border border-t-1 border-b-0 border-r-0 border-l-0 border-gray-400">
            <ChoiceList
              title="Ordenar"
              titleHidden
              choices={[
                { label: "Ascendente", value: "1" },
                { label: "Descendente", value: "-1" },
              ]}
              selected={orden}
              onChange={handleOrdenChange}
            />
          </div>
        </div>
      ),
      shortcut: true,
    },
  ];

  return (
    <div>
      <Card>
        <Card.Section>
          <Filters
            queryPlaceholder="Buscar anuncio"
            queryValue={queryValue}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
            filters={filters}
            appliedFilters={appliedFilters}
          />
        </Card.Section>
        {isLoading ? (
          <SkeletonTable hasCheckbox headingsCount={5} rowsCount={5} />
        ) : (
          <div>
            <div className="selectable-items">
              <ResourceList
                totalItemsCount={pagination.total}
                resourceName={{ singular: "anuncio", plural: "anuncios" }}
                items={anuncios}
                selectable
                selectedItems={selectedItems}
                onSelectionChange={handleSelectAll}
                renderItem={() => null}
              />
            </div>
            <div className="ant-table-styles">
              <Table
                onRow={(record) => ({
                  onClick: () =>
                    handleSelect(
                      !Boolean(selectedItems.indexOf(record.id) >= 0),
                      record.id
                    ),
                })}
                rowKey="id"
                rowClassName="whitespace-nowrap"
                dataSource={anuncios}
                scroll={{ scrollToFirstRowOnChange: true, x: "100%" }}
                pagination={false}
                locale={{ emptyText: emptyStateMarkup }}
                columns={[
                  {
                    title: "Título",
                    dataIndex: "titulo",
                    render: (titulo, record) => (
                      <div className="flex flex-row items-center">
                        <div className="mr-6">
                          <Checkbox
                            value={record._id}
                            checked={selectedItems.indexOf(record._id) >= 0}
                            onChange={(val) => handleSelect(val, record._id)}
                          />
                        </div>
                        <div
                          onClick={() =>
                            router.push(`/admin/anuncios/${record._id}`)
                          }
                          className="text-link"
                        >
                          <TextStyle variation="strong">{titulo}</TextStyle>
                        </div>
                      </div>
                    ),
                  },
                  {
                    title: "Fecha de inicio",
                    dataIndex: "fecha_inicio",
                    render: (fecha_inicio) =>
                      fecha_inicio ? (
                        <TextStyle>
                          {moment(fecha_inicio).format("DD MMM YYYY")} a las{" "}
                          {moment(fecha_inicio).format("HH:mm")}
                        </TextStyle>
                      ) : (
                        "--"
                      ),
                  },
                  {
                    title: "Fecha de fin",
                    dataIndex: "fecha_fin",
                    render: (fecha_fin) =>
                      fecha_fin ? (
                        <TextStyle>
                          {moment(fecha_fin).format("DD MMM YYYY")} a las{" "}
                          {moment(fecha_fin).format("HH:mm")}
                        </TextStyle>
                      ) : (
                        "--"
                      ),
                  },
                  {
                    title: "Estatus",
                    dataIndex: "estatus",
                    render: (estatus) => (
                      <div className="whitespace-no-wrap">
                        <CustomBadge status={estatus} />
                      </div>
                    ),
                  },
                  {
                    title: "Clics",
                    dataIndex: "clicks",
                    render: (clicks) => (
                      <TextStyle>
                        {Number(clicks).toLocaleString("en", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </TextStyle>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        )}
        <div className="flex justify-center py-6 pagination px-10">
          <AntPagination
            total={pagination.total}
            current={pagination.currentPage + 1}
            pageSize={pagination.sizePerPage}
            onChange={(page) => {
              dispatch(changePage(page - 1));
            }}
          />
        </div>
      </Card>
    </div>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "estatusAnuncio":
        return value.map((val) => `Estado ${val}`).join(", ");
      case "orderSelected":
        return `Ordenar por ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null || value === "titulo";
    }
  }
}
