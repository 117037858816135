import {
  Button,
  Heading,
  Icon,
  Link,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";
import { useEffect, useState } from "react";

import { CreditCardMajor } from "@shopify/polaris-icons";

import { useLocation, useParams } from "react-router-dom";
import { SendEmailModal } from "../../components";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { formatTo2Digits } from "../../utils/Formats";
import logoImg from "./logo.png";

export const GraciasScreen = () => {
  const params = useParams();
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search);

  const [showModal, setShowModal] = useState(false);

  const [orderDetails, setOrderDetails] = useState({
    envio: {
      precio: 0,
      nombre: "",
    },
    cliente: {
      email: "",
      nombre: "",
    },
    notas: "",
    folio: "",
    subtotal: 0,
    impuestoSubtotal: 0,
    total: 0,
  });
  const [productList, setProductList] = useState([]);
  const [payments, setPayments] = useState({});
  const [idManualPayment, setIdManualPayment] = useState(null);
  const [manualPayments, setManualPayments] = useState({});
  const [shippingAddress, setShippingAddress] = useState({});
  const [billingAddress, setBillingAddress] = useState({});

  useEffect(() => {
    // Obtiene los datos del pedido
    ApiServiceComercios.pedidoDetalle(params.id).then(({ pedido }) => {
      let products = pedido?.articulos?.filter(
        ({ articulo }) => articulo.nombre !== "Fletes"
      );

      // Obtiene las query
      let card = searchQuery.get("card");
      let brand = searchQuery.get("brand")?.toUpperCase();
      let amount = searchQuery.get("amount");

      // Obtiene la condición de pago
      let payment = pedido.cliente.condicion_pago.nombre_condicion;
      let conditionPayment = payment.toLowerCase().replace("i", "í");

      let total = pedido?.total;
      let tdc = parseFloat(amount?.replace(",", ""));

      // Datos para la sección de Método de Pago
      setPayments({
        card,
        brand,
        amount,
        conditionPayment,
        conditionDays: total - tdc,
      });

      //Datas de pago manual
      if (pedido?.payment?.manualPayment) {
        setIdManualPayment(pedido.payment.manualPayment._id);
      }

      // Manda el listado de productos del pedido
      setProductList(products);

      // Obtiene los datos a mostrar en pantalla
      setOrderDetails({
        folio: pedido?.folio,
        cliente: {
          nombre: pedido?.cliente?.nombre,
          email: pedido?.cliente?.email,
        },
        envio: {
          nombre: pedido?.envio?.nombre,
          precio: pedido?.envio?.precio,
        },
        notas: pedido?.notas,
        total: pedido?.total,
        impuestoSubtotal: pedido?.impuestoSubtotalPedido,
        subtotal: pedido.subtotalPedido,
      });

      // Encuentra las diferentes direcciones del pedido
      let addressShipping = pedido?.cliente?.direcciones?.find(
        (item) => item.consignatario_id === pedido?.direccionEnvioId
      );

      let addressBilling = pedido?.cliente?.direcciones?.find(
        (item) => item.consignatario_id === pedido?.direccionFacturacionId
      );

      setBillingAddress(addressBilling);
      setShippingAddress(addressShipping);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (idManualPayment) {
      ApiServiceComercios.getManualPaymentById(idManualPayment)
        .then(({ manualPayment }) => {
          setManualPayments(manualPayment);
        })
        .finally(() => () => {});
    }
  }, [idManualPayment]);

  // Render the product list of the order
  const itemProduct = productList.map(
    ({ articulo, cantidad, totalPrecioConDescuento }) => (
      <div
        className="flex justify-between items-center gap-4"
        key={articulo?.clave}
      >
        <div className="flex items-center gap-4">
          <div className="relative">
            <Thumbnail source={articulo?.imagen?.[0]} />

            {/* Quantity */}
            <div className="absolute -top-4 -right-3 bg-gray-400 rounded-full p-1 px-3 text-white">
              {cantidad ?? 0}
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <TextStyle variation="strong">{articulo?.nombre}</TextStyle>
            <TextStyle variation="subdued">SKU: {articulo?.clave}</TextStyle>
          </div>
        </div>

        <div className="pr-6">
          <TextStyle variation="strong">
            {formatTo2Digits(totalPrecioConDescuento * cantidad)}
          </TextStyle>
        </div>
      </div>
    )
  );

  // Todos los datos de la tarjeta
  // Si no hay datos de pago con tarjeta, no lo muestra
  const targetDetail = (payments.card || payments.brand) && (
    <div>
      <Heading>Método de pago</Heading>
      <div>
        <TextStyle variation="subdued">
          {payments?.conditionPayment} -{" "}
          {formatTo2Digits(payments.conditionDays)}
        </TextStyle>
        <div className="flex gap-3">
          <div>
            <Icon source={CreditCardMajor} color="base" />
          </div>
          <TextStyle variation="subdued">
            {payments.brand} Terminación en {payments.card} - $
            {payments?.amount}
          </TextStyle>
        </div>
      </div>
    </div>
  );

  const manualPaymentDetail = manualPayments.name && (
    <div>
      <Heading>Método de pago</Heading>
      <div>
        <TextStyle variation="subdued">{manualPayments.name}</TextStyle>
      </div>
    </div>
  );

  const onChangeStateModal = () => {
    setShowModal((prev) => !prev);
  };

  return (
    <div className="flex justify-around xl:h-screen sm:h-5/6 flex-wrap sm:flex-wrap md:flex-nowrap bg-scroll">
      {/* Order detail section */}
      <div className="w-screen h-full bg-white">
        <div className="p-9 px-16 bg-white">
          <div className="flex justify-center">
            <div>
              <img src={logoImg} alt="logo" />
            </div>
          </div>

          {/* Sección de agradecimiento */}
          <div className="flex gap-5">
            <div className="relative">
              <svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M49 25C49 38.2548 38.2548 49 25 49C11.7452 49 1 38.2548 1 25C1 11.7452 11.7452 1 25 1C38.2548 1 49 11.7452 49 25Z"
                  stroke="#1773B0"
                  stroke-width="2"
                />
              </svg>

              <div className="absolute left-6 top-7">
                <svg
                  width="22"
                  height="16"
                  viewBox="0 0 22 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 6.5113L8.3067 13.818L21.1247 1"
                    stroke="#1773B0"
                    stroke-width="2"
                  />
                </svg>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <TextStyle variation="subdued">
                Pedido {orderDetails.folio}
              </TextStyle>
              <p className="text-3xl">
                ¡Gracias {orderDetails.cliente.nombre}!
              </p>
            </div>
          </div>

          {/* Sección de detalles card */}
          <div className="flex flex-col gap-9 pt-8 pl-24 pr-4">
            <div className="border rounded-lg p-4 pr-36">
              <div className="flex flex-col gap-3">
                <Heading>
                  {payments.card
                    ? "Tu pedido está confirmado"
                    : "Tu pago está en aprobación"}
                </Heading>
                <TextStyle variation="subdued">
                  {payments.card
                    ? "Hemos recibido tu pedido y lo estamos preparando. Podremos enviarte notificaciones a tu correo del avance de tu entrega."
                    : "Hemos recibido tu pedido, los pedidos pagados manualmente deben aprobarse antes de su preparación."}
                </TextStyle>
              </div>
            </div>

            {/* Section client information */}
            <div className="border rounded-lg p-4">
              <div>
                <div className="pl-4">
                  <p className="text-3xl font-medium">Tu información</p>
                </div>

                <div className="grid grid-cols-2 pt-3 px-4">
                  <div className="flex flex-col gap-4">
                    <div>
                      <Heading>Contacto</Heading>
                      <div className="flex flex-col pt-2">
                        <TextStyle variation="subdued">
                          {orderDetails.cliente.nombre}
                        </TextStyle>
                        <TextStyle variation="subdued">
                          {orderDetails.cliente.email}
                        </TextStyle>
                      </div>
                    </div>

                    <div>
                      <Heading>Dirección de envío</Heading>
                      <div className="pt-2 flex flex-col">
                        <TextStyle variation="subdued">
                          {shippingAddress?.contacto}
                        </TextStyle>
                        <TextStyle variation="subdued">
                          {shippingAddress?.direccion}
                        </TextStyle>
                        <TextStyle variation="subdued">
                          {shippingAddress?.ciudad}, {shippingAddress?.estado},{" "}
                          {shippingAddress?.pais}.
                        </TextStyle>
                        <TextStyle variation="subdued">
                          C.P. {shippingAddress?.codigo_postal}
                        </TextStyle>
                      </div>
                    </div>
                    <div>
                      <div>
                        <Heading>Método de envío</Heading>
                        <TextStyle variation="subdued">
                          {orderDetails.envio.nombre}
                        </TextStyle>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-4">
                    {/* Detalles del pago con tarjeta */}
                    {payments.card ? targetDetail : manualPaymentDetail}

                    <div className="w-3/4">
                      <Heading>Dirección de facturación</Heading>
                      <div className="flex flex-col pt-2">
                        <TextStyle variation="subdued">
                          {billingAddress?.contacto}
                        </TextStyle>
                        <TextStyle variation="subdued">
                          RFC: {billingAddress?.rfc}
                        </TextStyle>
                        <TextStyle variation="subdued">
                          {billingAddress?.nombre_calle}
                        </TextStyle>
                        <TextStyle variation="subdued">
                          {billingAddress?.ciudad}, {billingAddress?.estado},{" "}
                          {billingAddress?.pais}.
                        </TextStyle>
                        <TextStyle variation="subdued">
                          C.P. {billingAddress?.codigo_postal}
                        </TextStyle>
                        <TextStyle variation="subdued">
                          {billingAddress?.telefono}
                        </TextStyle>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Section notes */}
            {orderDetails?.notas && (
              <div className="border rounded-lg p-4 flex flex-col gap-3">
                <Heading>Notas</Heading>
                <TextStyle variation="subdued">{orderDetails?.notas}</TextStyle>
              </div>
            )}

            {/* Section footer help */}
            <div className="flex justify-between pt-5 flex-wrap">
              <div className="flex items-center gap-4 mb-4">
                <div>¿Necesitas ayuda?</div>
                <Link onClick={onChangeStateModal}>
                  Ponte en contacto con nosotros
                </Link>
              </div>

              <Button url="/cliente/pedidos" primary>
                Finalizar
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Order product list  section */}
      <div className="w-9/12 pt-48 px-20 mb-24">
        <div className="flex flex-col px-12 gap-6">{itemProduct}</div>

        <hr className="my-8" />

        {/* Section cuentas */}
        <div>
          <div className="px-16 flex flex-col gap-4">
            <div className="flex justify-between">
              <TextStyle variation="subdued">
                Subtotal ({productList.length} artículos)
              </TextStyle>
              <TextStyle variation="strong">
                {formatTo2Digits(orderDetails?.subtotal)}
              </TextStyle>
            </div>
            <div className="flex justify-between">
              <TextStyle variation="subdued">Envío</TextStyle>
              <TextStyle variation="strong">
                {formatTo2Digits(orderDetails.envio?.precio)}
              </TextStyle>
            </div>
            <div className="flex justify-between">
              <TextStyle variation="subdued">Impuestos</TextStyle>
              <TextStyle variation="strong">
                {formatTo2Digits(orderDetails.impuestoSubtotal)}
              </TextStyle>
            </div>
          </div>
        </div>

        <hr className="my-8" />

        {/* section total to pay */}
        <div className="px-16 flex justify-between">
          <p className="text-3xl font-semibold">Total</p>
          <div className="flex gap-2 items-end">
            <TextStyle variation="subdued">MXN</TextStyle>
            <p className="text-4xl font-semibold">
              {formatTo2Digits(orderDetails.total)}
            </p>
          </div>
        </div>
      </div>

      {showModal && (
        <SendEmailModal isActive={showModal} onClose={onChangeStateModal} />
      )}
    </div>
  );
};
