import React from "react";
import { Icon, TextStyle } from "@shopify/polaris";
import * as IconType from "@shopify/polaris-icons";

export default function ItemButton({
  onClick,
  icon,
  text,
  textStrong,
  hasBorder = false,
}) {
  return (
    <div className="bg-white rounded">
      <div
        className="px-6 pt-6 cursor-pointer hover:bg-gray-200"
        onClick={onClick}
      >
        <div
          className={`flex justify-between w-full pb-6 ${
            hasBorder ? "border-b-2 border-gray-300" : ""
          }`}
        >
          <div className="flex select-none">
            {icon && (
              <div className="mr-4">
                <Icon source={IconType[icon]} color="inkLighter" />
              </div>
            )}
            <TextStyle>
              <TextStyle variation="strong">{textStrong}</TextStyle> {text}
            </TextStyle>
          </div>
          <div>
            <Icon source={IconType["ChevronRightMinor"]} color="inkLighter" />
          </div>
        </div>
      </div>
    </div>
  );
}
