import React, { useEffect, useState } from "react";
// import moment from "moment";
import {
  Card,
  //   DisplayText,
  FormLayout,
  Layout,
  Page,
  TextStyle,
  Button,
} from "@shopify/polaris";
import {
  Checklist,
  ComercioMicrosipSkeleton,
  Layer,
  HelpFooter,
} from "../../components";
import ApiServiceComercios from "../../services/ApiServiceComercios";
// import { NumberFormat } from "../../utils/Formats";

export default function PlanesScreen({ forbidden }) {
  const [plan, setPlan] = useState({
    name: "",
    description: "",
    amount: 0,
    frequency: "",
    maxCollaborators: 0,
    commissions: 0,
    vence: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    function obtenerPlan() {
      ApiServiceComercios.obtenerPlanComercio()
        .then(
          ({
            plan: {
              frequencies = [],
              frequency = "",
              name = "",
              description = "",
              maxCollaborators = 0,
              commissions = 0,
              vence,
            },
          }) => {
            const amount = frequencies.find(
              (i) => i.name === frequency
            )?.amount;

            setPlan({
              name: name,
              description: description,
              amount,
              frequency: frequency,
              maxCollaborators: maxCollaborators,
              commissions: commissions,
              vence: vence,
            });
          }
        )
        .finally(() => {
          setIsLoading(false);
        });
    }
    obtenerPlan();
  }, []);

  //   function validateMonth(frequency) {
  //     const frequencies = {
  //       mensual: "Mes",
  //       semestral: "6 meses",
  //       anual: "Año",
  //     };
  //     return frequencies[frequency] || "-";
  //   }

  if (isLoading) {
    return (
      <Layer title="Planes" forbidden={forbidden}>
        <ComercioMicrosipSkeleton />
      </Layer>
    );
  }

  return (
    <Layer title="Planes" forbidden={forbidden}>
      <Page
        title="Planes"
        breadcrumbs={[
          { content: "Configuración", url: "/admin/configuracion" },
        ]}
        separator
      >
        <Layout>
          <Layout.AnnotatedSection
            title="Detalles del plan"
            description="Lee nuestros términos del servicio y política de privacidad."
          >
            <Card>
              <Card.Section>
                <FormLayout>
                  <TextStyle variation="strong">
                    Plan: {plan.name || "-"}
                  </TextStyle>
                  <div className="flex flex-row justify-between items-center">
                    <TextStyle>{plan.description}</TextStyle>
                    {/* <div className="flex flex-row items-end">
                                 <DisplayText size="large">${Number(plan.amount || 0).toLocaleString("en", NumberFormat)}</DisplayText>
                                 <div className="whitespace-nowrap">
                                    <TextStyle>/{validateMonth(plan.frequency)}</TextStyle>
                                 </div>
                              </div> */}
                  </div>
                </FormLayout>
              </Card.Section>
              <Card.Section>
                <FormLayout>
                  <TextStyle variation="strong">Tu plan incluye</TextStyle>
                  <Checklist
                    text={`${plan.maxCollaborators} colaboradores de tu equipo`}
                    isLoading={false}
                    isSuccess={true}
                  />
                  <Checklist
                    text={`Comisión por transacción ${plan.commissions}%`}
                    isLoading={false}
                    isSuccess={true}
                  />
                </FormLayout>
              </Card.Section>
              {/* <Card.Section>
                <FormLayout>
                  <TextStyle variation="strong">Tu próximo cobro</TextStyle>
                  <TextStyle>
                    {moment(plan.vence).format("DD [de] MMMM [de] YYYY")} por $
                    {Number(plan.amount || 0).toLocaleString(
                      "en",
                      NumberFormat
                    )}
                  </TextStyle>
                </FormLayout>
              </Card.Section> */}
              <Card.Section>
                <FormLayout>
                  <TextStyle variation="strong">Forma de pago</TextStyle>
                  <TextStyle>No se ha agregado una forma de pago</TextStyle>
                </FormLayout>
              </Card.Section>
              <Card.Section>
                <div className="flex flex-row my-10 justify-end">
                  <Button disabled>Cancelar suscripción</Button>
                  <div className="w-4" />
                  <Button primary disabled>
                    Cambiar plan
                  </Button>
                </div>
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
        <HelpFooter
          title="planes"
          url="https://help.b2bgo.mx/configuracion/plan"
        />
      </Page>
    </Layer>
  );
}
