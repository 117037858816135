import { Card, DataTable, TextStyle } from "@shopify/polaris";
import moment from "moment";
import { useEffect, useState } from "react";
import { formatTo2Digits } from "../../utils/Formats";

export default function TransactionsInfoCard({ origin, data, dateBill }) {
  const [billData, setBillData] = useState([]);
  const [orderData, setOrderData] = useState({});

  useEffect(() => {
    if (data) {
      if (origin === "billing") {
        setBillData(data);
      } else {
        setOrderData(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const rowBillsMarkup = billData?.map((item) => {
    const date = (
      <TextStyle>{moment(dateBill).format("DD [de] MMMM")}</TextStyle>
    );
    const origin = <TextStyle>{"Factura"}</TextStyle>;
    const referencia = <TextStyle>{item.folio}</TextStyle>;
    const cargo = <TextStyle>{formatTo2Digits(item.saldo, true)}</TextStyle>;

    return [date, origin, cargo, referencia];
  });

  const rowOrderMarkup = [
    [
      <TextStyle>
        {moment(orderData.createdDate).format("DD [de] MMMM")}
      </TextStyle>,
      <TextStyle>Pedido</TextStyle>,
      <TextStyle>{orderData.folio}</TextStyle>,
      <TextStyle>{formatTo2Digits(orderData.total, true)}</TextStyle>,
    ],
  ];

  return (
    <Card fullWidth title="Transacciones">
      <Card.Section>
        <DataTable
          columnContentTypes={["text", "text", "text", "numeric"]}
          headings={["Fecha", "Concepto", "Referencia", "Cargo"]}
          rows={origin === "billing" ? rowBillsMarkup : rowOrderMarkup}
        />
      </Card.Section>
    </Card>
  );
}
