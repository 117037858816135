import React, { useEffect, useCallback, useState, useRef } from "react";
import {
  Banner,
  Button,
  Card,
  FormLayout,
  Layout,
  Page,
  Select,
  Stack,
  TextField,
  ContextualSaveBar,
} from "@shopify/polaris";
import { Skeleton } from "antd";
import { useDispatch } from "react-redux";
import { CountrySelect, Layer } from "../../components";
import useDebounce from "../../services/useDebounce";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { toggleToast } from "../../actions/InteractiveActions";
import { Estados } from "../../utils/EstadosRepublica";
import { EmailEx } from "../../utils/RegEx";

export default function ConfigEmailScreen(props) {
  const dispatch = useDispatch();
  const emailContact = useRef(null);

  const [email, setEmail] = useState("");
  const [countryActive, toggleActive] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isLoadingEmail, setIsLoadingEmail] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [emailVerification, setEmailVerification] = useState({
    exist: false,
    isVerified: false,
    id: null,
  });

  const [sender, setSender] = useState({
    from_email: "",
    from_name: "",
    reply_to: "",
    reply_to_name: "",
    address: "",
    state: "",
    city: "",
    country: "México",
    zip: "",
    nickname: "",
  });

  const [selectedFlag, setSelectedFlag] = useState({
    country: { code: "MX", phone_code: "52" },
    phone: { code: "MX", phone_code: "52" },
  });
  const [fieldsError, setFieldsError] = useState({
    from_email: "",
    reply_to: "",
  });

  const debouncedEmail = useDebounce(email, 900);

  useEffect(() => {
    emailContact.current = email;
  });

  const fetchVerifiedEmail = useCallback((email = "", initial = true) => {
    if (email && !initial) {
      setIsLoadingEmail(true);
      ApiServiceComercios.obtenerComercioConfigEmail(encodeURIComponent(email))
        .then(({ isVerified = false, exist = false, id = null, sender }) => {
          setEmailVerification({
            exist,
            isVerified,
            id,
          });
          setSender({ ...sender, country: sender?.country || "México" });
        })
        .finally(() => setIsLoadingEmail(false));
    } else {
      setIsLoadingEmail(false);
    }
  }, []);

  useEffect(() => {
    fetchVerifiedEmail(emailContact.current, false);
  }, [debouncedEmail, fetchVerifiedEmail]);

  useEffect(() => {
    setEmail(props.location.state.email);
  }, [fetchVerifiedEmail, props.location.state.email]);

  const resendEmail = () => {
    setIsLoadingEmail(true);
    ApiServiceComercios.resendInvitationSender(emailVerification.id)
      .then(({ message }) => {
        dispatch(toggleToast({ message }));
      })
      .finally(() => setIsLoadingEmail(false));
  };

  const handleChange = (field, value) => {
    setIsDirty(true);
    setSender((state) => ({
      ...state,
      [field]: value,
    }));
  };

  const handleSelectCountry = (country) => {
    handleChange("country", country["COUNTRY"]);
    setSelectedFlag((state) => ({
      ...state,
      country: {
        code: country["ISO CODES"].split(" / ")[0],
      },
    }));
    toggleActive();
  };

  function handleEmailOnBlur(value, field) {
    if (!EmailEx.test(value)) {
      setFieldsError((state) => ({
        ...state,
        [field]: "Ingresa un correo válido",
      }));
    } else {
      setFieldsError((state) => ({ ...state, [field]: "" }));
    }
  }

  const onAddSender = () => {
    try {
      setIsAdding(true);
      let { state = "" } = sender;
      if (state) {
        state = state.substring(0, 2).toUpperCase();
      }
      handleEmailOnBlur(sender.from_email, "from_email");
      handleEmailOnBlur(sender.reply_to, "reply_to");
      if (emailVerification.exist) {
        handleUpdateConfig()
          .then(() => {
            dispatch(
              toggleToast({ message: "Datos actualizados correctamente" })
            );
          })
          .finally(() => setIsAdding(false));
      } else {
        if (!fieldsError.from_email && !fieldsError.reply_to) {
          ApiServiceComercios.createSender({
            ...sender,
            state,
            from_email: email,
          })
            .then(async ({ sender, message }) => {
              setEmailVerification({
                ...emailVerification,
                exist: true,
                isVerified: false,
                id: sender.id,
              });
              setSender(sender);
              dispatch(toggleToast({ message }));
              await handleUpdateConfig();
            })
            .finally(() => setIsAdding(false));
        }
      }
    } catch (error) {}
  };

  const handleUpdateConfig = async () => {
    const comercioData = {
      comercio: {
        contacto: {
          emailRemitente: email,
        },
      },
    };

    return new Promise((resolve, reject) => {
      ApiServiceComercios.editarComercio(comercioData)
        .then((result) => {
          resolve();
        })
        .catch((err) => {
          reject();
        });
    });
  };

  const handleChangeEmail = (value) => {
    setEmail(value);
    setIsDirty(true);
  };

  const contextualSaveBar = isDirty ? (
    <ContextualSaveBar
      discardAction={{
        content: "Cancelar",
        url: "/admin/configuracion/general",
      }}
      saveAction={{
        content: "Guardar",
        onAction: onAddSender,
        disabled:
          isAdding || !sender.from_name || !sender.nickname || !sender.reply_to,
        loading: isAdding,
      }}
    />
  ) : null;

  return (
    <Layer title="Configurar email" forbidden={props.forbidden}>
      <Page
        title="Correo electrónico del remitente"
        breadcrumbs={[
          {
            content: "Configuración general",
            url: "/admin/configuracion/general",
          },
        ]}
      >
        {contextualSaveBar}
        <Layout>
          <Layout.AnnotatedSection
            title="Comunicaciones con clientes"
            description="Esta información estará visible para los clientes cuando los contactes con correos electrónicos relacionados con cotizaciones y pedidos."
          >
            <Card sectioned>
              <FormLayout>
                <TextField
                  label="Correo electrónico del remitente"
                  value={email}
                  placeholder="contacto"
                  onChange={handleChangeEmail}
                  type="email"
                />
                {isLoadingEmail ? (
                  <Skeleton avatar paragraph={{ rows: 2 }} />
                ) : emailVerification.exist ? (
                  <>
                    <Banner status="success">Este remitente si existe</Banner>
                    {emailVerification.isVerified ? (
                      <Banner status="success">
                        Este remitente si está verificado
                      </Banner>
                    ) : (
                      <Banner
                        title="Este remitente no está verificado"
                        status="critical"
                        action={{
                          content: "Reenviar verificación",
                          onAction: resendEmail,
                          disabled: !emailVerification.exist,
                        }}
                      >
                        Te enviaremos un correo de verificación, reenviálo a
                        admin@b2bgo.mx para completar el proceso.
                      </Banner>
                    )}
                  </>
                ) : (
                  <Banner title="Este remitente no existe" status="critical">
                    Para crear su remitente, rellene todos los campos del
                    formulario y luego haga clic en Guardar
                  </Banner>
                )}
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title="Dirección del comercio"
            description="Con esta dirección se creará un remitente verificado en nuestros sistemas."
          >
            <Card sectioned>
              <FormLayout>
                <Stack distribution="fillEvenly">
                  <TextField
                    label="Nombre del remitente *"
                    value={sender?.from_name}
                    onChange={(value) => handleChange("from_name", value)}
                    placeholder="Comercio"
                    maxLength={256}
                    disabled={emailVerification.exist}
                  />
                  <TextField
                    label="Apodo *"
                    value={sender?.nickname}
                    onChange={(value) => handleChange("nickname", value)}
                    placeholder="Pedidos"
                    maxLength={100}
                    disabled={emailVerification.exist}
                  />
                </Stack>
                <div className="flex items-start">
                  <div className="flex-1">
                    <TextField
                      type="email"
                      label="Responder a *"
                      placeholder="contacto@correo.com"
                      maxLength={256}
                      value={sender?.reply_to}
                      onChange={(value) => handleChange("reply_to", value)}
                      onBlur={() =>
                        handleEmailOnBlur(sender?.reply_to, "reply_to")
                      }
                      error={fieldsError.reply_to}
                      disabled={emailVerification.exist}
                    />
                  </div>
                </div>
                <TextField
                  label="Dirección"
                  value={sender?.address}
                  onChange={(value) => handleChange("address", value)}
                  maxLength={256}
                  disabled={emailVerification.exist}
                />
                <Stack distribution="fillEvenly">
                  <TextField
                    label="Ciudad"
                    value={sender?.city}
                    onChange={(value) => handleChange("city", value)}
                    maxLength={150}
                    disabled={emailVerification.exist}
                  />
                  <TextField
                    label="Código postal"
                    value={sender?.zip}
                    onChange={(value) => handleChange("zip", value)}
                    maxLength={10}
                    disabled={emailVerification.exist}
                  />
                </Stack>
                <Stack distribution="fillEvenly">
                  <Select
                    label="Estado"
                    options={Estados}
                    value={sender?.state}
                    placeholder="Selecciona un estado"
                    onChange={(value) => handleChange("state", value)}
                    disabled={emailVerification.exist}
                  />
                  <CountrySelect
                    title="País"
                    active={countryActive}
                    handleSelect={handleSelectCountry}
                    selectedCountry={sender?.country}
                    selectedFlag={selectedFlag.country}
                    toggleActive={toggleActive}
                    disabled={emailVerification.exist}
                  />
                </Stack>
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
        <div className="flex justify-end mt-4">
          <Button url="/admin/configuracion/general" disabled={isAdding}>
            Cancelar
          </Button>
          <div className="mx-3" />
          <Button
            onClick={onAddSender}
            disabled={
              isAdding ||
              !sender.from_name ||
              !sender.nickname ||
              !sender.reply_to
            }
            loading={isAdding}
            primary
          >
            Guardar
          </Button>
        </div>
      </Page>
    </Layer>
  );
}
