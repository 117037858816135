import React from "react";
import { Thumbnail, TextStyle, Button } from "@shopify/polaris";
import { MobileCancelMajor } from "@shopify/polaris-icons";
import { Row, Col } from "antd";
import { getImageVideoUrl } from "../../utils/productValidators";

export default function ArticleSimple({
  onRemove,
  disabled,
  articulo = null,
  title = "",
  id,
}) {
  const { isParent = false } = articulo || {};
  const onDeleteItem = () => {
    if (articulo) {
      if (isParent) {
        onRemove(articulo.variants[0].variantId._id, articulo._id);
      } else {
        onRemove(articulo._id);
      }
    } else {
      onRemove(id);
    }
  };

  return (
    <>
      <div className="border-b-2 border-gray-300 pb-2">
        <Row gutter={[1, 2]} align="middle">
          <Col span={20} xs={24} sm={22} md={20}>
            {articulo ? (
              <div className="flex mt-6 items-start">
                <div className="relative mr-8">
                  <Thumbnail
                    size="medium"
                    source={
                      getImageVideoUrl(articulo?.imagen, "image", false) ||
                      "/Default Photo.png"
                    }
                  />
                </div>
                <div className="flex flex-col">
                  <p className="text-blue-600">
                    {articulo ? articulo.nombre : ""}
                  </p>
                  <TextStyle variation="subdued">
                    SKU:{" "}
                    {isParent
                      ? articulo.variants[0].variantId.sku
                      : articulo.sku || articulo.clave || ""}
                  </TextStyle>
                </div>
              </div>
            ) : (
              <div className="my-8">
                <TextStyle>{title}</TextStyle>
              </div>
            )}
          </Col>
          <Col span={4} sm={2} md={4}>
            <div className="flex flex-row h-full justify-end items-center xs:items-start">
              {!disabled && (
                <div className="mr-2 xs:self-start">
                  <Button
                    icon={MobileCancelMajor}
                    plain
                    onClick={onDeleteItem}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
