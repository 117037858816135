import _ from "lodash";
import {
  ADD_ARTICULOS_SHOPPING_CART,
  CHANGE_QUANTITY,
  ADD_CLIENT_INFO,
  CLEAR_SHOPPING_CART,
  REMOVE_ARTICLE_SHOPPING_CART,
  ADD_NOTE_ITEM_CART,
  CLEAR_SHOPPING_CART_NO_USER,
} from "../actions/ShoppingCartActions";

const initialState = {
  articles: [],
  descuentoMaximo: [],
  descuentoVolumen: [],
  descuentoPromocion: [],
  showTax: false,
  limiteCredito: {},
  clienteEnvio: {},
  impuesto: {},
  totales: [],
  moneda: {},
  customerData: {},
  shippingFees: [],
  discounts: [],
  priceToUse: null,
  isActivePayment: false,
  status_microsip: "",
  usePaymentsInOrders: false,
};

export default function ShoppingCartReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ARTICULOS_SHOPPING_CART: {
      let articles = [...state.articles];

      let {
        article,
        cantidad,
        descuentoMaximo,
        descuentoVolumen,
        descuentoPromocion,
        showTax,
      } = action.payload.data;
      let existArticle = articles.find(
        (i) => String(i._id) === String(article._id)
      );
      if (existArticle) {
        const index = _.findIndex(articles, { _id: article._id });
        articles.splice(index, 1, {
          ...article,
          cantidad: Number(existArticle.cantidad) + Number(cantidad),
        });
      } else {
        articles.push({ ...article, cantidad: Number(cantidad) });
      }

      return {
        ...state,
        articles,
        descuentoMaximo,
        descuentoVolumen,
        descuentoPromocion,
        showTax,
      };
    }

    case ADD_NOTE_ITEM_CART: {
      let articles = [...state.articles];
      const { note, id } = action.payload.data;
      articles.forEach((obj) => {
        if (obj.id === id) {
          obj["notas"] = note;
        }
      });
      return {
        ...state,
        articles,
      };
    }

    case CHANGE_QUANTITY: {
      let articles = [...state.articles];

      let { articuloId, cantidad } = action.payload.data;
      let existArticle = articles.find(
        (i) => String(i._id) === String(articuloId)
      );

      const index = _.findIndex(articles, { _id: articuloId });
      articles.splice(index, 1, {
        ...existArticle,
        cantidad: Number(cantidad),
      });

      return {
        ...state,
        articles,
      };
    }

    case REMOVE_ARTICLE_SHOPPING_CART: {
      let articles = [...state.articles];
      const { articuloId } = action.payload.data;

      articles = articles.filter((i) => String(i._id) !== String(articuloId));

      return {
        ...state,
        articles,
      };
    }

    case ADD_CLIENT_INFO: {
      const data = _.pick(action.payload.data, [
        "limiteCredito",
        "clienteEnvio",
        "impuesto",
        "moneda",
        "customerData",
        "shippingFees",
        "discounts",
        "priceToUse",
        "isActivePayment",
        "vendedor",
        "status_microsip",
        "usePaymentsInOrders",
      ]);

      return {
        ...state,
        ...data,
        customerData: {
          ...state.customerData,
          ...data.customerData,
        },
      };
    }

    case CLEAR_SHOPPING_CART: {
      return {
        ...initialState,
      };
    }
    case CLEAR_SHOPPING_CART_NO_USER: {
      return {
        ...state,
        articles: [],
        totales: [],
      };
    }

    default: {
      return state;
    }
  }
}
