import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Page,
  Layout,
  Card,
  TextStyle,
  Link,
  ContextualSaveBar,
  TextField,
  Button,
  ButtonGroup,
  FormLayout,
  Checkbox,
  Banner,
} from "@shopify/polaris";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  obtenerComercioConfigMicrosip,
  editarComercioConfigMicrosip,
} from "../../actions/ComerciosActions";
import { Layer, ComercioMicrosipSkeleton, HelpFooter } from "../../components";
import { toggleToast } from "../../actions/InteractiveActions";

export default function ComercioConfigMicrosipDataScreen({ forbidden }) {
  const router = useHistory();
  const dispatch = useDispatch();

  const defaultValues = useRef({
    id: "",
    articuloDatos: {
      nombre: false,
      descripcion: false,
      codigo_barra: false,
      factor_venta: false,
      peso: false,
      imagen: false,
      packingKeys: false,
      factor_uni_venta: false,
      linea_articulo: false,
    },
    hours: 0,
  });
  const [configuracion, setConfiguracion] = useState({
    ...defaultValues.current,
  });
  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const handleFieldChange = useCallback((value, key) => {
    setConfiguracion((state) => ({ ...state, [key]: value }));
    value && setIsDirty(true);
  }, []);

  useEffect(() => {
    dispatch(obtenerComercioConfigMicrosip())
      .then((response) => {
        const { articuloDatos, hours, _id } = response;
        const data = {
          articuloDatos,
          id: _id,
          hours: hours.toString(),
        };
        setConfiguracion((state) => ({ ...state, ...data }));
      })
      .finally(() => setIsLoading(false));
  }, [dispatch]);

  function handleDiscard() {
    router.push("/admin/configuracion/integracion/microsip");
  }

  function handleSave() {
    setIsSaving(true);
    const artiDatos = {
      ...configuracion,
      hours: configuracion.hours.toString(),
    };
    let config = { ...artiDatos, enabled: true };

    dispatch(editarComercioConfigMicrosip(config, configuracion.id))
      .then(({ message }) => {
        dispatch(toggleToast({ message }));
      })
      .finally(() => {
        setIsSaving(false);
        setIsDirty(false);
      });
  }

  function handleChangeValue(value, key) {
    setIsDirty(true);
    setConfiguracion((state) => ({
      ...state,
      articuloDatos: { ...state.articuloDatos, [key]: value },
    }));
  }

  function onBlurChange(val) {
    if (!val) {
      setConfiguracion((state) => ({ ...state, hours: "0" }));
    }
  }

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Cambios no guardados"
      saveAction={{
        onAction: handleSave,
        disabled: !isDirty || isSaving,
        loading: isSaving,
      }}
      discardAction={{
        onAction: handleDiscard,
        disabled: isSaving,
      }}
    />
  ) : null;

  if (isLoading) {
    return (
      <Layer title="Integración microsip" forbidden={forbidden}>
        <ComercioMicrosipSkeleton />
      </Layer>
    );
  }

  return (
    <Layer title="Integración microsip" forbidden={forbidden}>
      <Page
        separator
        title="Microsip"
        breadcrumbs={[
          {
            content: "Integraciones",
            url: "/admin/configuracion/integracion/microsip",
          },
        ]}
      >
        {contextualSaveBarMarkup}
        <Layout>
          <Layout.AnnotatedSection
            title="Detalles de la integración"
            description={
              <TextStyle>
                B2BGO usarán estos datos para conectarse con Microsip. <br />
                <br />
                En el servidor de Microsip deberás instalar 4Q Sinc de Exsim 
                para completar la integración, descárga aquí la versión para <Link url="https://exsim.mx/downloads/4QSinc_2022.zip">Microsip 2022</Link> o <Link url="https://exsim.mx/downloads/4QSinc_2023.zip">Microsip 2023</Link>.
              </TextStyle>
            }
          >
            <Card>
              <Card.Section>
                <FormLayout>
                  <TextField
                    label="Horas"
                    type="number"
                    placeholder="72"
                    helpText="Indica las horas en que el sincronizador obtendrá los últimos datos actualizados en el rango de las horas indicadas"
                    value={configuracion.hours}
                    onChange={(text) => handleFieldChange(text, "hours")}
                    onBlur={() => onBlurChange(configuracion.hours)}
                  />
                </FormLayout>
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title="Datos a sincronizar"
            description="Activa el campo que deseas mantener sincronizado con tu sistema Microsip. Mantenlo desactivado si deseas gestionarlo en b2bGO. "
          >
            <Card sectioned>
              <TextStyle>Datos de los Artículos</TextStyle>
              <FormLayout>
                <Checkbox
                  label="Nombre"
                  helpText="El nombre de tu producto, tal y como deseas que tus clientes lo vean."
                  checked={configuracion.articuloDatos.nombre}
                  onChange={(value) => handleChangeValue(value, "nombre")}
                />
                <Checkbox
                  label="Descripción"
                  helpText="Describe tus productos de forma detallada para informar y persuadir a tus clientes"
                  checked={configuracion.articuloDatos.descripcion}
                  onChange={(value) => handleChangeValue(value, "descripcion")}
                />
                <Checkbox
                  label="Código de barras"
                  helpText="Los revendedores generalmente utilizan los códigos de barras."
                  checked={configuracion.articuloDatos.codigo_barra}
                  onChange={(value) => handleChangeValue(value, "codigo_barra")}
                />
                <Checkbox
                  label="Factor de venta"
                  helpText="Puedes establecer el número de unidades en las que se agrupa y vende un producto."
                  checked={configuracion.articuloDatos.factor_venta}
                  onChange={(value) => handleChangeValue(value, "factor_venta")}
                />
                <Checkbox
                  label="Peso"
                  helpText="Peso real del producto. Este campo solo aparece cuando la opción Este es un producto físico está marcada."
                  checked={configuracion.articuloDatos.peso}
                  onChange={(value) => handleChangeValue(value, "peso")}
                />
                <Checkbox
                  label="Imagen"
                  helpText="El uso de imágenes permite apreciar mejor un artículo y aumenta la confianza de tus clientes en la calidad de tus productos."
                  checked={configuracion.articuloDatos.imagen}
                  onChange={(value) => handleChangeValue(value, "imagen")}
                />
                <Checkbox
                  label="Clave de empaques"
                  helpText="Las claves de empaque permiten buscar a los artículos y agregarlos a los pedidos y cotizaciones"
                  checked={configuracion.articuloDatos.packingKeys}
                  onChange={(value) => handleChangeValue(value, "packingKeys")}
                />
                <Checkbox
                  label="Cantidad mínimo"
                  helpText="Puedes establecer la cantidad mínima del producto"
                  checked={configuracion.articuloDatos.factor_uni_venta}
                  onChange={(value) =>
                    handleChangeValue(value, "factor_uni_venta")
                  }
                />
                <Checkbox
                  label="Línea de artículo"
                  helpText="La línea de artículo a la cual pertenece tu artículo"
                  checked={configuracion.articuloDatos.linea_articulo}
                  onChange={(value) =>
                    handleChangeValue(value, "linea_articulo")
                  }
                />
              </FormLayout>
            </Card>
            <Banner status="warning" title="Integridad de la sincronización">
              Para mantener la integridad de la información, el campo Clave
              principal no es editable. Los Precios y Existencias siempre se
              actualizarán.
            </Banner>
          </Layout.AnnotatedSection>

          <Layout.AnnotatedSection>
            <div className="flex justify-end">
              <ButtonGroup>
                <Button onClick={handleDiscard} disabled={isSaving}>
                  Descartar
                </Button>
                <Button
                  disabled={isSaving}
                  loading={isSaving}
                  primary
                  onClick={handleSave}
                >
                  Guardar
                </Button>
              </ButtonGroup>
            </div>
          </Layout.AnnotatedSection>
        </Layout>
      </Page>
      <HelpFooter
        title="integraciones"
        url="https://help.b2bgo.mx/configuracion/integraciones/microsip"
      />
    </Layer>
  );
}
