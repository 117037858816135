import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import {
  Page,
  Card,
  Layout,
  TextField,
  Stack,
  Checkbox,
  TextStyle,
  Select,
  DataTable,
  EmptyState,
  FormLayout,
  ContextualSaveBar,
  Thumbnail,
  Badge,
  OptionList,
  Button,
  PageActions,
} from "@shopify/polaris";
import {
  ComercioDetalleSkeleton,
  DeleteModal,
  GeneralModal,
  ImageModal,
  Layer,
  PreventTransitionPrompt,
} from "../../components";
import { toggleToast } from "../../actions/InteractiveActions";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { optionsMedida } from "../../utils/Typedefs";
import useToggle from "../../hooks/useToggle";
import { scrollToTop } from "../../utils/windowActions";
import { getImageVideoUrl } from "../../utils/productValidators";

export default function VariantsScreen({ forbidden }) {
  const { id, variantId } = useParams();

  const dispatch = useDispatch();
  const router = useHistory();
  const [isActiveImageModal, onToggleImageModal] = useToggle();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const [articuloDB, setArticuloDB] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [variantSelected, setVariantSelected] = useState({});
  const [isBlocking, setIsBlocking] = useState(false);
  const [targetVariant, setTargetVariant] = useState({});
  const [minimum, setMinimum] = useState(1);
  const [maximum, setMaximum] = useState(1);
  const [factorVenta, setFactorVenta] = useState(1);
  const [fieldsError, setFieldsError] = useState({
    minimum: null,
    maximum: null,
    factor_venta: null,
  });

  useEffect(() => {
    ApiServiceComercios.obtenerVariante(id)
      .then(({ articulo }) => {
        const { variants } = articulo;
        const variant = variants.find(
          (variant) => String(variant.variantId._id) === String(variantId)
        );
        setArticuloDB(articulo);
        setVariantSelected(variant);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [id, variantId]);

  function onSelectImage(image = "") {
    onToggleImageModal();
    handleChangeArticle(image ? [image] : [], "imagen");
  }

  useEffect(() => {
    if (maximum < minimum) {
      setFieldsError((state) => ({
        ...state,
        maximum: "Debe ser mayor al mínimo",
      }));
    } else if (maximum % factorVenta !== 0) {
      setFieldsError((state) => ({
        ...state,
        maximum: "Debe ser múltiplo del factor de venta",
      }));
    } else {
      setFieldsError((state) => ({
        ...state,
        maximum: null,
      }));
    }
    if (minimum < factorVenta) {
      setFieldsError((state) => ({
        ...state,
        minimum: "No debe ser menor al factor de venta",
      }));
    } else {
      setFieldsError((state) => ({
        ...state,
        minimum: null,
      }));
    }

    if (maximum < factorVenta && maximum > 0) {
      setFieldsError((state) => ({
        ...state,
        factor_venta: "Debe ser menor al máximo",
      }));
    } else {
      setFieldsError((state) => ({
        ...state,
        factor_venta: "",
      }));
    }

    if (maximum === 0) {
      setFieldsError((state) => ({
        ...state,
        maximum: "",
      }));
    }
  }, [setMinimum, setMaximum, setFactorVenta, minimum, maximum, factorVenta]);

  async function handleSave(
    onlyImages = false,
    filesIncoming = [],
    aceptedImagesIncoming = [],
    updateProperties = true,
    selectedImages = []
  ) {
    const variantID = variantSelected.variantId._id;

    setIsEditing(true);
    const formData = new FormData();

    let articuloData = {};
    if (updateProperties) {
      articuloData["peso"] = variantSelected.variantId.peso;
      articuloData["imagen"] = variantSelected.variantId.imagen;
      articuloData["factor_venta"] = variantSelected.variantId.factor_venta;
      articuloData["continuar_vendiendo"] =
        variantSelected.variantId.continuar_vendiendo;
      articuloData["willDeleteImage"] = true;
      articuloData["minimum"] = Number(minimum);
      articuloData["maximum"] = Number(maximum);
    } else {
      articuloData["willDeleteImage"] = false;
    }

    if (aceptedImagesIncoming.length > 0) {
      articuloData["imagen"] = aceptedImagesIncoming;
    }

    if (onlyImages) {
      articuloData["imagen"] = articuloDB.imagen.filter(
        (i) => !selectedImages.includes(i)
      );
    }

    if (filesIncoming.length > 0) {
      filesIncoming.forEach((file) => formData.append("files", file));
      formData.append("articulo", JSON.stringify(articuloData));
    }

    return new Promise((resolve, reject) => {
      ApiServiceComercios.actualizarArticulo(
        { ...articuloData },
        updateProperties ? variantID : id,
        filesIncoming.length > 0 ? formData : null
      )
        .then(({ message, imagen }) => {
          if (updateProperties) {
            dispatch(toggleToast({ message }));
          } else {
            setArticuloDB((state) => ({ ...state, imagen }));
          }
          resolve({ ok: true });
        })
        .catch(() => {
          reject({ ok: false });
        })
        .finally(() => {
          setIsEditing(false);
          setIsDirty(false);
        });
    });
  }

  function changeHref(variantID) {
    const href = window.location.href;
    let url = href.split("/variant/");
    let newUrl = `${url[0]}/variant/${variantID}`;
    window.history.pushState({}, "", newUrl);
  }

  function handleSelectVariant(variantID) {
    const { variants } = articuloDB;
    const variant = variants.find(
      (i) => String(i.variantId._id) === String(variantID[0])
    );
    setTargetVariant(variant);
    setMaximum(
      variant.variantId.maximum ? Number(variant.variantId.maximum) : Number(1)
    );
    setMinimum(
      variant.variantId.minimum ? Number(variant.variantId.minimum) : Number(1)
    );
    setFactorVenta(
      variant.variantId.factor_venta
        ? Number(variant.variantId.factor_venta)
        : Number(1)
    );
    isDirty && setIsBlocking(true);
    !isDirty && setVariantSelected(variant);
    changeHref(variantID[0]);
    scrollToTop();
  }

  function onAbandonVariant() {
    setVariantSelected(targetVariant);
    setIsDirty(false);
    setIsBlocking(false);
  }

  function onCancelBlocking() {
    setTargetVariant({});
    setIsBlocking(false);
  }

  function handleDiscard() {
    router.push("/admin/articulos");
  }

  function previousPage() {
    const index = articuloDB.variants.findIndex(
      ({ variantId: { _id } }) => _id === variantSelected.variantId._id
    );
    const next = articuloDB.variants[index - 1];
    if (next) {
      const {
        variantId: { _id },
      } = next;
      handleSelectVariant([_id]);
    }
  }

  function nextPage() {
    const index = articuloDB.variants.findIndex(
      ({ variantId: { _id } }) => _id === variantSelected.variantId._id
    );
    const next = articuloDB.variants[index + 1];
    if (next) {
      const {
        variantId: { _id },
      } = next;
      handleSelectVariant([_id]);
    }
  }

  function validateName(options) {
    return options.filter((i) => i).join(" / ");
  }

  function sincronizar() {
    ApiServiceComercios.sincronizarArticulos().finally(() => {
      dispatch(toggleToast({ message: "En breve se actualizarán los datos" }));
    });
  }

  function handleChangeArticle(value, key) {
    const variantID = variantSelected.variantId._id;
    const variants = [...articuloDB.variants];
    const index = _.findIndex(variants, { variantId: { _id: variantID } });

    variants.splice(index, 1, {
      ...variantSelected,
      variantId: {
        ...variantSelected.variantId,
        [key]: value,
      },
    });

    setIsDirty(true);
    setArticuloDB((state) => ({ ...state, variants }));
    setVariantSelected(variants[index]);
  }

  if (isLoading) {
    return (
      <Layer title={`Artículo`}>
        <ComercioDetalleSkeleton />
      </Layer>
    );
  }

  if (articuloDB === null) {
    return (
      <Layer title={`Artículo`}>
        <EmptyState
          heading="Oops! Hubo un problema al intentar obtener este artículo"
          action={{
            content: "Volver a intentar",
            onAction: () => window.location.reload(),
          }}
          secondaryAction={{
            content: "Regresar a artículos",
            url: "/admin/articulos",
          }}
          image="/Ad.svg"
        >
          <p>Intenta de nuevo más tarde</p>
        </EmptyState>
      </Layer>
    );
  }

  const contextualSave = isDirty ? (
    <ContextualSaveBar
      message="Cambios no guardados"
      saveAction={{
        onAction: () => handleSave(false, [], [], true, []),
        disabled:
          !isDirty ||
          fieldsError.maximum ||
          fieldsError.factor_venta ||
          fieldsError.minimum,
        loading: isEditing,
      }}
      discardAction={{
        onAction: handleDiscard,
        disabled: isEditing,
      }}
    />
  ) : null;

  const deleteVariantArticle = () => {
    setIsSavingChanges(true);
    ApiServiceComercios.deleteVariantArticle(id, variantId).then((res) => {
      handleDiscard();
      dispatch(
        toggleToast({
          message: res.message,
        })
      );
    });
  };

  const { variants = [] } = articuloDB;

  return (
    <Layer
      title={`Artículo detalle - ${validateName([
        variantSelected.option1,
        variantSelected.option2,
        variantSelected.option3,
        variantSelected.option4,
        variantSelected.option5,
        variantSelected.option6,
        variantSelected.option7,
        variantSelected.option8,
        variantSelected.option9,
        variantSelected.option10,
      ])}`}
      forbidden={forbidden}
      scrollToTop
    >
      <Page
        title={validateName([
          variantSelected.option1,
          variantSelected.option2,
          variantSelected.option3,
          variantSelected.option4,
          variantSelected.option5,
          variantSelected.option6,
          variantSelected.option7,
          variantSelected.option8,
          variantSelected.option9,
          variantSelected.option10,
        ])}
        breadcrumbs={[
          {
            content: articuloDB.nombre || "Artículo detalle",
            url: `/admin/articulos/${id}`,
          },
        ]}
        pagination={{
          hasPrevious:
            variants.findIndex(
              ({ variantId: { _id } }) => _id === variantSelected.variantId._id
            ) >= 1,
          hasNext:
            variants.findIndex(
              ({ variantId: { _id } }) => _id === variantSelected.variantId._id
            ) <
            variants.length - 1,
          onPrevious: previousPage,
          nextTooltip: "Siguiente variante",
          onNext: nextPage,
          previousTooltip: "Variante anterior",
        }}
      >
        {contextualSave}
        {isBlocking && (
          <GeneralModal
            body="Si abandonas esta página, se perderán los cambios no guardados."
            onAction={onAbandonVariant}
            onClose={onCancelBlocking}
            open={isBlocking}
            primary="Abandonar página"
            secondary="Cancelar"
            title="Cambios no guardados"
            destructive
          />
        )}
        {isActiveImageModal && (
          <ImageModal
            isOpen={isActiveImageModal}
            onClose={onToggleImageModal}
            images={articuloDB.imagen}
            onAddImage={handleSave}
            onSelectImage={onSelectImage}
            imageVariant={
              variantSelected?.variantId?.imagen?.length > 0
                ? variantSelected?.variantId?.imagen
                : []
            }
          />
        )}

        <PreventTransitionPrompt
          when={isDirty}
          setIsBlocking={setIsDirty}
          router={router}
          title="Tienes cambios sin guardar en esta página"
          message="Si abandonas esta página, perderás todos los cambios no guardados. ¿Estás seguro de que quieres salir de esta página?"
        />
        <Layout>
          <Layout.Section secondary>
            <Card sectioned>
              <div className="flex">
                <div className="flex">
                  <Thumbnail
                    size="medium"
                    source={
                      getImageVideoUrl(articuloDB.imagen, "image", false) ||
                      "/Default Photo.png"
                    }
                    alt={articuloDB.nombre}
                  />
                </div>
                <div className="flex flex-col ml-4 items-start">
                  <div className="">
                    <TextStyle variation="strong">
                      {articuloDB.nombre}
                    </TextStyle>
                  </div>
                  <Badge status="success">
                    {variantSelected?.variantId?.sales_channels?.online_store
                      ? "Activo"
                      : "No activo"}
                  </Badge>
                </div>
              </div>
            </Card>

            <Card title="Variantes">
              <div className="mt-6" />
              <OptionList
                options={articuloDB.variants.map(
                  ({
                    option1 = "",
                    option2 = "",
                    option3 = "",
                    option4 = "",
                    option5 = "",
                    option6 = "",
                    option7 = "",
                    option8 = "",
                    option9 = "",
                    option10 = "",
                    variantId: { _id: variantId, imagen = [] },
                  }) => ({
                    label: (
                      <div className="flex items-center">
                        <Thumbnail
                          source={
                            getImageVideoUrl(imagen, "image", false) ||
                            "/Default Photo.png"
                          }
                          size="small"
                        />
                        <div className="ml-4">
                          <TextStyle>
                            {validateName([
                              option1,
                              option2,
                              option3,
                              option4,
                              option5,
                              option6,
                              option7,
                              option8,
                              option9,
                              option10,
                            ])}
                          </TextStyle>
                        </div>
                      </div>
                    ),
                    value: variantId,
                  })
                )}
                onChange={handleSelectVariant}
                selected={variantSelected.variantId._id}
              />
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card title="Opciones" sectioned>
              <div className="flex items-start">
                <div className="flex flex-col w-full">
                  <FormLayout>
                    {articuloDB.options.map((option, index) => (
                      <TextField
                        key={index}
                        label={option.name}
                        value={variantSelected["option" + (index + 1)]}
                        onChange={() => {}}
                        disabled
                      />
                    ))}
                  </FormLayout>
                </div>
                <div className="ml-8 flex flex-col items-center">
                  <div
                    className="relative mb-2 cursor-pointer border-2 rounded flex overflow-hidden border-gray-400 justify-center"
                    style={{ height: 160, width: 160 }}
                  >
                    <img
                      onClick={onToggleImageModal}
                      src={
                        getImageVideoUrl(
                          variantSelected?.variantId?.imagen,
                          "image",
                          false
                        ) || "/Default Photo.png"
                      }
                      className="static-image self-center"
                      alt={variantSelected?.variantId?.nombre}
                    />
                  </div>
                  <Button onClick={onToggleImageModal} plain>
                    Cambiar imagen
                  </Button>
                </div>
              </div>
            </Card>

            <Card
              sectioned
              title="Precio"
              actions={[{ content: "Actualizar", onAction: sincronizar }]}
            >
              <DataTable
                headings={["Lista precio", "Precio", "Moneda"]}
                columnContentTypes={["text", "numeric", "numeric"]}
                rows={variantSelected.variantId?.precios?.map((item) => {
                  return [
                    item?.nombre_precio || "",
                    "$" +
                      Number(item?.precio || 0).toLocaleString("en", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }),
                    item?.moneda || "",
                  ];
                })}
              />
            </Card>

            <Card title="Inventario">
              <Card.Section>
                <Stack distribution="fill" wrap spacing="loose">
                  <TextField
                    disabled
                    label="SKU (Clave del producto)"
                    type="text"
                    value={variantSelected.variantId.sku}
                    onChange={() => {}}
                  />
                  <TextField
                    disabled
                    label="Código de barras (ISBN, UPC, GTIN, etc.)"
                    type="text"
                    value={variantSelected.variantId.codigo_barra}
                    onChange={() => {}}
                  />
                </Stack>
                <div className="my-4" />
                <Checkbox
                  disabled={isEditing}
                  label="Continuar vendiendo cuando se haya agotado"
                  onChange={(value) =>
                    handleChangeArticle(value, "continuar_vendiendo")
                  }
                  checked={variantSelected.variantId.continuar_vendiendo}
                />
              </Card.Section>
              <Card.Section title="Unidades de venta">
                <FormLayout>
                  <TextStyle>
                    Se usa para vender los artículos en mínimos, máximos y
                    múltiplos de un factor de venta.
                  </TextStyle>
                  <Stack distribution="fill" wrap spacing="loose">
                    <TextField
                      label="Mínimo"
                      type="number"
                      min={factorVenta}
                      value={String(minimum)}
                      onChange={(value) => {
                        handleChangeArticle(value, "minimum");
                        setMinimum(Number(value));
                      }}
                      error={fieldsError.minimum}
                    />
                    <TextField
                      label="Máximo"
                      type="number"
                      min={0}
                      value={String(maximum)}
                      onChange={(value) => {
                        handleChangeArticle(value, "maximum");
                        setMaximum(Number(value));
                      }}
                      suffix={maximum === 0 && <Badge>Ilimitado</Badge>}
                      error={fieldsError.maximum}
                    />
                  </Stack>
                  <Stack distribution="fillEvenly" wrap spacing="loose">
                    <TextField
                      label="Factor de venta"
                      type="number"
                      min={1}
                      value={String(factorVenta)}
                      onChange={(value) => {
                        handleChangeArticle(value, "factor_venta");
                        setFactorVenta(Number(value));
                      }}
                      error={fieldsError.factor_venta}
                    />
                    <Select
                      disabled
                      label="Unidad medida"
                      options={[
                        ...new Set(
                          optionsMedida
                            .map((i) => i.label)
                            .concat([variantSelected.variantId.unidadmed])
                        ),
                      ].map((i) => ({ label: i, value: i }))}
                      onChange={() => {}}
                      value={variantSelected.variantId.unidadmed}
                    />
                  </Stack>
                </FormLayout>
              </Card.Section>
            </Card>

            <Card
              title="Disponibilidad"
              actions={[{ content: "Actualizar", onAction: sincronizar }]}
            >
              <div className="mx-8 mb-8">
                <TextStyle variation="subdued">{`${variantSelected.variantId.existencia?.length} de ${variantSelected.variantId.existencia?.length} sucursales`}</TextStyle>
              </div>
              {variantSelected.variantId.existencia?.map((ext, id) => {
                return (
                  <div key={id}>
                    <div className="w-full h-px bg-gray-400" />
                    <Card.Section>
                      <div className="flex justify-between flex-wrap">
                        <TextStyle>{ext.nombre_almacen}</TextStyle>
                        <TextStyle>
                          {Number(ext.existencia).toLocaleString("en", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </TextStyle>
                      </div>
                    </Card.Section>
                  </div>
                );
              })}
            </Card>

            <Card title="Envíos">
              <Card.Section>
                <Checkbox
                  disabled
                  label="Este es un producto físico"
                  checked={variantSelected.variantId.es_producto_fisico}
                  onChange={() => {}}
                />
              </Card.Section>
              <Card.Section title="Peso">
                <TextStyle>
                  Se usa para calcular las tarifas de envío al momento del pago.
                </TextStyle>
                <div className="mt-8">
                  <TextField
                    label="Peso (Kg)"
                    type="number"
                    min={0}
                    step={1}
                    value={String(variantSelected.variantId.peso)}
                    onChange={(value) => handleChangeArticle(value, "peso")}
                  />
                </div>
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
        <div className="mt-10">
          <PageActions
            primaryAction={{
              content: "Guardar",
              onAction: handleDiscard,
            }}
            secondaryActions={[
              {
                content: "Eliminar",
                destructive: true,
                outline: true,
                onAction: () => setShowDeleteModal(true),
              },
            ]}
          />
          {
            <DeleteModal
              active={showDeleteModal}
              title="Eliminar variante del artículo"
              body={"Esta acción no puede deshacerse."}
              handleChange={() => setShowDeleteModal(false)}
              handleDelete={deleteVariantArticle}
              isLoading={isSavingChanges}
            />
          }
        </div>
      </Page>
    </Layer>
  );
}
