import {
  Button,
  ChoiceList,
  Icon,
  Popover,
  RangeSlider,
  Stack,
  Tag,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { ChevronDownMinor, SearchMinor } from "@shopify/polaris-icons";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CollapseAction, PopoverOptions } from "..";
import ChooseComponent from "./WrapperComponent";

import _ from "lodash";
import useToggle from "../../hooks/useToggle";
import { formatTo2Digits } from "../../utils/Formats";

export default function ClienteArticulosFiltros({
  selected = [],
  filters = [],
  handleFilterChange = () => {},
  lineaArticulo = [],
  collapseOnEnter = false,
  handleLineaArticuloChange = () => {},
  marcasSelected = [],
  handleMarcasFilterChange = () => {},
  optionsFilters = {},
  articleOptions = [],
  isOpen = false,
  toggleActive = () => {},
  filterOptionsSelected = [],
  handleFilterOptionsChange = () => {},
  maxPrice = 0,
  rangeValue = [0, 0],
  setRangeValue = () => {},
  mode = "list",
  productTypes = [],
  brands = [],
  tags = [],
  options = [],
  sortByQuantity,
  loading = false,
}) {
  const deselectedLineas = useRef([]);
  const [marcas, setMarcas] = useState([]);
  const [inputSearchLines, setInputSearchLines] = useState("");
  const [itemsLines, setItemsLines] = useState([]);
  const [open, toggleOpen] = useToggle();
  const [selectedFilters, setSelectedFilters] = useState([]);

  tags = [...new Set(tags.concat(selected))];

  articleOptions = articleOptions
    .map((opt) => ({
      ...opt,
      id: {
        ...opt.id,
        values: opt.id.values
          .filter((i) => options.some(({ _id }) => _id === i))
          .map((i) => ({
            label: `${i} (${options.find(({ _id }) => _id === i).count})`,
            value: encodeURIComponent(i),
          })),
      },
    }))
    .filter((opt) => opt.id.values.length > 0);

  useEffect(() => {
    if (filters) {
      var data;
      if (sortByQuantity) {
        const newArray =
          filters
            .map((i) => ({
              ...i,
              options: i.options

                .filter(({ value }) => tags.some(({ _id }) => _id === value))
                .map((i) => ({
                  ...i,
                  count: tags.find(({ _id }) => _id === i.value).count,
                })),
            }))
            .filter((i) => i.options.length > 0) || [];

        data =
          newArray
            .map((i) => ({
              ...i,
              options: _.sortBy(i.options, ["count"])
                .reverse()
                .filter(({ value }) => tags.some(({ _id }) => _id === value))
                .map((i) => ({
                  ...i,
                  label: `${i.label} (${i.count})`,
                })),
            }))
            .filter((i) => i.options.length > 0) || [];
      } else {
        data =
          filters
            .map((i) => ({
              ...i,
              options: _.sortBy(i.options, ["name"])
                .filter(({ value }) => tags.some(({ _id }) => _id === value))
                .map((i) => ({
                  ...i,
                  label: `${i.label} (${
                    tags.find(({ _id }) => _id === i.value).count
                  })`,
                })),
            }))
            .filter((i) => i.options.length > 0) || [];
      }
    }
    setSelectedFilters(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, loading]);

  useEffect(() => {
    if (optionsFilters.useBrand) {
      if (sortByQuantity) {
        setMarcas(
          brands.map(({ _id, count }) => ({
            label: `${_id} (${count})`,
            value: encodeURIComponent(_id),
          }))
        );
      } else {
        setMarcas(
          _.sortBy(brands, ["_id"]).map(({ _id, count }) => ({
            label: `${_id} (${count})`,
            value: encodeURIComponent(_id),
          }))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsFilters.useBrand, brands]);

  useEffect(() => {
    if (optionsFilters.useProductType) {
      var data;
      if (sortByQuantity) {
        data = productTypes.map(({ _id, count }) => ({
          label: `${_id} (${count})`,
          value: _id,
        }));
      } else {
        data = _.sortBy(productTypes, ["_id"]).map(({ _id, count }) => ({
          label: `${_id} (${count})`,
          value: _id,
        }));
      }

      setItemsLines(data);
      deselectedLineas.current = data;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsFilters.useProductType, productTypes]);

  const handleRangeSliderChange = useCallback(
    (value) => {
      setRangeValue(value);
    },
    [setRangeValue]
  );

  const updateInputLines = (value) => {
    setInputSearchLines(value);
    if (!value) {
      setItemsLines(deselectedLineas.current);
      return;
    }

    const regExp = new RegExp(value, "i");
    const result = deselectedLineas.current.filter((item) =>
      item.label.match(regExp)
    );
    setItemsLines(result);
  };

  const removeTagLineas = (tag) => {
    const removeTag = lineaArticulo.filter((item) => item !== tag);
    handleLineaArticuloChange(removeTag);
  };

  const tagsLineasSelected = lineaArticulo.map((opt) => (
    <Tag key={opt} onRemove={() => removeTagLineas(opt)}>
      {opt}
    </Tag>
  ));

  const handleRemoveFilters = () => {
    handleFilterChange([]);
    handleLineaArticuloChange([]);
    handleFilterOptionsChange([]);
    handleMarcasFilterChange([]);
    updateInputLines("");
  };

  return (
    <>
      <ChooseComponent
        handleRemoveFilters={handleRemoveFilters}
        isOpen={isOpen}
        toggleActive={toggleActive}
        optionsFilters={optionsFilters}
        hasArticleOptions={articleOptions.filter((i) => i.active).length > 0}
        mode={mode}
      >
        {mode === "list" && (
          <>
            {optionsFilters.useProductType && (
              <CollapseAction
                title="Línea de artículo"
                active={collapseOnEnter}
              >
                <div className="pb-3">
                  <Stack spacing="tight" wrap>
                    {tagsLineasSelected}
                  </Stack>
                </div>

                {deselectedLineas.current.length >= 9 && (
                  <div className="">
                    <TextField
                      prefix={<Icon source={SearchMinor} />}
                      placeholder="Buscar por líneas"
                      value={inputSearchLines}
                      onChange={updateInputLines}
                    />
                  </div>
                )}

                <div
                  className={`mt-3 overflow-scroll ${
                    itemsLines.length > 0 ? "block" : "none"
                  }`}
                  style={{ maxHeight: 300 }}
                >
                  <ChoiceList
                    choices={itemsLines}
                    selected={lineaArticulo || []}
                    onChange={handleLineaArticuloChange}
                    allowMultiple
                  />
                </div>
              </CollapseAction>
            )}
            {optionsFilters.useBrand && (
              <CollapseAction title="Marcas" active={collapseOnEnter}>
                <ChoiceList
                  choices={marcas}
                  selected={marcasSelected || []}
                  onChange={handleMarcasFilterChange}
                  allowMultiple
                />
              </CollapseAction>
            )}
            {optionsFilters.usePrice && (
              <CollapseAction title="Precio" active={collapseOnEnter}>
                <TextStyle>
                  {formatTo2Digits(rangeValue[0], true)} -{" "}
                  {formatTo2Digits(rangeValue[1], true)}
                </TextStyle>
                <RangeSlider
                  output={true}
                  label="Precio"
                  labelHidden
                  value={rangeValue}
                  prefix="$"
                  min={0}
                  max={maxPrice > 0 ? maxPrice : 1000}
                  step={1}
                  onChange={handleRangeSliderChange}
                />
              </CollapseAction>
            )}
            {articleOptions
              .filter((i) => i.active)
              .map((opt, idx) => (
                <CollapseAction
                  title={opt?.id?.name}
                  key={idx}
                  active={collapseOnEnter}
                >
                  <ChoiceList
                    choices={opt.id?.values?.map(({ label, value }) => ({
                      label,
                      value,
                    }))}
                    selected={filterOptionsSelected}
                    onChange={handleFilterOptionsChange}
                    allowMultiple
                  />
                </CollapseAction>
              ))}

            {selectedFilters.map(({ title, options }, idx) => (
              <CollapseAction title={title} key={idx} active={collapseOnEnter}>
                <ChoiceList
                  choices={options}
                  selected={selected}
                  onChange={handleFilterChange}
                  allowMultiple
                />
              </CollapseAction>
            ))}
          </>
        )}
        {mode === "inline" && (
          <div className="flex flex-row my-2">
            <p style={{ fontSize: 14 }}>Filtros:</p>
            {optionsFilters.useProductType && (
              <PopoverOptions
                title="Tipo de producto"
                handleChange={handleLineaArticuloChange}
                handleClear={() => handleLineaArticuloChange([])}
                options={itemsLines}
                selectedOptions={lineaArticulo}
              >
                {deselectedLineas.current.length >= 9 && (
                  <div className="">
                    <TextField
                      prefix={<Icon source={SearchMinor} />}
                      placeholder="Buscar por líneas"
                      value={inputSearchLines}
                      onChange={updateInputLines}
                    />
                  </div>
                )}
              </PopoverOptions>
            )}
            {optionsFilters.useBrand && (
              <PopoverOptions
                title="Marca"
                handleChange={handleMarcasFilterChange}
                handleClear={() => handleMarcasFilterChange([])}
                options={marcas}
                selectedOptions={marcasSelected}
              />
            )}
            {optionsFilters.usePrice && (
              <>
                <Popover
                  activator={
                    <Button
                      plain
                      removeUnderline
                      disclosure=""
                      monochrome
                      onClick={toggleOpen}
                    >
                      <div className="flex items-center">
                        Precio
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </Button>
                  }
                  active={open}
                  onClose={toggleOpen}
                >
                  <Popover.Pane fixed sectioned>
                    <Popover.Section>
                      <div className="flex justify-between">
                        <TextStyle>Precio</TextStyle>
                      </div>
                    </Popover.Section>
                  </Popover.Pane>
                  <Popover.Pane sectioned>
                    <Popover.Section>
                      <div className="my-8 pr-8" style={{ width: 300 }}>
                        <TextStyle>
                          {formatTo2Digits(rangeValue[0], true)} -{" "}
                          {formatTo2Digits(rangeValue[1], true)}
                        </TextStyle>
                        <RangeSlider
                          labelHidden
                          value={rangeValue}
                          prefix="$"
                          min={0}
                          max={maxPrice > 0 ? maxPrice : 1000}
                          step={1}
                          onChange={handleRangeSliderChange}
                        />
                      </div>
                    </Popover.Section>
                  </Popover.Pane>
                </Popover>
              </>
            )}

            {articleOptions
              .filter((i) => i.active)
              .map((opt, idx) => (
                <PopoverOptions
                  key={idx}
                  title={opt?.id?.name}
                  handleChange={handleFilterOptionsChange}
                  // handleClear={handleFilterOptionsChange}
                  options={opt.id?.values?.map(({ label, value }) => ({
                    label,
                    value,
                  }))}
                  selectedOptions={filterOptionsSelected}
                />
              ))}
            {selectedFilters.map(({ title, options }, idx) => (
              <PopoverOptions
                key={idx}
                title={title}
                handleChange={handleFilterChange}
                handleClear={() => handleFilterChange([])}
                options={options}
                selectedOptions={selected}
              />
            ))}
          </div>
        )}
      </ChooseComponent>
    </>
  );
}
