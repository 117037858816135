import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  Button,
  Card,
  Checkbox,
  ContextualSaveBar,
  DropZone,
  Layout,
  Page,
  PageActions,
  RadioButton,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";

import {
  DeleteModal,
  HelpFooter,
  Layer,
  PreventTransitionPrompt,
} from "../../components";
import SunEditor from "suneditor-react";
import ImageZoom from "react-medium-image-zoom";

import ApiServiceComercios from "../../services/ApiServiceComercios";
import ApiServiceConfig from "../../services/ApiServiceConfig";

import { ConditionOptions } from "./ConditionOptions";
import { useDispatch } from "react-redux";
import { toggleToast } from "../../actions/InteractiveActions";
import { validImageTypes } from "../../utils/constants";
import { CollectionProducts } from "./CollectionProducts";

const initialCondition = {
  fieldName: "nombre",
  type: "equalsTo",
  fieldValue: "",
};

export default function AddCollectionScreen({ forbidden }) {
  const router = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // States to collection data
  const [collectionData, setCollectionData] = useState({
    name: "",
    description: "",
    type: "",
    matchedType: "all",
  });
  const [onlineStore, setOnlineStore] = useState(true);
  const [conditions, setConditions] = useState([]);

  // States to articles
  const [isLoadingArticles, setIsLoadingArticles] = useState(false);
  const [articlesCollection, setArticlesCollection] = useState([]);
  const [articlesConditions, setArticlesConditions] = useState([]);
  const [sortArticles, setSortArticles] = useState("nombre");
  const [articlesPagination, setArticlesPagination] = useState({
    total: 0,
    limit: 10,
    skip: 0,
  });

  // States to load files
  const [isAddingFiles, setIsAddingFiles] = useState(false);
  const [blobFile, setBlobFile] = useState([]);

  useEffect(() => {
    if (params?.id) {
      setIsEditing(true);
      setIsLoadingArticles(true);
      ApiServiceComercios.getCollectionById(params?.id, {
        limit: articlesPagination.limit,
        skip: articlesPagination.skip,
      }).then(({ collection }) => {
        setCollectionData({
          name: collection?.name,
          description: collection?.description,
          type: collection?.type,
          matchedType: collection?.matchedType,
        });
        setOnlineStore(collection?.sales_channels?.online_store);
        setConditions(collection?.conditions);
        setArticlesConditions(collection?.conditions);
        setBlobFile(collection?.files);

        setArticlesCollection(collection?.articles);
        setTotalToPagination(collection?.total);
        setIsLoadingArticles(false);
      });
    }
  }, [params, articlesPagination.limit, articlesPagination.skip]);

  useEffect(() => {
    if (
      isEditing &&
      articlesConditions.length !== 0 &&
      collectionData.type === "auto"
    ) {
      setIsLoadingArticles(true);

      const querys = {
        limit: articlesPagination.limit,
        skip: articlesPagination.skip,
        conditions: JSON.stringify(articlesConditions),
        matchedType: collectionData.matchedType,
        sales_channels: {
          online_store: onlineStore,
        },
        sort: sortArticles,
      };

      ApiServiceComercios.getCollectionArticles(querys).then(
        ({ articles, total }) => {
          setArticlesCollection(articles);
          setIsLoadingArticles(false);
          setTotalToPagination(total);
        }
      );
    }
  }, [
    collectionData.matchedType,
    collectionData.type,
    articlesConditions,
    isEditing,
    onlineStore,
    articlesPagination.limit,
    articlesPagination.skip,
    sortArticles,
  ]);

  const handleDiscard = () => router.goBack();

  const setTotalToPagination = (total) => {
    setArticlesPagination((prevState) => ({
      ...prevState,
      total,
    }));
  };

  const handleBlocking = () => {
    setIsBlocking(true);
    setIsDirty(true);
  };

  const handleChange = useCallback(
    (name, value) => {
      setCollectionData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      handleBlocking();
    },
    [setCollectionData]
  );

  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) => {
      const formData = new FormData();

      if (acceptedFiles.length > 0) {
        setIsAddingFiles(true);
        acceptedFiles.forEach((file) => {
          formData.append("files", file);
        });

        ApiServiceConfig.uploadFile(formData)
          .then(({ files = [] }) => {
            setBlobFile((state) => [...state, ...files]);
          })
          .finally(() => setIsAddingFiles(false));
        handleBlocking();
      }
    },
    [setIsAddingFiles, setBlobFile]
  );

  const handleDeleteImage = () => {
    setBlobFile([]);
    handleBlocking();
  };

  const addNewCondition = () => {
    setConditions([...conditions, initialCondition]);
    handleBlocking();
  };

  const saveCollection = () => {
    let data = {
      ...collectionData,
      conditions: collectionData.type === "manual" ? [] : conditions,
      sales_channels: {
        online_store: onlineStore,
      },
      files: blobFile,
    };

    setIsSavingChanges(true);
    setIsBlocking(false);

    isEditing ? updateCollection(data) : addCollection(data);
  };

  const addCollection = (data) => {
    ApiServiceComercios.postCollections(data).then((res) => {
      handleDiscard();
      dispatch(
        toggleToast({
          message: res.message,
        })
      );
    });
  };

  const updateCollection = (data) => {
    ApiServiceComercios.putCollection(params?.id, data).then((res) => {
      handleDiscard();
      dispatch(
        toggleToast({
          message: res.message,
        })
      );
    });
  };

  const deleteCollection = () => {
    setIsSavingChanges(true);
    ApiServiceComercios.deleteCollection(params?.id).then((res) => {
      handleDiscard();
      dispatch(
        toggleToast({
          message: res.message,
        })
      );
    });
  };

  const contextualSaveBarMarkup = isDirty && (
    <ContextualSaveBar
      message="Cambios no guardados"
      saveAction={{
        content: isEditing ? "Editar" : "Guardar",
        onAction: saveCollection,
        disabled:
          !collectionData?.name ||
          !collectionData?.type ||
          (collectionData?.type === "auto" &&
            conditions.some((item) => !item?.fieldValue)),
        loading: isSavingChanges,
      }}
      discardAction={{
        onAction: handleDiscard,
      }}
    />
  );

  return (
    <Layer
      title={`${isEditing ? "Editar" : "Crear"} coleccion`}
      forbidden={forbidden}
    >
      <Page
        title={`${isEditing ? "Editar" : "Crear"} colección`}
        breadcrumbs={[
          {
            content: "Atrás",
            onAction: handleDiscard,
          },
        ]}
      >
        <Layout>
          {contextualSaveBarMarkup}

          <PreventTransitionPrompt
            when={isBlocking}
            setIsBlocking={setIsBlocking}
            router={router}
            title="Tienes cambios sin guardar en esta página"
            message="Si abandonas esta página, perderás todos los cambios no guardados. ¿Estás seguro de que quieres salir de esta página?"
          />

          <Layout.Section>
            <Card sectioned>
              <TextField
                label="Nombre"
                value={collectionData?.name}
                onChange={(txt) => handleChange("name", txt)}
              />

              <div className={"mt-6"}>
                <TextStyle>Descripción</TextStyle>
                <SunEditor
                  lang="es"
                  height="300"
                  enable
                  setContents={collectionData.description}
                  defaultValue={collectionData.description}
                  onChange={(txt) => {
                    setCollectionData((prevState) => {
                      if (prevState.description !== txt) {
                        handleBlocking();
                      }
                      return {
                        ...prevState,
                        description: txt,
                      };
                    });
                  }}
                />
              </div>
            </Card>

            <Card title="Tipo de colección">
              <Card.Section>
                <Stack>
                  <RadioButton
                    id="manual"
                    label="Manual"
                    checked={collectionData?.type === "manual"}
                    onChange={(_, value) => handleChange("type", value)}
                    helpText="Puedes agregar un producto a una o más colecciones manuales 
                    desde la página de detalles del producto."
                  />
                  <RadioButton
                    id="auto"
                    label="Automatizada"
                    checked={collectionData?.type === "auto"}
                    onChange={(_, value) => handleChange("type", value)}
                    helpText="Los productos existentes y futuros que coincidan 
                    con las condiciones establecidas se agregarán automáticamente 
                    a esta colección."
                  />
                </Stack>
              </Card.Section>

              {collectionData?.type === "auto" && (
                <Card.Section title="CONDICIONES">
                  <div className="mb-3">
                    <TextStyle variation="subdued">
                      Los productos deben coincidir con
                    </TextStyle>
                  </div>

                  <Stack>
                    <RadioButton
                      id="all"
                      label="Todas las condiciones"
                      checked={collectionData?.matchedType === "all"}
                      onChange={(_, value) =>
                        handleChange("matchedType", value)
                      }
                    />
                    <RadioButton
                      id="any"
                      label="Cualquier condición"
                      checked={collectionData?.matchedType === "any"}
                      onChange={(_, value) =>
                        handleChange("matchedType", value)
                      }
                    />
                  </Stack>

                  <div className="mt-5">
                    <ConditionOptions
                      conditions={conditions}
                      setConditions={setConditions}
                      handleBloking={handleBlocking}
                    />
                  </div>
                  <Button onClick={addNewCondition}>
                    Agregar otra condición
                  </Button>
                </Card.Section>
              )}
            </Card>

            {isEditing && (
              <CollectionProducts
                isLoading={isLoadingArticles}
                articles={articlesCollection}
                pagination={articlesPagination}
                setPagination={setArticlesPagination}
                valueSort={sortArticles}
                handleChange={setSortArticles}
              />
            )}
          </Layout.Section>

          <Layout.Section secondary>
            <Card title="Disponibilidad de la colección" sectioned>
              <Checkbox
                label="Tienda en línea"
                checked={onlineStore}
                onChange={setOnlineStore}
              />
            </Card>

            <Card
              title="Imagen de colección"
              actions={
                blobFile.length > 0 && [
                  { content: "Eliminar", onAction: handleDeleteImage },
                ]
              }
            >
              <Card.Section>
                <ImageZoom
                  image={{
                    src: blobFile[0]?.url ?? "",
                  }}
                />

                {blobFile.length > 0 || (
                  <DropZone
                    accept={validImageTypes}
                    type="file"
                    disabled={isAddingFiles}
                    onDrop={handleDropZone}
                  >
                    <DropZone.FileUpload
                      actionTitle="Agregar imagen"
                      actionHint="o arrastra y suelta las imágenes que deseas subir"
                    />
                  </DropZone>
                )}
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>

        <PageActions
          primaryAction={{
            content: "Guardar",
            disabled:
              !collectionData?.name ||
              !collectionData?.type ||
              (collectionData?.type === "auto" &&
                conditions.some((item) => !item?.fieldValue)),
            onAction: saveCollection,
            loading: isSavingChanges,
          }}
          secondaryActions={
            isEditing && [
              {
                content: "Eliminar",
                destructive: true,
                outline: true,
                onAction: () => setShowDeleteModal(true),
              },
            ]
          }
        />

        {isEditing && (
          <DeleteModal
            active={showDeleteModal}
            title="Eliminar colección"
            body={"Esta acción no puede deshacerse."}
            handleChange={() => setShowDeleteModal(false)}
            handleDelete={deleteCollection}
            isLoading={isSavingChanges}
          />
        )}

        <HelpFooter
          title="colecciones"
          url="https://help.b2bgo.mx/articulos/colecciones"
        />
      </Page>
    </Layer>
  );
}
