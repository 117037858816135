import React, { useCallback, useEffect, useState } from "react";

import { Autocomplete, Icon } from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import useGetVendors from "../../../hooks/useGetVendors";

const VendorAutocomplete = ({
  setVendorInputValue,
  vendorInputValue,
  placeholder,
  disabled = false,
}) => {
  const { vendors, isLoadingVendors } = useGetVendors();
  const [deselectedOptions, setDeselectedOpt] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([vendorInputValue]);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState(deselectedOptions);

  useEffect(() => {
    setOptions(vendors);
    setDeselectedOpt(vendors);
    setInputValue(
      vendors.find((item) => item.value === vendorInputValue)?.label
    );
  }, [vendors, vendorInputValue]);

  const updateText = useCallback(
    (value) => {
      setInputValue(value);

      if (value === "") {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const updateSelection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = options.find((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption && matchedOption.label;
      });

      setSelectedOptions(selected);
      setInputValue(selectedValue[0]);
      setVendorInputValue(selected[0]);
    },
    [options, setVendorInputValue]
  );

  const textField = (
    <Autocomplete.TextField
      clearButton
      onClearButtonClick={() => setInputValue("")}
      onChange={updateText}
      value={inputValue}
      prefix={<Icon source={SearchMinor} color="inkLightest" />}
      placeholder={placeholder}
      disabled={disabled}
    />
  );

  return (
    <div style={{ marginBottom: "0.5rem" }}>
      <Autocomplete
        options={options}
        selected={selectedOptions}
        textField={textField}
        loading={isLoadingVendors}
        onSelect={updateSelection}
      />
    </div>
  );
};
export default VendorAutocomplete;
