import React, { useEffect, useState } from "react";
import { Autocomplete } from "@shopify/polaris";
import ApiServiceComercios from "../../services/ApiServiceComercios";

export const AutocompleteCondition = ({
  index,
  value,
  handleChange,
  typeCondition,
}) => {
  const [options, setOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);

  useEffect(() => {
    const getSevices = {
      marca: getProductBrands,
      linea_articulo: getProductsTypes,
      etiquetas: getProductTags,
    };

    return getSevices[typeCondition]();
  }, [typeCondition]);

  const getProductBrands = () => {
    ApiServiceComercios.getBrands().then(({ brands }) => {
      const data = brands.map((item) => ({
        label: item?.name,
        value: item?.name,
      }));

      setOptions(data);
      setDeselectedOptions(data);
    });
  };

  const getProductsTypes = () => {
    ApiServiceComercios.obtenerLineas().then(({ lineas }) => {
      const data = lineas.map((item) => ({
        label: item.nombre,
        value: item.nombre,
      }));

      setOptions(data);
      setDeselectedOptions(data);
    });
  };

  const getProductTags = () => {
    ApiServiceComercios.getTags({ module: "Artículos" }).then(({ tags }) => {
      const data = tags.map((item) => ({
        label: item?.name,
        value: item?.name,
      }));

      setOptions(data);
      setDeselectedOptions(data);
    });
  };

  const updateSelection = (val) => {
    handleChange(index, "fieldValue", val);

    if (val === "") {
      setOptions(deselectedOptions);
      return;
    }

    const filterRegex = new RegExp(val, "i");
    const resOption = deselectedOptions.filter((option) =>
      option.label.match(filterRegex)
    );

    setOptions(resOption);
  };

  const autocompleteText = (
    <Autocomplete.TextField
      key={index}
      value={value}
      onChange={(txt) => updateSelection(txt)}
    />
  );

  return (
    <Autocomplete
      key={index}
      options={options}
      selected={[value]}
      textField={autocompleteText}
      onSelect={(val) => updateSelection(val[0])}
    />
  );
};
