import { b2bgoApiaxios, b2bgoAuthaxios } from "../utils/Axios";

const ApiServiceConfig = {
  API_ADMIN_CONFIG:
    process.env.NODE_ENV === "development" ? "3004" : "admin-config",

  /**
   * Admin Config
   */

  async uploadFile(data) {
    const method = "POST";
    const url = "/configuracion/files";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  /**
   * Obtiene el listado de los modulos
   */
  async getModules() {
    const method = "POST";
    const url = "/modules";
    return this.makeAuthorizedRequest({ method, url });
  },

  async createIncident(caso, formData) {
    const method = "POST";
    const url = `/casos`;
    const data = formData ? formData : caso;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  makeQueryParams(params) {
    let queries = "";
    for (var key in params) {
      queries += `${key}=${params[key]}&`;
    }
    return queries;
  },

  async makeRequest(requestData = {}) {
    try {
      let res = await b2bgoApiaxios({
        ...requestData,
        api_uri: this.API_ADMIN_CONFIG,
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  async makeAuthorizedRequest(requestData = {}) {
    try {
      let res = await b2bgoAuthaxios({
        ...requestData,
        api_uri: this.API_ADMIN_CONFIG,
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  //Crear sugerencia
  async createSuggestion(caso, formData) {
    const method = "POST";
    const url = "/mejoras";
    const data = formData ? formData : caso;
    return this.makeAuthorizedRequest({ method, url, data });
  },
};
export default ApiServiceConfig;
