import React, { useState } from 'react'
import { Card, Layout, Page, TextStyle } from '@shopify/polaris'
import { HelpFooter, InitializeModal, Layer } from '../../components'
import { useCallback } from 'react';
import ApiServiceComercios from '../../services/ApiServiceComercios';
import { useDispatch } from 'react-redux';
import { toggleToast } from '../../actions/InteractiveActions';

export default function UtilieriasScreen({ forbidden }) {
   const [isOpen, setIsOpen] = useState(false);
   const dispatch = useDispatch();
   const [isLoading, setIsLoading] = useState(false);

   const handleChange = useCallback(() => setIsOpen(!isOpen), [isOpen]);

   function handleInitialize(state) {
      setIsLoading(true);
      ApiServiceComercios.initializeDatabase(state)
         .then(({ message }) => {
            dispatch(toggleToast({ message }));
            handleChange();
         }).catch((err) => {
         }).finally(() => {
            setIsLoading(false);
         });
   }

   return (
      <Layer title="Utilerías" forbidden={forbidden}>
         <Page
            title="Utilerías"
            breadcrumbs={[{ content: "Configuración", url: "/admin/configuracion" }]}
         >
            <Layout>
               <Layout.AnnotatedSection
                  title="Inicializar información"
                  description="Elimina todos los catálogos, documentos,  las estadísticas de venta y configuraciones."
               >
                  <Card sectioned primaryFooterAction={{ content: "Inicializar", destructive: true, onAction: handleChange }}>
                     <TextStyle>
                        Los datos de tu cuenta se eliminarán y no podrás recuperarlos.
                     </TextStyle>
                     {isOpen && (
                        <InitializeModal
                           active={isOpen}
                           handleChange={handleChange}
                           handleInitialize={handleInitialize}
                           isLoading={isLoading}
                        />
                     )}
                  </Card>
               </Layout.AnnotatedSection>
            </Layout>
            <HelpFooter
          title="utilerías"
          url="https://help.b2bgo.mx/configuracion/utilerias"
        />
         </Page>
      </Layer>
   )
}
