import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Icon,
  Modal,
  TextStyle,
  EmptyState,
  Scrollable,
  Checkbox,
  TextField,
} from "@shopify/polaris";
import { SearchMajor } from "@shopify/polaris-icons";
import { Table } from "antd";
import ApiServiceComercios from "../../../services/ApiServiceComercios";
import { SkeletonTable } from "../..";

export default function LineaArticuloModal(props) {
  const [query, setQuery] = useState("");
  const [lineas, setLineas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedLineas, setSelectedLineas] = useState([]);
  const deselectedOptions = useRef([]);

  useEffect(() => {
    ApiServiceComercios.obtenerLineas()
      .then(({ lineas }) => {
        const newLineas = lineas.filter((i) => !props.selected.includes(i._id));

        deselectedOptions.current = newLineas;

        setLineas(newLineas);
      })
      .finally(() => setIsLoading(false));
    return () => null;
  }, [props.selected]);

  function updateText(value) {
    setQuery(value);

    if (value === "") {
      setLineas(deselectedOptions.current);
      return;
    }
    const filterRegex = new RegExp(value, "i");
    const resultOptions = deselectedOptions.current.filter((option) =>
      option.nombre.match(filterRegex)
    );
    setLineas(resultOptions);
  }

  function addLineas() {
    props.onAddLinea(selectedLineas);
    props.togleModal();
  }

  function handleSelect(selected, val) {
    if (selected) {
      const linea = lineas.find((ar) => ar._id === val);
      setSelectedLineas((state) => [...state, linea]);
    } else {
      setSelectedLineas(selectedLineas.filter((ar) => ar._id !== val));
    }
  }

  const textField = (
    <TextField
      onChange={updateText}
      prefix={<Icon source={SearchMajor} />}
      value={query}
      placeholder="Buscar tipos de producto"
    />
  );

  const loadingState = isLoading ? (
    <SkeletonTable headingsCount={4} rowsCount={3} />
  ) : null;

  const emptyStateMarkup =
    !isLoading && lineas.length === 0 ? (
      <EmptyState
        heading="¡Oops! no hay tipos de producto"
        image="/emptystate-files.png"
      />
    ) : undefined;

  return (
    <Modal
      title="Agregar línea de producto"
      open={props.isActive}
      onClose={props.togleModal}
      secondaryActions={[{ content: "Cancelar", onAction: props.togleModal }]}
      primaryAction={{
        content: "Agregar al cliente",
        onAction: addLineas,
        disabled: selectedLineas.length === 0,
      }}
    >
      <Scrollable style={{ height: "100%" }}>
        <div className="my-4 products-modal">
          <div className="mx-4">{textField}</div>
          {loadingState}
          {!isLoading && (
            <div className="mt-8 ant-table-styles">
              <Table
                onRow={(record) => ({
                  onClick: () => {
                    handleSelect(
                      !Boolean(
                        selectedLineas.some((i) => i._id === record._id)
                      ),
                      record._id
                    );
                  },
                })}
                rowKey="_id"
                dataSource={lineas}
                scroll={{ scrollToFirstRowOnChange: true, x: "100%" }}
                pagination={false}
                locale={{ emptyText: emptyStateMarkup }}
                columns={[
                  {
                    title: "Nombre",
                    dataIndex: "nombre",
                    render: (nombre, record) => (
                      <div className="flex flex-row items-center pointer-events-none px-8">
                        {!record.isParent && (
                          <Checkbox
                            value={record.id}
                            checked={selectedLineas.some(
                              (i) => i._id === record._id
                            )}
                            onChange={() => {}}
                          />
                        )}
                        <div className="sm:flex flex-col md:flex-row lg:flex-row">
                          <div className="xs:flex text-xl lg:whitespace-no-wrap">
                            <TextStyle>{nombre}</TextStyle>
                          </div>
                        </div>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          )}
        </div>
      </Scrollable>
    </Modal>
  );
}

LineaArticuloModal.propTypes = {
  isActive: PropTypes.bool,
  togleModal: PropTypes.func,
  onAddLinea: PropTypes.func,
  selected: PropTypes.array,
};

LineaArticuloModal.defaultProps = {
  isActive: false,
  togleModal: () => {},
  onAddLinea: () => {},
  selected: [],
};
