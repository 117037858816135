import {
  Card,
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
} from "@shopify/polaris";
import React from "react";

export default function PaymentDetailSkeleton({ fullWidth = false }) {
  return (
    <SkeletonPage breadcrumbs fullWidth={fullWidth}>
      <Layout>
        <Layout.Section>
          <Card>
            <Card.Section>
              <SkeletonDisplayText size="small" />
              <div className="my-10" />
              <SkeletonBodyText lines={2} />
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
          <Card>
            <Card.Section>
              <SkeletonBodyText lines={2} />
              <div className="my-10" />
              <SkeletonDisplayText size="small" />
            </Card.Section>
          </Card>
          <Card subdued>
            <Card.Section subdued>
              <SkeletonDisplayText size="small" />
            </Card.Section>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <Card.Section>
              <SkeletonBodyText lines={4} />
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}
