import { useState, useEffect, useCallback } from "react";
import useGetElementSizes from "../../hooks/useGetElementSizes";
import "./SectionsStyled.css";
import _ from "lodash";

export default function BannerImageStyled({ section }) {
  const [isMobile, setIsMobile] = useState(false);
  const [{ properties }, setSection] = useState({ properties: {} });
  const { measuredRef, width } = useGetElementSizes();

  const containerSize = {
    small: "220px",
    medium: "360px",
    large: "420px",
  };

  useEffect(() => {
    setSection(section);
  }, [section]);

  useEffect(() => {
    setIsMobile(width <= 768);
  }, [width]);

  const getStyles = useCallback(
    (field) => {
      const styles = {
        "text-banner-container": {
          background: properties.backgroundColor,
          color: properties.textColor,
          opacity:
            Number(
              properties.opacity && !_.isNaN(properties.opacity)
                ? properties.opacity
                : 100
            ) / 100,
        },
        "banner-container": {
          top: properties.sliderHeight === "small" ? 0 : 50,
          bottom: properties.sliderHeight === "small" ? 0 : 50,
        },
        "banner-title": {
          color: properties.textColor,
        },
        "banner-button": {
          background: properties.buttonColor,
          color: properties.textButtonColor,
        },
        upToLeft: {
          justifyContent: "flex-start",
          alignItems: "flex-start",
        },
        upToCenter: {
          justifyContent: "flex-start",
          alignItems: "center",
        },
        upToRight: {
          justifyContent: "flex-start",
          alignItems: "flex-end",
        },
        centerToLeft: {
          justifyContent: "center",
          alignItems: "flex-start",
        },
        centerToCenter: {
          justifyContent: "center",
          alignItems: "center",
        },
        centerToRight: {
          justifyContent: "center",
          alignItems: "flex-end",
        },
        bottomToLeft: {
          justifyContent: "flex-end",
          alignItems: "flex-start",
        },
        bottomToCenter: {
          justifyContent: "flex-end",
          alignItems: "center",
        },
        bottomToRight: {
          justifyContent: "flex-end",
          alignItems: "flex-end",
        },
      };

      return styles[field];
    },
    [properties]
  );

  const BannerText = () => {
    return (
      <div
        className="text-banner-container relative flex"
        style={{
          ...getStyles("banner-container"),
        }}
      >
        <div
          className="absolute inset-0"
          style={{ ...getStyles("text-banner-container") }}
        />
        <div
          className="flex justify-center flex-col z-10 m-10"
          style={{
            width: "90%",
            height: "90%",
            ...getStyles(properties.position),
          }}
        >
          <p
            className="banner-title z-10"
            style={{ ...getStyles("banner-title") }}
          >
            {properties.title}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div
      className="flex border-b border-gray-200"
      style={{ height: isMobile && properties.hideOnMobile ? 1 : "auto" }}
      ref={measuredRef}
    >
      <div className="flex justify-cente w-full">
        <div
          className="flex justify-center w-full text-center"
          style={{
            display: isMobile && properties.hideOnMobile ? "none" : "flex",
            minHeight: containerSize[properties.sliderHeight],
            height: containerSize[properties.sliderHeight],
            maxWidth: "100vw",
          }}
        >
          <div className="h-full flex relative w-full">
            {properties?.src && (
              <img
                className="object-cover w-full"
                src={properties?.src ? properties?.src : ""}
                alt={properties?.title}
              />
            )}
            <BannerText />
          </div>
        </div>
      </div>
    </div>
  );
}
