import { Page, Layout } from "@shopify/polaris";
import { AnalyticsMajor } from "@shopify/polaris-icons";
import { Layer, ReportItem } from "../../components";
import "./ReportesScreenStyles.css";

const actionGroups = {
  title: "Últimos 30 días",
  icon: AnalyticsMajor,
  accessibilityLabel: "Action group label",
  actions: [
    {
      content: "Últimos 30 días",
      accessibilityLabel: "30 días",
      onAction: () => {},
    },
  ],
};

export default function ReportesScreen({ forbidden }) {
  return (
    <Layer title="Reportes" forbidden={forbidden} scrollToTop>
      <Page
        fullWidth
        title="Reportes"
        subtitle="Comparado con el periodo anterior"
        actionGroups={[actionGroups]}
      >
        <Layout>
          <ReportItem
            title="Ventas"
            subtitle="Pedidos totales en estatus facturado de los comercios a sus clientes a través del portal de B2Bgo, comparado con el mismo período anterior."
            heading="Pedidos de los últimos 30 días"
            total="$66,904.39"
            showAllAction={() => {}}
            actions={[
              {
                label: "Pedidos",
                secondary: "2,450 pedidos",
                action: () => {},
              },
              {
                label: "Ventas promedio",
                secondary: "5,679 venta promedio",
                action: () => {},
              },
            ]}
          />
          <ReportItem
            title="Comercios"
            subtitle="Cantidad de comercios activos en B2Bgo, adquisición y venta promercio."
            heading="Comercios activos en los ultimos 30 dias"
            total="197"
            showAllAction={() => {}}
            actions={[
              {
                label: "Nuevos comercios",
                secondary: "5 comercios",
                action: () => {},
              },
              {
                label: "Ventas por comercio",
                secondary: "197 clientes",
                action: () => {},
              },
            ]}
          />
        </Layout>
      </Page>
    </Layer>
  );
}
