import React, { useCallback, useState, useEffect, useRef } from "react";
import { Page, Stack } from "@shopify/polaris";
import { AnalyticsMajor } from "@shopify/polaris-icons";
import moment from "moment";
import jwt from "jsonwebtoken";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../reducers";
import "./DashboardClienteScreenStyles.css";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import {
  Layer,
  ReportSheet,
  TodayMetrics,
  // ItemButton,
  AnuncioSkeleton,
  AnuncioCard,
  FooterCliente,
} from "../../components";
import { clearAll } from "../../actions/AuthActions";
import ApiServiceReportes from "../../services/ApiServiceReportes";
import { NumberFormat, formatTo2Digits } from "../../utils/Formats";
import normalizeById from "../../utils/NormalizedById";
import GroupDataByDate from "../../utils/GroupDataByDate";
import useAbility from "../../hooks/useAbility";

const classes =
  "px-8 py-16 flex flex-col text-center justify-center items-center";

export default function DashboardClienteScreeen({ forbidden }) {
  const dispatch = useDispatch();
  const isForbiddenReadEstadoCuenta = useAbility("read", "Estado de cuenta");
  const isForbiddenReadPedidos = useAbility("read", "Pedidos");
  const { name = "", last_name = "", nombre = "" } = useSelector(getUserInfo);
  const [sheetActive, setSheetActive] = useState(false);
  const [anuncios, setAnuncios] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingReport, setIsLoadingReport] = useState(true);
  const [activeFilter, setActiveFilter] = useState("Hoy");
  const [articulos, setArticulos] = useState([]);
  const [limiteCredito, setLimiteCredito] = useState({});
  const [pedidos, setPedidos] = useState([]);
  const [grouped, setGrouped] = useState([]);
  const [lastSync, setLastSync] = useState("");
  const [selectedDates, setSelectedDates] = useState({
    start: moment().format(),
    end: moment().format(),
  });

  const [usePaymentsInDueBalance, setUsePaymentsInDueBalance] = useState(false);

  const { _id } = useSelector((state) => state.Auth.userData);
  const grant_type = useSelector((state) => state.Auth.grant_type);
  const selectedDatesRef = useRef();

  let token = useSelector((state) => state.Auth.access_token);

  useEffect(() => {
    selectedDatesRef.current = selectedDates;
  });

  useEffect(() => {
    if (token) {
      jwt.verify(token, process.env.REACT_APP_SECRET_SEED, (error, decoded) => {
        if (error) {
          dispatch(clearAll());
        }
      });
    }

    return () => null;
  }, [_id, dispatch, grant_type, token]);

  useEffect(() => {
    ApiServiceComercios.obtenerAnunciosClientes()
      .then((result) => {
        if (result.ok) {
          setAnuncios(result.anuncios);
        }
      })
      .finally(() => setIsLoading(false));
    ApiServiceReportes.obtenerDashboardCliente({
      fecha: selectedDatesRef.current,
    })
      .then(({ articulos, cliente, pedidos, lastSync }) => {
        const { limite_credito, moneda } = cliente || {};
        setLimiteCredito({ ...limite_credito, moneda });
        setArticulos(articulos);
        setPedidos(pedidos);
        let data = normalizeById(pedidos, "_id");
        let group = GroupDataByDate(data);
        setGrouped(group);
        setLastSync(lastSync);
      })
      .finally(() => setIsLoadingReport(false));

    ApiServiceComercios.getPaymentConfigurationDetails().then(
      ({ usePaymentsInDueBalance }) => {
        setUsePaymentsInDueBalance(usePaymentsInDueBalance);
      }
    );
  }, []);

  const toggleSheetActive = useCallback(
    () => setSheetActive((sheetActive) => !sheetActive),
    []
  );

  function selectDates(values) {
    setSelectedDates(values);
    setIsLoadingReport(true);
    ApiServiceReportes.obtenerDashboardCliente({ fecha: values })
      .then(({ articulos, cliente: { limite_credito }, pedidos }) => {
        setLimiteCredito(limite_credito);
        setArticulos(articulos);
        setPedidos(pedidos);
        let data = normalizeById(pedidos, "_id");
        let group = GroupDataByDate(data);
        setGrouped(group);
      })
      .finally(() => setIsLoadingReport(false));
  }

  function onRead(id) {
    setAnuncios((s) => s.filter((s) => s._id !== id));
  }

  return (
    <Layer title="Inicio" forbidden={forbidden}>
      <div className="flex flex-col justify-between h-full">
        <Page
          title={`Buen día, ${nombre} ${String(name).trim()} ${String(
            last_name
          ).trim()}.`}
          subtitle={
            !lastSync
              ? "Este es tu estado de cuenta"
              : `Este es tu estado de cuenta, actualizado el ${moment(
                  lastSync
                ).format("D")} de ${moment(lastSync).format(
                  "MMMM"
                )} a las ${moment(lastSync).format("HH:mm a")}.`
          }
          secondaryActions={[
            {
              icon: AnalyticsMajor,
              content: "Ver reporte",
              target: "REMOTE",
              onAction: toggleSheetActive,
            },
          ]}
          fullWidth
        >
          <ReportSheet
            isLoading={isLoadingReport}
            setSelectedDates={selectDates}
            subtitle={`${moment(selectedDates.start).format(
              "MMM D"
            )} - ${moment(selectedDates.end).format("D")}`}
            graphics={[
              {
                data: grouped.map((i) => ({
                  name: moment(i.date).format("MMM D"),
                  pedidos: i.collection.length,
                  total: i.collection
                    .map((i) => i.total)
                    .reduce((total, cu) => total + cu, 0),
                })),
                title: "Total de compras",
                total: `$${Number(
                  pedidos
                    .map((i) => i.total)
                    .reduce((total, cu) => total + cu, 0) || 0
                ).toLocaleString("en", NumberFormat)}`,
                value: `${pedidos.length} pedidos`,
                color: "greenCake",
                variant: "number",
                dataKey: "pedidos",
                // subtitle: "Sep 1-30",
              },
            ]}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            sheetActive={sheetActive}
            toggleSheetActive={toggleSheetActive}
            title="Artículos principales"
            topArticulos={articulos}
          />
          <div className="mx-8 sm:mx-0 md:mx-0 mb-8">
            <Stack distribution="fillEvenly" wrap spacing="none">
              <TodayMetrics
                classes={classes}
                heading="Saldo total"
                isLoading={isLoadingReport}
                metric={`$${Number(
                  limiteCredito?.saldo?.actual || 0
                ).toLocaleString("en", NumberFormat)} ${
                  limiteCredito?.moneda?.moneda || ""
                }`}
                redirect={
                  !isForbiddenReadPedidos
                    ? {
                        title: "Agregar pedido",
                        url: "/cliente/pedidos/agregar",
                      }
                    : null
                }
              />
              <TodayMetrics
                classes={classes}
                heading={`Vencido ${limiteCredito?.vencido?.prctjactual || 0}%`}
                isLoading={isLoadingReport}
                metric={`${formatTo2Digits(
                  Number(limiteCredito?.vencido?.actual || 0)
                )} ${limiteCredito?.moneda?.moneda || ""}`}
                redirect={
                  usePaymentsInDueBalance &&
                  Number(limiteCredito?.vencido?.actual) > 0
                    ? {
                        title: "Pagar",
                        url: '/cliente/facturas?status=["Atrasado"]',
                      }
                    : undefined
                }
              />
              <TodayMetrics
                classes={classes}
                heading={`Por vencer ${
                  limiteCredito?.porvencer?.prctjactual || 0
                }%`}
                isLoading={isLoadingReport}
                metric={`$${Number(
                  limiteCredito?.porvencer?.actual || 0
                ).toLocaleString("en", NumberFormat)} ${
                  limiteCredito?.moneda?.moneda || ""
                }`}
                redirect={
                  !isForbiddenReadEstadoCuenta
                    ? {
                        title: "Ver estado de cuenta",
                        url: "/cliente/estado-de-cuenta",
                      }
                    : null
                }
              />
            </Stack>
          </div>
          {isLoading &&
            Array.from(Array(3).keys()).map((item) => {
              return (
                <div key={item}>
                  <AnuncioSkeleton />
                </div>
              );
            })}
          {anuncios.map((anuncio) => {
            return (
              <div key={anuncio._id}>
                <AnuncioCard anuncio={anuncio} onRead={onRead} />
              </div>
            );
          })}
        </Page>
        <FooterCliente />
      </div>
    </Layer>
  );
}
