import React from 'react';
import { useSelector } from 'react-redux';
import { getPreliminares, isLoadingPreliminares, getPaginationPreliminares } from '../../reducers';

export default function PreliminaresContainer({ children }) {
   const isLoading = useSelector(isLoadingPreliminares);
   const preliminares = useSelector(getPreliminares)
      .map(item => ({
         ...item,
         id: item._id,
         articulos: item.articuloFlete && item.articuloFlete.nombre
            ? item.articulos.filter(i => i.articulo._id !== item.articuloFlete.id)
            : item.articulos.filter(i => i.articulo && i.articulo.nombre.indexOf("Flete") < 0)
      }));

   const pagination = useSelector(getPaginationPreliminares);

   return <>{children({ isLoading, preliminares, pagination })}</>
}
