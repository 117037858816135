import React, { useEffect, useState } from "react";
import {
  Button,
  Icon,
  ResourceItem,
  ResourceList,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";
import {
  ChevronLeftMinor,
  CollectionsMajor,
  ProductsMajor,
} from "@shopify/polaris-icons";
import ApiServiceComercios from "../../../services/ApiServiceComercios";
import { getImageVideoUrl } from "../../../utils/productValidators";

export const EnlaceDetail = ({
  enlace = "",
  queryInput = "",
  goBack,
  handleText,
  closeFocus,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [options, setOptions] = useState([
    {
      label: "",
      value: "",
      media: "",
    },
  ]);

  useEffect(() => {
    const getServices = {
      articulos: getArticles,
      colecciones: getCollections,
    };

    if (getServices?.[enlace]) {
      return getServices[enlace](queryInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enlace, queryInput]);

  const getArticles = (query) => {
    setIsLoading(true);
    ApiServiceComercios.obtenerArticulos({
      query,
      existencia: -1,
    }).then(({ articulos }) => {
      let arr = articulos.map((item) => ({
        label: item?.nombre,
        value: item?.handle,
        media: (
          <Thumbnail
            size="small"
            source={
              getImageVideoUrl(item?.imagen, "image", false) ||
              "/Default Photo.png"
            }
          />
        ),
      }));

      let allArticles = [
        {
          label: (
            <div className="flex items-center gap-4">
              <Icon source={ProductsMajor} color="base" />
              Todos los artículos
            </div>
          ),
          value: "",
        },
      ];

      setOptions(allArticles.concat(arr));
      setIsLoading(false);
    });
  };

  const getCollections = (query) => {
    setIsLoading(true);
    ApiServiceComercios.getCollections({ query }).then(({ collections }) => {
      let arr = collections.map((item) => ({
        label: item?.name,
        value: item?.handle,
        media: (
          <Thumbnail
            size="small"
            source={
              getImageVideoUrl(
                item?.files?.map(({ url }) => url),
                "image",
                false
              ) || "/Default Photo.png"
            }
          />
        ),
      }));

      let allCollections = [
        {
          label: (
            <div className="flex items-center gap-4">
              <Icon source={CollectionsMajor} color="base" />
              Todas las colecciones
            </div>
          ),
          value: "",
        },
      ];

      setOptions(allCollections.concat(arr));
      setIsLoading(false);
    });
  };

  const changeEnlace = (val) => {
    let opts = {
      articulos: "products",
      colecciones: "collections",
    };

    let path = `/${opts[enlace]}/${val}`;
    handleText(path);
    closeFocus();
    goBack();
  };

  return (
    <div className="p-3">
      <div className="flex justify-between">
        <div>
          <Button icon={ChevronLeftMinor} plain onClick={goBack}>
            Atras
          </Button>
        </div>
        <div className="ml-3">
          <TextStyle variation="subdued">
            {options.length - 1} resultados
          </TextStyle>
        </div>
      </div>

      <ResourceList
        resourceName={{
          singular: enlace,
          plural: enlace,
        }}
        items={options}
        loading={isLoading}
        renderItem={({ label, value, media }) => {
          return (
            <ResourceItem id={value} onClick={() => changeEnlace(value)}>
              <div className="flex items-center gap-3">
                <div>{media || null}</div>
                <div>
                  <TextStyle>{label}</TextStyle>
                </div>
              </div>
            </ResourceItem>
          );
        }}
      />
    </div>
  );
};
