import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Avatar,
  Button,
  Card,
  ChoiceList,
  EmptyState,
  FormLayout,
  Icon,
  Layout,
  Link,
  Page,
  Select,
  TextField,
  TextStyle,
  Loading,
  Modal,
  ContextualSaveBar,
  Banner,
  List,
} from "@shopify/polaris";
import _ from "lodash";
import moment from "moment";
import { SearchMinor } from "@shopify/polaris-icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { invitarClienteComercio } from "../../actions/ComerciosActions";
import { obtenerClienteComercio } from "../../actions/ClientesActions";
import { toggleToast } from "../../actions/InteractiveActions";
import {
  ArticleSimple,
  Layer,
  Timeline,
  LimitCreditCard,
  CustomBadge,
  ComercioDetalleSkeleton,
  EditClienteEstatusModal,
  EditContactDirectionModal,
  TagsAutocomplete,
  ClientsContactCard,
  HelpFooter,
} from "../../components";
import { NumberFormat } from "../../utils/Formats";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import useDebounce from "../../services/useDebounce";
import { clearArticulos } from "../../actions/ArticulosActions";
import { getPaginationClientes, getUserInfo } from "../../reducers";
import ArticleModal from "../../components/common/ArticleModal";
import ArticleLineModal from "../../components/common/ArticleLineModal";
import { extractInitials } from "../../utils/stringModifiers";
import useToggle from "../../hooks/useToggle";

const estatus = {
  Activo: {
    nombre: "Activo",
    tipo: 1,
  },
  Inactivo: {
    nombre: "Inactivo",
    tipo: 2,
  },
  Invitado: {
    nombre: "Invitado",
    tipo: 3,
  },
};

const aplicaciones = [
  { label: "Todos", value: 1 },
  { label: "Tipos de productos específicos", value: 2 },
  { label: "Productos en específico", value: 3 },
];

export default function ClienteDetalleScreen({ forbidden }) {
  const router = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [showHistorial, setShowHistorial] = useState(true);
  const [isInviting, setIsInviting] = useState(false);
  const deselectedOptions = useRef([
    { value: "Ferretería", label: "Ferretería" },
    { value: "Buen cliente", label: "Buen cliente" },
    { value: "Recomendación", label: "Recomendación" },
  ]);
  const [segmento, setSegmento] = useState([1]);
  const [almacenesDB, setAlmacenesDB] = useState([]);
  const pageState = useSelector((state) => state.Clientes.allIdsDB);
  const usuario = useSelector(getUserInfo);
  const pagination = useSelector(getPaginationClientes);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValueArticle, setInputValueArticle] = useState("");
  const [inputValueProductType, setInputValueProductType] = useState("");
  const [clienteDB, setClienteDB] = useState(null);
  const [initial, setInitial] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditingCliente, setisEditingCliente] = useState(false);
  const [isEditModal, toggleClienteModal] = useToggle(false);
  const [isOpenModal, handleDirectionModal] = useToggle(false);
  const [isEditingDirection, setIsEditingDirection] = useState(false);
  const [isOpenGestion, handleGestionModal] = useToggle(false);
  const [directionEnvio, setDirectionEnvio] = useState({});
  const [isGestioning, setIsGestioning] = useState(false);
  const [isActiveLineModal, setIsActiveLineModal] = useState(true);
  const [isActiveProductoModal, setIsActiveProductoModal] = useState(true);
  const [initialSearch, setInitialSearch] = useState(true);
  const [selectedArticulos, setSelectedArticulos] = useState([]);
  const [selectedTypeArticulos, setSelectedTypeArticulos] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState([]);

  const debouncedSearchTerm = useDebounce(inputValueArticle, 900);
  const debouncedSearchTypeProduct = useDebounce(inputValueProductType, 900);

  useEffect(() => {
    setInitialSearch(false);
    setInitial(false);
    dispatch(obtenerClienteComercio(id))
      .then(({ cliente, ok }) => {
        if (ok) {
          let merged = _.unionBy(
            deselectedOptions.current,
            cliente.etiquetas.map((et) => ({ label: et, value: et })),
            "value"
          );
          const {
            articleClient: { type = 1, articles = [], articlesTypes = [] },
            errors,
          } = cliente;
          setErrors(errors);
          setClienteDB({ ...cliente });
          setSegmento([type]);
          setSelectedArticulos(articles);
          setSelectedTypeArticulos(articlesTypes);

          deselectedOptions.current = merged;
          setSelectedOptions(cliente.etiquetas);
        }
      })
      .finally(() => setIsLoading(false));

    ApiServiceComercios.obtenerSucursales({ permitir_registro: true }).then(
      ({ ok, sucursales }) => {
        if (ok) {
          setAlmacenesDB(
            sucursales.map((a) => ({ label: a.nombre, value: a._id }))
          );
        }
      }
    );

    return () => null;
  }, [dispatch, id]);

  useEffect(() => {
    setIsActiveProductoModal((state) => !state);

    return () => {
      setIsActiveProductoModal(false);
    };
  }, [debouncedSearchTerm]);
  useEffect(() => {
    setIsActiveLineModal((state) => !state);
  }, [debouncedSearchTypeProduct]);

  const updateTextArticle = useCallback(
    (value) => setInputValueArticle(value),
    []
  );
  const updateTextProductType = useCallback(
    (value) => setInputValueProductType(value),
    []
  );

  function handleModalChange() {
    if (!initialSearch) {
      setIsActiveProductoModal(!isActiveProductoModal);
    }
  }

  function handleModalChangeProductType() {
    if (!initial) {
      setIsActiveLineModal(!isActiveLineModal);
    }
  }

  function invitarCliente() {
    setIsInviting(true);
    dispatch(invitarClienteComercio([id]))
      .then((res) => {
        if (res.ok) {
          dispatch(toggleToast({ message: res.message }));
        }
      })
      .catch((error) => {
        if (error.response && !error.response?.data?.ok) {
          dispatch(toggleToast({ message: error.response.data.message }));
        }
      })
      .finally(() => setIsInviting(false));
  }

  function onEtiquetaChange(opts) {
    setisEditingCliente(true);
    ApiServiceComercios.actualizarCliente({ etiquetas: opts }, id)
      .then(({ ok, message }) => {
        if (ok) {
          dispatch(toggleToast({ message }));
          setSelectedOptions(opts);
        }
      })
      .catch((err) => {})
      .finally(() => setisEditingCliente(false));
  }

  function handleSave() {
    setIsSaving(true);

    const ids = selectedArticulos.map((a) => a._id);
    const withVariantsIds = selectedArticulos
      .filter((i) => i.isParent)
      .map((i) => i.variants[0].variantId._id);
    const parentsIds = selectedArticulos.map((i) => i.parentId);

    let allIds = [...ids, ...withVariantsIds, ...parentsIds].filter((i) => i);
    allIds = [...new Set(allIds)];

    const data = {
      articleClient: {
        type: segmento[0],
        articles: allIds,
        articlesTypes: selectedTypeArticulos.map((a) => a._id),
      },
    };

    ApiServiceComercios.actualizarCliente(data, id)
      .then(({ message }) => {
        dispatch(toggleToast({ message }));
      })
      .finally(() => {
        setIsSaving(false);
        setIsDirty(false);
      });
  }

  function onAlmacenChange(opt) {
    setisEditingCliente(true);
    ApiServiceComercios.actualizarCliente({ almacen: opt }, id)
      .then(({ ok, message }) => {
        if (ok) {
          dispatch(toggleToast({ message }));
          setClienteDB((state) => ({
            ...state,
            almacen: {
              nombre: almacenesDB.find((a) => a.value === opt).label,
              _id: opt,
            },
          }));
        }
      })
      .finally(() => setisEditingCliente(false));
  }

  function onRemoveArticulo(id, parentId) {
    let articulos = selectedArticulos.filter(
      (item) => item._id !== id && item._id !== parentId
    );
    setSelectedArticulos(articulos);
    setIsDirty(true);
  }

  function onRemoveArticleType(id) {
    setSelectedTypeArticulos(
      selectedTypeArticulos.filter((item) => item._id !== id)
    );
    setIsDirty(true);
  }

  function changeStatus(opt) {
    if (opt === "Invitado") {
      invitarCliente();
      setClienteDB((state) => ({ ...state, estatus: estatus[opt] }));
    } else {
      setisEditingCliente(true);
      ApiServiceComercios.actualizarCliente({ estatus: estatus[opt] }, id)
        .then(({ ok, message }) => {
          dispatch(toggleToast({ message }));
          setClienteDB((state) => ({ ...state, estatus: estatus[opt] }));
        })
        .finally(() => setisEditingCliente(false));
    }
  }

  function previousPage() {
    const prevId = pageState[pageState.indexOf(id) - 1];
    router.push(`/admin/clientes/${prevId}`);
  }

  function nextPage() {
    const nextId = pageState[pageState.indexOf(id) + 1];
    router.push(`/admin/clientes/${nextId}`);
  }

  function readDirectionEnvio(direction = {}) {
    return (
      <div className="flex flex-col">
        <TextStyle>
          {direction.direccion && direction.direccion + ", "}
          {direction.ciudad && direction.ciudad + ", "}
          {direction.estado && direction.estado + ", "}
          {direction.codigo_postal && "C.P. " + direction.codigo_postal}.
        </TextStyle>
        <TextStyle variation="subdued">{direction.telefono}</TextStyle>
      </div>
    );
  }

  function handleSaveDirection(directionEdited, addNew = false) {
    setIsEditingDirection(true);
    handleDirectionModal();
    setIsGestioning(false);
    const direction =
      clienteDB.direcciones.find(
        (i) => i.consignatario_id === directionEdited?.consignatario_id
      ) || {};
    const dirIndex = clienteDB.direcciones.findIndex(
      (i) => i.consignatario_id === directionEdited?.consignatario_id
    );
    const isEqual = _.isEqual(directionEdited, direction);
    let willUpdate = false;
    if (!isEqual) {
      willUpdate =
        String(directionEdited.consignatario).trim() ===
        String(direction?.consignatario).trim();
    }
    // Si willUpdate = true, va a actualizar el consignatario, sino va a crear un nuevo registro
    ApiServiceComercios.actualizaDireccionCliente({
      ...directionEdited,
      willUpdate,
      cliente_id: clienteDB._id,
      editDirectionName: "envío",
    })
      .then(({ consignatario_id, message }) => {
        let direcciones = clienteDB.direcciones;
        if (willUpdate) {
          direcciones[dirIndex] = directionEdited;
        } else {
          direcciones.push({
            ...directionEdited,
            consignatario_id:
              consignatario_id || directionEdited.consignatario_id,
          });
        }
        const direccionesId = {};
        direccionesId["direccionEnvioId"] = willUpdate
          ? directionEdited.consignatario_id
          : consignatario_id;
        dispatch(
          toggleToast({
            message: addNew ? "Dirección creada correctamente" : message,
          })
        );
        setClienteDB((state) => ({ ...state, direcciones, ...direccionesId }));
      })
      .finally(() => {
        setIsEditingDirection(false);
      });
  }

  function handleChangeDirectionModal(update) {
    if (update) {
      setIsGestioning(true);
      handleGestionModal();
    }

    setDirectionEnvio(
      update
        ? clienteDB.direcciones.find(
            (i) => i.consignatario_id === clienteDB.direccionEnvioId
          )
        : {
            consignatario: "",
            rfc: "",
            contacto: "",
            email: "",
            telefono: "",
            direccion: "",
            colonia: "",
            ciudad: "",
            codigo_postal: "",
            estado: "",
            pais: "México",
            usarParaEnvios: false,
            usarParaFacturar: false,
          }
    );
    handleDirectionModal();
  }

  function selectArticulos(items) {
    let merged = _.unionBy(selectedArticulos, items, "_id");
    dispatch(clearArticulos());
    setSelectedArticulos(merged);
    setIsDirty(true);
  }

  function onAddLinea(items) {
    let merged = _.unionBy(selectedTypeArticulos, items, "_id");
    setSelectedTypeArticulos(merged);
    setIsDirty(true);
  }

  const contextualSaveBar = isDirty ? (
    <ContextualSaveBar
      fullWidth
      message="Cambios sin guardar"
      discardAction={{
        content: "Descartar",
        onAction: () => router.goBack(),
        disabled: isSaving,
      }}
      saveAction={{
        content: "Guardar",
        onAction: handleSave,
        loading: isSaving,
        disabled: isSaving,
      }}
    />
  ) : null;

  if (isLoading) {
    return (
      <Layer title={`Cliente`} forbidden={forbidden}>
        <ComercioDetalleSkeleton fullWidth />
      </Layer>
    );
  }

  if (clienteDB === null) {
    return (
      <Layer title={`Cliente`} forbidden={forbidden}>
        <EmptyState
          heading="Oops! Hubo un problema al intentar obtener este cliente"
          action={{
            content: "Volver a intentar",
            onAction: () => window.location.reload(),
          }}
          secondaryAction={{
            content: "Regresar a clientes",
            onAction: () => router.goBack(),
          }}
          image="/Ad.svg"
        >
          <p>Intenta de nuevo más tarde</p>
        </EmptyState>
      </Layer>
    );
  }

  console.log(clienteDB);

  const media = (
    <Avatar
      customer={false}
      size="medium"
      name={clienteDB.nombre}
      initials={clienteDB.nombre.slice(0, 2).toUpperCase()}
    />
  );

  return (
    <Layer title={`Cliente - ${clienteDB.nombre}`} forbidden={forbidden}>
      <Page
        fullWidth
        title={clienteDB.nombre}
        breadcrumbs={[{ content: "Clientes", onAction: () => router.goBack() }]}
        primaryAction={
          clienteDB.estatus.tipo !== 1
            ? {
                content: "Invitar",
                onAction: invitarCliente,
                loading: isInviting,
                disabled:
                  !clienteDB.email ||
                  !clienteDB.almacen ||
                  isInviting ||
                  errors.length > 0,
              }
            : null
        }
        pagination={{
          hasPrevious:
            pageState.indexOf(id) !== 0 ||
            pagination.currentPage * pagination.sizePerPage !== 0,
          onPrevious: previousPage,
          nextTooltip: "Siguiente cliente",
          hasNext: pageState.indexOf(id) + 1 < pageState.length,
          onNext: nextPage,
          previousTooltip: "Cliente anterior",
        }}
      >
        {contextualSaveBar}
        {isEditModal && (
          <EditClienteEstatusModal
            hasEmail={clienteDB.email}
            isOpen={isEditModal}
            toggleModal={toggleClienteModal}
            estatus={clienteDB.estatus.nombre}
            almacen={clienteDB}
            onChange={changeStatus}
          />
        )}

        {isEditingDirection && <Loading />}

        {isOpenModal && (
          <EditContactDirectionModal
            direccionEnvio={directionEnvio}
            direccionFacturacion={{}}
            title={"envío"}
            direcciones={clienteDB?.direcciones}
            isOpen={isOpenModal}
            handleChange={handleDirectionModal}
            handleSave={handleSaveDirection}
            isNewDirection={!isGestioning}
          />
        )}
        {isOpenGestion && (
          <Modal
            title="Gestionar direcciones"
            open={isOpenGestion}
            onClose={handleGestionModal}
          >
            <Modal.Section>
              <Card sectioned>
                <FormLayout>
                  {readDirectionEnvio(
                    clienteDB.direcciones.find(
                      (i) => i.consignatario_id === clienteDB.direccionEnvioId
                    )
                  )}
                  <Button
                    plain
                    onClick={() => handleChangeDirectionModal(true)}
                  >
                    Editar dirección
                  </Button>
                </FormLayout>
              </Card>
            </Modal.Section>
          </Modal>
        )}

        {errors.length > 0 && (
          <div className="py-5">
            <Banner
              status="warning"
              title="El cliente tiene los siguientes errores"
            >
              <List>
                {errors.map((item, index) => (
                  <List.Item key={index}>{item?.message}</List.Item>
                ))}
              </List>
            </Banner>
          </div>
        )}

        <Layout>
          <Layout.Section>
            <Card>
              <Card.Section fullWidth>
                <div className="flex flex-row">
                  <div className="mx-8">{media}</div>
                  <h3 className="flex flex-col">
                    <TextStyle variation="strong">{clienteDB.nombre}</TextStyle>
                    <TextStyle variation="subdued">{clienteDB.rfc}</TextStyle>
                    <TextStyle variation="subdued">
                      Clave: {clienteDB.clave}
                    </TextStyle>
                    <TextStyle variation="subdued">
                      Registrado {moment(clienteDB.createdDate).fromNow()}
                    </TextStyle>
                  </h3>
                </div>
              </Card.Section>
              <Card.Section fullWidth>
                <div className="flex flex-row flex-wrap justify-around content-end">
                  <div className="flex flex-col text-center m-4">
                    {clienteDB.pedido ? (
                      <>
                        <Link url={`/admin/pedidos/${clienteDB.pedido._id}`}>
                          Último pedido
                        </Link>
                        <TextStyle variation="strong">
                          {moment(clienteDB.pedido.createdDate).fromNow()}
                        </TextStyle>
                      </>
                    ) : (
                      <TextStyle variation="strong">
                        Aún no hay pedidos
                      </TextStyle>
                    )}
                  </div>
                  <div className="flex flex-col text-center m-4">
                    <TextStyle variation="subdued">Ventas totales</TextStyle>
                    <TextStyle variation="strong">
                      $
                      {Number(
                        clienteDB.pedidos
                          .map((i) => i.total)
                          .reduce((total, cu) => total + cu, 0)
                      ).toLocaleString("en", NumberFormat)}
                    </TextStyle>
                    <TextStyle variation="subdued">{`${
                      clienteDB.pedidos.length
                    } ${
                      clienteDB.pedidos.length > 1 ? "pedidos" : "pedido"
                    }`}</TextStyle>
                  </div>
                  <div className="flex flex-col text-center m-4">
                    <TextStyle variation="subdued">Venta promedio</TextStyle>
                    <TextStyle variation="Strong">
                      $
                      {Number(
                        clienteDB.pedidos
                          .map((i) => i.total)
                          .reduce((total, cu) => total + cu, 0) /
                          clienteDB.pedidos.length || 0
                      ).toLocaleString("en", NumberFormat)}
                    </TextStyle>
                  </div>
                </div>
              </Card.Section>
            </Card>

            {/* Contact section */}
            <ClientsContactCard />

            {clienteDB && <LimitCreditCard data={clienteDB.limite_credito} />}

            <Card title="Artículos">
              <Card.Section>
                <FormLayout>
                  <TextStyle>Artículos que se le venden al cliente:</TextStyle>
                  <ChoiceList
                    title=""
                    titleHidden
                    choices={aplicaciones}
                    selected={segmento}
                    onChange={(value) => {
                      setIsDirty(true);
                      setSegmento(value);
                    }}
                  />
                </FormLayout>
              </Card.Section>
              {segmento[0] === 2 && (
                <>
                  <Card.Section>
                    <TextField
                      type="search"
                      onChange={updateTextProductType}
                      value={inputValueProductType}
                      prefix={<Icon source={SearchMinor} color="inkLighter" />}
                      placeholder="Buscar tipos de productos"
                      disabled={!clienteDB}
                      connectedRight={
                        <Button
                          disabled={!clienteDB}
                          onClick={handleModalChangeProductType}
                        >
                          Buscar
                        </Button>
                      }
                    />
                  </Card.Section>
                  {selectedTypeArticulos.length > 0 ? (
                    <Card.Section>
                      {selectedTypeArticulos.map((articuloType) => {
                        return (
                          <div key={articuloType._id}>
                            <ArticleSimple
                              id={articuloType._id}
                              title={articuloType.nombre}
                              onRemove={onRemoveArticleType}
                            />
                          </div>
                        );
                      })}
                    </Card.Section>
                  ) : null}
                </>
              )}

              {segmento[0] === 3 && (
                <>
                  <Card.Section>
                    <TextField
                      type="search"
                      onChange={updateTextArticle}
                      value={inputValueArticle}
                      prefix={<Icon source={SearchMinor} color="inkLighter" />}
                      placeholder="Buscar productos"
                      disabled={!clienteDB}
                      connectedRight={
                        <Button
                          disabled={!clienteDB}
                          onClick={handleModalChange}
                        >
                          Buscar
                        </Button>
                      }
                    />
                  </Card.Section>
                  {selectedArticulos.length > 0 ? (
                    <Card.Section>
                      {selectedArticulos.map((articulo) => {
                        return (
                          <div key={articulo._id}>
                            <ArticleSimple
                              articulo={articulo}
                              onRemove={onRemoveArticulo}
                            />
                          </div>
                        );
                      })}
                    </Card.Section>
                  ) : null}
                </>
              )}
            </Card>

            {isActiveProductoModal && (
              <ArticleModal
                isActive={isActiveProductoModal}
                handleChange={handleModalChange}
                selected={selectedArticulos
                  .filter((i) => !i.isParent)
                  .map((a) => a._id)}
                selectArticulos={selectArticulos}
                primaryActionTitle="Agregar al cliente"
                value={inputValueArticle}
              />
            )}

            {isActiveLineModal && (
              <ArticleLineModal
                isActive={isActiveLineModal}
                togleModal={handleModalChangeProductType}
                selected={selectedTypeArticulos.map((a) => a._id)}
                primaryActionTitle="Agregar al cliente"
                onAddLinea={onAddLinea}
                value={inputValueArticle}
              />
            )}
            <div className="my-8">
              <Timeline
                visible={showHistorial}
                onChange={setShowHistorial}
                initials={extractInitials(usuario.name, usuario.last_name)}
                parentMessageId={id}
                subtitle="Sólo tú y el resto del personal pueden ver los comentarios."
              />
            </div>
          </Layout.Section>
          <Layout.Section secondary>
            <Card fullWidth>
              <Card.Section title="Contacto">
                <div className="flex flex-col">
                  <Link>{clienteDB.email}</Link>
                  <TextStyle variation="subdued">{clienteDB.nombre}</TextStyle>
                </div>
              </Card.Section>

              <Card.Section
                title="Dirección predeterminada"
                actions={
                  clienteDB.direcciones.length > 0 && [
                    {
                      content: "Gestionar",
                      onAction: () => handleGestionModal(false),
                    },
                  ]
                }
              >
                <FormLayout>
                  {clienteDB.direccionEnvioId &&
                  clienteDB.direcciones.some(
                    (i) => i.consignatario_id === clienteDB.direccionEnvioId
                  ) ? (
                    readDirectionEnvio(
                      clienteDB.direcciones.find(
                        (i) => i.consignatario_id === clienteDB.direccionEnvioId
                      )
                    )
                  ) : (
                    <TextStyle>
                      Aún no hay una dirección predeterminada
                    </TextStyle>
                  )}
                  <Button
                    plain
                    onClick={() => handleChangeDirectionModal(false)}
                  >
                    Agregar nueva dirección
                  </Button>
                </FormLayout>
              </Card.Section>
            </Card>
            <Card title="Logística">
              <Card.Section>
                <FormLayout>
                  <Select
                    label="Envíos"
                    value={clienteDB.envio?.envio_nombre || ""}
                    options={[
                      {
                        label: clienteDB.envio?.envio_nombre,
                        value: clienteDB.envio?.envio_nombre,
                      },
                    ]}
                    disabled
                    onChange={() => {}}
                  />
                  <Select
                    placeholder="Selecciona un almacén"
                    label="Almacén"
                    disabled={isEditingCliente}
                    value={
                      clienteDB.almacen ? clienteDB.almacen._id : undefined
                    }
                    options={almacenesDB}
                    onChange={(val) => onAlmacenChange(val)}
                  />
                </FormLayout>
              </Card.Section>
              <Card.Section title="General">
                <FormLayout>
                  <Select
                    label="Moneda"
                    disabled
                    value={clienteDB?.moneda?.nombre}
                    options={[
                      {
                        label: clienteDB?.moneda?.nombre,
                        value: clienteDB?.moneda?.nombre,
                      },
                    ]}
                  />
                  <Select
                    label="Condición de pago"
                    value={clienteDB.condicion_pago?.nombre_condicion || ""}
                    options={[
                      {
                        label: clienteDB.condicion_pago?.nombre_condicion,
                        value: clienteDB.condicion_pago?.nombre_condicion,
                      },
                    ]}
                    disabled
                    onChange={() => {}}
                  />
                  <Select
                    label="Tipo de cliente"
                    value={clienteDB.tipo_cliente?.nombre_tipo || ""}
                    options={[
                      {
                        label: clienteDB.tipo_cliente?.nombre_tipo,
                        value: clienteDB.tipo_cliente?.nombre_tipo,
                      },
                    ]}
                    disabled
                    onChange={() => {}}
                  />
                  <Select
                    label="Zona de cliente"
                    value={clienteDB.zona_cliente?.nombre_zona || ""}
                    options={[
                      {
                        label: clienteDB.zona_cliente?.nombre_zona,
                        value: clienteDB.zona_cliente?.nombre_zona,
                      },
                    ]}
                    disabled
                    onChange={() => {}}
                  />
                  <Select
                    label="Cobrador"
                    value={clienteDB.cobrador?.nombre_cobrador || ""}
                    options={[
                      {
                        label: clienteDB.cobrador?.nombre_cobrador,
                        value: clienteDB.cobrador?.nombre_cobrador,
                      },
                    ]}
                    disabled
                    onChange={() => {}}
                  />
                  <Select
                    label="Vendedor"
                    value={clienteDB.vendedor?.nombre_vendedor || ""}
                    options={[
                      {
                        label: clienteDB.vendedor?.nombre_vendedor,
                        value: clienteDB.vendedor?.nombre_vendedor,
                      },
                    ]}
                    disabled
                    onChange={() => {}}
                  />
                  <Select
                    label="Estatus Microsip"
                    value={clienteDB.status_microsip}
                    options={[
                      { label: "Activo", value: "A" },
                      { label: "Baja", value: "B" },
                      { label: "Suspendido de crédito", value: "C" },
                      { label: "Suspendido de ventas", value: "V" },
                    ]}
                    disabled
                    onChange={() => {}}
                  />
                </FormLayout>
              </Card.Section>
            </Card>
            <Card
              title="Estado"
              actions={
                clienteDB.estatus.tipo !== 1
                  ? [
                      {
                        content: "Editar",
                        onAction: toggleClienteModal,
                        disabled:
                          !clienteDB.email ||
                          !clienteDB.almacen ||
                          isInviting ||
                          errors.length > 0,
                      },
                    ]
                  : null
              }
              sectioned
            >
              <CustomBadge status={clienteDB.estatus.nombre} />
            </Card>

            <TagsAutocomplete
              title="Etiquetas"
              subdued
              selected={selectedOptions}
              onSelect={onEtiquetaChange}
              outLoading={isEditingCliente}
              module="Clientes"
            />
          </Layout.Section>
        </Layout>
        <div className="flex flex-row my-10 justify-end">
          <Button disabled={isSaving} onClick={() => router.goBack()}>
            Descartar
          </Button>
          <div className="w-4" />
          <Button
            disabled={isSaving}
            loading={isSaving}
            primary
            onClick={handleSave}
          >
            Guardar
          </Button>
        </div>
        <HelpFooter title="clientes" url="https://help.b2bgo.mx/clientes" />
      </Page>
    </Layer>
  );
}
