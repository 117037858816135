import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Page,
  Layout,
  Card,
  TextField,
  Stack,
  FormLayout,
  Banner,
  TextStyle,
  SkeletonPage,
  SkeletonDisplayText,
  SkeletonBodyText,
  TextContainer,
  Select,
  PageActions,
} from "@shopify/polaris";
import { DeleteModal, Layer } from "../../components";
import { EmailEx } from "../../utils/RegEx";
import ApiServiceUsers from "../../services/ApiServiceUsers";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { toggleToast } from "../../actions/InteractiveActions";
import "./AddPlanPermisosScreenStyles.css";
import useToggle from "../../hooks/useToggle";

export default function AddPlanPermisosScreen({ forbidden }) {
  const dispatch = useDispatch();
  const router = useHistory();
  const [activeDeleteModal, toggleDeleteModal] = useToggle(false);

  const { role: roleId, id } = useParams();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingRol, setLoadingRol] = useState(true);
  const [loadingUser, setLoadingUser] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [vendors, setVendors] = useState([]);
  const [collectors, setCollectors] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [role, setRole] = useState({});
  const [user, setUser] = useState({
    name: "",
    last_name: "",
    email: "",
    vendor: undefined,
    collector: undefined,
  });

  const [plan, setPlan] = useState({
    maxCollaborators: 0,
    currentUsers: 0,
  });

  useEffect(() => {
    function getUser() {
      setLoadingUser(true);
      ApiServiceUsers.obtenerUsuario(id)
        .then(({ ok, usuario }) => {
          if (ok) {
            setUser(usuario);
          }
        })
        .finally(() => setLoadingUser(false));
    }
    function fetchCollaborators() {
      ApiServiceUsers.getMaxCollaborators()
        .then(({ maxCollaborators, currentUsers }) => {
          setPlan({ maxCollaborators, currentUsers });
        })
        .finally(() => setIsLoading(false));
    }

    const getRol = () => {
      ApiServiceUsers.getMerchantRole(roleId)
        .then(({ role }) => {
          setRole(role);
          if (role.tipo === 10) {
            getVendors();
            getCollectors();
          }
        })
        .finally(() => setLoadingRol(false));
    };

    const getVendors = () => {
      setIsLoading(true);
      ApiServiceComercios.getVendors()
        .then(({ vendors = [] }) => {
          const items = vendors.map(({ name, _id }) => ({
            label: name,
            value: _id,
          }));

          setVendors(items);
        })
        .finally(() => setIsLoading(false));
    };
    const getCollectors = () => {
      setIsLoading(true);
      ApiServiceComercios.getCollectors()
        .then(({ collectors = [] }) => {
          const items = collectors.map(({ name, _id }) => ({
            label: name,
            value: _id,
          }));

          setCollectors(items);
        })
        .finally(() => setIsLoading(false));
    };

    id && getUser();
    getRol();
    fetchCollaborators();

    return () => null;
  }, [roleId, id]);

  const handleChange = useCallback((field, value) => {
    setUser((prevState) => ({ ...prevState, [field]: value }));
  }, []);

  function handleAdd() {
    setIsUpdating(true);
    const { email, name, last_name, collector, vendor } = user;
    ApiServiceUsers.crearUsuario({
      email,
      name,
      rol: role.tipo,
      last_name,
      collector,
      vendor,
      sendEmail: true,
    })
      .then(({ ok, message }) => {
        if (ok) {
          dispatch(toggleToast({ message }));
          router.push("/admin/configuracion/permisos");
        }
      })
      .catch(() => {
        setIsUpdating(false);
      });
  }

  function handleSave() {
    setIsUpdating(true);
    const { collector, vendor } = user;
    ApiServiceUsers.updateUsuario({ collector, vendor, id })
      .then(({ ok }) => {
        if (ok) {
          dispatch(
            toggleToast({ message: "Usuario actualizado correctamente" })
          );
          router.push("/admin/configuracion/permisos");
        }
      })
      .catch(() => {
        setIsUpdating(false);
      });
  }

  function handleDelete() {
    setIsDeleting(true);
    ApiServiceUsers.eliminarUsuario(id)
      .then(({ ok, message }) => {
        if (ok) {
          dispatch(toggleToast({ message }));
          router.push("/admin/configuracion/permisos");
        }
      })
      .catch((error) => {
        setIsDeleting(false);
      });
  }

  function handleEmailOnBlur() {
    if (!EmailEx.test(user.email)) {
      setEmailError("Ingresa una dirección de correo electrónico válida");
    } else {
      setEmailError("");
    }
  }

  if (isLoading || loadingRol || loadingUser) {
    return (
      <Layer title="Usuario" forbidden={forbidden}>
        <SkeletonPage>
          <Layout>
            <Layout.AnnotatedSection
              title={<SkeletonDisplayText size="small" />}
              description={<SkeletonBodyText lines={2} />}
            >
              <Card sectioned>
                <TextContainer>
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={6} />
                </TextContainer>
              </Card>
            </Layout.AnnotatedSection>
          </Layout>
        </SkeletonPage>
      </Layer>
    );
  }

  const { collector, email, last_name, name, vendor } = user;

  return (
    <Layer title="Usuario" forbidden={forbidden}>
      <Page
        title={`Agregar ${role.nombre}`}
        breadcrumbs={[
          { content: "Usuarios", url: "/admin/configuracion/permisos" },
        ]}
      >
        {!(plan.maxCollaborators > plan.currentUsers) && (
          <div className="mb-8">
            <Banner
              status="warning"
              title="No se pueden invitar a más colaboradores"
            >
              <TextStyle>
                Has alcanzado el número máximo de colaboradores permitidos en tu
                plan
              </TextStyle>
            </Banner>
          </div>
        )}
        <Layout>
          <Layout.AnnotatedSection
            title={role.nombre}
            description={role.descripcion}
          >
            <Card sectioned>
              <FormLayout>
                <Stack distribution="fill" wrap spacing="loose">
                  <TextField
                    requiredIndicator
                    label="Nombre"
                    type="text"
                    value={name}
                    onChange={(value) => handleChange("name", value)}
                    placeholder="John"
                    disabled={id}
                  />
                  <TextField
                    requiredIndicator
                    label="Apellido"
                    type="text"
                    value={last_name}
                    onChange={(value) => handleChange("last_name", value)}
                    placeholder="Doe"
                    disabled={id}
                  />
                </Stack>
                <Stack distribution="fill">
                  <TextField
                    requiredIndicator
                    error={emailError}
                    label="Correo electrónico"
                    type="email"
                    value={email}
                    onChange={(value) => handleChange("email", value)}
                    onBlur={handleEmailOnBlur}
                    placeholder="correo@email.com"
                    disabled={id}
                  />
                </Stack>
                {role && role.tipo === 10 && (
                  <Stack vertical>
                    <Select
                      requiredIndicator
                      label="Vendedor"
                      placeholder="Selecciona un vendedor"
                      options={vendors}
                      value={vendor}
                      onChange={(value) => handleChange("vendor", value)}
                    />
                    <Select
                      label="Cobrador"
                      placeholder="Selecciona un cobrador"
                      options={collectors}
                      value={collector}
                      onChange={(value) => handleChange("collector", value)}
                    />
                  </Stack>
                )}
              </FormLayout>
              <PageActions
                primaryAction={{
                  content: id ? "Guardar" : "Enviar invitación",
                  loading: isLoading || isUpdating,
                  onAction: id ? handleSave : handleAdd,
                  disabled:
                    !(plan.maxCollaborators > plan.currentUsers) ||
                    !name ||
                    !last_name ||
                    !email ||
                    isLoading ||
                    emailError ||
                    isUpdating ||
                    (role.tipo === 10 && !vendor),
                }}
                secondaryActions={
                  id
                    ? [
                        {
                          content: "Eliminar",
                          destructive: true,
                          outline: true,
                          onAction: toggleDeleteModal,
                        },
                      ]
                    : []
                }
              />
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </Page>
      {id && activeDeleteModal && (
        <DeleteModal
          isLoading={isDeleting}
          active={activeDeleteModal}
          handleDelete={handleDelete}
          handleChange={toggleDeleteModal}
          title="Eliminar usuario"
          body="¿Estás seguro que quieres eliminar este usuario? Esta acción no podrá ser deshecha."
        />
      )}
    </Layer>
  );
}
