import React, { useEffect, useState } from "react";
import {
  ClientsContactCard,
  ComercioDetalleSkeleton,
  EditContactDirectionModal,
  Layer,
  LimitCreditCard,
} from "../../components";
import {
  Avatar,
  Button,
  Card,
  Heading,
  Layout,
  Page,
  Select,
  TextStyle,
} from "@shopify/polaris";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { useDispatch, useSelector } from "react-redux";
import { toggleToast } from "../../actions/InteractiveActions";

export const ClientProfileScreen = ({ forbidden }) => {
  const clientId = useSelector((state) => state.Auth.userData._id);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [showModalAddress, setShowModalAddress] = useState(false);
  const [isNewDirection, setIsNewDirection] = useState(false);

  const [clientData, setClientData] = useState();
  const [limitCredit, setLimitCredit] = useState(null);
  const [addressCurrent, setAddressCurrent] = useState();
  const [clientAddress, setClientAddress] = useState([]);
  const [shippingAddress, setShippingAddress] = useState();

  const loadData = () => {
    ApiServiceComercios.obtenerClienteComercio(clientId).then(({ cliente }) => {
      setClientData(cliente);
      setLimitCredit(cliente?.limite_credito);
      setClientAddress(cliente?.direcciones);

      setAddressCurrent(
        cliente?.direcciones?.find(
          ({ consignatario_id }) =>
            consignatario_id === cliente.direccionEnvioId
        )
      );
      setIsLoading(false);
    });
  };

  // Carga por primera vez la info del perfil
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Hace la animación de carga
  if (isLoading) {
    return (
      <Layer title={`Cliente`} forbidden={forbidden}>
        <ComercioDetalleSkeleton fullWidth />
      </Layer>
    );
  }

  // Cambia el estado del modal
  const changeStateModalAddress = () => {
    setShowModalAddress((state) => !state);
  };

  // Manda la dirección actual para editar
  const onEditAddress = () => {
    setShippingAddress(
      clientAddress.find(
        (item) => item.consignatario_id === clientData?.direccionEnvioId
      )
    );

    setIsNewDirection(false);
    changeStateModalAddress();
  };

  // Manda en el modal el estado vacío para crear nueva dirección
  const onNewAddress = () => {
    setIsNewDirection(true);
    setShippingAddress({
      consignatario: "",
      rfc: "",
      contacto: "",
      email: "",
      telefono: "",
      direccion: "",
      colonia: "",
      ciudad: "",
      codigo_postal: "",
      estado: "",
      pais: "México",
      usarParaEnvios: false,
      usarParaFacturar: false,
    });

    changeStateModalAddress();
  };

  // Actualiza la dirección seleccionada
  const onSaveAddress = (directionEdit) => {
    ApiServiceComercios.actualizaDireccionCliente({
      ...directionEdit,
      willUpdate: !isNewDirection,
      cliente_id: clientData._id,
      editDirectionName: "envío",
    }).then(({ message }) => {
      changeStateModalAddress();
      dispatch(toggleToast({ message }));
      loadData();
    });
  };

  return (
    <Layer title="Contactos" forbidden={forbidden}>
      <Page
        title="Contactos"
        fullWidth
        breadcrumbs={[
          {
            content: "",
            url: "/cliente",
          },
        ]}
      >
        <Layout>
          {showModalAddress && (
            <EditContactDirectionModal
              title="envío"
              isOpen={showModalAddress}
              handleChange={changeStateModalAddress}
              direcciones={clientAddress}
              direccionEnvio={shippingAddress}
              direccionFacturacion={{}}
              handleSave={onSaveAddress}
            />
          )}

          <Layout.Section>
            <Card sectioned>
              <div className="flex items-center gap-4">
                <div>
                  <Avatar
                    name={clientData?.nombre}
                    initials={clientData?.nombre.slice(0, 2).toUpperCase()}
                  />
                </div>
                <div className="flex flex-col">
                  <Heading>{clientData?.nombre}</Heading>

                  <TextStyle variation="subdued">{clientData?.rfc}</TextStyle>
                  <TextStyle variation="subdued">
                    CLAVE: {clientData?.clave}
                  </TextStyle>
                </div>
              </div>
            </Card>

            <ClientsContactCard />

            {limitCredit && <LimitCreditCard data={limitCredit} />}
          </Layout.Section>

          <Layout.Section secondary>
            <Card
              title="Dirección de envío"
              actions={[{ content: "Editar", onAction: onEditAddress }]}
              sectioned
            >
              <div className="flex flex-col">
                <TextStyle>{addressCurrent?.nombre_calle}</TextStyle>
                <TextStyle>
                  {addressCurrent?.ciudad}, {addressCurrent?.estado}
                </TextStyle>
                <TextStyle>
                  {addressCurrent?.pais}, {addressCurrent?.codigo_postal}
                </TextStyle>
                <TextStyle>{addressCurrent?.telefono}</TextStyle>
              </div>

              <div className="pt-4">
                <Button plain onClick={onNewAddress}>
                  Agregar nueva dirección
                </Button>
              </div>
            </Card>

            <Card title="Logística">
              <Card.Section>
                <div className="flex flex-col gap-4">
                  <Select
                    label="Envíos"
                    disabled
                    options={[
                      {
                        label: clientData?.envio.envio_nombre,
                        value: clientData?.envio.envio_id,
                      },
                    ]}
                    onChange={() => {}}
                  />
                  <Select
                    label="Almacén"
                    disabled
                    options={[
                      {
                        label: clientData?.almacen?.nombre,
                        value: clientData?.almacen?._id,
                      },
                    ]}
                    onChange={() => {}}
                  />
                </div>
              </Card.Section>

              <Card.Section>
                <Heading>General</Heading>
                <div className="flex flex-col gap-4 pt-4">
                  <Select
                    label="Moneda"
                    disabled
                    options={[
                      {
                        label: clientData?.moneda?.nombre,
                        value: clientData?.moneda?._id,
                      },
                    ]}
                    onChange={() => {}}
                  />
                  <Select
                    label="Condición de pago"
                    disabled
                    options={[
                      {
                        label: clientData?.condicion_pago.nombre_condicion,
                        value: clientData?.condicion_pago.condicion_id,
                      },
                    ]}
                    onChange={() => {}}
                  />
                  <Select
                    label="Vendedor"
                    disabled
                    options={[
                      {
                        label: clientData?.vendedor?.nombre_vendedor,
                        value: clientData?.vendedor?.vendedor_id,
                      },
                    ]}
                    onChange={() => {}}
                  />
                </div>
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </Layer>
  );
};
