import { useEffect, useState } from "react";
import ApiServiceComercios from "../services/ApiServiceComercios";

const useGetOrigins = (selectedOrigins) => {
  const [origins, setOrigins] = useState([]);
  const [isLoadingOrigins, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    ApiServiceComercios.getOrigins()
      .then(({ origins }) => {
        //add values to autocomplete list
        const originsItems = origins.map(({ name, _id }) => ({
          label: name,
          value: _id,
        }));

        setOrigins(originsItems);
      })
      .finally(() => setIsLoading(false));
  }, [selectedOrigins]);

  const createOrigin = async (name = "") => {
    return new Promise(async (resolve, reject) => {
      ApiServiceComercios.createOrigin({ name })
        .then(({ origin = {} }) => {
          const { name, _id } = origin;
          setOrigins((prevState) => [
            ...prevState,
            { label: name, value: _id },
          ]);
          setIsLoading(false);
          resolve(origin);
        })
        .catch(() => {
          setIsLoading(false);
          reject(null);
        });
    });
  };

  return { origins, isLoadingOrigins, setOrigins, createOrigin };
};

export default useGetOrigins;
