import { useEffect } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Button, Icon, Stack, TextStyle } from "@shopify/polaris";
import { CircleTickMajor } from "@shopify/polaris-icons";

export const SuccessOrderPayment = ({
  totalAmountToPay,
  confirmPaymentData,
  onCloseModal,
}) => {
  const router = useHistory();

  const actualDate = new Date();
  const currentDate = `${moment(actualDate).format("LLLL")} `;

  const goToOrderDetail = () => {
    onCloseModal();
    return router.push(
      `/cliente/pedidos/${confirmPaymentData?.orderId}/gracias`
    );
  };

  useEffect(() => {
    if (confirmPaymentData) {
      const { last4, brand } = confirmPaymentData?.paymentMethod;

      let query = `amount=${parseFloat(
        totalAmountToPay
      )}&card=${last4}&brand=${brand}`;

      router.push(
        `/cliente/pedidos/${confirmPaymentData?.orderId}/gracias?${query}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmPaymentData]);

  return (
    <div className="p-8 flex flex-col text-center items-center">
      <Icon source={CircleTickMajor} color="success" />
      <div className="mb-6">
        <p className="font-bold text-3xl my-6">Tu pago fue exitoso</p>
        <TextStyle variant="bodyMd" as="p" fontWeight="regular">
          Tu pedido ha sido creado y se ha asociado tu pago.
        </TextStyle>
      </div>
      <div className="flex w-full	 flex-col my-4">
        <div className="flex justify-between	">
          <TextStyle variant="bodyMd" as="p" fontWeight="regular">
            Importe:
          </TextStyle>
          <TextStyle variant="bodyMd" as="p" fontWeight="regular">
            ${totalAmountToPay}
          </TextStyle>
        </div>
        <hr className="my-6" />
        <div className="flex justify-between	">
          <TextStyle variant="bodyMd" as="p" fontWeight="regular">
            Fecha:
          </TextStyle>
          <TextStyle variant="bodyMd" as="p" fontWeight="regular">
            {currentDate}
          </TextStyle>
        </div>
        <hr className="my-6" />

        <div className="flex justify-between	">
          <TextStyle variant="bodyMd" as="p" fontWeight="regular">
            Folio:
          </TextStyle>
          <TextStyle variant="bodyMd" as="p" fontWeight="regular">
            {confirmPaymentData?.folio}
          </TextStyle>
        </div>
        <hr className="my-6" />
        <div className="flex justify-end items-center mt-6 ">
          <Stack alignment="center">
            <div className="flex justify-end">
              <div
                className="text-blue-600 w-full h-full cursor-pointer"
                onClick={goToOrderDetail}
              >
                <TextStyle variant="bodyMd" as="p" fontWeight="regular">
                  <div className="flex">
                    <Button>Ir al detalle del pedido</Button>
                  </div>
                </TextStyle>
              </div>
            </div>
            <Button
              primary
              onClick={() => {
                onCloseModal();
                router.push("/cliente/pedidos");
              }}
            >
              Aceptar
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
};
