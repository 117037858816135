import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Button, ButtonGroup, TextStyle } from "@shopify/polaris";

import {
  DragHandleMinor,
  AddMajor,
  ChevronUpMinor,
  ChevronDownMinor,
} from "@shopify/polaris-icons";

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";

import arrayMove from "array-move";
import AddElementMenuModal from "./AddElementMenuModal";
import { ExpandedRowThree } from "./ExpandedRowThree";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { useDispatch } from "react-redux";
import { toggleToast } from "../../actions/InteractiveActions";

const DragHandle = sortableHandle(() => (
  <DragHandleMinor
    style={{
      cursor: "grab",
      fill: "#5c6ac4",
      inlineSize: "20px",
      margin: "auto",
    }}
  />
));

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

export const ExpandedRow = ({ data: dataParent, handleReload }) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [isExpanable, setIsExpanable] = useState(false);

  const [dataTable, setDataTable] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);

  useEffect(() => {
    let dataFormated = dataParent?.items.map((subItem, index) => ({
      index,
      order: index,
      key: index,
      ...subItem,
    }));

    setDataTable(dataFormated);
  }, [dataParent]);

  const columns = [
    {
      title: "",
      width: 50,
      dataIndex: "_id",
      className: "sort-drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "",
      dataIndex: "title",
      className: "name-subTagDrag-visible",
    },
    {
      title: "",
      dataIndex: "items",
      className: "options-drag-visible",
      render: (_) => "",
    },
    {
      title: "",
      dataIndex: "_id",
      align: "right",
      className: "actions-drag-visible pl-0",
      render: (id, data) => (
        <div className="flex justify-end">
          <ButtonGroup className="filter-group" segmented>
            <Button onClick={() => onHandleEdit(data)} size="slim">
              Editar
            </Button>
            <Button onClick={() => onHandleDelete(id)} size="slim">
              Eliminar
            </Button>
          </ButtonGroup>
        </div>
      ),
    },
  ];

  const DraggableContainer = (props) => {
    return (
      <SortableContainer
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={onSortEnd}
        {...props}
      />
    );
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(dataTable, oldIndex, newIndex);
      newData.forEach((data, index) => (data["order"] = index));

      // Update the order in DB to show in the table
      ApiServiceComercios.putMenuList(dataParent?._id, {
        items: newData.map((item) => item?._id),
      }).then(({ message }) => {
        dispatch(
          toggleToast({
            message,
          })
        );
      });

      setDataTable(newData);
    }
  };

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataTable.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const handleModal = () => {
    setShowModal(false);
    setDataEdit(null);
  };

  const onHandleEdit = (data) => {
    setShowModal(true);
    setDataEdit(data);
  };

  const onHandleDelete = (id) => {
    ApiServiceComercios.deleteMenuList(id).then(({ message }) => {
      handleReload();
      dispatch(
        toggleToast({
          message,
        })
      );
    });
  };

  return (
    <div>
      <Table
        showHeader={false}
        className={`dragDrop ${dataTable.length === 0 && "hidden"}`}
        pagination={false}
        dataSource={dataTable}
        columns={columns}
        rowKey="index"
        components={
          !isExpanable && {
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }
        }
        expandable={{
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <Button
                plain
                icon={ChevronUpMinor}
                onClick={(e) => onExpand(record, e)}
              />
            ) : (
              <Button
                plain
                icon={ChevronDownMinor}
                onClick={(e) => onExpand(record, e)}
              />
            ),
          expandedRowRender: (data) => (
            <ExpandedRowThree
              data={data}
              handleReload={handleReload}
              columns={columns}
            />
          ),
          onExpandedRowsChange: () => setIsExpanable((prevState) => !prevState),
        }}
        locale={{
          emptyText: <div className="hidden " />,
        }}
      />

      <div className="pl-20 pt-4 self-center">
        <Button plain onClick={() => setShowModal(true)} icon={AddMajor}>
          Agregar sub elemento a
          <TextStyle variation="strong"> {dataParent.title}</TextStyle>
        </Button>
      </div>

      {showModal && (
        <AddElementMenuModal
          active={showModal}
          handleModal={handleModal}
          handleReload={handleReload}
          isParent={false}
          idParent={dataParent?._id}
          idsSubItems={dataParent?.items.map((e) => e._id)}
          dataEdit={dataEdit}
        />
      )}
    </div>
  );
};
