export default function RichTextStyled({ section }) {
  const { properties: item } = section;

  const getStyles = (item, field) => {
    const styles = {
      "text-banner-container": {
        background: item.backgroundColor,
        color: item.textColor,
      },
      "banner-title": {
        color: item.textColor,
      },
      "banner-button": {
        background: item.buttonColor,
        color: item.textButtonColor,
      },
      left: {
        justifyContent: "flex-start",
        alignItems: "center",
      },
      center: {
        justifyContent: "center",
        alignItems: "center",
      },
      right: {
        justifyContent: "flex-end",
        alignItems: "center",
      },
    };

    return styles[field];
  };
  return (
    <div
      className="flex justify-center text-center bg-white"
      style={{
        ...getStyles(item, "text-banner-container"),
        ...getStyles(item, item.position),
      }}
    >
      <div className="h-full block relative">
        <div className="block  z-10 m-10">
          <p
            className="banner-title z-10"
            style={{ ...getStyles(item, "banner-title") }}
          >
            {item.title}
          </p>
          <p
            className="my-10 banner-subtitle z-10"
            style={{ ...getStyles(item, "banner-title") }}
          >
            {item.subtitle}
          </p>
          {item.textButton && (
            <button
              className="banner-button z-10 font-semibold"
              onClick={() => window.open(item.urlButton, "_blank")}
              style={{ ...getStyles(item, "banner-button") }}
            >
              {item.textButton}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
