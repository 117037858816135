import { Card, Page, Tabs } from "@shopify/polaris";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchPayments, resetPage } from "../../actions/PaymentActions";
import { HelpFooter, Layer, PaymentsList } from "../../components";
import PaymentListContainer from "../../containers/PaymentListContainer";
import useUrlSearch from "../../hooks/useUrlSearch";
import { getPaginationPedidos } from "../../reducers";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import useDebounce from "../../services/useDebounce";
import { TabsPayments } from "../../utils/Tabs";

const objSearch = [
  "query",
  "customers",
  "tab_selected",
  "status",
  "sort",
  "order",
  "created_at",
  "selected_dates",
];
const PaymentsScreen = ({ forbidden }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { updateHistory, getSearch } = useUrlSearch({ objSearch, pathname });
  const queryValueRef = useRef(
    decodeURIComponent(getSearch("query", "", true))
  );
  const pagination = useSelector(getPaginationPedidos);
  const queryClientRef = useRef(null);
  const deselectedClientOptions = useRef([]);

  const [createdAt, setCreatedAt] = useState(
    getSearch("created_at", "todos", true)
  );
  const [selectedCreatedAt, setSelectedCreatedAt] = useState(
    getSearch("selected_dates", {
      start: new Date(),
      end: new Date(),
    })
  );
  const [tabSelected, setTabSelected] = useState(
    Number(getSearch("tab_selected", 0, true))
  );
  const [queryValue, setQueryValue] = useState(
    decodeURIComponent(getSearch("query", "", true))
  );
  const [statusPayment, setStatusPayment] = useState(getSearch("status", []));
  const [order, setOrder] = useState(getSearch("order", ["-1"]));
  const [sort, setSort] = useState(getSearch("sort", ["createdAt"]));
  const [selectedItems, setSelectedItems] = useState([]);
  const [envios, setEnvios] = useState([]);
  const [inputValueClient, setInputValueClient] = useState("");
  const [isLoadingClientes, setIsLoadingClientes] = useState(false);
  const [clientesOptions, setClientesOptions] = useState([]);
  const [clientSelected, setClientSelected] = useState(
    getSearch("customers", [])
  );

  const debouncedSearchTerm = useDebounce(queryValue, 900);
  const debouncedClientTerm = useDebounce(inputValueClient, 900);

  useEffect(() => {
    queryValueRef.current = queryValue;
    queryClientRef.current = debouncedClientTerm;
  });

  useEffect(() => {
    function onQueryEndChange() {
      setIsLoadingClientes(true);
      ApiServiceComercios.obtenerClientesComercio({
        limit: 20,
        skip: 0,
        sort: "nombre",
        query: queryClientRef.current,
        order: 1,
        status: JSON.stringify(["Activo"]),
      })
        .then(({ clientes }) => {
          const clientsFormatted = clientes.map((cliente) => ({
            label: cliente.nombre,
            value: cliente._id,
          }));

          const clientOptionsId = deselectedClientOptions.current.map(
            (clientOption) => clientOption.value.toString()
          );

          const newClients = clientsFormatted.filter(
            (client) => !clientOptionsId.includes(client.value)
          );

          deselectedClientOptions.current = [
            ...deselectedClientOptions.current,
            ...newClients,
          ];
          setClientesOptions(clientsFormatted);
        })
        .finally(() => setIsLoadingClientes(false));
    }
    onQueryEndChange();
  }, [debouncedClientTerm]);

  useEffect(() => {
    ApiServiceComercios.obtenerEnvios()
      .then(({ envios }) => {
        setEnvios(envios);
      })
      .catch((err) => {});
    return () => null;
  }, []);

  useEffect(() => {
    dispatch(
      fetchPayments({
        sort: sort[0],
        query: decodeURIComponent(queryValueRef.current),
        order: order[0],
        status: JSON.stringify([
          ...new Set(
            statusPayment.concat(
              tabSelected === 0 ? [] : [TabsPayments[tabSelected].panelID]
            )
          ),
        ]),
        date: createdAt === "todos" ? undefined : selectedCreatedAt,
        customers: JSON.stringify(clientSelected),
      })
    );
  }, [
    dispatch,
    sort,
    order,
    statusPayment,
    tabSelected,
    createdAt,
    debouncedSearchTerm,
    selectedCreatedAt,
    clientSelected,
    pagination.currentPage,
  ]);

  const updateTextContact = useCallback((value) => {
    setInputValueClient(value);
    if (value === "") {
      setClientesOptions(deselectedClientOptions.current);
      return;
    }

    const filterRegex = new RegExp(value, "i");
    const resultOptions = deselectedClientOptions.current.filter((option) =>
      option.label.match(filterRegex)
    );
    setClientesOptions(resultOptions);
  }, []);

  function handleChangeStates(
    value,
    setState,
    key,
    useStringify = true,
    resetTab = false
  ) {
    updateHistory(
      {
        [key]: value,
      },
      useStringify
    );
    resetPagination();
    setState(value);
    if (resetTab) {
      setTabSelected(0);
      updateHistory(
        {
          tab_selected: 0,
        },
        false
      );
    }
  }

  function resetPagination() {
    dispatch(resetPage());
  }

  function handleSelectAll(values = []) {
    setSelectedItems(values);
  }

  return (
    <Layer title="Pagos" forbidden={forbidden} scrollToTop>
      <Page title="Pagos">
        <PaymentListContainer>
          {({ isLoading, pagination, payments, paymentColumns }) => {
            return (
              <Card>
                <Tabs
                  tabs={TabsPayments}
                  selected={tabSelected}
                  onSelect={(value) =>
                    handleChangeStates(
                      value,
                      setTabSelected,
                      "tab_selected",
                      false
                    )
                  }
                >
                  <PaymentsList
                    createdAt={createdAt}
                    handleCreatedAtChange={(_, value) =>
                      handleChangeStates(
                        value,
                        setCreatedAt,
                        "created_at",
                        false
                      )
                    }
                    selectedCreatedAt={{
                      start: new Date(selectedCreatedAt.start),
                      end: new Date(selectedCreatedAt.end),
                    }}
                    setSelectedCreatedAt={(value) =>
                      handleChangeStates(
                        value,
                        setSelectedCreatedAt,
                        "selected_dates"
                      )
                    }
                    handleDateCreatedRemove={() =>
                      handleChangeStates(
                        "todos",
                        setCreatedAt,
                        "created_at",
                        false
                      )
                    }
                    selectedItems={selectedItems}
                    handleSelectAll={handleSelectAll}
                    paymentColumns={paymentColumns}
                    payments={payments}
                    statusPayment={statusPayment}
                    orden={order}
                    orderSelected={sort}
                    pagination={pagination}
                    queryValue={decodeURIComponent(queryValue)}
                    envios={envios}
                    isLoading={isLoading}
                    handleStatusPaymentsChange={(value) =>
                      handleChangeStates(
                        value,
                        setStatusPayment,
                        "status",
                        true,
                        true
                      )
                    }
                    handleStatusPaymentRemove={() =>
                      handleChangeStates(
                        [],
                        setStatusPayment,
                        "status",
                        true,
                        true
                      )
                    }
                    handleFiltersQueryChange={(value) =>
                      handleChangeStates(
                        value ? encodeURIComponent(value) : value,
                        setQueryValue,
                        "query",
                        false
                      )
                    }
                    handleQueryValueRemove={() =>
                      handleChangeStates("", setQueryValue, "query", false)
                    }
                    handleOrdenChange={(value) =>
                      handleChangeStates(value, setOrder, "order")
                    }
                    handleOrderSelectedChange={(value) =>
                      handleChangeStates(value, setSort, "sort")
                    }
                    handleOrderSelectedRemove={() =>
                      handleChangeStates(["createdAt"], setSort, "sort")
                    }
                    clientOptions={clientesOptions}
                    clientSelected={clientSelected}
                    handleClientChange={(value) =>
                      handleChangeStates(value, setClientSelected, "customers")
                    }
                    deselectedClients={deselectedClientOptions}
                    inputValueClient={inputValueClient}
                    updateTextContact={updateTextContact}
                    loadingClients={isLoadingClientes}
                  />
                </Tabs>
              </Card>
            );
          }}
        </PaymentListContainer>
        <HelpFooter title="pagos" url="https://help.b2bgo.mx/pagos" />
      </Page>
    </Layer>
  );
};
export default PaymentsScreen;
