import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import useGetElementSizes from "../../hooks/useGetElementSizes";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import "./SectionsStyled.css";
import { Skeleton } from "antd";
import _ from "lodash";

export default function BannerImageStyled({ section, mode = "" }) {
  let { handle } = useParams();
  const [isMobile, setIsMobile] = useState(false);
  const [{ properties }, setSection] = useState({ properties: {} });
  const { measuredRef, width } = useGetElementSizes();
  const [collection, setCollection] = useState({});
  const [loading, setLoading] = useState(false);

  const containerSize = {
    small: "220px",
    medium: "360px",
    large: "420px",
  };

  useEffect(() => {
    let newHandle = handle;
    const fetchCollection = () => {
      setLoading(true);
      if (mode === "edit") {
        ApiServiceComercios.getCollections({ limit: 1 }).then(
          ({ collections }) => {
            newHandle = collections.length > 0 ? collections[0].handle : "";
            fetchCollectionByHandle();
          }
        );
      } else {
        fetchCollectionByHandle();
      }
    };

    const fetchCollectionByHandle = () => {
      ApiServiceComercios.getArticlesByCollectionsHandle(newHandle)
        .then(({ collection }) => {
          setCollection(collection);
        })
        .finally(() => setLoading(false));
    };

    fetchCollection();
  }, [handle, mode]);

  useEffect(() => {
    setSection(section);
  }, [section]);

  useEffect(() => {
    setIsMobile(width <= 768);
  }, [width]);

  const getStyles = useCallback(
    (field) => {
      const styles = {
        "text-banner-container": {
          background: properties.backgroundColor,
          color: properties.textColor,
          opacity:
            Number(
              properties.opacity && !_.isNaN(properties.opacity)
                ? properties.opacity
                : 100
            ) / 100,
        },
        "banner-container": {
          top: properties.sliderHeight === "small" ? 0 : 50,
          bottom: properties.sliderHeight === "small" ? 0 : 50,
        },
        "banner-title": {
          color: properties.textColor,
        },
        "banner-button": {
          background: properties.buttonColor,
          color: properties.textButtonColor,
        },
        upToLeft: {
          justifyContent: "flex-start",
          alignItems: "flex-start",
        },
        upToCenter: {
          justifyContent: "flex-start",
          alignItems: "center",
        },
        upToRight: {
          justifyContent: "flex-start",
          alignItems: "flex-end",
        },
        centerToLeft: {
          justifyContent: "center",
          alignItems: "flex-start",
        },
        centerToCenter: {
          justifyContent: "center",
          alignItems: "center",
        },
        centerToRight: {
          justifyContent: "center",
          alignItems: "flex-end",
        },
        bottomToLeft: {
          justifyContent: "flex-end",
          alignItems: "flex-start",
        },
        bottomToCenter: {
          justifyContent: "flex-end",
          alignItems: "center",
        },
        bottomToRight: {
          justifyContent: "flex-end",
          alignItems: "flex-end",
        },
      };

      return styles[field];
    },
    [properties]
  );

  const BannerText = () => {
    return (
      <div
        className="text-banner-container relative flex"
        style={{
          ...getStyles("banner-container"),
        }}
      >
        <div
          className="absolute inset-0"
          style={{ ...getStyles("text-banner-container") }}
        />
        <div
          className="flex justify-center flex-col z-10 m-10"
          style={{
            width: "100%",
            height: "90%",
            ...getStyles(properties.position),
          }}
        >
          <p
            className="banner-title z-10"
            style={{ ...getStyles("banner-title") }}
          >
            {collection.name}
          </p>
          <span
            className="my-10 banner-subtitle z-10"
            style={{ ...getStyles("banner-title") }}
          >
            <div className="dangerous-html">
              {parse(collection?.description || "", {
                htmlparser2: { recognizeCDATA: true },
                trim: false,
              })}
            </div>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className="flex border-b border-gray-200"
      style={{ height: isMobile && properties.hideOnMobile ? 1 : "auto" }}
      ref={measuredRef}
    >
      <div className="flex justify-cente w-full">
        <div
          className="flex justify-center w-full text-center"
          style={{
            display: isMobile && properties.hideOnMobile ? "none" : "flex",
            minHeight: containerSize[properties.sliderHeight],
            height: containerSize[properties.sliderHeight],
            maxWidth: "100vw",
          }}
        >
          {loading ? (
            <div
              className="w-full h-full flex flex-col"
              style={{ ...getStyles(properties.position) }}
            >
              <Skeleton.Input active size="small" className="mb-6" />

              <Skeleton.Input active size="large" />
            </div>
          ) : (
            <div className="h-full flex relative w-full">
              {collection?.files?.length > 0 && (
                <img
                  className="object-cover w-full"
                  src={
                    collection?.files?.length > 0
                      ? collection?.files[0].url
                      : ""
                  }
                  alt={collection?.name}
                />
              )}
              <BannerText />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
