import React, { useCallback, useState, useEffect } from "react";
import { Card, DropZone, Modal, FormLayout, TextStyle } from "@shopify/polaris";
import { ZoomImage } from "..";

export default function ImageModal({
  isOpen = false,
  onClose,
  images = [],
  onAddImage,
  onSelectImage,
  imageVariant = [],
}) {
  const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];

  const [selectedImages, setSelectedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openFileDialog, setOpenFileDialog] = useState(false);

  const toggleOpenFileDialog = useCallback(
    () => setOpenFileDialog((openFileDialog) => !openFileDialog),
    []
  );

  useEffect(() => {
    const activeImage = images.find((i) => i === imageVariant[0]);
    if (activeImage) {
      setSelectedImages([activeImage]);
    }
  }, [images, imageVariant]);

  function handleSelectImage(value, image) {
    if (value) {
      if (!selectedImages.some((i) => i === image)) {
        setSelectedImages([image]);
      }
    } else {
      setSelectedImages(selectedImages.filter((i) => i !== image));
    }
  }

  function handleDropZoneDrop(_dropFiles, acceptedFiles, _rejectedFiles) {
    setIsLoading(true);
    onAddImage(false, acceptedFiles, [], false, selectedImages).finally(() =>
      setIsLoading(false)
    );
  }

  const fileUpload = <DropZone.FileUpload />;

  return (
    <Modal
      open={isOpen}
      title="Actualizar imagen de variante"
      onClose={onClose}
      primaryAction={{
        content: "Listo",
        onAction: () =>
          onSelectImage(selectedImages.length > 0 ? selectedImages[0] : ""),
      }}
      secondaryActions={[
        {
          content: "Cancelar",
          onAction: onClose,
        },
        {
          content: "Agregar imagen",
          onAction: toggleOpenFileDialog,
          loading: isLoading,
          disabled: isLoading,
        },
      ]}
    >
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="subdued">
            Solo puedes seleccionar imágenes como elementos multimedia de
            variantes
          </TextStyle>
          <ZoomImage
            images={images}
            selectedImages={selectedImages}
            handleSelectImage={handleSelectImage}
          >
            <div className="flex flex-row w-full">
              <DropZone
                type="image"
                accept={validImageTypes.join(",")}
                onDrop={handleDropZoneDrop}
                allowMultiple={true}
                overlay
                openFileDialog={openFileDialog}
                onFileDialogClose={toggleOpenFileDialog}
              >
                {fileUpload}
              </DropZone>
            </div>
          </ZoomImage>
        </FormLayout>
      </Card>
    </Modal>
  );
}
