import {
  ForbiddenLayout,
  LayoutOnlineStore,
  RenderSectionsStyled,
} from "../../components";
import useGetPageSections from "../../hooks/useGetPageSections";

const currentPage = "collections-list-page";
export default function CollectionsListPage({ forbidden }) {
  const { data: sections } = useGetPageSections(currentPage, []);

  const heading =
    sections?.find((section) => section.type === "heading")?.sections || [];
  const body =
    sections?.find((section) => section.type === currentPage)?.sections || [];
  const footer =
    sections?.find((section) => section.type === "footer")?.sections || [];

  if (forbidden) {
    return <ForbiddenLayout />;
  }

  return (
    <LayoutOnlineStore title="Lista de colecciones">
      <RenderSectionsStyled
        sections={sections}
        heading={heading}
        body={body}
        footer={footer}
      />
    </LayoutOnlineStore>
  );
}
