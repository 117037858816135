import React from "react";
import { Icon, TextStyle } from "@shopify/polaris";

import "./style.css";
import { useHistory } from "react-router-dom";

const SidebarClient = ({ routes = [] }) => {
  const history = useHistory();

  const goTo = (link) => {
    history.push(link);
  };

  return (
    <div id="navigation" className="w-auto bg-gray-50">
      <ul className="pt-4 px-5 font-extrabold">
        {routes.map((item, idx) => (
          <li
            className={item.url === "/cliente/articulos" ? "itemSelected" : ""}
            onClick={() => goTo(item.url)}
            key={idx}
          >
            <div className="flex p-3 gap-5 hover:bg-gray-200 cursor-pointer">
              <div>
                <Icon source={item.icon} color="base" />
              </div>
              <div className="sidebar_label">
                <TextStyle>{item.label}</TextStyle>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SidebarClient;
