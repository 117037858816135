import React from "react";
import {
  SkeletonDisplayText,
  DisplayText,
  Heading,
  TextStyle,
  Button,
} from "@shopify/polaris";

export default function TodayMetrics({
  classes,
  isLast,
  heading,
  isLoading,
  metric,
  color,
  redirect,
}) {
  return (
    <div
      className={`${classes} border-card ${
        isLast ? "m-0 sm:ml-10 md:ml-10" : ""
      }`}
    >
      {isLoading ? (
        <div
          className={`w-full flex justify-center ${
            isLast ? "skeleton-small-card" : "skeleton-card"
          }`}
        >
          <SkeletonDisplayText size={isLast ? "small" : "medium"} />
          {isLast && (
            <div
              className="text-white px-4 py-1 ml-2 rounded-md flex flex-row"
              style={{ background: color }}
            >
              <TextStyle variation="strong">· En vivo</TextStyle>
            </div>
          )}
        </div>
      ) : (
        <div className={`${isLast ? "flex justify-center items-center" : ""}`}>
          <DisplayText element="h1" size="large">
            {metric}
          </DisplayText>
          {isLast && (
            <div
              className="text-white px-4 py-1 ml-2 rounded-md"
              style={{ background: color }}
            >
              <TextStyle variation="strong">· En vivo</TextStyle>
            </div>
          )}
        </div>
      )}
      <Heading element="p">
        <TextStyle variation="subdued">{heading}</TextStyle>
      </Heading>
      {redirect && (
        <div className="mt-2">
          <Button outline url={redirect.url}>
            {redirect.title}
          </Button>
        </div>
      )}
    </div>
  );
}
