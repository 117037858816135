import React from "react";
import { Document, Font, Image, Page, Text, View } from "@react-pdf/renderer";
import ApplicationStyles from "../../../styles/ApplicationStyles";
import moment from "moment";
import NumberToLetter from "../../../utils/NumberToLetter";
import Tahoma from "../../../assets/fonts/Tahoma-Regular.ttf";
import TahomaBold from "../../../assets/fonts/Tahoma-Bold.ttf";
import { NumberFormat } from "../../../utils/Formats";
import { getImageVideoUrl } from "../../../utils/productValidators";

const { flexBox, pdfStyle } = ApplicationStyles;

export default function CotizacionPDF({ data }) {
  const { articulos } = data;
  const qrvalue = `https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=${window.location.href}&choe=UTF-8`;

  Font.register({ family: "Tahoma", src: Tahoma, format: "truetype" });
  Font.register({ family: "Tahoma-Bold", src: TahomaBold, format: "truetype" });

  function readDirectionFacturacion(direction) {
    return (
      <>
        <View
          style={
            ([flexBox.flex, flexBox.column, pdfStyle.bodyDirection],
            { width: "100%" })
          }
        >
          <Text style={pdfStyle.bodyDirection}>{direction.rfc}</Text>
          <Text style={pdfStyle.bodyDirection}>
            {direction.direccion}
            {" CP. "}
            {direction.codigo_postal}
          </Text>
          <Text style={pdfStyle.bodyDirection}>
            {direction.ciudad}, {direction.estado}, {direction.pais}
          </Text>
          <Text style={pdfStyle.bodyDirection}>{direction.telefono}</Text>
        </View>
      </>
    );
  }

  function readDirectionEnvio(direction) {
    return (
      <>
        <View
          style={
            ([flexBox.flex, flexBox.column, pdfStyle.bodyDirection],
            { width: "100%" })
          }
        >
          <Text style={pdfStyle.bodyDirection}>{direction.rfc}</Text>
          <Text style={pdfStyle.bodyDirection}>
            {direction.direccion}
            {" CP. "}
            {direction.codigo_postal}
          </Text>
          <Text style={pdfStyle.bodyDirection}>
            {direction.ciudad}, {direction.estado}, {direction.pais}
          </Text>
          <Text style={pdfStyle.bodyDirection}>{direction.telefono}</Text>
        </View>
      </>
    );
  }

  return (
    <Document>
      <Page size="A4" style={pdfStyle.page}>
        {/* HEADER */}
        <View style={[flexBox.row, flexBox.justifyContentSpaceBetween]} fixed>
          <View
            style={
              ([flexBox.flex, flexBox.column],
              { width: "20%", marginLeft: "0.9mm" })
            }
          >
            <Image
              style={pdfStyle.merchantImage}
              src={data?.url_imagen ? data.url_imagen : "/b2bgologo.png"}
            />
          </View>
          <View style={([flexBox.flex, flexBox.column], { width: "30%" })}>
            <Text style={pdfStyle.titleBodyData}>{data.comercio}</Text>
            <Text style={pdfStyle.bodyData}>{data.comercioData.rfc}</Text>
            <Text style={pdfStyle.bodyData}>
              {data.comercioData.direccion}
              {" CP. "}
              {data.comercioData.codigo_postal}
              {"\n"}
            </Text>
            <Text style={pdfStyle.bodyData}>
              {data.comercioData.ciudad}, {data.comercioData.estado},{" "}
              {data.comercioData.pais}
              {"\n"}
            </Text>
          </View>
          <View
            style={
              ([flexBox.flex, flexBox.column],
              { width: "20%", marginTop: "-0.2mm" })
            }
          >
            <Text style={pdfStyle.bigTextHeader}>{data.estatus.nombre}</Text>
          </View>
          <View
            style={
              ([flexBox.flex, flexBox.column],
              { width: "30%", marginRight: "0.9mm", textAlign: "right" })
            }
          >
            <Text style={pdfStyle.bigTextHeaderDocument}>
              Cotización {data.folio}
            </Text>
            <Text style={pdfStyle.bodyDataRight}>
              {moment(data.createdDate).format("D")} de{" "}
              {moment(data.createdDate).format("MMMM")} del{" "}
              {moment(data.createdDate).format("YYYY")}
            </Text>
          </View>
        </View>
        {/* DIRECCIONES  */}
        <View style={[flexBox.row, { marginBottom: "-1mm" }]} fixed>
          <View
            style={[
              pdfStyle.tableHeader,
              {
                width: "50%",
              },
            ]}
            fixed
          >
            <Text fixed>Facturación</Text>
          </View>
          <View
            style={[
              pdfStyle.tableHeader,
              {
                width: "50%",
              },
            ]}
            fixed
          >
            <Text fixed>Dirección de envío</Text>
          </View>
        </View>

        <View style={[flexBox.flex, flexBox.row]} fixed>
          <View style={{ width: "50%", marginRight: "0.5mm" }}>
            <View
              style={
                ([flexBox.flex, flexBox.column],
                { width: "100%", marginBottom: "1.5mm" })
              }
            >
              <Text style={pdfStyle.directionName}>{data.cliente.nombre}</Text>
              {data.direccionFacturacionId &&
              data.cliente.direcciones.some(
                (i) => i.consignatario_id === data.direccionFacturacionId
              )
                ? readDirectionFacturacion(
                    data.cliente.direcciones.find(
                      (i) => i.consignatario_id === data.direccionFacturacionId
                    )
                  )
                : null}
            </View>
          </View>
          <View style={{ width: "50%", marginRight: "0.5mm" }}>
            <View
              style={
                ([flexBox.flex, flexBox.column],
                { width: "100%", marginBottom: "1.5mm" })
              }
            >
              <Text style={pdfStyle.directionName}>{data.cliente.nombre}</Text>
              {data.direccionEnvioId &&
              data.cliente.direcciones.some(
                (i) => i.consignatario_id === data.direccionEnvioId
              )
                ? readDirectionEnvio(
                    data.cliente.direcciones.find(
                      (i) => i.consignatario_id === data.direccionEnvioId
                    )
                  )
                : null}
            </View>
          </View>
        </View>

        {/* PRODUCTOS  */}
        <View
          style={[flexBox.flex, flexBox.row, { marginBottom: "-1mm" }]}
          fixed
        >
          <View style={[pdfStyle.tableHeader, { width: "37%" }]} fixed>
            <Text fixed>Producto</Text>
          </View>

          <View
            style={[
              pdfStyle.tableHeader,
              {
                width: "13%",
              },
            ]}
            fixed
          >
            <Text fixed>Código</Text>
          </View>

          <View
            style={[
              pdfStyle.tableHeader,
              {
                width: "12.5%",
              },
            ]}
            fixed
          >
            <Text fixed>Unidades</Text>
          </View>

          <View
            style={[
              pdfStyle.tableHeader,
              {
                width: "12.5%",
              },
            ]}
            fixed
          >
            <Text fixed>Precio</Text>
          </View>

          <View
            style={[
              pdfStyle.tableHeader,
              {
                width: "12.5%",
              },
            ]}
            fixed
          >
            <Text fixed>Descto.</Text>
          </View>

          <View
            style={[
              pdfStyle.tableHeader,
              {
                width: "12.5%",
              },
            ]}
            fixed
          >
            <Text fixed>Importe</Text>
          </View>
        </View>

        <View
          style={[
            flexBox.flex,
            flexBox.row,
            { marginBottom: "-1mm", fontFamily: "Tahoma" },
          ]}
        >
          <View style={{ width: "37%", marginRight: "0.8mm" }}>
            {articulos.map((item, index) => (
              <View
                wrap={false}
                key={index}
                style={[flexBox.flex, flexBox.row, pdfStyle.spaceProducts]}
              >
                <View
                  style={{
                    width: "25%",
                    marginRight: "4.5mm",
                  }}
                  wrap={false}
                >
                  <Image
                    style={pdfStyle.productImage}
                    src={
                      getImageVideoUrl(item.articulo?.imagen, "image", false) ||
                      "/Default Photo.png"
                    }
                  />
                </View>
                <View
                  style={{
                    width: "75%",
                    marginRight: "0.8mm",
                  }}
                >
                  <Text
                    style={[pdfStyle.bodyProductLeft, { paddingRight: "4mm" }]}
                    wrap={false}
                  >
                    {item.articulo.nombre || `  `}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <View style={{ width: "13%" }}>
            {articulos.map((item, index) => (
              <Text
                key={index}
                style={[pdfStyle.bodyProductLeft, pdfStyle.spaceProducts]}
                wrap={false}
              >
                {item.articulo.clave || `  `}
              </Text>
            ))}
          </View>
          <View style={{ width: "12.5%", marginHorizontal: "0.8mm" }}>
            {articulos.map((item, index) => (
              <Text
                key={index}
                style={[pdfStyle.bodyProduct, pdfStyle.spaceProducts]}
                wrap={false}
              >
                {Number(item.cantidad).toLocaleString("en", 0) || `  `}
                {"\n\n\n\n\n"}
              </Text>
            ))}
          </View>
          <View style={{ width: "12.5%", marginHorizontal: "0.8mm" }}>
            {articulos.map((item, index) => (
              <Text
                key={index}
                style={[pdfStyle.bodyProduct, pdfStyle.spaceProducts]}
                wrap={false}
              >
                {Number(item.precioDeLista.precio).toLocaleString(
                  "en",
                  NumberFormat
                ) || `  `}
                {"\n\n\n\n\n"}
              </Text>
            ))}
          </View>
          <View style={{ width: "12.5%", marginHorizontal: "0.8mm" }}>
            {articulos.map((item, index) => (
              <Text
                key={index}
                style={[pdfStyle.bodyProduct, pdfStyle.spaceProducts]}
                wrap={false}
              >
                {item.totalPorcentajeDescuento
                  ? `${Number(item.totalPorcentajeDescuento)}%`
                  : null}
                {"\n\n\n\n\n"}
              </Text>
            ))}
          </View>
          <View style={{ width: "12.5%", marginLeft: "0.8mm" }}>
            {articulos.map((item, index) => (
              <Text
                key={index}
                style={[pdfStyle.bodyProduct, pdfStyle.spaceProducts]}
                wrap={false}
              >
                {item.totalPrecioConDescuento
                  ? Number(
                      item.totalPrecioConDescuento * item.cantidad
                    ).toLocaleString("en", NumberFormat)
                  : Number(
                      item.precioDeLista.precio * item.cantidad
                    ).toLocaleString("en", NumberFormat)}
                {"\n\n\n\n\n"}
              </Text>
            ))}
          </View>
        </View>
        {/* SUMMARY */}
        <View
          style={[flexBox.row, flexBox.flex, pdfStyle.footerBorder]}
          wrap={false}
        >
          <View style={{ width: "15%", marginLeft: "-3mm", marginTop: "-2mm" }}>
            <Image src={qrvalue} />
          </View>
          <View style={{ width: "50%", marginTop: "0mm" }}>
            <Text style={pdfStyle.bodyData}>
              {"Son: "}
              {NumberToLetter(data.total)}
            </Text>
            <Text style={[pdfStyle.bodyData, { maxHeight: "3mm" }]}>
              {"Notas: "}
              {data.notas}
            </Text>
            <Text style={pdfStyle.bodyData}>
              {"Crédito: "}
              {data.cliente.condicion_pago.nombre_condicion.toLowerCase()}
            </Text>
            <Text style={pdfStyle.bodyData}>
              {"Envío: "}
              {data.envio.nombre}
            </Text>
          </View>
          <View style={{ width: "15%", marginTop: "0mm", marginLeft: "2mm" }}>
            <Text style={pdfStyle.bodyData}>
              {"Subtotal: "}
              {articulos.length}
              {" productos "}
            </Text>
            <Text style={pdfStyle.bodyData}>{"Total impuestos "}</Text>
            <Text style={pdfStyle.bodyData}>
              {"Envíos ("}
              {data.envio.nombre}
              {")"}
            </Text>
            <Text
              style={{
                marginTop: "2mm",
                fontSize: 7,
                textAlign: "left",
                fontFamily: "Tahoma-Bold",
              }}
            >
              {"Total "}
            </Text>
            <Text
              style={{
                marginTop: "2mm",
                fontSize: 6,
                textAlign: "left",
              }}
            >
              {"Peso del embarque (Kgs)  "}
            </Text>
          </View>
          <View style={{ width: "20%", textAlign: "right", marginTop: "0mm" }}>
            <Text style={pdfStyle.bodyDataRight}>
              {Number(data.subtotalCotizacion).toLocaleString(
                "en",
                NumberFormat
              )}
            </Text>
            <Text style={pdfStyle.bodyDataRight}>
              {Number(data.impuestoSubtotalCotizacion).toLocaleString(
                "en",
                NumberFormat
              )}
            </Text>
            <Text style={pdfStyle.bodyDataRight}>
              {Number(data.envio.precio).toLocaleString("en", NumberFormat)}
            </Text>
            <Text
              style={{
                marginTop: "2mm",
                fontSize: 7,
                fontFamily: "Tahoma-Bold",
              }}
            >
              {`$${Number(data.total).toLocaleString("en", NumberFormat)}`}
            </Text>
            <Text
              style={{
                marginTop: "2mm",
                fontSize: 7,
              }}
            >
              {`${Number(data.weight).toLocaleString("en", NumberFormat)}`}
            </Text>
          </View>
        </View>

        {/* FOOTER */}
        <Text style={[pdfStyle.footerLeft]} fixed>
          {data.comercioData.telefono}
        </Text>

        <Text style={[pdfStyle.footerCenter]} fixed>
          {data.comercioData.email}
        </Text>

        <Text style={[pdfStyle.footerRight]} fixed>
          {process.env.NODE_ENV === "development"
            ? "comercio-dev.b2bgo.com.mx"
            : window.location.hostname}
        </Text>

        {/* PAGINATION */}
        <Text
          style={pdfStyle.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `Pág: ${pageNumber} de ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
