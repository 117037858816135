import { Card, FormLayout, Modal, TextField } from "@shopify/polaris";
import React, { useState } from "react";

const initialState = {
  reference: "",
  description: "",
};

function AddSuggestionModal({ isActive, onClose, onSave, loading = false }) {
  const [suggestion, setSuggestion] = useState(initialState);

  const inputChange = (name = "", value = "") => {
    setSuggestion((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = () => {
    onSave(suggestion);
    onClose();
  };

  return (
    <>
      <Modal
        title="Enviar sugerencia de mejora"
        open={isActive}
        onClose={onClose}
        primaryAction={{
          content: "Crear sugerencia de mejora",
          onAction: handleSave,
          disabled: loading || !suggestion.reference || !suggestion.description,
          loading: loading,
        }}
        secondaryActions={[
          {
            content: "Cancelar",
            onAction: onClose,
            disabled: loading,
          },
        ]}
      >
        <Modal.Section>
          <Card sectioned>
            <FormLayout>
              <FormLayout>
                <TextField
                  type="text"
                  label="Sugerencia"
                  placeholder="Escribe tu sugerencia"
                  requiredIndicator
                  value={suggestion.reference}
                  onChange={(val) => inputChange("reference", val)}
                />
                <TextField
                  type="text"
                  label="Descripción"
                  placeholder="Explica detalladamente lo que te gustaría que el sistema hiciera"
                  requiredIndicator
                  multiline={3}
                  value={suggestion.description}
                  onChange={(val) => inputChange("description", val)}
                />
              </FormLayout>
            </FormLayout>
          </Card>
        </Modal.Section>
      </Modal>
    </>
  );
}

export default AddSuggestionModal;
