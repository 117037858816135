import React from 'react';
import { useSelector } from 'react-redux';
import { isLoadingAnuncios, getAnuncios, getPaginationAnuncios } from '../../reducers';

export default function AnunciosContainer({children}) {
   const isLoading = useSelector(isLoadingAnuncios);
   const anuncios = useSelector(getAnuncios).map(item => { return { ...item, id:item._id }});
   const pagination = useSelector(getPaginationAnuncios);

   return <>{children({isLoading, anuncios, pagination})}</>
}
