import React from 'react'
import { Card, Modal, TextStyle } from '@shopify/polaris'

export default function GeneralModal({open, onClose, title, body, primary, onAction, secondary, destructive = false}) {
   return (
      <Modal
         open={open}
         onClose={onClose}
         title={title}
         primaryAction={{content: primary, onAction: onAction, destructive}}
         secondaryActions={[{content: secondary, onAction: onClose}]}
      >
         <Card sectioned>
            <TextStyle>{ body }</TextStyle>
         </Card>
      </Modal>
   )
}
