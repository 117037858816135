import { useState, useLayoutEffect, useRef } from "react";

export default function MeasureExample() {
  const measuredRef = useRef(null);
  const [{ width, height }, setSizes] = useState({
    width: 0,
    height: 0,
  });

  useLayoutEffect(() => {
    if (measuredRef.current) {
      const firtMount = () => {
        const { offsetWidth: width, offsetHeight: height } =
          measuredRef.current;
        setSizes({ height, width });
      };
      firtMount();

      const measure = () => {
        const { height, width } = measuredRef.current.getBoundingClientRect();
        setSizes({ height, width });
      };

      window.addEventListener("resize", measure);

      return () => {
        window.removeEventListener("resize", measure);
      };
    }
  }, [measuredRef]);

  return { measuredRef, width, height };
}
