import { Card, EmptyState, TextStyle } from "@shopify/polaris";

export default function SectionEmptyState() {
  return (
    <Card sectioned>
      <EmptyState heading="Personaliza tus páginas" image="/sectionsLogo.svg">
        <TextStyle variation="subdued">
          Puedes editar tu tienda para personalizar el contenido y el diseño de
          tu tienda online. Cada sección ofrece configuraciones que te permiten
          cambiar el contenido de tu tienda sin editar ningún código.
        </TextStyle>
      </EmptyState>
    </Card>
  );
}
