import React, { useState } from "react";
import {
  Card,
  Checkbox,
  FormLayout,
  RangeSlider,
  Select,
  TextStyle,
} from "@shopify/polaris";
import { useEffect } from "react";

export default function BannerSliderCard({
  item,
  handleMainChange,
  handleDeleteSection,
}) {
  const [banner, setBanner] = useState({
    rotateSliders: false,
    hideOnMobile: false,
    sliderTime: 3,
    design: "",
    sliderHeight: "",
  });

  useEffect(() => {
    const {
      properties: {
        rotateSliders,
        hideOnMobile,
        sliderTime,
        design,
        sliderHeight,
      },
    } = item;

    setBanner((prevState) => ({
      ...prevState,
      rotateSliders: rotateSliders || false,
      hideOnMobile: hideOnMobile || false,
      sliderTime: sliderTime || 3,
      design: design || "full-width",
      sliderHeight: sliderHeight || "medium",
    }));
  }, [item]);

  const handleChange = (field, value) => {
    handleMainChange("properties", { ...banner, [field]: value }, item.id);
    setBanner((prevState) => ({ ...prevState, [field]: value }));
  };

  const { rotateSliders, hideOnMobile, sliderTime, design, sliderHeight } =
    banner;

  return (
    <Card
      primaryFooterAction={{
        content: "Eliminar sección",
        destructive: true,
        outline: true,
        onAction: () => handleDeleteSection(item.id),
      }}
      footerActionAlignment="left"
    >
      <Card.Section>
        <FormLayout>
          <TextStyle variation="strong">Banner slider</TextStyle>
          <Select
            label="Diseño"
            placeholder="Selecciona un diseño"
            options={[
              {
                label: "Ancho completo",
                value: "full-width",
              },
              {
                label: "Ajustado",
                value: "fitted-width",
              },
            ]}
            value={design}
            onChange={(val) => handleChange("design", val)}
          />
          <Select
            label="Altura de la diapositiva"
            placeholder="Selecciona una altura"
            options={[
              {
                label: "Pequeña",
                value: "small",
              },
              {
                label: "Mediana",
                value: "medium",
              },
              {
                label: "Grande",
                value: "large",
              },
            ]}
            value={sliderHeight}
            onChange={(val) => handleChange("sliderHeight", val)}
          />
          <Checkbox
            label="Rotar las diapositivas automáticamente"
            checked={rotateSliders}
            onChange={(val) => handleChange("rotateSliders", val)}
          />
          <div className="flex flex-row items-end max-items">
            <RangeSlider
              label="Segundos para cambiar las diapositivas"
              output
              min={0}
              max={10}
              step={0.2}
              value={sliderTime}
              onChange={(val) => handleChange("sliderTime", val)}
            />
            <div className="ml-6 whitespace-nowrap">
              <TextStyle>{sliderTime}s</TextStyle>
            </div>
          </div>
        </FormLayout>
      </Card.Section>
      <Card.Section title="Diseño para móviles">
        <Checkbox
          label="Ocultar en móviles"
          checked={hideOnMobile}
          onChange={(val) => handleChange("hideOnMobile", val)}
        />
      </Card.Section>
    </Card>
  );
}
