import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Page,
  Layout,
  Card,
  ContextualSaveBar,
  TextField,
  Checkbox,
  Stack,
  Select,
  FormLayout,
  EmptyState,
  Button,
} from "@shopify/polaris";
import {
  Layer,
  CountrySelect,
  ComercioMicrosipSkeleton,
  HelpFooter,
} from "../../components";
import { useHistory, useParams } from "react-router-dom";
import { Estados } from "../../utils/EstadosRepublica";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { PhoneEx } from "../../utils/RegEx";
import { countryCodes } from "../../utils/CountryCodes";
import { useDispatch } from "react-redux";
import { toggleToast } from "../../actions/InteractiveActions";

const estados = Estados.map((item) => {
  return { label: item, value: item };
});

export default function AgregarSucursalScreen({ forbidden }) {
  const { id } = useParams();
  const router = useHistory();
  const dispatch = useDispatch();
  const defaultState = useRef({
    nombre: "",
    permitir_registro: false,
    direccion: "",
    colonia: "",
    ciudad: "",
    codigo_postal: "",
    estado: "",
    pais: "México",
    telefono: "",
    nombre_sucursal: "",
  });
  const [sucursal, setSucursal] = useState({ ...defaultState.current });
  const [countryActive, setCountryActive] = useState(false);
  const [phoneActive, setPhoneActive] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isCreatingSucursal, setIsCreatingSucursal] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFlag, setSelectedFlag] = useState({
    country: {
      code: "MX",
      phone_code: "52",
    },
    phone: {
      code: "MX",
      phone_code: "52",
    },
  });

  useEffect(() => {
    function obtenerSucursal() {
      ApiServiceComercios.obtenerSucursal(id)
        .then(({ sucursal }) => {
          let {
            ciudad = "",
            codigo_postal = "",
            colonia = "",
            direccion = "",
            estado = "",
            nombre = "",
            pais = "",
            telefono = "",
            permitir_registro = false,
            sucursal: nombre_sucursal = "",
          } = sucursal;
          if (!telefono) {
            telefono = "+52";
          }
          setSelectedFlag((s) => ({
            ...s,
            phone: {
              code:
                countryCodes
                  .find(
                    (i) =>
                      i["COUNTRY CODE"] ===
                      telefono.split(" ")[0].replace("+", "")
                  )
                  ["ISO CODES"].split(" / ")[0] || "MX",
              phone_code: telefono.split(" ")[0].slice(1),
            },
            country: {
              ...s.country,
              code: pais
                ? countryCodes
                    .filter((item) => item["COUNTRY"] === pais)[0]
                    ["ISO CODES"].split(" / ")[0]
                : "MX",
            },
          }));
          setSucursal({
            telefono: telefono.split(" ")[1] || "",
            ciudad,
            codigo_postal,
            colonia,
            direccion,
            estado,
            nombre,
            permitir_registro,
            pais: pais ? pais : "México",
            nombre_sucursal,
          });
        })
        .finally(() => setIsLoading(false));
    }

    if (id !== "agregar") {
      setIsNew(false);
      obtenerSucursal();
    } else {
      setIsLoading(false);
    }
  }, [id]);

  const togglePhoneActive = useCallback(
    () => setPhoneActive((active) => !active),
    []
  );
  const toggleActive = useCallback(
    () => setCountryActive((active) => !active),
    []
  );
  const handleFieldChange = useCallback((value, key) => {
    setSucursal((state) => ({ ...state, [key]: value }));
    setIsDirty(true);
  }, []);

  const handlePhoneFieldChange = useCallback((value) => {
    if (PhoneEx.test(value)) {
      setSucursal((state) => ({ ...state, telefono: value }));
      setIsDirty(true);
    }
  }, []);

  function handleSelectCountry(country) {
    setIsDirty(true);
    setSucursal((state) => ({ ...state, pais: country["COUNTRY"] }));
    setSelectedFlag((state) => ({
      ...state,
      country: {
        code: country["ISO CODES"].split(" / ")[0],
        phone_code: country["COUNTRY CODE"],
      },
    }));
    toggleActive();
  }

  function handleSelectPhone(country) {
    setIsDirty(true);
    setSelectedFlag((state) => ({
      ...state,
      phone: {
        code: country["ISO CODES"].split(" / ")[0],
        phone_code: country["COUNTRY CODE"],
      },
    }));
    togglePhoneActive();
  }

  function handleSave() {
    setIsCreatingSucursal(true);
    ApiServiceComercios.crearSucursal({
      ...sucursal,
      telefono: `+${selectedFlag.phone.phone_code}${
        sucursal.telefono.length > 0 ? " " : ""
      }${sucursal.telefono}`,
    }).then(({ message }) => {
      dispatch(toggleToast({ message }));
      setTimeout(() => {
        handleDiscard();
      }, 200);
    });
  }

  function handleUpdate() {
    setIsCreatingSucursal(true);
    ApiServiceComercios.actualizarSucursal(
      {
        ...sucursal,
        telefono: `+${selectedFlag.phone.phone_code}${
          sucursal.telefono.length > 0 ? " " : ""
        }${sucursal.telefono}`,
      },
      id
    ).then(({ message }) => {
      dispatch(toggleToast({ message }));
      setTimeout(() => {
        handleDiscard();
      }, 200);
    });
  }

  function handleDiscard() {
    router.push("/admin/configuracion/sucursales");
  }

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Cambios no guardados"
      saveAction={{
        onAction: isNew ? handleSave : handleUpdate,
        disabled: isCreatingSucursal,
        loading: isCreatingSucursal,
      }}
      discardAction={{
        onAction: handleDiscard,
        disabled: isCreatingSucursal,
      }}
    />
  ) : null;

  if (isLoading) {
    return (
      <Layer title="Sucursal" forbidden={forbidden}>
        <ComercioMicrosipSkeleton />
      </Layer>
    );
  }

  if (id !== "agregar" && !id.match(/^[0-9a-fA-F]{24}$/)) {
    return (
      <Layer title={`Comercio`} forbidden={forbidden}>
        <EmptyState
          heading="Oops! Hubo un problema al intentar obtener esta sucursal"
          action={{
            content: "Volver a intentar",
            onAction: () => window.location.reload(),
          }}
          secondaryAction={{
            content: "Regresar a almacenes",
            url: "/admin/configuracion/sucursales",
          }}
          image="/Ad.svg"
        >
          <p>Intenta de nuevo más tarde</p>
        </EmptyState>
      </Layer>
    );
  }

  return (
    <Layer
      title={isNew ? "Agregar sucursal" : "Editar sucursal"}
      forbidden={forbidden}
    >
      <Page
        breadcrumbs={[
          { content: "Sucursales", url: "/admin/configuracion/sucursales" },
        ]}
        title={isNew ? "Agregar almacén" : "Editar almacén"}
      >
        {contextualSaveBarMarkup}
        <Layout>
          <Layout.AnnotatedSection
            title="Detalles del almacén"
            description="Dale a este almacén un nombre corto para identificarla fácilmente. Verás este nombre en áreas de pedidos."
          >
            <Card sectioned>
              <FormLayout>
                <TextField
                  label="Nombre del almacén"
                  type="text"
                  autoComplete={false}
                  placeholder="Almacén"
                  value={sucursal.nombre}
                  onChange={(text) => handleFieldChange(text, "nombre")}
                />
                <Checkbox
                  checked={sucursal.permitir_registro}
                  label="Permite registrar pedidos y mostrar existencias"
                  helpText="El inventario en esta sucursal está disponible para la venta online."
                  onChange={(val) =>
                    handleFieldChange(val, "permitir_registro")
                  }
                />
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title="Sucursal"
            description="Personaliza las sucursales de Microsip a la que pertenece este almacén."
          >
            <Card sectioned>
              <Stack distribution="fillEvenly" wrap spacing="loose">
                <TextField
                  label="Sucursal"
                  disabled
                  value={sucursal.nombre_sucursal}
                />
              </Stack>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection title="Dirección">
            <Card sectioned>
              <FormLayout>
                <TextField
                  label="Calle y número"
                  type="text"
                  value={sucursal.direccion}
                  onChange={(text) => handleFieldChange(text, "direccion")}
                />
                <TextField
                  label="Colonia"
                  type="text"
                  value={sucursal.colonia}
                  onChange={(text) => handleFieldChange(text, "colonia")}
                />
                <Stack distribution="fill" wrap spacing="loose">
                  <TextField
                    label="Ciudad"
                    type="text"
                    value={sucursal.ciudad}
                    onChange={(text) => handleFieldChange(text, "ciudad")}
                  />
                  <TextField
                    label="Código postal"
                    type="text"
                    value={sucursal.codigo_postal}
                    onChange={(text) =>
                      handleFieldChange(text, "codigo_postal")
                    }
                  />
                </Stack>
                <Stack distribution="fillEvenly" wrap spacing="loose">
                  <Select
                    label="Estado"
                    options={estados}
                    value={sucursal.estado}
                    placeholder="Selecciona un estado"
                    onChange={(text) => handleFieldChange(text, "estado")}
                  />
                  <CountrySelect
                    title="País"
                    active={countryActive}
                    handleSelect={handleSelectCountry}
                    selectedCountry={sucursal.pais}
                    selectedFlag={selectedFlag.country}
                    toggleActive={toggleActive}
                  />
                </Stack>
                <CountrySelect
                  showCode
                  active={phoneActive}
                  handleSelect={handleSelectPhone}
                  selectedFlag={selectedFlag.phone}
                  toggleActive={togglePhoneActive}
                  title="Número de teléfono"
                >
                  <TextField
                    maxLength={10}
                    type="tel"
                    value={sucursal.telefono}
                    onChange={handlePhoneFieldChange}
                  />
                </CountrySelect>
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
        <div className="flex flex-row flex-wrap justify-end my-10">
          <Button disabled={isCreatingSucursal} onClick={handleDiscard}>
            Descartar
          </Button>
          <div className="w-4" />
          <Button
            disabled={isCreatingSucursal}
            loading={isCreatingSucursal}
            primary
            onClick={isNew ? handleSave : handleUpdate}
          >
            Guardar
          </Button>
        </div>

        <HelpFooter
          title="almacenes"
          url="https://help.b2bgo.mx/configuracion/sucursales"
        />
      </Page>
    </Layer>
  );
}
