import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { addClientInfo } from "../../actions/ShoppingCartActions";
import useGetCurrencies from "../../hooks/useGetCurrencies";
import useGetElementSizes from "../../hooks/useGetElementSizes";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import CardItemSkeleton from "../CardItemSkeleton";
import { AddVariantItemModal } from "../../components";
import CardItem from "../common/CardItem";
import useToggle from "../../hooks/useToggle";
import { getDiscounts, getShippingFees } from "../../utils/orderValidations";

export default function OutstandingCollectionStyled({ section, mode }) {
  const router = useHistory();
  const dispatch = useDispatch();

  const [isMobile, setIsMobile] = useState(false);
  const { properties } = section;
  const { measuredRef, width } = useGetElementSizes();
  const [products, setProducts] = useState([]);

  const [isVariantModalOpen, toggleVariantModal] = useToggle();
  const [variantSelected, setVariantSelected] = useState(null);

  const [clienteDB, setCliente] = useState(null);
  const [impuestoDB, setImpuestoDB] = useState(null);
  const [descuentoMaximo, setPorMaximo] = useState();
  const [descuentoVolumen, setPorVolumen] = useState();
  const [descuentoPromocion, setPorPromosGen] = useState();
  const [showStock, setShowStock] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const { currencies, isLoadingCurrencies } = useGetCurrencies();

  const titleSize = {
    small: "24px",
    medium: "40px",
    large: "52px",
  };

  useEffect(() => {
    setIsMobile(width <= 768);
  }, [width]);

  useEffect(() => {
    ApiServiceComercios.obtenerPreferenciasComercio().then(
      ({ preferencia }) => {
        setShowStock(preferencia?.mostrarExistenciaProducto);
      }
    );
  }, []);

  useEffect(() => {
    const { handle, productsNumberInDesktop } = properties;
    if (handle) {
      const fetchData = () => {
        ApiServiceComercios.getArticlesByCollectionsHandle(handle, {
          limit: productsNumberInDesktop,
        })
          .then(({ articles }) => {
            setProducts(articles);
          })
          .finally(() => setLoadingProducts(false));
      };

      function fetchPedidoDatos() {
        ApiServiceComercios.obtenerPedidoDatos().then(
          ({
            ok,
            cliente,
            descuentos,
            impuesto,
            isActivePayment,
            usePaymentsInOrders,
          }) => {
            if (ok) {
              let currency = cliente.moneda;

              let shippingFees = getShippingFees(
                cliente?.envio?.shippingFees,
                currency
              );

              setCliente(cliente);
              setImpuestoDB(impuesto);

              const {
                promos,
                maximos,
                volumenes,
                productsDiscount,
                priceToUse,
              } = getDiscounts(descuentos, cliente);
              setPorPromosGen(promos);
              setPorVolumen(volumenes);
              setPorMaximo(maximos);

              dispatch(
                addClientInfo({
                  limiteCredito: cliente?.limite_credito,
                  clienteEnvio: cliente?.envio,
                  impuesto,
                  moneda: currency,
                  customerData: cliente,
                  shippingFees,
                  discounts: productsDiscount,
                  priceToUse,
                  isActivePayment,
                  vendedor: cliente?.vendedor,
                  status_microsip: cliente?.status_microsip,
                  usePaymentsInOrders,
                })
              );
            }
          }
        );
      }
      fetchPedidoDatos();
      fetchData();
    } else {
      setProducts([]);
    }
  }, [properties, dispatch]);

  const getStyles = (field) => {
    const styles = {
      container: {
        gridTemplateColumns: `repeat(${
          isMobile ? properties.rowsNumberInMobile : 4
        }, minmax(0, 1fr))`,
      },
      heading: {
        fontSize: titleSize[properties.titleSize],
        lineHeight: titleSize[properties.titleSize],
        textAlign: properties.position,
      },
    };
    return styles[field];
  };

  function onToggleVariantModal(_id = "") {
    setVariantSelected(_id);
    toggleVariantModal();
  }

  return (
    <div
      className="flex w-full"
      style={{ height: isMobile && properties.hideOnMobile ? 1 : "auto" }}
      ref={measuredRef}
    >
      {isVariantModalOpen && (
        <AddVariantItemModal
          canShowStock={showStock}
          isOpen={isVariantModalOpen}
          onClose={toggleVariantModal}
          id={variantSelected}
          almacenId={clienteDB?.almacen?.id}
          discounts={{
            descuentoPromocion,
            descuentoVolumen,
            descuentoMaximo,
          }}
          showTax={impuestoDB?.mostrarPrecioConImpuesto}
        />
      )}
      <div
        className="flex-col w-full py-10 bg-white"
        style={{
          display: isMobile && properties.hideOnMobile ? "none" : "flex",
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <p className="mb-6" style={{ ...getStyles("heading") }}>
          {properties.title}
        </p>
        <div
          className="grid gap-x-4 gap-y-7 collection-container self-center"
          style={{ ...getStyles("container") }}
        >
          {loadingProducts && isLoadingCurrencies ? (
            <>
              <CardItemSkeleton />
            </>
          ) : (
            products.map((product, key) => (
              <CardItem
                mode={mode}
                key={key}
                styleCard="OnlineStore"
                discounts={{
                  descuentoPromocion,
                  descuentoVolumen,
                  descuentoMaximo,
                }}
                itemData={product}
                almacenId={clienteDB?.almacen?.id}
                clientCurrency={clienteDB?.moneda}
                currencies={currencies}
                showTax={impuestoDB?.mostrarPrecioConImpuesto}
                onSelectVariant={onToggleVariantModal}
                canShowStock={showStock}
                onClickImage={() =>
                  router.push({
                    pathname: `/products/${product.handle}`,
                    state: {
                      almacenId: clienteDB?.almacen?.id,
                      discounts: {
                        descuentoPromocion,
                        descuentoVolumen,
                        descuentoMaximo,
                      },
                      showTax: impuestoDB?.mostrarPrecioConImpuesto,
                    },
                  })
                }
                properties={properties}
              />
            ))
          )}
        </div>
        {properties.showSeeAllButton && (
          <div className="flex justify-center mt-10">
            <button
              style={{
                backgroundColor: "#1C2260",
                color: "white",
                fontWeight: 600,
                padding: "8px 16px",
                marginTop: 8,
                borderRadius: 4,
              }}
              onClick={() => router.push(`/collections/${properties.handle}`)}
            >
              Ver todos
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
