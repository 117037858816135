import React from "react";
import PropTypes from "prop-types";
import { Banner, Button, Icon, Modal, TextStyle } from "@shopify/polaris";
import * as IconType from "@shopify/polaris-icons";
import { useHistory } from "react-router-dom";
import "./AddDiscountModalStyles.css";
import ability from "../../auth/ability";
import { useSelector } from "react-redux";
import { getPermissions } from "../../reducers";

export default function AddDiscountModal({ toggleModal, isActive }) {
  const router = useHistory();
  const permissions = useSelector(getPermissions);
  const actions = [
    {
      title: "Por cliente",
      subtitle:
        "Registra las diferentes políticas de precios y descuentos por cliente que maneja la empresa.",
      iconName: "CustomerPlusMajor",
      onAction: () => {
        router.push("/admin/descuentos/agregar-by-cliente");
      },
      can: "Cotizaciones",
    },
    {
      title: "Por producto",
      subtitle:
        "Registra las diferentes políticas de descuentos por artículo que maneja la empresa. Para aplicar estas políticas en los pedidos es necesario relacionarlas a los clientes por medio de las políticas de descuentos por cliente.",
      iconName: "AddProductMajor",
      onAction: () => {
        router.push("/admin/descuentos/agregar-by-producto");
      },
      can: "Cotizaciones",
    },
    {
      title: "Por promoción",
      subtitle:
        "Registra las diferentes políticas de descuentos por promociones y ofertas que maneja la empresa.",
      iconName: "DiscountCodeMajor",
      onAction: () => {
        router.push("/admin/descuentos/agregar-by-promocion");
      },
      can: "Cotizaciones",
    },
    {
      title: "Por volumen",
      subtitle:
        "Registra las diferentes políticas de descuentos por volumen que maneja la empresa.",
      iconName: "DiscountAutomaticMajor",
      onAction: () => {
        router.push("/admin/descuentos/agregar-by-volumen");
      },
      can: "Cotizaciones",
    },
    {
      title: "Máximos",
      subtitle:
        "El registro de los descuentos máximos es opcional, tiene como finalidad no permitir que se apliquen descuentos no autorizados al registrar las ventas.",
      iconName: "CircleCancelMajor",
      onAction: () => {
        router.push("/admin/descuentos/agregar-by-maximo");
      },
      can: "Cotizaciones",
    },
  ];

  return (
    <Modal
      title="Agregar descuento"
      onClose={toggleModal}
      open={isActive}
      secondaryActions={[{ content: "Cancelar", onAction: toggleModal }]}
    >
      <Modal.Section>
        {actions
          .filter((i) => ability(permissions).can("read", i.can))
          .map(({ title, subtitle, iconName, onAction }, id) => (
            <Button key={id} onClick={onAction}>
              <div className="flex flex-row px-8 py-6 ">
                <div className="w-9 h-9">
                  <Icon source={IconType[iconName]} backdrop />
                </div>
                <div className="flex flex-col mx-8 items-start text-left">
                  <TextStyle variation="strong">{title}</TextStyle>
                  <TextStyle>{subtitle}</TextStyle>
                </div>
                <div className="w-9 h-9 self-center">
                  <Icon source={IconType["ChevronRightMinor"]} />
                </div>
              </div>
            </Button>
          ))}
        <Banner status="info">
          <p>
            Los clientes pueden combinar descuentos por promoción, por cliente,
            por artículo y por volumen.
          </p>
        </Banner>
      </Modal.Section>
    </Modal>
  );
}

AddDiscountModal.propTypes = {
  toggleModal: PropTypes.func,
  isActive: PropTypes.bool,
};

AddDiscountModal.defaultProps = {
  toggleModal: () => {},
  isActive: false,
};
