import React, { useState } from "react";
import { Button, Spinner } from "@shopify/polaris";
import FileSaver from "file-saver";

import ApiServiceComercios from "../../services/ApiServiceComercios";
import CustomPopover from "../CustomPopover";

export default function DownloadPDFXML({ xml, folioMicrosip, pdf, status }) {
  const [loadings, setLoadings] = useState({
    isLoadingPDF: false,
    isLoadingXML: false,
  });
  const isCanceled = status === "Cancelado";

  function downloadBill(folioMicrosip, tipo, id) {
    setLoadings((s) => ({
      ...s,
      [tipo === "PDF" ? "isLoadingPDF" : "isLoadingXML"]: true,
    }));
    ApiServiceComercios.descargarFactura({ id, tipo })
      .then((response) => {
        const folio =
          folioMicrosip.replace(/\d+/g, "") + folioMicrosip.replace(/\D+/g, "");
        FileSaver.saveAs(response, `factura-${folio}.${tipo.toLowerCase()}`);
      })
      .finally(() =>
        setLoadings((s) => ({ ...s, isLoadingPDF: false, isLoadingXML: false }))
      );
  }

  const Activator = ({ onClick }) => {
    return (
      <div className="no-underline text-gray-600 z-50 div-no-grap">
        <Button
          disabled={isCanceled}
          onClick={onClick}
          disclosure
          plain
          textAlign="left"
          monochrome
        >
          Descargar
        </Button>
      </div>
    );
  };

  const items = [
    {
      content: "PDF",
      onAction: () => downloadBill(folioMicrosip, "PDF", pdf),
      disabled: xml === "N" || isCanceled,
      prefix: loadings.isLoadingPDF && <Spinner size="small" />,
    },
    {
      content: "XML",
      onAction: () => downloadBill(folioMicrosip, "XML", pdf),
      disabled: xml === "N" || status === "Cancelado",
      prefix: loadings.isLoadingXML && <Spinner size="small" />,
    },
  ];

  return (
    <div className="w-full h-full cursor pointer p-6 whitespace-nowrap">
      <CustomPopover items={items} activator={Activator} />
    </div>
  );
}
