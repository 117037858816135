import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import {
  Card,
  Checkbox,
  FormLayout,
  Modal,
  Select,
  Stack,
  TextField,
  Button,
  TextStyle,
  Autocomplete,
} from "@shopify/polaris";
import { CountrySelect, CustomPopover } from "..";
import { countryCodes } from "../../utils/CountryCodes";
import { Ciudades } from "../../utils/Ciudades";
import { Estados } from "../../utils/EstadosRepublica";
import { EmailEx } from "../../utils/RegEx";
import { CountrySelectTextField } from "../CountrySelect/CountrySelect";
import useToggle from "../../hooks/useToggle";

const estados = Estados.map((item) => {
  return { label: item, value: item };
});

export default function EditContactDirectionModal({
  isOpen,
  handleChange,
  direcciones = [],
  title = "",
  direccionEnvio = {},
  direccionFacturacion = {},
  handleSave,
  isNewDirection = false,
}) {
  const [phoneActive, togglePhoneActive] = useToggle(false);
  const [selectedFlag, setSelectedFlag] = useState({
    country: { code: "MX", phone_code: "52" },
    phone: { code: "MX", phone_code: "52" },
  });
  const [direccion, setDireccion] = useState({
    consignatario: "",
    rfc: "",
    contacto: "",
    email: "",
    telefono: "",
    direccion: "",
    colonia: "",
    ciudad: "",
    codigo_postal: "",
    estado: "",
    pais: "",
    usarParaEnvios: false,
    usarParaFacturar: false,
  });

  const deselectedOptions = Ciudades.map((i) => ({ label: i, value: i }));
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [fieldsError, setFieldsError] = useState({
    email: "",
    consignatario: "",
  });

  useEffect(() => {
    let direction = {};
    if (direcciones.length > 0) {
      direction = title === "envío" ? direccionEnvio : direccionFacturacion;

      let { telefono, pais } = direction;
      if (
        !pais ||
        pais === "MEXICO" ||
        pais === "Mexico" ||
        pais === "MÉXICO"
      ) {
        pais = "México";
      }

      if (telefono) {
        let code = ["52"];
        if (telefono.indexOf("+") >= 0) {
          code = telefono.split(" ");
        }

        let lada = "";
        if (code.length > 1) {
          lada = (code.length > 1 ? code[0] : "52").replace("+", "");
        } else {
          lada = "52";
        }
        const phoneCode = countryCodes.find((i) => i["COUNTRY"] === pais);
        const countryCode = countryCodes.find(
          (i) => i["COUNTRY CODE"] === lada
        );

        setSelectedFlag({
          phone: {
            code: countryCode["ISO CODES"].split(" / ")[0] || "MX",
            phone_code: countryCode["COUNTRY CODE"] || "52",
          },
          country: {
            code: phoneCode["ISO CODES"].split(" / ")[0] || "MX",
            phone_code: phoneCode["COUNTRY CODE"] || "52",
          },
        });
      }

      pais = pais.toLowerCase();
      pais = pais.charAt(0).toUpperCase() + pais.slice(1);

      setDireccion((state) => ({ ...state, ...direction, pais, telefono }));
    }
  }, [direccionEnvio, direccionFacturacion, title, direcciones.length]);

  function updateSelection(selected) {
    const selectedValue = deselectedOptions.find(
      (i) => i.value === selected[0]
    )["label"];
    setSelectedOptions(selected);
    handleChangeText(selectedValue, "ciudad");
  }

  const updateText = useCallback(
    (value) => {
      handleChangeText(value, "ciudad");

      if (value === "") {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  function handleChangeText(value, key) {
    setDireccion((s) => ({ ...s, [key]: value }));
  }

  function handleSelectPhone(country) {
    setSelectedFlag((state) => ({
      ...state,
      phone: {
        code: country["ISO CODES"].split(" / ")[0],
        phone_code: country["COUNTRY CODE"],
      },
    }));
    togglePhoneActive();
  }

  function onDirectionSelection(index) {
    let { telefono, pais = "México" } = direcciones[index];

    pais = pais.toLowerCase();
    pais = pais.charAt(0).toUpperCase() + pais.slice(1);

    if (!pais) pais = "México";
    if (!telefono) telefono = "+52";

    setDireccion((state) => ({ ...state, ...direcciones[index] }));
    const country = countryCodes.find(
      (i) =>
        i["COUNTRY CODE"] ===
        (telefono.indexOf("+") >= 0 ? telefono : "+52 ")
          .split(" ")[0]
          .replace("+", "")
    );
    const phone = countryCodes.find((i) => i["COUNTRY"] === pais);
    setSelectedFlag({
      phone: {
        code: country["ISO CODES"].split(" / ")[0] || "MX",
        phone_code: country["COUNTRY CODE"] || "52",
      },
      country: {
        code: phone["ISO CODES"].split(" / ")[0] || "MX",
        phone_code: phone["COUNTRY CODE"] || "52",
      },
    });
  }

  function onSave() {
    handleSave(direccion, isNewDirection);
  }

  function handleOnBlur(value, key) {
    if (!value) {
      setFieldsError((state) => ({
        ...state,
        [key]: `El ${key} es necesario`,
      }));
    } else {
      setFieldsError((state) => ({ ...state, [key]: "" }));
    }
  }

  function handleEmailOnBlur() {
    if (!EmailEx.test(direccion.email)) {
      setFieldsError((state) => ({
        ...state,
        email: "Ingresa una dirección de correo electrónico válida",
      }));
    } else {
      setFieldsError((state) => ({ ...state, email: "" }));
    }
  }

  const directions = direcciones.map((direccion, index) => {
    return {
      content: (
        <div className="flex flex-col">
          <TextStyle>{direccion.consignatario}</TextStyle>
          <TextStyle>{direccion.direccion}</TextStyle>
          <TextStyle>
            {direccion.codigo_postal} {direccion.estado}
          </TextStyle>
          <TextStyle>{direccion.pais}</TextStyle>
        </div>
      ),
      onAction: () => onDirectionSelection(index),
    };
  });

  const textFieldCity = (
    <Autocomplete.TextField
      onChange={updateText}
      label="Ciudad"
      value={direccion.ciudad}
      placeholder="Selecciona una ciudad"
    />
  );

  const Activator = ({ onClick }) => {
    return (
      <Button onClick={onClick} disclosure textAlign="left">
        Selecciona otra dirección
      </Button>
    );
  };

  return (
    <Modal
      title={`Editar dirección de ${title}`}
      open={isOpen}
      onClose={handleChange}
      secondaryActions={[
        {
          content: "Cancelar",
          onAction: handleChange,
        },
      ]}
      primaryAction={{
        content: "Guardar",
        onAction: onSave,
        disabled:
          _.isEqual(
            title === "envío" ? direccionEnvio : direccionFacturacion,
            direccion
          ) ||
          fieldsError.consignatario ||
          fieldsError.email,
      }}
    >
      <Card sectioned>
        <FormLayout>
          {!isNewDirection && (
            <div className="w-1/2">
              <CustomPopover items={directions} activator={Activator} />
            </div>
          )}
          <TextField
            value={direccion.consignatario}
            onChange={(value) => handleChangeText(value, "consignatario")}
            label="Nombre"
            placeholder="Nombre de la dirección"
            onBlur={() =>
              handleOnBlur(direccion.consignatario, "consignatario")
            }
            error={fieldsError.consignatario}
          />
          <Stack distribution="fillEvenly" wrap>
            <TextField
              value={direccion.rfc}
              onChange={(value) => handleChangeText(value, "rfc")}
              label="RFC"
              placeholder="RFC"
            />
            <TextField
              value={direccion.contacto}
              onChange={(value) => handleChangeText(value, "contacto")}
              label="Contacto"
              placeholder="Contacto"
            />
          </Stack>
          <Stack distribution="fillEvenly" wrap>
            <TextField
              value={direccion.email}
              onChange={(value) => handleChangeText(value, "email")}
              label="Correo electrónico"
              placeholder="ejemplo@mail.com"
              onBlur={handleEmailOnBlur}
              error={fieldsError.email}
            />

            <CountrySelect
              showCode
              active={phoneActive}
              handleSelect={handleSelectPhone}
              selectedFlag={selectedFlag.phone}
              toggleActive={togglePhoneActive}
              title="Teléfono"
            >
              <TextField
                maxLength={10}
                type="tel"
                value={direccion.telefono}
                onChange={(text) => handleChangeText(text, "telefono")}
              />
            </CountrySelect>
          </Stack>
          <Stack distribution="fillEvenly" wrap>
            <TextField
              value={direccion.direccion}
              onChange={(value) => handleChangeText(value, "direccion")}
              label="Dirección"
            />
            <TextField
              value={direccion.colonia}
              onChange={(value) => handleChangeText(value, "colonia")}
              label="Colonia"
            />
          </Stack>
          <Stack distribution="fillEvenly" wrap>
            <Autocomplete
              options={options}
              selected={selectedOptions}
              onSelect={updateSelection}
              textField={textFieldCity}
            />
            <TextField
              value={direccion.codigo_postal}
              onChange={(value) => handleChangeText(value, "codigo_postal")}
              label="Código postal"
            />
          </Stack>
          <Stack distribution="fillEvenly" wrap>
            <Select
              value={direccion.estado}
              onChange={(value) => handleChangeText(value, "estado")}
              label="Estado"
              options={estados}
              placeholder="Selecciona un estado"
            />
            <CountrySelectTextField
              handleChangeText={(value) => handleChangeText(value, "pais")}
              value={direccion.pais}
            />
          </Stack>
          <Checkbox
            checked={direccion.usarParaEnvios}
            onChange={(value) => handleChangeText(value, "usarParaEnvios")}
            label="Usar dirección para envíos"
          />
          <Checkbox
            checked={direccion.usarParaFacturar}
            onChange={(value) => handleChangeText(value, "usarParaFacturar")}
            label="Usar dirección para facturar"
          />
        </FormLayout>
      </Card>
    </Modal>
  );
}
