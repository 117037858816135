export const typeTeam = {
    ADMINISTRADOR: { name: 'administrador', type: 1},
    COLABORADOR: { name: 'colaborador', tyoe: 2},
    ADMINISTRADOR_COMERCIO: { name: 'colaborador', type: 3}
}

export const optionsMedida = [
    {label: 'Bolsa', value: 'Bolsa'},
    {label: 'Caja', value: 'Caja'},
    {label: 'Juego', value: 'Juego'},
    {label: 'KG', value: 'KG'},
    {label: 'KILO', value: 'KILO'},
    {label: 'METRO', value: 'METRO'},
    {label: 'MTO', value: 'MTO'},
    {label: 'PAR', value: 'PAR'},
    {label: 'Pieza', value: 'Pieza'},
    {label: 'PIEZA', value: 'PIEZA'},
 ];