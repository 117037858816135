import React from 'react';
import { useSelector } from 'react-redux';
import { getArticulos, getPaginationArticulos } from '../../reducers';

export default function ArticulosContainer({children}) {
   const isLoading = useSelector((state) => state.Articulos.isLoading);
   const articulos = useSelector(getArticulos).map((item) => {return {...item, id: item._id}});
   const pagination = useSelector(getPaginationArticulos);

   return <React.Fragment>{children({isLoading, articulos, pagination})}</React.Fragment>
}
