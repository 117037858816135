import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import useAbility from "../../hooks/useAbility";
import { getAuthData } from "../../reducers";

export default function Layer({ children, title = "" }) {
  const isForbiddenOnlineStore = useAbility("read", "Tienda en línea");
  const [isMounted, setIsMounted] = useState(false);
  let { grant_type } = useSelector(getAuthData);

  useEffect(() => {
    document.title = title || "B2BGO";
    return () => null;
  }, [title]);
  useEffect(() => {
    setTimeout(() => {
      setIsMounted(true);
    }, 100);
  }, []);

  if (
    isMounted &&
    ["CLIENTE", "CONTACT"].includes(grant_type) &&
    isForbiddenOnlineStore
  ) {
    return <Redirect to="/cliente" />;
  }

  return <>{children}</>;
}
