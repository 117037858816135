import { ActionList, Icon, Popover } from "@shopify/polaris";
import useToggle from "../../hooks/useToggle";
import { PAGE_SECTIONS_LIST } from "../../utils/constants";
import * as Icons from "@shopify/polaris-icons";

export default function AddSection({ onAddItem }) {
  const [active, toggleActive] = useToggle(false);

  const activator = (
    <div className="flex main-button">
      <button
        type="button"
        className="relative Polaris-Navigation__Item"
        onClick={toggleActive}
      >
        <div
          style={{
            marginTop: "0.6rem",
            marginRight: "1.6rem",
            marginBottom: "0.6rem",
          }}
        >
          <Icon source={Icons["AddMajor"]} color="interactive" />
        </div>
        <span className="Polaris-Navigation__Text text-blue-600">
          Agregar sección
        </span>
      </button>
    </div>
  );

  return (
    <Popover active={active} onClose={toggleActive} activator={activator}>
      <ActionList
        actionRole="menuitem"
        items={PAGE_SECTIONS_LIST.map((section) => ({
          content: section.label,
          icon: Icons[section.icon],
          onAction: () => {
            onAddItem(section.type);
            toggleActive();
          },
        }))}
      />
    </Popover>
  );
}
