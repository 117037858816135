import { Button, Heading, Scrollable, Sheet } from "@shopify/polaris";
import { MobileCancelMajor } from "@shopify/polaris-icons";
import React from "react";
import useWindowSize from "../../hooks/useWindowSize";

export default function ChooseComponent({
  children,
  handleRemoveFilters,
  isOpen,
  toggleActive,
  optionsFilters,
  mode,
  hasArticleOptions,
}) {
  const size = useWindowSize();

  return size.width < 768 ? (
    <CompactComponent
      handleRemoveFilters={handleRemoveFilters}
      isOpen={isOpen}
      toggleActive={toggleActive}
      optionsFilters={optionsFilters}
      mode={mode}
      hasArticleOptions={hasArticleOptions}
    >
      {children}
    </CompactComponent>
  ) : (
    <WideComponent
      mode={mode}
      handleRemoveFilters={handleRemoveFilters}
      optionsFilters={optionsFilters}
      hasArticleOptions={hasArticleOptions}
    >
      {children}
    </WideComponent>
  );
}

export const WideComponent = ({
  children = null,
  handleRemoveFilters = () => {},
  optionsFilters,
  mode = null,
  hasArticleOptions,
}) => {
  const { useBrand, usePrice, useProductType } = optionsFilters;

  return (
    <div className="flex flex-col whitespace-nowrap">
      {children}

      {(useBrand ||
        usePrice ||
        useProductType ||
        hasArticleOptions ||
        mode === "list") && (
        <div className="flex flex-col">
          <div className="flex mt-8 ml-8">
            <Button onClick={handleRemoveFilters} plain>
              Limpiar filtros
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export const CompactComponent = ({
  children = null,
  isOpen = false,
  toggleActive = () => {},
  handleRemoveFilters = () => {},
  optionsFilters,
  mode,
  hasArticleOptions,
}) => {
  const { useBrand, usePrice, useProductType } = optionsFilters;

  return (
    <Sheet open={isOpen} onClose={toggleActive}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div
          style={{
            alignItems: "center",
            borderBottom: "1px solid #DFE3E8",
            display: "flex",
            justifyContent: "space-between",
            padding: "1.6rem",
            width: "100%",
          }}
        >
          <Heading>Filtros</Heading>
          <Button
            accessibilityLabel="Cancelar"
            icon={MobileCancelMajor}
            onClick={toggleActive}
            plain
          />
        </div>
        <Scrollable
          style={{ paddingTop: 0, paddingBottom: "1.6rem", height: "100%" }}
        >
          <div className="flex flex-col">
            {children}

            {(useBrand ||
              usePrice ||
              useProductType ||
              hasArticleOptions ||
              mode === "list") && (
              <div className="flex my-8 ml-8">
                <Button onClick={handleRemoveFilters} plain>
                  Limpiar filtros
                </Button>
              </div>
            )}
          </div>
          <div
            style={{
              alignItems: "center",
              borderTop: "1px solid #DFE3E8",
              display: "flex",
              justifyContent: "space-between",
              padding: "1.6rem",
              width: "100%",
            }}
          >
            <Button onClick={toggleActive}>Cancelar</Button>
            <Button primary onClick={toggleActive}>
              Listo
            </Button>
          </div>
        </Scrollable>
      </div>
    </Sheet>
  );
};
