import _ from "lodash";

export const getRandomId = () =>
  Math.floor(Math.random() * Date.now()).toString(16);

export const getMovimientos = (vencido = [], porVencer = []) => {
  let newVencidos = vencido.map((item) => ({
    ...item,
    porVencer: "",
    dias1_30: _.inRange(item.atraso, 1, 30) ? "$" + item.saldo : "",
    dias31_60: _.inRange(item.atraso, 31, 60) ? "$" + item.saldo : "",
    dias61_mas: item.atraso > 60 ? "$" + item.saldo : "",
  }));
  let newVencer = porVencer.map((item) => ({
    ...item,
    atraso: 0,
    porVencer: "$" + item.saldo,
    dias1_30: "",
    dias31_60: "",
    dias61_mas: "",
  }));

  return [...newVencidos, ...newVencer];
};
