import { Button, InlineError, Stack } from "@shopify/polaris";
import { PaymentElement } from "@stripe/react-stripe-js";
import { formatTo2Digits } from "../../../utils/Formats";

const CustomOrderForm = ({
  handleSubmit,
  totalAmountToPay,
  processingPayment,
  message,
  onCancel,
}) => {
  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement />
      <hr className="my-8" />
      <div className="flex justify-end">
        <InlineError message={message} fieldID="myFieldID" />
      </div>
      <div className="flex justify-end mt-8">
        <Stack>
          <Button disabled={processingPayment} onClick={onCancel}>
            Cancelar
          </Button>
          <Button primary submit id="submit" loading={processingPayment}>
            Pagar {formatTo2Digits(totalAmountToPay)}
          </Button>
        </Stack>
      </div>
    </form>
  );
};
export default CustomOrderForm;
