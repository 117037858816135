const Colors = {
  main: "#1C2260",
  purple: "#9C6ADE",
  darkPurple: "#8140D7",
  dark: "#212B36",
  greenCake: "#47C1BF",
  linkBlue: "#006fbb",
  greenActive: "#BBE5B3",
  orangeDisabled: "#FFC48B",
  subdued: "#919EAB",
  linkButton: "#2C6ECB",
  subduedButton: "#5C5F62",
};

export default Colors;
