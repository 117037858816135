import { Colors } from "../styles";
import { getContrastYIQ } from "./getContrast";

export const validateUrl = (tipo, id) => {
  switch (tipo) {
    case 1:
      return `/admin/descuentos/by-cliente/${id}`;

    case 2:
      return `/admin/descuentos/by-producto/${id}`;

    case 3:
      return `/admin/descuentos/by-promocion/${id}`;

    case 4:
      return `/admin/descuentos/by-volumen/${id}`;

    case 5:
      return `/admin/descuentos/by-maximo/${id}`;

    default:
      return "";
  }
};

// Configuración del chat
export const chatIntegration = (chat) => {
  try {
    if (chat && chat.tipo && chat.script.length > 0) {
      switch (chat.tipo) {
        case "GORGIAS": {
          const gorgias = document.getElementById("gorgias-chat");
          if (gorgias.childElementCount === 0) {
            let text = "";
            let src = "";
            const fragments = document
              .createRange()
              .createContextualFragment(chat.script);
            const scripts = fragments.querySelectorAll("script");
            scripts.forEach((scr) => {
              if (scr.innerHTML.length > 0) text = scr.innerHTML;
              if (scr.src.length > 0) src = scr.src;
            });

            const script1 = document.createElement("script");
            const script2 = document.createElement("script");
            script1.type = script2.type = "text/javascript";
            script1.text = text;
            script2.src = src;
            script2.defer = true;
            gorgias.appendChild(script1);
            gorgias.appendChild(script2);
          }
          break;
        }
        case "TAWKTO": {
          const tawkto = document.getElementById("tawkToChat");
          const fragments = document
            .createRange()
            .createContextualFragment(chat.script);
          const scriptData = fragments.querySelector("script");
          if (tawkto.childElementCount === 0) {
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.text = scriptData.innerText;
            tawkto.appendChild(script);
          }
          break;
        }

        default:
          break;
      }
    }
  } catch (error) {}
};

export const validateLogo = ({
  url_imagen,
  nombre_comercio,
  primaryColor,
  grant_type,
}) => {
  setTimeout(() => {
    // Logo configuration
    let logo = document.querySelector("div.Polaris-TopBar__LogoContainer");
    if (logo && !url_imagen) {
      logo.classList.add("flex");
      if (logo.childElementCount === 1) {
        let nombre = document.createElement("h1");
        nombre.appendChild(document.createTextNode(nombre_comercio ?? "B2BGo"));
        nombre.setAttribute(
          "class",
          `mx-4 text-4xl font-bold self-center store-name ${
            primaryColor
              ? getContrastYIQ(primaryColor) === "light"
                ? "text-white"
                : "text-black"
              : "text-white"
          }`
        );
        logo.appendChild(nombre);
      }
    }
    if (document.getElementsByClassName("Polaris-TopBar").length > 0) {
      document.getElementsByClassName(
        "Polaris-TopBar"
      )[0].style.backgroundColor =
        ["CLIENTE", "CONTACT"].includes(grant_type) && primaryColor
          ? primaryColor
          : Colors.main;
    }
  }, 0);
};
