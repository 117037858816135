import React, { useState, useCallback, useEffect, useRef } from "react";
import { Page, Card, Tabs, FooterHelp, Link } from "@shopify/polaris";
import { useDispatch, useSelector } from "react-redux";
import {
  Layer,
  PreliminaresClienteList,
  ImportPreliminaresModal,
  FooterCliente,
  CustomerStatusBanner,
} from "../../components";
import { TabsPedidosPreliminaresClienteScreen as tabs } from "../../utils/Tabs";
import {
  fetchPreliminares,
  resetPage,
} from "../../actions/PreliminaresActions";
import PreliminaresContainer from "../../containers/PreliminaresContainer";
import useDebounce from "../../services/useDebounce";
import useAbility from "../../hooks/useAbility";
import useToggle from "../../hooks/useToggle";
import { getPaginationPreliminares } from "../../reducers";
import ApiServiceComercios from "../../services/ApiServiceComercios";

export default function PedidosPreliminaresClienteScreen({ forbidden }) {
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(),
  });

  const dispatch = useDispatch();
  const [fecha, setFecha] = useState("todos");
  const isForbiddenCreate = useAbility("write", "Preliminares");
  const pagination = useSelector(getPaginationPreliminares);
  const [isModalActive, handleChange] = useToggle(false);
  const [tabSelected, setTabSelected] = useState(0);
  const [queryValue, setQueryValue] = useState("");
  const [estatusPedido, setEstatusPedido] = useState([]);
  const [creatorSelected, setCreatorSelected] = useState([]);
  const [vendorPedido, setVendorPedido] = useState([]);
  const [shippingMethod, setShippingMethod] = useState([]);
  const [orderSelected, setOrderSelected] = useState(["folio"]);
  const [orden, setOrder] = useState(["-1"]);
  const [statusMicrosip, setStatusMicrosip] = useState("");

  const queryValueRef = useRef();
  const debouncedSearchTerm = useDebounce(queryValue, 900);

  useEffect(() => {
    try {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    queryValueRef.current = queryValue;
  });

  useEffect(() => {
    const getCustomerStatus = () => {
      ApiServiceComercios.getCustomerStatus().then(({ customer }) => {
        setStatusMicrosip(customer?.status_microsip);
      });
    };
    getCustomerStatus();
  }, []);

  useEffect(() => {
    function fetchData() {
      dispatch(
        fetchPreliminares({
          byCliente: true,
          sort: orderSelected[0],
          query: queryValueRef.current,
          orden: orden[0],
          estatus: JSON.stringify([
            ...new Set(
              estatusPedido.concat(
                tabSelected === 1 ? [] : [tabs[tabSelected].panelID]
              )
            ),
          ]),
          vendors: JSON.stringify(vendorPedido),
          createdBy: JSON.stringify(creatorSelected),
          shippingMethod: JSON.stringify(shippingMethod),
          fecha: fecha === "todos" ? undefined : selectedDates,
        })
      );
    }
    fetchData();
  }, [
    dispatch,
    orderSelected,
    orden,
    estatusPedido,
    vendorPedido,
    shippingMethod,
    creatorSelected,
    tabSelected,
    fecha,
    debouncedSearchTerm,
    selectedDates,
    pagination.currentPage,
  ]);

  const handleRadioChange = useCallback(
    (_checked, newValue) => setFecha(newValue),
    []
  );

  function handleFiltersQueryChange(value) {
    setQueryValue(value);
  }

  function handleChangeStates(value, setState, resetTab = false) {
    resetTab && setTabSelected(0);
    resetPagination();
    setState(value);
  }

  function resetPagination() {
    dispatch(resetPage());
  }

  return (
    <Layer title="Pedidos Preliminares" forbidden={forbidden}>
      <Page
        fullWidth
        title="Preliminares"
        // Feature
        secondaryActions={[
          {
            content: "Importar",
            onAction: handleChange,
          },
        ]}
        primaryAction={
          !isForbiddenCreate
            ? {
                content: "Crear preliminar",
                primary: true,
                url: "/cliente/pedidos/preliminares/agregar",
              }
            : undefined
        }
      >
        {isModalActive && (
          <ImportPreliminaresModal
            isActive={isModalActive}
            handleChange={handleChange}
            type="cliente"
          />
        )}
        <CustomerStatusBanner status={statusMicrosip} />
        <Card>
          <PreliminaresContainer>
            {({ isLoading, preliminares, pagination }) => {
              return (
                <Tabs
                  tabs={tabs}
                  selected={tabSelected}
                  onSelect={(value) =>
                    handleChangeStates(value, setTabSelected, true)
                  }
                >
                  <PreliminaresClienteList
                    fecha={fecha}
                    setSelectedDates={(value, label) =>
                      handleChangeStates(value, setSelectedDates, true)
                    }
                    selectedDates={selectedDates}
                    handleDateRemove={() =>
                      handleChangeStates("todos", setFecha, true)
                    }
                    handleRadioChange={handleRadioChange}
                    tabSelected={tabSelected}
                    orden={orden}
                    orderSelected={orderSelected}
                    preliminares={preliminares}
                    isLoading={isLoading}
                    pagination={pagination}
                    queryValue={queryValue}
                    estatusPedido={estatusPedido}
                    creatorSelected={creatorSelected}
                    shippingMethod={shippingMethod}
                    vendorPedido={vendorPedido}
                    handleOrdenChange={(value) =>
                      handleChangeStates(value, setOrder)
                    }
                    handleOrderSelectedChange={(value) =>
                      handleChangeStates(value, setOrderSelected)
                    }
                    handleOrderSelectedRemove={() =>
                      handleChangeStates(["folio"], setOrderSelected)
                    }
                    handleFiltersQueryChange={handleFiltersQueryChange}
                    handleQueryValueRemove={() => setQueryValue("")}
                    handleEstatusPedidoChange={(value) =>
                      handleChangeStates(value, setEstatusPedido, true)
                    }
                    handleEstatusPedidoRemove={() =>
                      handleChangeStates([], setEstatusPedido, true)
                    }
                    handleVendorPedidoChange={(value) =>
                      handleChangeStates(value, setVendorPedido, true)
                    }
                    handleVendorPedidoRemove={() =>
                      handleChangeStates([], setVendorPedido, true)
                    }
                    handleShippingMethodChange={(value) =>
                      handleChangeStates(value, setShippingMethod, true)
                    }
                    handleShippingMethodRemove={() =>
                      handleChangeStates([], setShippingMethod, true)
                    }
                    handleCreatorSelectedChange={(value) =>
                      handleChangeStates(value, setCreatorSelected, true)
                    }
                    handleCreatorSelectedRemove={() =>
                      handleChangeStates([], setCreatorSelected, true)
                    }
                  />
                </Tabs>
              );
            }}
          </PreliminaresContainer>
        </Card>
        <FooterHelp>
          Más información de <Link>pedidos preliminares</Link>
        </FooterHelp>
      </Page>
      <FooterCliente />
    </Layer>
  );
}
