import {
  Card,
  Icon,
  SkeletonBodyText,
  SkeletonDisplayText,
  TextStyle,
} from "@shopify/polaris";
import {
  CircleAlertMajor,
  CircleTickOutlineMinor,
} from "@shopify/polaris-icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toggleToast } from "../../actions/InteractiveActions";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { formatTo2Digits } from "../../utils/Formats";

export default function PaymentDetailCard({ payment }) {
  const dispatch = useDispatch();
  const router = useHistory();
  const [paymentData, setPaymentData] = useState({});
  const [loading, setLoading] = useState(false);
  const [manualPaymentData, setManualPaymentData] = useState({});
  const [isMarkingAsPaid, setIsMarkingAsPaid] = useState(false);

  useEffect(() => {
    if (payment) {
      setPaymentData(payment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment]);

  const validateStyles = () => {
    return paymentData.status === "pending"
      ? "border-yellow-300"
      : payment?.status === "succeeded"
      ? "border-green-300"
      : "border-red-300";
  };

  const validateIcon = () => {
    return paymentData.status === "pending"
      ? CircleAlertMajor
      : payment?.status === "succeeded"
      ? CircleTickOutlineMinor
      : CircleAlertMajor;
  };

  const validateTitle = () => {
    return paymentData.status === "pending"
      ? "Pendiente"
      : payment?.status === "succeeded"
      ? "Pagado"
      : "Fallido";
  };

  useEffect(() => {
    if (paymentData) {
      if (paymentData?.manualPayment?._id) {
        const getManualPayment = async () => {
          setLoading(true);
          await ApiServiceComercios.getManualPaymentById(
            paymentData?.manualPayment?._id
          )
            .then(({ manualPayment = {} }) => {
              setManualPaymentData(manualPayment);
            })
            .finally(() => setLoading(false));
        };
        getManualPayment();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentData]);

  const showPaymentMethod = () => {
    if (paymentData?.paymentMethod?.brand !== "") {
      return (
        capitalizeFirstLetter(paymentData?.paymentMethod?.brand || "") +
        " ***** " +
        paymentData?.paymentMethod?.last4
      );
    } else {
      return manualPaymentData?.name;
    }
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  function onMarkAsPaid() {
    setIsMarkingAsPaid(true);
    const id = paymentData?._id;
    ApiServiceComercios.markAsPaid(id, { status: "succeeded" })
      .then(({ message }) => {
        dispatch(toggleToast({ message }));
        setIsMarkingAsPaid(false);
        router.push("/admin/pagos");
      })
      .catch((err) => {});
  }

  if (loading) {
    return (
      <Card>
        <Card.Section>
          <SkeletonDisplayText size="small" />
          <div className="my-10" />
          <SkeletonBodyText lines={2} />
        </Card.Section>
      </Card>
    );
  }

  return (
    <Card
      fullWidth
      primaryFooterAction={{
        content: "Marcar como pagado",
        disabled: paymentData.status === "succeeded" || isMarkingAsPaid,
        onAction: onMarkAsPaid,
        loading: isMarkingAsPaid,
      }}
    >
      <Card.Section>
        <div className="flex flex-col">
          <div className="flex items-center">
            <div
              className={`flex rounded-full border-4 mr-6 ${validateStyles()}`}
            >
              <Icon
                source={validateIcon()}
                color={
                  paymentData.status === "succeeded" ? "success" : "warning"
                }
              />
            </div>
            <TextStyle variation="strong">{validateTitle()}</TextStyle>
          </div>
          <div className="flex justify-between my-6">
            <TextStyle variation="strong">
              Total {paymentData?.currency?.toUpperCase() ?? ""}
            </TextStyle>
            <TextStyle variation="strong">
              {formatTo2Digits(paymentData.amount)}
            </TextStyle>
          </div>
          <div className="flex flex-col my-6">
            <TextStyle variation="subdued">Forma de pago</TextStyle>
            <TextStyle>{showPaymentMethod()}</TextStyle>
          </div>
        </div>
      </Card.Section>
    </Card>
  );
}
