import ApiServiceComercios from "../services/ApiServiceComercios";
import NormalizeById from "../utils/NormalizedById";
import { getPaginationPayments } from "../reducers";

export const PAYMENTS_BEGIN = "PAYMENTS_BEGIN";
export const ADD_PAYMENTS = "ADD_PAYMENTS";
export const PAYMENTSS_FAILED = "PAYMENTSS_FAILED";
export const CLEAR_PAYMENTS = "CLEAR_PAYMENTS";
export const RESET_PAGE = "RESET_PAGE";
export const ADD_ID_PAYMENT = "ADD_ID_PAYMENT";
export const CHANGE_PAGE = "CHANGE_PAGE";

export function fetchPayments(params) {
  return async (dispatch, getState) => {
    let pagination = getPaginationPayments(getState());
    dispatch(clearPayments());
    dispatch(paymentsBegin());
    return ApiServiceComercios.getPayments({
      ...params,
      limit: pagination.sizePerPage,
      skip: pagination.currentPage * pagination.sizePerPage,
    })
      .then((result) => {
        let data = NormalizeById(result.payments, "_id");
        data["total"] = result.total;
        data["allIdsDB"] = result.allIds;
        dispatch(addPayments(data));
        return result;
      })
      .catch((err) => {
        dispatch(paymentsFailed());
      });
  };
}

export const paymentsBegin = () => ({
  type: PAYMENTS_BEGIN,
});

export const addPayments = (data) => ({
  type: ADD_PAYMENTS,
  payload: { data },
});

export const paymentsFailed = () => ({
  type: PAYMENTSS_FAILED,
});

export const clearPayments = () => ({
  type: CLEAR_PAYMENTS,
});

export const changePage = (currentPage) => ({
  type: CHANGE_PAGE,
  payload: { data: { currentPage } },
});

export const resetPage = () => ({
  type: RESET_PAGE,
});

export const addIdPayment = (id) => ({
  type: ADD_ID_PAYMENT,
  payload: { id },
});
