import React, { useEffect, useState } from "react";
import {
  Card,
  FormLayout,
  Modal,
  OptionList,
  Popover,
  Spinner,
  TextField,
} from "@shopify/polaris";

import { EnlaceDetail } from "./EnlaceDetail";
import { useDispatch } from "react-redux";
import { toggleToast } from "../../../actions/InteractiveActions";
import ApiServiceComercios from "../../../services/ApiServiceComercios";
import useDebounce from "../../../services/useDebounce";
import { enlaceOptions } from "../../../utils/constants";

export default function AddElementMenuModal({
  active = false,
  handleModal: handleCloseModal,
  handleReload = () => {},
  dataEdit,
  lastCount = 0,
  isParent = true,
  idParent = "",
  idsSubItems = [],
}) {
  const dispatch = useDispatch();
  const [isFocus, setIsFocus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [nameInput, setNameInput] = useState(dataEdit?.title || "");
  const [enlaceInput, setEnlaceInput] = useState(dataEdit?.url || "");
  const [nameFocused, setNameFocused] = useState(false);
  const [enlaceSelected, setEnlaceSelected] = useState("");
  const [enlaceFocused, setEnlaceFocused] = useState(false);
  const [optionsMenu, setOptionsMenu] = useState(enlaceOptions);

  const inputDebounce = useDebounce(enlaceInput);

  useEffect(() => {
    let query = inputDebounce;

    let apiArticles = ApiServiceComercios.obtenerArticulos({
      query,
      existencia: -1,
    });
    let apiCollection = ApiServiceComercios.getCollections({ query });

    if (query && !enlaceSelected) {
      setIsLoading(true);
      Promise.all([apiArticles, apiCollection]).then((res) => {
        const [{ articulos }, { collections }] = res;

        let list = {
          articulos,
          colecciones: collections,
        };

        // it filter enlaces with items finded
        let newOptions = enlaceOptions.filter((item) => {
          return list[item.value]?.length > 0;
        });

        // formated to show in list
        let formatedOptions = newOptions.map((item) => ({
          ...item,
          label: `${item.label} (${list[item.value].length})`,
        }));

        setOptionsMenu(formatedOptions);
        setIsLoading(false);
      });
    } else {
      setOptionsMenu(enlaceOptions);
    }
  }, [inputDebounce, enlaceSelected]);

  const handleFocus = () => setIsFocus(!isFocus);

  const removeEnlaceSelected = () => {
    setEnlaceSelected("");
  };

  const handleSuccess = (message = "") => {
    setIsSaving(false);
    handleCloseModal();
    handleReload();
    dispatch(
      toggleToast({
        message,
      })
    );
  };

  const updateData = () => {
    let newState = {
      ...dataEdit,
      title: nameInput,
      url: enlaceInput,
    };

    setIsSaving(true);
    ApiServiceComercios.putMenuList(dataEdit?._id, newState).then(
      (_) => handleSuccess("Menú actualizado")
    );
  };

  const addData = () => {
    const data = {
      title: nameInput,
      url: enlaceInput,
      order: lastCount,
      isParent,
    };

    setIsSaving(true);
    ApiServiceComercios.postMenuList(data).then(({ message, menu }) => {
      if (!isParent) {
        let items = idsSubItems.concat(menu._id);
        ApiServiceComercios.putMenuList(idParent, { items }).then((_) => {
          handleSuccess("Menú actualizado");
        });
      } else {
        handleSuccess(message);
      }
    });
  };

  const handleEnlace = (value) => {
    setEnlaceSelected(value);
    setIsFocus(true);

    if (["/", "/contact"].includes(value[0])) {
      removeEnlaceSelected();
      setEnlaceInput(value[0]);
      setIsFocus(false);
    }
  };

  const activator = (
    <TextField
      label="Enlace"
      placeholder="Buscar o pegar un enlace"
      autoComplete="off"
      value={enlaceInput}
      error={enlaceFocused && !enlaceInput && "El enlace es obligatorio"}
      onChange={setEnlaceInput}
      onFocus={() => setIsFocus(true)}
      onClearButtonClick={() => setEnlaceInput("")}
      clearButton
    />
  );

  const closeOptionsMenu = () => {
    setIsFocus(false);
    setEnlaceFocused(true);
  };

  return (
    <Modal
      open={active}
      onClose={handleCloseModal}
      title={`${dataEdit ? "Editar" : "Agregar"} elemento del menú`}
      primaryAction={{
        content: "Guardar",
        disabled: !nameInput || !enlaceInput,
        onAction: dataEdit ? updateData : addData,
        loading: isSaving,
      }}
      secondaryActions={[
        {
          content: "Cancelar",
          onAction: handleCloseModal,
        },
      ]}
    >
      <Modal.Section>
        <Card.Section>
          <FormLayout>
            <TextField
              label="Nombre"
              autoComplete="off"
              placeholder="p. ej. Catálogo"
              value={nameInput}
              onChange={setNameInput}
              error={nameFocused && !nameInput && "El nombre es obligatorio"}
              onBlur={() => setNameFocused(true)}
            />

            <div className="w-full">
              <Popover
                active={isFocus}
                activator={activator}
                autofocusTarget="first-node"
                onClose={closeOptionsMenu}
                fullWidth
              >
                <div>
                  {isLoading ? (
                    <div className="flex justify-center py-5">
                      <Spinner />
                    </div>
                  ) : (
                    <div className={enlaceInput.length < 8 && `h-80`}>
                      <div className={enlaceSelected && "hidden"}>
                        <OptionList
                          selected={[]}
                          options={optionsMenu}
                          onChange={handleEnlace}
                        />
                      </div>
                      <div className={!enlaceSelected && "hidden"}>
                        <EnlaceDetail
                          enlace={enlaceSelected[0]}
                          queryInput={inputDebounce}
                          handleText={setEnlaceInput}
                          goBack={removeEnlaceSelected}
                          closeFocus={handleFocus}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Popover>
            </div>
          </FormLayout>
        </Card.Section>
      </Modal.Section>
    </Modal>
  );
}
