import React from "react";
import {
  Page,
  Layout,
  Icon,
  Card,
  Heading,
  FormLayout,
  TextStyle,
} from "@shopify/polaris";
import { Layer } from "../../components";
import { DomainsMajor, EmailMajor } from "@shopify/polaris-icons";
import "./HelpCenterScreen.css";

export default function HelpCenterScreen({ forbidden }) {
  return (
    <Layer title="Centro de ayuda de b2bGO" forbidden={forbidden} scrollToTop>
      <Page
        title="Centro de ayuda de b2bGO"
        subtitle="Elije la forma en que deseas recibir apoyo."
      >
        <Layout>
          <Layout.Section oneThird>
            <Card
              sectioned
              secondaryFooterActions={[
                {
                  content: "Ir a la guía de uso",
                  url: "https://help.b2bgo.mx/guia-de-uso/inicio",
                },
              ]}
              footerActionAlignment="left"
            >
              <FormLayout>
                <Icon source={DomainsMajor} color="base" />
                <Heading>Guía de uso</Heading>
                <TextStyle>
                  Encuentra la información que necesitas para usar b2bGO.
                </TextStyle>
              </FormLayout>
            </Card>
          </Layout.Section>
          <Layout.Section oneThird>
            <Card
              sectioned
              secondaryFooterActions={[
                {
                  content: "Enviar correo",
                  url: "/admin/centro-de-ayuda/correo-electrónico",
                },
              ]}
              footerActionAlignment="left"
            >
              <FormLayout>
                <Icon source={EmailMajor} color="base" />
                <Heading>Correo electrónico</Heading>
                <TextStyle>
                  Envíanos un correo electrónico explicando tu situación.
                </TextStyle>
              </FormLayout>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </Layer>
  );
}
