import React, { useState, useCallback } from "react";
import {
  Banner,
  Button,
  Caption,
  Card,
  DropZone,
  FormLayout,
  Icon,
  Link,
  List,
  Modal,
  Stack,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";
import { AttachmentMajor } from "@shopify/polaris-icons";
import * as XLSX from "xlsx";
import { normalizeByPosition } from "../../utils/NormalizedById";
import { Table } from "antd";
import CustomPopover from "../CustomPopover";
import ApiServiceComercios from "../../services/ApiServiceComercios";

export default function ImportArticlesModal({
  onClose = () => {},
  open = false,
}) {
  const [file, setFile] = useState(null);
  const [loadings, setLoadings] = useState({
    isDropZone: false,
    isPrevisualizing: false,
    isUploading: false,
  });
  const [currentStep, setCurrentStep] = useState(1);
  const [targetArticles, setTargetArticles] = useState([]);

  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        setLoadings((i) => ({ ...i, isDropZone: true }));
        let reader = new FileReader();
        reader.readAsArrayBuffer(acceptedFiles[0]);
        reader.onloadend = (e) => {
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: "array" });

          workbook.SheetNames.forEach((sheetName) => {
            let xlRowJson = XLSX.utils.sheet_to_json(
              workbook.Sheets[sheetName]
            );

            if (xlRowJson.length > 0) {
              let data = normalizeByPosition(xlRowJson);
              setTargetArticles(data);
            }
            setLoadings((i) => ({ ...i, isDropZone: false }));
          });
          setFile(() => acceptedFiles[0]);
        };
      }
    },
    []
  );

  const previsualizeArticles = () => {
    setLoadings((s) => ({ ...s, isPrevisualizing: true }));
    const skus = targetArticles.map((i) => i.sku);

    ApiServiceComercios.getArticlesValidation({ skus })
      .then(({ articles = [] }) => {
        let skusResponse = articles.map((i) => i.sku.toString());
        setTargetArticles((s) =>
          s
            .filter((i) => skusResponse.includes(i.sku.toString()))
            .map((i) => ({
              ...i,
              id: articles.find((ar) => ar.sku.toString() === i.sku.toString())[
                "id"
              ],
            }))
        );
        setCurrentStep(2);
      })
      .catch((err) => {
        setCurrentStep(4);
      })
      .finally(() => {
        setLoadings((s) => ({ ...s, isPrevisualizing: false }));
      });
  };
  const updateArticles = () => {
    setLoadings((s) => ({ ...s, isUploading: true }));
    ApiServiceComercios.updateArticlesByXlsx({ articles: targetArticles })
      .then(({ message = "" }) => {
        setCurrentStep(3);
      })
      .catch((err) => {
        setCurrentStep(4);
      })
      .finally(() => {
        setLoadings((s) => ({ ...s, isUploading: false }));
      });
  };
  const retryAgain = () => {
    setFile(null);
    setTargetArticles([]);
    setCurrentStep(1);
  };

  const validFileTypes = [
    ".xlsx",
    ".xls",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const fileUpload = !file && (
    <DropZone.FileUpload
      actionTitle="Agrega un archivo .xlsx"
      actionHint="o arrastra y suelta el archivo que deseas subir."
    />
  );

  const uploadedFile = file && (
    <Stack>
      <div className="flex px-10 border-r border-b" style={{ minHeight: 70 }}>
        <Icon source={AttachmentMajor} />
      </div>
      <div>
        {file.name} <Caption>{file.size} bytes</Caption>
      </div>
    </Stack>
  );

  const actionsForSteps = {
    1: {
      content: "Subir y continuar",
      onAction: previsualizeArticles,
      disabled:
        targetArticles.length === 0 ||
        loadings.isDropZone ||
        loadings.isPrevisualizing,
      loading: loadings.isDropZone || loadings.isPrevisualizing,
    },
    2: {
      content: "Actualizar artículos",
      onAction: updateArticles,
      loadings: loadings.isUploading,
      disabled: loadings.isUploading || targetArticles.length === 0,
    },
    3: {
      content: "Cerrar",
      onAction: onClose,
      primary: currentStep,
    },
    4: {
      content: "Intentar nuevamente",
      onAction: retryAgain,
    },
  };

  return (
    <Modal
      title="Actualizar artículos con un archivo XLSX"
      open={open}
      onClose={onClose}
      primaryAction={{ ...actionsForSteps[currentStep] }}
      secondaryActions={
        currentStep !== 3
          ? [
              {
                content: "Cancelar",
                onAction: onClose,
              },
            ]
          : []
      }
    >
      <Card sectioned>
        {currentStep === 1 && (
          <FormLayout>
            <div className="my-4">
              <TextStyle>
                Descarga una{" "}
                <Link
                  url="https://b2bgo.blob.core.windows.net/ecohete-resources/Plantilla artículos.xlsx"
                  external
                >
                  plantilla XLSX de muestra
                </Link>{" "}
                para ver un ejemplo del formato requerido.
              </TextStyle>
            </div>
            <DropZone
              type="file"
              accept={validFileTypes.join(",")}
              allowMultiple={false}
              onDrop={handleDropZone}
              overlay
            >
              {fileUpload}
              {uploadedFile}
            </DropZone>
            <Banner status="warning">
              <TextStyle>
                Actualiza cualquier artículo actual que tenga el mismo SKU. Los
                valores existentes se utilizarán para las columnas que faltan.
              </TextStyle>
            </Banner>
          </FormLayout>
        )}
        {currentStep === 2 && (
          <FormLayout>
            <Banner status="info">
              Estás actualizando artículos a B2BGo. Te mostramos una vista
              previa de tus artículos para tu revisión.
            </Banner>
            <TextContainer>
              Estás importando{" "}
              <TextStyle variation="strong">
                {targetArticles.length} productos
              </TextStyle>{" "}
              y{" "}
              <TextStyle variation="strong">
                {targetArticles.reduce(
                  (prev, current) => prev + current.imagen.length,
                  0
                )}{" "}
                imágenes.
              </TextStyle>{" "}
              Se{" "}
              <TextStyle variation="strong">
                reemplazará los valores de los producto existentes
              </TextStyle>{" "}
              en B2Bgo.{" "}
              <TextStyle variation="strong">
                Esta acción no puede deshacerse.
              </TextStyle>
            </TextContainer>
            <Table
              rowKey="_id"
              className="whitespace-nowrap"
              dataSource={targetArticles}
              scroll={{ scrollToFirstRowOnChange: true, x: "100%" }}
              pagination={false}
              locale={{ emptyText: "No hay artículos" }}
              columns={[
                {
                  title: "SKU",
                  dataIndex: "sku",
                  render: (sku = "") => <TextStyle>{sku}</TextStyle>,
                },
                {
                  title: "Nombre",
                  dataIndex: "nombre",
                  render: (nombre = "") => <TextStyle>{nombre}</TextStyle>,
                },
                {
                  title: "Descripción",
                  dataIndex: "descripcion",
                  render: (descripcion = "") => (
                    <TextStyle>
                      {descripcion.length > 0
                        ? descripcion.substring(0, 30) + "..."
                        : descripcion}
                    </TextStyle>
                  ),
                },
                {
                  title: "Código de barras",
                  dataIndex: "codigo_barra",
                  render: (codigo_barra = "") => (
                    <TextStyle>{codigo_barra}</TextStyle>
                  ),
                },
                {
                  title: "Imágenes",
                  dataIndex: "imagen",
                  render: (imagen = []) => {
                    const images = imagen.map((url) => {
                      return {
                        image: url,
                        helpText: url,
                        value: url,
                      };
                    });
                    const Activator = ({ onClick }) => {
                      return (
                        <div className="no-underline text-gray-600 z-50 div-no-grap">
                          <Button
                            onClick={onClick}
                            disclosure
                            plain
                            textAlign="left"
                            monochrome
                          >
                            {" "}
                            {imagen.length}
                            {`${imagen.length > 1 ? " Imágenes" : " Imagen"}`}
                          </Button>
                        </div>
                      );
                    };
                    return (
                      <div className="w-full h-full cursor-pointer p-6">
                        <CustomPopover items={images} activator={Activator} />
                      </div>
                    );
                  },
                },

                {
                  title: "Peso",
                  dataIndex: "peso",
                  render: (peso = "") => <TextStyle>{peso}</TextStyle>,
                },
                {
                  title: "Mínimo",
                  dataIndex: "minimo",
                  render: (minimo = "") => <TextStyle>{minimo}</TextStyle>,
                },
                {
                  title: "Máximo",
                  dataIndex: "maximo",
                  render: (maximo = "") => <TextStyle>{maximo}</TextStyle>,
                },
                {
                  title: "Factor de venta",
                  dataIndex: "factor",
                  render: (factor = "") => <TextStyle>{factor}</TextStyle>,
                },
                {
                  title: "Marca",
                  dataIndex: "marca",
                  render: (marca = "") => <TextStyle>{marca}</TextStyle>,
                },
                {
                  title: "Etiquetas",
                  dataIndex: "etiquetas",
                  render: (etiquetas = []) => {
                    const tags = etiquetas.map((tag) => {
                      return {
                        content: tag,
                        value: tag,
                      };
                    });
                    const Activator = ({ onClick }) => {
                      return (
                        <div className="no-underline text-gray-600 z-50 div-no-grap">
                          <Button
                            onClick={onClick}
                            disclosure
                            plain
                            textAlign="left"
                            monochrome
                          >
                            {" "}
                            {etiquetas.length}
                            {`${
                              etiquetas.length > 1 ? " Etiquetas" : " Etiqueta"
                            }`}
                          </Button>
                        </div>
                      );
                    };
                    return (
                      <div className="w-full h-full cursor-pointer p-6">
                        <CustomPopover items={tags} activator={Activator} />
                      </div>
                    );
                  },
                },
              ]}
            />
          </FormLayout>
        )}
        {currentStep === 3 && (
          <FormLayout>
            <TextStyle variation="strong">
              Actualmente estamos actualizando artículos a tu tienda.
            </TextStyle>
            <TextStyle>
              Esto podría tomar algo de tiempo en completarse. Si lo deseas,
              puedes cerrar esta ventana y continuar trabajando.
            </TextStyle>
          </FormLayout>
        )}
        {currentStep === 4 && (
          <FormLayout>
            <Banner status="warning">
              Se produjo un error al importar tu archivo XLSX. Corrige el error
              e intenta importar el archivo XLSX nuevamente.
            </Banner>
            <TextStyle variation="strong">
              Estos son algunos posibles errores:
            </TextStyle>
            <List>
              <List.Item>
                El formato del archivo no corresponden a la estructura de
                artículos recomendada. Descarga una{" "}
                <Link
                  url="https://b2bgo.blob.core.windows.net/ecohete-resources/Plantilla artículos.xlsx"
                  external
                >
                  plantilla XLSX de muestra
                </Link>{" "}
                para ver un ejemplo del formato requerido.
              </List.Item>
              <List.Item>
                Existen SKUs de artículos no resgitradas en B2Bgo.
              </List.Item>
              <List.Item>Existen artículos sin campos indicados.</List.Item>
            </List>
          </FormLayout>
        )}
      </Card>
    </Modal>
  );
}
