import { b2bgoApiaxios, b2bgoAuthaxios } from "../utils/Axios";

const ApiServiceReportes = {
  API_ADMIN_REPORTES:
    process.env.NODE_ENV === "development" ? "3005" : "reportes",

  /**
   * Reportes
   */

  async obtenerDashboardComercio(params) {
    const method = "GET";
    const url = "/reportes/comercio-dashboard";
    return this.makeAuthorizedRequest({ method, url, params });
  },

  async obtenerDashboardCliente(params) {
    const method = "GET";
    const url = "/reportes/comercio-dashboard-cliente";
    return this.makeAuthorizedRequest({ method, url, params });
  },
  async contactMerchant(data) {
    const method = "POST";
    const url = "/reportes/contact-merchant";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  makeQueryParams(params) {
    let queries = "";
    for (var key in params) {
      queries += `${key}=${params[key]}&`;
    }
    return queries;
  },

  async makeRequest(requestData = {}) {
    try {
      let res = await b2bgoApiaxios({
        ...requestData,
        api_uri: this.API_ADMIN_REPORTES,
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  async makeAuthorizedRequest(requestData = {}) {
    try {
      let res = await b2bgoAuthaxios({
        ...requestData,
        api_uri: this.API_ADMIN_REPORTES,
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  },
};
export default ApiServiceReportes;
