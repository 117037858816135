import React, { useState } from "react";
import { Button, Layout, Page, Scrollable } from "@shopify/polaris";
import { useDispatch } from "react-redux";
import {
  ChooseSection,
  CustomSidebar,
  RenderSectionsStyled,
  Layer,
} from "../../components";
import {
  createSection,
  createSubSection,
  insertElement,
} from "../../utils/craeteSections";
import { PAGE_SECTIONS_LIST_DEFAULT } from "../../utils/constants";
import { SectionEmptyState } from "../../components/SectionsStyled";
import useGetPageSections from "../../hooks/useGetPageSections";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { toggleToast } from "../../actions/InteractiveActions";
import "../../components/SectionsStyled/SectionsStyled.css";

export default function PageCustomizationScreen({ forbidden }) {
  const dispatch = useDispatch({});
  const [welcome, setWelcome] = useState(true);
  const [currentPage, setCurrentPage] = useState("home-page");
  const actionGroups = [
    {
      content: "Página de inicio",
      accessibilityLabel: "home-page",
      onAction: () => handlePageSelection("home-page"),
    },
    {
      content: "Detalle del producto",
      accessibilityLabel: "products-page",
      onAction: () => handlePageSelection("products-page"),
    },
    {
      content: "Todos los productos",
      accessibilityLabel: "all-products-page",
      onAction: () => handlePageSelection("all-products-page"),
    },
    {
      content: "Colección",
      accessibilityLabel: "collection-page",
      onAction: () => handlePageSelection("collection-page"),
    },
    {
      content: "Lista de colecciones",
      accessibilityLabel: "collections-list-page",
      onAction: () => handlePageSelection("collections-list-page"),
    },
    {
      content: "Página 404",
      accessibilityLabel: "not-found-page",
      onAction: () => handlePageSelection("not-found-page"),
    },
  ];

  const {
    data: sections,
    setData: setSections,
    loading,
  } = useGetPageSections(currentPage, [currentPage]);

  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSubSection, setSelectedSubSection] = useState(null);

  const handlePageSelection = (page) => {
    setCurrentPage(page);
    setSelectedSection(null);
    setSelectedSubSection(null);
  };

  // DONE
  const handleMainChange = (field, value, id) => {
    let selectedSection = null;
    let prevSections = sections.map((mainSection) => ({
      ...mainSection,
      sections: mainSection.sections.map((section) => {
        let newSection = { ...section };
        if (newSection.id === id) {
          newSection[field] = value;
          selectedSection = newSection;
        }
        return newSection;
      }),
    }));

    setSelectedSection(selectedSection);
    setSections(prevSections);
  };

  const onSelectItem = (section, subSection) => {
    setSelectedSection(section);
    setWelcome(false);
    setSelectedSubSection(subSection);
  };

  // DONE
  const handleDeleteSection = (id) => {
    setSections((prevSections) =>
      prevSections.map((mainSection) => ({
        ...mainSection,
        sections: mainSection.sections.filter((section) => section.id !== id),
      }))
    );
    setSelectedSection(null);
    setSelectedSubSection(null);
  };

  // DONE
  const handleDeleteSubSection = (id, idSubItem) => {
    setSections((prevSections) =>
      prevSections.map((mainSection) => ({
        ...mainSection,
        sections: mainSection.sections.map((section) =>
          section.id === id
            ? {
                ...section,
                subNavigationItems: section.subNavigationItems.filter(
                  (subSection) => subSection.id !== idSubItem
                ),
              }
            : section
        ),
      }))
    );
    setSelectedSubSection(null);
  };

  // DONE
  const onAddItem = (type = "") => {
    const newItem = createSection(PAGE_SECTIONS_LIST_DEFAULT, type);
    const bodySections = sections.find(
      (section) => section.type === currentPage
    );

    const addSectionIndex = bodySections.sections.length;
    const newSections = insertElement(
      bodySections.sections,
      addSectionIndex,
      newItem
    );
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.type === currentPage
          ? {
              ...section,
              sections: newSections,
            }
          : section
      )
    );
  };

  // DONE
  const onAddSubItem = (id = "") => {
    let last = 0;
    let section = sections.find((section) =>
      section.sections.some((section) => section.id === id)
    );
    section = section.sections.find((section) => section.id === id);

    const { type, subNavigationItems } = section;
    if (subNavigationItems.length > 0) {
      const lastItem = subNavigationItems[subNavigationItems.length - 1];
      last = Number(lastItem.label.replace(/\D/g, ""));
    }

    const subSection = createSubSection(type, last);
    setSections((prevSections) =>
      prevSections.map((mainSection) => ({
        ...mainSection,
        sections: mainSection.sections.map((section) =>
          section.id === id
            ? {
                ...section,
                subNavigationItems: [...section.subNavigationItems, subSection],
              }
            : section
        ),
      }))
    );

    setSelectedSection((prevState) =>
      prevState
        ? {
            ...prevState,
            subNavigationItems: prevState.subNavigationItems.concat(subSection),
          }
        : undefined
    );
  };

  const handleSave = async () => {
    const promises = sections.map((i) =>
      ApiServiceComercios.updatePageSectionById(i._id, { sections: i.sections })
    );
    try {
      await Promise.all(promises);
      dispatch(toggleToast({ message: "Datos guardados correctamente" }));
    } catch (error) {}
  };

  const heading =
    sections?.find((section) => section.type === "heading")?.sections || [];
  const body =
    sections?.find((section) => section.type === currentPage)?.sections || [];
  const footer =
    sections?.find((section) => section.type === "footer")?.sections || [];

  return (
    <Layer title="Personaliza tus páginas" forbidden={forbidden}>
      <div
        className="customization-page"
        style={{ height: "calc(100vh - 56px)" }}
      >
        <Page
          fullWidth
          title="Personaliza tus páginas"
          breadcrumbs={[
            {
              content: "Personalizar",
              url: "/admin/tienda_online/personalizar",
            },
          ]}
          actionGroups={[
            {
              title: actionGroups.find(
                (action) => action.accessibilityLabel === currentPage
              )["content"],
              actions: actionGroups,
            },
          ]}
          primaryAction={
            <Button primary onClick={handleSave}>
              Guardar
            </Button>
          }
        >
          <Layout>
            <Layout.Section oneThird>
              <CustomSidebar
                loading={loading}
                title={
                  actionGroups.find(
                    (action) => action.accessibilityLabel === currentPage
                  )["content"]
                }
                heading={heading}
                body={body}
                footer={footer}
                onClick={onSelectItem}
                selectedSection={selectedSection}
                selectedSubSection={selectedSubSection}
                handleMainChange={handleMainChange}
                onAddItem={onAddItem}
                onSort={setSections}
                onAddSubItem={onAddSubItem}
                showAddSection={
                  ![
                    "products-page",
                    "collection-page",
                    "collections-list-page",
                    "all-products-page",
                    "not-found-page",
                  ].includes(currentPage)
                }
                currentPage={currentPage}
              />
            </Layout.Section>
            <Layout.Section>
              <Scrollable vertical>
                {welcome ? (
                  <SectionEmptyState />
                ) : (
                  <RenderSectionsStyled
                    heading={heading}
                    body={body}
                    footer={footer}
                    mode="edit"
                  />
                )}
              </Scrollable>
            </Layout.Section>
            {selectedSection && (
              <Layout.Section oneThird>
                <ChooseSection
                  selectedSection={selectedSection}
                  selectedSubSection={selectedSubSection}
                  handleMainChange={handleMainChange}
                  handleDeleteSection={handleDeleteSection}
                  handleDeleteSubSection={handleDeleteSubSection}
                  currentPage={currentPage}
                />
              </Layout.Section>
            )}
          </Layout>
        </Page>
      </div>
    </Layer>
  );
}
