import {
  ADD_AUTH_DATA,
  CLEAR_AUTH,
  AUTH_DATA_BEGIN,
  ADD_TOKEN,
  LOGIN_COMERCIO,
  SET_TIMEZONE,
  ADD_NEW_TOKENS,
} from "../actions/AuthActions";

const initialState = {
  loggedIn: false,
  isLoading: false,
  userData: {},
  access_token: "",
  grant_type: "",
  token_type: "",
  comercios: "",
  url_imagen: "",
  nombre_comercio: "",
  zonaHoraria: "America/Mexico_City",
  chat: {},
  comercioData: {
    direccion: "",
    ciudad: "",
    estado: "",
    pais: "",
    codigo_postal: "",
    telefono: "",
    email: "",
    rfc: "",
    colorSchema: {},
  },
  refreshToken: "",
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_DATA_BEGIN:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_AUTH_DATA:
      return {
        ...state,
        isLoading: false,
        loggedIn: true,
        grant_type: action.payload.data.grant_type,
        access_token: action.payload.data.token,
        userData: action.payload.data.usuario,
        comercios:
          action.payload.data.grant_type === "ADMIN"
            ? action.payload.data.comercios
            : null,
        url_imagen: action.payload.data.url_imagen,
        nombre_comercio: action.payload.data.nombre_comercio,
        zonaHoraria: action.payload.data.zonaHoraria,
        chat: action.payload.data.chat,
        comercioData: {
          ...state.comercioData,
          direccion: action.payload.data.comercioData?.direccion || "",
          ciudad: action.payload.data.comercioData?.ciudad || "",
          estado: action.payload.data.comercioData?.estado || "",
          pais: action.payload.data.comercioData?.pais || "",
          codigo_postal: action.payload.data.comercioData?.codigo_postal || "",
          telefono: action.payload.data.comercioData?.telefono || "",
          email: action.payload.data.comercioData?.email || "",
          rfc: action.payload.data.comercioData?.rfc || "",
          colorSchema: action.payload.data.comercioData?.colorSchema || {},
        },
        refreshToken: action.payload.data.refreshToken,
      };
    case ADD_TOKEN: {
      return {
        ...state,
        access_token: action.payload.data,
      };
    }
    case ADD_NEW_TOKENS: {
      return {
        ...state,
        access_token: action.payload.data.token,
        refreshToken: action.payload.data.refreshToken,
      };
    }
    case LOGIN_COMERCIO: {
      return {
        ...state,
        url_imagen: action.payload.data.url_imagen,
        nombre_comercio: action.payload.data.nombre_comercio,
        grant_type: action.payload.data.grant_type,
        access_token: action.payload.data.token,
        colorSchema: action.payload.data.colorSchema,
      };
    }
    case SET_TIMEZONE: {
      return {
        ...state,
        zonaHoraria: action.payload.timezone,
      };
    }

    case CLEAR_AUTH:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
