import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {};

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: [
    "Auth",
    "Pedidos",
    "Articulos",
    "Clientes",
    "Cotizaciones",
    "ShoppingCart",
  ],
  // blackList: ['Auth']
};

const persisterReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunk];
const composeEnhancers =
  process.env.NODE_ENV === "production" ? compose : compose;
// const composeEnhancers = process.env.NODE_ENV === 'production' ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

const store = createStore(
  persisterReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
export const persistor = persistStore(store);
