import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { isAuthenticated } from "../reducers";
import ability from "../auth/ability";
import PermissionsContainer from "../containers/PermisosContainer";

export default function PrivateRoute({
  component: Component,
  can = "",
  access = "",
  ...rest
}) {
  const isLoggedIn = useSelector(isAuthenticated);

  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <Route
      {...rest}
      component={(props) => (
        <PermissionsContainer>
          {(permissions) => (
            <Component
              {...props}
              forbidden={ability(permissions).cannot(access, can)}
            />
          )}
        </PermissionsContainer>
      )}
    />
  );
}
