import { useEffect, useMemo, useState } from "react";
import { Card, Modal, Spinner } from "@shopify/polaris";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CustomForm from "./CustomForm";
import StripeCheckoutContainer from "./StripeCheckoutContainer";
import SuccessPayment from "./SuccessPayment";
import ApiServiceComercios from "../../../services/ApiServiceComercios";

export default function StripePaymentModal({
  activeModal,
  onClose,
  amount,
  origin,
  currency = "MXN",
  billId = [],
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [secretKey, setClientSecret] = useState("");
  const [stripePromise, setStripePromise] = useState(null);
  const [processingPayment, setIsProcessing] = useState(false);
  const [hasSuccessPayment, setHasSuccessPayment] = useState(false);

  const options = useMemo(() => {
    return {
      clientSecret: secretKey,
    };
  }, [secretKey]);

  useEffect(() => {
    setIsLoading(true);
    ApiServiceComercios.getPublicKey().then(({ publicKey }) => {
      const stripe = loadStripe(publicKey);
      setStripePromise(stripe);
    });
  }, [setStripePromise, setIsLoading]);

  useEffect(() => {
    const createPaymentIntent = () => {
      const data = {
        amount: Math.abs(amount),
        currency,
      };
      ApiServiceComercios.setPaymentAttempt(data)
        .then(({ clientSecret }) => {
          setClientSecret(clientSecret);
        })
        .finally(() => setIsLoading(false));
    };
    createPaymentIntent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      title={
        hasSuccessPayment
          ? "Pago exitoso"
          : "Introducir tarjeta de crédito o débito"
      }
      open={activeModal}
      onClose={processingPayment ? () => {} : onClose}
    >
      <Modal.Section>
        {isLoading ? (
          <Card>
            <div className="p-16 flex justify-center items-center">
              <Spinner size="large" />
            </div>
          </Card>
        ) : (
          <div className="p-8">
            {stripePromise && secretKey ? (
              <Elements stripe={stripePromise} options={options}>
                <StripeCheckoutContainer
                  setIsProcessing={setIsProcessing}
                  setHasSuccessPayment={setHasSuccessPayment}
                  origin={origin}
                  billId={billId}
                >
                  {({
                    handleSubmit,
                    hasSuccessResponse,
                    message,
                    confirmPaymentData,
                  }) => {
                    return (
                      <>
                        {hasSuccessResponse ? (
                          <SuccessPayment
                            totalAmountToPay={amount}
                            confirmPaymentData={confirmPaymentData}
                            onCloseModal={onClose}
                          />
                        ) : (
                          <CustomForm
                            message={message}
                            processingPayment={processingPayment}
                            handleSubmit={handleSubmit}
                            totalAmountToPay={amount}
                            onCancel={onClose}
                          />
                        )}
                      </>
                    );
                  }}
                </StripeCheckoutContainer>
              </Elements>
            ) : null}
          </div>
        )}
      </Modal.Section>
    </Modal>
  );
}
