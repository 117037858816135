import React, { useState, useEffect } from "react";
import {
  DisplayText,
  TextStyle,
  Form,
  Button,
  Spinner,
} from "@shopify/polaris";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import jwt from "jsonwebtoken";
import "./RecoveryPasswordScreenStyles.css";
import { resetPassword } from "../../actions/AuthActions";
import { PasswordField } from "../../components";
import ApiServiceComercios from "../../services/ApiServiceComercios";

export default function RecoveryPassword(props) {
  const router = useHistory();
  const dispatch = useDispatch();
  const [password, setPassword] = useState({
    text: "",
    passwordError: null,
    password: "",
  });
  const [isValid, setIsValid] = useState(null);
  const [recovery, setRecovery] = useState({
    isRecovered: false,
    isRecovering: false,
  });
  const [tokenData, setTokenData] = useState(null);
  const [idComercio, setIdComercio] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [isValidating, setIsValidating] = useState(true);

  useEffect(() => {
    const token = new URLSearchParams(props.location.search).get("token");
    setTokenData(token);
    jwt.verify(
      token,
      process.env.REACT_APP_SECRET_SEED_RECOVERY,
      (error, decoded) => {
        if (error) {
          setIsValidating(false);
          return setIsValid(false);
        }
        const { id_comercio = null } = decoded;
        setIdComercio(id_comercio);
        setIsValid(true);
        setIsValidating(false);
        return;
      }
    );
    return () => null;
  }, [props]);

  function handleRecovery() {
    setRecovery((state) => ({ ...state, isRecovering: true }));
    dispatch(
      resetPassword({
        token: tokenData,
        password: password.password,
        idComercio,
      })
    )
      .then((res) => {
        if (!res.ok) {
          setPassword((state) => ({ ...state, passwordError: res.message }));
        }
        if (res.ok) {
          setRecovery((state) => ({ ...state, isRecovered: true }));
        }
        setRecovery((state) => ({ ...state, isRecovering: false }));
      })
      .catch((error) => {
        setRecovery((state) => ({ ...state, isRecovering: false }));
      });
  }

  function login() {
    router.replace("/login");
  }

  useEffect(() => {
    document.title = "B2BGO - Recuperar contraseña";
    ApiServiceComercios.getImageData().then(({ storeDB }) => {
      setImageData(storeDB?.imagen?.url);
    });
    return () => null;
  }, []);

  if (isValidating) {
    return (
      <div className="w-full bg-white h-screen ">
        <div className="flex justify-center items-center h-full">
          <Spinner size="large" />
        </div>
      </div>
    );
  }

  return (
    <div id="bg-gradient-animation" className="w-full h-screen pt-48">
      <div className="login-card w-full sm:h-auto">
        <img
          src={imageData ? imageData : "b2bgologo.png"}
          className="mb-10 img-login"
          alt="b2bgo-logo"
        />
        {!isValid && isValid !== null ? (
          <div className="mb-1">
            <DisplayText element="h2" size="large">
              {" "}
              Lo sentimos, esta petición ya no es válida
            </DisplayText>
            <div className="mt-8">
              <Button primary onClick={login}>
                Ir a login
              </Button>
            </div>
          </div>
        ) : (
          <div>
            {!recovery.isRecovered ? (
              <div>
                <div className="mb-1">
                  <DisplayText element="h2" size="large">
                    Olvidaste tu contraseña
                  </DisplayText>
                </div>

                <div>
                  <div className="mb-8">
                    <TextStyle element="p" variation="subdued">
                      Ingresa una nueva contraseña para tu cuenta
                    </TextStyle>
                  </div>
                  <Form onSubmit={handleRecovery}>
                    <div className="my-8 form-width">
                      <PasswordField
                        value={password.password}
                        onChange={(password) =>
                          setPassword((state) => ({ ...state, password }))
                        }
                      />
                    </div>
                    <div className="flex flex-row items-center">
                      <Button
                        submit
                        primary
                        disabled={!password.password}
                        loading={recovery.isRecovering}
                      >
                        Guardar
                      </Button>
                      <div className="mx-8">
                        <Button primary onClick={login} plain>
                          Ir a login
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            ) : (
              <div>
                <div className="mb-8">
                  <TextStyle element="p" variation="subdued">
                    Tu contraseña ha sido restablecida
                  </TextStyle>
                </div>
                <Button primary onClick={login}>
                  Ir a login
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
