import {
  Card,
  EmptyState,
  Layout,
  ResourceItem,
  ResourceList,
  TextStyle,
} from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleToast } from "../../actions/InteractiveActions";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import AddManualPaymentModal from "../AddManualPaymentModal";

export default function ManualPaymentsCard() {
  const dispatch = useDispatch();
  const [paymentsData, setPaymentsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [id, setId] = useState(null);

  const toggleModal = useCallback(() => setIsActive(!isActive), [isActive]);

  const getPayments = async () => {
    setLoading(true);
    await ApiServiceComercios.getManualPayments()
      .then(({ manualPayments = [] }) => {
        setPaymentsData(manualPayments);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getPayments();
  }, []);

  const openModal = (id = "", edit = false) => {
    if (edit) {
      setId(id);
    } else {
      setId(null);
    }
    toggleModal();
  };

  const emptyStateMarkup = (
    <EmptyState
      heading="No hay pagos manuales registrados"
      action={{
        content: "Agregar pagos manuales",
        onAction: openModal,
      }}
    >
      <p>Configura tus propios métodos de pago manuales.</p>
    </EmptyState>
  );

  function onAdd(paymentsData, id) {
    if (!id) {
      createPayment(paymentsData);
    } else {
      updatePayment(paymentsData, id);
    }
  }

  const createPayment = async (data) => {
    setIsCreating(true);
    ApiServiceComercios.createManualPayment({
      name: data.name,
      instructions: data.instructions,
    })
      .then(({ ok, message }) => {
        if (ok) {
          dispatch(toggleToast({ message }));
          toggleModal();
          getPayments();
          setIsCreating(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          const { response } = error;
          const { data } = response;
          if (data) {
            dispatch(toggleToast(data.error));
          }
        }
        setIsCreating(false);
      });
  };

  const deleteManualPayment = async (id) => {
    setIsDeleting(true);

    ApiServiceComercios.deleteManualPayment(id).then((res) => {
      if (res.ok) {
        dispatch(toggleToast({ message: res.message }));
        toggleModal();
        getPayments();
        setIsDeleting(false);
      }
    });
  };

  const updatePayment = async (data, id) => {
    setIsCreating(true);
    ApiServiceComercios.updateManualPayment(id, {
      name: data.name,
      instructions: data.instructions,
    })
      .then(({ ok, message }) => {
        if (ok) {
          dispatch(toggleToast({ message }));
          toggleModal();
          getPayments();
          setIsCreating(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          const { response } = error;
          const { data } = response;
          if (data) {
            dispatch(toggleToast(data.error));
          }
        }
        setIsCreating(false);
      });
  };

  return (
    <>
      {isActive && (
        <AddManualPaymentModal
          isActive={isActive}
          onClose={toggleModal}
          loading={isCreating}
          isDeleting={isDeleting}
          onDelete={deleteManualPayment}
          onAdd={onAdd}
          id={id}
        />
      )}
      <Layout.AnnotatedSection
        title="Métodos de pago manual"
        description="Los pagos realizados fuera de la tienda en línea. Los pedidos pagados manualmente deben aprobarse antes de su preparación."
      >
        <Card
          sectioned
          title="Pagos manuales"
          actions={[
            {
              content: "Agregar",
              onAction: openModal,
            },
          ]}
        >
          <ResourceList
            loading={loading}
            emptyState={emptyStateMarkup}
            totalItemsCount={paymentsData.length}
            items={paymentsData}
            resourceName={{
              singular: "metodo de pago",
              plural: "metodos de pago",
            }}
            renderItem={(item) => {
              return (
                <ResourceItem
                  id={item._id}
                  onClick={() => openModal(item._id, true)}
                >
                  <div>
                    <TextStyle>{item.name}</TextStyle>
                  </div>
                </ResourceItem>
              );
            }}
          />
        </Card>
      </Layout.AnnotatedSection>
    </>
  );
}
