import {
  ADD_HISTORIAL,
  HISTORIAL_BEGIN,
  HISTORIAL_FAILED,
  CLEAR_HISTORIAL,
  RESET_PAGE,
  CHANGE_PAGE,
} from "../actions/SincronizacionActions";

const initialState = {
  byId: {},
  allIds: [],
  allIdsDB: [],
  isLoading: false,
  currentPage: 0,
  sizePerPage: 20,
  total: 0,
  successful: {},
  failed: {},
  lastSync: {},
};

export default function HistorialReducer(state = initialState, action) {
  switch (action.type) {
    case HISTORIAL_BEGIN: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ADD_HISTORIAL: {
      let allIds = [
        ...new Set(state.allIds.concat(action.payload.data.allIds)),
      ];
      let byId = {
        ...state.byId,
        ...action.payload.data.byId,
      };
      return {
        ...state,
        allIds,
        byId,
        total: action.payload.data.total,
        successful: action.payload.data.successful,
        failed: action.payload.data.failed,
        lastSync: action.payload.data.lastSync,
        isLoading: false,
      };
    }
    case CLEAR_HISTORIAL: {
      return {
        ...state,
        byId: {},
        allIds: [],
        allIdsDB: [],
        isLoading: false,
      };
    }
    case HISTORIAL_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case CHANGE_PAGE: {
      const currentPage = action.payload.data.currentPage;
      return {
        ...state,
        currentPage,
      };
    }
    case RESET_PAGE: {
      return {
        ...state,
        currentPage: 0,
        total: 0,
      };
    }
    default: {
      return state;
    }
  }
}

export const isLoadingHistorial = (state) => {
  return state.isLoading;
};
