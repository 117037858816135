import {
  Button,
  Heading,
  Icon,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";
import React from "react";
import { CancelSmallMinor } from "@shopify/polaris-icons";

import ALERT from "../../assets/images/alert.svg";
import ANNOUN from "../../assets/images/announcement.svg";
import ICON from "../../assets/images/Icon.svg";
import WARN from "../../assets/images/warn.svg";
import DEFAULT from "../../assets/images/default.svg";
const NotifiactionItem = ({
  title = "Nueva Funcionalidad",
  linkText = "Leer más",
  description = "Ahora puedes seleccionar una lista de productos para tus clientes.",
  link,
  type,
  id,
  handleDelete,
}) => {
  const ICON_TYPES = {
    information: ICON,
    announcement: ANNOUN,
    attention: WARN,
    alert: ALERT,
    default: DEFAULT,
  };

  return (
    <div
      key={id}
      className={`container-notif 	${
        type === "alert" && "bg-red-50 border-red-b"
      }`}
    >
      <div className="flex flex-row items-center">
        <div className="flex flex-row width-90">
          <img alt="logo" className="notif-icon" src={ICON_TYPES[type]} />
          <div className="flex pl-4	">
            <Heading variation="subdued">{title}</Heading>
          </div>
        </div>
        {type !== "default" ? (
          <div className="cursor-pointer" onClick={() => handleDelete(id)}>
            <Icon source={CancelSmallMinor} color="SkyDark" backdrop />
          </div>
        ) : null}
      </div>
      <div className="py-4">
        <TextContainer spacing="tight">
          <TextStyle variation="subdued">
            {description} <br />
            {link.length ? (
              <Button
                plain
                external
                onClick={() => window.open(link, "_blank")}
              >
                {linkText}
              </Button>
            ) : null}
          </TextStyle>
        </TextContainer>
      </div>
    </div>
  );
};
export { NotifiactionItem };
