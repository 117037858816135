import {
  Autocomplete,
  Button,
  Card,
  ContextualSaveBar,
  DropZone,
  EmptyState,
  Heading,
  Icon,
  Layout,
  Link,
  Page,
  Select,
  SkeletonBodyText,
  Spinner,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { clearArticulos } from "../../actions/ArticulosActions";
import { toggleToast } from "../../actions/InteractiveActions";
import {
  ArticuloItem,
  BuscarProductoModal,
  ComercioDetalleSkeleton,
  CreateCollectionAutocomplete,
  CustomBadge,
  CustomerStatusBanner,
  DatePicker,
  HelpFooter,
  Layer,
  TagsAutocomplete,
  Timeline,
  ZoomImage,
  PreventTransitionPrompt,
} from "../../components";
import { VendorAutocomplete } from "../../components/common/Index";
import DropzoneFiles from "../../components/DropzoneFiles";
import useGetCurrencies from "../../hooks/useGetCurrencies";
import useGetOrigins from "../../hooks/useGetOrigins";
import useGetPhases from "../../hooks/useGetPhases";
import { getPaginationOpportunities, getUserInfo } from "../../reducers";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import ApiServiceConfig from "../../services/ApiServiceConfig";
import useDebounce from "../../services/useDebounce";
import {
  PERCENTAGES,
  validFileTypes,
  validImageTypes,
} from "../../utils/constants";
import { getDiscounts } from "../../utils/orderValidations";
import { extractInitials } from "../../utils/stringModifiers";

export default function OportunidadDetalleScreen({ forbidden }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const router = useHistory();
  const queryClientRef = useRef(null);
  const selectArticulosRef = useRef(null);
  const handleModalChangeRef = useRef(null);

  const usuario = useSelector(getUserInfo);
  const [oportunidad, setOportunidad] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalActive, setIsModalActive] = useState(false);
  const [inputValueClient, setInputValueClient] = useState("");
  const [clientesOptions, setClientesOptions] = useState([]);
  const [isLoadingClientes, setIsLoadingClientes] = useState(false);
  const pageState = useSelector((state) => state.Oportunidades.allIdsDB);
  const pagination = useSelector(getPaginationOpportunities);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showHistorial, setShowHistorial] = useState(true);
  const [fieldErrors, setFieldErrors] = useState({
    createOrder: "",
  });

  const [blobFile, setBlobFile] = useState([]);
  const [isAddingFiles, setIsAddingFiles] = useState(false);
  const [clienteDB, setCliente] = useState(null);

  const [selectedArticulos, setSelectedArticulos] = useState([]);
  const [articleCount, setArticleCount] = useState([]);
  const [quantityError, setQuantityError] = useState([]);
  const [initialSearch, setInitialSearch] = useState(true);
  const [totales, setTotales] = useState([]);
  const [porPromosGen, setPorPromosGen] = useState(null);
  const [porVolumen, setPorVolumen] = useState(null);
  const [porMaximo, setPorMaximo] = useState(null);

  const [inputArticleValue, setInputArticleValue] = useState("");
  const [tagsSelected, setTags] = useState([]);
  const [clientSelectedOptions, setClientSelectedOptions] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [importe, setImporte] = useState("0");
  const [selectedDates, setSelectedDates] = useState(new Date());
  const [openFileDialog, setOpenFileDialog] = useState(false);
  const [description, setDescription] = useState("");
  const [productDiscounts, setProductDiscounts] = useState([]);
  const [priceToUseId, setPriceToUseId] = useState(null);

  const { phases, isLoadingPhases, createPhase } = useGetPhases();
  const { origins, isLoadingOrigins, createOrigin } = useGetOrigins();
  const { currencies } = useGetCurrencies();
  const [typeSelected, setTypeSelected] = useState("");
  const [phaseSelected, setPhaseSelected] = useState("");
  const [originSelected, setOriginSelected] = useState("");
  const [vendorInputValue, setVendorInputValue] = useState("");
  const [probabilitySelected, setProbabilitySelected] = useState("0");
  const handleImporte = useCallback((value) => {
    setImporte(value);
    setIsDirty(true);
  }, []);
  const handleTypeChange = useCallback((value) => {
    setTypeSelected(value);
    setIsDirty(true);
  }, []);
  const handlePhaseChange = useCallback((value) => {
    setPhaseSelected(value);
    setIsDirty(true);
  }, []);
  const handleOriginChange = useCallback((value) => {
    setOriginSelected(value);
    setIsDirty(true);
  }, []);
  const handleProbabilityChange = useCallback((value) => {
    setProbabilitySelected(value);
    setIsDirty(true);
  }, []);

  const toggleOpenFileDialog = useCallback(() => {
    setOpenFileDialog((openFileDialog) => !openFileDialog);
    setIsDirty(true);
  }, []);

  const handleDescriptionChange = useCallback((newValue) => {
    setDescription(newValue);
    setIsDirty(true);
  }, []);

  const updateArticleText = useCallback((value) => {
    setInputArticleValue(value);
    setIsDirty(true);
  }, []);

  const debouncedSearchTerm = useDebounce(inputValueClient, 900);
  const debouncedSearchTerm2 = useDebounce(inputArticleValue, 900);

  useEffect(() => {
    queryClientRef.current = inputValueClient;
    selectArticulosRef.current = selectArticulos;
    handleModalChangeRef.current = handleModalChange;
  });

  const willReturnInitialData = useCallback((totalArt, articuloId) => {
    if (totalArt && totalArt > 0) {
      setTotales((prevState) =>
        prevState.find((i) => i.articuloId === articuloId)
          ? prevState.map((i) =>
              i.articuloId === articuloId ? { ...i, total: totalArt } : i
            )
          : [...prevState, { total: totalArt, articuloId }]
      );
    }
  }, []);

  useEffect(() => {
    setInitialSearch(false);
    ApiServiceComercios.opportunityDetail(id)
      .then(({ opportunity }) => {
        setOportunidad({
          ...opportunity,
          articles: opportunity.articles,
        });
        setTags(opportunity.tags || []);

        setImporte(opportunity.amount.toString());
        setTypeSelected(opportunity.type);
        setPhaseSelected([opportunity.phase._id]);
        setOriginSelected([opportunity?.origin._id]);
        setSelectedDates(new Date(opportunity?.closingDate));
        setProbabilitySelected(`${opportunity?.probability}`);
        setDescription(opportunity?.description);
        setVendorInputValue(
          opportunity?.vendor ? opportunity?.vendor._id : null
        );
        setBlobFile(opportunity?.files || []);
        if (opportunity?.clientId) {
          updateSelection([opportunity?.clientId?._id], false);
        }
        selectArticulosRef.current(
          opportunity.articles.map((i) => ({
            ...i.articulo,
            id: i.articulo._id,
            cantidad: i.cantidad.toString(),
            notas: i.notas || "",
          })),
          false
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  useEffect(() => {
    handleModalChangeRef.current();
  }, [debouncedSearchTerm2]);

  // Search clients when query value
  useEffect(() => {
    function onQueryEndChange() {
      setIsLoadingClientes(true);
      ApiServiceComercios.obtenerClientesComercio({
        limit: 20,
        skip: 0,
        sort: "nombre",
        query: queryClientRef.current,
        orden: 1,
        estatus: JSON.stringify(["Activo"]),
      })
        .then(({ clientes }) => {
          const obj = clientes.map((i) => ({
            label: (
              <div className="flex flex-col">
                <TextStyle>{i.nombre}</TextStyle>
                <TextStyle variation="subdued">{i.email}</TextStyle>
              </div>
            ),
            value: i._id,
          }));
          setClientesOptions(obj);
        })
        .finally(() => setIsLoadingClientes(false));
    }

    onQueryEndChange();
  }, [debouncedSearchTerm]);

  const actionGroups = {
    title: "Más acciones",
    accessibilityLabel: "Action group label",
    actions: [
      {
        content: "Marcar como ganada",
        accessibilityLabel: "ganada",
        onAction: () =>
          handlePhaseChange([
            phases.find((phase) => phase.label === "Ganada").value,
          ]),
        disabled: isUpdating,
      },
      {
        content: "Marcar como perdida",
        accessibilityLabel: "perdida",
        onAction: () =>
          handlePhaseChange([
            phases.find((phase) => phase.label === "Perdida").value,
          ]),
        disabled: isUpdating,
      },
      {
        content: "Crear pedido",
        accessibilityLabel: "pedido",
        onAction: () => createDocumentFromOpportunity("/admin/pedidos/agregar"),
        disabled:
          _.isEmpty(oportunidad?.clientId) ||
          _.isEmpty(clienteDB) ||
          _.isEmpty(selectedArticulos),
      },
      {
        content: "Crear cotización",
        accessibilityLabel: "cotizacion",
        onAction: () =>
          createDocumentFromOpportunity("/admin/cotizaciones/agregar"),
        disabled:
          _.isEmpty(oportunidad?.clientId) ||
          _.isEmpty(clienteDB) ||
          _.isEmpty(selectedArticulos),
      },
    ],
  };

  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) => {
      const formData = new FormData();

      if (acceptedFiles.length > 0) {
        setIsDirty(true);
        setIsAddingFiles(true);
        acceptedFiles.forEach((file) => {
          formData.append("files", file);
        });

        ApiServiceConfig.uploadFile(formData)
          .then(({ files = [] }) => {
            setBlobFile((state) => [...state, ...files]);
          })
          .finally(() => setIsAddingFiles(false));
      }
    },
    []
  );

  const handleDiscard = () => {
    router.push(`/admin/oportunidades/${id}`);
  };

  function handleModalChange() {
    if (!initialSearch) {
      setIsModalActive(!isModalActive);
    }
  }

  function selectArticulos(items, block = true) {
    block && setIsDirty(true);
    let merged = _.unionBy(selectedArticulos, items, "_id");
    merged = merged.filter((i) => i.precios.length > 0);
    items = items.filter((i) => i.precios.length > 0);
    dispatch(clearArticulos());
    setSelectedArticulos(merged);
    setArticleCount((state) => [
      ...new Set(
        state.concat(
          items.map((item) => ({
            id: item._id,
            precio: item.precios[0]?.precio || 0,
            cantidad: item.cantidad ? item.cantidad : "1",
            total:
              (item.cantidad ? item.cantidad : 1) *
              (item.precios[0]?.precio || 1),
          }))
        )
      ),
    ]);
  }

  function onRemoveArticulo(id) {
    let articulosIds = articleCount.filter((item) => item.id !== id);
    let articulos = selectedArticulos.filter((item) => item._id !== id);
    setSelectedArticulos(articulos);
    setArticleCount(articulosIds);
    setTotales(totales.filter((i) => i.articuloId !== id));
    setIsDirty(true);
  }

  function handleUpdateOpportunity() {
    const articles = selectedArticulos.map((ar) => {
      return {
        article: ar.hasOwnProperty("articulo") ? ar.articulo._id : ar._id,
        notas: ar.notas || "",
        quantity: Number(
          articleCount.find((art) => art.id === ar._id).cantidad
        ),
      };
    });

    const data = {
      articles,
      clientId: oportunidad?.clientId
        ? oportunidad?.clientId._id
        : clienteDB
        ? clienteDB?._id
        : "",
      vendorId: vendorInputValue,
      amount: Number(importe),
      closingDate: moment(selectedDates).format(),
      type: typeSelected,
      phase: phaseSelected[0],
      origin: originSelected[0],
      probability: Number(probabilitySelected),
      description: description,
      files: blobFile,
      tags: tagsSelected,
    };

    setIsUpdating(true);
    ApiServiceComercios.actualizarOpportunity(data, id)
      .then(({ message }) => {
        dispatch(toggleToast({ message }));
      })
      .finally(() => {
        setIsUpdating(false);
        setIsDirty(false);
      });
  }

  function setPhase(envio) {
    switch (envio) {
      case "Ganado":
        return "complete";
      case "Perdido":
        return "incomplete";
      case "Pendiente":
        return "partiallyComplete";
      default:
        return null;
    }
  }

  function selectTags(values) {
    setIsDirty(true);
    setTags(values);
  }

  function addNoteToArticle(note, id) {
    const selectedArt = [...selectedArticulos];
    selectedArt.forEach((obj) => {
      if (obj.id === id) {
        obj["notas"] = note;
      }
    });
    setIsDirty(true);
    setSelectedArticulos(selectedArticulos);
  }

  function duplicarOportunidad() {
    const state = {
      articles: selectedArticulos,
      clientId: oportunidad?.clientId._id,
      amount: importe,
      closingDate: selectedDates,
      typeSelected,
      phaseSelected,
      originSelected,
      probability: probabilitySelected,
      description,
      files: blobFile,
      tags: tagsSelected,
      vendor: vendorInputValue,
    };
    router.push("/admin/oportunidades/agregar", state);
  }

  function previousPage() {
    const prevId = pageState[pageState.indexOf(id) - 1];
    router.push(`/admin/oportunidades/${prevId}`);
  }

  function nextPage() {
    const nextId = pageState[pageState.indexOf(id) + 1];
    router.push(`/admin/oportunidades/${nextId}`);
  }

  /*
   * Crear documento desde oportunidad
   **/
  function createDocumentFromOpportunity(redirect_url) {
    setIsUpdating(true);
    setFieldErrors({
      createOrder: "",
    });
    const data = {
      articlesWithClave: selectedArticulos
        .map((i) => ({
          cantidad: articleCount.find((ar) => ar.id === i._id)?.cantidad || 1,
          clave: String(i.clave).replace(new RegExp('"', "ig"), ""),
        }))
        .filter((i) => i.clave && i.clave !== "undefined"),
      clave: oportunidad?.clientId?.clave
        ? oportunidad?.clientId?.clave
        : clienteDB?.clave,
    };
    ApiServiceComercios.getArticlesForCSV(data)
      .then(({ articles = [] }) => {
        articles = articles.filter(({ isParent }) => !isParent);
        if (articles.length > 0) {
          router.push(redirect_url, {
            articulos: articles.map((i) => ({
              ...i,
              notas:
                selectedArticulos.find((j) => j._id === i._id)?.notas || "",
            })),
            vendorId: vendorInputValue,
            clienteId: oportunidad?.clientId?._id
              ? oportunidad.clientId._id
              : clienteDB._id,
            almacenId: oportunidad?.clientId?.almacen?.id,
          });
        } else {
          setIsUpdating(false);
          setFieldErrors({
            createOrder:
              "No se encontraron suficientes existencias para crear el documento",
          });
        }
      })
      .catch((error) => {
        setFieldErrors({
          createOrder: error?.response?.data?.message || "",
        });
        setIsUpdating(false);
      });
  }

  function readDirectionEnvio(direction) {
    return (
      <div className="flex flex-col">
        <TextStyle>
          {direction.direccion && direction.direccion + ", "}
        </TextStyle>
        <TextStyle>
          {direction.ciudad && direction.ciudad + ", "}
          {direction.estado && direction.estado + ", "}
        </TextStyle>
        <TextStyle>
          {direction.codigo_postal && "C.P. " + direction.codigo_postal}.
        </TextStyle>
        <TextStyle variation="subdued">{direction.telefono}</TextStyle>
      </div>
    );
  }

  if (isLoading) {
    return (
      <Layer title="Detalles de la oportunidad" forbidden={forbidden}>
        <ComercioDetalleSkeleton fullWidth />
      </Layer>
    );
  }

  if (!oportunidad) {
    return (
      <Layer title="Detalles de la oportunidad" forbidden={forbidden}>
        <EmptyState
          heading="Oops! Hubo un problema al intentar obtener esta oportunidad"
          action={{
            content: "Volver a intentar",
            onAction: () => window.location.reload(),
          }}
          secondaryAction={{
            content: "Regresar a oportunidades",
            onAction: () => router.goBack(),
          }}
          image="/Ad.svg"
        >
          <p>Intenta de nuevo más tarde</p>
        </EmptyState>
      </Layer>
    );
  }

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      fullWidth
      message="Cambios no guardados"
      saveAction={{
        content: "Guardar",
        onAction: handleUpdateOpportunity,
        disabled: !isDirty || quantityError.length,
        loading: isUpdating,
      }}
      discardAction={{
        onAction: handleDiscard,
        disabled: isUpdating,
      }}
    />
  ) : null;

  const titleMetada = (
    <div className="flex items-center">
      <div className="mr-6">
        <TextStyle variation="subdued">
          {moment(oportunidad?.createdDate).format("D ")} de{" "}
          {moment(oportunidad?.createdDate).format("MMMM YYYY, HH:mm")}
          {` por ${oportunidad?.createdBy?.name} ${oportunidad?.createdBy?.last_name}`}
        </TextStyle>
      </div>
      <CustomBadge
        progress={setPhase(phaseSelected[0])}
        status={
          phases.find(
            (phase) => phase.value.toString() === phaseSelected.toString()
          )?.label
        }
      />
    </div>
  );

  function handleOnBlur(value) {
    if (_.isNumber(value)) {
      setImporte("0");
    }
  }

  async function onAddPhase(name = "") {
    if (name) {
      const newPhase = await createPhase(name).catch((error) => error);
      if (newPhase) {
        setPhaseSelected([newPhase._id]);
        setIsDirty(true);
      }
    }
  }

  async function onAddOrigin(name = "") {
    if (name) {
      const newOrigin = await createOrigin(name).catch((error) => error);
      if (newOrigin) {
        setOriginSelected([newOrigin._id]);
        setIsDirty(true);
      }
    }
  }

  function updateSelection(selected, updateAndLoading = true) {
    !updateAndLoading && setIsLoading(true);
    ApiServiceComercios.obtenerPedidoDatos(selected[0])
      .then(({ ok, cliente, descuentos }) => {
        if (ok) {
          if (updateAndLoading) {
            setOportunidad((oportunidad) => ({
              ...oportunidad,
              clientId: cliente,
            }));
          }
          // Valores por default

          const { promos, maximos, volumenes, productsDiscount, priceToUse } =
            getDiscounts(descuentos, cliente);
          setPorPromosGen(promos);
          setPorVolumen(volumenes);
          setPorMaximo(maximos);
          setProductDiscounts(productsDiscount);
          setPriceToUseId(priceToUse);
          setCliente(cliente);
          updateAndLoading && setIsDirty(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    setClientSelectedOptions(selected);
  }

  const updateTextContact = (value) => {
    setInputValueClient(value);
    setIsDirty(true);
    if (value === "") {
      setClientesOptions([]);
      return;
    }
    const filterRegex = new RegExp(value, "i");
    const resultOptions = [].filter((option) =>
      option.label.match(filterRegex)
    );
    setClientesOptions(resultOptions);
  };

  function onChangeArticleValue(value, id, total) {
    setIsDirty(true);
    let articles = [...articleCount];
    articles.forEach((obj) => {
      if (obj.id === id) {
        obj.cantidad = value;
      }
    });
    setArticleCount(articles);
    setTotales(totales.map((i) => (i.articuloId === id ? { ...i, total } : i)));
  }

  function handleChangeDocuments(values = []) {
    setBlobFile(values);
    setIsDirty(true);
  }

  const clienteData = (
    <Card fullWidth>
      <Card.Section>
        <div className="flex justify-between items-center">
          <h3 className="Polaris-Subheading">CLIENTE</h3>
          <div className="relative" style={{ width: 40, top: -10 }}>
            <img
              className="rounded-full"
              src="/clienteAvatar.png"
              alt="Cliente"
            />
          </div>
        </div>
        <div className="flex flex-col">
          <Link external url={`/admin/clientes/${oportunidad?.clientId?._id}`}>
            {oportunidad?.clientId?.nombre}
          </Link>
        </div>
      </Card.Section>
      <Card.Section title="Contacto">
        <div className="flex flex-col">
          <TextStyle variation="subdued">
            {oportunidad?.clientId?.nombre || ""}
          </TextStyle>
          <Link>{oportunidad?.clientId?.email || ""}</Link>
        </div>
      </Card.Section>
      {!oportunidad?.direccionEnvioId ? (
        <Card.Section title="Dirección">
          <div className="flex flex-col">
            <TextStyle variation="subdued">
              No se suministró la dirección de envío
            </TextStyle>
          </div>
        </Card.Section>
      ) : (
        <Card.Section title="Dirección de envío">
          {oportunidad?.direccionEnvioId &&
          oportunidad?.clientId &&
          oportunidad?.clientId.direcciones.some(
            (i) => i.consignatario_id === oportunidad?.direccionEnvioId
          ) ? (
            readDirectionEnvio(
              oportunidad?.clientId?.direcciones.find(
                (i) => i.consignatario_id === oportunidad?.direccionEnvioId
              )
            )
          ) : (
            <TextStyle>Aún no hay una dirección de envío asignada</TextStyle>
          )}
        </Card.Section>
      )}
    </Card>
  );

  const vendorData = (
    <VendorAutocomplete
      vendorInputValue={vendorInputValue}
      setVendorInputValue={(value) => {
        setVendorInputValue(value);
        setIsDirty(true);
      }}
      placeholder="Buscar vendedor"
    />
  );

  const buscarClienteField = (
    <Autocomplete.TextField
      clearButton
      onClearButtonClick={() => updateTextContact("")}
      onChange={updateTextContact}
      value={inputValueClient}
      prefix={<Icon source={SearchMinor} color="inkLighter" />}
      placeholder="Busca un cliente"
    />
  );

  const emptyClientData = isLoadingClientes ? (
    <Card>
      <Card.Section>
        <SkeletonBodyText lines={4} />
      </Card.Section>
      <Card.Section>
        <SkeletonBodyText lines={2} />
      </Card.Section>
    </Card>
  ) : (
    <Card title="Cliente">
      <Card.Section>
        <Autocomplete
          listTitle="Clientes disponibles"
          willLoadMoreResults
          loading={isLoadingClientes}
          options={clientesOptions}
          selected={clientSelectedOptions}
          onSelect={updateSelection}
          textField={buscarClienteField}
        />
      </Card.Section>
    </Card>
  );

  const fileUpload = (
    <DropZone.FileUpload actionHint="o arrastra aquí para subir" />
  );

  const hasCustomer = !_.isEmpty(clienteDB);

  return (
    <Layer
      title={`Detalle oportunidad ${oportunidad.folio}`}
      forbidden={forbidden}
    >
      <Page
        fullWidth
        title={oportunidad.folio}
        subtitle={titleMetada}
        breadcrumbs={[
          { content: "Oportunidades", onAction: () => router.goBack() },
        ]}
        pagination={{
          hasPrevious:
            pageState.indexOf(id) !== 0 ||
            pagination.currentPage * pagination.sizePerPage !== 0,
          onPrevious: previousPage,
          nextTooltip: "Siguiente oportunidad",
          hasNext: pageState.indexOf(id) + 1 < pageState.length,
          onNext: nextPage,
          previousTooltip: "Oportunidad anterior",
        }}
        actionGroups={[actionGroups]}
        secondaryActions={[
          {
            content: "Duplicar",
            onAction: duplicarOportunidad,
          },
        ]}
      >
        <PreventTransitionPrompt
          when={isDirty}
          setIsBlocking={setIsDirty}
          router={router}
          title="Tienes cambios sin guardar en esta página"
          message="Si abandonas esta página, perderás todos los cambios no guardados. ¿Estás seguro de que quieres salir de esta página?"
        />
        {contextualSaveBarMarkup}
        {isModalActive && (clienteDB || oportunidad?.clientId) && (
          <BuscarProductoModal
            primaryActionTitle="Agregar a la oportunidad"
            selected={articleCount.map((a) => a.id)}
            selectArticulos={(items) => selectArticulos(items, true)}
            updateText={updateArticleText}
            value={inputArticleValue}
            isActive={isModalActive}
            handleChange={handleModalChange}
            idCliente={
              oportunidad?.clientId?._id ? oportunidad.clientId._id : ""
            }
            mostrarExistenciaProducto={false}
            almacenId={
              oportunidad?.clientId
                ? oportunidad.clientId.almacen.id
                : clienteDB?.almacen?.id
            }
            validateAvailability={false}
          />
        )}
        <Layout>
          <Layout.Section>
            <CustomerStatusBanner status={clienteDB?.status_microsip} />
            <div className="selectClientSmall">
              {oportunidad.clientId ? clienteData : emptyClientData}
            </div>
            <Card title="Detalles de la oportunidad">
              <Card.Section>
                <div className="mb-6">
                  <Stack distribution="fillEvenly" wrap spacing="loose">
                    <TextField
                      label="Importe"
                      type="number"
                      value={importe}
                      onChange={handleImporte}
                      onBlur={() => handleOnBlur(importe)}
                      pattern="^\d*(\.\d{0,2})?$"
                      prefix="$"
                    />
                    <DatePicker
                      date={selectedDates}
                      title="Fecha de cierre"
                      onSelectDate={({ start }) => {
                        setSelectedDates(start);
                        setIsDirty(true);
                      }}
                      requiredIndicator
                      disableBefore={new Date()}
                    />
                  </Stack>
                </div>
                <div className="mb-6">
                  <Stack distribution="fillEvenly" wrap spacing="loose">
                    <Select
                      label="Tipo"
                      options={["Cliente", "Prospecto"]}
                      value={typeSelected}
                      onChange={handleTypeChange}
                      requiredIndicator
                    />
                    <CreateCollectionAutocomplete
                      listTitle="Etapas sugeridas"
                      loading={isLoadingPhases}
                      value={
                        phases.find(
                          (phase) =>
                            phase.value.toString() === phaseSelected.toString()
                        )?.label
                      }
                      label="Etapa"
                      deselectedOptions={phases}
                      setSelectedOption={handlePhaseChange}
                      selectedOptions={phaseSelected}
                      requiredIndicator
                      onAddNew={onAddPhase}
                    />
                  </Stack>
                </div>
                <div className="mb-6">
                  <Stack distribution="fillEvenly" wrap spacing="loose">
                    <CreateCollectionAutocomplete
                      listTitle="Orígines sugeridos"
                      loading={isLoadingOrigins}
                      label="Origen"
                      value={
                        origins.find(
                          (origin) =>
                            origin.value.toString() ===
                            originSelected.toString()
                        )?.label
                      }
                      deselectedOptions={origins}
                      setSelectedOption={handleOriginChange}
                      selectedOptions={originSelected}
                      requiredIndicator
                      onAddNew={onAddOrigin}
                    />
                    <Select
                      label="Probabilidad"
                      options={PERCENTAGES}
                      value={probabilitySelected}
                      onChange={handleProbabilityChange}
                    />
                  </Stack>
                </div>

                <Stack distribution="fillEvenly" wrap>
                  <TextField
                    label="Descripción de la oportunidad"
                    value={description}
                    onChange={handleDescriptionChange}
                    maxLength={2500}
                    autoComplete="off"
                    showCharacterCount
                    multiline={2}
                  />
                </Stack>
              </Card.Section>
            </Card>
            {hasCustomer && !isLoading ? (
              <Card title="Artículos de la oportunidad">
                <Card.Section>
                  {/* BUSCAR PRODUCTOS */}
                  <TextField
                    type="search"
                    onChange={updateArticleText}
                    value={inputArticleValue}
                    prefix={<Icon source={SearchMinor} color="inkLighter" />}
                    placeholder="Buscar productos"
                    disabled={!oportunidad.clientId}
                    connectedRight={
                      <Button
                        disabled={!oportunidad.clientId}
                        onClick={handleModalChange}
                      >
                        Buscar
                      </Button>
                    }
                  />
                  {!isLoading
                    ? selectedArticulos.map((articulo, index) => {
                        return (
                          <div
                            key={
                              articulo._id ||
                              "_" + Math.random().toString(36).substring(2, 9)
                            }
                          >
                            {articulo ? (
                              <ArticuloItem
                                descMaximos={porMaximo}
                                descVolumenAplicada={porVolumen}
                                runOnInsertBegin={willReturnInitialData}
                                descPromoAplicada={porPromosGen}
                                articulo={articulo}
                                showAsDetail={false}
                                quantity={Number(
                                  articleCount.find(
                                    (ar) => ar.id === articulo._id
                                  )?.cantidad || 1
                                )}
                                handleChange={onChangeArticleValue}
                                selectNote={addNoteToArticle}
                                onRemove={onRemoveArticulo}
                                isLast={
                                  oportunidad.articles.length - 1 === index
                                }
                                isAdding
                                setQuantityError={(value, id) =>
                                  setQuantityError((prevState) =>
                                    value
                                      ? prevState.some(
                                          (errorId) => errorId === id
                                        )
                                        ? prevState
                                        : [...prevState, id]
                                      : prevState.filter(
                                          (errorId) => errorId !== id
                                        )
                                  )
                                }
                                currencies={currencies}
                                discounts={productDiscounts}
                                priceToUse={priceToUseId}
                              />
                            ) : null}
                          </div>
                        );
                      })
                    : null}
                </Card.Section>

                <Card.Section>
                  <div className="flex justify-end">
                    <div className="mr-3">
                      <Button
                        secondary
                        onClick={() =>
                          createDocumentFromOpportunity(
                            "/admin/pedidos/agregar"
                          )
                        }
                        disabled={
                          _.isEmpty(oportunidad?.clientId) ||
                          _.isEmpty(clienteDB) ||
                          _.isEmpty(selectedArticulos) ||
                          clienteDB?.status_microsip !== "A"
                        }
                        loading={isUpdating}
                      >
                        Crear pedido
                      </Button>
                    </div>
                    <div>
                      <Button
                        primary
                        onClick={() =>
                          createDocumentFromOpportunity(
                            "/admin/cotizaciones/agregar"
                          )
                        }
                        disabled={
                          _.isEmpty(oportunidad?.clientId) ||
                          _.isEmpty(clienteDB) ||
                          _.isEmpty(selectedArticulos)
                        }
                        loading={isUpdating}
                      >
                        Crear cotización
                      </Button>
                    </div>
                  </div>
                  {fieldErrors.createOrder && (
                    <div className="flex justify-end my-4">
                      <TextStyle variation="negative">
                        {fieldErrors.createOrder}
                      </TextStyle>
                    </div>
                  )}
                </Card.Section>
              </Card>
            ) : null}
            <Card
              title={
                <div className="flex items-center justify-between">
                  <div className="flex">
                    <Heading>Documentos</Heading>
                    <div className="mx-2" />
                    {isAddingFiles && <Spinner size="small" />}
                  </div>
                  {selectedFiles.length > 0 && (
                    <Button
                      plain
                      destructive
                      onClick={() => {
                        handleChangeDocuments(
                          blobFile.filter((i) => !selectedFiles.includes(i.url))
                        );
                        setSelectedFiles([]);
                      }}
                    >
                      Eliminar documentos
                    </Button>
                  )}
                </div>
              }
            >
              <Card.Section>
                <ZoomImage
                  version={2}
                  setSelectedFiles={setSelectedFiles}
                  selectedFiles={selectedFiles}
                  images={blobFile.filter((file) =>
                    validImageTypes.includes(file.mimetype)
                  )}
                >
                  <div className="flex flex-row w-full">
                    <DropZone
                      labelHidden
                      outline
                      onDrop={handleDropZone}
                      type="file"
                      accept={validFileTypes.join(",")}
                      openFileDialog={openFileDialog}
                      onFileDialogClose={toggleOpenFileDialog}
                      disabled={isAddingFiles}
                    >
                      {fileUpload}
                    </DropZone>
                  </div>
                </ZoomImage>
              </Card.Section>
              {blobFile.some(
                (file) => !validImageTypes.includes(file.mimetype)
              ) && (
                <Card.Section>
                  <div className="flex items-center">
                    <Heading>Documentos</Heading>
                    <div className="mx-2" />
                    {isAddingFiles && <Spinner size="small" />}
                  </div>
                  <div className="mt-8">
                    <DropzoneFiles
                      hasDelete
                      onDelete={(f) => {
                        handleChangeDocuments(
                          blobFile.filter((i) => i.url !== f.url)
                        );
                      }}
                      files={blobFile.filter(
                        (file) =>
                          !validImageTypes.includes(file.mimetype) &&
                          !selectedFiles.includes(file.url)
                      )}
                    />
                  </div>
                </Card.Section>
              )}
            </Card>
            <div className="my-8">
              <Timeline
                visible={showHistorial}
                onChange={setShowHistorial}
                initials={extractInitials(usuario.name, usuario.last_name)}
                parentMessageId={id}
                subtitle="Sólo tú y el resto del personal pueden ver los comentarios."
              />
            </div>
          </Layout.Section>
          <Layout.Section secondary>
            <div className="selectClientLarge">
              {oportunidad.clientId ? clienteData : emptyClientData}
            </div>
            <Card sectioned title="Vendedor">
              {vendorData}
            </Card>
            <TagsAutocomplete
              title="Etiquetas"
              subdued
              selected={tagsSelected}
              onSelect={selectTags}
              module="Oportunidades"
            />
          </Layout.Section>
        </Layout>
        <HelpFooter
          title="Oportunidades"
          url="https://help.b2bgo.mx/oportunidades"
        />
      </Page>
    </Layer>
  );
}
