import {
  Card,
  Checkbox,
  FormLayout,
  RangeSlider,
  Select,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";

export default function CollectionsContainer({
  item,
  handleMainChange = () => {},
  handleDeleteSection = () => {},
  currentPage = "",
}) {
  const [collectionList, setCollectionList] = useState({
    title: "",
    titleSize: "medium",
    rowsNumberDesktop: 4,
    hideOnMobile: false,
    rowsNumberMobile: 2,
  });

  useEffect(() => {
    const {
      properties: {
        title,
        titleSize,
        rowsNumberDesktop,
        hideOnMobile,
        rowsNumberMobile,
      },
    } = item;
    setCollectionList((prevState) => ({
      ...prevState,
      title: title || "",
      titleSize: titleSize || "medium",
      rowsNumberDesktop: rowsNumberDesktop || 4,
      hideOnMobile: hideOnMobile || false,
      rowsNumberMobile: rowsNumberMobile || 2,
    }));
  }, [item]);

  const handleChange = (field, value) => {
    handleMainChange(
      "properties",
      { ...collectionList, [field]: value },
      item.id
    );
    setCollectionList((prevState) => ({ ...prevState, [field]: value }));
  };

  const {
    title,
    titleSize,
    rowsNumberDesktop,
    hideOnMobile,
    rowsNumberMobile,
  } = collectionList;

  return (
    <>
      <Card
        primaryFooterAction={
          !["collections-list-page"].includes(currentPage)
            ? {
                content: "Eliminar sección",
                destructive: true,
                outline: true,
                onAction: () => handleDeleteSection(item.id),
              }
            : null
        }
        footerActionAlignment="left"
      >
        <Card.Section>
          <FormLayout>
            <TextStyle variation="strong">Lista de colecciones</TextStyle>
            <TextField
              label="Título"
              value={title}
              onChange={(val) => handleChange("title", val)}
              placeholder="Colecciones"
              autoComplete="off"
            />
            <Select
              label="Tamaño del título"
              placeholder="Selecciona un tamaño"
              value={titleSize}
              onChange={(val) => handleChange("titleSize", val)}
              options={[
                {
                  label: "Pequeño",
                  value: "small",
                },
                {
                  label: "Mediano",
                  value: "medium",
                },
                {
                  label: "Grande",
                  value: "large",
                },
              ]}
            />
            <div className="flex flex-row items-end max-items">
              <RangeSlider
                label="Número de columnas en escritorio"
                output
                min={1}
                max={5}
                step={1}
                value={rowsNumberDesktop}
                onChange={(val) => handleChange("rowsNumberDesktop", val)}
              />
              <div className="ml-6 whitespace-nowrap">
                <TextStyle>{rowsNumberDesktop}</TextStyle>
              </div>
            </div>
          </FormLayout>
        </Card.Section>
        <Card.Section title="DISEÑO PARA MÓVILES">
          <FormLayout>
            <Checkbox
              label="Ocultar en móviles"
              checked={hideOnMobile}
              onChange={(val) => handleChange("hideOnMobile", val)}
            />
            <div className="flex flex-row items-end max-items">
              <RangeSlider
                label="Número de columnas en móviles"
                output
                min={1}
                max={4}
                step={1}
                value={rowsNumberMobile}
                onChange={(val) => handleChange("rowsNumberMobile", val)}
              />
              <div className="ml-6 whitespace-nowrap">
                <TextStyle>{rowsNumberMobile}</TextStyle>
              </div>
            </div>
          </FormLayout>
        </Card.Section>
      </Card>
    </>
  );
}
