import React, { useState, useEffect } from "react";
import { Card, FormLayout, TextField } from "@shopify/polaris";

export default function HeaderSection({ item, handleMainChange }) {
  const [heading, setHeading] = useState({
    phone: "",
    email: "",
  });
  useEffect(() => {
    const {
      properties: { phone, email },
    } = item;
    setHeading((heading) => ({
      ...heading,
      phone: phone || "",
      email: email || "",
    }));
  }, [item]);

  const handleChange = (value, field) => {
    if (field === "phone") {
      value = formatPhoneNumber(value);
    }
    setHeading((prevState) => ({ ...prevState, [field]: value }));
    handleMainChange("properties", { ...heading, [field]: value }, item.id);
  };

  function formatPhoneNumber(phone) {
    let ph = phone.replace(/\D/g, "").substring(0, 10);
    // Backspace and Delete keys
    const deleteKey = true;
    const len = ph.length;
    if (len < 3) {
      ph = "(" + ph;
    } else if (len === 3) {
      ph = "(" + ph + (deleteKey ? "" : ") ");
    } else if (len < 6) {
      ph = "(" + ph.substring(0, 3) + ") " + ph.substring(3, 6);
    } else if (len === 6) {
      ph =
        "(" +
        ph.substring(0, 3) +
        ") " +
        ph.substring(3, 6) +
        (deleteKey ? "" : "-");
    } else {
      ph =
        "(" +
        ph.substring(0, 3) +
        ") " +
        ph.substring(3, 6) +
        " " +
        ph.substring(6, 10);
    }
    return ph;
  }

  return (
    <Card title="Encabezado">
      <Card.Section>
        <FormLayout>
          <TextField
            label="Teléfono"
            placeholder="999 999 99 99"
            type="tel"
            value={heading.phone}
            onChange={(value) => handleChange(value, "phone")}
            autoComplete="off"
          />
          <TextField
            label="Correo"
            type="email"
            placeholder="contacto@empresa.com"
            value={heading.email}
            onChange={(value) => handleChange(value, "email")}
            autoComplete="off"
          />
        </FormLayout>
      </Card.Section>
    </Card>
  );
}
