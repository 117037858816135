import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ActionList, Button, Icon, Link, Popover } from "@shopify/polaris";
import {
  MobileHamburgerMajor,
  CircleRightMajor,
  ProfileMajor,
  ChevronRightMinor,
  ChevronUpMinor,
  CheckoutMajor,
  SearchMajor,
  CustomersMajor,
} from "@shopify/polaris-icons";
import { Menu, Drawer } from "antd";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import useGetElementSizes from "../../hooks/useGetElementSizes";
import useToggle from "../../hooks/useToggle";
import "./SectionsStyled.css";
import { useDispatch, useSelector } from "react-redux";
import { toggleToast } from "../../actions/InteractiveActions";
import { logout } from "../../actions/AuthActions";
import {
  getOrderInfo,
  getTotalArticlesShoppingCart,
  getUserInfo,
} from "../../reducers";
import useDetectTouchWindow from "../../hooks/useDetectTouchWindow";
import ShoppingCart from "../../components/common/ShoppingCart";
import useGetCurrencies from "../../hooks/useGetCurrencies";
import ShoppingCardButton from "../ShoppingCardButton";
import { NavSearchField } from ".";

export default function NavBar({ mode = "view", visibles }) {
  const dispatch = useDispatch();
  const router = useHistory();

  const [isOpen, toggleOpen] = useToggle(false);
  const [isOpenSearchBar, toggleSearchBar] = useToggle(false);
  const [isActivePopover, togglePopover] = useToggle(false);
  const [cartActive, toggleCart] = useToggle(false);
  const [isMobile, toggleMobile] = useState(false);

  const usuario = useSelector(getUserInfo);
  const allArticles = useSelector(getTotalArticlesShoppingCart);
  const { moneda: currency } = useSelector(getOrderInfo);

  const { currencies } = useGetCurrencies();
  const { measuredRef, width } = useGetElementSizes();
  const isTouchable = useDetectTouchWindow();

  const [storeData, setStoreData] = useState({
    imagen: "",
    name: "",
  });

  useEffect(() => {
    toggleMobile(width <= 767);
  }, [width]);

  const [expandOnHover, setExpandOnHover] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      ApiServiceComercios.getImageData()
        .then(({ storeDB }) => {
          setStoreData({
            imagen: storeDB?.imagen?.url,
            name: storeDB?.nombre,
          });
        })
        .catch((error) => {
          throw error;
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    const isTouchable = window.matchMedia("(pointer: coarse)").matches;
    const getFiltersData = () => {
      ApiServiceComercios.getMenuList().then(({ expandOnHover, menus }) => {
        // const hasDuplicatedPath = ""
        const items = menus.map((menu) => ({
          label: (
            <Link
              url={
                ((expandOnHover && !isTouchable) ||
                  (menu.url && menu.items.length === 0)) &&
                mode === "view"
                  ? menu.url
                  : ""
              }
              removeUnderline
              monochrome
            >
              {menu.title}
            </Link>
          ),
          expandIcon: ({ isOpen }) => {
            return menu.items.length === 0 ? (
              <></>
            ) : (
              <Icon source={isOpen ? ChevronUpMinor : ChevronRightMinor} />
            );
          },
          children: menu.items?.map((item) => ({
            type: "group",
            label: (
              <Link
                url={mode === "view" ? item.url : ""}
                removeUnderline
                monochrome
              >
                {item.title}
              </Link>
            ),
            key: item.url,
            children: item.items?.map((subItem) => ({
              label: (
                <Link
                  url={mode === "view" ? subItem.url : ""}
                  removeUnderline
                  monochrome
                >
                  {subItem.title}
                </Link>
              ),
              key: subItem.url,
            })),
          })),
        }));
        setExpandOnHover(expandOnHover);
        setItems(items);
      });
    };
    getFiltersData();
  }, [mode]);

  const handleClick = (event) => {
    const { key } = event;
    key && router.push(key);
  };

  const menu = (
    <Menu
      triggerSubMenuAction={expandOnHover && !isTouchable ? "hover" : "click"}
      style={{ width: "100%" }}
      className="justify-center"
      onClick={handleClick}
      mode={isMobile ? "inline" : "horizontal"}
      items={items}
    />
  );

  const logoName = storeData.imagen ? (
    <img
      onClick={() => router.push("/")}
      style={{ height: 30 }}
      src={storeData.imagen}
      alt={storeData.name}
    />
  ) : (
    <p
      onClick={() => router.push("/")}
      className="ml-6"
      style={{ fontWeight: 600, fontSize: 14 }}
    >
      {storeData.name}
    </p>
  );

  const menuMobil = (
    <Drawer
      className="menu-drawer"
      title={logoName}
      placement="left"
      closable
      onClose={toggleOpen}
      open={!isMobile ? false : isOpen}
    >
      {menu}
    </Drawer>
  );

  return (
    <>
      <div className="w-full px-4" ref={measuredRef}>
        {menuMobil}
        <NavSearchField
          mode={mode}
          visibles={visibles}
          active={isOpenSearchBar}
          toggleActive={toggleSearchBar}
        />
        {cartActive && (
          <ShoppingCart
            isCartVisible={cartActive}
            onCloseCart={toggleCart}
            currency={currency}
            currencies={currencies}
            modeView="online_store"
          />
        )}
        <div
          className={`flex items-center p-4 bg-white border-t border-gray-200 ${
            isMobile ? "justify-end" : "justify-between"
          }`}
          style={{ height: 56 }}
        >
          {isMobile ? (
            <div className="ml-4">
              <Button
                icon={MobileHamburgerMajor}
                plain
                onClick={mode === "view" ? toggleOpen : () => {}}
              />
            </div>
          ) : (
            <>
              <div className="flex flex-1 items-center">{logoName}</div>
              <div className="flex-1 flex justify-center">{menu}</div>
            </>
          )}
          <div className="flex-1 flex justify-end items-center">
            <div className="mr-6">
              <Button
                onClick={toggleSearchBar}
                icon={SearchMajor}
                monochrome
                size="large"
                plain
              />
            </div>
            <Popover
              active={isActivePopover}
              activator={
                <div className="mr-2 flex">
                  <Button
                    plain
                    onClick={mode === "view" ? togglePopover : () => {}}
                    icon={CustomersMajor}
                  />
                </div>
              }
              onClose={togglePopover}
            >
              <Popover.Pane>
                <ActionList
                  items={[
                    {
                      content: (
                        <p className="whitespace-nowrap">Ir a tu cuenta</p>
                      ),
                      icon: ProfileMajor,
                      onAction: () => {
                        router.replace("/cliente");
                      },
                    },
                    {
                      content: "Cerrar sesión",
                      icon: CircleRightMajor,
                      onAction: () => {
                        dispatch(toggleToast({ message: "" }));
                        dispatch(logout(usuario._id)).then((res) => {
                          router.replace("/login");
                        });
                      },
                    },
                  ]}
                />
              </Popover.Pane>
            </Popover>
            <div className="ml-6 mt-4">
              <ShoppingCardButton
                icon={CheckoutMajor}
                badge={allArticles}
                onClick={mode === "view" ? toggleCart : () => {}}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
