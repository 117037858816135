import { useCallback, useRef, useState, useEffect } from "react";
import {
  Autocomplete,
  Button,
  Icon,
  TextContainer,
  Thumbnail,
} from "@shopify/polaris";
import { MobileCancelMajor, SearchMinor } from "@shopify/polaris-icons";
import { Drawer } from "antd";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import "./SectionsStyled.css";
import useDebounce from "../../services/useDebounce";
import { useHistory } from "react-router-dom";
import { getImageVideoUrl } from "../../utils/productValidators";

export default function NavBar({
  mode = "view",
  visibles,
  active = false,
  toggleActive = () => {},
}) {
  const queryValueRef = useRef();
  const router = useHistory();

  const [options, setOptions] = useState([]);
  const [queryValue, setQueryValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = useCallback((value) => setQueryValue(value), []);
  const debouncedSearchTerm = useDebounce(queryValue, 900);

  useEffect(() => {
    queryValueRef.current = queryValue;
  });

  useEffect(() => {
    const fetchArticulos = () => {
      if (queryValueRef.current) {
        setLoading(true);
        ApiServiceComercios.getItemsByFilters({
          limit: 10,
          skip: 0,
          query: queryValueRef.current,
        })
          .then(({ ok, articulos }) => {
            if (ok) {
              try {
                const options = articulos.map((articulo) => ({
                  label: articulo.nombre || articulo.titulo || "",
                  value: articulo.handle,
                  key: articulo._id,
                  media: (
                    <Thumbnail
                      source={
                        getImageVideoUrl(articulo?.imagen, "image", false) ||
                        "/Default Photo.png"
                      }
                    />
                  ),
                }));

                setOptions([{ title: "Articulos", options }]);
              } catch (error) {}
            }
            setLoading(false);
          })
          .catch(() => setLoading(false));
      }
    };
    fetchArticulos();
  }, [debouncedSearchTerm]);

  const updateSelection = (handle) => {
    router.push(`/products/${handle[0]}`);
  };
  const handleClose = () => {
    toggleActive();
    // setQueryValue("");
  };

  const textField = (
    <Autocomplete.TextField
      value={queryValue}
      onChange={handleChange}
      clearButton
      onClearButtonClick={() => setQueryValue("")}
      placeholder="Buscar"
      autoComplete="off"
      suffix={<Icon source={SearchMinor} />}
      autoFocus
    />
  );

  const emptyState = (
    <>
      <div className="justify-center flex items-center">
        <Icon source={SearchMinor} />
      </div>
      <div style={{ textAlign: "center" }}>
        <TextContainer>No se encontraron artículos</TextContainer>
      </div>
    </>
  );

  const drawerBody = (
    <div className="drawer-search-input flex items-center">
      <Autocomplete
        options={options}
        onSelect={updateSelection}
        textField={textField}
        loading={loading}
        selected={[]}
        emptyState={emptyState}
        willLoadMoreResults
      />
      <div className="drawer-search-close-btn ml-8">
        <Button
          monochrome
          plain
          icon={MobileCancelMajor}
          size="large"
          onClick={handleClose}
        />
      </div>
    </div>
  );

  const drawer = (
    <Drawer
      contentWrapperStyle={{
        backgroundColor: "transparent",
        paddingTop: visibles * 40,
      }}
      title=""
      placement="top"
      onClose={handleClose}
      open={active}
      closable={false}
      headerStyle={{ backgroundColor: "red" }}
      height={visibles * 40 + 56}
      bodyStyle={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
      }}
    >
      {drawerBody}
    </Drawer>
  );

  return <>{drawer}</>;
}
