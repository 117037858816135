import ApiServiceComercios from "../services/ApiServiceComercios";
import NormalizeById from "../utils/NormalizedById";
import { getPaginationFacturas } from "../reducers";

export const FACTURAS_BEGIN = "FACTURAS_BEGIN";
export const ADD_FACTURAS = "ADD_FACTURAS";
export const FACTURAS_FAILED = "FACTURAS_FAILED";
export const CLEAR_FACTURAS = "CLEAR_FACTURAS";
export const RESET_PAGE = "RESET_PAGE";
export const ADD_ID_FACTURA = "ADD_ID_FACTURA";
export const CHANGE_PAGE = "CHANGE_PAGE";

export function fetchFacturas(params) {
  return async (dispatch, getState) => {
    let pagination = getPaginationFacturas(getState());
    dispatch(clearFacturas());
    dispatch(facturasBegin());
    return ApiServiceComercios.obtenerFacturas({
      ...params,
      limit: pagination.sizePerPage,
      skip: pagination.currentPage * pagination.sizePerPage,
    })
      .then((result) => {
        let data = NormalizeById(result.facturas, "_id");
        data["total"] = result.total;
        data["allIdsDB"] = result.allIds;
        data["moneda"] = result.moneda;
        dispatch(addFacturas(data));
        return result;
      })
      .catch((err) => {
        dispatch(facturasFailed());
      });
  };
}

export const facturasBegin = () => ({
  type: FACTURAS_BEGIN,
});

export const addFacturas = (data) => ({
  type: ADD_FACTURAS,
  payload: { data },
});

export const facturasFailed = () => ({
  type: FACTURAS_FAILED,
});

export const clearFacturas = () => ({
  type: CLEAR_FACTURAS,
});

export const changePage = (currentPage) => ({
  type: CHANGE_PAGE,
  payload: { data: { currentPage } },
});

export const resetPage = () => ({
  type: RESET_PAGE,
});

export const addIdFactura = (id) => ({
  type: ADD_ID_FACTURA,
  payload: { id },
});
