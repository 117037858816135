import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  EmptyState,
  ResourceItem,
  ResourceList,
  Tabs,
  TextStyle,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";

import { TabsEquipoCard } from "../../utils/Tabs";
import { useDispatch, useSelector } from "react-redux";
import AddContactModal from "../AddContactModal";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { toggleToast } from "../../actions/InteractiveActions";

export const ClientsContactCard = () => {
  const idAuth = useSelector((state) => state.Auth.userData._id);
  const { id: idClient } = useParams();
  const dispatch = useDispatch();

  const [contactList, setContactList] = useState([]);
  const [moduleList, setModuleList] = useState([]);
  const [showModalOptions, setShowModalOptions] = useState();

  const [dataToEdit, setDataToEdit] = useState(null);
  const [tabSelected, setTabSelected] = useState(0);

  useEffect(() => {
    onInitData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInitData = () => {
    // Verifica si el id sera del cliente por parámetro (vista desde admin) o iniciado sesión
    let id = idClient || idAuth;
    ApiServiceComercios.obtenerClienteComercio(id).then(({ cliente, modules }) => {
      setContactList(cliente.contacts);
      setModuleList(modules);
    });
  };

  const onCloseModal = () => {
    setShowModalOptions((state) => !state);
    setDataToEdit(null);
  };

  const showModalToEdit = (data) => {
    setShowModalOptions(true);
    setDataToEdit(data);
  };

  const emptyState = (
    <EmptyState heading="No hay contactos">
      <p>Actualmente no hay contactos</p>
    </EmptyState>
  );

  const resendInvitation = (idContact) => {
    let id = idClient || idAuth; // Id del cliente
    ApiServiceComercios.resendInvitationContact(id, idContact)
      .then(({ message }) => {
        dispatch(toggleToast({ message }));
      })
      .catch(() => {
        dispatch(toggleToast({ message: "Error, inténtelo más tarde" }));
      });
  };

  return (
    <>
      <Card
        title="Contactos"
        actions={[
          {
            content: "Agregar",
            onAction: onCloseModal,
          },
        ]}
      >
        <Tabs tabs={TabsEquipoCard} selected={tabSelected} onSelect={setTabSelected}>
          <ResourceList
            items={contactList.filter(
              ({ estatus }) => estatus.tipo === (tabSelected === 0 ? 1 : 3)
            )}
            emptyState={emptyState}
            renderItem={(item) => {
              let avatar = <Avatar customer size="medium" />;

              return (
                <ResourceItem id={item.clientId} media={avatar}>
                  <div className="grid grid-cols-2">
                    <div className="flex flex-col" onClick={() => showModalToEdit(item)}>
                      <TextStyle variation="strong">
                        {item.name} {item.last_name}
                      </TextStyle>
                      <TextStyle variation="subdued">{item.email}</TextStyle>
                    </div>

                    {item.estatus.tipo === 1 ? (
                      // Is his status is active, show this information
                      <div
                        className="flex justify-between items-center"
                        onClick={() => showModalToEdit(item)}
                      >
                        <TextStyle>{item.phone && "+"}{item.phone}</TextStyle>

                        <TextStyle>{item.department}</TextStyle>
                      </div>
                    ) : (
                      // Is his status is Guest, show the option for invite again
                      <div className="flex justify-end">
                        <Button onClick={() => resendInvitation(item._id)}>
                          Reenviar invitación
                        </Button>
                      </div>
                    )}
                  </div>
                </ResourceItem>
              );
            }}
          />
        </Tabs>
      </Card>

      {showModalOptions && (
        <AddContactModal
          isActive={showModalOptions}
          onClose={onCloseModal}
          data={dataToEdit}
          refresh={onInitData}
          modules={moduleList}
        />
      )}
    </>
  );
};
