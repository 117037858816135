import ApiServiceComercios from "../services/ApiServiceComercios";
import NormalizeById from "../utils/NormalizedById";
import { getPaginationAnuncios } from "../reducers";

export const ANUNCIOS_BEGIN = "ANUNCIOS_BEGIN";
export const ADD_ANUNCIOS = "ADD_ANUNCIOS";
export const ANUNCIOS_FAILED = "ANUNCIOS_FAILED";
export const CLEAR_ANUNCIOS = "CLEAR_ANUNCIOS";
export const RESET_PAGE = "RESET_PAGE";
export const CHANGE_PAGE = "CHANGE_PAGE";

export function fetchAnuncios(params) {
  return async (dispatch, getState) => {
    let pagination = getPaginationAnuncios(getState());
    dispatch(clearAnuncios());
    dispatch(anunciosBegin());
    return ApiServiceComercios.obtenerAnuncios({
      ...params,
      limit: pagination.sizePerPage,
      skip: pagination.currentPage * pagination.sizePerPage,
    })
      .then((result) => {
        let data = NormalizeById(result.anuncios, "_id");
        data["total"] = result.total;
        dispatch(addAnuncios(data));
        return result;
      })
      .catch((err) => {
        dispatch(anunciosFailed());
      });
  };
}

export const anunciosBegin = () => ({
  type: ANUNCIOS_BEGIN,
});

export const addAnuncios = (data) => ({
  type: ADD_ANUNCIOS,
  payload: { data },
});

export const anunciosFailed = () => ({
  type: ANUNCIOS_FAILED,
});

export const clearAnuncios = () => ({
  type: CLEAR_ANUNCIOS,
});

export const changePage = (currentPage) => ({
  type: CHANGE_PAGE,
  payload: { data: { currentPage } },
});

export const resetPage = () => ({
  type: RESET_PAGE,
});
