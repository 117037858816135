import React, { useEffect, useState } from "react";
import { Table } from "antd";

import { AddMajor } from "@shopify/polaris-icons";

import { Button, TextStyle } from "@shopify/polaris";
import AddElementMenuModal from "./AddElementMenuModal";

import { sortableContainer, sortableElement } from "react-sortable-hoc";

import arrayMove from "array-move";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { useDispatch } from "react-redux";
import { toggleToast } from "../../actions/InteractiveActions";

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

export const ExpandedRowThree = ({
  data: dataParent,
  handleReload,
  columns: columnsPartner = [],
}) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [columns, setColumns] = useState([]);

  const [data, setData] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);

  useEffect(() => {
    let dataFormated = dataParent?.items.map((subItem, index) => ({
      index,
      order: index,
      key: index,
      ...subItem,
    }));

    setData(dataFormated);
  }, [dataParent]);

  useEffect(() => {
    let columnsFormated = columnsPartner.map((item, index) => {
      let newItem = {
        ...item,
        width: 140,
      };

      return index === 0 ? newItem : item;
    });
    setColumns(columnsFormated);
  }, [columnsPartner]);

  const DraggableContainer = (props) => {
    return (
      <SortableContainer
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={onSortEnd}
        {...props}
      />
    );
  };

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(data, oldIndex, newIndex);
      newData.forEach((data, index) => (data["order"] = index));

      // Update the order in DB to show in the table
      ApiServiceComercios.putMenuList(dataParent?._id, {
        items: newData.map((item) => item?._id),
      }).then(({ message }) => {
        dispatch(
          toggleToast({
            message,
          })
        );
      });

      setData(newData);
    }
  };

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = data.findIndex((x) => x.index === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  const closeModal = () => {
    setShowModal(false);
    setDataEdit(null);
  };

  return (
    <div>
      <Table
        columns={columns}
        className={`${data.length === 0 && "hidden"}`}
        dataSource={data}
        showHeader={false}
        pagination={false}
        locale={{
          emptyText: <div />,
        }}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />

      <div className="pl-24 pt-4 self-center">
        <Button plain onClick={() => setShowModal(true)} icon={AddMajor}>
          Agregar sub elemento a
          <TextStyle variation="strong"> {dataParent.title}</TextStyle>
        </Button>
      </div>

      {showModal && (
        <AddElementMenuModal
          active={showModal}
          isParent={false}
          handleModal={closeModal}
          handleReload={handleReload}
          idParent={dataParent?._id}
          idsSubItems={dataParent?.items}
          dataEdit={dataEdit}
        />
      )}
    </div>
  );
};
