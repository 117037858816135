import React, { useState } from "react";
import {
  Banner,
  Card,
  Checkbox,
  FormLayout,
  Modal,
  TextStyle,
} from "@shopify/polaris";

export default function MicrosipConfigModal({
  isOpen,
  handleChange,
  data,
  handleSave,
}) {
  const [articuloDatos, setArticuloDatos] = useState({
    nombre: data.nombre || false,
    descripcion: data.descripcion || false,
    codigo_barra: data.codigo_barra || false,
    factor_venta: data.factor_venta || false,
    peso: data.peso || false,
    imagen: data.imagen || false,
    packingKeys: data.packingKeys || false,
    factor_uni_venta: data.factor_uni_venta || false,
    linea_articulo: data.linea_articulo || false,
  });

  function handleChangeValue(value, key) {
    setArticuloDatos((state) => ({ ...state, [key]: value }));
  }

  return (
    <Modal
      title="Configuración de sincronización"
      open={isOpen}
      onClose={handleChange}
      primaryAction={{
        content: "Aceptar",
        onAction: () => handleSave(articuloDatos),
      }}
      secondaryActions={[{ content: "Cancelar", onAction: handleChange }]}
    >
      <Banner status="info">
        Activa el campo que deseas mantener sincronizado con tu sistema
        Microsip. Mantenlo desactivado si deseas gestionarlo en b2bGO.{" "}
      </Banner>
      <Card sectioned>
        <TextStyle>Datos de los Artículos</TextStyle>
        <FormLayout>
          <Checkbox
            label="Nombre"
            helpText="El nombre de tu producto, tal y como deseas que tus clientes lo vean."
            checked={articuloDatos.nombre}
            onChange={(value) => handleChangeValue(value, "nombre")}
          />
          <Checkbox
            label="Descripción"
            helpText="Describe tus productos de forma detallada para informar y persuadir a tus clientes"
            checked={articuloDatos.descripcion}
            onChange={(value) => handleChangeValue(value, "descripcion")}
          />
          <Checkbox
            label="Código de barras"
            helpText="Los revendedores generalmente utilizan los códigos de barras."
            checked={articuloDatos.codigo_barra}
            onChange={(value) => handleChangeValue(value, "codigo_barra")}
          />
          <Checkbox
            label="Factor de venta"
            helpText="Puedes establecer el número de unidades en las que se agrupa y vende un producto."
            checked={articuloDatos.factor_venta}
            onChange={(value) => handleChangeValue(value, "factor_venta")}
          />
          <Checkbox
            label="Peso"
            helpText="Peso real del producto. Este campo solo aparece cuando la opción Este es un producto físico está marcada."
            checked={articuloDatos.peso}
            onChange={(value) => handleChangeValue(value, "peso")}
          />
          <Checkbox
            label="Imagen"
            helpText="El uso de imágenes permite apreciar mejor un artículo y aumenta la confianza de tus clientes en la calidad de tus productos."
            checked={articuloDatos.imagen}
            onChange={(value) => handleChangeValue(value, "imagen")}
          />
          <Checkbox
            label="Clave de empaques"
            helpText="Las claves de empaque permiten buscar a los artículos y agregarlos a los pedidos y cotizaciones"
            checked={articuloDatos.packingKeys}
            onChange={(value) => handleChangeValue(value, "packingKeys")}
          />
          <Checkbox
            label="Cantidad mínimo"
            helpText="Puedes establecer la cantidad mínima del producto"
            checked={articuloDatos.factor_uni_venta}
            onChange={(value) => handleChangeValue(value, "factor_uni_venta")}
          />
          <Checkbox
            label="Línea de artículo"
            helpText="La línea de artículo a la cual pertenece tu artículo"
            checked={articuloDatos.linea_articulo}
            onChange={(value) => handleChangeValue(value, "linea_articulo")}
          />
        </FormLayout>
      </Card>
      <Banner status="warning" title="Integridad de la sincronización">
        Para mantener la integridad de la información, el campo Clave principal
        no es editable. Los Precios y Existencias siempre se actualizarán.
      </Banner>
    </Modal>
  );
}
