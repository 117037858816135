import ApiServiceComercios from "../services/ApiServiceComercios";
import NormalizedById from "../utils/NormalizedById";
import { getPaginationClientes } from "../reducers";

export const ADD_CLIENTES_BEGIN = "ADD_CLIENTES_BEGIN";
export const ADD_CLIENTES = "ADD_CLIENTES";
export const ADD_CLIENTES_FAILED = "ADD_CLIENTES_FAILED";
export const CLEAR_CLIENTES = "CLEAR_CLIENTES";
export const RESET_PAGE = "RESET_PAGE";
export const CHANGE_PAGE = "CHANGE_PAGE";

export function fetchClientesComercio(params) {
  return async (dispatch, getState) => {
    let pagination = getPaginationClientes(getState());
    dispatch(clearUsuarios());
    dispatch(addClientesBegin());
    return ApiServiceComercios.obtenerClientesComercio({
      ...params,
      limit: pagination.sizePerPage,
      skip: pagination.currentPage * pagination.sizePerPage,
    })
      .then((response) => {
        let data = NormalizedById(response.clientes, "_id");
        data["total"] = response.total;
        data["allIdsDB"] = response.allIds;
        dispatch(addClientes(data));
        return response;
      })
      .catch((error) => {
        dispatch(addClientesFailed());
        throw error;
      });
  };
}

export function obtenerClienteComercio(id) {
  return async (dispatch) => {
    return ApiServiceComercios.obtenerClienteComercio(id)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export const addClientesBegin = () => ({
  type: ADD_CLIENTES_BEGIN,
});

export const addClientes = (data) => ({
  type: ADD_CLIENTES,
  payload: { data },
});

export const addClientesFailed = () => ({
  type: ADD_CLIENTES_FAILED,
});

export const clearUsuarios = () => ({
  type: CLEAR_CLIENTES,
});

export const changePage = (currentPage) => ({
  type: CHANGE_PAGE,
  payload: { data: { currentPage } },
});

export const resetPage = () => ({
  type: RESET_PAGE,
});
