import React, { useState } from "react";
import { Badge, Button, TextStyle } from "@shopify/polaris";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import "./ForbiddenLayoutStyles.css";
import { useDispatch } from "react-redux";
import { toggleToast } from "../../actions/InteractiveActions";

export default function ForbiddenLayout() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  function crecerPlan() {
    setIsLoading(true);
    ApiServiceComercios.crecerPlanComercio()
      .then(({ message }) => {
        dispatch(toggleToast({ message }));
      })
      .finally((err) => {
        setIsLoading(false);
      });
  }

  return (
    <div className="ui-empty-state">
      <div className="ui-empty-state__section">
        <div className="ui-empty-state__subsection__foreground">
          <div className="ui-empty-state__subsection">
            <div>
              <TextStyle variation="strong">Tu plan actual: </TextStyle>
              <Badge status="attention">Freemium</Badge>
            </div>
            <h2 className="ui-empty-state__subsection_title">
              Crece a un plan Corporativo y trabaja sin limitaciones
            </h2>
            <h2 className="ui-empty-state__subsection_subtitle">
              Cotizaciones en línea, importación de pedidos, estados de cuentas
              y descarga de facturas para tu cliente.
            </h2>
            <Button
              primary
              disabled={isLoading}
              loading={isLoading}
              onClick={crecerPlan}
            >
              Crece tu plan
            </Button>
          </div>
        </div>
        <div className="ui-empty-state__subsection ui-empty-state__subsection__background">
          <div className="ui-empty-state__items">
            <div className="ui-empty-state__item">
              <div className="ui-empty-state__subitems">
                <div className="ui-empty-state__subitem">
                  <img alt="¡Ups!" src="/forbidden.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
