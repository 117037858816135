import { useSelector } from "react-redux";
import store from "../../Store";
import Colors from "../../styles/Colors";
import { getAuthData } from "../../reducers";
import { b2bgoApiaxios, b2bgoAuthaxios } from "../../utils/Axios";
import { suscribeForRefreshAuth } from "../../actions/AuthActions";

export default function ApiInitializerContainer(props) {
  const url_imagen = useSelector((state) => state.Auth.url_imagen);
  const colorSchema = useSelector(
    (state) => state.Auth.comercioData.colorSchema
  );
  const grant_type = useSelector((state) => state.Auth.grant_type);
  const secondaryColor = colorSchema?.secondary ? colorSchema?.secondary : null;

  let API_HOST =
    process.env.NODE_ENV === "development"
      ? "http://localhost:port"
      : process.env.REACT_APP_API_HOST;
  b2bgoAuthaxios.interceptors.request.use((request) => {
    let newBaseURL =
      process.env.NODE_ENV === "development"
        ? String(API_HOST).replace("port", request.api_uri)
        : API_HOST.replace("[api]", request.api_uri);
    let tokenData = getAuthData(store.getState());
    let accessToken = tokenData.access_token;
    request.headers["Authorization"] = `Bearer ${accessToken}`;
    request.baseURL = newBaseURL;
    return request;
  });

  b2bgoApiaxios.interceptors.request.use((request) => {
    let newBaseURL =
      process.env.NODE_ENV === "development"
        ? String(API_HOST).replace("port", request.api_uri)
        : API_HOST.replace("[api]", request.api_uri);
    request.baseURL = newBaseURL;
    return request;
  });

  b2bgoAuthaxios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalConfig = error.config;
      const status = error.response ? error.response.status : 0;
      if (status === 401) {
        originalConfig._retry = true;
        setTimeout(async () => {
          await store.dispatch(suscribeForRefreshAuth());
          return b2bgoAuthaxios(error.config);
        }, 500);
      }
      return Promise.reject(error);
    }
  );

  const theme = {
    colors: {
      primary:
        ["CLIENTE", "CONTACT"].includes(grant_type) && secondaryColor
          ? secondaryColor
          : Colors.main,
    },
    logo: {
      // width: 50,
      topBarSource: url_imagen ? url_imagen : "/Logo.png",
    },
  };
  return props.children({ theme });
}
