/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useState } from "react";
import {
  FormLayout,
  Modal,
  Stack,
  TextField,
  Card,
  Button,
  Badge,
} from "@shopify/polaris";

const FeeModal = ({
  active = false,
  currentIndex = 0,
  onClose = () => {},
  handleSaveFee = () => {},
  currentShippingFee = null,
}) => {
  const [feeTitle, setFeeTitle] = useState("");
  const [price, setPrice] = useState("0");
  const [maxPrice, setMaxPrice] = useState("0");
  const [minPrice, setMinPrice] = useState("0");

  useEffect(() => {
    if (currentShippingFee) {
      setFeeTitle(
        currentShippingFee.feeTitle ? String(currentShippingFee?.feeTitle) : ""
      );
      setPrice(String(currentShippingFee?.price || 0));
      setMaxPrice(String(currentShippingFee?.maxPrice || 0));
      setMinPrice(String(currentShippingFee?.minPrice || 0));
    }
  }, [currentShippingFee, setPrice, setMaxPrice, setMinPrice]);

  const handleSave = useCallback(
    (toDelete) => {
      const shippingFee = {
        feeTitle,
        price: Number(price),
        maxPrice: Number(maxPrice),
        minPrice: Number(minPrice),
      };
      if (currentIndex >= 0 && !toDelete) {
        handleSaveFee(shippingFee, currentIndex);
      } else if (toDelete) {
        handleSaveFee(null, currentIndex, true);
      } else {
        handleSaveFee(shippingFee);
      }
    },
    [price, maxPrice, minPrice, feeTitle, currentIndex, handleSaveFee]
  );

  const onChange = (value, setState) => {
    setState(value);
  };

  const handleOnBlur = (value, setState) => {
    if (!value) {
      setState("0");
    }
  };

  return (
    <Modal
      open={active}
      onClose={onClose}
      title={currentIndex !== null ? "Editar tarifa" : "Agregar tarifa"}
      secondaryActions={[
        {
          content: "Descartar",
          onAction: onClose,
        },
      ]}
      primaryAction={{
        content: "Guardar",
        onAction: () => handleSave(false),
        disabled: !feeTitle,
      }}
      footer={
        currentIndex !== null && (
          <Button outline destructive onClick={() => handleSave(true)}>
            Eliminar
          </Button>
        )
      }
    >
      <Card sectioned>
        <FormLayout>
          <Stack distribution="fill" spacing="loose">
            <TextField
              id="key"
              label="Nombre de la tarifa"
              type="text"
              placeholder="Express, General, etc."
              value={feeTitle}
              onChange={(value) => onChange(value, setFeeTitle)}
            />
          </Stack>
          <Stack distribution="fill" spacing="loose">
            <TextField
              id="key"
              label="Precio de envío"
              type="number"
              prefix="$"
              placeholder="0.00"
              min={0}
              value={price}
              onChange={(value) => onChange(value, setPrice)}
              onBlur={() => handleOnBlur(price, setPrice)}
              suffix={(Number(price) === 0 || !price) && <Badge>Gratis</Badge>}
            />
          </Stack>
          <Stack distribution="fill" wrap={false} spacing="loose">
            <TextField
              type="number"
              label="Precio mínimo"
              prefix="$"
              placeholder="0.00"
              min={0}
              value={minPrice}
              onChange={(value) => onChange(value, setMinPrice)}
              onBlur={() => handleOnBlur(minPrice, setMinPrice)}
            />
            <TextField
              type="number"
              label="Precio máximo"
              placeholder="Sin límite"
              prefix="$"
              min={0}
              value={maxPrice}
              onChange={(value) => onChange(value, setMaxPrice)}
              onBlur={() => handleOnBlur(maxPrice, setMaxPrice)}
              suffix={
                (Number(maxPrice) === 0 || !maxPrice) && (
                  <Badge>Sin límite</Badge>
                )
              }
            />
          </Stack>
        </FormLayout>
      </Card>
    </Modal>
  );
};

export default FeeModal;
