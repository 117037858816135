import {
  Button,
  Heading,
  Icon,
  TextContainer,
  TextStyle,
  TopBar,
} from "@shopify/polaris";
import { CancelSmallMinor, CirclePlusMajor } from "@shopify/polaris-icons";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toggleToast } from "../../actions/InteractiveActions";
import useToggle from "../../hooks/useToggle";
import ApiServiceConfig from "../../services/ApiServiceConfig";
import AddSuggestionModal from "./addSuggestionModal";

export const SuggestionMenuMarkup = ({
  isSuggestionMenuOpen,
  toggleIsSuggestionMenuOpen,
}) => (
  <TopBar.Menu
    activatorContent={
      <div className="relative flex">
        <div className="notification-icon">
          <Icon color="subdued" source={CirclePlusMajor} />
        </div>
      </div>
    }
    open={isSuggestionMenuOpen}
    onOpen={toggleIsSuggestionMenuOpen}
  />
);

export default function NavBarSuggestion({
  isSuggestionMenuOpen,
  toggleIsSuggestionMenuOpen,
}) {
  const dispatch = useDispatch();
  const [activeModal, toggleActiveModal] = useToggle(false);
  const [loading, setLoading] = useState(false);

  const handleOpenModal = () => {
    toggleActiveModal();
    toggleIsSuggestionMenuOpen();
  };

  const handleSave = (suggestion) => {
    setLoading(true);
    let data = {
      isSuggestion: true,
      reference: suggestion.reference,
      description: suggestion.description,
    };
    ApiServiceConfig.createSuggestion(data)
      .then(({ ok, message }) => {
        if (ok) {
          dispatch(toggleToast({ message }));
          toggleIsSuggestionMenuOpen();
        }
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      {activeModal && (
        <AddSuggestionModal
          isActive={activeModal}
          onClose={handleOpenModal}
          onSave={handleSave}
          loading={loading}
        />
      )}
      {isSuggestionMenuOpen && (
        <div>
          <div className="overlay-notif" onClick={toggleIsSuggestionMenuOpen} />
          <div className="modal">
            <div className="container-notif">
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row width-90">
                  <div className="notif-icon items-center flex rounded-full bg-gray-200 p-2 ">
                    <Icon color="subdued" source={CirclePlusMajor} />
                  </div>
                  <div className="flex pl-4	">
                    <Heading variation="subdued">Sugerencia de mejora</Heading>
                  </div>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => toggleIsSuggestionMenuOpen()}
                >
                  <Icon source={CancelSmallMinor} color="SkyDark" backdrop />
                </div>
              </div>
              <div className="py-4">
                <TextContainer spacing="tight">
                  <TextStyle variation="subdued">
                    Envía tu sugerencia de mejora para los sistemas B2BGo.{" "}
                    <br />
                    <div className="mt-4">
                      <Button plain onClick={handleOpenModal}>
                        Enviar sugerencia
                      </Button>
                    </div>
                  </TextStyle>
                </TextContainer>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
