export const countryCodes = [
    {
      "COUNTRY": "Afghanistan",
      "COUNTRY CODE": "93",
      "ISO CODES": "AF / AFG",
    },
    {
      "COUNTRY": "Albania",
      "COUNTRY CODE": "355",
      "ISO CODES": "AL / ALB",
    },
    {
      "COUNTRY": "Algeria",
      "COUNTRY CODE": "213",
      "ISO CODES": "DZ / DZA",
    },
    {
      "COUNTRY": "American Samoa",
      "COUNTRY CODE": "1-684",
      "ISO CODES": "AS / ASM",
    },
    {
      "COUNTRY": "Andorra",
      "COUNTRY CODE": "376",
      "ISO CODES": "AD / AND",
    },
    {
      "COUNTRY": "Angola",
      "COUNTRY CODE": "244",
      "ISO CODES": "AO / AGO",
    },
    {
      "COUNTRY": "Anguilla",
      "COUNTRY CODE": "1-264",
      "ISO CODES": "AI / AIA",
    },
    {
      "COUNTRY": "Antarctica",
      "COUNTRY CODE": "672",
      "ISO CODES": "AQ / ATA",
    },
    {
      "COUNTRY": "Antigua and Barbuda",
      "COUNTRY CODE": "1-268",
      "ISO CODES": "AG / ATG",
    },
    {
      "COUNTRY": "Argentina",
      "COUNTRY CODE": "54",
      "ISO CODES": "AR / ARG",
    },
    {
      "COUNTRY": "Armenia",
      "COUNTRY CODE": "374",
      "ISO CODES": "AM / ARM",
    },
    {
      "COUNTRY": "Aruba",
      "COUNTRY CODE": "297",
      "ISO CODES": "AW / ABW",
    },
    {
      "COUNTRY": "Australia",
      "COUNTRY CODE": "61",
      "ISO CODES": "AU / AUS",
    },
    {
      "COUNTRY": "Austria",
      "COUNTRY CODE": "43",
      "ISO CODES": "AT / AUT",
    },
    {
      "COUNTRY": "Azerbaijan",
      "COUNTRY CODE": "994",
      "ISO CODES": "AZ / AZE",
    },
    {
      "COUNTRY": "Bahamas",
      "COUNTRY CODE": "1-242",
      "ISO CODES": "BS / BHS",
    },
    {
      "COUNTRY": "Bahrain",
      "COUNTRY CODE": "973",
      "ISO CODES": "BH / BHR",
    },
    {
      "COUNTRY": "Bangladesh",
      "COUNTRY CODE": "880",
      "ISO CODES": "BD / BGD",
    },
    {
      "COUNTRY": "Barbados",
      "COUNTRY CODE": "1-246",
      "ISO CODES": "BB / BRB",
    },
    {
      "COUNTRY": "Belarus",
      "COUNTRY CODE": "375",
      "ISO CODES": "BY / BLR",
    },
    {
      "COUNTRY": "Belgium",
      "COUNTRY CODE": "32",
      "ISO CODES": "BE / BEL",
    },
    {
      "COUNTRY": "Belize",
      "COUNTRY CODE": "501",
      "ISO CODES": "BZ / BLZ",
    },
    {
      "COUNTRY": "Benin",
      "COUNTRY CODE": "229",
      "ISO CODES": "BJ / BEN",
    },
    {
      "COUNTRY": "Bermuda",
      "COUNTRY CODE": "1-441",
      "ISO CODES": "BM / BMU",
    },
    {
      "COUNTRY": "Bhutan",
      "COUNTRY CODE": "975",
      "ISO CODES": "BT / BTN",
    },
    {
      "COUNTRY": "Bolivia",
      "COUNTRY CODE": "591",
      "ISO CODES": "BO / BOL",
    },
    {
      "COUNTRY": "Bosnia and Herzegovina",
      "COUNTRY CODE": "387",
      "ISO CODES": "BA / BIH",
    },
    {
      "COUNTRY": "Botswana",
      "COUNTRY CODE": "267",
      "ISO CODES": "BW / BWA",
    },
    {
      "COUNTRY": "Brazil",
      "COUNTRY CODE": "55",
      "ISO CODES": "BR / BRA",
    },
    {
      "COUNTRY": "British Indian Ocean Territory",
      "COUNTRY CODE": "246",
      "ISO CODES": "IO / IOT",
    },
    {
      "COUNTRY": "British Virgin Islands",
      "COUNTRY CODE": "1-284",
      "ISO CODES": "VG / VGB",
    },
    {
      "COUNTRY": "Brunei",
      "COUNTRY CODE": "673",
      "ISO CODES": "BN / BRN",
    },
    {
      "COUNTRY": "Bulgaria",
      "COUNTRY CODE": "359",
      "ISO CODES": "BG / BGR",
    },
    {
      "COUNTRY": "Burkina Faso",
      "COUNTRY CODE": "226",
      "ISO CODES": "BF / BFA",
    },
    {
      "COUNTRY": "Burundi",
      "COUNTRY CODE": "257",
      "ISO CODES": "BI / BDI",
    },
    {
      "COUNTRY": "Cambodia",
      "COUNTRY CODE": "855",
      "ISO CODES": "KH / KHM",
    },
    {
      "COUNTRY": "Cameroon",
      "COUNTRY CODE": "237",
      "ISO CODES": "CM / CMR",
    },
    {
      "COUNTRY": "Canada",
      "COUNTRY CODE": "1",
      "ISO CODES": "CA / CAN",
    },
    {
      "COUNTRY": "Cape Verde",
      "COUNTRY CODE": "238",
      "ISO CODES": "CV / CPV",
    },
    {
      "COUNTRY": "Cayman Islands",
      "COUNTRY CODE": "1-345",
      "ISO CODES": "KY / CYM",
    },
    {
      "COUNTRY": "Central African Republic",
      "COUNTRY CODE": "236",
      "ISO CODES": "CF / CAF",
    },
    {
      "COUNTRY": "Chad",
      "COUNTRY CODE": "235",
      "ISO CODES": "TD / TCD",
    },
    {
      "COUNTRY": "Chile",
      "COUNTRY CODE": "56",
      "ISO CODES": "CL / CHL",
    },
    {
      "COUNTRY": "China",
      "COUNTRY CODE": "86",
      "ISO CODES": "CN / CHN",
    },
    {
      "COUNTRY": "Christmas Island",
      "COUNTRY CODE": "61",
      "ISO CODES": "CX / CXR",
    },
    {
      "COUNTRY": "Cocos Islands",
      "COUNTRY CODE": "61",
      "ISO CODES": "CC / CCK",
    },
    {
      "COUNTRY": "Colombia",
      "COUNTRY CODE": "57",
      "ISO CODES": "CO / COL",
    },
    {
      "COUNTRY": "Comoros",
      "COUNTRY CODE": "269",
      "ISO CODES": "KM / COM",
    },
    {
      "COUNTRY": "Cook Islands",
      "COUNTRY CODE": "682",
      "ISO CODES": "CK / COK",
    },
    {
      "COUNTRY": "Costa Rica",
      "COUNTRY CODE": "506",
      "ISO CODES": "CR / CRI",
    },
    {
      "COUNTRY": "Croatia",
      "COUNTRY CODE": "385",
      "ISO CODES": "HR / HRV",
    },
    {
      "COUNTRY": "Cuba",
      "COUNTRY CODE": "53",
      "ISO CODES": "CU / CUB",
    },
    {
      "COUNTRY": "Curacao",
      "COUNTRY CODE": "599",
      "ISO CODES": "CW / CUW",
    },
    {
      "COUNTRY": "Cyprus",
      "COUNTRY CODE": "357",
      "ISO CODES": "CY / CYP",
    },
    {
      "COUNTRY": "Czech Republic",
      "COUNTRY CODE": "420",
      "ISO CODES": "CZ / CZE",
    },
    {
      "COUNTRY": "Democratic Republic of the Congo",
      "COUNTRY CODE": "243",
      "ISO CODES": "CD / COD",
    },
    {
      "COUNTRY": "Denmark",
      "COUNTRY CODE": "45",
      "ISO CODES": "DK / DNK",
    },
    {
      "COUNTRY": "Djibouti",
      "COUNTRY CODE": "253",
      "ISO CODES": "DJ / DJI",
    },
    {
      "COUNTRY": "Dominica",
      "COUNTRY CODE": "1-767",
      "ISO CODES": "DM / DMA",
    },
    {
      "COUNTRY": "Dominican Republic",
      "COUNTRY CODE": "1-809",
      "ISO CODES": "DO / DOM",
    },
    {
      "COUNTRY": "East Timor",
      "COUNTRY CODE": "670",
      "ISO CODES": "TL / TLS",
    },
    {
      "COUNTRY": "Ecuador",
      "COUNTRY CODE": "593",
      "ISO CODES": "EC / ECU",
    },
    {
      "COUNTRY": "Egypt",
      "COUNTRY CODE": "20",
      "ISO CODES": "EG / EGY",
    },
    {
      "COUNTRY": "El Salvador",
      "COUNTRY CODE": "503",
      "ISO CODES": "SV / SLV",
    },
    {
      "COUNTRY": "Equatorial Guinea",
      "COUNTRY CODE": "240",
      "ISO CODES": "GQ / GNQ",
    },
    {
      "COUNTRY": "Eritrea",
      "COUNTRY CODE": "291",
      "ISO CODES": "ER / ERI",
    },
    {
      "COUNTRY": "Estonia",
      "COUNTRY CODE": "372",
      "ISO CODES": "EE / EST",
    },
    {
      "COUNTRY": "Ethiopia",
      "COUNTRY CODE": "251",
      "ISO CODES": "ET / ETH",
    },
    {
      "COUNTRY": "Falkland Islands",
      "COUNTRY CODE": "500",
      "ISO CODES": "FK / FLK",
    },
    {
      "COUNTRY": "Faroe Islands",
      "COUNTRY CODE": "298",
      "ISO CODES": "FO / FRO",
    },
    {
      "COUNTRY": "Fiji",
      "COUNTRY CODE": "679",
      "ISO CODES": "FJ / FJI",
    },
    {
      "COUNTRY": "Finland",
      "COUNTRY CODE": "358",
      "ISO CODES": "FI / FIN",
    },
    {
      "COUNTRY": "France",
      "COUNTRY CODE": "33",
      "ISO CODES": "FR / FRA",
    },
    {
      "COUNTRY": "French Polynesia",
      "COUNTRY CODE": "689",
      "ISO CODES": "PF / PYF",
    },
    {
      "COUNTRY": "Gabon",
      "COUNTRY CODE": "241",
      "ISO CODES": "GA / GAB",
    },
    {
      "COUNTRY": "Gambia",
      "COUNTRY CODE": "220",
      "ISO CODES": "GM / GMB",
    },
    {
      "COUNTRY": "Georgia",
      "COUNTRY CODE": "995",
      "ISO CODES": "GE / GEO",
    },
    {
      "COUNTRY": "Germany",
      "COUNTRY CODE": "49",
      "ISO CODES": "DE / DEU",
    },
    {
      "COUNTRY": "Ghana",
      "COUNTRY CODE": "233",
      "ISO CODES": "GH / GHA",
    },
    {
      "COUNTRY": "Gibraltar",
      "COUNTRY CODE": "350",
      "ISO CODES": "GI / GIB",
    },
    {
      "COUNTRY": "Greece",
      "COUNTRY CODE": "30",
      "ISO CODES": "GR / GRC",
    },
    {
      "COUNTRY": "Greenland",
      "COUNTRY CODE": "299",
      "ISO CODES": "GL / GRL",
    },
    {
      "COUNTRY": "Grenada",
      "COUNTRY CODE": "1-473",
      "ISO CODES": "GD / GRD",
    },
    {
      "COUNTRY": "Guam",
      "COUNTRY CODE": "1-671",
      "ISO CODES": "GU / GUM",
    },
    {
      "COUNTRY": "Guatemala",
      "COUNTRY CODE": "502",
      "ISO CODES": "GT / GTM",
    },
    {
      "COUNTRY": "Guernsey",
      "COUNTRY CODE": "44-1481",
      "ISO CODES": "GG / GGY",
    },
    {
      "COUNTRY": "Guinea",
      "COUNTRY CODE": "224",
      "ISO CODES": "GN / GIN",
    },
    {
      "COUNTRY": "Guinea-Bissau",
      "COUNTRY CODE": "245",
      "ISO CODES": "GW / GNB",
    },
    {
      "COUNTRY": "Guyana",
      "COUNTRY CODE": "592",
      "ISO CODES": "GY / GUY",
    },
    {
      "COUNTRY": "Haiti",
      "COUNTRY CODE": "509",
      "ISO CODES": "HT / HTI",
    },
    {
      "COUNTRY": "Honduras",
      "COUNTRY CODE": "504",
      "ISO CODES": "HN / HND",
    },
    {
      "COUNTRY": "Hong Kong",
      "COUNTRY CODE": "852",
      "ISO CODES": "HK / HKG",
    },
    {
      "COUNTRY": "Hungary",
      "COUNTRY CODE": "36",
      "ISO CODES": "HU / HUN",
    },
    {
      "COUNTRY": "Iceland",
      "COUNTRY CODE": "354",
      "ISO CODES": "IS / ISL",
    },
    {
      "COUNTRY": "India",
      "COUNTRY CODE": "91",
      "ISO CODES": "IN / IND",
    },
    {
      "COUNTRY": "Indonesia",
      "COUNTRY CODE": "62",
      "ISO CODES": "ID / IDN",
    },
    {
      "COUNTRY": "Iran",
      "COUNTRY CODE": "98",
      "ISO CODES": "IR / IRN",
    },
    {
      "COUNTRY": "Iraq",
      "COUNTRY CODE": "964",
      "ISO CODES": "IQ / IRQ",
    },
    {
      "COUNTRY": "Ireland",
      "COUNTRY CODE": "353",
      "ISO CODES": "IE / IRL",
    },
    {
      "COUNTRY": "Isle of Man",
      "COUNTRY CODE": "44-1624",
      "ISO CODES": "IM / IMN",
    },
    {
      "COUNTRY": "Israel",
      "COUNTRY CODE": "972",
      "ISO CODES": "IL / ISR",
    },
    {
      "COUNTRY": "Italy",
      "COUNTRY CODE": "39",
      "ISO CODES": "IT / ITA",
    },
    {
      "COUNTRY": "Ivory Coast",
      "COUNTRY CODE": "225",
      "ISO CODES": "CI / CIV",
    },
    {
      "COUNTRY": "Jamaica",
      "COUNTRY CODE": "1-876",
      "ISO CODES": "JM / JAM",
    },
    {
      "COUNTRY": "Japan",
      "COUNTRY CODE": "81",
      "ISO CODES": "JP / JPN",
    },
    {
      "COUNTRY": "Jersey",
      "COUNTRY CODE": "44-1534",
      "ISO CODES": "JE / JEY",
    },
    {
      "COUNTRY": "Jordan",
      "COUNTRY CODE": "962",
      "ISO CODES": "JO / JOR",
    },
    {
      "COUNTRY": "Kazakhstan",
      "COUNTRY CODE": "7",
      "ISO CODES": "KZ / KAZ",
    },
    {
      "COUNTRY": "Kenya",
      "COUNTRY CODE": "254",
      "ISO CODES": "KE / KEN",
    },
    {
      "COUNTRY": "Kiribati",
      "COUNTRY CODE": "686",
      "ISO CODES": "KI / KIR",
    },
    {
      "COUNTRY": "Kosovo",
      "COUNTRY CODE": "383",
      "ISO CODES": "XK / XKX",
    },
    {
      "COUNTRY": "Kuwait",
      "COUNTRY CODE": "965",
      "ISO CODES": "KW / KWT",
    },
    {
      "COUNTRY": "Kyrgyzstan",
      "COUNTRY CODE": "996",
      "ISO CODES": "KG / KGZ",
    },
    {
      "COUNTRY": "Laos",
      "COUNTRY CODE": "856",
      "ISO CODES": "LA / LAO",
    },
    {
      "COUNTRY": "Latvia",
      "COUNTRY CODE": "371",
      "ISO CODES": "LV / LVA",
    },
    {
      "COUNTRY": "Lebanon",
      "COUNTRY CODE": "961",
      "ISO CODES": "LB / LBN",
    },
    {
      "COUNTRY": "Lesotho",
      "COUNTRY CODE": "266",
      "ISO CODES": "LS / LSO",
    },
    {
      "COUNTRY": "Liberia",
      "COUNTRY CODE": "231",
      "ISO CODES": "LR / LBR",
    },
    {
      "COUNTRY": "Libya",
      "COUNTRY CODE": "218",
      "ISO CODES": "LY / LBY",
    },
    {
      "COUNTRY": "Liechtenstein",
      "COUNTRY CODE": "423",
      "ISO CODES": "LI / LIE",
    },
    {
      "COUNTRY": "Lithuania",
      "COUNTRY CODE": "370",
      "ISO CODES": "LT / LTU",
    },
    {
      "COUNTRY": "Luxembourg",
      "COUNTRY CODE": "352",
      "ISO CODES": "LU / LUX",
    },
    {
      "COUNTRY": "Macau",
      "COUNTRY CODE": "853",
      "ISO CODES": "MO / MAC",
    },
    {
      "COUNTRY": "Macedonia",
      "COUNTRY CODE": "389",
      "ISO CODES": "MK / MKD",
    },
    {
      "COUNTRY": "Madagascar",
      "COUNTRY CODE": "261",
      "ISO CODES": "MG / MDG",
    },
    {
      "COUNTRY": "Malawi",
      "COUNTRY CODE": "265",
      "ISO CODES": "MW / MWI",
    },
    {
      "COUNTRY": "Malaysia",
      "COUNTRY CODE": "60",
      "ISO CODES": "MY / MYS",
    },
    {
      "COUNTRY": "Maldives",
      "COUNTRY CODE": "960",
      "ISO CODES": "MV / MDV",
    },
    {
      "COUNTRY": "Mali",
      "COUNTRY CODE": "223",
      "ISO CODES": "ML / MLI",
    },
    {
      "COUNTRY": "Malta",
      "COUNTRY CODE": "356",
      "ISO CODES": "MT / MLT",
    },
    {
      "COUNTRY": "Marshall Islands",
      "COUNTRY CODE": "692",
      "ISO CODES": "MH / MHL",
    },
    {
      "COUNTRY": "Mauritania",
      "COUNTRY CODE": "222",
      "ISO CODES": "MR / MRT",
    },
    {
      "COUNTRY": "Mauritius",
      "COUNTRY CODE": "230",
      "ISO CODES": "MU / MUS",
    },
    {
      "COUNTRY": "Mayotte",
      "COUNTRY CODE": "262",
      "ISO CODES": "YT / MYT",
    },
    {
      "COUNTRY": "México",
      "COUNTRY CODE": "52",
      "ISO CODES": "MX / MEX",
    },
    {
      "COUNTRY": "Micronesia",
      "COUNTRY CODE": "691",
      "ISO CODES": "FM / FSM",
    },
    {
      "COUNTRY": "Moldova",
      "COUNTRY CODE": "373",
      "ISO CODES": "MD / MDA",
    },
    {
      "COUNTRY": "Monaco",
      "COUNTRY CODE": "377",
      "ISO CODES": "MC / MCO",
    },
    {
      "COUNTRY": "Mongolia",
      "COUNTRY CODE": "976",
      "ISO CODES": "MN / MNG",
    },
    {
      "COUNTRY": "Montenegro",
      "COUNTRY CODE": "382",
      "ISO CODES": "ME / MNE",
    },
    {
      "COUNTRY": "Montserrat",
      "COUNTRY CODE": "1-664",
      "ISO CODES": "MS / MSR",
    },
    {
      "COUNTRY": "Morocco",
      "COUNTRY CODE": "212",
      "ISO CODES": "MA / MAR",
    },
    {
      "COUNTRY": "Mozambique",
      "COUNTRY CODE": "258",
      "ISO CODES": "MZ / MOZ",
    },
    {
      "COUNTRY": "Myanmar",
      "COUNTRY CODE": "95",
      "ISO CODES": "MM / MMR",
    },
    {
      "COUNTRY": "Namibia",
      "COUNTRY CODE": "264",
      "ISO CODES": "NA / NAM",
    },
    {
      "COUNTRY": "Nauru",
      "COUNTRY CODE": "674",
      "ISO CODES": "NR / NRU",
    },
    {
      "COUNTRY": "Nepal",
      "COUNTRY CODE": "977",
      "ISO CODES": "NP / NPL",
    },
    {
      "COUNTRY": "Netherlands",
      "COUNTRY CODE": "31",
      "ISO CODES": "NL / NLD",
    },
    {
      "COUNTRY": "New Caledonia",
      "COUNTRY CODE": "687",
      "ISO CODES": "NC / NCL",
    },
    {
      "COUNTRY": "New Zealand",
      "COUNTRY CODE": "64",
      "ISO CODES": "NZ / NZL",
    },
    {
      "COUNTRY": "Nicaragua",
      "COUNTRY CODE": "505",
      "ISO CODES": "NI / NIC",
    },
    {
      "COUNTRY": "Niger",
      "COUNTRY CODE": "227",
      "ISO CODES": "NE / NER",
    },
    {
      "COUNTRY": "Nigeria",
      "COUNTRY CODE": "234",
      "ISO CODES": "NG / NGA",
    },
    {
      "COUNTRY": "Niue",
      "COUNTRY CODE": "683",
      "ISO CODES": "NU / NIU",
    },
    {
      "COUNTRY": "North Korea",
      "COUNTRY CODE": "850",
      "ISO CODES": "KP / PRK",
    },
    {
      "COUNTRY": "Northern Mariana Islands",
      "COUNTRY CODE": "1-670",
      "ISO CODES": "MP / MNP",
    },
    {
      "COUNTRY": "Norway",
      "COUNTRY CODE": "47",
      "ISO CODES": "NO / NOR",
    },
    {
      "COUNTRY": "Oman",
      "COUNTRY CODE": "968",
      "ISO CODES": "OM / OMN",
    },
    {
      "COUNTRY": "Pakistan",
      "COUNTRY CODE": "92",
      "ISO CODES": "PK / PAK",
    },
    {
      "COUNTRY": "Palau",
      "COUNTRY CODE": "680",
      "ISO CODES": "PW / PLW",
    },
    {
      "COUNTRY": "Palestine",
      "COUNTRY CODE": "970",
      "ISO CODES": "PS / PSE",
    },
    {
      "COUNTRY": "Panama",
      "COUNTRY CODE": "507",
      "ISO CODES": "PA / PAN",
    },
    {
      "COUNTRY": "Papua New Guinea",
      "COUNTRY CODE": "675",
      "ISO CODES": "PG / PNG",
    },
    {
      "COUNTRY": "Paraguay",
      "COUNTRY CODE": "595",
      "ISO CODES": "PY / PRY",
    },
    {
      "COUNTRY": "Peru",
      "COUNTRY CODE": "51",
      "ISO CODES": "PE / PER",
    },
    {
      "COUNTRY": "Philippines",
      "COUNTRY CODE": "63",
      "ISO CODES": "PH / PHL",
    },
    {
      "COUNTRY": "Pitcairn",
      "COUNTRY CODE": "64",
      "ISO CODES": "PN / PCN",
    },
    {
      "COUNTRY": "Poland",
      "COUNTRY CODE": "48",
      "ISO CODES": "PL / POL",
    },
    {
      "COUNTRY": "Portugal",
      "COUNTRY CODE": "351",
      "ISO CODES": "PT / PRT",
    },
    {
      "COUNTRY": "Puerto Rico",
      "COUNTRY CODE": "1-787",
      "ISO CODES": "PR / PRI",
    },
    {
      "COUNTRY": "Qatar",
      "COUNTRY CODE": "974",
      "ISO CODES": "QA / QAT",
    },
    {
      "COUNTRY": "Republic of the Congo",
      "COUNTRY CODE": "242",
      "ISO CODES": "CG / COG",
    },
    {
      "COUNTRY": "Reunion",
      "COUNTRY CODE": "262",
      "ISO CODES": "RE / REU",
    },
    {
      "COUNTRY": "Romania",
      "COUNTRY CODE": "40",
      "ISO CODES": "RO / ROU",
    },
    {
      "COUNTRY": "Russia",
      "COUNTRY CODE": "7",
      "ISO CODES": "RU / RUS",
    },
    {
      "COUNTRY": "Rwanda",
      "COUNTRY CODE": "250",
      "ISO CODES": "RW / RWA",
    },
    {
      "COUNTRY": "Saint Barthelemy",
      "COUNTRY CODE": "590",
      "ISO CODES": "BL / BLM",
    },
    {
      "COUNTRY": "Saint Helena",
      "COUNTRY CODE": "290",
      "ISO CODES": "SH / SHN",
    },
    {
      "COUNTRY": "Saint Kitts and Nevis",
      "COUNTRY CODE": "1-869",
      "ISO CODES": "KN / KNA",
    },
    {
      "COUNTRY": "Saint Lucia",
      "COUNTRY CODE": "1-758",
      "ISO CODES": "LC / LCA",
    },
    {
      "COUNTRY": "Saint Martin",
      "COUNTRY CODE": "590",
      "ISO CODES": "MF / MAF",
    },
    {
      "COUNTRY": "Saint Pierre and Miquelon",
      "COUNTRY CODE": "508",
      "ISO CODES": "PM / SPM",
    },
    {
      "COUNTRY": "Saint Vincent and the Grenadines",
      "COUNTRY CODE": "1-784",
      "ISO CODES": "VC / VCT",
    },
    {
      "COUNTRY": "Samoa",
      "COUNTRY CODE": "685",
      "ISO CODES": "WS / WSM",
    },
    {
      "COUNTRY": "San Marino",
      "COUNTRY CODE": "378",
      "ISO CODES": "SM / SMR",
    },
    {
      "COUNTRY": "Sao Tome and Principe",
      "COUNTRY CODE": "239",
      "ISO CODES": "ST / STP",
    },
    {
      "COUNTRY": "Saudi Arabia",
      "COUNTRY CODE": "966",
      "ISO CODES": "SA / SAU",
    },
    {
      "COUNTRY": "Senegal",
      "COUNTRY CODE": "221",
      "ISO CODES": "SN / SEN",
    },
    {
      "COUNTRY": "Serbia",
      "COUNTRY CODE": "381",
      "ISO CODES": "RS / SRB",
    },
    {
      "COUNTRY": "Seychelles",
      "COUNTRY CODE": "248",
      "ISO CODES": "SC / SYC",
    },
    {
      "COUNTRY": "Sierra Leone",
      "COUNTRY CODE": "232",
      "ISO CODES": "SL / SLE",
    },
    {
      "COUNTRY": "Singapore",
      "COUNTRY CODE": "65",
      "ISO CODES": "SG / SGP",
    },
    {
      "COUNTRY": "Sint Maarten",
      "COUNTRY CODE": "1-721",
      "ISO CODES": "SX / SXM",
    },
    {
      "COUNTRY": "Slovakia",
      "COUNTRY CODE": "421",
      "ISO CODES": "SK / SVK",
    },
    {
      "COUNTRY": "Slovenia",
      "COUNTRY CODE": "386",
      "ISO CODES": "SI / SVN",
    },
    {
      "COUNTRY": "Solomon Islands",
      "COUNTRY CODE": "677",
      "ISO CODES": "SB / SLB",
    },
    {
      "COUNTRY": "Somalia",
      "COUNTRY CODE": "252",
      "ISO CODES": "SO / SOM",
    },
    {
      "COUNTRY": "South Africa",
      "COUNTRY CODE": "27",
      "ISO CODES": "ZA / ZAF",
    },
    {
      "COUNTRY": "South Korea",
      "COUNTRY CODE": "82",
      "ISO CODES": "KR / KOR",
    },
    {
      "COUNTRY": "South Sudan",
      "COUNTRY CODE": "211",
      "ISO CODES": "SS / SSD",
    },
    {
      "COUNTRY": "Spain",
      "COUNTRY CODE": "34",
      "ISO CODES": "ES / ESP",
    },
    {
      "COUNTRY": "Sri Lanka",
      "COUNTRY CODE": "94",
      "ISO CODES": "LK / LKA",
    },
    {
      "COUNTRY": "Sudan",
      "COUNTRY CODE": "249",
      "ISO CODES": "SD / SDN",
    },
    {
      "COUNTRY": "Suriname",
      "COUNTRY CODE": "597",
      "ISO CODES": "SR / SUR",
    },
    {
      "COUNTRY": "Svalbard and Jan Mayen",
      "COUNTRY CODE": "47",
      "ISO CODES": "SJ / SJM",
    },
    {
      "COUNTRY": "Swaziland",
      "COUNTRY CODE": "268",
      "ISO CODES": "SZ / SWZ",
    },
    {
      "COUNTRY": "Sweden",
      "COUNTRY CODE": "46",
      "ISO CODES": "SE / SWE",
    },
    {
      "COUNTRY": "Switzerland",
      "COUNTRY CODE": "41",
      "ISO CODES": "CH / CHE",
    },
    {
      "COUNTRY": "Syria",
      "COUNTRY CODE": "963",
      "ISO CODES": "SY / SYR",
    },
    {
      "COUNTRY": "Taiwan",
      "COUNTRY CODE": "886",
      "ISO CODES": "TW / TWN",
    },
    {
      "COUNTRY": "Tajikistan",
      "COUNTRY CODE": "992",
      "ISO CODES": "TJ / TJK",
    },
    {
      "COUNTRY": "Tanzania",
      "COUNTRY CODE": "255",
      "ISO CODES": "TZ / TZA",
    },
    {
      "COUNTRY": "Thailand",
      "COUNTRY CODE": "66",
      "ISO CODES": "TH / THA",
    },
    {
      "COUNTRY": "Togo",
      "COUNTRY CODE": "228",
      "ISO CODES": "TG / TGO",
    },
    {
      "COUNTRY": "Tokelau",
      "COUNTRY CODE": "690",
      "ISO CODES": "TK / TKL",
    },
    {
      "COUNTRY": "Tonga",
      "COUNTRY CODE": "676",
      "ISO CODES": "TO / TON",
    },
    {
      "COUNTRY": "Trinidad and Tobago",
      "COUNTRY CODE": "1-868",
      "ISO CODES": "TT / TTO",
    },
    {
      "COUNTRY": "Tunisia",
      "COUNTRY CODE": "216",
      "ISO CODES": "TN / TUN",
    },
    {
      "COUNTRY": "Turkey",
      "COUNTRY CODE": "90",
      "ISO CODES": "TR / TUR",
    },
    {
      "COUNTRY": "Turkmenistan",
      "COUNTRY CODE": "993",
      "ISO CODES": "TM / TKM",
    },
    {
      "COUNTRY": "Turks and Caicos Islands",
      "COUNTRY CODE": "1-649",
      "ISO CODES": "TC / TCA",
    },
    {
      "COUNTRY": "Tuvalu",
      "COUNTRY CODE": "688",
      "ISO CODES": "TV / TUV",
    },
    {
      "COUNTRY": "U.S. Virgin Islands",
      "COUNTRY CODE": "1-340",
      "ISO CODES": "VI / VIR",
    },
    {
      "COUNTRY": "Uganda",
      "COUNTRY CODE": "256",
      "ISO CODES": "UG / UGA",
    },
    {
      "COUNTRY": "Ukraine",
      "COUNTRY CODE": "380",
      "ISO CODES": "UA / UKR",
    },
    {
      "COUNTRY": "United Arab Emirates",
      "COUNTRY CODE": "971",
      "ISO CODES": "AE / ARE",
    },
    {
      "COUNTRY": "United Kingdom",
      "COUNTRY CODE": "44",
      "ISO CODES": "GB / GBR",
    },
    {
      "COUNTRY": "United States",
      "COUNTRY CODE": "1",
      "ISO CODES": "US / USA",
    },
    {
      "COUNTRY": "Uruguay",
      "COUNTRY CODE": "598",
      "ISO CODES": "UY / URY",
    },
    {
      "COUNTRY": "Uzbekistan",
      "COUNTRY CODE": "998",
      "ISO CODES": "UZ / UZB",
    },
    {
      "COUNTRY": "Vanuatu",
      "COUNTRY CODE": "678",
      "ISO CODES": "VU / VUT",
    },
    {
      "COUNTRY": "Vatican",
      "COUNTRY CODE": "379",
      "ISO CODES": "VA / VAT",
    },
    {
      "COUNTRY": "Venezuela",
      "COUNTRY CODE": "58",
      "ISO CODES": "VE / VEN",
    },
    {
      "COUNTRY": "Vietnam",
      "COUNTRY CODE": "84",
      "ISO CODES": "VN / VNM",
    },
    {
      "COUNTRY": "Wallis and Futuna",
      "COUNTRY CODE": "681",
      "ISO CODES": "WF / WLF",
    },
    {
      "COUNTRY": "Western Sahara",
      "COUNTRY CODE": "212",
      "ISO CODES": "EH / ESH",
    },
    {
      "COUNTRY": "Yemen",
      "COUNTRY CODE": "967",
      "ISO CODES": "YE / YEM",
    },
    {
      "COUNTRY": "Zambia",
      "COUNTRY CODE": "260",
      "ISO CODES": "ZM / ZMB",
    },
    {
      "COUNTRY": "Zimbabwe",
      "COUNTRY CODE": "263",
      "ISO CODES": "ZW / ZWE",
    },
];