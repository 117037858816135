import { Icon } from "@shopify/polaris";
import { ChevronRightMinor, ChevronLeftMinor } from "@shopify/polaris-icons";
import { Pagination } from "antd";
import "./AntPaginationStyles.css";

const locale = {
  prev_page: "Página anterior",
  next_page: "Siguiente página",
  prev_5: "5 páginas anteriores",
  next_5: "Siguientes 5 páginas",
  prev_3: "3 páginas anteriores",
  next_3: "Siguientes 3 páginas",
};

const DirectionIcon = ({ disabled, icon }) => {
  return (
    <div
      style={{ width: 31, height: 31 }}
      className={`flex justify-center items-center rounded-md border ${
        disabled ? "border-gray-300" : "border-gray-500"
      }`}
    >
      <Icon source={icon} color={disabled ? "subdued" : "base"} />
    </div>
  );
};

export default function AntPagination({
  current,
  pageSize,
  onChange,
  total,
  size = "small",
}) {
  return (
    <Pagination
      total={total}
      current={current}
      pageSize={pageSize}
      onChange={onChange}
      showSizeChanger={false}
      locale={locale}
      prevIcon={({ disabled }) => (
        <DirectionIcon disabled={disabled} icon={ChevronLeftMinor} />
      )}
      nextIcon={({ disabled }) => (
        <DirectionIcon disabled={disabled} icon={ChevronRightMinor} />
      )}
      showLessItems
      size={size}
    />
  );
}
