import {
  Button,
  Card,
  Checkbox,
  ChoiceList,
  EmptyState,
  Filters,
  ResourceItem,
  ResourceList,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";
import { Table } from "antd";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { changePage } from "../../actions/ArticulosActions";
import {
  ARTICLE_STATE,
  CONTINUE_SELLING,
  SALES_CHANNELS,
} from "../../utils/constants";
import { NumberFormat } from "../../utils/Formats";
import AntPagination from "../AntPagination";
import CustomPopover from "../CustomPopover";
import SkeletonTable from "../SkeletonTable";
import TagsAutocomplete from "../TagsAutocomplete";
import "./ArticulosList.css";
import { getImageVideoUrl } from "../../utils/productValidators";

export default function ArticulosList({
  pagination,
  articulos,
  isLoading,
  queryValue,
  handleFiltersQueryChange,
  handleQueryValueRemove,
  orden,
  lineas,
  handleOrdenChange,
  orderSelected,
  lineaArticulo,
  handleOrderSelectedChange,
  handleOrderSelectedRemove,
  handleLineaArticuloRemove,
  handleLineaArticuloChange,
  sucursales,
  handleSucursalRemove,
  sucursal,
  handleSucursalChange,
  precios,
  precioLista,
  handlePrecioListaChange,
  handlePrecioListaRemove,
  state,
  handleStateRemove,
  salesChannel,
  handleSalesChannelChange,
  handleSalesChannelRemove,
  taggedWith,
  handleTaggedWithChange,
  handleTaggedWithRemove,
  brand,
  brands,
  handleBrandChange,
  handleBrandRemove,
  continueSelling,
  handleContinueSellingChange,
  handleContinueSellingRemove,
  handleContinueSelling,
  allIds = [],
}) {
  const router = useHistory();
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);
  const [allArticles, setAllArticles] = useState(false);
  const handleFiltersClearAll = useCallback(() => {
    handleSucursalRemove();
    handleLineaArticuloRemove();
    handleQueryValueRemove();
    handlePrecioListaRemove();
    handleOrderSelectedRemove();
    handleStateRemove();
    handleSalesChannelRemove();
    handleTaggedWithRemove();
    handleBrandRemove();
    handleContinueSellingRemove();
  }, [
    handleSucursalRemove,
    handleQueryValueRemove,
    handleLineaArticuloRemove,
    handlePrecioListaRemove,
    handleOrderSelectedRemove,
    handleStateRemove,
    handleSalesChannelRemove,
    handleTaggedWithRemove,
    handleBrandRemove,
    handleContinueSellingRemove,
  ]);
  function handleSelectAll(values = []) {
    setSelectedItems(values);
  }
  function handleSelect(selected, val) {
    if (allArticles) {
      deselectAllArticles();
    }
    if (selected) {
      setSelectedItems((state) => [...new Set(state.concat(val))]);
    } else {
      setSelectedItems(selectedItems.filter((sel) => val !== sel));
    }
  }

  let appliedFilters = [];
  if (!isEmpty(sucursal)) {
    const key = "sucursal";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, sucursal),
      onRemove: handleSucursalRemove,
    });
  }

  if (!isEmpty(lineaArticulo)) {
    const key = "lineaArticulo";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, lineaArticulo),
      onRemove: handleLineaArticuloRemove,
    });
  }

  if (!isEmpty(state)) {
    const key = "state";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, state),
      onRemove: handleStateRemove,
    });
  }

  if (!isEmpty(salesChannel)) {
    const key = "salesChannel";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, salesChannel),
      onRemove: handleSalesChannelRemove,
    });
  }

  if (!isEmpty(brand)) {
    const key = "brand";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, brand),
      onRemove: handleBrandRemove,
    });
  }

  if (!isEmpty(taggedWith)) {
    const key = "taggedWith";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  if (!isEmpty(continueSelling)) {
    const key = "continueSelling";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, continueSelling),
      onRemove: handleContinueSellingRemove,
    });
  }

  function navigate(id) {
    router.push(`/admin/articulos/${id}`, { allIds });
  }

  const emptyStateMarkup =
    !isLoading && articulos.length === 0 ? (
      <EmptyState
        heading="Tus articulos aparecerán aquí"
        image="/empty-articles.png"
      >
        <p>
          Después de que te conectes con Microsip, aquí se mostrarán tus
          artículos.
        </p>
      </EmptyState>
    ) : undefined;

  const filters = [
    {
      key: "lineaArticulo",
      label: "Línea de artículo",
      filter: (
        <ChoiceList
          title="Línea de artículo"
          titleHidden
          choices={lineas}
          selected={lineaArticulo || []}
          onChange={handleLineaArticuloChange}
          allowMultiple
        />
      ),
      shortcut: true,
      disabled: isLoading,
    },
    {
      key: "sucursal",
      label: "Almacén",
      filter: (
        <ChoiceList
          title="Almacén"
          titleHidden
          choices={sucursales}
          selected={sucursal || []}
          onChange={handleSucursalChange}
          allowMultiple
        />
      ),
      shortcut: true,
      disabled: isLoading,
    },
    /*
    {
      key: "state",
      label: "Estado",
      filter: (
        <ChoiceList
          title="Estado"
          titleHidden
          choices={ARTICLE_STATE}
          selected={state || []}
          onChange={handleStateChange}
          allowMultiple
        />
      ),
      shortcut: false,
      disabled: isLoading,
    },*/
    {
      key: "salesChannel",
      label: "Canales de venta",
      filter: (
        <ChoiceList
          title="Canales de venta"
          titleHidden
          choices={SALES_CHANNELS}
          selected={salesChannel || []}
          onChange={handleSalesChannelChange}
        />
      ),
      shortcut: false,
      disabled: isLoading,
    },
    {
      key: "brand",
      label: "Marca",
      filter: (
        <ChoiceList
          title="Marca"
          titleHidden
          choices={brands}
          selected={brand || []}
          onChange={handleBrandChange}
          allowMultiple
        />
      ),
      shortcut: false,
      disabled: isLoading,
    },
    {
      key: "taggedWith",
      label: "Etiquetado con",
      filter: (
        <TagsAutocomplete
          title=""
          selected={taggedWith}
          onSelect={handleTaggedWithChange}
          module="Artículos"
          useCard={false}
          createTags={false}
          listTitle="Etiquetas disponibles"
        />
      ),
      shortcut: false,
      disabled: isLoading,
    },
    {
      key: "continueSelling",
      label: "Continuar vendiendo",
      filter: (
        <ChoiceList
          title="Continuar vendiendo"
          titleHidden
          choices={CONTINUE_SELLING}
          selected={continueSelling || []}
          onChange={handleContinueSellingChange}
          allowMultiple
        />
      ),
      shortcut: false,
      disabled: isLoading,
    },
    {
      key: "precioLista",
      label: "Lista de precios",
      filter: (
        <ChoiceList
          title="Lista de precios"
          titleHidden
          choices={precios}
          selected={precioLista || []}
          onChange={handlePrecioListaChange}
        />
      ),
      shortcut: false,
      disabled: isLoading,
    },
    {
      key: "orderSelected",
      label: "Ordenar",
      filter: (
        <div className="flex flex-col">
          <ChoiceList
            title="Ordenar"
            titleHidden
            choices={[
              { label: "Nombre", value: "nombre" },
              { label: "Clave", value: "clave" },
              { label: "Precio", value: "preciounitario" },
              { label: "Existencia", value: "totalStock" },
              { label: "U. Medida", value: "unidadmed" },
            ]}
            selected={orderSelected || []}
            onChange={handleOrderSelectedChange}
          />
          <div className="flex w-full border border-t-1 border-b-0 border-r-0 border-l-0 border-gray-400">
            <ChoiceList
              title="Ordenar"
              titleHidden
              choices={[
                { label: "Ascendente", value: "1" },
                { label: "Descendente", value: "-1" },
              ]}
              selected={orden}
              onChange={handleOrdenChange}
            />
          </div>
        </div>
      ),
      shortcut: false,
    },
  ];

  const bulkActions = [
    {
      content: "Activar vender cuando esté agotado",
      onAction: () => handleContinueSelling(true, selectedItems, allArticles),
    },
    {
      content: "Desactivar vender cuando esté agotado",
      onAction: () => handleContinueSelling(false, selectedItems, allArticles),
    },
  ];

  const showPromiseConfirm = () => {
    const span = document.getElementsByClassName(
      "Polaris-ResourceList-CheckableButton__Label"
    );
    if (span.length) {
      span[1].innerHTML = "+20 seleccionados";
    }
    handleSelectAll(articulos.map((i) => i._id));
    return setAllArticles(true);
  };

  const deselectAllArticles = () => {
    const span = document.getElementsByClassName(
      "Polaris-ResourceList-CheckableButton__Label"
    );
    if (span.length) {
      span[1].innerHTML = "20 seleccionados";
    }
    return setAllArticles(false);
  };

  return (
    <div>
      <Card>
        <Card.Section>
          <Filters
            queryPlaceholder="Filtrar artículos"
            queryValue={queryValue}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
            filters={filters}
            appliedFilters={appliedFilters}
          />
        </Card.Section>
        {isLoading ? (
          <SkeletonTable hasCheckbox headingsCount={5} rowsCount={5} />
        ) : (
          <div>
            <div className="selectable-items">
              <ResourceList
                totalItemsCount={pagination.total}
                resourceName={{ singular: "artículo", plural: "artículos" }}
                items={articulos}
                selectable
                selectedItems={selectedItems}
                onSelectionChange={handleSelectAll}
                renderItem={() => <ResourceItem />}
                bulkActions={bulkActions}
              />
            </div>
            {selectedItems.length > 0 && (
              <div className="select-all relative sm:absolute	">
                {allArticles ? (
                  <>
                    Seleccionados los +20 artículos.{" "}
                    <Button plain onClick={deselectAllArticles}>
                      Deshacer
                    </Button>
                  </>
                ) : (
                  <Button plain onClick={showPromiseConfirm}>
                    Seleccionar +20 artículos
                  </Button>
                )}
              </div>
            )}
            <div className="ant-table-styles-article">
              <Table
                rowKey="id"
                className="cursor-pointer"
                dataSource={articulos}
                scroll={{ scrollToFirstRowOnChange: true, x: "100%" }}
                pagination={false}
                locale={{ emptyText: emptyStateMarkup }}
                headings={[
                  "",
                  "Clave",
                  "Nombre",
                  "Precio",
                  "Existencia",
                  "U. med.",
                ]}
                columns={[
                  {
                    title: "Clave",
                    dataIndex: "clave",
                    render: (clave, { _id, imagen, isParent }) => (
                      <div className="flex flex-row items-center">
                        <div className="pl-6">
                          <Checkbox
                            value={_id}
                            checked={selectedItems.indexOf(_id) >= 0}
                            onChange={(val) => handleSelect(val, _id)}
                          />
                        </div>
                        <div
                          className="flex flex-row items-center pr-6 w-full h-full whitespace-nowrap"
                          onClick={() => navigate(_id)}
                        >
                          <div className="mr-4">
                            <Thumbnail
                              size="medium"
                              source={
                                getImageVideoUrl(imagen, "image", false) ||
                                "/Default Photo.png"
                              }
                            />
                          </div>
                          <div className="text-left text-link">
                            <TextStyle variation="strong">
                              {isParent ? clave.split(" ")[0] : clave || "--"}
                            </TextStyle>
                          </div>
                        </div>
                      </div>
                    ),
                  },
                  {
                    title: "Nombre",
                    dataIndex: "nombre",
                    render: (nombre, { clave, _id }) => (
                      <div
                        className="w-full h-full cursor-pointer p-12 pl-6 whitespace-nowrap"
                        onClick={() => navigate(_id)}
                      >
                        <TextStyle>{nombre || clave}</TextStyle>
                      </div>
                    ),
                  },
                  {
                    title: "Precio",
                    dataIndex: "precios",
                    render: (precios = [], { _id }) => (
                      <div
                        className="w-full h-full cursor-pointer p-12 pl-6 whitespace-nowrap"
                        onClick={() => navigate(_id)}
                      >
                        <TextStyle>
                          {precios?.find(
                            (i) => i.nombre_precio === precioLista[0]
                          )
                            ? "$" +
                              Number(
                                precios?.find(
                                  (i) => i.nombre_precio === precioLista[0]
                                ).precio
                              ).toLocaleString("en", NumberFormat)
                            : "--"}
                        </TextStyle>
                      </div>
                    ),
                  },
                  {
                    title: "Existencia",
                    dataIndex: "existencia",
                    render: (existencia = []) => {
                      const items = existencia
                        .filter((i) =>
                          sucursal.length > 0
                            ? sucursal.includes(i.nombre_almacen)
                            : true
                        )
                        .filter((i) => i.existencia !== 0)
                        .map((i) => ({
                          content: `${i.existencia} - ${i.nombre_almacen}`,
                        }));

                      const existenciaResult =
                        sucursal && sucursal.length > 0
                          ? existencia
                              .filter((i) =>
                                sucursal.includes(i.nombre_almacen)
                              )
                              .reduce(
                                (prev, total) => prev + total.existencia,
                                0
                              )
                          : existencia.reduce(
                              (prev, total) => prev + total.existencia,
                              0
                            );

                      const Activator = ({ onClick }) => (
                        <div className="no-underline text-gray-600 z-50 div-no-grap">
                          <Button
                            onClick={onClick}
                            disclosure
                            plain
                            textAlign="left"
                            monochrome
                          >
                            {existenciaResult} existencias
                          </Button>
                        </div>
                      );

                      return (
                        <div className="w-full h-full cursor-pointer p-12 pl-6 whitespace-nowrap">
                          <CustomPopover items={items} activator={Activator} />
                        </div>
                      );
                    },
                  },
                  {
                    title: "U. Med.",
                    dataIndex: "unidadmed",
                    className: "whitespace-nowrap",
                    render: (unidadmed, { isParent, variants = [], _id }) => {
                      const unidadmedResult = isParent
                        ? variants[0]?.variantId?.unidadmed || ""
                        : unidadmed;
                      return (
                        <div
                          className="w-full h-full cursor-pointer p-12 pl-6 whitespace-nowrap"
                          onClick={() => navigate(_id)}
                        >
                          <TextStyle>{unidadmedResult}</TextStyle>
                        </div>
                      );
                    },
                  },
                ]}
              />
            </div>
          </div>
        )}
        <div className="flex justify-center py-6 pagination px-10">
          <AntPagination
            total={pagination.total}
            current={pagination.currentPage + 1}
            pageSize={pagination.sizePerPage}
            onChange={(page) => {
              dispatch(changePage(page - 1));
            }}
          />
        </div>
      </Card>
    </div>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "sucursal":
        return `Almacén - ${sucursales
          .filter((i) => value.some((j) => j === i.value))
          .map((i) => i.label)
          .join(", ")}`;
      case "lineaArticulo":
        return `Linea ${lineas
          .filter((i) => value.some((j) => j === i.value))
          .map((i) => i.label)
          .join(", ")}`;
      case "precioLista":
        return value.map((val) => `${val}`).join(", ");
      case "state":
        return `Estado ${ARTICLE_STATE.filter((i) =>
          value.some((j) => j === i.value)
        )
          .map((i) => i.label)
          .join(", ")}`;
      case "salesChannel":
        return value.map((val) => `Canal de venta ${val}`).join(", ");
      case "taggedWith":
        return `Etiquetado con ${value}`;
      case "brand":
        return `Marca ${brands
          .filter((i) => value.some((j) => j === i.value))
          .map((i) => i.label)
          .join(", ")}`;
      case "continueSelling":
        return `${CONTINUE_SELLING.filter((i) =>
          value.some((j) => j === i.value)
        )
          .map((i) => i.label)
          .join(", ")}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return (
        value === "" ||
        value == null ||
        value === "hoy" ||
        value === "Precio de lista"
      );
    }
  }
}
