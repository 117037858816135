import React, { useState, useCallback } from 'react';
import { Card, ChoiceList, Modal } from '@shopify/polaris';
import { useEffect } from 'react';


export default function EditClienteEstatusModal({isOpen, toggleModal, estatus, onChange, hasEmail}) {
   const [selected, setSelected] = useState(['hidden']);
   const handleChange = useCallback((value) => setSelected(value), []);

   useEffect(() => {
      setSelected(estatus);
      return () => null
   }, [estatus]);

   function onChangeSelect(val) {
      handleChange(val);
      onChange(val[0]);
      toggleModal();
   }

   return (
      <Modal 
         open={isOpen}
         onClose={toggleModal}
         title="Editar el estatus del contacto"
         secondaryActions={[{content: "Cancelar", onAction: toggleModal}]}
      >
         <Modal.Section>
            <Card sectioned>
               <ChoiceList
                  title="Cambia el estatus de la cuenta del cliente"
                  choices={[
                     {label: "Activo", value: "Activo", disabled: true},
                     {label: "Inactivo", value: "Inactivo" },
                     {label: "Invitar", value: "Invitado", disabled:estatus === "Activo" || !hasEmail},
                  ]}
                  selected={selected}
                  onChange={onChangeSelect}
               />
            </Card>
         </Modal.Section>
      </Modal>
   )
}
