import { useEffect, useState } from "react";
import ApiServiceAuth from "../services/ApiServiceAuth";
import { getSchedule } from "../utils/validateTime";

export default function useSchedule() {
  const [scheduleState, setScheduleState] = useState({
    isInRange: true,
    remaining: { hours: 0, minutes: 0, seconds: 0 },
  });
  const [schedules, setSchedules] = useState([]);
  const [shouldUseSchedule, setShouldseSchedule] = useState(false);
  const [exists, setExists] = useState(true);
  const [isLoginAllowed, setIsLoginAllowed] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      if (shouldUseSchedule && schedules.length > 0) {
        const { isInRange, remaining } = getSchedule(schedules);
        setScheduleState({ isInRange, remaining });
        return;
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [schedules, shouldUseSchedule]);

  useEffect(() => {
    const fetchValidateStore = () => {
      ApiServiceAuth.validateStore()
        .then(({ schedules = [], useSchedule = false, exists }) => {
          setSchedules(schedules);
          setShouldseSchedule(useSchedule);
          const { isInRange, remaining, hasSchedules } = getSchedule(schedules);
          setScheduleState({ isInRange, remaining });
          setIsLoginAllowed(hasSchedules && isInRange);
          setExists(exists);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchValidateStore();
  }, []);
  return {
    ...scheduleState,
    schedules,
    shouldUseSchedule,
    exists,
    isLoginAllowed,
    loading,
  };
}
