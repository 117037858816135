import React, { useCallback } from "react";
import { ChoiceList, TextField, EmptyState } from "@shopify/polaris";
import { useHistory } from "react-router";
import { ChoiceDate, Table } from "..";
import { changePage } from "../../actions/CotizacionesActions";
import { OPPORTUNITY_ORDER_BY, OPPORTUNITY_TYPE } from "../../utils/constants";
import useGetPhases from "../../hooks/useGetPhases";
import useGetOrigins from "../../hooks/useGetOrigins";
import useGetVendors from "../../hooks/useGetVendors";

import imgEmptyState from "./img.png";
import { useDispatch, useSelector } from "react-redux";
import { getAuthData } from "../../reducers";

export default function OportunidadesList({
  oportunidadesColumns,
  oportunidades,
  phaseOpportunity,
  opportunityType,
  origin,
  handlePhaseOpportunityChange,
  handlePhaseOpportunityRemove,
  handleOpportunityTypeChange,
  handleOpportunityTypeRemove,
  handleOriginChange,
  handleOriginRemove,
  orderSelected,
  handleOrderSelectedChange,
  orden,
  handleOrdenChange,
  closingAt,
  createdAt,
  handleClosingAtChange,
  handleCreatedAtChange,
  handleDateCreatedRemove,
  selectedClosingAt,
  setSelectedClosingAt,
  selectedCreatedAt,
  setSelectedCreatedAt,
  opportunityVendor,
  handleVendorChange,
  handleVendorRemove,
  handleOrderSelectedRemove,
  handleQueryValueRemove,
  handleDateClosingRemove,
  handleFiltersQueryChange,
  isLoading,
  pagination,
  queryValue,
  handleSelectAll,
  selectedItems,
  taggedWith,
  handleTaggedWithChange,
  handleTaggedWithRemove,
}) {
  const dispatch = useDispatch();
  const router = useHistory();
  let { grant_type } = useSelector(getAuthData);
  const { phases } = useGetPhases();
  const { origins } = useGetOrigins();
  const { vendors } = useGetVendors();

  const handleFiltersClearAll = useCallback(() => {
    handlePhaseOpportunityRemove();
    handleDateClosingRemove();
    handleOpportunityTypeRemove();
    handleOriginRemove();
    handleVendorRemove();
    handleTaggedWithRemove();
    handleOrderSelectedRemove();
    handleDateCreatedRemove();
    handleQueryValueRemove();
  }, [
    handlePhaseOpportunityRemove,
    handleDateClosingRemove,
    handleOpportunityTypeRemove,
    handleOriginRemove,
    handleVendorRemove,
    handleTaggedWithRemove,
    handleOrderSelectedRemove,
    handleDateCreatedRemove,
    handleQueryValueRemove,
  ]);

  function navigate(id) {
    router.push(`/admin/oportunidades/${id}`);
  }

  let filters = [
    {
      key: "phaseOpportunity",
      label: "Etapa de la oportunidad",
      filter: (
        <ChoiceList
          title="Etapa de la oportunidad"
          titleHidden
          choices={phases.map((phase) => ({
            label: phase.label,
            value: phase.value,
          }))}
          selected={phaseOpportunity || []}
          onChange={handlePhaseOpportunityChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      shortcut: false,
      key: "closingDate",
      label: "Fecha estimada de cierre",
      filter: (
        <ChoiceDate
          value={closingAt}
          handleChange={handleClosingAtChange}
          selectedDates={selectedClosingAt}
          setSelectedDates={setSelectedClosingAt}
          past={false}
        />
      ),
    },
    {
      key: "opportunityType",
      label: "Tipo de oportunidad",
      filter: (
        <ChoiceList
          title="Tipo de oportunidad"
          titleHidden
          choices={OPPORTUNITY_TYPE}
          selected={opportunityType}
          onChange={handleOpportunityTypeChange}
        />
      ),
    },
    {
      key: "origin",
      label: "Origen",
      filter: (
        <ChoiceList
          title="Origen"
          titleHidden
          choices={origins.map((origin) => ({
            label: origin.label,
            value: origin.value,
          }))}
          selected={origin || []}
          onChange={handleOriginChange}
          allowMultiple
        />
      ),
    },
    {
      key: "opportunityVendor",
      label: "Vendedor",
      filter: (
        <ChoiceList
          title="Vendedor"
          titleHidden
          choices={vendors.map((vendor) => ({
            label: vendor.label,
            value: vendor.value,
          }))}
          selected={opportunityVendor || []}
          onChange={handleVendorChange}
          allowMultiple
        />
      ),
    },
    {
      key: "taggedWith",
      label: "Etiquetado con",
      filter: (
        <TextField
          label="Etiquetado con"
          value={taggedWith}
          onChange={handleTaggedWithChange}
        />
      ),
      shortcut: false,
    },
    {
      key: "orderSelected",
      label: "Ordenar",
      filter: (
        <div className="flex flex-col">
          <ChoiceList
            title="Ordenar"
            titleHidden
            choices={OPPORTUNITY_ORDER_BY}
            selected={orderSelected || []}
            onChange={handleOrderSelectedChange}
          />
          <div className="flex w-full border border-t-1 border-b-0 border-r-0 border-l-0 border-gray-400">
            <ChoiceList
              title="Ordenar"
              titleHidden
              choices={[
                { label: "Ascendente", value: "1" },
                { label: "Descendente", value: "-1" },
              ]}
              selected={orden}
              onChange={handleOrdenChange}
            />
          </div>
        </div>
      ),
      shortcut: true,
    },
    {
      shortcut: true,
      key: "dateCreated",
      label: "Fecha de creación",
      filter: (
        <ChoiceDate
          value={createdAt}
          handleChange={handleCreatedAtChange}
          selectedDates={selectedCreatedAt}
          setSelectedDates={setSelectedCreatedAt}
          past
        />
      ),
    },
  ];

  filters =
    grant_type === "AGENT"
      ? filters.filter((filter) => filter.key !== "opportunityVendor")
      : filters;

  const emptyState = (
    <EmptyState
      heading="Tus oportunidades aparecerán aquí"
      action={{
        content: "Crear oportunidad",
        url: "/admin/oportunidades/agregar",
      }}
      image={imgEmptyState}
      u
    >
      <p>
        Aquí podrás crear tus oportunidades de venta con tus clientes y darle
        seguimiento hasta el pedido.
      </p>
    </EmptyState>
  );

  return (
    <>
      <Table
        handleSelectAll={handleSelectAll}
        selectedItems={selectedItems}
        columns={oportunidadesColumns}
        dataSource={oportunidades}
        emptyContent="Intenta crear una nueva oportunidad"
        filters={filters}
        vendors={vendors}
        handleFiltersClearAll={handleFiltersClearAll}
        handleFiltersQueryChange={handleFiltersQueryChange}
        handleQueryValueRemove={handleQueryValueRemove}
        isLoading={isLoading}
        navigate={navigate}
        pagination={pagination}
        queryValue={queryValue}
        tableName="oportunidades"
        type="oportunidad"
        urlEmptyStateShop="/admin/oportunidades/agregar"
        customEmptyState={emptyState}
        appliedFiltersData={[
          {
            secondLabel: "Etapa",
            label: "phaseOpportunity",
            state: phases
              .filter((i) => phaseOpportunity.some((j) => j === i.value))
              .map((i) => i.label),
            handleRemove: handlePhaseOpportunityRemove,
            willMap: true,
          },
          {
            seconlabel: "Fecha de cierre",
            label: "closingDate",
            state: closingAt,
            handleRemove: handleDateClosingRemove,
          },
          {
            secondLabel: "Tipo",
            label: "opportunityType",
            state: opportunityType,
            handleRemove: handleOpportunityTypeRemove,
            willMap: true,
          },
          {
            secondLabel: "Origen",
            label: "origin",
            state: origins
              .filter((i) => origin.some((j) => j === i.value))
              .map((i) => i.label),
            handleRemove: handleOriginRemove,
            willMap: true,
          },
          {
            secondLabel: "Vendedor",
            label: "opportunityVendor",
            state: vendors
              .filter((i) => opportunityVendor.some((j) => j === i.value))
              .map((i) => i.label),
            handleRemove: handleVendorRemove,
            willMap: true,
          },
          {
            secondLabel: "Etiquetado con",
            label: "taggedWith",
            handleRemove: handleTaggedWithRemove,
            willMap: true,
          },
          {
            secondLabel: "Fecha de creacion",
            label: "dateCreated",
            handleRemove: handleDateCreatedRemove,
            willMap: true,
          },
        ]}
        onChangePage={(page) => dispatch(changePage(page))}
      />
    </>
  );
}
