import { LayoutOnlineStore, RenderSectionsStyled } from "../../components";
import useGetPageSections from "../../hooks/useGetPageSections";

const currentPage = "not-found-page";
export default function ProductsPage() {
  const { data: sections } = useGetPageSections(currentPage, []);

  const heading =
    sections?.find((section) => section.type === "heading")?.sections || [];
  const body =
    sections?.find((section) => section.type === currentPage)?.sections || [];
  const footer =
    sections?.find((section) => section.type === "footer")?.sections || [];

  return (
    <LayoutOnlineStore title="Página no encontrada">
      <RenderSectionsStyled
        sections={sections}
        heading={heading}
        body={body}
        footer={footer}
      />
    </LayoutOnlineStore>
  );
}
