import React from 'react';
import { useSelector } from 'react-redux';
import { getClientes, isLoadingClientes, getPaginationClientes } from '../../reducers';

export default function ClientesContainer({children}) {
   const isLoading = useSelector(isLoadingClientes);
   const pagination = useSelector(getPaginationClientes);
   const clientes = useSelector(getClientes).map(item => { return { ...item, id:item._id }});

   return (
      <React.Fragment>
         {children({clientes, isLoading, pagination}) }
      </React.Fragment>
   )
}
