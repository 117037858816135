import React, { useEffect, useState, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Card, Page, Tabs } from "@shopify/polaris";
import { useDispatch, useSelector } from "react-redux";
import { fetchDescuentos, resetPage } from "../../actions/DescuentosActions";
import DescuentosContainer from "../../containers/DescuentosContainer";
import {
  AddDiscountModal,
  DescuentosList,
  HelpFooter,
  Layer,
} from "../../components";
import { TabsDescuentosScreen as tabs } from "../../utils/Tabs";
import useDebounce from "../../services/useDebounce";
import useUrlSearch from "../../hooks/useUrlSearch";
import { getPaginationDescuentos } from "../../reducers";

const objSearch = ["query", "tab_selected", "status", "sort", "order"];
export default function DescuentosScreen({ forbidden }) {
  const { pathname } = useLocation();
  const { updateHistory, getSearch } = useUrlSearch({ objSearch, pathname });
  const dispatch = useDispatch();
  const pagination = useSelector(getPaginationDescuentos);

  const [tabSelected, setTabSelected] = useState(
    Number(getSearch("tab_selected", 0, true))
  );
  const [estatusDescuento, setEstatusDescuento] = useState(
    getSearch("status", [])
  );
  const queryValueRef = useRef(
    decodeURIComponent(getSearch("query", "", true))
  );
  const [queryValue, setQueryValue] = useState(
    decodeURIComponent(getSearch("query", "", true))
  );
  const [order, setOrder] = useState(getSearch("order", ["-1"]));
  const [sort, setSort] = useState(getSearch("sort", ["createdDate"]));
  const [isActiveModal, setIsActiveModal] = useState(false);

  const debouncedSearchTerm = useDebounce(queryValue, 900);
  useEffect(() => {
    queryValueRef.current = queryValue;
  });

  useEffect(() => {
    function obtenerDescuentos() {
      dispatch(
        fetchDescuentos({
          sort: sort[0],
          query: decodeURIComponent(queryValueRef.current),
          orden: order[0],
          estatus: JSON.stringify(estatusDescuento),
          tipo: tabs[tabSelected].panelID,
        })
      );
    }
    obtenerDescuentos();
  }, [
    dispatch,
    sort,
    order,
    estatusDescuento,
    tabSelected,
    debouncedSearchTerm,
    pagination.currentPage,
  ]);

  const toggleModalDiscount = useCallback(
    () => setIsActiveModal(!isActiveModal),
    [isActiveModal]
  );

  function handleChangeStates(
    value,
    setState,
    key,
    useStringify = true,
    resetTab = false
  ) {
    updateHistory(
      {
        [key]: value,
      },
      useStringify
    );
    resetPagination();
    setState(value);
    if (resetTab) {
      setTabSelected(0);
      updateHistory(
        {
          tab_selected: 0,
        },
        false
      );
    }
  }

  function resetPagination() {
    dispatch(resetPage());
  }

  return (
    <Layer title="Descuentos" forbidden={forbidden} scrollToTop>
      <Page
        title="Descuentos"
        fullWidth
        primaryAction={{
          content: "Agregar descuento",
          onAction: toggleModalDiscount,
        }}
      >
        {isActiveModal && (
          <AddDiscountModal
            toggleModal={toggleModalDiscount}
            isActive={isActiveModal}
          />
        )}
        <Card>
          <DescuentosContainer>
            {({ isLoading, descuentos, pagination }) => {
              return (
                <Tabs
                  tabs={tabs}
                  selected={tabSelected}
                  onSelect={(value) => {
                    handleChangeStates(
                      value,
                      setTabSelected,
                      "tab_selected",
                      false
                    );
                  }}
                >
                  <DescuentosList
                    isLoading={isLoading}
                    descuentos={descuentos}
                    pagination={pagination}
                    toggleModal={toggleModalDiscount}
                    estatusDescuento={estatusDescuento}
                    handleEstatusDescuentoChange={(value) => {
                      handleChangeStates(
                        value,
                        setEstatusDescuento,
                        "status",
                        true,
                        true
                      );
                    }}
                    handleEstatusDescuentoRemove={() => {
                      handleChangeStates(
                        [],
                        setEstatusDescuento,
                        "status",
                        true,
                        true
                      );
                    }}
                    queryValue={decodeURIComponent(queryValue)}
                    handleFiltersQueryChange={(value) =>
                      handleChangeStates(
                        value ? encodeURIComponent(value) : value,
                        setQueryValue,
                        "query",
                        false
                      )
                    }
                    handleQueryValueRemove={() => {
                      handleChangeStates("", setQueryValue, "query", false);
                    }}
                    orden={order}
                    handleOrdenChange={(value) =>
                      handleChangeStates(value, setOrder, "order")
                    }
                    orderSelected={sort}
                    handleOrderSelectedChange={(value) =>
                      handleChangeStates(value, setSort, "sort")
                    }
                  />
                </Tabs>
              );
            }}
          </DescuentosContainer>
        </Card>
        <HelpFooter title="descuentos" url="https://help.b2bgo.mx/descuentos" />
      </Page>
    </Layer>
  );
}
