export const Timezone = [
   {
     "id": "UTC",
     "location":  "Default",
     "codigo_zona": "(GMT+00:00)"
   },
   {
     "id": "Etc/GMT+12",
     "location":  "International Date Line West",
     "codigo_zona": "(GMT-12:00)"
   },
   {
     "id": "Etc/GMT+11",
     "location":  "Coordinated Universal Time-11",
     "codigo_zona": "(GMT-11:00)"
   },
   {
     "id": "Pacific/Samoa",
     "location":  "Samoa",
     "codigo_zona": "(GMT-11:00)"
   },
   {
     "id": "Pacific/Honolulu",
     "location":  "Hawaii",
     "codigo_zona": "(GMT-10:00)"
   },
   {
     "id": "Pacific/Marquesas",
     "location":  "Marquesas Islands",
     "codigo_zona": "(GMT-09:30)"
   },
   {
     "id": "America/Anchorage",
     "location":  "Alaska",
     "codigo_zona": "(GMT-09:00)"
   },
   {
     "id": "America/Tijuana",
     "location":  "Baja California",
     "codigo_zona": "(GMT-08:00)"
   },
   {
     "id": "America/Los_Angeles",
     "location":  "Pacific Time (US & Canada)",
     "codigo_zona": "(GMT-08:00)"
   },
   {
     "id": "America/Chihuahua",
     "location":  "Chihuahua,Mazatlan",
     "codigo_zona": "(GMT-07:00)"
   },
   {
     "id": "America/Phoenix",
     "location":  "Arizona",
     "codigo_zona": "(GMT-07:00)"
   },
   {
     "id": "America/Denver",
     "location":  "Mountain Time (US & Canada)",
     "codigo_zona": "(GMT-07:00)"
   },
   {
     "id": "America/Chicago",
     "location":  "Central Time (US & Canada)",
     "codigo_zona": "(GMT-06:00)"
   },
   {
     "id": "America/Guatemala",
     "location":  "Central America",
     "codigo_zona": "(GMT-06:00)"
   },
   {
     "id": "America/Mexico_City",
     "location":  "Guadalajara, Ciudad de México, Monterrey",
     "codigo_zona": "(GMT-06:00)"
   },
   {
     "id": "America/Regina",
     "location":  "Saskatchewan",
     "codigo_zona": "(GMT-06:00)"
   },
   {
     "id": "America/Bogota",
     "location":  "Bogota, Lima, Quito",
     "codigo_zona": "(GMT-05:00)"
   },
   {
     "id": "America/New_York",
     "location":  "Eastern Time (US & Canada)",
     "codigo_zona": "(GMT-05:00)"
   },
   {
     "id": "America/Indianapolis",
     "location":  "Indiana (East)",
     "codigo_zona": "(GMT-05:00)"
   },
   {
     "id": "America/Caracas",
     "location":  "Caracas",
     "codigo_zona": "(GMT-04:30)"
   },
   {
     "id": "America/Halifax",
     "location":  "Atlantic Time (Canada)",
     "codigo_zona": "(GMT-04:00)"
   },
   {
     "id": "America/Asuncion",
     "location":  "Asuncion",
     "codigo_zona": "(GMT-04:00)"
   },
   {
     "id": "America/Cuiaba",
     "location":  "Cuiaba",
     "codigo_zona": "(GMT-04:00)"
   },
   {
     "id": "America/Santiago",
     "location":  "Santiago",
     "codigo_zona": "(GMT-04:00)"
   },
   {
     "id": "America/La_Paz",
     "location":  "Georgetown, La Paz, Manaus, San Juan",
     "codigo_zona": "(GMT-04:00)"
   },
   {
     "id": "America/St_Johns",
     "location":  "Newfoundland",
     "codigo_zona": "(GMT-03:30)"
   },
   {
     "id": "America/Buenos_Aires",
     "location":  "Buenos Aires",
     "codigo_zona": "(GMT-03:00)"
   },
   {
     "id": "America/Sao_Paul",
     "location":  "Brasilia",
     "codigo_zona": "(GMT-03:00)"
   },
   {
     "id": "America/Cayenne",
     "location":  "Cayenne, Fortaleza",
     "codigo_zona": "(GMT-03:00)"
   },
   {
     "id": "America/Montevideo",
     "location":  "Montevideo",
     "codigo_zona": "(GMT-03:00)"
   },
   {
     "id": "America/Godthab",
     "location":  "Greenland",
     "codigo_zona": "(GMT-03:00)"
   },
   {
     "id": "Etc/GMT+2",
     "location":  "Coordinated Universal Time-02",
     "codigo_zona": "(GMT-02:00)"
   },
   {
     "id": "Atlantic/Azores",
     "location":  "Azores",
     "codigo_zona": "(GMT-01:00)"
   },
   {
     "id": "Atlantic/Cape_Verde",
     "location":  "Cape Verde Is.",
     "codigo_zona": "(GMT-01:00)"
   },
   {
     "id": "Africa/Casablanca",
     "location":  "Casablanca",
     "codigo_zona": "(GMT+00:00)"
   },
   {
     "id": "Atlantic/Reykjavik",
     "location":  "Monrovia,Reykjavik",
     "codigo_zona": "(GMT+00:00)"
   },
   {
     "id": "Europe/London",
     "location":  "Greenwich Mean Time : Dublin,Edinburgh,Lisbon, London",
     "codigo_zona": "(GMT+00:00)"
   },
   {
     "id": "Europe/Warsaw",
     "location":  "Sarajevo,Skopje,  Warsaw, Zagreb",
     "codigo_zona": "(GMT+01:00)"
   },
   {
     "id": "Africa/Lagos",
     "location":  "West Central Africa",
     "codigo_zona": "(GMT+01:00)"
   },
   {
     "id": "Europe/Budapest",
     "location":  "Belgrade,Bratislava,  Budapest, Ljubljana, Prague",
     "codigo_zona": "(GMT+01:00)"
   },
   {
     "id": "Europe/Paris",
     "location":  "Brussels, Copenhagen,Madrid, Paris",
     "codigo_zona": "(GMT+01:00)"
   },
   {
     "id": "Europe/Berlin",
     "location":  "Amsterdam,Berlin,  Bern, Rome,Stockholm, Vienna",
     "codigo_zona": "(GMT+01:00)"
   },
   {
     "id": "Africa/Johannesburg",
     "location":  "Harare, Pretoria",
     "codigo_zona": "(GMT+02:00)"
   },
   {
     "id": "Asia/Damascus",
     "location":  "Damascus",
     "codigo_zona": "(GMT+02:00)"
   },
   {
     "id": "Europe/Kiev",
     "location":  "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
     "codigo_zona": "(GMT+02:00)"
   },
   {
     "id": "Africa/Windhoek",
     "location":  "Windhoek",
     "codigo_zona": "(GMT+02:00)"
   },
   {
     "id": "Europe/Minsk",
     "location":  "Minsk",
     "codigo_zona": "(GMT+02:00)"
   },
   {
     "id": "Europe/Istanbu",
     "location":  "Athens,Bucharest,  Istanbul",
     "codigo_zona": "(GMT+02:00)"
   },
   {
     "id": "Asia/Amman",
     "location":  "Amman",
     "codigo_zona": "(GMT+02:00)"
   },
   {
     "id": "Asia/Beirut",
     "location":  "Beirut",
     "codigo_zona": "(GMT+02:00)"
   },
   {
     "id": "Asia/Jerusalem",
     "location":  "Jerusalem",
     "codigo_zona": "(GMT+02:00)"
   },
   {
     "id": "Africa/Cairo",
     "location":  "Cairo",
     "codigo_zona": "(GMT+02:00)"
   },
   {
     "id": "Asia/Riyadh",
     "location":  "Kuwait, Riyadh",
     "codigo_zona": "(GMT+03:00)"
   },
   {
     "id": "Europe/Moscow",
     "location":  "Moscow, St. Petersburg, Volgograd",
     "codigo_zona": "(GMT+03:00)"
   },
   {
     "id": "Asia/Baghdad",
     "location":  "Baghdad",
     "codigo_zona": "(GMT+03:00)"
   },
   {
     "id": "Africa/Nairobi",
     "location":  "Nairobi",
     "codigo_zona": "(GMT+03:00)"
   },
   {
     "id": "Asia/Tehran",
     "location":  "Tehran",
     "codigo_zona": "(GMT+03:30)"
   },
   {
     "id": "Indian/Mauritius",
     "location":  "Port Louis",
     "codigo_zona": "(GMT+04:00)"
   },
   {
     "id": "Asia/Tbilisi",
     "location":  "Tbilisi",
     "codigo_zona": "(GMT+04:00)"
   },
   {
     "id": "Asia/Baku",
     "location":  "Baku",
     "codigo_zona": "(GMT+04:00)"
   },
   {
     "id": "Asia/Yerevan",
     "location":  "Yerevan",
     "codigo_zona": "(GMT+04:00)"
   },
   {
     "id": "Asia/Dubai",
     "location":  "Abu Dhabi, Muscat",
     "codigo_zona": "(GMT+04:00)"
   },
   {
     "id": "Asia/Kabul",
     "location":  "Kabul",
     "codigo_zona": "(GMT+04:30)"
   },
   {
     "id": "Asia/Yekaterinburg",
     "location":  "Yekaterinburg",
     "codigo_zona": "(GMT+05:00)"
   },
   {
     "id": "Asia/Karachi",
     "location":  "Islamabad,Karachi",
     "codigo_zona": "(GMT+05:00)"
   },
   {
     "id": "Asia/Tashkent",
     "location":  "Tashkent",
     "codigo_zona": "(GMT+05:00)"
   },
   {
     "id": "Asia/Calcutta",
     "location":  "Chennai, Kolkata, Mumbai,New Delhi",
     "codigo_zona": "(GMT+05:30)"
   },
   {
     "id": "Asia/Colombo",
     "location":  "Sri Jayawardenepura",
     "codigo_zona": "(GMT+05:30)"
   },
   {
     "id": "Asia/Katmandu",
     "location":  "Kathmandu",
     "codigo_zona": "(GMT+05:45)"
   },
   {
     "id": "Asia/Dhaka",
     "location":  "Dhaka",
     "codigo_zona": "(GMT+06:00)"
   },
   {
     "id": "Asia/Novosibirsk",
     "location":  "Novosibirsk",
     "codigo_zona": "(GMT+06:00)"
   },
   {
     "id": "Asia/Almaty",
     "location":  "Astana",
     "codigo_zona": "(GMT+06:00)"
   },
   {
     "id": "Asia/Rangoon",
     "location":  "Yangon (Rangoon)",
     "codigo_zona": "(GMT+06:30)"
   },
   {
     "id": "Asia/Krasnoyarsk",
     "location":  "Krasnoyarsk",
     "codigo_zona": "(GMT+07:00)"
   },
   {
     "id": "Asia/Bangkok",
     "location":  "Bangkok, Hanoi,Jakarta",
     "codigo_zona": "(GMT+07:00)"
   },
   {
     "id": "Asia/Ulaanbaatar",
     "location":  "Ulaanbaatar",
     "codigo_zona": "(GMT+08:00)"
   },
   {
     "id": "Australia/Perth",
     "location":  "Perth",
     "codigo_zona": "(GMT+08:00)"
   },
   {
     "id": "Asia/Taipei",
     "location":  "Taipei",
     "codigo_zona": "(GMT+08:00)"
   },
   {
     "id": "Asia/Singapore",
     "location":  "Kuala Lumpur,Singapore",
     "codigo_zona": "(GMT+08:00)"
   },
   {
     "id": "Asia/Shanghai",
     "location":  "Beijing,Chongqing,Hong Kong, Urumqi",
     "codigo_zona": "(GMT+08:00)"
   },
   {
     "id": "Asia/Irkutsk",
     "location":  "Irkutsk",
     "codigo_zona": "(GMT+08:00)"
   },
   {
     "id": "Asia/Pyongyang",
     "location":  "Pyongyang",
     "codigo_zona": "(GMT+08:30)"
   },
   {
     "id": "Australia/Eucla",
     "location":  "Eucla",
     "codigo_zona": "(GMT+08:30)"
   },
   {
     "id": "Asia/Seoul",
     "location":  "Seoul",
     "codigo_zona": "(GMT+09:00)"
   },
   {
     "id": "Asia/Tokyo",
     "location":  "Osaka,Sapporo, Tokyo",
     "codigo_zona": "(GMT+09:00)"
   },
   {
     "id": "Asia/Yakutsk",
     "location":  "Yakutsk",
     "codigo_zona": "(GMT+09:00)"
   },
   {
     "id": "Australia/Darwin",
     "location":  "Darwin",
     "codigo_zona": "(GMT+09:30)"
   },
   {
     "id": "Australia/Adelaide",
     "location":  "Adelaide",
     "codigo_zona": "(GMT+09:30)"
   },
   {
     "id": "Australia/Hobart",
     "location":  "Hobart",
     "codigo_zona": "(GMT+10:00)"
   },
   {
     "id": "Asia/Vladivostok",
     "location":  "Vladivostok",
     "codigo_zona": "(GMT+10:00)"
   },
   {
     "id": "Pacific/Port_Moresby",
     "location":  "Guam, Port Moresby",
     "codigo_zona": "(GMT+10:00)"
   },
   {
     "id": "Australia/Brisbane",
     "location":  "Brisbane",
     "codigo_zona": "(GMT+10:00)"
   },
   {
     "id": "Australia/Sydney",
     "location":  "Canberra,Melbourne,  Sydney",
     "codigo_zona": "(GMT+10:00)"
   },
   {
     "id": "Australia/LHI",
     "location":  "Lord Howe Island",
     "codigo_zona": "(GMT+10:30)"
   },
   {
     "id": "Asia/Magadan",
     "location":  "Magadan, Solomon Is.,New Caledonia",
     "codigo_zona": "(GMT+11:00)"
   },
   {
     "id": "Pacific/Fiji",
     "location":  "Fiji",
     "codigo_zona": "(GMT+12:00)"
   },
   {
     "id": "Asia/Kamchatka",
     "location":  "Petropavlovsk-Kamchatsky",
     "codigo_zona": "(GMT+12:00)"
   },
   {
     "id": "Pacific/Auckland",
     "location":  "Auckland, Wellington",
     "codigo_zona": "(GMT+12:00)"
   },
   {
     "id": "Etc/GMT-12",
     "location":  "Coordinated Universal Time+12",
     "codigo_zona": "(GMT+12:00)"
   },
   {
     "id": "Pacific/Chatham",
     "location":  "Chatham Islands",
     "codigo_zona": "(GMT+12:45)"
   },
   {
     "id": "Pacific/Enderbury",
     "location":  "Phoenix Islands, Tokelau, Tonga",
     "codigo_zona": "(GMT+13:00)"
   },
   {
     "id": "Pacific/Kiritimati",
     "location":  "Line Islands",
     "codigo_zona": "(GMT+14:00)"
   }
]