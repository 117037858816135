import { Banner, Card, Modal, TextStyle } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import Checklist from "../Checklist";
import "./TestConnectionModal.css";

export default function TestConnectionModal({
  isActive,
  connection,
  handleTestConnection,
}) {
  const [fetchClientes, setFetchClientes] = useState({
    success: null,
    isLoading: true,
  });
  const [fetchArticulos, setFetchArticulos] = useState({
    success: null,
    isLoading: true,
  });
  const [fetchPedidos, setFectchPedidos] = useState({
    success: null,
    isLoading: true,
  });
  useEffect(() => {
    setFetchClientes((state) => ({ ...state, isLoading: true }));

    function touchSkyClients() {
      const url = `${connection.host}/exsim/b2b/metodo/CLIENTES/${connection.empresa}/1/0/1/N`;
      ApiServiceComercios.checkIntegrationMicrosip({ url })
        .then((res) => {
          if (res.ok) {
            setFetchClientes((state) => ({ ...state, success: true }));
          }
        })
        .catch((error) =>
          setFetchClientes((state) => ({ ...state, success: false }))
        )
        .finally(() =>
          setFetchClientes((state) => ({ ...state, isLoading: false }))
        );
    }

    function touchSkyPedidos() {
      const url = `${connection.host}/exsim/b2b/metodo/PEDIDOS/${connection.empresa}/1/0/1/N`;
      ApiServiceComercios.checkIntegrationMicrosip({ url })
        .then((res) => {
          if (res.ok) {
            setFectchPedidos((state) => ({ ...state, success: true }));
          }
        })
        .catch((err) =>
          setFectchPedidos((state) => ({ ...state, success: false }))
        )
        .finally(() =>
          setFectchPedidos((state) => ({ ...state, isLoading: false }))
        );
    }

    function touchSkyArticles() {
      const url = `${connection.host}/exsim/b2b/metodo/ARTICULOS/${connection.empresa}/0/1/0/0/1/N`;
      ApiServiceComercios.checkIntegrationMicrosip({ url })
        .then((res) => {
          if (res.ok) {
            setFetchArticulos((state) => ({ ...state, success: true }));
          }
        })
        .catch((error) =>
          setFetchArticulos((state) => ({ ...state, success: false }))
        )
        .finally(() =>
          setFetchArticulos((state) => ({ ...state, isLoading: false }))
        );
    }
    touchSkyArticles();
    touchSkyClients();
    touchSkyPedidos();
    return () => null;
  }, [connection]);

  return (
    <Modal
      title="Probando conexión"
      open={isActive}
      onClose={() =>
        !fetchClientes.isLoading || !fetchArticulos.isLoading
          ? handleTestConnection(
              fetchClientes.success && fetchArticulos.success
            )
          : null
      }
      primaryAction={{
        disabled: fetchClientes.isLoading || fetchArticulos.isLoading,
        content: "Aceptar",
        onAction: () =>
          handleTestConnection(fetchClientes.success && fetchArticulos.success),
      }}
    >
      <Modal.Section>
        <Card.Section>
          <div className="flex justify-center">
            <img
              id="test-conextion-img"
              src="/connection.gif"
              alt="Test Connection"
            />
          </div>
          <Checklist
            text="Validando servicio clientes"
            isLoading={fetchClientes.isLoading}
            isSuccess={fetchClientes.success}
          />
          <Checklist
            text="Validando servicio artículos"
            isLoading={fetchArticulos.isLoading}
            isSuccess={fetchArticulos.success}
          />
          <Checklist
            text="Validando servicio pedidos"
            isLoading={fetchPedidos.isLoading}
            isSuccess={fetchPedidos.success}
          />
          {fetchClientes.success && fetchArticulos.success ? (
            <div className="my-4 border-t-2 border-green-500">
              <Banner
                title="Conexión completada"
                status="success"
                action={{ content: "Ver manual" }}
              >
                <TextStyle>
                  Los datos que ingresaste son correctos, ahora estarás
                  conectado a Microsip. Se sincronizarán tus catálogos de
                  clientes y artículos. Tus clientes podrán crear pedidos, ver
                  sus estados de cuenta y descargar sus facturas.
                </TextStyle>
              </Banner>
            </div>
          ) : !fetchClientes.isLoading && !fetchArticulos.isLoading ? (
            <div className="my-4 border-t-2 border-orange-300">
              <Banner
                title="Conexión incompleta"
                status="warning"
                action={{ content: "Ver manual" }}
              >
                <TextStyle>
                  Algunos datos que ingresaste son incorrectos, intenta
                  verificar nuevamente el servidor, la carpeta de datos, nombre
                  de la base de datos, usuario, contraseña y vuelve a revisar la
                  conexión y que el servicio de la API 4Q Sinc esté en
                  ejecución.
                </TextStyle>
              </Banner>
            </div>
          ) : null}
        </Card.Section>
      </Modal.Section>
    </Modal>
  );
}
