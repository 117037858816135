import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Page, Card, Tabs, FooterHelp, Link } from "@shopify/polaris";
import { useDispatch, useSelector } from "react-redux";
import {
  Layer,
  PedidosClienteList,
  ImportarPedidosModal,
  FooterCliente,
  CustomerStatusBanner,
} from "../../components";
import { TabsPedidosClienteScreen as tabs } from "../../utils/Tabs";
import { fetchPedidos, resetPage } from "../../actions/PedidosActions";
import PedidosContainer from "../../containers/PedidosContainer";
import useDebounce from "../../services/useDebounce";
import useAbility from "../../hooks/useAbility";
import useUrlSearch from "../../hooks/useUrlSearch";
import useToggle from "../../hooks/useToggle";
import { getPaginationPedidos } from "../../reducers";
import ApiServiceComercios from "../../services/ApiServiceComercios";

const objSearch = [
  "query",
  "tab_selected",
  "status",
  "sort",
  "order",
  "shipping",
  "created_by",
  "vendors",
  "selected_dates",
  "created_at",
];
export default function PedidosClienteScreen({ forbidden }) {
  const { pathname } = useLocation();
  const { updateHistory, getSearch } = useUrlSearch({ objSearch, pathname });
  const dispatch = useDispatch();
  const queryValueRef = useRef(
    decodeURIComponent(getSearch("query", "", true))
  );
  const pagination = useSelector(getPaginationPedidos);

  const [createdAt, setCreatedAt] = useState(
    getSearch("created_at", "todos", true)
  );
  const [selectedCreatedAt, setSelectedCreatedAt] = useState(
    getSearch("selected_dates", {
      start: new Date(),
      end: new Date(),
    })
  );

  const isForbiddenCreate = useAbility("write", "Pedidos");
  const [isModalActive, toggleModal] = useToggle();
  const [tabSelected, setTabSelected] = useState(
    Number(getSearch("tab_selected", 0, true))
  );
  const [queryValue, setQueryValue] = useState(
    decodeURIComponent(getSearch("query", "", true))
  );
  const [estatusPedido, setEstatusPedido] = useState(getSearch("status", []));
  const [vendorPedido, setVendorPedido] = useState(getSearch("vendors", []));
  const [shippingMethod, setShippingMethod] = useState(
    getSearch("shipping", [])
  );
  const [creatorSelected, setCreatorSelected] = useState(
    getSearch("created_by", [])
  );
  const [sort, setSort] = useState(getSearch("sort", ["folio"]));
  const [orden, setOrder] = useState(getSearch("order", ["-1"]));
  const [statusMicrosip, setStatusMicrosip] = useState("");

  const debouncedSearchTerm = useDebounce(queryValue, 900);

  useEffect(() => {
    queryValueRef.current = queryValue;
  });

  useEffect(() => {
    const getCustomerStatus = () => {
      ApiServiceComercios.getCustomerStatus().then(({ customer }) => {
        setStatusMicrosip(customer?.status_microsip);
      });
    };
    getCustomerStatus();
  }, []);

  useEffect(() => {
    function fetchData() {
      dispatch(
        fetchPedidos({
          byCliente: true,
          sort: sort[0],
          query: decodeURIComponent(queryValueRef.current),
          orden: orden[0],
          estatus: JSON.stringify([
            ...new Set(
              estatusPedido.concat(
                tabSelected === 1 ? [] : [tabs[tabSelected].panelID]
              )
            ),
          ]),
          vendors: JSON.stringify(vendorPedido),
          createdBy: JSON.stringify(creatorSelected),
          shippingMethod: JSON.stringify(shippingMethod),
          fecha: createdAt === "todos" ? undefined : selectedCreatedAt,
        })
      );
    }
    fetchData();
  }, [
    dispatch,
    sort,
    orden,
    estatusPedido,
    vendorPedido,
    shippingMethod,
    creatorSelected,
    tabSelected,
    createdAt,
    debouncedSearchTerm,
    selectedCreatedAt,
    pagination.currentPage,
  ]);

  function handleChangeStates(
    value,
    setState,
    key,
    useStringify = true,
    resetTab = false
  ) {
    updateHistory(
      {
        [key]: value,
      },
      useStringify
    );
    resetPagination();
    setState(value);
    if (resetTab) {
      setTabSelected(0);
      updateHistory(
        {
          tab_selected: 0,
        },
        false
      );
    }
  }

  function resetPagination() {
    dispatch(resetPage());
  }

  return (
    <Layer title="Pedidos" forbidden={forbidden} scrollToTop>
      <Page
        fullWidth
        title="Pedidos"
        secondaryActions={[
          {
            content: "Importar",
            onAction: toggleModal,
            disabled: statusMicrosip === "V",
          },
        ]}
        primaryAction={
          !isForbiddenCreate
            ? {
                content: "Crear pedido",
                primary: true,
                url: "/cliente/pedidos/agregar",
                disabled: statusMicrosip === "V",
              }
            : undefined
        }
      >
        {isModalActive && (
          <ImportarPedidosModal
            isActive={isModalActive}
            handleChange={toggleModal}
            type="cliente"
          />
        )}
        <CustomerStatusBanner status={statusMicrosip} />
        <Card>
          <PedidosContainer>
            {({ isLoading, pedidos, pagination }) => {
              return (
                <Tabs
                  tabs={tabs}
                  selected={tabSelected}
                  onSelect={(value) =>
                    handleChangeStates(
                      value,
                      setTabSelected,
                      "tab_selected",
                      false
                    )
                  }
                >
                  <PedidosClienteList
                    createdAt={createdAt}
                    selectedCreatedAt={{
                      start: new Date(selectedCreatedAt.start),
                      end: new Date(selectedCreatedAt.end),
                    }}
                    setSelectedCreatedAt={(value) =>
                      handleChangeStates(
                        value,
                        setSelectedCreatedAt,
                        "selected_dates"
                      )
                    }
                    handleCreatedAtChange={(_, value) =>
                      handleChangeStates(
                        value,
                        setCreatedAt,
                        "created_at",
                        false
                      )
                    }
                    handleDateRemove={() =>
                      handleChangeStates(
                        "todos",
                        setCreatedAt,
                        "created_at",
                        false
                      )
                    }
                    tabSelected={tabSelected}
                    orden={orden}
                    orderSelected={sort}
                    pedidos={pedidos}
                    isLoading={isLoading}
                    pagination={pagination}
                    queryValue={decodeURIComponent(queryValue)}
                    estatusPedido={estatusPedido}
                    vendorPedido={vendorPedido}
                    creatorSelected={creatorSelected}
                    shippingMethod={shippingMethod}
                    handleOrdenChange={(value) =>
                      handleChangeStates(value, setOrder, "order")
                    }
                    handleOrderSelectedChange={(value) =>
                      handleChangeStates(value, setSort, "sort")
                    }
                    handleOrderSelectedRemove={() =>
                      handleChangeStates(["folio"], setSort, "sort")
                    }
                    handleFiltersQueryChange={(value) =>
                      handleChangeStates(
                        value ? encodeURIComponent(value) : value,
                        setQueryValue,
                        "query",
                        false
                      )
                    }
                    handleQueryValueRemove={() =>
                      handleChangeStates("", setQueryValue, "query", false)
                    }
                    handleEstatusPedidoChange={(value) =>
                      handleChangeStates(
                        value,
                        setEstatusPedido,
                        "status",
                        true,
                        true
                      )
                    }
                    handleEstatusPedidoRemove={() =>
                      handleChangeStates(
                        [],
                        setEstatusPedido,
                        "status",
                        true,
                        true
                      )
                    }
                    handleVendorPedidoChange={(value) =>
                      handleChangeStates(value, setVendorPedido, "vendors")
                    }
                    handleVendorPedidoRemove={() =>
                      handleChangeStates([], setVendorPedido, "vendors")
                    }
                    handleShippingMethodChange={(value) =>
                      handleChangeStates(value, setShippingMethod, "shipping")
                    }
                    handleShippingMethodRemove={() =>
                      handleChangeStates([], setShippingMethod, "shipping")
                    }
                    handleCreatorSelectedChange={(value) =>
                      handleChangeStates(
                        value,
                        setCreatorSelected,
                        "created_by"
                      )
                    }
                    handleCreatorSelectedRemove={() =>
                      handleChangeStates([], setCreatorSelected, "created_by")
                    }
                    disabledAddButton={
                      isForbiddenCreate || statusMicrosip === "V"
                    }
                  />
                </Tabs>
              );
            }}
          </PedidosContainer>
        </Card>
        <FooterHelp>
          Más información de <Link>pedidos</Link>
        </FooterHelp>
      </Page>
      <FooterCliente />
    </Layer>
  );
}
