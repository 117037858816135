import React, { useState, useCallback, useEffect } from "react";
import {
  ResourceList,
  Filters,
  ChoiceList,
  EmptyState,
  TextStyle,
  Button,
  Checkbox,
  Card,
  TextField,
  Icon,
  Tooltip,
  ResourceItem,
  Spinner,
} from "@shopify/polaris";
import { NoteMajor } from "@shopify/polaris-icons";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Table } from "antd";
import CustomBadge from "../CustomBadge";
import CustomPopover from "../CustomPopover";
import "./PedidosList.css";
import ChoiceDate from "../ChoiceDate/ChoiceDate";
import { changePage } from "../../actions/PedidosActions";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import useGetVendors from "../../hooks/useGetVendors";
import { NumberFormat } from "../../utils/Formats";
import SkeletonTable from "../SkeletonTable";
import {
  ORDER_BY,
  CREATED_BY,
  ORDERS_PROPS,
} from "../../../app/utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { getAuthData } from "../../reducers";
import AntPagination from "../AntPagination";

export default function PedidosList({
  orderSelected = [],
  handleOrderSelectedChange = () => {},
  handleOrderSelectedRemove = () => {},
  pagination = {},
  pedidos = [],
  isLoading = false,
  queryValue = "",
  orden = [],
  handleFiltersQueryChange = () => {},
  handleQueryValueRemove = () => {},
  estatusPedido = [],
  vendorPedido = [],
  shippingMethod = [],
  creatorSelected = [],
  handleCreatorSelectedChange = () => {},
  handleCreatorSelectedRemove = () => {},
  handleOrdenChange = () => {},
  handleEstatusPedidoChange = () => {},
  handleEstatusPedidoRemove = () => {},
  handleVendorPedidoChange = () => {},
  handleVendorPedidoRemove = () => {},
  handleShippingMethodChange = () => {},
  handleShippingMethodRemove = () => {},
  createdAt,
  handleDateRemove = () => {},
  handleCreatedAtChange = () => {},
  selectedCreatedAt,
  setSelectedCreatedAt = () => {},
  taggedWith = "",
  handleTaggedWithChange = () => {},
  handleTaggedWithRemove = () => {},
  updateTextContact = () => {},
  inputValueClient = "",
  clientOptions = [],
  clientSelected = [],
  deselectedClients = { current: [] },
  handleClientChange = () => {},
  loadingClients = false,
  type = "orders",
}) {
  const dispatch = useDispatch();
  const defaultProps = ORDERS_PROPS[type];
  const router = useHistory();
  const [selectedItems, setSelectedItems] = useState([]);
  const [envios, setEnvios] = useState([]);
  const { vendors } = useGetVendors();
  let { grant_type } = useSelector(getAuthData);

  const handleFiltersClearAll = useCallback(() => {
    handleEstatusPedidoRemove();
    handleVendorPedidoRemove();
    handleShippingMethodRemove();
    handleQueryValueRemove();
    handleOrderSelectedRemove();
    handleCreatorSelectedRemove();
    handleTaggedWithRemove();
    updateTextContact("");
    handleClientChange([]);
  }, [
    handleQueryValueRemove,
    handleEstatusPedidoRemove,
    handleVendorPedidoRemove,
    handleShippingMethodRemove,
    handleOrderSelectedRemove,
    handleCreatorSelectedRemove,
    handleTaggedWithRemove,
    updateTextContact,
    handleClientChange,
  ]);

  useEffect(() => {
    ApiServiceComercios.obtenerEnvios()
      .then((result) => {
        if (result.ok) {
          setEnvios(result.envios);
        }
      })
      .catch((err) => {});
    return () => null;
  }, []);

  function handleSelectAll(values = []) {
    setSelectedItems(values);
  }
  function handleSelect(selected, val) {
    if (selected) {
      setSelectedItems((state) => [...new Set(state.concat(val))]);
    } else {
      setSelectedItems(selectedItems.filter((sel) => val !== sel));
    }
  }

  function setStatus(envio) {
    const status = {
      Pendiente: "incomplete",
      Surtido: "complete",
      Cancelado: undefined,
      Cerrado: "complete",
    };
    return status[envio];
  }

  function navigate(id) {
    router.push(`${defaultProps.url}/${id}`);
  }

  let appliedFilters = [];
  if (!isEmpty(clientSelected)) {
    const key = "cliente";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, clientSelected),
      onRemove: () => handleClientChange([]),
    });
  }
  if (!isEmpty(shippingMethod)) {
    const key = "shippingMethod";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, shippingMethod),
      onRemove: handleShippingMethodRemove,
    });
  }

  if (!isEmpty(estatusPedido)) {
    const key = "estatusPedido";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, estatusPedido),
      onRemove: handleEstatusPedidoRemove,
    });
  }

  if (!isEmpty(creatorSelected)) {
    const key = "createdBy";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, creatorSelected),
      onRemove: handleCreatorSelectedRemove,
    });
  }

  if (!isEmpty(createdAt)) {
    const key = "dateStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, createdAt),
      onRemove: handleDateRemove,
    });
  }

  if (!isEmpty(vendorPedido)) {
    const key = "vendorPedido";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, vendorPedido),
      onRemove: handleVendorPedidoRemove,
    });
  }

  if (!isEmpty(taggedWith)) {
    const key = "taggedWith";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  const emptyStateMarkup = (
    <EmptyState
      heading={defaultProps.emptyHeading}
      action={{
        content: defaultProps.emptyContent,
        url: `${defaultProps.url}/agregar`,
      }}
      image="/emptystate-files-n.png"
    >
      <p>{defaultProps.emptyBody}</p>
    </EmptyState>
  );

  let filters = [
    {
      key: "cliente",
      label: "Cliente",
      filter: (
        <>
          <TextField
            clearButton
            onClearButtonClick={() => updateTextContact("")}
            onChange={updateTextContact}
            value={inputValueClient}
            placeholder="Busca un cliente"
            autoComplete="off"
          />
          <div className="my-6">
            <ChoiceList
              title="Cliente"
              titleHidden
              choices={clientOptions}
              selected={clientSelected}
              onChange={handleClientChange}
              allowMultiple
            />
            {loadingClients && (
              <div className="flex justify-center">
                <Spinner size="small" />
              </div>
            )}
          </div>
        </>
      ),
      shortcut: true,
    },
    {
      key: "estatusPedido",
      label: "Estatus",
      filter: (
        <ChoiceList
          title="Estatus"
          titleHidden
          choices={defaultProps.choicesStatus}
          selected={estatusPedido || []}
          onChange={handleEstatusPedidoChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "shippingMethod",
      label: "Vía de embarque",
      filter: (
        <ChoiceList
          title="Vía de embarque"
          titleHidden
          choices={envios.map((envio) => ({
            label: envio.nombre,
            value: envio.nombre,
          }))}
          selected={shippingMethod || []}
          onChange={handleShippingMethodChange}
          allowMultiple
        />
      ),
    },
    {
      key: "createdBy",
      label: "Creado",
      filter: (
        <ChoiceList
          title="Creado"
          titleHidden
          choices={CREATED_BY}
          selected={creatorSelected || []}
          onChange={handleCreatorSelectedChange}
          allowMultiple
        />
      ),
      shortcut: false,
    },
    {
      key: "vendorPedido",
      label: "Vendedor",
      filter: (
        <ChoiceList
          title="Vendedor"
          titleHidden
          choices={vendors.map((vendor) => ({
            label: vendor.label,
            value: vendor.value,
          }))}
          selected={vendorPedido || []}
          onChange={handleVendorPedidoChange}
          allowMultiple
        />
      ),
    },
    {
      key: "taggedWith",
      label: "Etiquetado con",
      filter: (
        <TextField
          label="Etiquetado con"
          value={taggedWith}
          onChange={handleTaggedWithChange}
        />
      ),
      shortcut: false,
    },
    {
      key: "orderSelected",
      label: "Ordenar",
      filter: (
        <div className="flex flex-col">
          <ChoiceList
            title="Ordenar"
            titleHidden
            choices={ORDER_BY}
            selected={orderSelected || []}
            onChange={handleOrderSelectedChange}
          />
          <div className="flex w-full border border-t-1 border-b-0 border-r-0 border-l-0 border-gray-400">
            <ChoiceList
              title="Ordenar"
              titleHidden
              choices={[
                { label: "Ascendente", value: "1" },
                { label: "Descendente", value: "-1" },
              ]}
              selected={orden}
              onChange={handleOrdenChange}
            />
          </div>
        </div>
      ),
      shortcut: true,
    },
    {
      shortcut: false,
      key: "dateStatus",
      label: "Fecha",
      filter: (
        <ChoiceDate
          value={createdAt}
          handleChange={handleCreatedAtChange}
          selectedDates={selectedCreatedAt}
          setSelectedDates={setSelectedCreatedAt}
          past
        />
      ),
    },
  ];

  filters =
    grant_type === "AGENT"
      ? filters.filter((filter) => filter.key !== "vendorPedido")
      : filters;

  return (
    <div>
      <Card>
        <Card.Section>
          <Filters
            queryPlaceholder={defaultProps.placeholder}
            queryValue={queryValue}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
            filters={filters}
            appliedFilters={appliedFilters}
          />
        </Card.Section>
        <div>
          <div className="selectable-items">
            <ResourceList
              totalItemsCount={pagination.total}
              resourceName={defaultProps.resourceName}
              items={pedidos}
              selectable
              selectedItems={selectedItems}
              onSelectionChange={handleSelectAll}
              renderItem={(_) => <ResourceItem />}
            />
          </div>
          {isLoading ? (
            <SkeletonTable hasCheckbox headingsCount={5} rowsCount={5} />
          ) : (
            <div className="ant-table-styles-order">
              <Table
                rowKey="id"
                dataSource={pedidos}
                scroll={{ scrollToFirstRowOnChange: true, x: "100%" }}
                pagination={false}
                locale={{ emptyText: emptyStateMarkup }}
                columns={[
                  {
                    title: defaultProps.title,
                    dataIndex: "folio",
                    render: (folio, { _id, notas }) => (
                      <div className="flex flex-row items-center">
                        <div className="pl-6">
                          <Checkbox
                            value={_id}
                            checked={selectedItems.indexOf(_id) >= 0}
                            onChange={(val) => handleSelect(val, _id)}
                          />
                        </div>
                        <div
                          className="py-6 pr-6 h-full text-link"
                          onClick={() => navigate(_id)}
                        >
                          <TextStyle variation="strong">{folio}</TextStyle>
                        </div>
                        {notas && (
                          <div>
                            <Tooltip content={notas}>
                              <Icon color="subdued" source={NoteMajor} />
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    ),
                  },
                  {
                    width: 170,
                    title: "Fecha",
                    dataIndex: "createdDate",
                    render: (date, { _id }) => (
                      <div
                        className="w-full h-full cursor-pointer p-6 whitespace-nowrap"
                        onClick={() => navigate(_id)}
                      >
                        <TextStyle>
                          {moment(date).format("D MMM")} a las{" "}
                          {moment(date).format("HH:mm")}
                        </TextStyle>
                      </div>
                    ),
                  },
                  {
                    title: "Cliente",
                    dataIndex: "cliente",
                    render: (cliente, { _id }) => (
                      <div
                        className="w-full h-full cursor-pointer p-6 whitespace-nowrap"
                        onClick={() => navigate(_id)}
                      >
                        <TextStyle>{cliente?.nombre || "--"}</TextStyle>
                      </div>
                    ),
                  },
                  {
                    title: "Total",
                    dataIndex: "total",
                    render: (total, { _id }) => (
                      <div
                        className="w-full h-full cursor-pointer p-6 whitespace-nowrap"
                        onClick={() => navigate(_id)}
                      >
                        <TextStyle>
                          ${Number(total).toLocaleString("en", NumberFormat)}
                        </TextStyle>
                      </div>
                    ),
                  },
                  {
                    title: "Estatus",
                    dataIndex: "estatus",
                    render: (estatus, { _id }) => (
                      <div
                        className="w-full h-full cursor-pointer p-6 whitespace-nowrap"
                        onClick={() => navigate(_id)}
                      >
                        <CustomBadge
                          status={estatus?.nombre || ""}
                          progress={setStatus(estatus?.nombre || "")}
                        />
                      </div>
                    ),
                  },
                  {
                    title: "Embarque",
                    dataIndex: "envio",
                    render: (envio, { _id }) => (
                      <div
                        className="w-full h-full cursor-pointer p-6 whitespace-nowrap"
                        onClick={() => navigate(_id)}
                      >
                        <TextStyle>
                          {envio
                            ? envio?.nombre?.length > 0
                              ? envio?.nombre
                              : "--"
                            : "--"}
                        </TextStyle>
                      </div>
                    ),
                  },
                  {
                    title: "Artículos",
                    dataIndex: "articulos",
                    render: (articulos) => {
                      const articles = articulos.map((articulo) => {
                        return {
                          content: articulo.articulo?.nombre || "",
                          onAction: () => {
                            router.push(
                              `/admin/articulos/${articulo.articulo._id}`
                            );
                          },
                        };
                      });
                      const Activator = ({ onClick }) => {
                        return (
                          <div className="no-underline text-gray-600 z-50 div-no-grap">
                            <Button
                              onClick={onClick}
                              disclosure
                              plain
                              textAlign="left"
                              monochrome
                            >
                              {" "}
                              {articulos.length} artículo
                              {`${articulos.length > 1 ? "s" : ""}`}
                            </Button>
                          </div>
                        );
                      };
                      return (
                        <div className="w-full h-full cursor-pointer p-6">
                          <CustomPopover
                            items={articles}
                            activator={Activator}
                          />
                        </div>
                      );
                    },
                  },
                ]}
              />
            </div>
          )}
        </div>
        <div className="flex justify-center py-6 pagination px-10">
          <AntPagination
            total={pagination.total}
            current={pagination.currentPage + 1}
            pageSize={pagination.sizePerPage}
            onChange={(page) => {
              dispatch(changePage(page - 1));
            }}
          />
        </div>
      </Card>
    </div>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "shippingMethod": {
        return value.map((val) => `Vía de embarque ${val}`).join(", ");
      }
      case "estatusPedido": {
        return value.map((val) => `Estatus ${val}`).join(", ");
      }
      case "createdBy": {
        return value.map((val) => `Creado en ${val}`).join(", ");
      }
      case "vendorPedido": {
        return `Vendedor ${vendors
          .filter((i) => value.some((j) => j === i.value))
          .map((i) => i.label)
          .join(", ")}`;
      }
      case "cliente": {
        return `Cliente ${deselectedClients.current
          .filter((client) => value.some((val) => val === client.value))
          .map((client) => client.label)
          .join(",")}`;
      }
      case "dateStatus": {
        return `${value}`;
      }
      case "taggedWith": {
        return `Etiquetado con ${value}`;
      }
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null || value === "todos";
    }
  }
}
