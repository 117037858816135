import React, { useState } from "react";
import { Card, Icon, Spinner, TextStyle } from "@shopify/polaris";
import { MobileCancelMajor } from "@shopify/polaris-icons";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import "./AnuncioCardStyles.css";
import { useDispatch } from "react-redux";
import { toggleToast } from "../../actions/InteractiveActions";

export default function AnuncioCard({ anuncio, onRead }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  function handleClick() {
    ApiServiceComercios.clickAnuncio(anuncio._id);
    window.open(anuncio.enlace);
  }

  function handleRead() {
    setIsLoading(true);
    ApiServiceComercios.marcarAnuncioLeido(anuncio._id)
      .then(({ message }) => {
        dispatch(toggleToast({ message }));
        onRead(anuncio._id);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

  return (
    <div className="my-10 anuncio-card">
      <Card
        sectioned
        footerActionAlignment="left"
        title={anuncio.titulo}
        secondaryFooterActions={
          anuncio.textButton
            ? [{ content: anuncio.textButton, onAction: handleClick }]
            : null
        }
        actions={[
          {
            content: isLoading ? (
              <Spinner size="small" />
            ) : (
              <Icon source={MobileCancelMajor} color="indigoDarker" />
            ),
            onAction: handleRead,
          },
        ]}
      >
        <TextStyle>{anuncio.descripcion}</TextStyle>
      </Card>
    </div>
  );
}
