import React, { useEffect, useState } from "react";
import { Autocomplete, Icon, Spinner } from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";

import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useDebounce from "../../../services/useDebounce";
import ApiServiceComercios from "../../../services/ApiServiceComercios";
import { toggleToast } from "../../../actions/InteractiveActions";
import { CustomTag } from "../../../components/";

export const CollectionsAutocomplete = ({ idProduct = "" }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [input, setInput] = useState("");
  const [collectionSelecteds, setCollectionSelecteds] = useState([]);
  const [collectionTags, setCollectionTags] = useState([]);
  const [options, setOptions] = useState([]);

  const query = useDebounce(input);

  const getCollections = () => {
    setIsUpdating(true);
    ApiServiceComercios.obtenerArticulo(idProduct).then(({ collections }) => {
      setCollectionSelecteds(collections?.map(({ _id }) => _id));

      setCollectionTags(
        collections.map(({ _id, name, type }) => ({
          label: name,
          value: _id,
          type,
        }))
      );

      setIsUpdating(false);
    });
  };

  useEffect(() => {
    getCollections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoading(true);

    ApiServiceComercios.getCollections({
      query,
      limit: 40,
      type: JSON.stringify(["manual"]),
    }).then(({ collections }) => {
      const opts = collections.map(({ name, _id }) => ({
        label: name,
        value: _id,
      }));

      setOptions(opts);
      setIsLoading(false);
    });
  }, [query]);

  const updateCollectionsSelected = (newArr) => {
    setIsLoading(true);
    setIsUpdating(true);
    ApiServiceComercios.actualizarArticulo(
      {
        collections: newArr,
      },
      id
    ).then((_) => {
      setIsLoading(false);
      setIsUpdating(false);
      getCollections();
      dispatch(
        toggleToast({
          message: "Colección actualizado correctamente",
        })
      );
    });
  };

  const removeTag = (id) => {
    const newArr = collectionSelecteds.filter((item) => item !== id);
    updateCollectionsSelected(newArr);
  };

  const updateSelect = (value) => {
    updateCollectionsSelected(value);
  };

  const textFieldAutocomplete = (
    <Autocomplete.TextField
      label="Colecciones"
      value={input}
      onChange={setInput}
      autoComplete="off"
      prefix={<Icon source={SearchMinor} />}
      placeholder="Selecciona una colección"
    />
  );

  return (
    <div>
      <Autocomplete
        textField={textFieldAutocomplete}
        options={options}
        selected={collectionSelecteds}
        allowMultiple
        onSelect={updateSelect}
        loading={isLoading}
      />

      <div className="flex flex-wrap gap-4 mt-4">
        {isUpdating ? (
          <Spinner size="small" />
        ) : (
          <>
            {collectionTags.map(({ value, label, type }) => (
              <div>
                <CustomTag
                  key={value}
                  id={value}
                  name={label}
                  type={type}
                  showButton={type === "manual"}
                  onClick={() => removeTag(value)}
                />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
