import React from 'react';
import {
    LineChart, Line
} from 'recharts';
import {
    Layout,
    Card,
    TextStyle,
    Subheading,
    DisplayText,
    Button,
    Icon
} from '@shopify/polaris';
import {
    CaretDownMinor
} from '@shopify/polaris-icons';

import Colors from '../../styles/Colors';

const data = [
    {
      name: 'Page A', pv: 2400, amt: 2400,
    },
    {
      name: 'Page B', pv: 1398, amt: 2210,
    },
    {
      name: 'Page C', pv: 9800, amt: 2290,
    },
    {
      name: 'Page D', pv: 908, amt: 2000,
    },
    {
      name: 'Page E', pv: 1800, amt: 2181,
    },
    {
      name: 'Page F', pv: 10800, amt: 2500,
    },
    {
      name: 'Page G', pv: 2000, amt: 2100,
    },
  ];

const ReportItem = ({title, subtitle, showAllAction, actions, total, heading}) => {
    return (
        <Layout.Section fullWidth>
            <Card sectioned> 
                <Card.Section title={title}>
                    <TextStyle>{subtitle}</TextStyle>
                    <div className="cardReport">
                        <Subheading>{heading}</Subheading>
                        <div className="my-10 flex justify-between full-width">
                            <DisplayText element="h1" size="large">{total}</DisplayText>
                            <LineChart
                                width={190}
                                height={32}
                                data={data}
                                margin={{
                                top: 5, right: 30, left: 20, bottom: 5,
                                }}
                            >
                                <Line type="linear" dataKey="pv" dot={false} stroke={Colors.darkPurple} activeDot={false} />
                            </LineChart>

                        </div>
                    </div>
                </Card.Section>
                {actions.map((item, index) => (
                    <Card.Section key={index}>
                        <div className="flex justify-between">
                            <Button plain onClick={item.action}>{item.label}</Button>
                            <TextStyle variation="subdued">{item.secondary}</TextStyle>
                        </div>
                    </Card.Section>

                ))}
                <Card.Section>
                    <div className="flex justify-center">
                        <Button plain onClick={showAllAction}>
                            <div className="flex justify-center items-center">
                                Mostrar todo <Icon source={CaretDownMinor}/>
                            </div>
                        </Button>
                    </div>
                </Card.Section>
            </Card>
        </Layout.Section>
    );
}
 
export default ReportItem;  