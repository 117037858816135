import React, { useCallback, useState } from "react";
import { Card, DataTable, EmptyState } from "@shopify/polaris";
import PackagingKeysModal from "./PackagingKeysModal";

const PackagingKeys = ({ packingKeys = [], setPackingKeys, setIsDirty }) => {
  const packingKeysStatic = packingKeys;
  const [active, setActive] = useState(false);
  const [keyname, setKeyname] = useState("");
  const [role, setRole] = useState("");
  const [quantity, setQuantity] = useState("0");
  const [fieldErrors, setFieldErrors] = useState({
    keyname: "",
  });

  const handleModalChange = useCallback(() => {
    setActive(!active);
    setKeyname("");
    setRole("");
    setQuantity("0");
  }, [active]);

  const handleAddPackageKey = useCallback(() => {
    if (keyname && role && quantity) {
      setIsDirty(true);
      packingKeysStatic.push({
        clave: keyname,
        role: role,
        quantity: Number(quantity),
      });
      setPackingKeys(packingKeysStatic);
      handleModalChange();
    }
  }, [
    keyname,
    role,
    quantity,
    packingKeysStatic,
    setPackingKeys,
    setIsDirty,
    handleModalChange,
  ]);

  const handleSetKeyName = (val) => {
    const exists = packingKeys.some((i) => i.clave === val);
    if (exists) {
      setFieldErrors((s) => ({
        ...s,
        keyname: "Ya existe esta clave en este producto",
      }));
    } else {
      setFieldErrors((s) => ({ ...s, keyname: "" }));
    }
    setKeyname(val);
  };

  return (
    <Card
      sectioned
      title="Empaques"
      actions={[{ content: "Agregar", onAction: handleModalChange }]}
    >
      {active && (
        <PackagingKeysModal
          fieldErrors={fieldErrors}
          active={active}
          packingKeysStatic={packingKeysStatic}
          keyname={keyname}
          role={role}
          quantity={quantity}
          setKeyname={handleSetKeyName}
          setRole={setRole}
          setQuantity={setQuantity}
          setActive={setActive}
          handleModalChange={handleModalChange}
          handleAddPackageKey={handleAddPackageKey}
          setPackingKeys={setPackingKeys}
        />
      )}
      <DataTable
        headings={["Clave", "Rol", "Contenido"]}
        columnContentTypes={["text", "text", "numeric"]}
        rows={packingKeys?.map(({ clave, quantity, role }) => [
          clave || "",
          role || "",
          quantity || "",
        ])}
      />
      {packingKeys.length === 0 && (
        <EmptyState heading="Sin claves de empaques">
          Indica el contenido del empaque en la unidad de medida del artículo.
        </EmptyState>
      )}
    </Card>
  );
};
export default PackagingKeys;
