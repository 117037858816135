import React, { useLayoutEffect } from "react";
import { Badge, Navigation } from "@shopify/polaris";
import {
  HomeMajor,
  SettingsMajor,
  CashDollarMajor,
  OrdersMajor,
  ProductsMajor,
  CustomersMajor,
  BalanceMajor,
  BillingStatementDollarMajor,
  ConfettiMajor,
  DiscountsMajor,
  OnlineStoreMajor,
  DraftOrdersMajor,
  UnfulfilledMajor,
  ProfileMinor,
} from "@shopify/polaris-icons";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import ability from "../../auth/ability";
import { getAuthData, getPermissions } from "../../reducers";
import useWindowSize from "../../hooks/useWindowSize";
import SidebarClient from "../SidebarClient";

import "../../styles/Sidebar.css";

export default function Sidebar() {
  const { grant_type } = useSelector(getAuthData);
  const size = useWindowSize();
  const permissions = useSelector(getPermissions);
  const navigate = useHistory();

  let location = useLocation();

  const comercioRoutes = [
    {
      label: "Inicio",
      icon: HomeMajor,
      url: "/admin",
      selected: ["/admin"].indexOf(location.pathname) >= 0,
      can: "Inicio",
      subNavigationItems: [],
    },
    {
      label: "Oportunidades",
      icon: UnfulfilledMajor,
      url: "/admin/oportunidades",
      selected:
        ["/admin/oportunidades", "/admin/oportunidades/agregar"].indexOf(
          location.pathname
        ) >= 0 || location.pathname.indexOf("admin/oportunidades/") >= 0,
      can: "Oportunidades",
      subNavigationItems: [],
    },
    {
      label: "Cotizaciones",
      icon: DraftOrdersMajor,
      url: "/admin/cotizaciones",
      selected:
        ["/admin/cotizaciones", "/admin/cotizaciones/agregar"].indexOf(
          location.pathname
        ) >= 0 || location.pathname.indexOf("admin/cotizaciones/") >= 0,
      can: "Cotizaciones",
      subNavigationItems: [],
    },
    {
      label: "Pedidos",
      icon: OrdersMajor,
      url: "/admin/pedidos",
      onClick: () => {
        navigate.push("/admin/pedidos");
      },
      selected:
        location.pathname.indexOf("preliminares") >= 0 ||
        location.pathname.indexOf("pedidos") >= 0,
      can: "Pedidos",
      subNavigationItems: [
        {
          url: "/admin/pedidos/preliminares",
          label: "Preliminares",
          selected: location.pathname.indexOf("preliminares") >= 0,
          disabled: false,
          can: "Preliminares",
          badge: <Badge status="info">Nuevo</Badge>,
        },
      ],
    },
    {
      label: "Artículos",
      icon: ProductsMajor,
      url: "/admin/articulos",
      onClick: () => {
        navigate.push("/admin/articulos");
      },
      selected:
        ["/admin/articulos", "/admin/articulos/colecciones"].indexOf(
          location.pathname
        ) >= 0 || location.pathname.indexOf("admin/articulos") >= 0,
      can: "Artículos",
      subNavigationItems: [
        {
          url: "/admin/articulos/colecciones",
          label: "Colecciones",
          selected:
            ["/admin/articulos/colecciones"].indexOf(location.pathname) >= 0,
          disabled: false,
          can: "Colecciones",
        },
      ],
    },
    {
      label: "Clientes",
      icon: CustomersMajor,
      url: "/admin/clientes",
      selected:
        ["/admin/clientes", "/admin/clientes/agregar"].indexOf(
          location.pathname
        ) >= 0 || location.pathname.indexOf("admin/clientes") >= 0,
      can: "Clientes",
      subNavigationItems: [],
    },

    {
      label: "Pagos",
      icon: CashDollarMajor,
      url: "/admin/pagos",
      selected: ["/admin/pagos"].indexOf(location.pathname) >= 0,
      can: "Pagos",
      subNavigationItems: [],
    },
    {
      label: "Descuentos",
      icon: DiscountsMajor,
      url: "/admin/descuentos",
      selected:
        [
          "/admin/descuentos",
          "/admin/descuentos/agregar-by-producto",
          "/admin/descuentos/agregar-by-cliente",
          "/admin/descuentos/agregar-by-promocion",
          "/admin/descuentos/agregar-by-volumen",
          "/admin/descuentos/agregar-by-maximo",
        ].indexOf(location.pathname) >= 0 ||
        location.pathname.indexOf("admin/descuentos") >= 0,
      can: "Descuentos",
      subNavigationItems: [],
    },
    {
      label: "Anuncios",
      icon: ConfettiMajor,
      url: "/admin/anuncios",
      selected:
        ["/admin/anuncios", "/admin/anuncios/agregar"].indexOf(
          location.pathname
        ) >= 0 || location.pathname.indexOf("admin/anuncios") >= 0,
      can: "Anuncios",
      subNavigationItems: [],
    },
  ];

  const comercioSecondRoutes = [
    {
      label: "Tienda en línea",
      url: "/admin/tienda_online/navegacion",
      onClick: () => {
        navigate.push("/admin/tienda_online/navegacion");
      },
      icon: OnlineStoreMajor,
      selected:
        [
          "/admin/tienda_online/navegacion",
          "/admin/tienda_online/preferencias",
          "/admin/tienda_online/personalizar",
          "/admin/tienda_online/filtros",
          "/admin/tienda_online/menus",
        ].indexOf(location.pathname) >= 0,
      subNavigationItems: [
        {
          url: "/admin/tienda_online/navegacion",
          label: "Navegación",
          selected:
            [
              "/admin/tienda_online/navegacion",
              "/admin/tienda_online/filtros",
              "/admin/tienda_online/menus",
            ].indexOf(location.pathname) >= 0,
          can: "Navegación",
        },
        {
          url: "/admin/tienda_online/preferencias",
          label: "Preferencias",
          selected:
            ["/admin/tienda_online/preferencias"].indexOf(location.pathname) >=
            0,
          can: "Preferencias",
        },
        {
          url: "/admin/tienda_online/personalizar",
          label: "Personalizar",
          selected:
            ["/admin/tienda_online/personalizar"].indexOf(location.pathname) >=
            0,
          can: "Personalizar",
        },
      ],
      can: "Tienda en línea",
    },
  ];

  const clienteRoutes = [
    {
      label: "Inicio",
      icon: HomeMajor,
      url: "/cliente",
      selected: ["/cliente"].indexOf(location.pathname) >= 0,
      can: "Inicio",
      subNavigationItems: [],
    },
    {
      label: "Cotizaciones",
      icon: DraftOrdersMajor,
      url: "/cliente/cotizaciones",
      selected:
        ["/cliente/cotizaciones", "/cliente/cotizaciones/agregar"].indexOf(
          location.pathname
        ) >= 0 || location.pathname.indexOf("cliente/cotizaciones/") >= 0,
      can: "Cotizaciones",
      subNavigationItems: [],
    },
    {
      label: "Pedidos",
      icon: OrdersMajor,
      url: "/cliente/pedidos",
      onClick: () => {
        navigate.push("/cliente/pedidos");
      },
      selected:
        location.pathname.indexOf("preliminares") >= 0 ||
        location.pathname.indexOf("pedidos") >= 0,
      can: "Pedidos",
      subNavigationItems: [
        {
          url: "/cliente/pedidos/preliminares",
          label: "Preliminares",
          selected: location.pathname.indexOf("preliminares") >= 0,
          disabled: false,
          can: "Preliminares",
          badge: <Badge status="info">Nuevo</Badge>,
        },
      ],
    },
    {
      label: "Artículos",
      icon: ProductsMajor,
      url: "/cliente/articulos",
      selected:
        ["/cliente/articulos", "/cliente/articulos/agregar"].indexOf(
          location.pathname
        ) >= 0 || location.pathname.indexOf("cliente/articulos/") >= 0,
      can: "Artículos",
      subNavigationItems: [],
    },
    {
      label: "Estados de cuenta",
      icon: BalanceMajor,
      url: "/cliente/estado-de-cuenta",
      selected: ["/cliente/estado-de-cuenta"].indexOf(location.pathname) >= 0,
      can: "Estado de cuenta",
      subNavigationItems: [],
    },
    {
      label: "Facturas",
      icon: BillingStatementDollarMajor,
      url: "/cliente/facturas",
      selected: ["/cliente/facturas"].indexOf(location.pathname) >= 0,
      can: "Facturas",
      subNavigationItems: [],
    },
  ];

  useLayoutEffect(() => {
    setTimeout(() => {
      const main = document.querySelector(".Polaris-Frame__Main");
      if (main) {
        if (
          ["/cliente/articulos", "/cliente/articulos/agregar"].indexOf(
            location.pathname
          ) >= 0
        ) {
          if (size.width > 768) {
            main.style.paddingLeft = "60px";
          } else {
            main.style.paddingLeft = "0px";
          }
        }
        if (
          ["/admin/tienda_online/page-customization"].indexOf(
            location.pathname
          ) >= 0
        ) {
          if (size.width > 768) {
            main.style.paddingLeft = "0px";
            return;
          } else {
            main.style.paddingLeft = "0px";
            return;
          }
        }
      }
    }, 10);
  }, [location.pathname, size.width]);

  return (
    <>
      {["COMERCIO", "AGENT"].includes(grant_type) ? (
        location.pathname === "/admin/tienda_online/page-customization" ? (
          <></>
        ) : (
          <Navigation location="/">
            <Navigation.Section
              items={comercioRoutes
                .filter((i) => ability(permissions).can("read", i.can))
                .map((item) => ({
                  ...item,
                  subNavigationItems: item.subNavigationItems.filter(
                    (subiItem) => ability(permissions).can("read", subiItem.can)
                  ),
                }))}
              fill={
                comercioSecondRoutes.filter((i) =>
                  ability(permissions).can("read", i.can)
                ).length === 0
              }
            />
            {comercioSecondRoutes.filter((i) =>
              ability(permissions).can("read", i.can)
            ).length > 0 && (
              <Navigation.Section
                title="Canales de ventas"
                fill
                items={comercioSecondRoutes.filter((i) =>
                  ability(permissions).can("read", i.can)
                )}
              />
            )}
            <Navigation.Section
              items={[
                {
                  label: "Configuración",
                  icon: SettingsMajor,
                  url: "/admin/configuracion",
                  selected:
                    [
                      "/admin/historial",
                      "/admin/configuracion/sucursales",
                      "/admin/configuracion/sucursales/agregar",
                      "/admin/configuracion/envio-entregas",
                      "/admin/configuracion/envio-entregas/agregar",
                      "/admin/configuracion",
                      "/admin/configuracion/general",
                      "/admin/configuracion/permisos",
                      "/admin/configuracion/integraciones",
                      "/admin/configuracion/integracion/microsip",
                      "/admin/configuracion/paginas",
                      "/admin/configuracion/pagos",
                      "/admin/configuracion/impuestos",
                      "/admin/configuracion/impuestos/detalle",
                      "/admin/configuracion/chats",
                    ].indexOf(location.pathname) >= 0,
                  can: "Configuración",
                },
              ].filter((i) => ability(permissions).can("read", i.can))}
            />
          </Navigation>
        )
      ) : null}

      {["CLIENTE", "CONTACT"].includes(grant_type) &&
        (location.pathname === "/cliente/articulos" ? (
          <SidebarClient routes={clienteRoutes} />
        ) : (
          <Navigation location="/">
            <Navigation.Section
              fill
              items={clienteRoutes
                .filter((i) => ability(permissions).can("read", i.can))
                .map((item) => ({
                  ...item,
                  subNavigationItems: item.subNavigationItems.filter(
                    (subiItem) => ability(permissions).can("read", subiItem.can)
                  ),
                }))}
            />

            {grant_type === "CLIENTE" && (
              <Navigation.Section
                items={[
                  {
                    label: "Perfil",
                    icon: ProfileMinor,
                    url: "/cliente/perfil",
                  },
                ]}
              />
            )}
          </Navigation>
        ))}
    </>
  );
}
