import React from "react";
import {
  Card,
  EmptyState,
  Filters,
  ResourceItem,
  ResourceList,
} from "@shopify/polaris";
import { AntPagination, SkeletonTable } from "..";
import "./TableStyles.css";
import { Table } from "antd";
import { useSelector } from "react-redux";
import { getAuthData } from "../../reducers";
import { TABLE_VALUES } from "../../utils/constants";

export default function TableComponent({
  appliedFiltersData = [],
  columns,
  dataSource,
  emptyContent,
  filters,
  handleFiltersClearAll,
  handleFiltersQueryChange,
  handleQueryValueRemove,
  handleSelectAll,
  isLoading,
  pagination,
  queryValue,
  selectedItems,
  tableName,
  type,
  urlEmptyStateClient = "",
  urlEmptyStateShop = "",
  customEmptyState,
  onChangePage,
}) {
  const { grant_type } = useSelector(getAuthData);
  let appliedFilters = [];

  appliedFiltersData.forEach((i) => {
    if (!isEmpty(i.state)) {
      const key = i.label;
      appliedFilters.push({
        key,
        label: disambiguateLabel(key, i.state),
        onRemove: i.handleRemove,
      });
    }
  });

  const emptyStateMarkup =
    !isLoading && dataSource.length === 0 ? (
      <EmptyState
        heading={`Tus ${tableName} aparecerán aquí`}
        action={{
          content: emptyContent,
          url:
            grant_type === "COMERCIO" ? urlEmptyStateClient : urlEmptyStateShop,
        }}
        image="/emptystate-files-n.png"
      >
        <p>
          Aquí podrás ver los {tableName} de tus clientes y darle seguimiento.
        </p>
      </EmptyState>
    ) : undefined;

  const skeletonTable = isLoading ? (
    <SkeletonTable hasCheckbox headingsCount={5} rowsCount={5} />
  ) : null;

  return (
    <>
      <Card.Section>
        <Filters
          queryPlaceholder={`Buscar ${type}`}
          queryValue={queryValue}
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={handleQueryValueRemove}
          onClearAll={handleFiltersClearAll}
          filters={filters}
          appliedFilters={appliedFilters}
        />
      </Card.Section>
      <div className="selectable-items">
        <ResourceList
          selectable
          items={dataSource}
          onSelectionChange={handleSelectAll}
          selectedItems={selectedItems}
          totalItemsCount={pagination.total}
          resourceName={{
            singular: `${type}`,
            plural: TABLE_VALUES.find((i) => i.singular === type).plural,
          }}
          renderItem={(_) => <ResourceItem />}
        />
      </div>
      {skeletonTable}
      {!isLoading && (
        <div className="ant-table-styles-order">
          <Table
            pagination={false}
            rowKey="_id"
            rowClassName="w-full h-full cursor-pointer whitespace-nowrap p-6"
            columns={columns}
            dataSource={dataSource}
            scroll={{ scrollToFirstRowOnChange: true, x: "100%" }}
            locale={{ emptyText: customEmptyState || emptyStateMarkup }}
          />
        </div>
      )}
      <div className="flex justify-center py-6 border pagination px-10">
        <AntPagination
          total={pagination.total}
          current={pagination.currentPage + 1}
          pageSize={pagination.sizePerPage}
          onChange={(page) => {
            onChangePage(page - 1);
          }}
        />
      </div>
    </>
  );

  function disambiguateLabel(key, value) {
    const filter = appliedFiltersData.find((i) => i.label === key);
    if (filter["willMap"])
      return value?.map((val) => `${filter["secondLabel"]} ${val}`).join(", ");
    else return value;
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null || value === "todos";
    }
  }
}
