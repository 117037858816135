import React, { useState, useRef } from "react";
import {
  TextStyle,
  Checkbox,
  Collapsible,
  Avatar,
  Button,
  Icon,
  Tooltip,
} from "@shopify/polaris";
import { SmileyHappyMajor, AttachmentMajor } from "@shopify/polaris-icons";
import "./TimelineStyles.css";
import { Mentions, Upload } from "antd";
import "emoji-mart/css/emoji-mart.css";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import useToggle from "../../hooks/useToggle";

// import useDebounce from "../../services/useDebounce";
// import TimelineMention from "./TimelineMention";
// import TimelineHashtag from "./TimelineHashtag";
import TimelineEmoji from "./TimelineEmoji";
import TimelineMessages from "./TimelineMessages";
import { useEffect } from "react";
import DropzoneFiles from "../DropzoneFiles";

// const { Option } = Mentions;

export default function Timeline({
  visible,
  onChange,
  initials,
  parentMessageId = "",
  subtitle = "",
}) {
  const messageTextareaRef = useRef(null);
  const [message, setMessage] = useState("");
  const [popoverActive, togglePopoverActive] = useToggle(false);
  const [isFlexCol, setIsFlexCol] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [isPosting, setIsPosting] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    validateInputPlace(" ");
    ApiServiceComercios.getMessages(parentMessageId)
      .then(({ messages }) => {
        setMessages(messages);
      })
      .finally(() => {
        setIsLoading(false);
        validateInputPlace("");
      });
  }, [parentMessageId]);
  // const [initial, setInitial] = useState(true);
  // const [isLoading, setIsLoading] = useState(false);
  // const deselectedOptions = [
  //   { label: "label", _id: "1" },
  //   { label: "label", _id: "2" },
  // ];
  //const [messageHtml, setMessageHtml] = useState(``);
  // const [searchUserValue, setSearchUserValue] = useState("");
  // const [mentionsOptions, setMentionsOptions] = useState({
  //   "@": [
  //     { label: "label", _id: "1" },
  //     { label: "label", _id: "2" },
  //   ],
  // });
  // const [prefix, setPrefix] = useState("@");

  // const debouncedSearchTerm = useDebounce(searchUserValue, 900);

  // useEffect(() => onQueryEndChange(), [debouncedSearchTerm]);

  // const updateSearchUserValue = useCallback(
  //   (value) => {
  //     setSearchUserValue(value);
  //     if (value === "") {
  //       setMentionsOptions((state) => ({ ...state, "@": deselectedOptions }));
  //       return;
  //     }

  //     const filterRegex = new RegExp(value, "i");
  //     const resultOptions = deselectedOptions.filter((option) =>
  //       option.label.match(filterRegex)
  //     );
  //     setMentionsOptions((state) => ({ ...state, "@": resultOptions }));
  //   },
  //   [deselectedOptions]
  // );

  // useEffect(() => setInitial(false), []);

  const validateInputPlace = (value) => {
    setMessage(value);

    // Create element to calculate input size
    let text = document.createElement("span");
    document.body.appendChild(text);
    text.style.fontSize = 16 + "px";
    text.style.height = "auto";
    text.style.width = "auto";
    text.style.position = "absolute";
    text.style.whiteSpace = "no-wrap";
    text.innerHTML = value;
    let width = Math.ceil(text.clientWidth);
    let formattedWidth = width + "px";
    document.body.removeChild(text);

    const textBoxMessage = document.getElementById("textBoxMessage");
    const textBoxMessageSize = window.getComputedStyle(textBoxMessage).width;

    const textBoxActions = document.getElementById("textBoxActions");
    const textBoxActionsSize = window.getComputedStyle(textBoxActions).width;

    const isFlexColumn =
      Number(formattedWidth.split("px")[0]) >
      Number(textBoxMessageSize.split("px")[0]) -
        (Number(textBoxActionsSize.split("px")[0]) - 30);
    setIsFlexCol(isFlexColumn);
  };

  // const onQueryEndChange = () => {
  //   if (!initial) {
  //   }
  // };

  const onEmojiClick = (emojiObject) => {
    validateInputPlace(`${message} ${emojiObject.native} `);
    togglePopoverActive();
    messageTextareaRef.current.focus();
  };

  // const onSelectMention = (value, isFromSelectInText = false) => {
  //   const val = isFromSelectInText
  //     ? `${message}${value} `
  //     : `${message} ${value} `;
  //   validateInputPlace(val);

  //   const mentionSelected = isFromSelectInText ? `@${value}` : value;

  //   setNewText(val, mentionSelected);
  // };

  // const setNewText = (val = "", mentionSelected = "") => {
  //   if (mentionSelected) {
  //     val = [
  //       val.slice(0, val.indexOf(mentionSelected)),
  //       `<span><a href="/admin/equipos" className="py-1 px-2 text-white bg-purple-600 rounded hover:text-gray-400">`,
  //       val.slice(val.indexOf(mentionSelected)),
  //     ].join("");

  //     const valResultHtml = insertAtCursor(
  //       val,
  //       val.lastIndexOf(mentionSelected) + mentionSelected.length,
  //       "</a></span>"
  //     );
  //     setMessageHtml(valResultHtml);
  //   } else {
  //     setMessageHtml(val);
  //   }
  // };

  // // function to insert text at given position
  // const insertAtCursor = (text, position, newText) => {
  //   return text.slice(0, position) + newText + text.slice(position);
  // };

  const onRemoveImage = (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const onPostMessage = () => {
    setIsPosting(true);
    const formData = new FormData();

    let messageData = {
      message,
    };

    if (fileList.length > 0) {
      fileList.forEach((file) => formData.append("files", file));
      formData.append("message", JSON.stringify(messageData));
    }

    ApiServiceComercios.postMessage(
      messageData,
      parentMessageId,
      fileList.length > 0 ? formData : null
    )
      .then((result) => {
        const newMessages = [...messages];
        newMessages.push(result.message);
        setMessages(newMessages);
        setMessage("");
        setFileList([]);
      })
      .finally(() => {
        setIsPosting(false);
      });
  };

  const emojiActivator = (
    <Tooltip content="Agregar emoji" dismissOnMouseOut>
      <div className="timeLineActions">
        <Button onClick={togglePopoverActive} monochrome>
          <Icon source={SmileyHappyMajor} color="skyDark" backdrop={false} />
        </Button>
      </div>
    </Tooltip>
  );

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center px-8">
        <TextStyle variation="strong">Historial</TextStyle>
        <Checkbox
          label="Mostrar comentarios"
          checked={visible}
          onChange={onChange}
        />
      </div>
      <Collapsible
        open={visible}
        id="basic-collapsible"
        transition={{ duration: "150ms", timingFunction: "ease" }}
      >
        <div className="timelineDivider my-10 w-full"></div>
        <div className="timelineContainer flex items-start w-full py-2">
          <div className="mr-6">
            <Avatar initials={initials} customer={false} size="medium" />
          </div>
          <div
            id="textBoxMessage"
            className={`bg-gray-100 w-full flex ${
              isFlexCol || fileList.length > 0 ? "flex-col" : "flex-row"
            }`}
          >
            <Mentions
              ref={messageTextareaRef}
              id="messageText"
              placeholder="Escribe un comentario"
              value={message}
              autoSize
              onChange={(value) => validateInputPlace(value)}
              style={{ width: "100%" }}
              prefix={[]}
              // loading={isLoading}
              onSelect={(ment) => {
                //onSelectMention(ment.value, true);
              }}
              // notFoundContent={
              //   <TextStyle variation="subdued">
              //     No hay nadie para mencionar
              //   </TextStyle>
              // }
            >
              {/* {mentionsOptions[prefix].map(({ label, _id }) => {
                return (
                  <Option
                    key={_id}
                    value={label}
                    style={{
                      backgroundColor: "white",
                      padding: 0,
                      borderRadius: 3,
                    }}
                  >
                    <div
                      id={_id}
                      className="mx-6 my-4 flex flex-row items-center flex-wrap"
                    >
                      {prefix === "@" && (
                        <div className="mr-8">
                          <Avatar
                            size="medium"
                            initials={label
                              .match(/\b(\w)/g)
                              .join("")
                              .slice(0, 2)}
                          />
                        </div>
                      )}
                      <h3 className="flex flex-col">
                        <TextStyle variation="strong">{label}</TextStyle>
                      </h3>
                    </div>
                  </Option>
                );
              })} */}
            </Mentions>

            <DropzoneFiles
              files={fileList}
              hasDelete
              onDelete={onRemoveImage}
            />

            <div className="flex justify-between">
              <div></div>
              <div id="textBoxActions" className="flex justify-end">
                <TimelineEmoji
                  popoverActive={popoverActive}
                  emojiActivator={emojiActivator}
                  togglePopoverActive={togglePopoverActive}
                  onEmojiClick={onEmojiClick}
                />
                {/* Feature*/}
                {/* <TimelineMention
                  searchUserValue={searchUserValue}
                  updateSearchUserValue={updateSearchUserValue}
                  isLoading={isLoading}
                  mentionsOptions={mentionsOptions}
                  onSelectMention={onSelectMention}
                />

                <TimelineHashtag /> */}

                <Upload
                  listType="picture"
                  onRemove={(file) => {
                    const index = fileList.indexOf(file);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    setFileList(newFileList);
                  }}
                  beforeUpload={(file) => {
                    setFileList((state) => [...state, file]);
                    return false;
                  }}
                  fileList={fileList}
                  accept="image/*, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .docx, .doc"
                >
                  <div className="timeLineActions">
                    <Button monochrome>
                      <Icon
                        source={AttachmentMajor}
                        color="skyDark"
                        backdrop={false}
                      />
                    </Button>
                  </div>
                </Upload>
                <div>
                  <Button
                    disabled={!message || isPosting}
                    loading={isPosting}
                    monochrome
                    onClick={onPostMessage}
                  >
                    Publicar
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" timelineContainer flex justify-end mt-4">
          <TextStyle variation="subdued">{subtitle}</TextStyle>
        </div>
        <div className="timelineContainer">
          <TimelineMessages data={messages} loading={isLoading} />
        </div>
      </Collapsible>
    </div>
  );
}
