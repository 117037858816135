import React from 'react';
import { Popover, ActionList } from "@shopify/polaris";
import { TextStyle } from '@shopify/polaris';
import Flag from 'react-country-flag';
import {countryCodes} from '../../utils/CountryCodes';

export default function CountryPopover({active, toggleActive, activator, handleCallback, showCode}) {
   return (
      <Popover 
         active={active} 
         activator={activator} 
         onClose={toggleActive}
         preferredAlignment="left"
         preferredPosition="mostSpace"
      >
         <ActionList
            items={
               countryCodes.map((country) => {
                  return {
                        content: <div className="flex flex-row" key={country['COUNTRY CODE']}>
                           <Flag 
                              countryCode={country['ISO CODES'].split(' / ')[0]} 
                              style={{fontSize: '2em', marginRight: '4px'}}

                           />
                           <div className="flex">
                              <TextStyle>{country['COUNTRY']}</TextStyle>
                              {showCode && <div className="ml-4"><TextStyle variation="subdued">  (+{country['COUNTRY CODE']})</TextStyle></div>}
                           </div>
                        </div>,
                        onAction: () => handleCallback(country)
                     }  
               })
            }
         />
      </Popover>
   )
}
