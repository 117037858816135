import React from "react";
import {
  AnnouncementBarCardStyled,
  BannerSliderStyled,
  CollectionsListStyled,
  HeaderSectionStyled,
  OutstandingCollectionStyled,
  RichTextStyled,
  BannerImageStyled,
  VideoStyled,
  FooterStyled,
  ProductInformationStyled,
  BannerCollectionStyled,
  ProductsCollectionGridStyled,
  BannerProductStyled,
  NotFoundStyled,
} from ".";
import ProductsGridStyled from "./ProductsGridStyled";

export default function ChooseSectionsStyled({
  section = null,
  mode = "view",
}) {
  if (section?.type === "add-section") {
    return <></>;
  }
  if (section?.type === "announcement-bar") {
    return <AnnouncementBarCardStyled section={section} />;
  }
  if (section?.type === "heading") {
    return <HeaderSectionStyled section={section} />;
  }
  if (section?.type === "banner-slider") {
    return <BannerSliderStyled section={section} />;
  }
  if (section?.type === "collections-list") {
    return <CollectionsListStyled section={section} mode={mode} />;
  }
  if (section?.type === "rich-text") {
    return <RichTextStyled section={section} />;
  }
  if (section?.type === "outstanding-collection") {
    return <OutstandingCollectionStyled section={section} mode={mode} />;
  }
  if (section?.type === "banner-image") {
    return <BannerImageStyled section={section} />;
  }
  if (section?.type === "video") {
    return <VideoStyled section={section} />;
  }
  if (section?.type === "footer") {
    return <FooterStyled section={section} />;
  }
  if (section?.type === "product-information") {
    return <ProductInformationStyled section={section} mode={mode} />;
  }
  if (section?.type === "banner-collection") {
    return <BannerCollectionStyled section={section} mode={mode} />;
  }
  if (section?.type === "banner-product") {
    return <BannerProductStyled section={section} />;
  }
  if (section?.type === "products-collection-grid") {
    return <ProductsCollectionGridStyled section={section} mode={mode} />;
  }
  if (section?.type === "products-grid") {
    return <ProductsGridStyled section={section} mode={mode} />;
  }
  if (section?.type === "not-found") {
    return <NotFoundStyled section={section} mode={mode} />;
  }

  return <></>;
}
