import React from 'react';
import { 
    Subheading,
    TextStyle,
 } from "@shopify/polaris";

const DoubleText = ({title, subtitle}) => {
    return (
        <div className="flex flex-row items-center justify-between">
            <Subheading>{title}</Subheading>
            <TextStyle variation="subdued">{subtitle}</TextStyle>
        </div>
    );
}

export default DoubleText;
