import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Page,
  Layout,
  Card,
  TextField,
  Stack,
  Select,
  TextStyle,
  ContextualSaveBar,
  Thumbnail,
  DropZone,
  Caption,
  Banner,
  List,
  FormLayout,
  ButtonGroup,
  Button,
  Link,
  Spinner,
} from "@shopify/polaris";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { obtenerComercioConfig } from "../../actions/ComerciosActions";
import {
  Layer,
  ConfigGenComSkeleton,
  CountrySelect,
  HelpFooter,
} from "../../components";
import ComerciosContainer from "../../containers/ComerciosContainer";
import { Estados } from "../../utils/EstadosRepublica";
import { Timezone } from "../../utils/Timezone";
import { PhoneEx, EmailEx, rfcEx, IsLastNumber } from "../../utils/RegEx";
import { countryCodes } from "../../utils/CountryCodes";
import { INDUSTRIES_OPTIONS } from "../../utils/industries";

import { setTimezone } from "../../actions/AuthActions";
import { toggleToast } from "../../actions/InteractiveActions";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import useToggle from "../../hooks/useToggle";
import useDebounce from "../../services/useDebounce";

const estados = Estados.map((item) => {
  return { label: item, value: item };
});
const timezones = Timezone.map((item) => {
  return { label: `${item.codigo_zona} ${item.location}`, value: item.id };
});

export default function ComercioConfigGeneralScreen({ forbidden }) {
  const dispatch = useDispatch();
  const router = useHistory();

  const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];

  const emailContact = useRef(null);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [file, setFile] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);
  const [emailVerification, setEmailVerification] = useState({
    exist: false,
    isVerified: false,
    id: null,
  });
  const defaultValues = useRef({
    id: "",
    nombre: "",
    industria: "",
    zonaHoraria: "America/Mexico_City",
    contacto: { telefono: "", email: "", emailRemitente: "" },
    direccion: "",
    ciudad: "",
    codigo_postal: "",
    estado: "",
    pais: "México",
    prefijo: "",
    imagen: null,
    razon_social: "",
    rfc: "",
  });
  const [comercio, setComercio] = useState({ ...defaultValues.current });
  const [isDirty, setIsDirty] = useState(false);
  const [countryActive, toggleActive] = useToggle(false);
  const [comercioDB, setComercioDB] = useState(null);
  const [phoneActive, togglePhoneActive] = useToggle(false);
  const [selectedFlag, setSelectedFlag] = useState({
    country: { code: "MX", phone_code: "52" },
    phone: { code: "MX", phone_code: "52" },
  });
  const [fieldsError, setFieldsError] = useState({
    email: "",
    emailRemitente: "",
    rfc: "",
    prefijo: "",
  });
  const hasError = rejectedFiles.length > 0;

  const debouncedEmail = useDebounce(comercio.contacto.emailRemitente, 1000);

  useEffect(() => {
    emailContact.current = comercio.contacto.emailRemitente;
  });

  const fetchVerifiedEmail = useCallback((email = "", initial = true) => {
    if (email && !initial) {
      setIsLoadingEmail(true);
      ApiServiceComercios.obtenerComercioConfigEmail(encodeURIComponent(email))
        .then(({ isVerified = false, exist = false, id = null }) => {
          setEmailVerification({ exist, isVerified, id });
        })
        .catch((error) => error)
        .finally(() => setIsLoadingEmail(false));
    } else {
      setIsLoadingEmail(false);
    }
  }, []);

  useEffect(() => {
    fetchVerifiedEmail(emailContact.current, false);
  }, [debouncedEmail, fetchVerifiedEmail]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(obtenerComercioConfig())
      .then((res) => {
        const {
          _id,
          nombre,
          contacto = {},
          direccion,
          ciudad,
          codigo_postal,
          estado,
          pais,
          prefijo,
          imagen,
          zonaHoraria,
          industria,
          razon_social,
          rfc,
        } = res;

        const data = {
          id: _id,
          nombre,
          industria: industria || INDUSTRIES_OPTIONS[0].value,
          contacto: {
            ...contacto,
            telefono: contacto?.telefono?.split(" ")[1] || "",
          },
          direccion,
          ciudad,
          codigo_postal,
          estado,
          pais,
          prefijo,
          imagen,
          zonaHoraria,
          razon_social,
          rfc,
        };
        setComercioDB((state) => ({ ...state, ...data }));

        contacto.telefono = contacto.telefono || "52";
        contacto.telefono = contacto.telefono.split(" ")[0].replace("+", "");

        const country = countryCodes.find(
          (i) => i["COUNTRY CODE"] === contacto.telefono
        );

        const phone = countryCodes.find((i) => i["COUNTRY"] === pais);
        setSelectedFlag({
          phone: {
            code: country["ISO CODES"].split(" / ")[0] || "MX",
            phone_code: country["COUNTRY CODE"] || "52",
          },
          country: {
            code: phone["ISO CODES"].split(" / ")[0] || "MX",
            phone_code: phone["COUNTRY CODE"] || "52",
          },
        });
        setComercio((state) => ({ ...state, ...data }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch]);

  const handleFieldChange = useCallback((value, key) => {
    if (key === "telefono") {
      setComercio((state) => ({
        ...state,
        contacto: { ...state.contacto, [key]: value },
      }));
    } else {
      setComercio((state) => ({ ...state, [key]: value }));
    }
    value && setIsDirty(true);
  }, []);

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) => {
      setFile((file) => acceptedFiles[0]);
      setIsDirty(true);
      setRejectedFiles(_rejectedFiles);
    },
    []
  );

  const handleContactFieldChange = useCallback((value, key) => {
    if (key === "telefono") {
      if (PhoneEx.test(value)) {
        setComercio((state) => ({
          ...state,
          contacto: { ...state.contacto, [key]: value },
        }));
      }
    } else {
      setComercio((state) => ({
        ...state,
        contacto: { ...state.contacto, [key]: value },
      }));
    }
    value && setIsDirty(true);
  }, []);

  function handleEmailOnBlur(value, key) {
    if (!EmailEx.test(value)) {
      setFieldsError((state) => ({
        ...state,
        [key]: "Ingresa una dirección de correo electrónico válida",
      }));
    } else {
      setFieldsError((state) => ({ ...state, [key]: "" }));
    }
  }

  function handlePrefixOnBlur(value = "", key) {
    if (value.length === 0) {
      setFieldsError((state) => ({ ...state, [key]: "" }));
    } else {
      if (IsLastNumber.test(value.trim())) {
        setFieldsError((state) => ({
          ...state,
          [key]: "Ingresa un prefíjo válido terminando en letra",
        }));
      } else {
        setFieldsError((state) => ({ ...state, [key]: "" }));
      }
    }
  }

  function handleRFCOnBlur(value) {
    if (!rfcEx.test(value)) {
      setFieldsError((state) => ({ ...state, rfc: "Ingresa un RFC válido" }));
    } else {
      setFieldsError((state) => ({ ...state, rfc: "" }));
    }
  }

  function handleSave(callback, showMessage = true) {
    return new Promise((resolve, reject) => {
      const { email, emailRemitente, rfc, prefijo } = fieldsError;
      if (!email && !emailRemitente && !rfc && !prefijo) {
        const formData = new FormData();
        const comercioData = {
          ...comercio,
          contacto: {
            ...comercio.contacto,
            telefono: `+${selectedFlag.phone.phone_code}${
              comercio.contacto.telefono.length > 0 ? " " : ""
            }${comercio.contacto.telefono}`,
          },
        };
        if (file) {
          formData.append("files", file);
          formData.append("comercio", JSON.stringify(comercioData));
        }
        callback(
          { comercio: comercioData },
          comercio.id,
          file ? formData : null
        )
          .then(({ message }) => {
            setIsDirty(false);
            showMessage && dispatch(toggleToast({ message }));
            dispatch(setTimezone(comercio.zonaHoraria));
            resolve({ ok: true });
          })
          .catch(() => reject({ ok: false }));
      }
    });
  }

  function handleDiscard() {
    router.push("/admin/configuracion");
  }

  function handleSelectPhone(country) {
    setSelectedFlag((state) => ({
      ...state,
      phone: {
        code: country["ISO CODES"].split(" / ")[0],
        phone_code: country["COUNTRY CODE"],
      },
    }));
    togglePhoneActive();
    setIsDirty(true);
  }

  function handleSelectCountry(country) {
    setComercio((state) => ({ ...state, pais: country["COUNTRY"] }));
    setSelectedFlag((state) => ({
      ...state,
      country: {
        code: country["ISO CODES"].split(" / ")[0],
        phone_code: country["COUNTRY CODE"],
      },
    }));
    toggleActive();
    setIsDirty(true);
  }

  async function onHandleEmail(callback) {
    await handleSave(callback, false);
    router.push("/admin/configuracion/verifica-email", {
      email: comercio.contacto.emailRemitente,
    });
  }

  const fileUpload = !file && (
    <div className="flex my-6 justify-center">
      <Button primary outline>
        Agregar imagen
      </Button>
    </div>
  );
  const uploadedFile = file && (
    <Stack>
      <Thumbnail
        size="large"
        alt={file.name}
        source={
          validImageTypes.indexOf(file.type) >= 0
            ? window.URL.createObjectURL(file)
            : "/newPost.png"
        }
      />
      <div>
        {file.name} <Caption>{file.size} bytes</Caption>
      </div>
    </Stack>
  );
  const stackImage = comercioDB && comercioDB.imagen.url && (
    <Stack>
      <Thumbnail
        size="large"
        alt={comercioDB.imagen.nombre}
        source={comercioDB.imagen.url}
      />
      <div>{comercioDB.imagen.nombre}</div>
    </Stack>
  );

  const errorMessage = hasError && (
    <Banner
      title="Las siguientes imágenes no pudieron ser seleccionadas"
      status="critical"
    >
      <List type="bullet">
        {rejectedFiles.map((file, index) => (
          <List.Item key={index}>
            {`"${file.name}" no es soportado. El archivo debería de ser .jpeg, .jpg o .png`}
          </List.Item>
        ))}
      </List>
    </Banner>
  );

  if (isLoading) {
    return (
      <Layer title="Configuración general" forbidden={forbidden}>
        <ConfigGenComSkeleton />
      </Layer>
    );
  }

  return (
    <Layer title="Configuración general" forbidden={forbidden} scrollToTop>
      <Page
        breadcrumbs={[
          { content: "Configuración", url: "/admin/configuracion" },
        ]}
        title="General"
        separator
      >
        <ComerciosContainer>
          {({ modificarComercio, isEditing }) => {
            return (
              <>
                <Layout>
                  <Layout.AnnotatedSection
                    title="Detalles del comercio"
                    description="B2BGO usarán estos datos para conctactarte, se mostrará en el pié de tus páginas."
                  >
                    <Card>
                      <Card.Section>
                        <FormLayout>
                          <TextField
                            label="Nombre del comercio"
                            type="text"
                            placeholder="Shopify"
                            value={comercio.nombre}
                            onChange={(text) =>
                              handleFieldChange(text, "nombre")
                            }
                          />
                          <Select
                            label="Industria del comercio"
                            options={INDUSTRIES_OPTIONS}
                            value={comercio.industria}
                            onChange={(text) =>
                              handleFieldChange(text, "industria")
                            }
                          />
                          <div className="flex justify-between">
                            <TextField
                              disabled={isLoadingEmail}
                              suffix={
                                isLoadingEmail ? <Spinner size="small" /> : null
                              }
                              placeholder="contacto@mail.com"
                              label="Correo electrónico del remitente"
                              type="email"
                              value={comercio.contacto.emailRemitente}
                              helpText={
                                isLoadingEmail
                                  ? ""
                                  : !emailVerification.exist ||
                                    !emailVerification.isVerified
                                  ? ""
                                  : "Tus clientes verán esta dirección si les envías un correo electrónico."
                              }
                              onChange={(text) =>
                                handleContactFieldChange(text, "emailRemitente")
                              }
                              onBlur={() =>
                                handleEmailOnBlur(
                                  comercio.contacto.emailRemitente,
                                  "emailRemitente"
                                )
                              }
                              error={
                                fieldsError.emailRemitente ||
                                (isLoadingEmail ? (
                                  ""
                                ) : !emailVerification.exist ||
                                  !emailVerification.isVerified ? (
                                  <TextStyle variation="negative">
                                    Es posible que tus clientes vean
                                    admin@b2bgo.mx. {` `}
                                    <Link
                                      onClick={() =>
                                        onHandleEmail(modificarComercio)
                                      }
                                    >
                                      Corrígelo.
                                    </Link>
                                  </TextStyle>
                                ) : (
                                  ""
                                ))
                              }
                            />
                            <div className="mx-4" />
                            <TextField
                              label="Correo de contacto con B2Bgo"
                              type="email"
                              placeholder="contacto@mail.com"
                              value={comercio.contacto.email}
                              helpText="Usaremos este correo si necesitamos comunicarnos contigo."
                              onChange={(text) =>
                                handleContactFieldChange(text, "email")
                              }
                              onBlur={() =>
                                handleEmailOnBlur(
                                  comercio.contacto.email,
                                  "email"
                                )
                              }
                              error={fieldsError.email}
                            />
                          </div>
                        </FormLayout>
                      </Card.Section>
                    </Card>
                  </Layout.AnnotatedSection>
                  <Layout.AnnotatedSection
                    title="Dirección del comercio"
                    description="Esta dirección aparecerá en el pié de tu página."
                  >
                    <Card>
                      <Card.Section>
                        <FormLayout>
                          <Stack distribution="fillEvenly" wrap spacing="loose">
                            <div>
                              <TextField
                                label="Razón Social"
                                type="text"
                                value={comercio.razon_social}
                                onChange={(value) =>
                                  handleFieldChange(value, "razon_social")
                                }
                              />
                              {fieldsError.rfc && (
                                <div className="h-8" style={{ marginTop: 4 }} />
                              )}
                            </div>
                            <TextField
                              label="RFC"
                              type="text"
                              value={comercio.rfc}
                              onChange={(value) =>
                                handleFieldChange(value, "rfc")
                              }
                              onBlur={() => handleRFCOnBlur(comercio.rfc)}
                              error={fieldsError.rfc}
                            />
                          </Stack>
                          <CountrySelect
                            showCode
                            active={phoneActive}
                            handleSelect={handleSelectPhone}
                            selectedFlag={selectedFlag.phone}
                            toggleActive={togglePhoneActive}
                            title="Número de teléfono"
                          >
                            <TextField
                              maxLength={10}
                              type="tel"
                              value={comercio.contacto.telefono}
                              onChange={(text) =>
                                handleContactFieldChange(text, "telefono")
                              }
                            />
                          </CountrySelect>
                          <TextField
                            label="Dirección"
                            type="tel"
                            placeholder="Av."
                            value={comercio.direccion}
                            onChange={(text) =>
                              handleFieldChange(text, "direccion")
                            }
                          />
                          <Stack distribution="fill" wrap spacing="loose">
                            <TextField
                              label="Ciudad"
                              type="text"
                              placeholder="Mérida"
                              value={comercio.ciudad}
                              onChange={(text) =>
                                handleFieldChange(text, "ciudad")
                              }
                            />
                            <TextField
                              label="Código postal"
                              type="text"
                              placeholder="Ej. 42186"
                              value={comercio.codigo_postal}
                              onChange={(text) =>
                                handleFieldChange(text, "codigo_postal")
                              }
                            />
                          </Stack>
                          <Stack distribution="fillEvenly" wrap spacing="loose">
                            <Select
                              label="Estado"
                              options={estados}
                              value={comercio.estado}
                              placeholder="Selecciona un estado"
                              onChange={(text) =>
                                handleFieldChange(text, "estado")
                              }
                            />
                            <div className="mb-1">
                              <CountrySelect
                                title="País"
                                active={countryActive}
                                handleSelect={handleSelectCountry}
                                selectedCountry={comercio.pais}
                                selectedFlag={selectedFlag.country}
                                toggleActive={toggleActive}
                              />
                            </div>
                          </Stack>
                        </FormLayout>
                      </Card.Section>
                    </Card>
                  </Layout.AnnotatedSection>
                  <Layout.AnnotatedSection
                    title="Logotipo"
                    description="Cuando tus clientes entran a tu comercio, se muestra tu logotipo. También será integrado en tus correos electrónicos de confirmación de pedidos y en los descargables como pedidos y facturas."
                  >
                    <Card>
                      <Card.Section>
                        <FormLayout>
                          <TextStyle>
                            Tamaño recomendado 1200 x 628 px
                          </TextStyle>
                          {errorMessage}
                          <DropZone
                            type="image"
                            accept={validImageTypes.join(",")}
                            allowMultiple={false}
                            onDrop={handleDropZoneDrop}
                            outline={true}
                            overlay={false}
                            variableHeight
                          >
                            {uploadedFile}
                            {!file && stackImage}
                            {fileUpload}
                          </DropZone>
                        </FormLayout>
                      </Card.Section>
                    </Card>
                  </Layout.AnnotatedSection>
                  <Layout.AnnotatedSection
                    title="Zona horaria"
                    description="Determina la zona horaria de tu comercio. Servirá para el registro de los documentos y mostrar los anuncios a tus clientes."
                  >
                    <Card sectioned>
                      <Select
                        label="Huso horario"
                        options={timezones}
                        onChange={(value) =>
                          handleFieldChange(value, "zonaHoraria")
                        }
                        value={comercio.zonaHoraria}
                      />
                    </Card>
                  </Layout.AnnotatedSection>
                  <Layout.AnnotatedSection
                    title="Folio"
                    description="Los números de pedido comienzan por defecto en Nº 1001. Si bien no puedes cambiar el número del pedido en sí, puedes agregar un folio sin terminación en número."
                  >
                    <Card>
                      <Card.Section>
                        <TextField
                          label="Prefijo"
                          type="text"
                          placeholder=""
                          value={comercio.prefijo}
                          error={fieldsError.prefijo}
                          onChange={(text) =>
                            handleFieldChange(text, "prefijo")
                          }
                          onBlur={() =>
                            handlePrefixOnBlur(comercio.prefijo, "prefijo")
                          }
                        />
                      </Card.Section>
                    </Card>
                  </Layout.AnnotatedSection>
                </Layout>

                {isDirty ? (
                  <ContextualSaveBar
                    message="Cambios no guardados"
                    saveAction={{
                      onAction: () => handleSave(modificarComercio),
                      disabled:
                        !isDirty ||
                        fieldsError.email ||
                        fieldsError.emailRemitente ||
                        fieldsError.rfc ||
                        fieldsError.prefijo,
                      loading: isEditing,
                    }}
                    discardAction={{
                      onAction: handleDiscard,
                      disabled: isEditing,
                    }}
                  />
                ) : null}
                <div className="flex justify-end mt-8">
                  <ButtonGroup>
                    <Button disabled={isEditing} onClick={handleDiscard}>
                      Descartar
                    </Button>
                    <Button
                      primary
                      onClick={() => handleSave(modificarComercio)}
                      disabled={
                        !isDirty ||
                        fieldsError.email ||
                        fieldsError.emailRemitente ||
                        fieldsError.rfc ||
                        fieldsError.prefijo
                      }
                      loading={isEditing}
                    >
                      Guardar
                    </Button>
                  </ButtonGroup>
                </div>
              </>
            );
          }}
        </ComerciosContainer>
        <HelpFooter
          title="configuración general"
          url="https://help.b2bgo.mx/configuracion/general"
        />
      </Page>
    </Layer>
  );
}
