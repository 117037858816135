import {
  Button,
  Caption,
  Card,
  FormLayout,
  Modal,
  Stack,
  TextField,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";
import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";
import { urlEx } from "../../utils/RegEx";

export default function AddUrlImageModal({
  isActiveModal,
  setIsActiveModal,
  addImagesFromUrl,
  imagesFromUrl,
  imageFieldUrl,
  setImageFieldUrl,
  addImageItem,
  setImagesFilesFromUrl,
  videosFromUrl,
  setVideosFromUrl,
}) {
  const [errorUrl, setErrorUrl] = useState("");
  const [filesSelected, setFilesSelected] = useState([]);
  const [videoUrl, setVideoUrl] = useState([]);
  const player = useRef(null);

  function validateUrl() {
    if (!urlEx.test(imageFieldUrl)) {
      setErrorUrl("Agrega una url válida");
    } else {
      setErrorUrl("");
    }
  }

  function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open("get", url);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var file = new FileReader();
      file.onload = function () {
        callback(this.result);
      };
      file.readAsDataURL(xhr.response);
    };
    xhr.send();
  }

  async function urltoFile(url, filename, mimeType) {
    if (url.startsWith("data:")) {
      var arr = url.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      var file = new File([u8arr], filename, { type: mime || mimeType });
      return Promise.resolve(file);
    }
    const res = await fetch(url);
    const buf = await res.arrayBuffer();
    return new File([buf], filename, { type: mimeType });
  }

  function handleAdd() {
    const videosUrl = ["youtube", "vimeo"];
    const arrayVideos = [];
    videosUrl.forEach((videoUrl) => {
      if (imageFieldUrl.includes(videoUrl)) {
        arrayVideos.push(imageFieldUrl);
        setVideoUrl(arrayVideos);
        setVideosFromUrl(arrayVideos);
        filesSelected.filter((file) => file !== imageFieldUrl);
        setImageFieldUrl("");
      }
    });
    const now = new Date();
    const today = Date.parse(now);
    toDataURL(imageFieldUrl, function (dataURL) {
      urltoFile(dataURL, `image_${today}.png`, "image/png").then(function (
        file
      ) {
        const arr = filesSelected;
        arr.push(file);
        setFilesSelected(arr);
        setImagesFilesFromUrl(filesSelected);
        addImageItem();
      });
    });
  }

  return (
    <Modal
      open={isActiveModal}
      title="Agregar imagen por url"
      onClose={() => setIsActiveModal(false)}
      primaryAction={{
        content: "Continuar",
        onAction: addImagesFromUrl,
        disabled: imagesFromUrl.length === 0 && videosFromUrl.length === 0,
      }}
      secondaryActions={[
        {
          content: "Cancelar",
          onAction: () => setIsActiveModal(false),
        },
      ]}
    >
      <Card sectioned>
        <FormLayout>
          <TextStyle>Agrega la url de la imagen del producto</TextStyle>
          <TextField
            value={imageFieldUrl}
            placeholder="Agrega una URL válida"
            error={errorUrl}
            onChange={(value) => setImageFieldUrl(value)}
            connectedRight={
              <Button disabled={!imageFieldUrl || errorUrl} onClick={handleAdd}>
                Agregar
              </Button>
            }
            onBlur={validateUrl}
          />
          {imagesFromUrl.map((item, id) => {
            return (
              <div key={id}>
                <Stack alignment="center" key={id}>
                  <Thumbnail size="large" alt={item} source={item} />
                  <div>
                    <Caption>{item.slice(0, 50) + "..."}</Caption>
                  </div>
                </Stack>
              </div>
            );
          })}

          {videoUrl.map((item, id) => {
            return (
              <div key={id} className="flex flex-row gap-4">
                {/* <Stack alignment="center" key={id}> */}
                <div
                  className="relative border-2 rounded flex overflow-hidden"
                  style={{ height: 100, width: 170 }}
                >
                  <ReactPlayer
                    ref={player}
                    url={item}
                    height={"100%"}
                    width={"100%"}
                    config={{
                      youtube: { playerVars: { rel: 0, showinfo: 0 } },
                    }}
                    controls
                  />
                </div>
                <div>
                  <Caption>{item.slice(0, 50) + "..."}</Caption>
                </div>
                {/* </Stack> */}
              </div>
            );
          })}
        </FormLayout>
      </Card>
    </Modal>
  );
}
