import { ChoiceList, Spinner, TextField } from "@shopify/polaris";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { ChoiceDate, Table } from "..";
import { changePage } from "../../actions/PaymentActions";
import { PAYMENT_ORDER_BY, PAYMENT_STATUS } from "../../utils/constants";

export default function PaymentsList({
  paymentColumns,
  payments,
  statusPayment,
  handleStatusPaymentsChange,
  orderSelected,
  handleOrderSelectedChange,
  orden,
  handleOrdenChange,
  createdAt,
  handleCreatedAtChange,
  selectedCreatedAt,
  setSelectedCreatedAt,
  envios,
  handleOrderSelectedRemove,
  handleQueryValueRemove,
  handleStatusPaymentRemove,
  handleFiltersQueryChange,
  isLoading,
  pagination,
  queryValue,
  handleSelectAll,
  selectedItems,
  updateTextContact = () => {},
  inputValueClient = "",
  clientOptions = [],
  clientSelected = [],
  deselectedClients = { current: [] },
  handleClientChange = () => {},
  loadingClients = false,
  allowClientFilter = true,
  handleDateCreatedRemove,
}) {
  const dispatch = useDispatch();
  const handleFiltersClearAll = useCallback(() => {
    handleOrderSelectedRemove();
    handleQueryValueRemove();
    handleStatusPaymentRemove();
    handleDateCreatedRemove();
    updateTextContact("");
    handleClientChange([]);
  }, [
    handleOrderSelectedRemove,
    handleQueryValueRemove,
    handleStatusPaymentRemove,
    handleDateCreatedRemove,
    updateTextContact,
    handleClientChange,
  ]);

  let filters = [
    {
      key: "cliente",
      label: "Cliente",
      filter: (
        <>
          <TextField
            clearButton
            onClearButtonClick={() => updateTextContact("")}
            onChange={updateTextContact}
            value={inputValueClient}
            placeholder="Busca un cliente"
            autoComplete="off"
          />
          <div className="my-6">
            <ChoiceList
              title="Cliente"
              titleHidden
              choices={clientOptions}
              selected={clientSelected}
              onChange={handleClientChange}
              allowMultiple
            />
            {loadingClients && (
              <div className="flex justify-center">
                <Spinner size="small" />
              </div>
            )}
          </div>
        </>
      ),
      shortcut: true,
    },
    {
      key: "statusPayment",
      label: "Estado del pago",
      filter: (
        <ChoiceList
          title="Estado del pago"
          titleHidden
          choices={PAYMENT_STATUS}
          selected={statusPayment || []}
          onChange={handleStatusPaymentsChange}
        />
      ),
      shortcut: true,
    },
    {
      key: "orderSelected",
      label: "Ordenar",
      filter: (
        <div className="flex flex-col">
          <ChoiceList
            title="Ordenar"
            titleHidden
            choices={PAYMENT_ORDER_BY}
            selected={orderSelected || []}
            onChange={handleOrderSelectedChange}
          />
          <div className="flex w-full border border-t-1 border-b-0 border-r-0 border-l-0 border-gray-400">
            <ChoiceList
              title="Ordenar"
              titleHidden
              choices={[
                { label: "Ascendente", value: "1" },
                { label: "Descendente", value: "-1" },
              ]}
              selected={orden}
              onChange={handleOrdenChange}
            />
          </div>
        </div>
      ),
      shortcut: true,
    },
    {
      shortcut: false,
      key: "dateStatus",
      label: "Fecha",
      filter: (
        <ChoiceDate
          value={createdAt}
          handleChange={handleCreatedAtChange}
          selectedDates={selectedCreatedAt}
          setSelectedDates={setSelectedCreatedAt}
          past
        />
      ),
    },
  ];

  if (!allowClientFilter) {
    filters.shift();
  }

  return (
    <div>
      <Table
        handleSelectAll={handleSelectAll}
        selectedItems={selectedItems}
        columns={paymentColumns}
        dataSource={payments}
        filters={filters}
        envios={envios}
        handleFiltersClearAll={handleFiltersClearAll}
        handleFiltersQueryChange={handleFiltersQueryChange}
        handleQueryValueRemove={handleQueryValueRemove}
        isLoading={isLoading}
        navigate={() => {}}
        pagination={pagination}
        queryValue={queryValue}
        tableName="pagos"
        type="pago"
        appliedFiltersData={[
          {
            secondLabel: "Estado del pago",
            label: "statusPayment",
            state: statusPayment.map((status) =>
              status === "succeeded"
                ? "Pagado"
                : status === "pending"
                ? "Pendiente"
                : "Fallido"
            ),
            handleRemove: handleStatusPaymentRemove,
            willMap: true,
          },
          {
            seconlabel: "",
            label: "dateStatus",
            state: createdAt,
            handleRemove: handleDateCreatedRemove,
          },
          {
            secondLabel: "Cliente",
            label: "cliente",
            state: deselectedClients?.current
              .filter((client) =>
                clientSelected.some((val) => val === client.value)
              )
              .map((client) => client.label),
            handleRemove: () => handleClientChange([]),
            willMap: true,
          },
        ]}
        onChangePage={(page) => dispatch(changePage(page))}
      />
    </div>
  );
}
