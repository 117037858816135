import React, { useState, useEffect } from "react";
import {
  TextField,
  Form,
  TextStyle,
  DisplayText,
  Button,
  Link,
  Spinner,
} from "@shopify/polaris";
import { ViewMajor, HideMinor } from "@shopify/polaris-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAll,
  login,
  loginEmail,
  recoveryPassword,
} from "../../actions/AuthActions";
import jwt from "jsonwebtoken";
import { Redirect } from "react-router-dom";
import "./LoginScreenStyles.css";
import { getAuthData } from "../../reducers";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import useToggle from "../../hooks/useToggle";

export default function Login(props) {
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    errorEmail: null,
    errorPassword: null,
  });

  let accessToken = useSelector(getAuthData).access_token;

  useEffect(() => {
    function clearAllData() {
      if (accessToken) {
        jwt.verify(
          accessToken,
          process.env.REACT_APP_SECRET_SEED,
          (error, decoded) => {
            if (error) {
              dispatch(clearAll());
            }
          }
        );
      } else {
        dispatch(clearAll());
      }
    }
    function clearChat() {
      const gorgias = document.getElementById("gorgias-chat");
      gorgias.innerHTML = "";
      const tawkto = document.getElementById("tawkToChat");
      tawkto.innerHTML = "";
    }
    setTimeout(() => {
      clearChat();
    }, 200);

    clearAllData();
  }, [accessToken, dispatch]);

  const [loginAs, setLoginAs] = useState("Continuar a tu tienda");
  const [emailVerified, setEmailVerified] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [isLogining, setIsLogining] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [loginState, setLoginState] = useState("");
  const [isRecovery, setIsRecovery] = useState(false);
  const [grantType, setGrantType] = useState(null);
  const [isRecovering, setIsRecovering] = useState(false);
  const [showPassword, togglePassword] = useToggle(false);
  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    let token = new URLSearchParams(window.location.search).get(
      "externalLogin"
    );
    if (token) {
      jwt.verify(token, process.env.REACT_APP_SECRET_SEED, (error, decoded) => {
        if (error) {
          dispatch(clearAll());
        }

        const { data } = decoded;
        setIsLogin(true);
        if (data.isExternalLogin) {
          dispatch(
            login({
              email: data.email,
              isExternalLogin: data.isExternalLogin,
              grant_type: data.grant_type,
            })
          ).catch((error) => {
            dispatch(clearAll());
            setCredentials((state) => ({
              ...state,
              errorPassword: error.error?.message || error,
            }));
            setIsLogining(false);
            setIsLogin(false);
          });
        }
      });
    }
    return () => null;
  }, [dispatch]);

  function emailSubmit() {
    setIsLogin(true);
    dispatch(loginEmail(credentials.email))
      .then((data) => {
        if (data && !data.ok) {
          setCredentials((state) => ({
            ...state,
            errorEmail: data.error?.message,
          }));
        } else {
          const {
            usuario: { name = "", last_name = "", nombre = "" },
            grant_type,
          } = data;
          setGrantType(grant_type);
          //change for login state
          setLoginState(
            `como ${nombre && nombre} ${name && name} ${last_name && last_name}`
          );
          setLoginAs(
            `Continuar como ${nombre && nombre} ${name && name} ${
              last_name && last_name
            }`
          );
          setEmailVerified(true);
        }
      })
      .catch(({ response }) => {
        setCredentials((state) => ({
          ...state,
          errorEmail: response?.data?.message,
        }));
      })
      .finally(() => setIsLogin(false));
  }

  function passwordSubmit() {
    const { email, password } = credentials;
    setIsLogining(true);
    dispatch(login({ email, password, grant_type: grantType }))
      .then((data) => {
        if (data.ok) {
          props.history.replace(
            ["COMERCIO", "AGENT"].includes(data.grant_type)
              ? "/admin"
              : data.startOnOnlineStore
              ? "/"
              : "/cliente"
          );

          if (["CLIENTE", "CONTACT"].includes(data.grant_type)) {
            window.location.reload();
          }
        }
      })
      .catch((error) => {
        setCredentials((state) => ({
          ...state,
          errorPassword: error.error?.message || "",
        }));
        setIsLogining(false);
      });
  }

  function handleForgotPassword() {
    setForgotPassword(true);
    setEmailVerified(false);
  }

  function handleToLogin() {
    setForgotPassword(false);
    setEmailVerified(false);
    setIsRecovery(false);
    setCredentials({
      email: "",
      password: "",
      errorEmail: null,
      errorPassword: null,
    });
    setLoginAs("Continuar a tu tienda");
  }

  function handleRestablecer() {
    setIsRecovering(true);
    dispatch(recoveryPassword({ email: credentials.email, grantType }))
      .then((res) => {
        if (res.ok) {
          setIsRecovery(true);
        }
      })
      .finally(() => setIsRecovering(false));
  }

  useEffect(() => {
    document.title = "B2BGO - Login";
    ApiServiceComercios.getImageData()
      .then(({ storeDB }) => {
        setImageData(storeDB?.imagen?.url);
      })
      .catch((error) => {
        throw error;
      });
    return () => null;
  }, []);

  return (
    <div
      id="bg-gradient-animation"
      className="flex w-full h-screen items-center"
    >
      {props.data?.isLoggedIn ? (
        <Redirect
          to={
            ["ADMIN", "COMERCIO", "AGENT"].indexOf(props.data.grantType) >= 0
              ? "/admin"
              : "/cliente"
          }
        />
      ) : null}
      {!isLogining ? (
        <div className="login-card w-full sm:h-auto">
          <img
            src={imageData ? imageData : "b2bgologo.png"}
            className="mb-10 img-login"
            alt="b2bgo-logo"
          />
          <div>
            <div className="mb-1">
              <DisplayText element="h2" size="large">{`${
                forgotPassword ? "¿Olvidaste tu contraseña?" : "Iniciar sesión"
              }`}</DisplayText>
            </div>
            <div className="mb-8">
              <TextStyle element="p" variation="subdued">{`${
                forgotPassword
                  ? "Te enviaremos un enlace por correo electrónico para restablecerla"
                  : loginAs
              }`}</TextStyle>
            </div>
            {!emailVerified && !forgotPassword && (
              <Form onSubmit={emailSubmit}>
                <div className="my-8 form-width">
                  <TextField
                    clearButton
                    onClearButtonClick={() =>
                      setCredentials((state) => ({ ...state, email: "" }))
                    }
                    disabled={isLogin}
                    error={credentials.errorEmail}
                    label="Email"
                    type="email"
                    value={credentials.email}
                    onChange={(email) =>
                      setCredentials((state) => ({ ...state, email }))
                    }
                    placeholder="correo@email.com"
                  />
                </div>
                <Button
                  loading={isLogin}
                  submit
                  primary
                  disabled={!credentials.email.length > 0}
                >
                  <p className="mx-6">Siguiente</p>
                </Button>
              </Form>
            )}
            {emailVerified && (
              <Form onSubmit={passwordSubmit}>
                <div className="my-8 form-width">
                  <TextField
                    clearButton
                    onClearButtonClick={() =>
                      setCredentials((state) => ({ ...state, password: "" }))
                    }
                    autoFocus
                    disabled={isLogining}
                    error={credentials.errorPassword}
                    label="Contraseña"
                    type={showPassword ? "text" : "password"}
                    value={credentials.password}
                    onChange={(password) =>
                      setCredentials((state) => ({ ...state, password }))
                    }
                    placeholder="********"
                    connectedRight={
                      <Button
                        onClick={togglePassword}
                        icon={showPassword ? ViewMajor : HideMinor}
                      />
                    }
                  />
                </div>
                <div className="inline-block flex-col">
                  <div className="block mb-4">
                    <Button
                      submit
                      primary
                      disabled={!credentials.password.length > 0}
                    >
                      <p className="mx-6">Siguiente</p>
                    </Button>
                  </div>
                  <Link onClick={handleForgotPassword}>
                    ¿Olvidaste la contraseña?
                  </Link>
                </div>
              </Form>
            )}
            {forgotPassword && (
              <Form onSubmit={handleRestablecer}>
                <div className="my-8 form-width">
                  <TextStyle element="p">{credentials.email}</TextStyle>
                </div>
                <div className="inline-block flex-col">
                  {isRecovery ? (
                    <div className="block mb-4">
                      <TextStyle element="p">
                        Hemos enviado un correo de recuperación a tu cuenta
                      </TextStyle>
                    </div>
                  ) : (
                    <div className="block mb-4">
                      <Button
                        submit
                        primary
                        disabled={isRecovering}
                        loading={isRecovering}
                      >
                        <p className="mx-6">Restablecer</p>
                      </Button>
                    </div>
                  )}
                  <Link onClick={handleToLogin}>
                    Volver al inicio de sesión
                  </Link>
                </div>
              </Form>
            )}
          </div>
        </div>
      ) : (
        <div className="loading-anim">
          <Spinner color="teal" size="large" />
          <div className="mt-16 w-3/4">
            <DisplayText element="h1">
              Estás iniciando sesión {loginState}
            </DisplayText>
          </div>
        </div>
      )}
    </div>
  );
}
