import ApiServiceComercios from "../services/ApiServiceComercios";
import NormalizeById from "../utils/NormalizedById";
import { getPaginationOpportunities } from "../reducers";

export const OPPORTUNITIES_BEGIN = "OPPORTUNITIES_BEGIN";
export const ADD_OPPORTUNITIES = "ADD_OPPORTUNITIES";
export const OPPORTUNITIES_FAILED = "OPPORTUNITIES_FAILED";
export const CLEAR_OPPORTUNITIES = "CLEAR_OPPORTUNITIES";
export const RESET_PAGE = "RESET_PAGE";
export const ADD_OPPORTUNITY_ID = "ADD_OPPORTUNITY_ID";
export const CHANGE_PAGE = "CHANGE_PAGE";

export function fetchOpportunities(params) {
  return async (dispatch, getState) => {
    let pagination = getPaginationOpportunities(getState());
    dispatch(clearOpportunities());
    dispatch(opportunitiesBegin());
    return ApiServiceComercios.getOpportunities({
      ...params,
      limit: pagination.sizePerPage,
      skip: pagination.currentPage * pagination.sizePerPage,
    })
      .then((result) => {
        let data = NormalizeById(result.opportunities, "_id");
        data["total"] = result.total;
        data["allIdsDB"] = result.allIds;
        dispatch(addOpportunities(data));
        return result;
      })
      .catch((err) => {
        dispatch(failedOpportunities());
      });
  };
}

export const opportunitiesBegin = () => ({
  type: OPPORTUNITIES_BEGIN,
});

export const addOpportunities = (data) => ({
  type: ADD_OPPORTUNITIES,
  payload: { data },
});

export const failedOpportunities = () => ({
  type: OPPORTUNITIES_FAILED,
});

export const clearOpportunities = () => ({
  type: CLEAR_OPPORTUNITIES,
});

export const changePage = (currentPage) => ({
  type: CHANGE_PAGE,
  payload: { data: { currentPage } },
});

export const resetPage = () => ({
  type: RESET_PAGE,
});

export const addOpportunityId = (id) => ({
  type: ADD_OPPORTUNITY_ID,
  payload: { id },
});
