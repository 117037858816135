import {
  Card,
  Form,
  FormLayout,
  Modal,
  Select,
  Spinner,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleToast } from "../../actions/InteractiveActions";
import useToggle from "../../hooks/useToggle";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { Estados } from "../../utils/EstadosRepublica";
import { EmailEx, rfcEx } from "../../utils/RegEx";
import CountrySelect from "../CountrySelect";
import { CountrySelectTextField } from "../CountrySelect/CountrySelect";

const initialState = {
  nombre: "",
  email: "",
  telefono: "",
  rfc: "",
  clave: "",
  moneda: "",
  condicion_pago: "",
  tipo_cliente: "",
  zona_cliente: "",
  vendedor: "",
  cobrador: "",
  limite_credito: 0,
  envios: "",
  almacen: "",
  direccion: "",
  colonia: "",
  ciudad: "",
  codigo_postal: "",
  estado: "",
  pais: "México",
};

const estados = Estados.map((item) => {
  return { label: item, value: item };
});

export default function AddClientModal({
  isOpen,
  name,
  onClose,
  setIdCustomer,
}) {
  const dispatch = useDispatch();
  const [clientData, setClientData] = useState(initialState);
  const [currencies, setCurrencies] = useState([]);
  const [paymentConditions, setPaymentConditions] = useState([]);
  const [clientTypes, setClientTypes] = useState([]);
  const [clientZones, setClientZones] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [collectors, setCollectors] = useState([]);
  const [shipments, setShipments] = useState([]);
  const [stores, setStores] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showCountry, setShowCountry] = useState(false);
  const [isLoadingClient, setIsLoadingClient] = useState(false);
  const [isDiscard, setIsDiscard] = useToggle(false);
  const [modalIsHide, setModalIsHide] = useState(isOpen);
  const [isDirty, setIsDirty] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isLoadingRfc, setIsLoadingRfc] = useState(true);

  const [selectedFlag, setSelectedFlag] = useState({
    country: {
      code: "MX",
      phone_code: "52",
    },
    phone: {
      code: "MX",
      phone_code: "52",
    },
  });
  const [errorsInput, setErrors] = useState({
    email: "",
    name: "",
    clave: "",
    moneda: "",
    envio: "",
    almacen: "",
    direccion: "",
    ciudad: "",
    rfc: "",
  });
  const [mandatory, setMandatoryRfc] = useState(false);

  useEffect(() => {
    if (name) {
      inputChange("nombre", name);
    }
  }, [name]);

  const verifyAvailibility = () => {
    setIsVerifying(true);
    ApiServiceComercios.verifyAvailibility(clientData)
      .then((response) => {})
      .catch(({ response }) => {
        const errorsDB = response?.data?.errors;
        setErrorMessage("email", errorsDB?.email);
        setErrorMessage("name", errorsDB?.name);
        setErrorMessage("clave", errorsDB?.clave);
      })
      .finally(() => setIsVerifying(false));
  };

  const fetchCurrencies = () => {
    setIsLoading(true);
    ApiServiceComercios.getCurrencies()
      .then(({ monedas }) => {
        const currenciesItems = monedas.map(({ claveFiscal, nombre, _id }) => ({
          value: _id,
          label: nombre + " (" + claveFiscal + ")",
        }));

        setCurrencies(currenciesItems);
      })
      .finally(() => setIsLoading(false));
  };

  const fetchClientType = () => {
    setIsLoading(true);
    ApiServiceComercios.obtenerTiposCliente()
      .then(({ ok, tiposCliente }) => {
        if (ok) {
          const options = tiposCliente.map((i) => ({
            label: i.nombre,
            value: i._id,
          }));
          setClientTypes(options);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const fetchClientZone = () => {
    setIsLoading(true);
    ApiServiceComercios.obtenerZonasCliente()
      .then(({ ok, zonasCliente }) => {
        if (ok) {
          const options = zonasCliente.map((i) => ({
            label: i.nombre,
            value: i._id,
          }));
          setClientZones(options);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const fetchVendors = () => {
    setIsLoading(true);
    ApiServiceComercios.getVendors()
      .then(({ vendors }) => {
        const options = vendors.map(({ name, _id }) => ({
          label: name,
          value: _id,
        }));

        setVendors(options);
      })
      .finally(() => setIsLoading(false));
  };

  const getCollectors = () => {
    setIsLoading(true);
    ApiServiceComercios.getCollectors()
      .then(({ collectors = [] }) => {
        const options = collectors.map(({ name, _id }) => ({
          label: name,
          value: _id,
        }));

        setCollectors(options);
      })
      .finally(() => setIsLoading(false));
  };

  const fetchShipments = () => {
    setIsLoading(true);
    ApiServiceComercios.obtenerEnvios()
      .then(({ ok, envios }) => {
        if (ok) {
          const options = envios.map((i) => ({
            label: i.nombre,
            value: i._id,
          }));
          setShipments(options);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchStores = () => {
    setIsLoading(true);
    ApiServiceComercios.obtenerSucursales()
      .then(({ ok, sucursales }) => {
        if (ok) {
          const options = sucursales.map((i) => ({
            label: i.nombre,
            value: i._id,
          }));
          setStores(options);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchPaymentConditions = () => {
    setIsLoading(true);
    ApiServiceComercios.obtenerCondicionesPago()
      .then(({ ok, conditions }) => {
        if (ok) {
          const options = conditions.map(({ nombre }) => ({
            label: nombre,
            value: nombre,
          }));
          setPaymentConditions(options);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchConfig = () => {
    setIsLoadingRfc(true);
    ApiServiceComercios.obtenerComercioConfigMicrosipSeries()
      .then(({ mandatory_rfc }) => {
        setMandatoryRfc(mandatory_rfc);
      })
      .finally(() => setIsLoadingRfc(false));
  };

  useEffect(() => {
    fetchCurrencies();
    fetchClientType();
    fetchClientZone();
    fetchVendors();
    getCollectors();
    fetchShipments();
    fetchStores();
    fetchPaymentConditions();
    fetchConfig();
  }, []);

  const inputChange = (name = "", value = "") => {
    setIsDirty(true);
    if (name === "telefono") {
      let phoneNum = value.replace(/\D/g, "");
      const formatNum = phoneNum.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3");
      setClientData((prevState) => ({
        ...prevState,
        [name]: formatNum,
      }));
    } else {
      setClientData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const onBlurHandler = (key, value) => {
    if (!value) {
      setErrorMessage(key, "El nombre es necesario");
    } else {
      setErrorMessage(key, "");
      verifyAvailibility();
    }
  };

  const setErrorMessage = (name = "", msj = "") => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: msj,
    }));
  };

  const handleInputEmail = (value = "") => {
    inputChange("email", value);

    if (EmailEx.test(value)) {
      setErrorMessage("email", "");
      verifyAvailibility();
    } else {
      setErrorMessage("email", "El correo no tiene un formato valido");
    }
  };

  function handleRFCOnBlur(value) {
    if (!rfcEx.test(value)) {
      setErrorMessage("rfc", "Ingresa un RFC válido");
    } else {
      setErrorMessage("rfc", "");
    }
  }

  function handleSelectPhone(country) {
    setSelectedFlag((state) => ({
      ...state,
      phone: {
        code: country["ISO CODES"].split(" / ")[0],
        phone_code: country["COUNTRY CODE"],
      },
    }));
    setShowCountry(false);
  }

  const onSaveClient = () => {
    const tel = `+${selectedFlag.phone.phone_code}${
      clientData.telefono.length > 0 ? " " : ""
    }${clientData.telefono}`;

    const FormatData = { ...clientData };
    FormatData["telefono"] = tel;
    handleOnSave(FormatData);
  };

  const handleOnSave = (clienteData) => {
    setIsLoadingClient(true);
    ApiServiceComercios.createClient(clienteData)
      .then(({ ok, message, customer }) => {
        if (ok) {
          dispatch(toggleToast({ message }));
          const selectedClient = [];
          const customerId = customer?._id;
          selectedClient.push(customerId);
          setIdCustomer(selectedClient);
          onClose();
        }
      })
      .catch(({ response }) => {
        const errorsDB = response?.data?.errors;
        setErrorMessage("email", errorsDB?.email);
        setErrorMessage("name", errorsDB?.name);
        setErrorMessage("clave", errorsDB?.clave);
      })
      .finally(() => {
        setIsLoadingClient(false);
      });
  };

  const onHandleModal = () => {
    setIsDiscard();
    setModalIsHide((state) => !state);
  };

  return (
    <>
      {isDiscard && isDirty && (
        <Modal
          open={isDiscard}
          title="Tienes cambios sin guardar en esta página"
          onClose={() => {
            onClose();
            setModalIsHide(false);
            setIsDiscard();
          }}
          secondaryActions={[{ content: "Cancelar", onAction: onHandleModal }]}
          primaryAction={{
            content: "Salir de la página",
            onAction: onClose,
            destructive: true,
          }}
        >
          <Card sectioned>
            <TextStyle>
              Si abandonas esta página, perderás todos los cambios no guardados.
              ¿Estás seguro de que quieres salir de esta página?
            </TextStyle>
          </Card>
        </Modal>
      )}
      <Modal
        open={modalIsHide}
        onClose={isDirty ? onHandleModal : onClose}
        title="Agregar cliente"
        primaryAction={{
          content: "Crear cliente",
          onAction: onSaveClient,
          disabled:
            isLoading ||
            isLoadingClient ||
            !clientData.nombre ||
            !clientData.clave ||
            !clientData.moneda ||
            !clientData.envios ||
            !clientData.almacen ||
            !clientData.direccion ||
            !clientData.ciudad ||
            errorsInput.email ||
            errorsInput.name ||
            errorsInput.clave ||
            (mandatory && !clientData.rfc),
          loading: isLoading || isLoadingClient,
        }}
        secondaryActions={[
          {
            content: "Cancelar",
            onAction: isDirty ? onHandleModal : onClose,
            disabled: isLoading || isLoadingClient,
          },
        ]}
      >
        <Modal.Section>
          <Card.Section>
            <Form>
              <FormLayout>
                <TextField
                  label="Nombre"
                  loading={isVerifying}
                  disabled={isVerifying}
                  value={clientData.nombre}
                  requiredIndicator
                  onChange={(val) => inputChange("nombre", val)}
                  error={
                    (!clientData.nombre || errorsInput.name) && errorsInput.name
                  }
                  onBlur={() => onBlurHandler("name", clientData.nombre)}
                />
                <FormLayout.Group>
                  <TextField
                    loading={isVerifying}
                    disabled={isVerifying}
                    label="Correo electrónico"
                    value={clientData.email}
                    type="email"
                    requiredIndicator
                    error={
                      (!clientData.email || errorsInput.email) &&
                      errorsInput.email
                    }
                    onBlur={() => {
                      if (!clientData.email) {
                        setErrorMessage("email", "El correo es necesario");
                      }
                    }}
                    onChange={handleInputEmail}
                  />
                  <CountrySelect
                    showCode
                    active={showCountry}
                    handleSelect={handleSelectPhone}
                    selectedFlag={selectedFlag.phone}
                    toggleActive={setShowCountry}
                    title="Teléfono"
                  >
                    <TextField
                      maxLength={12}
                      type="tel"
                      value={clientData.telefono}
                      onChange={(text) => inputChange("telefono", text)}
                    />
                  </CountrySelect>
                  <TextField
                    label="RFC"
                    type="text"
                    value={clientData.rfc}
                    onChange={(val) => inputChange("rfc", val)}
                    onBlur={() => handleRFCOnBlur(clientData.rfc)}
                    error={errorsInput.rfc}
                    requiredIndicator={mandatory}
                    suffix={isLoadingRfc && <Spinner size="small" />}
                    disabled={isLoadingRfc}
                  />
                  <TextField
                    label="Clave"
                    loading={isVerifying}
                    disabled={isVerifying}
                    value={clientData.clave}
                    requiredIndicator
                    onChange={(val) => inputChange("clave", val)}
                    error={
                      (!clientData.clave || errorsInput.clave) &&
                      errorsInput.clave
                    }
                    onBlur={() => onBlurHandler("clave", clientData.clave)}
                  />
                </FormLayout.Group>
              </FormLayout>
            </Form>
          </Card.Section>
          <Card.Section>
            <Form>
              <FormLayout>
                <FormLayout.Group>
                  <Select
                    label="Moneda"
                    requiredIndicator
                    options={currencies}
                    error={!clientData.moneda && errorsInput.moneda}
                    onBlur={() =>
                      setErrorMessage("moneda", "La moneda es necesaria")
                    }
                    placeholder="Selecciona una moneda"
                    value={clientData.moneda}
                    onChange={(val) => inputChange("moneda", val)}
                  />
                  <Select
                    label="Condición de pago"
                    placeholder="Selecciona una condición"
                    options={paymentConditions}
                    value={clientData.condicion_pago}
                    onChange={(val) => inputChange("condicion_pago", val)}
                  />
                  <Select
                    label="Tipo de cliente"
                    placeholder="Selecciona un tipo"
                    options={clientTypes}
                    value={clientData.tipo_cliente}
                    onChange={(val) => inputChange("tipo_cliente", val)}
                  />
                  <Select
                    label="Zona de cliente"
                    placeholder="Selecciona una zona"
                    options={clientZones}
                    value={clientData.zona_cliente}
                    onChange={(val) => inputChange("zona_cliente", val)}
                  />
                  <Select
                    label="Vendedor"
                    placeholder="Selecciona un vendedor"
                    options={vendors}
                    value={clientData.vendedor}
                    onChange={(val) => inputChange("vendedor", val)}
                  />
                  <Select
                    label="Cobrador"
                    placeholder="Selecciona un cobrador"
                    options={collectors}
                    value={clientData.cobrador}
                    onChange={(val) => inputChange("cobrador", val)}
                  />
                  <TextField
                    type="number"
                    placeholder={0}
                    label="Límite de crédito"
                    value={clientData.limite_credito}
                    onChange={(val) => inputChange("limite_credito", val)}
                  />
                </FormLayout.Group>
              </FormLayout>
            </Form>
          </Card.Section>
          <Card.Section>
            <Form>
              <FormLayout>
                <FormLayout.Group>
                  <Select
                    label="Envíos"
                    requiredIndicator
                    placeholder="Selecciona un método de envío"
                    value={clientData.envios}
                    onChange={(val) => inputChange("envios", val)}
                    options={shipments}
                    error={!clientData.envios && errorsInput.envio}
                    onBlur={() =>
                      setErrorMessage("envio", "El envío es necesario")
                    }
                  />
                  <Select
                    label="Almacén"
                    requiredIndicator
                    placeholder="Selecciona un almacén"
                    value={clientData.almacen}
                    options={stores}
                    onChange={(val) => inputChange("almacen", val)}
                    error={!clientData.almacen && errorsInput.almacen}
                    onBlur={() =>
                      setErrorMessage("almacen", "El almacén es necesario")
                    }
                  />
                  <TextField
                    label="Dirección"
                    requiredIndicator
                    value={clientData.direccion}
                    onChange={(val) => inputChange("direccion", val)}
                    error={!clientData.direccion && errorsInput.direccion}
                    onBlur={() =>
                      setErrorMessage("direccion", "La dirección es necesaria")
                    }
                  />
                  <TextField
                    label="Colonia"
                    value={clientData.colonia}
                    onChange={(val) => inputChange("colonia", val)}
                  />
                  <TextField
                    label="Ciudad"
                    requiredIndicator
                    value={clientData.ciudad}
                    onChange={(val) => inputChange("ciudad", val)}
                    error={!clientData.ciudad && errorsInput.ciudad}
                    onBlur={() =>
                      setErrorMessage("ciudad", "La ciudad es necesaria")
                    }
                  />
                  <TextField
                    label="Código postal"
                    value={clientData.codigo_postal}
                    onChange={(val) => inputChange("codigo_postal", val)}
                  />
                  <Select
                    value={clientData.estado}
                    onChange={(value) => inputChange("estado", value)}
                    label="Estado"
                    options={estados}
                    placeholder="Selecciona un estado"
                  />
                  <CountrySelectTextField
                    handleChangeText={(value) => inputChange("pais", value)}
                    value={clientData.pais}
                  />
                </FormLayout.Group>
              </FormLayout>
            </Form>
          </Card.Section>
        </Modal.Section>
      </Modal>
    </>
  );
}
