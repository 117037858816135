import React, { useState, useRef, useCallback, useEffect } from "react";
import _ from "lodash";
import {
  Autocomplete,
  Banner,
  Button,
  Card,
  DataTable,
  FormLayout,
  Icon,
  Modal,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import {
  SearchMajor,
  MobileHorizontalDotsMajor,
  EditMajor,
  DeleteMajor,
} from "@shopify/polaris-icons";

import ApiServiceComercios from "../../services/ApiServiceComercios";
import CustomPopover from "../CustomPopover";
import { onBlurTextField } from "../../utils/TextFieldValidations";
import useDebounce from "../../services/useDebounce";

export default function DescuentoProductoModal({
  isActive = false,
  togleModal = () => {},
  onAddProducto = () => {},
  selected = [],
}) {
  const queryValueRef = useRef(null);
  const productosRef = useRef(null);

  const [productos, setProductos] = useState([]);
  const deselectedOptions = useRef([]);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [queryValue, setQueryValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [producto, setProducto] = useState({
    porcentaje: "0",
    nombre: "",
    id: null,
  });

  const debouncedSearchTerm = useDebounce(queryValue, 900);

  useEffect(() => {
    queryValueRef.current = queryValue;
    productosRef.current = productos;
  });

  useEffect(() => {
    const updatingLine = selected.find((s) => s.editing === true);
    if (updatingLine) {
      setProductos((s) => [
        ...s,
        {
          porcentaje: updatingLine.porcentaje,
          nombre: updatingLine.nombre,
          id: updatingLine.id,
        },
      ]);
      setProducto({
        porcentaje: updatingLine.porcentaje,
        nombre: updatingLine.nombre,
        id: updatingLine.id,
      });
      setIsEditing(true);
    }
    return () => null;
  }, [selected]);

  useEffect(() => {
    function onQueryEndChange() {
      setIsLoading(true);
      ApiServiceComercios.obtenerArticulos({
        sort: "nombre",
        query: queryValueRef.current,
        limit: 20,
        skip: 0,
        existencia: -1,
      })
        .then(({ ok, articulos }) => {
          if (ok) {
            const willAdd = articulos
              .map((i) => ({ label: i.nombre, value: i._id }))
              .filter(
                (i) =>
                  !selected
                    .concat(productosRef.current)
                    .map((i) => i.id)
                    .includes(i.value)
              );
            deselectedOptions.current = willAdd;
            setOptions(willAdd);
          }
        })
        .finally(() => setIsLoading(false));
    }

    onQueryEndChange();
  }, [debouncedSearchTerm, selected]);

  function updateText(value) {
    setQueryValue(value);
    if (value === "") {
      setOptions(deselectedOptions);
      return;
    }
    const filterRegex = new RegExp(value, "i");
    const resultOptions = deselectedOptions.current
      .filter((i) => !productos.map((l) => l.nombre).includes(i.label))
      .filter((option) => option.label.match(filterRegex));
    setOptions(resultOptions);
  }

  const updateSelection = useCallback((selected) => {
    const art = deselectedOptions.current.find((i) => i.value === selected[0]);
    setProducto((state) => ({ ...state, nombre: art.label, id: art.value }));
    setSelectedOptions(selected);
  }, []);

  function changeState(value, key) {
    setProducto((state) => ({ ...state, [key]: value }));
  }

  function addProductos() {
    onAddProducto(productos);
    togleModal();
  }

  function addProducto() {
    setOptions(options.filter((i) => i.value !== producto.id));
    setProductos((s) => [...s, { ...producto, editing: false }]);
    setProducto({ porcentaje: "0", nombre: "", id: null });
  }

  function onRemoveItem(item) {
    setProductos(productos.filter((i) => i.id !== item));
    setOptions(
      _.orderBy(
        [
          ...options,
          {
            label: productos.find((i) => i.id === item)["nombre"],
            value: item,
          },
        ],
        ["label"],
        ["asc"]
      )
    );
  }

  function onEditItem(item) {
    setIsEditing(true);
    setProducto((s) => ({
      ...s,
      nombre: productos.find((i) => i.id === item)["nombre"],
      id: productos.find((i) => i.id === item)["id"],
      porcentaje: productos.find((i) => i.id === item)["porcentaje"],
    }));
  }

  function onSetEdit() {
    let arr = [...productos];
    const index = _.findIndex(arr, { id: producto.id });
    arr.splice(index, 1, producto);
    setProductos(arr);
    setOptions(options.filter((i) => i.value !== producto.id));
    setProducto({ porcentaje: "0", nombre: "", id: null });
    setIsEditing(false);
  }

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      prefix={<Icon source={SearchMajor} />}
      label="Productos"
      value={queryValue}
      placeholder="Buscar producto"
      helpText={producto.nombre}
    />
  );

  return (
    <Modal
      title="Agregar productos"
      open={isActive}
      onClose={togleModal}
      secondaryActions={[{ content: "Cancelar", onAction: togleModal }]}
      primaryAction={{
        content: "Continuar",
        onAction: addProductos,
        disabled: productos.length === 0,
      }}
    >
      <Modal.Section>
        <Card sectioned>
          <FormLayout>
            <Autocomplete
              options={options}
              selected={selectedOptions}
              onSelect={updateSelection}
              textField={textField}
              willLoadMoreResults
              listTitle="Agregar productos"
              loading={isLoading}
              emptyState="No hay productos"
            />
            <Stack distribution="fillEvenly">
              <TextField
                min={1}
                max={100}
                align="right"
                suffix="%"
                type="number"
                label="Valor del descuento"
                value={producto.porcentaje}
                onChange={(value) => changeState(value, "porcentaje")}
                onBlur={() =>
                  onBlurTextField(
                    producto.porcentaje,
                    changeState,
                    100,
                    "porcentaje"
                  )
                }
              />
              <Button
                monochrome
                onClick={isEditing ? onSetEdit : addProducto}
                disabled={Number(producto.porcentaje) === 0 || !producto.nombre}
              >
                {isEditing ? "Editar" : "Agregar"}
              </Button>
            </Stack>
          </FormLayout>
          {productos.length > 0 && (
            <DataTable
              headings={["Producto", "Descuento"]}
              columnContentTypes={["text", "text"]}
              rows={productos.map(({ nombre, porcentaje, id }) => {
                const Activator = ({ onClick }) => {
                  return (
                    <div className="flex items-center">
                      <div className="mr-4">
                        <TextStyle>{porcentaje} % </TextStyle>
                      </div>
                      <Button
                        plain
                        icon={MobileHorizontalDotsMajor}
                        onClick={onClick}
                      />
                    </div>
                  );
                };
                const name = (
                  <TextStyle>
                    {nombre}{" "}
                    {id === producto.id ? (
                      <TextStyle variation="subdued">...editando</TextStyle>
                    ) : null}
                  </TextStyle>
                );
                const prcntj = (
                  <CustomPopover
                    items={[
                      {
                        content: "Editar",
                        onAction: () => onEditItem(id),
                        icon: EditMajor,
                      },
                      {
                        content: "Quitar",
                        onAction: () => onRemoveItem(id),
                        icon: DeleteMajor,
                        destructive: true,
                      },
                    ]}
                    activator={Activator}
                  />
                );
                return [name, prcntj];
              })}
            />
          )}
        </Card>
        <Banner status="info">
          <p>
            Los clientes pueden combinar descuentos por promoción, por cliente,
            por artículo y por volumen.
          </p>
        </Banner>
      </Modal.Section>
    </Modal>
  );
}
