export const errorsTraslations = (declineCode) => {
  const error = [
    {
      code: "account_country_invalid_address",
      message:
        "The country of the business address provided does not match the country of the account. Businesses must be located in the same country as the account.",
      messageES:
        "El país de la dirección comercial proporcionada no coincide con el país de la cuenta. Las empresas deben estar ubicadas en el mismo país que la cuenta.",
    },
    {
      code: "account_error_country_change_requires_additional_steps",
      message:
        "Your account has already onboarded as a Connect platform. Changing your country requires additional steps. Please reach out to Stripe support for more information.",
      messageES:
        "Su cuenta ya se ha incorporado como plataforma Connect. Cambiar su país requiere pasos adicionales. Comuníquese con el soporte de Stripe para obtener más información.",
    },
    {
      code: "account_information_mismatch",
      message:
        "Some account information mismatches with one another. For example, some banks might require that the business_profile.name must match the account holder name.",
      messageES:
        "Parte de la información de la cuenta no coincide entre sí. Por ejemplo, algunos bancos pueden exigir que business_profile.name coincida con el nombre del titular de la cuenta.",
    },
    {
      code: "account_invalid",
      message:
        "The account ID provided as a value for the Stripe-Account header is invalid. Check that your requests are specifying a valid account ID.",
      messageES:
        "El ID de cuenta proporcionado como valor para el encabezado Stripe-Account no es válido. Verifique que sus solicitudes especifiquen una ID de cuenta válida.",
    },
    {
      code: "account_number_invalid",
      message:
        "The bank account number provided is invalid (e.g., missing digits). Bank account information varies from country to country. We recommend creating validations in your entry forms based on the bank account formats we provide.",
      messageES:
        "El número de cuenta bancaria proporcionado no es válido (por ejemplo, faltan dígitos). La información de la cuenta bancaria varía de un país a otro. Recomendamos crear validaciones en sus formularios de entrada en función de los formatos de cuenta bancaria que proporcionamos.",
    },
    {
      code: "acss_debit_session_incomplete",
      message:
        "The ACSS debit session is not ready to transition to complete status yet. Please try again the request later.",
      messageES:
        "La sesión de débito ACSS aún no está lista para la transición al estado completo. Vuelva a intentar la solicitud más tarde.",
    },
    {
      code: "alipay_upgrade_required",
      message:
        "This method for creating Alipay payments is not supported anymore. Please upgrade your integration to use Sources instead.",
      messageES:
        "Este método para crear pagos de Alipay ya no es compatible. Actualice su integración para usar Fuentes en su lugar.",
    },
    {
      code: "amount_too_large",
      message:
        "The specified amount is greater than the maximum amount allowed. Use a lower amount and try again.",
      messageES:
        "La cantidad especificada es mayor que la cantidad máxima permitida. Use una cantidad menor y vuelva a intentarlo.",
    },
    {
      code: "amount_too_small",
      message:
        "The specified amount is less than the minimum amount allowed. Use a higher amount and try again.",
      messageES:
        "La cantidad especificada es menor que la cantidad mínima permitida. Use una cantidad mayor y vuelva a intentarlo.",
    },
    {
      code: "api_key_expired",
      message:
        "The API key provided has expired. Obtain your current API keys from the Dashboard and update your integration to use them.",
      messageES:
        "La clave de API proporcionada ha caducado. Obtenga sus claves API actuales del panel y actualice su integración para usarlas.",
    },
    {
      code: "authentication_required",
      message:
        "The payment requires authentication to proceed. If your customer is off session, notify your customer to return to your application and complete the payment. If you provided the error_on_requires_action parameter, then your customer should try another card that does not require authentication.",
      messageES:
        "El pago requiere autenticación para proceder. Si su cliente está fuera de sesión, notifique a su cliente que regrese a su aplicación y complete el pago. Si proporcionó el parámetro error_on_requires_action, entonces su cliente debe probar con otra tarjeta que no requiera autenticación.",
    },
    {
      code: "balance_insufficient",
      message:
        "The transfer or payout could not be completed because the associated account does not have a sufficient balance available. Create a new transfer or payout using an amount less than or equal to the account’s available balance.",
      messageES:
        "No se pudo completar la transferencia o el pago porque la cuenta asociada no tiene suficiente saldo disponible. Cree una nueva transferencia o pago usando una cantidad menor o igual al saldo disponible de la cuenta.",
    },
    {
      code: "bank_account_bad_routing_numbers",
      message:
        "The bank account is known to not support the currency in question.",
      messageES:
        "Se sabe que la cuenta bancaria no admite la moneda en cuestión.",
    },
    {
      code: "bank_account_declined",
      message:
        "The bank account provided can not be used to charge, either because it is not verified yet or it is not supported.",
      messageES:
        "La cuenta bancaria proporcionada no se puede usar para cobrar, ya sea porque aún no está verificada o no es compatible.",
    },
    {
      code: "bank_account_exists",
      message:
        "The bank account provided already exists on the specified Customer object. If the bank account should also be attached to a different customer, include the correct customer ID when making the request again.",
      messageES:
        "La cuenta bancaria proporcionada ya existe en el objeto Cliente especificado. Si la cuenta bancaria también debe adjuntarse a un cliente diferente, incluya la identificación del cliente correcta cuando vuelva a realizar la solicitud.",
    },
    {
      code: "bank_account_restricted",
      message: "The customer’s account cannot be used with the payment method.",
      messageES:
        "La cuenta del cliente no se puede utilizar con el método de pago.",
    },
    {
      code: "bank_account_unusable",
      message:
        "The bank account provided cannot be used. A different bank account must be used.",
      messageES:
        "La cuenta bancaria facilitada no se puede utilizar. Se debe utilizar una cuenta bancaria diferente.",
    },
    {
      code: "bank_account_unverified",
      message:
        "Your Connect platform is attempting to share an unverified bank account with a connected account.",
      messageES:
        "Su plataforma de conexión está intentando compartir una cuenta bancaria no verificada con una cuenta conectada.",
    },
    {
      code: "bank_account_verification_failed",
      message:
        "The bank account cannot be verified, either because the microdeposit amounts provided do not match the actual amounts, or because verification has failed too many times.",
      messageES:
        "La cuenta bancaria no se puede verificar, ya sea porque los montos de los microdepósitos proporcionados no coinciden con los montos reales o porque la verificación ha fallado demasiadas veces",
    },
    {
      code: "billing_invalid_mandate",
      message:
        "The Subscription or Invoice attempted payment on a PaymentMethod without an active mandate. In order to create Subscription or Invoice payments with this PaymentMethod, it must be confirmed on-session with a PaymentIntent or SetupIntent first.",
      messageES:
        "La Suscripción o Factura intentó el pago en un PaymentMethod sin un mandato activo. Para crear pagos de suscripción o facturas con este método de pago, primero se debe confirmar en la sesión con un PaymentIntent o SetupIntent.",
    },
    {
      code: "bitcoin_upgrade_required",
      message:
        "This method for creating Bitcoin payments is not supported anymore. Please upgrade your integration to use Sources instead.",
      messageES:
        "Este método para crear pagos de Bitcoin ya no es compatible. Actualice su integración para usar Fuentes en su lugar.",
    },
    {
      code: "card_decline_rate_limit_exceeded",
      message:
        "This card has been declined too many times. You can try to charge this card again after 24 hours. We suggest reaching out to your customer to make sure they have entered all of their information correctly and that there are no issues with their card.",
      messageES:
        "Esta tarjeta ha sido rechazada demasiadas veces. Puede intentar cargar esta tarjeta nuevamente después de 24 horas. Sugerimos comunicarse con su cliente para asegurarse de que haya ingresado toda su información correctamente y que no haya problemas con su tarjeta.",
    },
    {
      code: "card_declined",
      message:
        "The card has been declined. When a card is declined, the error returned also includes the decline_code attribute with the reason why the card was declined. Refer to our decline codes documentation to learn more.",
      messageES:
        "La tarjeta ha sido rechazada. Cuando se rechaza una tarjeta, el error devuelto también incluye el atributo declin_code con el motivo por el cual se rechazó la tarjeta. Consulte nuestra documentación de códigos de rechazo para obtener más información.",
    },
    {
      code: "cardholder_phone_number_required",
      message:
        "You must have a phone_number on file for Issuing Cardholders who will be creating EU cards. You cannot create EU cards without a phone_number on file for the cardholder. See the 3D Secure Documenation for more details.",
      messageES:
        "Debe tener un número de teléfono registrado para los titulares de tarjetas emisores que crearán tarjetas de la UE. No puede crear tarjetas de la UE sin un número de teléfono registrado para el titular de la tarjeta. Consulte la documentación de 3D Secure para obtener más detalles.",
    },
    {
      code: "charge_already_captured",
      message:
        "The charge you’re attempting to capture has already been captured. Update the request with an uncaptured charge ID.",
      messageES:
        "La carga que intentas capturar ya ha sido capturada. Actualice la solicitud con un ID de cargo no capturado.",
    },
    {
      code: "charge_already_refunded",
      message:
        "The charge you’re attempting to refund has already been refunded. Update the request to use the ID of a charge that has not been refunded.",
      messageES:
        "El cargo que intentas reembolsar ya se reembolsó. Actualiza la solicitud para utilizar el ID de un cargo que no se ha reembolsado.",
    },
    {
      code: "charge_disputed",
      message:
        "The charge you’re attempting to refund has been charged back. Check the disputes documentation to learn how to respond to the dispute.",
      messageES:
        "El cargo que está intentando reembolsar se ha devuelto. Consulte la documentación de disputas para saber cómo responder a la disputa.",
    },
    {
      code: "charge_exceeds_source_limit",
      message:
        "This charge would cause you to exceed your rolling-window processing limit for this source type. Please retry the charge later, or contact us to request a higher processing limit.",
      messageES:
        "Este cargo haría que excediera su límite de procesamiento de ventana móvil para este tipo de fuente. Vuelva a intentar realizar el cargo más tarde o comuníquese con nosotros para solicitar un límite de procesamiento más alto.",
    },
    {
      code: "charge_expired_for_capture",
      message:
        "The charge cannot be captured as the authorization has expired. Auth and capture charges must be captured within a set number of days (7 by default).",
      messageES:
        "El cargo no se puede capturar porque la autorización ha expirado. Los cargos de autenticación y captura deben capturarse dentro de un número determinado de días (7 por defecto).",
    },
    {
      code: "charge_invalid_parameter",
      message:
        "One or more provided parameters was not allowed for the given operation on the Charge. Check our API reference or the returned error message to see which values were not correct for that Charge.",
      messageES:
        "Uno o más parámetros proporcionados no estaban permitidos para la operación dada en el Cargo. Consulte nuestra referencia de API o el mensaje de error devuelto para ver qué valores no eran correctos para ese cargo.",
    },
    {
      code: "clearing_code_unsupported",
      message: "The clearing code provided is not supported.",
      messageES: "El código de compensación proporcionado no es compatible.",
    },
    {
      code: "country_code_invalid",
      message: "The country code provided was invalid.",
      messageES: "El código de país proporcionado no es válido.",
    },
    {
      code: "country_unsupported",
      message:
        "Your platform attempted to create a custom account in a country that is not yet supported. Make sure that users can only sign up in countries supported by custom accounts.",
      messageES:
        "Su plataforma intentó crear una cuenta personalizada en un país que aún no es compatible. Asegúrese de que los usuarios solo puedan registrarse en países admitidos por cuentas personalizadas.",
    },
    {
      code: "coupon_expired",
      message:
        "The coupon provided for a subscription or order has expired. Either create a new coupon, or use an existing one that is valid.",
      messageES:
        "El cupón proporcionado para una suscripción o pedido ha caducado. Crea un cupón nuevo o usa uno existente que sea válido.",
    },
    {
      code: "customer_max_payment_methods",
      message:
        "The maximum number of PaymentMethods for this Customer has been reached. Either detach some PaymentMethods from this Customer or proceed with a different Customer.",
      messageES:
        "Se ha alcanzado el número máximo de métodos de pago para este cliente. Separe algunos métodos de pago de este cliente o proceda con un cliente diferente.",
    },
    {
      code: "customer_max_subscriptions",
      message:
        "The maximum number of subscriptions for a customer has been reached. Contact us if you are receiving this error.",
      messageES:
        "Se ha alcanzado el número máximo de suscripciones para un cliente. Póngase en contacto con nosotros si recibe este error.",
    },
    {
      code: "debit_not_authorized",
      message:
        "The customer has notified their bank that this payment was unauthorized.",
      messageES:
        "El cliente ha notificado a su banco que este pago no estaba autorizado.",
    },
    {
      code: "email_invalid",
      message:
        "The email address is invalid (e.g., not properly formatted). Check that the email address is properly formatted and only includes allowed characters.",
      messageES:
        "La dirección de correo electrónico no es válida (por ejemplo, no tiene el formato correcto). Verifique que la dirección de correo electrónico tenga el formato correcto y solo incluya los caracteres permitidos.",
    },
    {
      code: "expired_card",
      message:
        "The card has expired. Check the expiration date or use a different card.",
      messageES:
        "La tarjeta ha caducado. Verifique la fecha de vencimiento o use una tarjeta diferente.",
    },
    {
      code: "fraudulent",
      message:
        "The card has been declined. When a card is declined, the error returned also includes the decline_code attribute with the reason why the card was declined. Refer to our decline codes documentation to learn more.",
      messageES:
        "La tarjeta ha sido rechazada. Cuando se rechaza una tarjeta, el error devuelto también incluye el atributo declin_code con el motivo por el cual se rechazó la tarjeta. Consulte nuestra documentación de códigos de rechazo para obtener más información.",
    },
    {
      code: "generic_decline",
      message:
        "The card has been declined. When a card is declined, the error returned also includes the decline_code attribute with the reason why the card was declined. Refer to our decline codes documentation to learn more.",
      messageES:
        "La tarjeta ha sido rechazada. Cuando se rechaza una tarjeta, el error devuelto también incluye el atributo declin_code con el motivo por el cual se rechazó la tarjeta. Consulte nuestra documentación de códigos de rechazo para obtener más información.",
    },
    {
      code: "idempotency_key_in_use",
      message:
        "The idempotency key provided is currently being used in another request. This occurs if your integration is making duplicate requests simultaneously.",
      messageES:
        "La clave de idempotencia proporcionada se está utilizando actualmente en otra solicitud. Esto ocurre si su integración está realizando solicitudes duplicadas simultáneamente.",
    },
    {
      code: "incorrect_address",
      message:
        "The card’s address is incorrect. Check the card’s address or use a different card.",
      messageES:
        "La dirección de la tarjeta es incorrecta. Verifique la dirección de la tarjeta o use una tarjeta diferente.",
    },
    {
      code: "incorrect_cvc",
      message:
        "The card’s security code is incorrect. Check the card’s security code or use a different card.",
      messageES:
        "El código de seguridad de la tarjeta es incorrecto. Verifique el código de seguridad de la tarjeta o use una tarjeta diferente.",
    },
    {
      code: "incorrect_number",
      message:
        "The card number is incorrect. Check the card’s number or use a different card.",
      messageES:
        "El número de tarjeta es incorrecto. Verifique el número de la tarjeta o use una tarjeta diferente.",
    },
    {
      code: "incorrect_zip",
      message:
        "The card’s postal code is incorrect. Check the card’s postal code or use a different card.",
      messageES:
        "El código postal de la tarjeta es incorrecto. Verifique el código postal de la tarjeta o use una tarjeta diferente.",
    },
    {
      code: "instant_payouts_config_disabled",
      message:
        "This connected account is not eligible for Instant Payouts. Ask the platform to enable Instant Payouts.",
      messageES:
        "Esta cuenta conectada no es elegible para pagos instantáneos. Pídele a la plataforma que habilite los pagos instantáneos.",
    },
    {
      code: "instant_payouts_currency_disabled",
      message:
        "This connected account is not eligible for Instant Payouts in this currency. Ask the platform to enable Instant Payouts in this currency.",
      messageES:
        "Esta cuenta conectada no es elegible para pagos instantáneos en esta moneda. Pida a la plataforma que habilite pagos instantáneos en esta moneda.",
    },
    {
      code: "instant_payouts_limit_exceeded",
      message:
        "You have reached your daily processing limits for Instant Payouts.",
      messageES:
        "Ha alcanzado sus límites de procesamiento diarios para pagos instantáneos.",
    },
    {
      code: "instant_payouts_unsupported",
      message:
        "This card is not eligible for Instant Payouts. Try a debit card from a supported bank.",
      messageES:
        "Esta tarjeta no es elegible para pagos instantáneos. Pruebe con una tarjeta de débito de un banco admitido",
    },
    {
      code: "insufficient_funds",
      message:
        "The customer’s account has insufficient funds to cover this payment.",
      messageES:
        "La cuenta del cliente no tiene fondos suficientes para cubrir este pago.",
    },
    {
      code: "intent_invalid_state",
      message:
        "Intent is not in the state that is required to perform the operation.",
      messageES:
        "La intención no está en el estado requerido para realizar la operación.",
    },
    {
      code: "intent_verification_method_missing",
      message:
        "Intent does not have verification method specified in its PaymentMethodOptions object.",
      messageES:
        "La intención no tiene un método de verificación especificado en su objeto PaymentMethodOptions.",
    },
    {
      code: "invalid_card_type",
      message:
        "The card provided as an external account is not supported for payouts. Provide a non-prepaid debit card instead.",
      messageES:
        "La tarjeta proporcionada como cuenta externa no es compatible con los pagos. Proporcione una tarjeta de débito no prepaga en su lugar",
    },
    {
      code: "invalid_characters",
      message:
        "This value provided to the field contains characters that are unsupported by the field.",
      messageES:
        "Este valor proporcionado al campo contiene caracteres que el campo no admite.",
    },
    {
      code: "invalid_charge_amount",
      message:
        "The specified amount is invalid. The charge amount must be a positive integer in the smallest currency unit, and not exceed the minimum or maximum amount.",
      messageES:
        "La cantidad especificada no es válida. El monto del cargo debe ser un número entero positivo en la unidad monetaria más pequeña y no exceder el monto mínimo o máximo.",
    },
    {
      code: "invalid_cvc",
      message:
        "The card’s security code is invalid. Check the card’s security code or use a different card.",
      messageES:
        "El código de seguridad de la tarjeta no es válido. Verifique el código de seguridad de la tarjeta o use una tarjeta diferente.",
    },
    {
      code: "invalid_expiry_month",
      message:
        "The card’s expiration month is incorrect. Check the expiration date or use a different card.",
      messageES:
        "El mes de vencimiento de la tarjeta es incorrecto. Verifique la fecha de vencimiento o use una tarjeta diferente.",
    },
    {
      code: "invalid_expiry_year",
      message:
        "The card’s expiration year is incorrect. Check the expiration date or use a different card.",
      messageES:
        "El año de vencimiento de la tarjeta es incorrecto. Verifique la fecha de vencimiento o use una tarjeta diferente.",
    },
    {
      code: "invalid_number",
      message:
        "The card number is invalid. Check the card details or use a different card.",
      messageES:
        "El número de tarjeta no es válido. Verifique los detalles de la tarjeta o use una tarjeta diferente.",
    },
    {
      code: "invalid_source_usage",
      message:
        "The source cannot be used because it is not in the correct state (e.g., a charge request is trying to use a source with a pending, failed, or consumed source). Check the status of the source you are attempting to use.",
      messageES:
        "La fuente no se puede usar porque no está en el estado correcto (por ejemplo, una solicitud de cargo está tratando de usar una fuente con una fuente pendiente, fallida o consumida). Compruebe el estado de la fuente que está intentando utilizar.",
    },
    {
      code: "invoice_no_customer_line_items",
      message:
        "An invoice cannot be generated for the specified customer as there are no pending invoice items. Check that the correct customer is being specified or create any necessary invoice items first.",
      messageES:
        "No se puede generar una factura para el cliente especificado ya que no hay elementos de factura pendientes. Verifique que se esté especificando el cliente correcto o cree primero los elementos de factura necesarios.",
    },
    {
      code: "invoice_no_payment_method_types",
      message:
        "An invoice cannot be finalized because there are no payment method types available to process the payment. Your invoice template settings or the invoice’s payment_settings might be restricting which payment methods are available, or you might need to activate more payment methods in the Dashboard.",
      messageES:
        "No se puede finalizar una factura porque no hay tipos de métodos de pago disponibles para procesar el pago. La configuración de su plantilla de factura o la configuración de pago de la factura pueden estar restringiendo qué métodos de pago están disponibles, o es posible que deba activar más métodos de pago en el Tablero.",
    },
    {
      code: "invoice_no_subscription_line_items",
      message:
        "An invoice cannot be generated for the specified subscription as there are no pending invoice items. Check that the correct subscription is being specified or create any necessary invoice items first.",
      messageES:
        "No se puede generar una factura para la suscripción especificada porque no hay elementos de factura pendientes. Verifique que se esté especificando la suscripción correcta o cree primero los elementos de factura necesarios.",
    },
    {
      code: "invoice_not_editable",
      message:
        "The specified invoice can no longer be edited. Instead, consider creating additional invoice items that will be applied to the next invoice. You can either manually generate the next invoice or wait for it to be automatically generated at the end of the billing cycle.",
      messageES:
        "La factura especificada ya no se puede editar. En su lugar, considere crear elementos de factura adicionales que se aplicarán a la próxima factura. Puede generar manualmente la siguiente factura o esperar a que se genere automáticamente al final del ciclo de facturación.",
    },
    {
      code: "invoice_on_behalf_of_not_editable",
      message:
        "You cannot update the on_behalf_of property of an invoice after the invoice has been assigned a number.",
      messageES:
        "No puede actualizar la propiedad on_behalf_of de una factura después de que se haya asignado un número a la factura.",
    },
    {
      code: "invoice_payment_intent_requires_action",
      message:
        "This payment requires additional user action before it can be completed successfully. Payment can be completed using the PaymentIntent associated with the invoice. See this page for more details.",
      messageES:
        "Este pago requiere una acción adicional del usuario antes de que pueda completarse con éxito. El pago se puede completar utilizando el PaymentIntent asociado con la factura. Mira esta pagina para mas detalles.",
    },
    {
      code: "invoice_upcoming_none",
      message:
        "There is no upcoming invoice on the specified customer to preview. Only customers with active subscriptions or pending invoice items have invoices that can be previewed.",
      messageES:
        "No hay una factura próxima en el cliente especificado para obtener una vista previa. Solo los clientes con suscripciones activas o elementos de factura pendientes tienen facturas que se pueden previsualizar.",
    },
    {
      code: "livemode_mismatch",
      message:
        "Test and live mode API keys, requests, and objects are only available within the mode they are in.",
      messageES:
        "Las claves, las solicitudes y los objetos de API en modo de prueba y en vivo solo están disponibles en el modo en el que se encuentran.",
    },
    {
      code: "lock_timeout",
      message:
        "This object cannot be accessed right now because another API request or Stripe process is currently accessing it. If you see this error intermittently, retry the request. If you see this error frequently and are making multiple concurrent requests to a single object, make your requests serially or at a lower rate. See the rate limit documentation for more details.",
      messageES:
        "No se puede acceder a este objeto en este momento porque otra solicitud de API o proceso de Stripe está accediendo actualmente. Si ve este error de forma intermitente, vuelva a intentar la solicitud. Si ve este error con frecuencia y está realizando varias solicitudes simultáneas a un solo objeto, realice sus solicitudes en serie o a una tasa más baja. Consulte la documentación del límite de velocidad para obtener más detalles.",
    },
    {
      code: "lost_card",
      message:
        "The card has been declined. When a card is declined, the error returned also includes the decline_code attribute with the reason why the card was declined. Refer to our decline codes documentation to learn more.",
      messageES:
        "La tarjeta ha sido rechazada. Cuando se rechaza una tarjeta, el error devuelto también incluye el atributo declin_code con el motivo por el cual se rechazó la tarjeta. Consulte nuestra documentación de códigos de rechazo para obtener más información.",
    },
    {
      code: "missing",
      message:
        "Both a customer and source ID have been provided, but the source has not been saved to the customer. To create a charge for a customer with a specified source, you must first save the card details.",
      messageES:
        "Se proporcionó un ID de cliente y de fuente, pero la fuente no se guardó en el cliente. Para crear un cargo para un cliente con una fuente específica, primero debe guardar los detalles de la tarjeta.",
    },
    {
      code: "no_account",
      message: "The bank account could not be located.",
      messageES: "No se pudo ubicar la cuenta bancaria.",
    },
    {
      code: "not_allowed_on_standard_account",
      message:
        "Transfers and payouts on behalf of a Standard connected account are not allowed.",
      messageES:
        "No se permiten transferencias ni pagos en nombre de una cuenta conectada estándar.",
    },
    {
      code: "out_of_inventory",
      message:
        "One or more line item(s) are out of stock. If more stock is available, update the inventory’s orderable quantity and try again.",
      messageES:
        "Uno o más artículos de línea están agotados. Si hay más existencias disponibles, actualice la cantidad que se puede pedir del inventario y vuelva a intentarlo.",
    },
    {
      code: "parameter_invalid_empty",
      message:
        "One or more required values were not provided. Make sure requests include all required parameters.",
      messageES:
        "No se proporcionaron uno o más valores requeridos. Asegúrese de que las solicitudes incluyan todos los parámetros necesarios.",
    },
    {
      code: "parameter_invalid_integer",
      message:
        "One or more of the parameters requires an integer, but the values provided were a different type. Make sure that only supported values are provided for each attribute. Refer to our API documentation to look up the type of data each attribute supports.",
      messageES:
        "Uno o más de los parámetros requiere un número entero, pero los valores proporcionados eran de un tipo diferente. Asegúrese de que solo se proporcionen valores admitidos para cada atributo. Consulte la documentación de nuestra API para buscar el tipo de datos que admite cada atributo.",
    },
    {
      code: "parameter_invalid_string_blank",
      message:
        "One or more values provided only included whitespace. Check the values in your request and update any that contain only whitespace.",
      messageES:
        "Uno o más valores proporcionados solo incluían espacios en blanco. Verifique los valores en su solicitud y actualice cualquiera que contenga solo espacios en blanco.",
    },
    {
      code: "parameter_invalid_string_empty",
      message:
        "One or more required string values is empty. Make sure that string values contain at least one character.",
      messageES:
        "Uno o más valores de cadena obligatorios están vacíos. Asegúrese de que los valores de cadena contengan al menos un carácter.",
    },
    {
      code: "parameter_missing",
      message:
        "One or more required values are missing. Check our API documentation to see which values are required to create or modify the specified resource.",
      messageES:
        "Faltan uno o más valores obligatorios. Consulte la documentación de nuestra API para ver qué valores se requieren para crear o modificar el recurso especificado.",
    },
    {
      code: "parameter_unknown",
      message:
        "The request contains one or more unexpected parameters. Remove these and try again.",
      messageES:
        "La solicitud contiene uno o más parámetros inesperados. Elimínelos y vuelva a intentarlo.",
    },
    {
      code: "parameters_exclusive",
      message:
        "Two or more mutually exclusive parameters were provided. Check our API documentation or the returned error message to see which values are permitted when creating or modifying the specified resource.",
      messageES:
        "Se proporcionaron dos o más parámetros mutuamente excluyentes. Consulte la documentación de nuestra API o el mensaje de error devuelto para ver qué valores están permitidos al crear o modificar el recurso especificado.",
    },
    {
      code: "payment_intent_action_required",
      message:
        "The provided payment method requires customer actions to complete, but error_on_requires_action was set. If you’d like to add this payment method to your integration, we recommend that you first upgrade your integration to handle actions.",
      messageES:
        "El método de pago proporcionado requiere acciones del cliente para completarse, pero se configuró error_on_requires_action. Si desea agregar este método de pago a su integración, le recomendamos que primero actualice su integración para manejar acciones.",
    },
    {
      code: "payment_intent_authentication_failure",
      message:
        "The provided payment method has failed authentication. Provide a new payment method to attempt to fulfill this PaymentIntent again.",
      messageES:
        "El método de pago proporcionado no ha superado la autenticación. Proporcione un nuevo método de pago para intentar cumplir con este PaymentIntent nuevamente.",
    },
    {
      code: "payment_intent_incompatible_payment_method",
      message:
        "The PaymentIntent expected a payment method with different properties than what was provided.",
      messageES:
        "PaymentIntent esperaba un método de pago con propiedades diferentes a las proporcionadas.",
    },
    {
      code: "payment_intent_invalid_parameter",
      message:
        "One or more provided parameters was not allowed for the given operation on the PaymentIntent. Check our API reference or the returned error message to see which values were not correct for that PaymentIntent.",
      messageES:
        "Uno o más parámetros proporcionados no se permitieron para la operación dada en PaymentIntent. Consulte nuestra referencia de API o el mensaje de error devuelto para ver qué valores no eran correctos para ese PaymentIntent.",
    },
    {
      code: "payment_intent_konbini_rejected_confirmation_number",
      message:
        "The confirmation_number provided in payment_method_options[konbini] was rejected by the processing partner at time of PaymentIntent confirmation.",
      messageES:
        "El número de confirmación proporcionado en las opciones de método de pago [konbini] fue rechazado por el socio de procesamiento en el momento de la confirmación de PaymentIntent.",
    },
    {
      code: "payment_intent_mandate_invalid",
      message:
        "The provided mandate is invalid and can not be used for the payment intent.",
      messageES:
        "El mandato proporcionado no es válido y no se puede utilizar para la intención de pago.",
    },
    {
      code: "payment_intent_payment_attempt_expired",
      message:
        "The latest payment attempt for the PaymentIntent has expired. Check the last_payment_error property on the PaymentIntent for more details, and provide a new payment method to attempt to fulfill this PaymentIntent again.",
      messageES:
        "El último intento de pago para PaymentIntent ha caducado. Verifique la propiedad last_payment_error en PaymentIntent para obtener más detalles y proporcione un nuevo método de pago para intentar cumplir con este PaymentIntent nuevamente.",
    },
    {
      code: "payment_intent_payment_attempt_failed",
      message:
        "The latest payment attempt for the PaymentIntent has failed. Check the last_payment_error property on the PaymentIntent for more details, and provide a new payment method to attempt to fulfill this PaymentIntent again.",
      messageES:
        "El último intento de pago para PaymentIntent ha fallado. Verifique la propiedad last_payment_error en PaymentIntent para obtener más detalles y proporcione un nuevo método de pago para intentar cumplir con este PaymentIntent nuevamente.",
    },
    {
      code: "payment_intent_unexpected_state",
      message:
        "The PaymentIntent’s state was incompatible with the operation you were trying to perform.",
      messageES:
        "El estado de PaymentIntent era incompatible con la operación que intentabas realizar",
    },
    {
      code: "payment_method_bank_account_already_verified",
      message: "This bank account has already been verified.",
      messageES: "Esta cuenta bancaria ya ha sido verificada.",
    },
    {
      code: "payment_method_bank_account_blocked",
      message:
        "This bank account has failed verification in the past and can not be used. Contact us if you wish to attempt to use these bank account credentials.",
      messageES:
        "Esta cuenta bancaria ha fallado en la verificación en el pasado y no se puede utilizar. Póngase en contacto con nosotros si desea intentar utilizar estas credenciales de cuenta bancaria.",
    },
    {
      code: "payment_method_billing_details_address_missing",
      message:
        "The PaymentMethod’s billing details is missing address details. Please update the missing fields and try again.",
      messageES:
        "Faltan detalles de la dirección en los detalles de facturación del método de pago. Actualice los campos que faltan y vuelva a intentarlo.",
    },
    {
      code: "payment_method_currency_mismatch",
      message:
        "The currency specified does not match the currency for the attached payment method. A payment can only be created for the same currency as the corresponding payment method.",
      messageES:
        "La moneda especificada no coincide con la moneda del método de pago adjunto. Solo se puede crear un pago para la misma moneda que el método de pago correspondiente.",
    },
    {
      code: "payment_method_invalid_parameter",
      message:
        "Invalid parameter was provided in the payment method object. Check our API documentation or the returned error message for more context.",
      messageES:
        "Se proporcionó un parámetro no válido en el objeto de método de pago. Consulte la documentación de nuestra API o el mensaje de error devuelto para obtener más contexto.",
    },
    {
      code: "payment_method_invalid_parameter_testmode",
      message:
        "The parameter provided for payment method is not allowed to be used in testmode. Check our API documentation or the returned error message for more context.",
      messageES:
        "El parámetro proporcionado para el método de pago no se puede utilizar en modo de prueba. Consulte la documentación de nuestra API o el mensaje de error devuelto para obtener más contexto.",
    },
    {
      code: "payment_method_microdeposit_failed",
      message:
        "Microdeposits were failed to be deposited into the customer’s bank account. Please check the account, institution and transit numbers as well as the currency type.",
      messageES:
        "Los microdepósitos no se depositaron en la cuenta bancaria del cliente. Verifique los números de cuenta, institución y tránsito, así como el tipo de moneda.",
    },
    {
      code: "payment_method_microdeposit_verification_amounts_invalid",
      message: "You must provide exactly two microdeposit amounts.",
      messageES: "Debe proporcionar exactamente dos montos de microdepósito.",
    },
    {
      code: "payment_method_microdeposit_verification_amounts_mismatch",
      message:
        "The amounts provided do not match the amounts that were sent to the bank account.",
      messageES:
        "Los montos proporcionados no coinciden con los montos que se enviaron a la cuenta bancaria.",
    },
    {
      code: "payment_method_microdeposit_verification_attempts_exceeded",
      message: "You have exceeded the number of allowed verification attempts.",
      messageES:
        "Has superado el número de intentos de verificación permitidos.",
    },
    {
      code: "payment_method_microdeposit_verification_descriptor_code_mismatch",
      message:
        "The verification code provided does not match the one sent to the bank account.",
      messageES:
        "El código de verificación proporcionado no coincide con el enviado a la cuenta bancaria.",
    },
    {
      code: "payment_method_microdeposit_verification_timeout",
      message:
        "Payment method should be verified with microdeposits within the required period.",
      messageES:
        "El método de pago debe verificarse con microdepósitos dentro del período requerido.",
    },
    {
      code: "payment_method_provider_decline",
      message:
        "The payment or setup attempt was declined by the issuer or customer. Check the last_payment_error or last_setup_error property on the PaymentIntent or SetupIntent respectively for more details, and provide a new payment method to attempt to fulfill this intent again.",
      messageES:
        "El emisor o el cliente rechazó el pago o el intento de configuración. Verifique la propiedad last_payment_error o last_setup_error en PaymentIntent o SetupIntent respectivamente para obtener más detalles y proporcione un nuevo método de pago para intentar cumplir con esta intención nuevamente.",
    },
    {
      code: "payment_method_provider_timeout",
      message:
        "The payment method failed due to a timeout. Check the last_payment_error or last_setup_error property on the PaymentIntent or SetupIntent respectively for more details, and provide a new payment method to attempt to fulfill this intent again.",
      messageES:
        "El método de pago falló debido a un tiempo de espera. Verifique la propiedad last_payment_error o last_setup_error en PaymentIntent o SetupIntent respectivamente para obtener más detalles y proporcione un nuevo método de pago para intentar cumplir con esta intención nuevamente.",
    },
    {
      code: "payment_method_unactivated",
      message:
        "The operation cannot be performed as the payment method used has not been activated. Activate the payment method in the Dashboard, then try again.",
      messageES:
        "No se puede realizar la operación porque no se ha activado el método de pago utilizado. Active el método de pago en el Panel de control y vuelva a intentarlo.",
    },
    {
      code: "payment_method_unexpected_state",
      message:
        "The provided payment method’s state was incompatible with the operation you were trying to perform. Confirm that the payment method is in an allowed state for the given operation before attempting to perform it.",
      messageES:
        "El estado del método de pago proporcionado no era compatible con la operación que intentaba realizar. Confirme que el método de pago se encuentra en un estado permitido para la operación dada antes de intentar realizarla.",
    },
    {
      code: "payment_method_unsupported_type",
      message: "The API only supports payment methods of certain types.",
      messageES: "La API solo admite métodos de pago de ciertos tipos.",
    },
    {
      code: "payouts_not_allowed",
      message:
        "Payouts have been disabled on the connected account. Check the connected account’s status to see if any additional information needs to be provided, or if payouts have been disabled for another reason.",
      messageES:
        "Los pagos se han deshabilitado en la cuenta conectada. Verifique el estado de la cuenta conectada para ver si se necesita proporcionar información adicional o si los pagos se han deshabilitado por algún otro motivo.",
    },
    {
      code: "platform_account_required",
      message:
        "Only Stripe Connect platforms can work with other accounts. If you need to setup a Stripe Connect platform, you can do so in the dashboard.",
      messageES:
        "Solo las plataformas Stripe Connect pueden funcionar con otras cuentas. Si necesita configurar una plataforma Stripe Connect, puede hacerlo en el tablero",
    },
    {
      code: "platform_api_key_expired",
      message:
        "The API key provided by your Connect platform has expired. This occurs if your platform has either generated a new key or the connected account has been disconnected from the platform. Obtain your current API keys from the Dashboard and update your integration, or reach out to the user and reconnect the account.",
      messageES:
        "La clave API proporcionada por su plataforma Connect ha caducado. Esto ocurre si su plataforma ha generado una clave nueva o si la cuenta conectada se ha desconectado de la plataforma. Obtenga sus claves API actuales del Panel y actualice su integración, o comuníquese con el usuario y vuelva a conectar la cuenta.",
    },
    {
      code: "postal_code_invalid",
      message: "The postal code provided was incorrect.",
      messageES: "El código postal proporcionado era incorrecto.",
    },
    {
      code: "processing_error",
      message:
        "An error occurred while processing the card. Try again later or with a different payment method.",
      messageES:
        "Ocurrió un error al procesar la tarjeta. Vuelve a intentarlo más tarde o con otro método de pago.",
    },
    {
      code: "product_inactive",
      message:
        "The product this SKU belongs to is no longer available for purchase.",
      messageES:
        "El producto al que pertenece este SKU ya no está disponible para su compra.",
    },
    {
      code: "rate_limit",
      message:
        "Too many requests hit the API too quickly. We recommend an exponential backoff of your requests.",
      messageES:
        "Demasiadas solicitudes llegan a la API demasiado rápido. Recomendamos un retroceso exponencial de sus solicitudes.",
    },
    {
      code: "refer_to_customer",
      message:
        "The customer has stopped the payment with their bank. Contact them for details and to arrange payment.",
      messageES:
        "El cliente ha detenido el pago con su banco. Póngase en contacto con ellos para más detalles y para organizar el pago.",
    },
    {
      code: "refund_disputed_payment",
      message: "You cannot refund a disputed payment.",
      messageES: "No puede reembolsar un pago en disputa.",
    },
    {
      code: "resource_already_exists",
      message:
        "A resource with a user-specified ID (e.g., plan or coupon) already exists. Use a different, unique value for id and try again.",
      messageES:
        "Ya existe un recurso con un ID especificado por el usuario (p. ej., plan o cupón). Use un valor diferente y único para id e inténtelo de nuevo.",
    },
    {
      code: "resource_missing",
      message:
        "The ID provided is not valid. Either the resource does not exist, or an ID for a different resource has been provided.",
      messageES:
        "La identificación proporcionada no es válida. El recurso no existe o se proporcionó una ID para un recurso diferente.",
    },
    {
      code: "return_intent_already_processed",
      message: "You cannot confirm this refund as it is already processed.",
      messageES: "No puede confirmar este reembolso porque ya está procesado.",
    },
    {
      code: "routing_number_invalid",
      message: "The bank routing number provided is invalid.",
      messageES: "El número de ruta bancaria proporcionado no es válido.",
    },
    {
      code: "secret_key_required",
      message:
        "The API key provided is a publishable key, but a secret key is required. Obtain your current API keys from the Dashboard and update your integration to use them.",
      messageES:
        "La clave API proporcionada es una clave publicable, pero se requiere una clave secreta. Obtenga sus claves API actuales del Panel y actualice su integración para usarlas.",
    },
    {
      code: "sepa_unsupported_account",
      message: "Your account does not support SEPA payments.",
      messageES: "Su cuenta no admite pagos SEPA.",
    },
    {
      code: "setup_attempt_failed",
      message:
        "The latest setup attempt for the SetupIntent has failed. Check the last_setup_error property on the SetupIntent for more details, and provide a new payment method to attempt to set it up again.",
      messageES:
        "El último intento de configuración para SetupIntent ha fallado. Verifique la propiedad last_setup_error en SetupIntent para obtener más detalles y proporcione un nuevo método de pago para intentar configurarlo nuevamente.",
    },
    {
      code: "setup_intent_authentication_failure",
      message:
        "The provided payment method has failed authentication. Provide a new payment method to attempt to fulfill this SetupIntent again.",
      messageES:
        "The provided payment method has failed authentication. Provide a new payment method to attempt to fulfill this SetupIntent again.",
    },
    {
      code: "setup_intent_invalid_parameter",
      message:
        "One or more provided parameters was not allowed for the given operation on the SetupIntent. Check our API reference or the returned error message to see which values were not correct for that SetupIntent.",
      messageES:
        "Uno o más parámetros proporcionados no se permitieron para la operación dada en SetupIntent. Consulte nuestra referencia de API o el mensaje de error devuelto para ver qué valores no eran correctos para ese SetupIntent.",
    },
    {
      code: "setup_intent_setup_attempt_expired",
      message:
        "The latest setup attempt for the SetupIntent has expired. Check the last_setup_error property on the SetupIntent for more details, and provide a new payment method to attempt to complete this SetupIntent again.",
      messageES:
        "The latest setup attempt for the SetupIntent has expired. Check the last_setup_error property on the SetupIntent for more details, and provide a new payment method to attempt to complete this SetupIntent again.",
    },
    {
      code: "setup_intent_unexpected_state",
      message:
        "The SetupIntent’s state was incompatible with the operation you were trying to perform.",
      messageES:
        "El estado de SetupIntent era incompatible con la operación que intentabas realizar.",
    },
    {
      code: "shipping_calculation_failed",
      message:
        "Shipping calculation failed as the information provided was either incorrect or could not be verified.",
      messageES:
        "El cálculo del envío falló porque la información proporcionada era incorrecta o no se pudo verificar.",
    },
    {
      code: "sku_inactive",
      message:
        "The SKU is inactive and no longer available for purchase. Use a different SKU, or make the current SKU active again.",
      messageES:
        "El SKU está inactivo y ya no está disponible para la compra. Utilice un SKU diferente o vuelva a activar el SKU actual.",
    },
    {
      code: "state_unsupported",
      message:
        "Occurs when providing the legal_entity information for a U.S. custom account, if the provided state is not supported. (This is mostly associated states and territories.)",
      messageES:
        "Ocurre cuando se proporciona la información de entidad legal para una cuenta personalizada de EE. UU., si el estado proporcionado no es compatible. (Esto es principalmente estados y territorios asociados",
    },
    {
      code: "status_transition_invalid",
      message: "The requested status transition is not valid.",
      messageES: "La transición de estado solicitada no es válida.",
    },
    {
      code: "tax_id_invalid",
      message:
        "The tax ID number provided is invalid (e.g., missing digits). Tax ID information varies from country to country, but must be at least nine digits.",
      messageES:
        "El número de identificación fiscal proporcionado no es válido (por ejemplo, faltan dígitos). La información de identificación fiscal varía de un país a otro, pero debe tener al menos nueve dígitos.",
    },
    {
      code: "taxes_calculation_failed",
      message: "Tax calculation for the order failed.",
      messageES: "Ha fallado el cálculo de impuestos para el pedido.",
    },
    {
      code: "terminal_location_country_unsupported",
      message:
        "Terminal is currently only available in some countries. Locations in your country cannot be created in livemode.",
      messageES:
        "La terminal actualmente solo está disponible en algunos países. Las ubicaciones en su país no se pueden crear en modo en vivo.",
    },
    {
      code: "testmode_charges_only",
      message:
        "Your account has not been activated and can only make test charges. Activate your account in the Dashboard to begin processing live charges.",
      messageES:
        "Su cuenta no ha sido activada y solo puede realizar cargos de prueba. Active su cuenta en el Tablero para comenzar a procesar cargos en vivo.",
    },
    {
      code: "tls_version_unsupported",
      message:
        "Your integration is using an older version of TLS that is unsupported. You must be using TLS 1.2 or above.",
      messageES:
        "Su integración utiliza una versión anterior de TLS que no es compatible. Debe utilizar TLS 1.2 o superior.",
    },
    {
      code: "token_already_used",
      message:
        "The token provided has already been used. You must create a new token before you can retry this request.",
      messageES:
        "El token proporcionado ya se ha utilizado. Debe crear un nuevo token antes de poder volver a intentar esta solicitud.",
    },
    {
      code: "token_in_use",
      message:
        "The token provided is currently being used in another request. This occurs if your integration is making duplicate requests simultaneously.",
      messageES:
        "El token proporcionado se está utilizando actualmente en otra solicitud. Esto ocurre si su integración está realizando solicitudes duplicadas simultáneamente.",
    },
    {
      code: "transfer_source_balance_parameters_mismatch",
      message:
        "When creating a Transfer, the payments parameter in source_balance should not be passed in when balance type is set to issuing.",
      messageES:
        "Al crear una transferencia, el parámetro de pagos en source_balance no se debe pasar cuando el tipo de saldo se establece en emisión.",
    },
    {
      code: "transfers_not_allowed",
      message:
        "The requested transfer cannot be created. Contact us if you are receiving this error.",
      messageES:
        "No se puede crear la transferencia solicitada. Póngase en contacto con nosotros si recibe este error.",
    },
    {
      code: "url_invalid",
      message: "The URL provided is invalid.",
      messageES: "La URL proporcionada no es válida.",
    },
  ].filter((error) => error.code === declineCode);
  return error;
};
