import React, { useState } from "react";
import { Table, message } from "antd";
import arrayMove from "array-move";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { Modal, TextField, Banner } from "@shopify/polaris";
import { DragHandleMinor } from "@shopify/polaris-icons";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import GhostButton from "../common/GhostButton";

const DragHandle = sortableHandle(() => (
  <DragHandleMinor
    style={{
      cursor: "grab",
      fill: "#5c6ac4",
      inlineSize: "20px",
      margin: "auto",
    }}
  />
));

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const DragNDrop = ({ tags, filterData }) => {
  const columns = [
    {
      title: "Opciones del filtro",
      width: 100,
      dataIndex: "index",
      className: "sort-drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "",
      dataIndex: "name",
      className: "name-subTagDrag-visible",
    },
    {
      title: () => (
        <GhostButton
          label="Guardando"
          secondLabel="Guardado"
          isSaved={isSaved}
        />
      ),
      dataIndex: "index",
      align: "right",
      className: "actions-drag-visible pl-0",
      render: () => <div className="flex justify-end" />,
    },
  ];
  const [isOpen, setOpen] = useState(false);
  const [isSaved, setSaveStatus] = useState(false);
  const [tagName, setTagName] = useState("");
  const [dataSource, setDataSource] = useState(tags);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      setSaveStatus(true);

      const newData = arrayMove(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      let filteredTags = [];
      newData.forEach((tags) => {
        filteredTags.push(Object.values(tags)[0]);
      });

      setDataSource(newData);
      filterData.tags = filteredTags;
      handleSendRequest(filterData._id, filterData, "tags");
    }
  };

  const DraggableContainer = (props) => (
    <div className="flex xs:flex max-w-sm sm:flex max-w-sm md:contents lg:contents">
      <SortableContainer
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={onSortEnd}
        {...props}
      />
    </div>
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  const handleSaveById = () => {
    filterData.tags[0] = tagName;
    handleSendRequest(filterData._id, filterData, "title");
  };

  const handleError = (err) => {
    message.error("Ops, ocurrió un error, intente más tarde.");
  };

  const handleSendRequest = (id, filterDataById, name) =>
    ApiServiceComercios.putShopFilterById(id, filterDataById)
      .then((res) => {
        if (name === "title") handleOnClose();
        setSaveStatus(false);
      })
      .catch((err) => handleError(err));

  return (
    <div className="flex xs:flex max-w-sm sm:flex max-w-sm md:contents lg:contents">
      <Table
        className="dragDrop"
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
      {isOpen && (
        <Modal
          title="Editar opción del filtro"
          onClose={handleOnClose}
          open={isOpen}
          primaryAction={{
            content: "Agregar",
            onAction: handleSaveById,
          }}
          secondaryActions={[{ content: "Cancelar", onAction: handleOnClose }]}>
          <Modal.Section>
            <div className="flex flex-col pt-8 pb-8">
              <div className="flex flex-col px-6">
                <TextField
                  label="Nombre de etiqueta"
                  value={tagName}
                  onChange={(name) => setTagName(name)}
                />
              </div>
              <div className="flex flex-col pt-10">
                <Banner status="info">
                  <p>
                    Agrega una lista de etiquetas de productos separadas por
                    comas. Solo se mostrarán en el filtro las etiquetas
                    encontradas en los productos.
                  </p>
                </Banner>
              </div>
            </div>
          </Modal.Section>
        </Modal>
      )}
    </div>
  );
};

export default DragNDrop;
