import React, { useEffect, useState } from 'react'
import { Card, FormLayout, Layout, Page, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, SkeletonThumbnail, Stack, TextField } from '@shopify/polaris'
import { Layer } from '../../components'
import ApiServiceComercios from '../../services/ApiServiceComercios'
import { useDispatch } from 'react-redux'
import { toggleToast } from '../../actions/InteractiveActions'

export default function ImpuestosScreen({ forbidden }) {
   const dispatch = useDispatch();
   const [isLoading, setIsLoading] = useState(true);
   const [isEditing, setIsEditing] = useState(false);
   const [impuestoDB, setImpuesto] = useState({ nombre: "", impuesto: "0" });
   useEffect(() => {
      ApiServiceComercios.obtenerImpuestoComercio()
         .then(({ ok, impuesto }) => {
            if (ok) {
               setImpuesto({
                  nombre: impuesto.nombre,
                  impuesto: String(impuesto.impuesto)
               });
            }
         }).finally((err) => {
            setIsLoading(false);
         });
   }, []);

   function handleSave() {
      setIsEditing(true);
      ApiServiceComercios.actualizarImpuestoComercio({ ...impuestoDB })
         .then(({ ok, message }) => {
            if (ok) {
               dispatch(toggleToast({ message }));
            }
         }).finally((err) => {
            setIsEditing(false);
         });
   }

   function onChangeState(value, key) {
      setImpuesto((s) => ({ ...s, [key]: value }));
   }

   function onBlurImpuesto() {
      if (Number(impuestoDB.impuesto) > 99) {
         onChangeState("99", "impuesto")
      }
      if (Number(impuestoDB.impuesto) < 0 || !impuestoDB.impuesto) {
         onChangeState("0", "impuesto")
      }
   }

   if (isLoading) {
      return (
         <Layer title="Envío y entrega" forbidden={forbidden}>
            <SkeletonPage
               breadcrumbs
               title={<SkeletonDisplayText size="medium" />}
               primaryAction={<SkeletonDisplayText size="medium" />}
            >
               <Layout>
                  <Layout.AnnotatedSection title={<SkeletonDisplayText size="medium" />} description={<SkeletonBodyText lines={2} />} >
                     {Array.from(Array(1).keys()).map((item) => {
                        return (
                           <div key={item} className="mb-8">
                              <Card sectioned  >
                                 <Stack>
                                    <SkeletonThumbnail size="small" />
                                    <div style={{ minWidth: 300 }}>
                                       <SkeletonBodyText lines={2} />
                                    </div>
                                 </Stack>
                              </Card>
                           </div>
                        )
                     })}
                  </Layout.AnnotatedSection>
               </Layout>
            </SkeletonPage>
         </Layer>
      );
   }

   return (
      <Layer title="Impuestos" forbidden={forbidden}>
         <Page
            separator
            title="Impuestos"
            breadcrumbs={[{ content: "Impuestos", url: '/admin/configuracion/impuestos' }]}
            primaryAction={{
               "content": "Guardar",
               "onAction": handleSave,
               "disabled": isEditing || !impuestoDB.nombre,
               "loading": isEditing
            }}
         >
            <Layout>
               <Layout.AnnotatedSection
                  title="Impuestos"
                  description="Registra los diferentes impuestos que se aplican a los productos y servicios al venderlos."
               >
                  <Card sectioned>
                     <FormLayout>
                        <TextField
                           disabled={isEditing}
                           label="Nombre del impuesto"
                           value={impuestoDB.nombre}
                           onChange={(value) => onChangeState(value, "nombre")}
                        />
                        <TextField
                           disabled={isEditing}
                           label="Tasa"
                           suffix="%"
                           align="right"
                           type="number"
                           max={99}
                           min={0}
                           value={impuestoDB.impuesto}
                           onChange={(value) => onChangeState(value, "impuesto")}
                           onBlur={onBlurImpuesto}
                        />
                     </FormLayout>
                  </Card>
               </Layout.AnnotatedSection>
            </Layout>
         </Page>
      </Layer>
   )
}
