import React from "react";
import { Page, Card, Layout } from "@shopify/polaris";
import { useHistory } from "react-router-dom";
import { Layer, ConfigButton, HelpFooter } from "../../components";
import ability from "../../auth/ability";
import { useSelector } from "react-redux";
import { getPermissions } from "../../reducers";

export default function ComercioConfiguracionScreen({ forbidden }) {
  const router = useHistory();
  const permissions = useSelector(getPermissions);

  const sections = [
    {
      text: "General",
      secondary: "Ver y actualizar los datos de tu tienda.",
      icon: "HomeMajor",
      onClick: () => router.push("/admin/configuracion/general"),
      can: "General",
    },
    {
      text: "Preferencias",
      secondary:
        "Ajusta los parámetros generales que se utiliza para tu operación.",
      icon: "SettingsMajor",
      onClick: () => router.push("/admin/configuracion/preferencias"),
      can: "Preferencias",
    },
    {
      text: "Plan",
      secondary: "Gestiona tu plan y administra tu método de pago.",
      icon: "GrammarMajor",
      onClick: () => router.push("/admin/configuracion/planes"),
      can: "Plan",
    },
    {
      text: "Pagos",
      secondary: "Gestiona como tus clientes pueden pagar en tu tienda.",
      icon: "PaymentsMajor",
      onClick: () => router.push("/admin/configuracion/pagos"),
      can: "Pagos",
    },
    {
      text: "Usuarios y permisos",
      secondary:
        "Gestiona lo que tus empleados pueden ver o hacer en tu tienda.",
      icon: "ProfileMajor",
      onClick: () => router.push("/admin/configuracion/permisos"),
      can: "Usuarios y permisos",
    },
    {
      text: "Integraciones",
      secondary: "Integra tu comercio con otros sistemas y funcionalidades.",
      icon: "AppsMajor",
      onClick: () => router.push("/admin/configuracion/integraciones"),
      can: "Integraciones",
    },
    {
      text: "Páginas",
      secondary: "Administra las páginas comerciales de tu comercio.",
      icon: "NoteMajor",
      onClick: () => router.push("/admin/configuracion/paginas"),
      can: "Páginas",
    },
    {
      text: "Almacenes",
      secondary:
        "Gestiona los lugares donde almacenas inventario, preparas pedidos y vendes productos.",
      icon: "LocationMajor",
      onClick: () => router.push("/admin/configuracion/sucursales"),
      can: "Sucursales",
    },
    {
      text: "Envío y entrega",
      secondary: "Gestiona cómo los clientes reciben sus pedidos.",
      icon: "ShipmentMajor",
      onClick: () => router.push("/admin/configuracion/envio-entregas"),
      can: "Envío y entrega",
    },
    {
      text: "Impuestos",
      secondary: "Gestiona el modo en el que tu tienda cobra los impuestos.",
      icon: "BillingStatementDollarMajor",
      onClick: () => router.push("/admin/configuracion/impuestos"),
      can: "Impuestos",
    },
    {
      text: "Monedas",
      secondary:
        "Administra los distintos tipos de moneda o divisas que manejas.",
      icon: "CashDollarMajor",
      onClick: () => router.push("/admin/configuracion/monedas"),
      can: "Monedas",
    },
    {
      text: "Chats",
      secondary: "Invita a los clientes a chatear desde cualquier lugar.",
      icon: "ChatMajor",
      onClick: () => router.push("/admin/configuracion/chats"),
      can: "Chats",
    },
    {
      text: "Utilerías",
      secondary:
        "Herramientas y utilidades para trabajar con tu base de datos.",
      icon: "ToolsMajor",
      onClick: () => router.push("/admin/configuracion/utilerias"),
      can: "Utilerías",
    },
    {
      text: "Vendedores y cobradores",
      secondary: "Gestiona a los vendedores y cobradores que atienden a tus clientes.",
      icon: "JobsMajor",
      onClick: () => router.push("/admin/configuracion/vendedores"),
      can: "Vendedores",
    },
  ];

  return (
    <Layer title="Configuración" forbidden={forbidden}>
      <Page fullWidth={false} title="Configuración">
        <Layout>
          <Layout.Section fullWidth>
            <Card>
              <Card.Section>
                <div className="w-full flex flex-wrap">
                  {sections
                    .filter((i) => ability(permissions).can("read", i.can))
                    .map(({ text, secondary, icon, onClick, isNew }) => (
                      <ConfigButton
                        key={text}
                        text={text}
                        secondary={secondary}
                        icon={icon}
                        onClick={onClick}
                        isNew={isNew}
                      />
                    ))}
                </div>
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
        <HelpFooter
          title="configuración"
          url="https://help.b2bgo.mx/configuracion/general"
        />
      </Page>
    </Layer>
  );
}
