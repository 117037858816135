import React, { useState, useRef, useCallback, useEffect } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Banner,
  Card,
  Select,
  FormLayout,
  Icon,
  Modal,
  SkeletonBodyText,
  Stack,
  TextField,
  Button,
  DataTable,
  TextStyle,
} from "@shopify/polaris";
import {
  SearchMajor,
  MobileHorizontalDotsMajor,
  EditMajor,
  DeleteMajor,
} from "@shopify/polaris-icons";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import CustomPopover from "../CustomPopover";
import { onBlurTextField } from "../../utils/TextFieldValidations";

export default function LineaArticuloPromoModal(props) {
  const [lineaArt, setLinea] = useState({
    porcentaje: "1",
    linea: "",
    precioLista: { nombre: "", id: "" },
  });
  const [lineas, setLineas] = useState([]);
  const [line, setLine] = useState("");
  const deselectedOptions = useRef([]);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [preciosLista, setPreciosLista] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setPreciosLista(props.precioLista);
    if (props.precioLista.length > 0)
      setLinea((s) => ({
        ...s,
        precioLista: {
          nombre: props.precioLista[0].label,
          id: props.precioLista[0].value,
        },
      }));
    const updatingLine = props.selected.find((s) => s.editing === true);
    if (updatingLine) {
      setLineas((s) => [
        ...s,
        {
          porcentaje: updatingLine.porcentaje,
          precioLista: updatingLine.precioLista,
          linea: updatingLine.linea,
          isEditing: false,
        },
      ]);
      setLinea((s) => ({
        ...s,
        linea: updatingLine.linea,
        precioLista: updatingLine.precioLista,
        porcentaje: updatingLine.porcentaje,
      }));
      setIsEditing(true);
      setLine(updatingLine.linea);
    }
  }, [props.precioLista, props.selected]);

  useEffect(() => {
    ApiServiceComercios.obtenerLineas()
      .then(({ ok, lineas }) => {
        if (ok) {
          let willAdd = lineas.map((i) => ({
            label: i.nombre,
            value: i.nombre,
          }));
          deselectedOptions.current = willAdd;
          if (props.precioLista.length > 0 && props.selected.length > 0) {
            const precioListaDraftId = props.precioLista[0]["value"];
            const filteredSelected = props.selected
              .filter((i) => i.precioLista.id === precioListaDraftId)
              .map((i) => i.linea);
            willAdd = willAdd.filter(
              (i) => !filteredSelected.includes(i.value)
            );
          }
          setOptions(willAdd);
        }
      })
      .finally(() => setIsLoading(false));
    return () => null;
  }, [props.selected, props.precioLista]);

  function updateText(value) {
    changeState(value, "linea");

    if (value === "") {
      //   setOptions(deselectedOptions);
      return;
    }
    const filterRegex = new RegExp(value, "i");
    const resultOptions = deselectedOptions.current
      .filter(
        (i) =>
          !lineas
            .map((l) => l.lineaPrecio)
            .includes(lineaArt.precioLista.id + i.label)
      )
      .filter((option) => option.label.match(filterRegex));
    setOptions(resultOptions);
  }

  const updateSelection = useCallback((selected) => {
    setSelectedOptions(selected);
    changeState(selected[0], "linea");
    setLine(selected[0]);
  }, []);

  function changeState(value, key) {
    setLinea((state) => ({ ...state, [key]: value }));
  }

  function addLineas() {
    props.onAddLinea(lineas);
    props.togleModal();
  }

  function addLinea() {
    setOptions(options.filter((i) => i.label !== line));
    setLineas((s) => [
      ...s,
      {
        porcentaje: lineaArt.porcentaje,
        lineaPrecio: lineaArt.precioLista.id + line,
        precioLista: lineaArt.precioLista,
        linea: line,
        editing: false,
      },
    ]);
    setLinea((s) => ({ ...s, linea: "", porcentaje: "0" }));
    setLine("");
  }

  function onRemoveItem(item, lineaPrecio) {
    setLineas(lineas.filter((i) => i.lineaPrecio !== lineaPrecio));
    const popDeselected = [...options];
    if (
      lineas.find((i) => i.lineaPrecio === lineaPrecio)["precioLista"]["id"] ===
      lineaArt.precioLista.id
    ) {
      popDeselected.push({ label: item, value: item });
    }
    setOptions(_.orderBy(popDeselected, ["label"], ["asc"]));
  }

  function onEditItem(item, precioListaId) {
    setLine(item);
    setIsEditing(true);
    setLinea((s) => ({
      ...s,
      linea: item,
      precioLista: lineas.find(
        (i) => i.linea === item && i.precioLista.id === precioListaId
      )["precioLista"],
      porcentaje: lineas.find(
        (i) => i.linea === item && i.precioLista.id === precioListaId
      )["porcentaje"],
    }));
  }

  function onSetEdit() {
    let arr = [...lineas];
    const index = _.findIndex(arr, {
      linea: line,
      precioLista: { id: lineaArt.precioLista.id },
    });
    arr.splice(index, 1, {
      porcentaje: lineaArt.porcentaje,
      lineaPrecio: lineaArt.precioLista.id + line,
      precioLista: lineaArt.precioLista,
      linea: line,
      editing: false,
    });
    setLineas(arr);
    setLinea((s) => ({ ...s, linea: "", porcentaje: "0" }));
    setOptions(options.filter((i) => i.label !== line));
    setLine("");
    setIsEditing(false);
  }

  function onSetPrecioLista(value) {
    const linFiltered = lineas
      .filter((l) => l.precioLista.id === value)
      .map((s) => s.linea);
    setOptions(
      deselectedOptions.current.filter((i) => !linFiltered.includes(i.label))
    );
    setLinea((s) => ({
      ...s,
      precioLista: {
        nombre: preciosLista.find((i) => i.value === value)["label"],
        id: value,
      },
    }));
  }

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      prefix={<Icon source={SearchMajor} />}
      label="Línea de producto"
      value={lineaArt.linea}
      placeholder="Buscar líneas de productos"
      helpText={line}
      disabled={isEditing}
    />
  );

  if (isLoading) {
    return (
      <Modal
        title="Agregar línea de producto"
        open={props.isActive}
        onClose={props.togleModal}
      >
        <Modal.Section>
          <Card sectioned>
            <SkeletonBodyText lines={2} />
          </Card>
        </Modal.Section>
      </Modal>
    );
  }

  return (
    <Modal
      title="Agregar línea de producto"
      open={props.isActive}
      onClose={props.togleModal}
      secondaryActions={[{ content: "Cancelar", onAction: props.togleModal }]}
      primaryAction={{
        content: "Continuar",
        onAction: addLineas,
        disabled: lineas.length === 0,
      }}
    >
      <Modal.Section>
        <Card sectioned>
          <FormLayout>
            <div className="stack-fillEvenly">
              <Stack distribution="fillEvenly" wrap>
                <Select
                  label="Lista de precio"
                  options={preciosLista}
                  disabled={isLoading}
                  onChange={onSetPrecioLista}
                  value={lineaArt.precioLista.id}
                />
                <Autocomplete
                  options={options}
                  selected={selectedOptions}
                  onSelect={updateSelection}
                  textField={textField}
                  willLoadMoreResults
                  listTitle="Líneas de productos existentes"
                  loading={isLoading}
                />
              </Stack>
            </div>
            <Stack distribution="fillEvenly" wrap>
              <TextField
                min={1}
                max={100}
                align="right"
                suffix="%"
                type="number"
                label="Valor del descuento"
                value={lineaArt.porcentaje}
                onChange={(value) => changeState(value, "porcentaje")}
                onBlur={() =>
                  onBlurTextField(
                    lineaArt.porcentaje,
                    changeState,
                    100,
                    "porcentaje"
                  )
                }
              />
              <Button
                monochrome
                onClick={isEditing ? onSetEdit : addLinea}
                disabled={Number(lineaArt.porcentaje) === 0 || !line}
              >
                {isEditing ? "Editar" : "Agregar"}
              </Button>
            </Stack>
          </FormLayout>
          {lineas.length > 0 && (
            <DataTable
              headings={[
                "Líneas de productos",
                "Lista de precios",
                "Descuentos",
              ]}
              columnContentTypes={["text", "text"]}
              rows={lineas.map(
                ({ linea, porcentaje, precioLista, lineaPrecio }) => {
                  const Activator = ({ onClick }) => {
                    return (
                      <div className="flex items-center">
                        <div className="mr-4">
                          <TextStyle>{porcentaje} % </TextStyle>
                        </div>
                        <Button
                          plain
                          icon={MobileHorizontalDotsMajor}
                          onClick={onClick}
                        />
                      </div>
                    );
                  };
                  const lin = (
                    <TextStyle>
                      {linea}{" "}
                      {linea === line &&
                      lineaArt.precioLista.id === precioLista.id &&
                      isEditing ? (
                        <TextStyle variation="subdued">...editando</TextStyle>
                      ) : null}
                    </TextStyle>
                  );
                  const pre = <TextStyle>{precioLista.nombre}</TextStyle>;
                  const porc = (
                    <CustomPopover
                      items={[
                        {
                          content: "Editar",
                          onAction: () => onEditItem(linea, precioLista.id),
                          icon: EditMajor,
                        },
                        {
                          content: "Quitar",
                          onAction: () => onRemoveItem(linea, lineaPrecio),
                          icon: DeleteMajor,
                          destructive: true,
                        },
                      ]}
                      activator={Activator}
                    />
                  );
                  return [lin, pre, porc];
                }
              )}
            />
          )}
        </Card>
        <Banner status="info">
          <p>
            Los clientes pueden combinar descuentos por promoción, por cliente,
            por artículo y por volumen.
          </p>
        </Banner>
      </Modal.Section>
    </Modal>
  );
}

LineaArticuloPromoModal.propTypes = {
  isActive: PropTypes.bool,
  togleModal: PropTypes.func,
  onAddLinea: PropTypes.func,
  selected: PropTypes.array,
  precioLista: PropTypes.array,
};

LineaArticuloPromoModal.defaultProps = {
  isActive: false,
  togleModal: () => {},
  onAddLinea: () => {},
  selected: [],
  precioLista: [],
};
