import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Card,
  FormLayout,
  Modal,
  SkeletonBodyText,
  Icon,
  Select,
  Stack,
  Button,
  Banner,
  DataTable,
  TextStyle,
} from "@shopify/polaris";
import {
  SearchMajor,
  MobileHorizontalDotsMajor,
  EditMajor,
  DeleteMajor,
} from "@shopify/polaris-icons";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import CustomPopover from "../CustomPopover";

function ZonaClienteModal(props) {
  const [tipos, setTipos] = useState([]);
  const [tipoCli, setTipoCli] = useState({
    editing: false,
    tipoPrecio: "",
    queryValue: "",
    descuento: "",
    zonaCliente: { nombre: "", id: "" },
    precioLista: { nombre: "", id: "" },
  });
  const [options, setOptions] = useState([]);
  const deselectedOptions = useRef([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [preciosLista, setPreciosLista] = useState([]);
  const [descuentos, setDescuentos] = useState([]);

  useEffect(() => {
    setPreciosLista(props.precioLista);
    setDescuentos(props.descuentos);
    const updatingLine = props.selected.find((s) => s.editing === true);
    if (props.descuentos.length > 0 && !updatingLine) {
      setTipoCli((s) => ({ ...s, descuento: props.descuentos[0]["value"] }));
    }
    if (props.precioLista.length > 0 && !updatingLine) {
      setTipoCli((s) => ({
        ...s,
        precioLista: {
          nombre: props.precioLista[0].label,
          id: props.precioLista[0].value,
        },
      }));
    }
    if (updatingLine) {
      setTipos((s) => [
        ...s,
        {
          tipoPrecio: updatingLine.tipoPrecio,
          descuento: updatingLine.descuento.value,
          zonaCliente: updatingLine.zonaCliente,
          precioLista: updatingLine.precioLista,
        },
      ]);
      setTipoCli((s) => ({
        ...s,
        tipoPrecio: updatingLine.tipoPrecio,
        descuento: updatingLine.descuento.value,
        zonaCliente: updatingLine.zonaCliente,
        precioLista: updatingLine.precioLista,
      }));
      setIsEditing(true);
    }
    ApiServiceComercios.obtenerZonasCliente()
      .then(({ ok, zonasCliente }) => {
        if (ok) {
          let willAdd = zonasCliente.map((i) => ({
            label: i.nombre,
            value: i._id,
          }));
          deselectedOptions.current = willAdd;
          if (props.precioLista.length > 0 && props.selected.length > 0) {
            // const precioListaDraftId = props.precioLista[0]["value"];
            const filteredSelected = props.selected
              // .filter(i => i.precioLista.id === precioListaDraftId)
              .map((i) => i.zonaCliente.id);
            willAdd = willAdd.filter(
              (i) => !filteredSelected.includes(i.value)
            );
          }
          setOptions(willAdd);
        }
      })
      .finally(() => setIsLoading(false));
    return () => null;
  }, [props.selected, props.descuentos, props.precioLista]);

  function updateSelection(selected) {
    setSelectedOptions(selected);
    const tipoCl = options.find((i) => i.value === selected[0]);
    changeState({ nombre: tipoCl.label, id: tipoCl.value }, "zonaCliente");
  }

  function changeState(value, key) {
    setTipoCli((state) => ({ ...state, [key]: value }));
  }

  function updateText(value) {
    changeState(value, "queryValue");

    if (value === "") {
      //   setOptions(deselectedOptions);
      return;
    }
    const filterRegex = new RegExp(value, "i");
    const resultOptions = deselectedOptions.current
      .filter((i) => !tipos.map((l) => l.zonaCliente.nombre).includes(i.label))
      .filter((option) => option.label.match(filterRegex));
    setOptions(resultOptions);
  }

  function AddTipos() {
    props.onAddData(tipos);
    props.toggleModal();
  }

  function addTipo() {
    setOptions(options.filter((i) => i.value !== tipoCli.zonaCliente.id));
    setTipos((s) => [
      ...s,
      {
        editing: false,
        precioLista: tipoCli.precioLista,
        zonaCliente: tipoCli.zonaCliente,
        descuento: descuentos.find((i) => i.value === tipoCli.descuento),
        tipoPrecio: tipoCli.precioLista.id + tipoCli.zonaCliente.nombre,
      },
    ]);
    setTipoCli((s) => ({
      ...s,
      queryValue: "",
      zonaCliente: { id: "", nombre: "" },
    }));
  }

  function onSetEdit() {
    let arr = [...tipos];
    const index = _.findIndex(arr, { tipoPrecio: tipoCli.tipoPrecio });
    arr.splice(index, 1, {
      descuento: descuentos.find((i) => i.value === tipoCli.descuento),
      zonaCliente: tipoCli.zonaCliente,
      precioLista: tipoCli.precioLista,
      editing: false,
      tipoPrecio: tipoCli.tipoPrecio,
    });
    setTipos(arr);
    setTipoCli((s) => ({
      ...s,
      tipoPrecio: "",
      zonaCliente: { nombre: "", id: "" },
    }));
    setIsEditing(false);
  }

  function onEditItem(zonaCliente, precioLista, descuento, tipoPrecio) {
    setIsEditing(true);
    setTipoCli((s) => ({
      ...s,
      tipoPrecio,
      zonaCliente,
      precioLista,
      descuento,
    }));
  }

  function onRemoveItem(tipoPrecio, tipoCl) {
    setTipos(tipos.filter((i) => i.tipoPrecio !== tipoPrecio));
    const popDeselected = [...options];
    popDeselected.push(tipoCl);
    // if(tipos.find(i => i.tipoPrecio === tipoPrecio)["precioLista"]["id"] === tipoCli.precioLista.id){
    // }
    setOptions(_.orderBy(popDeselected, ["label"], ["asc"]));
  }

  function onSetPrecioLista(value) {
    // const tipoFiltered = tipos.filter((l) => l.precioLista.id === value).map(s => s.zonaCliente.id)
    // setOptions(deselectedOptions.current.filter((i) => !tipoFiltered.includes(i.value)));
    setTipoCli((s) => ({
      ...s,
      queryValue: "",
      // zonaCliente: {nombre: "", id: ""},
      precioLista: {
        nombre: preciosLista.find((i) => i.value === value)["label"],
        id: value,
      },
    }));
  }

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      prefix={<Icon source={SearchMajor} />}
      label="Agregar zonas de cliente"
      value={tipoCli.queryValue}
      placeholder="Busca una zona de cliente"
      helpText={tipoCli.zonaCliente.nombre}
      disabled={isEditing}
    />
  );

  if (isLoading) {
    return (
      <Modal
        title="Agregar zonas de cliente"
        open={props.isActive}
        onClose={props.toggleModal}
      >
        <Modal.Section>
          <Card sectioned>
            <SkeletonBodyText lines={2} />
          </Card>
        </Modal.Section>
      </Modal>
    );
  }

  return (
    <Modal
      title="Agregar zonas de cliente"
      open={props.isActive}
      onClose={props.toggleModal}
      secondaryActions={[{ content: "Cancelar", onAction: props.toggleModal }]}
      primaryAction={{
        content: "Continuar",
        onAction: AddTipos,
        disabled: tipos.length === 0,
      }}
    >
      <Modal.Section>
        <Card sectioned>
          <FormLayout>
            <div className="stack-fillEvenly">
              <Stack distribution="fillEvenly" wrap>
                <Select
                  label="Lista de precio"
                  options={preciosLista}
                  disabled={isLoading}
                  onChange={onSetPrecioLista}
                  value={tipoCli.precioLista.id}
                />
                <Autocomplete
                  options={options}
                  selected={selectedOptions}
                  onSelect={updateSelection}
                  textField={textField}
                  willLoadMoreResults
                  listTitle="Tipos de clientes existentes"
                  loading={isLoading}
                  emptyState="No hay zonas de cliente"
                />
              </Stack>
            </div>
            <Stack distribution="fillEvenly" wrap>
              <Select
                label="Descuento general por artículo"
                options={descuentos}
                disabled={isLoading}
                onChange={(value) => {
                  changeState(value, "descuento");
                }}
                value={tipoCli.descuento}
              />
              <Button
                monochrome
                onClick={isEditing ? onSetEdit : addTipo}
                disabled={!tipoCli.zonaCliente.nombre}
              >
                {isEditing ? "Editar" : "Agregar"}
              </Button>
            </Stack>
          </FormLayout>
          {tipos.length > 0 && (
            <DataTable
              headings={["Lista de precios", "Zona de cliente", "Descuento"]}
              columnContentTypes={["text", "text", "text"]}
              rows={tipos.map(
                ({ precioLista, zonaCliente, descuento, tipoPrecio }) => {
                  const Activator = ({ onClick }) => {
                    return (
                      <div className="flex items-center">
                        <div className="mr-4">
                          <TextStyle>{descuento.label}</TextStyle>
                        </div>
                        <Button
                          plain
                          icon={MobileHorizontalDotsMajor}
                          onClick={onClick}
                        />
                      </div>
                    );
                  };
                  const pre = <TextStyle>{precioLista.nombre}</TextStyle>;
                  const tip = <TextStyle>{zonaCliente.nombre}</TextStyle>;
                  const desc = (
                    <CustomPopover
                      items={[
                        {
                          content: "Editar",
                          onAction: () =>
                            onEditItem(
                              zonaCliente,
                              precioLista,
                              descuento.value,
                              tipoPrecio
                            ),
                          icon: EditMajor,
                        },
                        {
                          content: "Quitar",
                          onAction: () =>
                            onRemoveItem(tipoPrecio, {
                              label: zonaCliente.nombre,
                              value: zonaCliente.id,
                            }),
                          icon: DeleteMajor,
                          destructive: true,
                        },
                      ]}
                      activator={Activator}
                    />
                  );
                  return [pre, tip, desc];
                }
              )}
            />
          )}
        </Card>
        <Banner status="info">
          <p>
            Los clientes pueden combinar descuentos por promoción, por cliente,
            por artículo y por volumen.
          </p>
        </Banner>
      </Modal.Section>
    </Modal>
  );
}

ZonaClienteModal.propTypes = {
  isActive: PropTypes.bool,
  toggleModal: PropTypes.func,
  onAddData: PropTypes.func,
  selected: PropTypes.array,
  precioLista: PropTypes.array,
  descuentos: PropTypes.array,
};

ZonaClienteModal.defaultProps = {
  isActive: false,
  toggleModal: () => {},
  onAddData: () => {},
  selected: [],
  precioLista: [],
  descuentos: [],
};

export default ZonaClienteModal;
