import React, { useState } from "react";
import {
  Card,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  SkeletonThumbnail,
  TextField,
  Stack,
  EmptyState,
  FormLayout,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { Layer, HelpFooter } from "../../components";

export default function CollectorDetailScreen({ forbidden }) {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [collectors, setCollectors] = useState(null);


  useEffect(() => {
    ApiServiceComercios.getCollector(id)
      .then(({ ok, collector }) => {
        if (ok) setCollectors(collector);
      })
      .finally(() => setIsLoading(false));
  }, [id]);


  if (isLoading) {
    return (
      <Layer title="Cobradores" forbidden={forbidden}>
        <SkeletonPage
          breadcrumbs
          title={<SkeletonDisplayText size="medium" />}
          primaryAction={<SkeletonDisplayText size="medium" />}
        >
          <Layout>
            <Layout.AnnotatedSection
              title={<SkeletonDisplayText size="medium" />}
              description={<SkeletonBodyText lines={2} />}
            >
              {Array.from(Array(1).keys()).map((item) => {
                return (
                  <div key={item} className="mb-8">
                    <Card sectioned>
                      <Stack>
                        <SkeletonThumbnail size="small" />
                        <div style={{ minWidth: 300 }}>
                          <SkeletonBodyText lines={2} />
                        </div>
                      </Stack>
                    </Card>
                  </div>
                );
              })}
            </Layout.AnnotatedSection>
          </Layout>
        </SkeletonPage>
      </Layer>
    );
  }

  if (collectors === null) {
    return (
      <Layer title={`Cobrador`} forbidden={forbidden}>
        <EmptyState
          heading="Oops! Hubo un problema al intentar obtener este cobrador"
          action={{
            content: "Volver a intentar",
            onAction: () => window.location.reload(),
          }}
          secondaryAction={{
            content: "Regresar a Vendedores y cobradores",
            url: "/admin/configuracion/vendedores",
          }}
          image="/Ad.svg"
        >
          <p>Intenta de nuevo más tarde</p>
        </EmptyState>
      </Layer>
    );
  }

  return (
    <Layer title="Cobradores" forbidden={forbidden}>
      <Page
        title="Cobradores"
        breadcrumbs={[
          { content: "Cobradores", url: "/admin/configuracion/vendedores" },
        ]}
        separator
      >
        <Layout>
          <Layout.AnnotatedSection
            title="Cobradores"
            description="Muestra el nombre del cobrador y la comisión que se le paga por las ventas."
          >
            <Card sectioned>
              <FormLayout>
                <TextField
                  disabled
                  label="Nombre del cobrador"
                  type="text"
                  autoComplete={false}
                  placeholder="Cobrador"
                  value={collectors.name}
                />
                <TextField
                  disabled
                  label="Comisión en %"
                  type="number"
                  min={0}
                  step={1}
                  value={String(collectors.comission)}
                />
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
        <HelpFooter
          title="vendedores y cobradores"
          url="https://help.b2bgo.mx/configuracion/vendedores-y-cobradores"
        />
      </Page>
    </Layer>
  );
}
