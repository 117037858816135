import { defineAbility } from "@casl/ability";

export const makePermissions = (permissions = []) => {
  const items = [];
  permissions.forEach(({ access, module }) => {
    access.forEach((access) => {
      items.push(`${access}:${module.name}`);
    });
  });

  return items;
};

const ability = (profile) =>
  defineAbility((can) => {
    profile.permissions.forEach((item) => {
      can(item.split(":")[0], item.split(":")[1]);
    });
  });

export default ability;
