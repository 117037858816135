import { SUB_SECTIONS_DEFAULT } from "./constants";
import { getRandomId } from "../utils/generateData";

export const createSection = (list, type) => {
  let item = list.find((item) => item.type === type);
  item["id"] = getRandomId();
  return item;
};

export const createSubSection = (type, last = 0) => {
  let item = { ...SUB_SECTIONS_DEFAULT.find((item) => item.type === type) };
  item["id"] = getRandomId();
  item["label"] = item.label + " " + (last + 1);
  delete item.type;
  return item;
};

export const insertElement = (array, index, newItem) => [
  ...array.slice(0, index),
  newItem,
  ...array.slice(index),
];
