import { Icon } from "@shopify/polaris";
import { AddMajor } from "@shopify/polaris-icons";

export default function AddSubItem({ onAddSubItem = () => {}, item = {} }) {
  return (
    <li className="Polaris-Navigation__ListItem">
      <button
        type="button"
        className={`Polaris-Navigation__Item`}
        onClick={() => onAddSubItem(item.id)}
      >
        <div
          style={{
            marginTop: "0.6rem",
            marginBottom: "0.6rem",
            marginRight: "1rem",
          }}
        >
          <Icon source={AddMajor} color="interactive" />
        </div>
        <span className={`Polaris-Navigation__Text text-blue-600`}>
          Agregar
        </span>
      </button>
    </li>
  );
}
