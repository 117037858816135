import React from "react";
import ChooseSectionsStyled from "./ChooseSectionsStyled";
import NavBar from "./NavBar";

export default function RenderSectionsStyled({
  heading = [],
  body = [],
  footer = [],
  mode = "view",
}) {
  return (
    <div
      className={`flex flex-col w-full bg-white ${
        mode === "view" ? "h-screen" : ""
      }`}
    >
      {heading
        .filter(({ visible }) => visible)
        .map((section, index) => (
          <ChooseSectionsStyled key={index} section={section} mode={mode} />
        ))}
      <NavBar
        mode={mode}
        visibles={heading.filter(({ visible }) => visible).length}
      />
      {body
        .filter(({ visible }) => visible)
        .map((section, index) => (
          <ChooseSectionsStyled key={index} section={section} mode={mode} />
        ))}
      {footer
        .filter(({ visible }) => visible)
        .map((section, index) => (
          <ChooseSectionsStyled key={index} section={section} mode={mode} />
        ))}
    </div>
  );
}
