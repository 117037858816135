import ApiServiceComercios from "../services/ApiServiceComercios";
import NormalizeById from "../utils/NormalizedById";
import { getPaginationCotizaciones } from "../reducers";

export const COTIZACIONES_BEGIN = "COTIZACIONES_BEGIN";
export const ADD_COTIZACIONES = "ADD_COTIZACIONES";
export const COTIZACIONES_FAILED = "COTIZACIONES_FAILED";
export const CLEAR_COTIZACIONES = "CLEAR_COTIZACIONES";
export const RESET_PAGE = "RESET_PAGE";
export const ADD_ID_COTIZACION = "ADD_ID_COTIZACION";
export const CHANGE_PAGE = "CHANGE_PAGE";

export function fetchCotizaciones(params) {
  return async (dispatch, getState) => {
    let pagination = getPaginationCotizaciones(getState());
    dispatch(clearCotizaciones());
    dispatch(cotizacionesBegin());
    return ApiServiceComercios.obtenerCotizaciones({
      ...params,
      limit: pagination.sizePerPage,
      skip: pagination.currentPage * pagination.sizePerPage,
    })
      .then((result) => {
        let data = NormalizeById(result.cotizaciones, "_id");
        data["total"] = result.total;
        data["allIdsDB"] = result.allIds;
        dispatch(addCotizaciones(data));
        return result;
      })
      .catch((err) => {
        dispatch(cotizacionesFailed());
      });
  };
}

export const cotizacionesBegin = () => ({
  type: COTIZACIONES_BEGIN,
});

export const addCotizaciones = (data) => ({
  type: ADD_COTIZACIONES,
  payload: { data },
});

export const cotizacionesFailed = () => ({
  type: COTIZACIONES_FAILED,
});

export const clearCotizaciones = () => ({
  type: CLEAR_COTIZACIONES,
});

export const changePage = (currentPage) => ({
  type: CHANGE_PAGE,
  payload: { data: { currentPage } },
});

export const resetPage = () => ({
  type: RESET_PAGE,
});

export const addIdCotizacion = (id) => ({
  type: ADD_ID_COTIZACION,
  payload: { id },
});
