import React from "react";
import { Badge } from "@shopify/polaris";
import { BADGE_STATUS } from "../../utils/constants";

const CustomBadge = ({ status, progress = null }) => {
  return (
    <div className="badge mr-4 whitespace-nowrap">
      <Badge progress={progress} status={BADGE_STATUS[status]}>
        {status}
      </Badge>
    </div>
  );
};

export default CustomBadge;
