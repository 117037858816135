import React, { useState, useEffect, useCallback } from "react";
import {
  Banner,
  Button,
  ButtonGroup,
  Card,
  Caption,
  ContextualSaveBar,
  DropZone,
  FormLayout,
  Layout,
  List,
  Page,
  Stack,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";
import { CheckboxList, ChooseColor, Layer } from "../../components";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleToast } from "../../actions/InteractiveActions";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { Skeleton } from "antd";
import useAbility from "../../hooks/useAbility";

export default function PersonalizarScreen({ forbidden }) {
  const dispatch = useDispatch();
  const isForbiddenSections = useAbility("read", "Secciones");

  const [isDirty, setIsDirty] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [storeDB, setStoreDB] = useState(null);
  const [modules, setModules] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [file, setFile] = useState();

  const [primaryColorHex, setPrimaryColorHex] = useState("#FFFFFF");
  const [buttonsColorHex, setButtonsColorHex] = useState("#000000");

  const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
  const hasError = rejectedFiles.length > 0;
  const router = useHistory();

  useEffect(() => {
    ApiServiceComercios.getCustomization()
      .then(({ storeDB }) => {
        const { modules = [] } = storeDB;
        setStoreDB(storeDB);
        setPrimaryColorHex(storeDB?.colorSchema.primary);
        setButtonsColorHex(storeDB?.colorSchema.secondary);
        setModules(modules);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) => {
      setFile((file) => acceptedFiles[0]);
      setIsDirty(true);
      setRejectedFiles(_rejectedFiles);
    },
    []
  );

  const handleDiscard = () => {
    router.push("/admin/tienda_online/personalizar");
  };

  const handleChangePrimary = useCallback((value) => {
    setIsDirty(true);
    setPrimaryColorHex(value);
  }, []);

  const handleChangeButtons = useCallback((value) => {
    setIsDirty(true);
    setButtonsColorHex(value);
  }, []);

  const handlePrimaryColor = (value) => {
    setIsDirty(true);
    setPrimaryColorHex(value);
  };

  const handleButtonsColor = (value) => {
    setIsDirty(true);
    setButtonsColorHex(value);
  };

  const handleModuleSelected = (isActive, id) => {
    setIsDirty(true);
    setModules((prevState) =>
      prevState.map((item) =>
        item.id._id === id ? { ...item, active: isActive } : item
      )
    );
  };

  const handleSave = async () => {
    const colors = {
      primary: primaryColorHex,
      secondary: buttonsColorHex,
    };
    const modulesChanged = modules.map((module) => ({
      active: module.active,
      id: module.id._id,
    }));
    const formData = new FormData();
    const comercioData = {
      colorSchema: colors,
      modules: modulesChanged,
    };

    if (file) {
      formData.append("files", file);
      formData.append("comercio", JSON.stringify(comercioData));
    }

    setIsCreating(true);
    ApiServiceComercios.putCustomization({
      comercio: comercioData,
      formData: file ? formData : null,
    })
      .then(({ ok, message }) => {
        if (ok) {
          dispatch(toggleToast({ message }));
          setIsCreating(false);
          setIsDirty(false);
        }
      })
      .finally((err) => {
        setIsCreating(false);
      });
  };

  const fileUpload = !file && <DropZone.FileUpload />;
  const uploadedFile = file && (
    <Stack alignment="center">
      <Thumbnail
        size="large"
        alt={file.name}
        source={
          validImageTypes.indexOf(file.type) >= 0
            ? window.URL.createObjectURL(file)
            : "/newPost.png"
        }
      />
      <div>
        {file.name} <Caption>{file.size} bytes</Caption>
      </div>
    </Stack>
  );

  const stackImage = storeDB && storeDB?.imagen.url && (
    <Stack>
      <Thumbnail
        size="large"
        alt={storeDB?.imagen.nombre}
        source={storeDB?.imagen.url}
      />
      <div>{storeDB?.imagen.nombre}</div>
    </Stack>
  );

  const errorMessage = hasError && (
    <Banner
      title="Las siguientes imágenes no pudieron ser seleccionadas"
      status="critical"
    >
      <List type="bullet">
        {rejectedFiles.map((file, index) => (
          <List.Item key={index}>
            {`"${file.name}" no es soportado. El archivo debería de ser .jpeg, .jpg o .png`}
          </List.Item>
        ))}
      </List>
    </Banner>
  );

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Cambios no guardados"
      saveAction={{
        content: "Guardar",
        onAction: handleSave,
        disabled: !isDirty,
        loading: isCreating,
      }}
      discardAction={{
        onAction: handleDiscard,
        disabled: isCreating,
      }}
    />
  ) : null;

  return (
    <Layer title="Personalizar" forbidden={forbidden}>
      <Page title="Personalizar" separator>
        {contextualSaveBarMarkup}
        <Layout>
          <Layout.AnnotatedSection
            title="Logotipo"
            description="Cuando tus clientes entran a tu comercio, se muestra tu logotipo. También será integrado en tus correos electrónicos de confirmación de pedidos y en los descargables como pedidos y facturas."
          >
            <Card>
              <Card.Section>
                <FormLayout>
                  <TextStyle>Tamaño recomendado 1200 x 628 px</TextStyle>
                  {errorMessage}
                  {isLoading ? (
                    <Skeleton.Image active />
                  ) : (
                    <DropZone
                      type="image"
                      accept={validImageTypes.join(",")}
                      allowMultiple={false}
                      onDrop={handleDropZoneDrop}
                      outline={false}
                    >
                      {uploadedFile}
                      {!file && stackImage}
                      {fileUpload}
                    </DropZone>
                  )}
                  <div className="mb-10" />
                </FormLayout>
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title="Colores"
            description="Se aplicará un esquema predeterminado de colores a tu tienda, que puedes cambiar en cualquier momento. Al agregar los colores de tu marca, lograrás un uso coherente de tu paleta de colores en toda tu tienda."
          >
            <Card>
              <Card.Section>
                <FormLayout>
                  <TextStyle variation="strong">Primario</TextStyle>
                  <div className="flex flex-row justify-between items-center">
                    <TextStyle>
                      El color se puede aplicar a los fondos de algunas
                      secciones.
                    </TextStyle>
                  </div>
                  {isLoading ? (
                    <Skeleton avatar paragraph={{ rows: 1, width: 100 }} />
                  ) : (
                    <>
                      <ChooseColor
                        handleColorPick={handlePrimaryColor}
                        handleTextChange={handleChangePrimary}
                        colorHex={primaryColorHex}
                        label="Color primario"
                      />
                    </>
                  )}
                </FormLayout>
                <FormLayout>
                  <div className="mt-20">
                    <TextStyle variation="strong">Botones</TextStyle>
                  </div>
                  <div className="flex flex-row justify-between items-center">
                    <TextStyle>
                      El color se puede aplicar a los botones.
                    </TextStyle>
                  </div>
                  {isLoading ? (
                    <Skeleton avatar paragraph={{ rows: 1, width: 100 }} />
                  ) : (
                    <>
                      <ChooseColor
                        handleColorPick={handleButtonsColor}
                        handleTextChange={handleChangeButtons}
                        colorHex={buttonsColorHex}
                        label="Color de botones"
                      />
                    </>
                  )}
                </FormLayout>
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
          {!isForbiddenSections && (
            <Layout.AnnotatedSection
              title="Secciones"
              description="Personaliza el contenido en la mayoría de las páginas de tu tienda."
            >
              <Card>
                <Card.Section>
                  <FormLayout>
                    <TextStyle variation="strong">Secciones</TextStyle>
                    <div className="flex items-center justify-between">
                      <FormLayout>
                        <TextStyle>
                          Agrega, personaliza, reorganiza o elimina secciones y
                          bloques.
                        </TextStyle>
                        <Button
                          primary
                          onClick={() =>
                            router.push(
                              "/admin/tienda_online/page-customization"
                            )
                          }
                        >
                          Personaliza tus páginas
                        </Button>
                      </FormLayout>
                      <img
                        alt="¡Ups!"
                        src="/sectionsLogo.svg"
                        width="120px"
                        height="100px"
                      />
                    </div>
                  </FormLayout>
                </Card.Section>
              </Card>
            </Layout.AnnotatedSection>
          )}
          <Layout.AnnotatedSection
            title="Módulos"
            description="Puedes seleccionar que esto aparezca cuando tus clientes entren a tu tienda. Desactivar los módulos implica que tus clientes no podrán acceder a ellos y afectará a todos los clientes."
          >
            <Card>
              <Card.Section>
                <FormLayout>
                  <TextStyle>
                    Mostrando {modules.length} módulos,{" "}
                    {modules.filter((module) => module.active === true).length}{" "}
                    activados
                  </TextStyle>
                  <div className="flex mt-8">
                    <FormLayout>
                      {modules?.map(({ id, active }, index) => (
                        <CheckboxList
                          key={index}
                          id={id?._id}
                          label={id?.name}
                          helpText={id?.description}
                          icon={id?.iconName}
                          checked={active}
                          disabled={id?.name === "Inicio" ?? false}
                          onChange={handleModuleSelected}
                        />
                      ))}
                    </FormLayout>
                  </div>
                </FormLayout>
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
        <div className="flex justify-end mt-8">
          <ButtonGroup>
            <Button onClick={handleDiscard} disabled={!isDirty}>
              Descartar
            </Button>
            <Button
              primary
              onClick={handleSave}
              disabled={!isDirty}
              loading={isCreating}
            >
              Guardar
            </Button>
          </ButtonGroup>
        </div>
      </Page>
    </Layer>
  );
}
