import React, { useState, useEffect } from "react";
import {
  Card,
  FormLayout,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { ChooseColor } from "..";

export default function AnnouncementBarCard({ item, handleMainChange }) {
  const [announcement, setAnnouncement] = useState({
    backgroundColor: "#000000",
    textColor: "#FFFFFF",
    text: "",
  });

  useEffect(() => {
    const {
      properties: { backgroundColor, text, textColor },
    } = item;
    setAnnouncement((prevState) => ({
      ...prevState,
      backgroundColor: backgroundColor || "#000000",
      textColor: textColor || "#FFFFFF",
      text: text || "",
    }));
  }, [item]);

  const handleChange = (field, value) => {
    handleMainChange(
      "properties",
      { ...announcement, [field]: value },
      item.id
    );
    setAnnouncement((prevState) => ({ ...prevState, [field]: value }));
  };

  const { text, textColor, backgroundColor } = announcement;

  return (
    <Card>
      <Card.Section>
        <TextStyle variation="strong">Barra de anuncios</TextStyle>
      </Card.Section>
      <Card.Section>
        <FormLayout>
          <TextField
            label="Texto"
            type="text"
            value={text}
            onChange={(text) => handleChange("text", text)}
            autoComplete="off"
            placeholder="Bienvenido a nuestra tienda"
          />
          <TextStyle>Esquema de colores de la barra de anuncios</TextStyle>
          <ChooseColor
            handleColorPick={(value) => {
              handleChange("backgroundColor", value);
            }}
            handleTextChange={(value) => handleChange("backgroundColor", value)}
            colorHex={backgroundColor}
            label="Color de la barra"
          />
          <ChooseColor
            handleColorPick={(value) => handleChange("textColor", value)}
            handleTextChange={(value) => handleChange("textColor", value)}
            colorHex={textColor}
            label="Color del texto"
          />
        </FormLayout>
      </Card.Section>
    </Card>
  );
}
