import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Thumbnail,
  TextStyle,
  Button,
  Checkbox,
  Icon,
  EmptyState,
  Scrollable,
  TextField,
} from "@shopify/polaris";
import { SearchMinor, PackageMajor } from "@shopify/polaris-icons";
import ItemButton from "../ItemButton";
import { useDispatch, useSelector } from "react-redux";
import {
  changePage,
  fecthArticulos,
  resetPage,
} from "../../actions/ArticulosActions";
import { getArticulos, getPaginationArticulos } from "../../reducers";
import SkeletonTable from "../SkeletonTable";
import useDebounce from "../../services/useDebounce";
import "./BuscarProductoModalStyles.css";
import { Table } from "antd";
import CustomPopover from "../CustomPopover";
import { getImageVideoUrl, validateStock } from "../../utils/productValidators";
import AntPagination from "../AntPagination";

export default function BuscarProductoModal({
  selectArticulos,
  value,
  isActive,
  handleChange,
  selected,
  idCliente,
  mostrarExistenciaProducto,
  primaryActionTitle = "",
  almacenId = undefined,
  validateAvailability = true,
}) {
  const pagination = useSelector(getPaginationArticulos);
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);
  const [initial, setInitial] = useState(true);
  let articulos = useSelector(getArticulos) || [];

  articulos = validateStock(
    articulos,
    selected,
    almacenId,
    validateAvailability
  );

  const [showItems, setShowItems] = useState(true);
  const [selectedArticulos, setSelectedArticulos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [queryValue, setQueryValue] = useState(value);
  const [selectedPackageKey, setSelectedPackageKey] = useState([]);

  const queryValueRef = useRef();
  const debouncedSearchTerm = useDebounce(queryValue, 800);
  useEffect(() => {
    queryValueRef.current = queryValue;
  });

  useEffect(() => {
    setInitial(false);
    if (value) {
      setShowItems(false);
    } else {
      setIsLoading(false);
    }
  }, [dispatch, value]);

  useEffect(() => {
    dispatch(resetPage());
    setIsLoading(true);
    dispatch(
      fecthArticulos(
        {
          notIn: selected,
          sort: "nombre",
          query: queryValueRef.current,
          existencia: 0,
          orden: 1,
          linea: [],
          sucursal: [],
          idCliente: idCliente,
          mostBught: false,
          toCreateOrder: true,
          almacenId,
          validateAvailability,
        },
        true
      )
    ).finally(() => {
      setIsLoading(false);
    });
  }, [
    debouncedSearchTerm,
    dispatch,
    almacenId,
    idCliente,
    selected,
    validateAvailability,
  ]);

  function onQueryEndChange(
    thunk = null,
    fetch = false,
    mostBught = undefined
  ) {
    if (thunk) dispatch(thunk());
    if ((!initial && !isLoading) || fetch) {
      setIsLoading(true);
      dispatch(
        fecthArticulos(
          {
            notIn: selected,
            sort: "nombre",
            query: queryValue,
            existencia: 0,
            orden: 1,
            linea: [],
            sucursal: [],
            idCliente: idCliente,
            mostBught: mostBught,
            toCreateOrder: true,
            almacenId,
            validateAvailability,
          },
          true
        )
      ).finally(() => {
        setInitial(false);
        setIsLoading(false);
      });
    }
  }

  function handleSelect(selected, val, isVariant = false, quantity = "0") {
    quantity = Number(quantity);
    if (selected) {
      const article = isVariant
        ? articulos
            .filter((i) => i.isParent)
            .find((i) => i.children.some((i) => i.id === val))
            ["children"].find((i) => i.id === val)
        : articulos.filter((ar) => ar._id === val)[0];

      let { minimum = 1, maximum = 0, factor_venta = 1 } = article;

      if (minimum === 0) {
        minimum = 1;
      }

      quantity = minimum === 1 ? (quantity === 0 ? 1 : quantity) : minimum;
      if (quantity >= 1) {
        if (quantity < minimum) {
          quantity = minimum;
        } else if (maximum > 0 && quantity > maximum) {
          quantity = maximum;
        } else if (quantity % factor_venta !== 0) {
          quantity = minimum;
        }
      }

      quantity = String(quantity);

      if (selectedArticulos.some((i) => i._id === article._id)) {
        setSelectedArticulos((state) =>
          state.map((i) => {
            if (i._id === article._id) {
              return {
                ...i,
                cantidad: quantity,
              };
            } else {
              return i;
            }
          })
        );
        setSelectedItems((state) => [...new Set(state.concat(val))]);
      } else {
        setSelectedArticulos((state) => [
          ...state,
          { ...article, cantidad: quantity },
        ]);
        setSelectedItems((state) => [...new Set(state.concat(val))]);
      }
    } else {
      setSelectedArticulos(selectedArticulos.filter((ar) => ar._id !== val));
      setSelectedItems(selectedItems.filter((sel) => val !== sel));
    }
  }

  function searchMoreArticles() {
    onQueryEndChange(resetPage, true);
    setShowItems(false);
  }

  function searchMostBought() {
    onQueryEndChange(resetPage, true, true);
    setShowItems(false);
  }

  function handleChangeText(val) {
    setShowItems(false);
    setQueryValue(val);
  }

  function chooseItems() {
    selectArticulos(selectedArticulos);
    handleChange();
  }

  function validateExistencia(existencia) {
    const existencias = Number(
      existencia
        .map((i) => i.existencia)
        .reduce((prev, total) => prev + total, 0)
    );
    if (mostrarExistenciaProducto) {
      return Number(existencias).toLocaleString("en", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    } else {
      if (existencias > 0) return "Disponible";
      else return "Bajo pedido";
    }
  }

  const emptyStateMarkup =
    !showItems && !isLoading && articulos.length === 0 ? (
      <EmptyState
        heading="¡Oops! no hay artículos"
        image="/emptystate-files-n.png"
      />
    ) : undefined;

  const searchButton = (
    <Button onClick={() => onQueryEndChange()}>Buscar</Button>
  );

  const textField = (
    <TextField
      autoComplete="off"
      onChange={handleChangeText}
      value={queryValue}
      clearButton
      prefix={<Icon source={SearchMinor} color="base" />}
      placeholder="Buscar productos"
      onClearButtonClick={() => {
        handleChangeText("");
      }}
      connectedRight={searchButton}
    />
  );

  const loadingState = isLoading ? (
    <SkeletonTable headingsCount={4} rowsCount={3} />
  ) : null;

  const onSelectKey = (key, keys, record) => {
    const quantity = keys.find((i) => i.clave === key)["quantity"];
    handleSelect(true, record.id, record.isVariant, quantity.toString());
    setSelectedPackageKey(key);
  };

  return (
    <Modal
      title="Seleccionar productos"
      open={isActive}
      onClose={handleChange}
      secondaryActions={[
        {
          content: "Cancelar",
          onAction: handleChange,
        },
      ]}
      footer={
        <AntPagination
          total={pagination.total}
          current={pagination.currentPage + 1}
          pageSize={pagination.sizePerPage}
          onChange={(page) => {
            dispatch(changePage(page - 1));
            onQueryEndChange(false);
          }}
        />
      }
      primaryAction={{
        content: primaryActionTitle,
        onAction: chooseItems,
        disabled: selectedItems.length === 0,
      }}
    >
      <Scrollable style={{ height: "100%" }}>
        <div className="my-4 products-modal">
          <div className="mx-4">{textField}</div>
          {loadingState}
          {!showItems && !isLoading ? (
            <div className="mt-8 ant-table-styles">
              <Table
                rowKey="id"
                dataSource={articulos}
                scroll={{ scrollToFirstRowOnChange: true, x: "100%" }}
                pagination={false}
                locale={{ emptyText: emptyStateMarkup }}
                columns={[
                  {
                    title: "Clave",
                    dataIndex: "clave",
                    render: (clave, record) => {
                      return (
                        <div
                          className="flex flex-row items-center p-8"
                          onClick={() => {
                            if (!record.isParent) {
                              handleSelect(
                                !Boolean(selectedItems.indexOf(record.id) >= 0),
                                record.id,
                                record.isVariant,
                                "1"
                              );
                            }
                          }}
                        >
                          {!record.isParent && (
                            <Checkbox
                              value={record.id}
                              checked={selectedItems.indexOf(record._id) >= 0}
                            />
                          )}
                          <div className="sm:flex flex-col md:flex-row lg:flex-row">
                            <div className="mr-4 flex justify-start aspect-square">
                              <Thumbnail
                                source={
                                  getImageVideoUrl(
                                    record?.imagen,
                                    "image",
                                    false
                                  ) || "/Default Photo.png"
                                }
                                size="small"
                              />
                            </div>
                            <div className="xs:flex text-xl lg:whitespace-no-wrap">
                              <TextStyle>
                                {record.isParent ? clave.split(" ")[0] : clave}
                              </TextStyle>
                            </div>
                          </div>
                        </div>
                      );
                    },
                  },
                  {
                    title: "Artículo",
                    dataIndex: "nombre",
                    width: "35%",
                    render: (nombre, record) => (
                      <div
                        className="div-left p8"
                        onClick={() => {
                          if (!record.isParent) {
                            handleSelect(
                              !Boolean(selectedItems.indexOf(record.id) >= 0),
                              record.id,
                              record.isVariant,
                              "1"
                            );
                          }
                        }}
                      >
                        <Button plain>{nombre || record.clave}</Button>
                      </div>
                    ),
                  },
                  {
                    title: "Empaques",
                    dataIndex: "packingKeys",
                    key: "packingKeys",
                    render: (keys = [], record) => {
                      const items = keys.map((key) => {
                        return {
                          content: key.clave || "",
                          onAction: () => {
                            onSelectKey(key.clave, keys, record);
                          },
                        };
                      });

                      const Activator = ({ onClick }) => {
                        return (
                          <div className="no-underline text-gray-600 z-50 div-no-grap">
                            <Button
                              onClick={onClick}
                              plain
                              textAlign="left"
                              monochrome
                              disclosure
                              icon={PackageMajor}
                            />
                          </div>
                        );
                      };
                      return (
                        <div className="w-full h-full cursor-pointer p-6">
                          {items.length > 0 && (
                            <CustomPopover
                              selectedPackageKey={selectedPackageKey}
                              items={items}
                              activator={Activator}
                            />
                          )}
                        </div>
                      );
                    },
                  },
                  {
                    title: "Disponible",
                    dataIndex: "existencia",
                    render: (existencia = [], record) => (
                      <div
                        className="p-8"
                        onClick={() => {
                          if (!record.isParent) {
                            handleSelect(
                              !Boolean(selectedItems.indexOf(record.id) >= 0),
                              record.id,
                              record.isVariant,
                              "1"
                            );
                          }
                        }}
                      >
                        <TextStyle>{validateExistencia(existencia)}</TextStyle>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          ) : null}
          {!isLoading && showItems ? (
            <div className="">
              <ItemButton
                onClick={searchMoreArticles}
                text="Todos los productos"
                hasBorder
              />
              <ItemButton
                onClick={searchMostBought}
                text="Los que más compras"
              />
            </div>
          ) : null}
        </div>
      </Scrollable>
    </Modal>
  );
}
