import React from 'react'
import { Card, Layout, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, SkeletonThumbnail, Stack } from '@shopify/polaris';

export default function FiltroMenuSkeleton() {
   return (
      <SkeletonPage
         breadcrumbs
         fullWidth
         title={<SkeletonDisplayText size="medium" />}
      >
         <Layout>
            <Layout.AnnotatedSection
               title={<SkeletonDisplayText size="medium" />}
               description={<SkeletonBodyText lines={2} />}
            >
               {Array.from(Array(1).keys()).map((item) => {
                  return (
                     <div key={item} className="mb-8">
                        <Card sectioned>
                           <Stack>
                              <SkeletonThumbnail size="small" />
                              <div style={{ minWidth: 300 }}>
                                 <SkeletonBodyText lines={2} />
                              </div>
                           </Stack>
                        </Card>
                     </div>
                  );
               })}
            </Layout.AnnotatedSection>
         </Layout>
      </SkeletonPage>
   )
}
