import { Card, Checkbox, FormLayout, Modal } from "@shopify/polaris";
import { useCallback, useState } from "react";

export default function SelectHistorialModal({
  open = false,
  onClose = () => {},
  onSync = () => {},
}) {
  const [selected, setSelected] = useState({
    Clientes: false,
    Artículos: false,
    Pedidos: false,
    Descuentos: false,
    Cotizaciones: false,
    Listas: false,
    Variantes: false,
    Vendedores: false,
    Imágenes: false,
    Cobradores: false,
  });

  const handleChange = useCallback((field, value) => {
    setSelected((prevState) => ({ ...prevState, [field]: value }));
  }, []);

  return (
    <Modal
      open={open}
      title="Catálogos a sincronizar"
      onClose={onClose}
      primaryAction={{
        content: "Sincronizar",
        disabled: !Object.values(selected).some((value) => value),
        onAction: () => {
          const selectedItems = Object.keys(selected).filter(
            (key) => selected[key]
          );
          onSync(selectedItems);
        },
      }}
      secondaryActions={[{ content: "Cancelar", onAction: onClose }]}
    >
      <Card sectioned title="Selecciona los catálogos a sincronizar">
        <FormLayout>
          <Checkbox
            label="Listas"
            onChange={(value) => handleChange("Listas", value)}
            checked={selected["Listas"]}
          />
          <Checkbox
            label="Clientes"
            onChange={(value) => handleChange("Clientes", value)}
            checked={selected["Clientes"]}
          />
          <Checkbox
            label="Artículos"
            onChange={(value) => handleChange("Artículos", value)}
            checked={selected["Artículos"]}
          />
          <Checkbox
            label="Variantes"
            onChange={(value) => handleChange("Variantes", value)}
            checked={selected["Variantes"]}
          />
          <Checkbox
            label="Descuentos"
            onChange={(value) => handleChange("Descuentos", value)}
            checked={selected["Descuentos"]}
          />
          <Checkbox
            label="Pedidos"
            onChange={(value) => handleChange("Pedidos", value)}
            checked={selected["Pedidos"]}
          />
          <Checkbox
            label="Vendedores"
            onChange={(value) => handleChange("Vendedores", value)}
            checked={selected["Vendedores"]}
          />
          <Checkbox
            label="Cobradores"
            onChange={(value) => handleChange("Cobradores", value)}
            checked={selected["Cobradores"]}
          />
          <Checkbox
            label="Cotizaciones"
            onChange={(value) => handleChange("Cotizaciones", value)}
            checked={selected["Cotizaciones"]}
          />
          <Checkbox
            label="Imágenes"
            onChange={(value) => handleChange("Imágenes", value)}
            checked={selected["Imágenes"]}
          />
        </FormLayout>
      </Card>
    </Modal>
  );
}
