import moment from "moment";

// function to get if has schedules
const hasSchedulesDays = (schedules) => {
  return schedules.some(
    (item) => item.dayOfWeek >= 0 && item.start && item.end
  );
};

export const getSchedule = (schedules) => {
  const hasSchedules = hasSchedulesDays(schedules);
  let response = {
    hasSchedules,
    isInRange: false,
    remaining: { days: 0, hours: 0, minutes: 0, seconds: 0 },
  };

  // Validate if has schedules, else return default response
  if (hasSchedules) {
    const currentDay = moment().day();

    const scheduledDay = schedules.find(
      (item) => item.dayOfWeek === currentDay
    );

    // validate schedule for current day
    if (scheduledDay) {
      const format = "HH:mm";
      const startTime = moment(scheduledDay.start, format);
      const endTime = moment(scheduledDay.end, format);

      // Validate if current time is in range for current schedule
      const isInRange = moment().isBetween(startTime, endTime);
      response.isInRange = isInRange;

      // Validate remaining time
      if (isInRange) {
        const { days, hours, minutes, seconds } = getTimeUntilDeadline(
          scheduledDay.end
        );
        response.remaining = { days, hours, minutes, seconds };
        return response;
      }
    }

    response.isInRange = false;

    // Calculate next schedule
    let { dayOfWeek, start } = getNextScheduledDay(schedules) || {};
    const [hour, minute] = start.split(":");
    let nextScheduledDay = moment(getNextDayOfWeek(dayOfWeek))
      .set({ hour, minute })
      .format("YYYY-MM-DD HH:mm");

    const { days, hours, minutes, seconds } =
      calculateTimeRemaining(nextScheduledDay);
    response.remaining = { days, hours, minutes, seconds };
  }

  return response;
};

function calculateTimeRemaining(targetDate) {
  // Convert the target date to a Moment object
  const targetDateMoment = moment(targetDate, "YYYY-MM-DD HH:mm");
  // Get the current date and time
  const currentDate = moment();
  // Calculate the difference in seconds
  const secondsRemaining = targetDateMoment.diff(currentDate, "seconds");

  // Calculate the days, hours, minutes, and remaining seconds
  const days = Math.floor(secondsRemaining / (60 * 60 * 24));
  const hours = Math.floor((secondsRemaining % (60 * 60 * 24)) / (60 * 60));
  const minutes = Math.floor((secondsRemaining % (60 * 60)) / 60);
  const seconds = secondsRemaining % 60;

  // Return the result
  return { days, hours, minutes, seconds };
}

function getNextDayOfWeek(currentDay) {
  // Get current date
  const currentDate = moment();
  // Calculate remaining days to the next day of week
  const daysUntilNextDay = (currentDay - currentDate.day() + 7) % 7;
  // Get & returns the date of next week
  const nextDate = currentDate.clone().add(daysUntilNextDay, "days");
  return nextDate.format();
}

const getNextScheduledDay = (schedules = []) => {
  const currentDay = moment().day();
  // Return next scheduled day
  return (
    schedules.find((item) => item.dayOfWeek > currentDay && item.start) ||
    schedules.find((item) => item.start)
  );
};

function getTimeUntilDeadline(deadlineHour) {
  const currentMoment = moment();
  const deadlineMoment = moment(deadlineHour, "HH:mm");

  if (!deadlineMoment.isValid()) {
    throw new Error(
      'Formato de hora límite no válido. Utiliza el formato "HH:mm".'
    );
  }

  // Checks if the time is earlier than the current time on the same day
  if (deadlineMoment.isBefore(currentMoment)) {
    // Incrementa la fecha para que sea en el siguiente día
    deadlineMoment.add(1, "day");
  }

  // Calculates the difference between the time and the current time
  const duration = moment.duration(deadlineMoment.diff(currentMoment));

  // Calculate days, hours, minutes, and remaining seconds
  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return { days, hours, minutes, seconds };
}
