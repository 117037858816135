import React from "react";
import { TextStyle, SkeletonBodyText, Thumbnail } from "@shopify/polaris";
import PropTypes from "prop-types";
import { NumberFormat } from "../../utils/Formats";
import { getImageVideoUrl } from "../../utils/productValidators";

export default function TopItem({ item, isLoading }) {
  return (
    <div>
      {!isLoading ? (
        <div className="flex flex-row justify-between items-start mt-6 border-separator pb-6">
          <div className="flex flex-row items-start">
            <div className="mr-4">
              <Thumbnail
                source={
                  getImageVideoUrl(item.articulo?.imagen, "image", false) ||
                  "/Default Photo.png"
                }
              />
            </div>
            <h3 className="flex flex-col">
              <TextStyle variation="strong">{item?.articulo?.nombre}</TextStyle>
              <TextStyle>
                {Number(item?.cantidad).toLocaleString("en", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </TextStyle>
            </h3>
          </div>
          <div>
            <TextStyle>
              ${Number(item?.total).toLocaleString("en", NumberFormat)}
            </TextStyle>
          </div>
        </div>
      ) : (
        <div className="mt-10 border-separator pb-6 skeleton-top-comercio">
          <SkeletonBodyText lines={2} />
        </div>
      )}
    </div>
  );
}

TopItem.propTypes = {
  item: PropTypes.object,
  isLoading: PropTypes.bool,
};

TopItem.defaultProps = {
  item: {},
  isLoading: false,
};
