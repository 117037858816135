import { useState, useEffect, useCallback } from "react";
import ApiServiceComercios from "../services/ApiServiceComercios";

/**
 * Custom hoook to get tags from db tags collection,
 * this custom hook allow you to retrieve & create tags.
 * Tags are separeted by module & they can be used wherever you want
 * Enjoy using this custom hook
 * @param {string} module - Name of module where you want yo use tags
 * @param {string} query - Query value to find in tags collection
 */
export default function useGetTags({ module = "Artículos", query = "" }) {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchTags = useCallback(
    (query = "") => {
      setLoading(true);
      ApiServiceComercios.getTags({ module, query })
        .then(({ tags }) => {
          setTags(tags);
        })
        .catch(() => {
          setTags([]);
        })
        .finally(() => setLoading(false));
    },
    [module]
  );

  useEffect(() => {
    fetchTags(query);
  }, [fetchTags, query]);

  const createTag = async (name = "") => {
    return new Promise((resolve, reject) => {
      if (name) {
        setLoading(true);

        ApiServiceComercios.createTag({ name, module })
          .then(({ ok }) => {
            resolve({ ok });
          })
          .catch(() => {
            reject({ ok: false });
          })
          .finally(() => setLoading(false));
      }
    });
  };

  return { tags, fetchTags, loading, createTag };
}
