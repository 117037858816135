import React from "react";
import { Timeline } from "antd";
import { Avatar, TextStyle } from "@shopify/polaris";
import { formatDateCalendar } from "../../utils/timelineTraslation";
import { extractInitials } from "../../utils/stringModifiers";
import DropzoneFiles from "../DropzoneFiles";

export default function TimelineMessages({ data = [], loading = false }) {
  const renderAvatar = (customer = {}) => {
    return (
      <Avatar initials={extractInitials(customer?.name, customer?.last_name)} />
    );
  };

  return (
    <div className="mt-20 ml-5">
      <Timeline pending={loading} reverse>
        {data.map((item) => (
          <Timeline.Item
            key={item._id}
            dot={renderAvatar(item.user)}
            color="red"
          >
            <div className="flex flex-col ml-8">
              <div className="mb-6">
                <TextStyle variation="strong">
                  {item?.user?.name} {item?.user?.last_name} {` `}
                </TextStyle>
                <TextStyle variation="subdued">
                  - {formatDateCalendar(item.createdDate)}
                </TextStyle>
              </div>
              <TextStyle>{item.message}</TextStyle>
              <DropzoneFiles files={item.files} />
            </div>
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  );
}
