import React from 'react';
import { Badge, Button, Icon, TextStyle } from "@shopify/polaris";
import * as IconType from '@shopify/polaris-icons';
import './ConfigButton.css'

const ConfigButton = ({text, icon, secondary, onClick, fullWidth=false, isNew=false}) => {
   return (
      <div className={`w-full ${ !fullWidth ? 'sm:w-1/2 md:w-1/2 lg:w-1/3' : '' }`}>
         <div className="custom-button">
            <Button 
               monochrome 
               size="medium" 
               textAlign="left" 
               outline={false}
               onClick={onClick}
            >
               <div className="flex my-6">
                     <div className="flex fit p-6 bg-gray-200 rounded-md">
                        <Icon source={IconType[icon]} color="green" />
                     </div>

                     <div className="flex flex-col justify-start items-start ml-8 no-underline">
                        <div className="flex flex-row gap-3 items-center">
                           <p className="text-blue-600 font-semibold mb-2">{text}</p>
                           { isNew && <Badge status="info">Nuevo</Badge> }
                        </div>
                        <TextStyle variation="subdued">{secondary}</TextStyle>
                     </div>
               </div>
            </Button>
         </div>
      </div>
   );
}

export default ConfigButton;
