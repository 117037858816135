import React, { useEffect, useState } from "react";
import {
  Page,
  Layout,
  Card,
  SkeletonPage,
  SkeletonDisplayText,
  SkeletonBodyText,
  SkeletonThumbnail,
  Stack,
} from "@shopify/polaris";
import { Layer, ConfigButton, HelpFooter } from "../../components";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { useHistory } from "react-router-dom";

const options = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export default function EnvioEntregaScreen({ forbidden }) {
  const [isLoading, setIsLoading] = useState(true);
  const [envios, setEnvios] = useState([]);
  const router = useHistory();

  useEffect(() => {
    ApiServiceComercios.obtenerEnvios()
      .then((result) => {
        if (result.ok) {
          setEnvios(result.envios);
        }
      })
      .catch((err) => {})
      .finally(() => setIsLoading(false));
    return () => null;
  }, []);

  if (isLoading) {
    return (
      <Layer title="Envío y entrega" forbidden={forbidden}>
        <SkeletonPage
          breadcrumbs
          title={<SkeletonDisplayText size="medium" />}
          primaryAction={<SkeletonDisplayText size="medium" />}
        >
          <Layout>
            <Layout.AnnotatedSection
              title={<SkeletonDisplayText size="medium" />}
              description={<SkeletonBodyText lines={2} />}
            >
              {Array.from(Array(3).keys()).map((item) => {
                return (
                  <div key={item} className="mb-8">
                    <Card sectioned>
                      <Stack>
                        <SkeletonThumbnail size="small" />
                        <div style={{ minWidth: 300 }}>
                          <SkeletonBodyText lines={2} />
                        </div>
                      </Stack>
                    </Card>
                  </div>
                );
              })}
            </Layout.AnnotatedSection>
          </Layout>
        </SkeletonPage>
      </Layer>
    );
  }

  return (
    <Layer title="Envío y entrega" forbidden={forbidden}>
      <Page
        title="Envío y entrega"
        breadcrumbs={[
          { content: "Configuración", url: "/admin/configuracion" },
        ]}
        // primaryAction={{
        //   content: "Agregar envío",
        //   url: "/admin/configuracion/envio-entregas/agregar",
        // }}
      >
        <Layout>
          <Layout.AnnotatedSection
            title="Envío y entrega"
            description="Gestiona cómo los clientes reciben sus pedido."
          >
            {envios.map((envio) => {
              return (
                <div key={envio._id} className="mb-8">
                  <Card>
                    <ConfigButton
                      onClick={() =>
                        router.push(
                          `/admin/configuracion/envio-entregas/${envio._id}`
                        )
                      }
                      fullWidth
                      text={envio.nombre}
                      secondary={
                        Number(envio.precio) === 0
                          ? "Gratis"
                          : `$${Number(envio.precio).toLocaleString(
                              "en",
                              options
                            )}`
                      }
                      icon="ShipmentMajor"
                    />
                  </Card>
                </div>
              );
            })}
          </Layout.AnnotatedSection>
        </Layout>
        <HelpFooter
          title="envío y entrega"
          url="https://help.b2bgo.mx/configuracion/envio-y-entrega"
        />
      </Page>
    </Layer>
  );
}
