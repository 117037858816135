import {
  Autocomplete,
  Badge,
  Button,
  Card,
  Icon,
  Link,
  SkeletonBodyText,
  TextStyle,
} from "@shopify/polaris";
import {
  CirclePlusMajor,
  MobileCancelMajor,
  SearchMinor,
} from "@shopify/polaris-icons";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import useDebounce from "../../services/useDebounce";
import AddClientModal from "../AddClientModal/AddClientModal";
import CustomBadge from "../CustomBadge";

export default function ClientSection({
  clienteDB = null,
  isLoading = false,
  setIsBlocking = () => {},
  updateSelection = () => {},
  removeClient = () => {},
  setEditDirectionName = () => {},
  handleDirectionModal = () => {},
  hasRemoveItem = true,
  showBilligAddres = true,
  createClient = true,
}) {
  const queryClientRef = useRef(null);
  const [inputValueClient, setInputValueClient] = useState("");
  const [clientesOptions, setClientesOptions] = useState([]);
  const [isLoadingClientes, setIsLoadingClientes] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [toggleActive, setToggleActive] = useState(false);

  const debouncedSearchTerm = useDebounce(inputValueClient, 900);

  useEffect(() => {
    queryClientRef.current = debouncedSearchTerm;
  });

  useEffect(() => {
    function onQueryEndChange() {
      setIsLoadingClientes(true);
      ApiServiceComercios.obtenerClientesComercio({
        limit: 20,
        skip: 0,
        sort: "nombre",
        query: queryClientRef.current,
        orden: 1,
        estatus: JSON.stringify(["Activo", "Invitado", "Inactivo"]),
        withClave: true,
      })
        .then(({ clientes }) => {
          const obj = clientes.map((i) => ({
            label: (
              <div className="flex flex-col gap-2">
                <TextStyle>{i.nombre}</TextStyle>
                <TextStyle variation="subdued">{i.email}</TextStyle>
                <CustomBadge status={i.estatus.nombre} />
              </div>
            ),
            value: i._id,
            name: i.nombre,
          }));
          setClientesOptions(obj);
        })
        .finally(() => setIsLoadingClientes(false));
    }

    onQueryEndChange();
  }, [debouncedSearchTerm]);

  const onCloseModal = () => {
    setToggleActive((state) => !state);
  };

  const updateTextContact = useCallback(
    (value) => {
      setInputValueClient(value);
      setIsBlocking(true);
      if (value === "") {
        setClientesOptions([]);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = [].filter((option) =>
        option.label.match(filterRegex)
      );
      setClientesOptions(resultOptions);
    },
    [setIsBlocking]
  );

  function readDirectionEnvio(direction) {
    return (
      <div className="flex flex-col">
        <TextStyle>
          {direction.direccion && direction.direccion + ", "}
          {direction.ciudad && direction.ciudad + ", "}
          {direction.estado && direction.estado + ", "}
          {direction.codigo_postal && "C.P. " + direction.codigo_postal}.
        </TextStyle>
        <TextStyle variation="subdued">{direction.telefono}</TextStyle>
      </div>
    );
  }

  function readDirectionFacturacion(direction) {
    if (
      clienteDB.direccionEnvioId &&
      clienteDB.direccionEnvioId === clienteDB.direccionFacturacionId
    ) {
      return <TextStyle>La misma dirección de envío</TextStyle>;
    } else {
      return (
        <div className="flex flex-col">
          <TextStyle>
            {direction.direccion && direction.direccion + ", "}
            {direction.ciudad && direction.ciudad + ", "}
            {direction.estado && direction.estado + ", "}
            {direction.codigo_postal && "C.P. " + direction.codigo_postal}.
          </TextStyle>
          <TextStyle variation="subdued">{direction.telefono}</TextStyle>
        </div>
      );
    }
  }

  const buscarClienteField = (
    <Autocomplete.TextField
      clearButton
      onClearButtonClick={() => updateTextContact("")}
      onChange={updateTextContact}
      value={inputValueClient}
      prefix={<Icon source={SearchMinor} color="inkLighter" />}
      placeholder="Busca un cliente"
      autoComplete="off"
    />
  );

  const onSelectClient = (values) => {
    updateSelection(values);
    setSelectedOptions(values);
  };

  if (!clienteDB) {
    return isLoading ? (
      <Card>
        <Card.Section>
          <SkeletonBodyText lines={4} />
        </Card.Section>
        <Card.Section>
          <SkeletonBodyText lines={2} />
        </Card.Section>
      </Card>
    ) : (
      <>
        <Card title="Cliente">
          <Card.Section>
            <Autocomplete
              listTitle="Clientes disponibles"
              willLoadMoreResults
              loading={isLoadingClientes}
              options={clientesOptions}
              selected={selectedOptions}
              textField={buscarClienteField}
              onSelect={(values) => onSelectClient(values)}
              actionBefore={
                createClient
                  ? {
                      content: (
                        <Button
                          plain
                          icon={
                            <Icon
                              source={CirclePlusMajor}
                              color="interactive"
                            />
                          }
                        >
                          Crear un nuevo cliente
                        </Button>
                      ),
                      onAction: onCloseModal,
                    }
                  : null
              }
            />
          </Card.Section>
        </Card>
        {toggleActive && (
          <AddClientModal
            isOpen={toggleActive}
            onClose={onCloseModal}
            name={inputValueClient}
            setIdCustomer={(val) => onSelectClient(val)}
          />
        )}
      </>
    );
  }

  return (
    <Card fullWidth>
      <Card.Section
        title="Contacto"
        actions={
          hasRemoveItem
            ? [{ icon: MobileCancelMajor, onAction: removeClient }]
            : []
        }
      >
        <div className="flex flex-col gap-3">
          <div className="flex flex-col">
            <TextStyle variation="subdued">{clienteDB.nombre}</TextStyle>
            <Link>{clienteDB.email}</Link>
          </div>
          <CustomBadge status={clienteDB.estatus.nombre} />
          {!clienteDB.clave && (
            <div className="mt-2">
              <Badge status="critical">
                <TextStyle variation="strong">
                  No hay una clave para este cliente
                </TextStyle>
              </Badge>
            </div>
          )}
          {!clienteDB.almacen && (
            <div className="mt-2">
              <Badge status="critical">
                <TextStyle variation="strong">
                  Asigna un almacén al cliente para crear pedidos
                </TextStyle>
              </Badge>
            </div>
          )}
        </div>
      </Card.Section>
      <Card.Section
        title="Dirección de envío"
        actions={[
          {
            content: "Editar",
            onAction: () => {
              setEditDirectionName("envío");
              handleDirectionModal();
            },
          },
        ]}
      >
        {clienteDB.direccionEnvioId &&
        clienteDB.direcciones.some(
          (i) => i.consignatario_id === clienteDB.direccionEnvioId
        ) ? (
          readDirectionEnvio(
            clienteDB.direcciones.find(
              (i) => i.consignatario_id === clienteDB.direccionEnvioId
            )
          )
        ) : (
          <TextStyle>Aún no hay una dirección de envío asignada</TextStyle>
        )}
      </Card.Section>
      {showBilligAddres && (
        <Card.Section
          title="Dirección de facturación"
          actions={[
            {
              content: "Editar",
              onAction: () => {
                setEditDirectionName("facturación");
                handleDirectionModal();
              },
            },
          ]}
        >
          {clienteDB.direccionFacturacionId &&
          clienteDB.direcciones.some(
            (i) => i.consignatario_id === clienteDB.direccionFacturacionId
          ) ? (
            readDirectionFacturacion(
              clienteDB.direcciones.find(
                (i) => i.consignatario_id === clienteDB.direccionFacturacionId
              )
            )
          ) : (
            <TextStyle>
              Aún no hay una dirección de facturación asignada
            </TextStyle>
          )}
        </Card.Section>
      )}
      <Card.Section title="Moneda">
        <TextStyle>
          {clienteDB?.moneda?.nombre}
          {" ("}
          {clienteDB?.moneda?.claveFiscal}
          {")"}
        </TextStyle>
      </Card.Section>
    </Card>
  );
}
