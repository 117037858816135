import React, { useState, useEffect } from "react";
import moment from "moment";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link, TextStyle, Modal, Spinner, Button } from "@shopify/polaris";

import ApiServiceComercios from "../../services/ApiServiceComercios";
import useToggle from "../../hooks/useToggle";
import { logout } from "../../actions/AuthActions";
import { getUserInfo } from "../../reducers";
import "../LoginScreen/LoginScreenStyles.css";
import useSchedule from "../../hooks/useSchedule";

const formatTime = "hh:mm a";
export default function ScheduledStorePage() {
  const dispatch = useDispatch();
  const router = useHistory();
  const usuario = useSelector(getUserInfo);

  const [isActive, toggleActive] = useToggle(false);
  const { loading, schedules, isInRange, remaining } = useSchedule();
  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    document.title = "B2BGO - Login";
    ApiServiceComercios.getImageData()
      .then(({ storeDB }) => {
        setImageData(storeDB?.imagen?.url);
      })
      .catch((error) => {
        throw error;
      });
    return () => null;
  }, []);

  const logoutSession = () => {
    dispatch(logout(usuario._id)).then(() => {
      router.replace("/login");
    });
  };

  if (isInRange) {
    <Redirect to="/login" />;
  }

  const rows = schedules
    ?.filter(({ start }) => start)
    .map(({ dayOfWeek, start, end }) => {
      let [hourStart, minuteStart] = start.split(":");
      let [hourEnd, minuteEnd] = end.split(":");
      let startInput = moment({ hour: hourStart, minute: minuteStart }).format(
        formatTime
      );
      let endInput = moment({ hour: hourEnd, minute: minuteEnd }).format(
        formatTime
      );
      let title = moment().day(dayOfWeek).format("dddd");

      return (
        <TextStyle variation="subdued">
          {`${title} de ${startInput} a ${endInput}`}
        </TextStyle>
      );
    });

  return (
    <div
      id="bg-gradient-animation"
      className="flex w-full h-screen items-center"
    >
      <div className="login-card w-full sm:h-auto">
        <img
          src={imageData ? imageData : "b2bgologo.png"}
          className="mb-10 img-login"
          alt="b2bgo-logo"
        />
        {loading ? (
          <div className="flex justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="text-3xl">
              <TextStyle variation="strong" fontWeight="semibold">
                Regresamos pronto
              </TextStyle>
            </div>
            <TextStyle variation="subdued">Podrás acceder en:</TextStyle>
            <div className="text-3xl my-20">
              <TextStyle variation="strong" fontWeight="semibold">
                <span>
                  {remaining.days} días {remaining.hours} horas{" "}
                  {remaining.minutes} minutos
                </span>
              </TextStyle>
            </div>
            <div className="text-blue-500">
              <Link onClick={toggleActive} removeUnderline>
                Conoce nuestros horarios de servicio
              </Link>
            </div>
            <div className="mt-4">
              <Button onClick={logoutSession} plain>
                Cerrar sesión
              </Button>
            </div>
          </>
        )}
      </div>

      {isActive && (
        <Modal open={isActive} onClose={toggleActive}>
          <div className="login-card w-full sm:h-auto">
            <img
              src={imageData ? imageData : "b2bgologo.png"}
              className=" img-login"
              alt="b2bgo-logo"
            />{" "}
            <div className="text-5xl">
              <TextStyle variation="strong" fontWeight="semibold">
                Horarios
              </TextStyle>
            </div>
            <div className="flex flex-col justify-center mt-10">{rows}</div>
            <div className="text-blue-500 mt-10">
              <Link onClick={toggleActive} removeUnderline>
                Regresar
              </Link>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
