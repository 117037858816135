import { Card, Page, Tabs } from "@shopify/polaris";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fecthArticulos, resetPage } from "../../actions/ArticulosActions";
import { toggleToast } from "../../actions/InteractiveActions";
import {
  ArticulosList,
  HelpFooter,
  ImportArticlesModal,
  Layer,
} from "../../components";
import ArticulosContainer from "../../containers/ArticulosContainer/ArticulosContainer";
import useToggle from "../../hooks/useToggle";
import useUrlSearch from "../../hooks/useUrlSearch";
import { getPaginationArticulos } from "../../reducers";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import useDebounce from "../../services/useDebounce";
import { TabsArticulosScreen as tabs } from "../../utils/Tabs";

const objSearch = [
  "query",
  "tab_selected",
  "product_type",
  "location",
  "status",
  "sale_channels",
  "brand",
  "tagged_with",
  "continue_selling",
  "prices",
  "sort",
  "order",
];

export default function ArticulosScreen({ forbidden }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { updateHistory, getSearch } = useUrlSearch({ objSearch, pathname });
  const queryValueRef = useRef(getSearch("query", "", true));
  const pagination = useSelector(getPaginationArticulos);

  const allIds = useSelector((state) => state.Articulos.allIdsDB);
  const [activeImportModal, toggleActiveImportModal] = useToggle(false);
  const [lineas, setLineas] = useState([]);
  const [precios, setPrecios] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const [brands, setBrands] = useState([]);
  const [orden, setOrder] = useState(getSearch("order", ["1"]));
  const [state, setState] = useState(getSearch("status", []));
  const [brand, setBrand] = useState(getSearch("brand", []));
  const [sucursal, setSucursal] = useState(getSearch("location", []));
  const [queryValue, setQueryValue] = useState(getSearch("query", "", true));

  const [lineaArticulo, setLineaArticulo] = useState(
    getSearch("product_type", [])
  );
  const [orderSelected, setSortSelected] = useState(
    getSearch("sort", ["nombre"])
  );
  const [precioLista, setPrecioLista] = useState(
    getSearch("prices", ["Precio de lista"])
  );
  const [tabSelected, setTabSelected] = useState(
    Number(getSearch("tab_selected", 0, true))
  );
  const [salesChannel, setSalesChannel] = useState(
    getSearch("sale_channels", [])
  );
  const [taggedWith, setTaggedWith] = useState([]);
  const [continueSelling, setContinueSelling] = useState(
    getSearch("continue_selling", [])
  );

  // const debouncedTaggedTerm = useDebounce(taggedWith, 900);
  const debouncedSearchTerm = useDebounce(queryValue, 900);

  useEffect(() => {
    queryValueRef.current = queryValue;
  });

  useEffect(() => {
    ApiServiceComercios.obtenerLineas().then(({ lineas }) => {
      setLineas(lineas.map((i) => ({ label: i.nombre, value: i.nombre })));
    });
    ApiServiceComercios.obtenerSucursales().then(({ sucursales }) => {
      setSucursales(
        sucursales.map((i) => ({ label: i.nombre, value: i.nombre }))
      );
    });
    ApiServiceComercios.obtenerPrecioListas().then(({ precioListas }) => {
      setPrecios(
        precioListas.map((i) => ({ label: i.nombre, value: i.nombre }))
      );
    });
    ApiServiceComercios.getBrands().then(({ brands }) => {
      setBrands(brands.map((i) => ({ label: i.name, value: i.name })));
    });
  }, []);

  useEffect(() => {
    dispatch(
      fecthArticulos({
        sort: orderSelected[0],
        query: queryValueRef.current,
        existencia: tabSelected - 1,
        orden: orden[0],
        linea: JSON.stringify(lineaArticulo),
        sucursal: JSON.stringify(sucursal),
        sales_channels: JSON.stringify(salesChannel),
        status: JSON.stringify(state),
        marcas: JSON.stringify(brand),
        taggedWith: JSON.stringify(taggedWith),
        continuar_vendiendo: JSON.stringify(continueSelling),
      })
    );
  }, [
    tabSelected,
    dispatch,
    orderSelected,
    orden,
    lineaArticulo,
    sucursal,
    debouncedSearchTerm,
    pagination.currentPage,
    state,
    salesChannel,
    taggedWith,
    brand,
    continueSelling,
  ]);

  function handleChangeStates(
    value,
    setStatus,
    field = "",
    useStringify = true
  ) {
    updateHistory(
      {
        [field]: value,
      },
      useStringify
    );
    resetPagination();
    setStatus(value);
  }

  function resetPagination() {
    dispatch(resetPage());
  }

  const handleContinueSelling = (
    continueSelling,
    items,
    hasAllItemsSelected
  ) => {
    ApiServiceComercios.setPreferencesByItems({
      continuar_vendiendo: continueSelling,
      articlesIds: items,
      allArticles: hasAllItemsSelected,
    }).then(({ message }) => dispatch(toggleToast({ message })));
  };

  return (
    <Layer title="Artículos" forbidden={forbidden} scrollToTop>
      <Page
        title="Artículos"
        fullWidth
        secondaryActions={[
          {
            content: "Importar",
            onAction: toggleActiveImportModal,
          },
        ]}
      >
        <Card>
          {activeImportModal && (
            <ImportArticlesModal
              open={activeImportModal}
              onClose={toggleActiveImportModal}
            />
          )}
          <ArticulosContainer>
            {({ isLoading, articulos, pagination }) => {
              return (
                <Tabs
                  tabs={tabs}
                  selected={tabSelected}
                  onSelect={(value) =>
                    handleChangeStates(
                      value,
                      setTabSelected,
                      "tab_selected",
                      false
                    )
                  }
                >
                  <ArticulosList
                    allIds={allIds}
                    tabSelected={tabSelected}
                    articulos={articulos}
                    pagination={pagination}
                    isLoading={isLoading}
                    queryValue={queryValue}
                    orden={orden}
                    orderSelected={orderSelected}
                    sucursales={sucursales}
                    sucursal={sucursal}
                    precios={precios}
                    precioLista={precioLista}
                    lineas={lineas}
                    lineaArticulo={lineaArticulo}
                    handleContinueSelling={handleContinueSelling}
                    handleFiltersQueryChange={(value) =>
                      handleChangeStates(value, setQueryValue, "query", false)
                    }
                    handleSucursalRemove={() =>
                      handleChangeStates([], setSucursal, "location")
                    }
                    handleSucursalChange={(value) =>
                      handleChangeStates(value, setSucursal, "location")
                    }
                    handlePrecioListaChange={(value) =>
                      handleChangeStates(value, setPrecioLista, "prices")
                    }
                    handlePrecioListaRemove={() =>
                      handleChangeStates(
                        ["Precio de lista"],
                        setPrecioLista,
                        "prices"
                      )
                    }
                    state={state}
                    salesChannel={salesChannel}
                    handleStateChange={(value) =>
                      handleChangeStates(value, setState, "status")
                    }
                    handleLineaArticuloRemove={() =>
                      handleChangeStates([], setLineaArticulo, "product_type")
                    }
                    handleLineaArticuloChange={(value) =>
                      handleChangeStates(
                        value,
                        setLineaArticulo,
                        "product_type"
                      )
                    }
                    handleOrdenChange={(value) =>
                      handleChangeStates(value, setOrder, "order")
                    }
                    handleOrderSelectedChange={(value) =>
                      handleChangeStates(value, setSortSelected, "sort")
                    }
                    handleOrderSelectedRemove={() =>
                      handleChangeStates(["nombre"], setSortSelected, "sort")
                    }
                    handleQueryValueRemove={() =>
                      handleChangeStates("", setQueryValue)
                    }
                    handleStateRemove={() =>
                      handleChangeStates([], setState, "status")
                    }
                    handleSalesChannelChange={(value) =>
                      handleChangeStates(
                        value,
                        setSalesChannel,
                        "sale_channels"
                      )
                    }
                    handleSalesChannelRemove={() =>
                      handleChangeStates([], setSalesChannel, "sale_channels")
                    }
                    taggedWith={taggedWith}
                    handleTaggedWithChange={(value) =>
                      handleChangeStates(value, setTaggedWith, "tagged_with")
                    }
                    handleTaggedWithRemove={() =>
                      handleChangeStates("", setTaggedWith, "tagged_with")
                    }
                    brand={brand}
                    brands={brands}
                    handleBrandChange={(value) =>
                      handleChangeStates(value, setBrand, "brand")
                    }
                    handleBrandRemove={() =>
                      handleChangeStates([], setBrand, "brand")
                    }
                    continueSelling={continueSelling}
                    handleContinueSellingChange={(value) =>
                      handleChangeStates(
                        value,
                        setContinueSelling,
                        "continue_selling"
                      )
                    }
                    handleContinueSellingRemove={() =>
                      handleChangeStates(
                        [],
                        setContinueSelling,
                        "continue_selling"
                      )
                    }
                  />
                </Tabs>
              );
            }}
          </ArticulosContainer>
        </Card>
        <HelpFooter title="artículos" url="https://help.b2bgo.mx/articulos" />
      </Page>
    </Layer>
  );
}
