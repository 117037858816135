import ApiServiceComercios from "../services/ApiServiceComercios";
import NormalizeById from "../utils/NormalizedById";
import { getPaginationPreliminares } from "../reducers";

export const PRELIMINARES_BEGIN = "PRELIMINARES_BEGIN";
export const ADD_PRELIMINARES = "ADD_PRELIMINARES";
export const PRELIMINARES_FAILED = "PRELIMINARES_FAILED";
export const CLEAR_PRELIMINARES = "CLEAR_PRELIMINARES";
export const RESET_PAGE = "RESET_PAGE";
export const ADD_ID_PRELIMINARES = "ADD_ID_PRELIMINARES";
export const CHANGE_PAGE = "CHANGE_PAGE";

export function fetchPreliminares(params) {
  return async (dispatch, getState) => {
    let pagination = getPaginationPreliminares(getState());
    dispatch(clearPreliminares());
    dispatch(preliminaresBegin());
    return ApiServiceComercios.obtenerPreliminares({
      ...params,
      limit: pagination.sizePerPage,
      skip: pagination.currentPage * pagination.sizePerPage,
    })
      .then((result) => {
        let data = NormalizeById(result.draftOrders, "_id");
        data["total"] = result.total;
        data["allIdsDB"] = result.allIds;
        dispatch(addPreliminares(data));
        return result;
      })
      .catch((err) => {
        dispatch(preliminaresFailed());
      });
  };
}

export const preliminaresBegin = () => ({
  type: PRELIMINARES_BEGIN,
});

export const addPreliminares = (data) => ({
  type: ADD_PRELIMINARES,
  payload: { data },
});

export const preliminaresFailed = () => ({
  type: PRELIMINARES_FAILED,
});

export const clearPreliminares = () => ({
  type: CLEAR_PRELIMINARES,
});

export const changePage = (currentPage) => ({
  type: CHANGE_PAGE,
  payload: { data: { currentPage } },
});

export const resetPage = () => ({
  type: RESET_PAGE,
});

export const addIdPreliminares = (id) => ({
  type: ADD_ID_PRELIMINARES,
  payload: { id },
});
