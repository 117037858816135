import { Button } from "@shopify/polaris";
import { DragHandleMinor } from "@shopify/polaris-icons";
import { SortableHandle } from "react-sortable-hoc";

export const DragButton = ({ disabled = false }) => (
  <Button icon={DragHandleMinor} plain disabled={disabled} />
);

export const DragHandle = SortableHandle(() => <DragButton />);
export const DragHandleSubItem = SortableHandle(() => <DragButton />);
