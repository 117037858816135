import React from "react";
import {
  Button,
  ChoiceList,
  Heading,
  Scrollable,
  Sheet,
} from "@shopify/polaris";
import { MobileCancelMajor } from "@shopify/polaris-icons";
import CollapseAction from "../CollapseAction";

export const SortProductsChoices = [
  { label: "Nombre", value: "nombre" },
  { label: "Clave", value: "clave" },
  { label: "Precio", value: "preciounitario" },
  { label: "Existencia", value: "existencia" },
  { label: "U. Medida", value: "unidadmed" },
];

export const OrderComponent = ({
  orderSelected = [],
  handleOrderSelectedChange = () => {},
  orden = [],
  handleOrdenChange = () => {},
}) => {
  return (
    <>
      <ChoiceList
        title="Ordenar"
        titleHidden
        choices={SortProductsChoices}
        selected={orderSelected || []}
        onChange={handleOrderSelectedChange}
      />
      <div className="flex w-full border border-t-1 border-b-0 border-r-0 border-l-0 border-gray-400">
        <ChoiceList
          title="Ordenar"
          titleHidden
          choices={[
            { label: "Ascendente", value: "1" },
            { label: "Descendente", value: "-1" },
          ]}
          selected={orden}
          onChange={handleOrdenChange}
        />
      </div>
    </>
  );
};

export default function ArticuloFilterModal({
  isOpen,
  toggleSheetActive,
  onAction,
  orderSelected,
  handleOrderSelectedChange,
  orden,
  handleOrdenChange,
}) {
  return (
    <Sheet open={isOpen} onClose={toggleSheetActive}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div
          style={{
            alignItems: "center",
            borderBottom: "1px solid #DFE3E8",
            display: "flex",
            justifyContent: "space-between",
            padding: "1.6rem",
            width: "100%",
          }}
        >
          <Heading>Ordenar</Heading>
          <Button
            accessibilityLabel="Cancelar"
            icon={MobileCancelMajor}
            onClick={toggleSheetActive}
            plain
          />
        </div>
        <Scrollable
          style={{ paddingTop: 0, paddingBottom: "1.6rem", height: "100%" }}
        >
          <CollapseAction title="Ordenar" active>
            <OrderComponent
              handleOrdenChange={handleOrdenChange}
              handleOrderSelectedChange={handleOrderSelectedChange}
              orden={orden}
              orderSelected={orderSelected}
            />
          </CollapseAction>
          <div
            style={{
              alignItems: "center",
              borderTop: "1px solid #DFE3E8",
              display: "flex",
              justifyContent: "space-between",
              padding: "1.6rem",
              width: "100%",
            }}
          >
            <Button onClick={toggleSheetActive}>Cancelar</Button>
            <Button primary onClick={onAction}>
              Listo
            </Button>
          </div>
        </Scrollable>
      </div>
    </Sheet>
  );
}
