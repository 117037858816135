import React from "react";
import { Card, Modal, TextContainer } from "@shopify/polaris";

export default function DeleteModal({
  active,
  handleChange,
  handleDelete,
  title,
  body,
  isLoading,
  primariActionContent = "Eliminar",
}) {
  return (
    <Modal
      open={active}
      onClose={handleChange}
      title={title}
      primaryAction={{
        content: primariActionContent,
        onAction: handleDelete,
        destructive: true,
        loading: isLoading,
        disabled: isLoading,
      }}
      secondaryActions={[
        {
          content: "Cancelar",
          onAction: handleChange,
          disabled: isLoading,
        },
      ]}
    >
      <Card sectioned>
        <TextContainer>
          <p>{body}</p>
        </TextContainer>
      </Card>
    </Modal>
  );
}
