import React from "react";
import {
  FormLayout,
  Modal,
  Stack,
  TextField,
  Card,
  Banner,
} from "@shopify/polaris";

const PackagingKeysModal = ({
  role,
  active,
  keyname,
  quantity,
  setKeyname,
  setRole,
  setQuantity,
  handleModalChange,
  handleAddPackageKey,
  fieldErrors,
}) => {
  return (
    <Modal
      open={active}
      onClose={handleModalChange}
      title="Agregar clave de empaque"
      primaryAction={{
        content: "Agregar",
        disabled: !keyname || !role || !quantity || fieldErrors.keyname,
        onAction: handleAddPackageKey,
      }}
      secondaryActions={[
        {
          content: "Cancelar",
          onAction: handleModalChange,
        },
      ]}
    >
      <Modal.Section>
        <Card>
          <Card.Section>
            <FormLayout>
              <Stack distribution="fill" spacing="loose">
                <TextField
                  id="key"
                  label="Clave"
                  type="text"
                  value={keyname}
                  onChange={setKeyname}
                  error={fieldErrors?.keyname}
                />
              </Stack>
              <Stack distribution="fill">
                <TextField
                  id="role"
                  label="Rol"
                  value={role}
                  onChange={setRole}
                />
                <TextField
                  id="content"
                  type="number"
                  label="Contenido"
                  min={1}
                  value={quantity}
                  onChange={setQuantity}
                />
              </Stack>
              <Stack distribution="fill" spacing="loose">
                <Banner status="success">
                  Indica en contenido del empaque en la unidad de medida del
                  artículo. Al utilizar una clave de empaque en la captura de
                  documentos el sistema asignará el contenido del empaque.
                </Banner>
              </Stack>
            </FormLayout>
          </Card.Section>
        </Card>
      </Modal.Section>
    </Modal>
  );
};

export default PackagingKeysModal;
