import { useEffect, useState } from "react";

export default function useDetectTouchWindow() {
  const [isTouchable, setIsTouchable] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsTouchable(window.matchMedia("(pointer: coarse)").matches);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isTouchable;
}
