export const ADD_ARTICULOS_SHOPPING_CART = "ADD_ARTICULOS_SHOPPING_CART";
export const CHANGE_QUANTITY = "CHANGE_QUANTITY";
export const ADD_CLIENT_INFO = "ADD_CLIENT_INFO";
export const CLEAR_SHOPPING_CART = "CLEAR_SHOPPING_CART";
export const REMOVE_ARTICLE_SHOPPING_CART = "REMOVE_ARTICLE_SHOPPING_CART";
export const ADD_NOTE_ITEM_CART = "ADD_NOTE_ITEM_CART";
export const CLEAR_SHOPPING_CART_NO_USER = "CLEAR_SHOPPING_CART_NO_USER";

export const addShoppingCartArticles = (data) => ({
  type: ADD_ARTICULOS_SHOPPING_CART,
  payload: { data },
});

export const changeQuantity = (data) => ({
  type: CHANGE_QUANTITY,
  payload: { data },
});

export const addClientInfo = (data) => ({
  type: ADD_CLIENT_INFO,
  payload: { data },
});

export const clearShoppingCart = () => ({
  type: CLEAR_SHOPPING_CART,
});

export const clearShoppingCartNoUser = () => ({
  type: CLEAR_SHOPPING_CART_NO_USER,
});

export const removeArticleShoppingCart = (data) => ({
  type: REMOVE_ARTICLE_SHOPPING_CART,
  payload: { data },
});

export const addNoteItemCart = (data) => ({
  type: ADD_NOTE_ITEM_CART,
  payload: { data },
});
