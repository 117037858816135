import React from 'react';
import { createContext } from "react";
import { useSocket } from "../hooks/useSocket";
import endpointSocket from '../utils/SocketUrl';

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {

   const { socket, isOnline } = useSocket(endpointSocket);

   return (
      <SocketContext.Provider value={{ socket, isOnline }}>
         { children } 
      </SocketContext.Provider>
   )
}