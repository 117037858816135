import {
  Autocomplete,
  Card,
  DropZone,
  Modal,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CircleAlertMinor from "../../assets/images/warn.svg";

import ApiServiceComercios from "../../services/ApiServiceComercios";
import useDebounce from "../../services/useDebounce";
import { validReceipTypes } from "../../utils/constants";
import { NormalizeName, formatTo2Digits } from "../../utils/Formats";
import { scrollToTop } from "../../utils/windowActions";
import DropzoneFiles from "../DropzoneFiles";

const title = {
  billing: "Las facturas pagadas manualmente deben aprobarse.",
  orders:
    "Los pedidos pagados manualmente deben aprobarse antes de su preparación.",
};

export default function ManualPaymentModal({
  active,
  onClose,
  dataOrder = {},
  setIsBlocking,
  setErrorArticulos,
  amount,
  origin,
  ids = [],
}) {
  const router = useHistory();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [payment, setPayment] = useState({
    paymentMethod: undefined,
    files: [],
  });
  const [isPaid, setIsPaid] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const [isPaying, setIsPaying] = useState(false);

  const debounceInputValue = useDebounce(inputValue, 900);

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      ApiServiceComercios.getManualPayments()
        .then(({ manualPayments = [] }) => {
          const options = manualPayments.map((i) => ({
            label: i.name,
            value: i._id,
            title: i.instructions,
          }));
          setOptions(options);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    };
    fetchData();
  }, [debounceInputValue]);

  const handleDropZoneDrop = (_dropFiles, acceptedFiles) => {
    const formData = new FormData();
    if (acceptedFiles.length > 0) {
      acceptedFiles.forEach((file) => {
        formData.append("files", file, NormalizeName(file.name));
      });
      ApiServiceComercios.postFiles(formData)
        .then(({ files = [] }) => {
          handleChange("files", files);
        })
        .finally(() => {
          setIsAdding(false);
        });
    }
  };

  const handleChange = (field, value) => {
    setPayment((state) => ({ ...state, [field]: value }));
  };

  const updateSelection = (selected) => {
    const item = options.find((option) => option.value === selected[0]);
    if (item) {
      setInputValue(item.label);
      handleChange("paymentMethod", item);
    }
  };

  const onFinish = () => {
    if (origin === "orders") {
      onClose();
      router.push(`/cliente/pedidos/${orderData?.id}/gracias`);
    }
    if (origin === "billing") {
      onClose(true, true);
    }
  };

  const onPayAction = () => {
    let { orderData: data, quotationId, draftOrderId } = dataOrder;
    const manualPayment = {
      amount,
      id: payment.paymentMethod.value,
      files: payment.files.map(({ url }) => url),
    };

    setIsPaying(true);
    if (origin === "orders") {
      data["manualPayment"] = manualPayment;
      setIsBlocking(false);
      ApiServiceComercios.crearPedido(data, null, quotationId, draftOrderId)
        .then((result) => {
          if (result.ok) {
            setIsPaying(false);
            setIsPaid(true);
            setOrderData({ id: result.id });
          }
        })
        .catch((err) => {
          setIsBlocking(true);
          if (err?.response?.data?.message) {
            setErrorArticulos({
              message: err.response?.data?.message || "",
              articulos: err.response?.data?.articulos || [],
            });
          }
          onClose();
          scrollToTop();
          setIsPaying(false);
        });
    }
    if (origin === "billing") {
      manualPayment["ids"] = ids;
      ApiServiceComercios.payBilling({ manualPayment })
        .then((result) => {
          setIsPaying(false);
          setIsPaid(true);
        })
        .catch((err) => {
          setIsPaying(false);
        });
    }
  };
  const handleOnClear = () => {
    setInputValue("");
    handleChange("paymentMethod", undefined);
  };

  const textfield = (
    <Autocomplete.TextField
      onChange={setInputValue}
      value={inputValue}
      placeholder="Selecciona un método de pago manual"
      autoComplete="off"
      label="Método de pago"
      clearButton
      readOnly={payment?.paymentMethod === undefined ? false : true}
      onClearButtonClick={handleOnClear}
    />
  );

  const fileUpload = payment.files.length === 0 && (
    <DropZone.FileUpload
      actionTitle="Agregar comprobante"
      actionHint={`Acepta imágenes y PDFs.`}
    />
  );

  return (
    <Modal
      title="Capturar un pago"
      open={active}
      onClose={isPaying ? undefined : onClose}
      secondaryActions={
        isPaid
          ? []
          : [{ content: "Cancelar", onAction: onClose, disabled: isPaying }]
      }
      primaryAction={{
        content: isPaid ? "Aceptar" : `Pagar ${formatTo2Digits(amount)}`,
        disabled:
          isAdding ||
          amount === 0 ||
          !payment.paymentMethod ||
          payment.files.length === 0,
        onAction: isPaid ? onFinish : onPayAction,
        loading: isPaying || isAdding,
      }}
    >
      {isPaid ? (
        <div className="p-8 flex flex-col text-center items-center">
          <div style={{ fontSize: 100 }}>
            <img width={40} height={40} src={CircleAlertMinor} alt="status" />
          </div>
          <div className="mb-6">
            <p className="font-bold text-3xl my-6">
              Tu pago está en aprobación
            </p>
            <TextStyle>{title[origin]}</TextStyle>
          </div>
          <div className="flex w-full	 flex-col my-4">
            <div className="flex justify-between">
              <TextStyle>Importe:</TextStyle>
              <TextStyle>{formatTo2Digits(amount)}</TextStyle>
            </div>
            <hr className="my-6" />
            <div className="flex justify-between	">
              <TextStyle>Fecha:</TextStyle>
              <TextStyle>
                {dayjs().format("ddd D MMM YYYY [a las] h:mm")}
              </TextStyle>
            </div>
          </div>
        </div>
      ) : (
        <Card sectioned>
          <div className="flex flex-col gap-4">
            <Stack distribution="fill">
              <Autocomplete
                options={options || []}
                selected={[]}
                onSelect={updateSelection}
                textField={textfield}
                loading={loading}
                willLoadMoreResults
                listTitle="Métodos de pago disponibles"
              />
              <TextField
                label="Importe"
                type="text"
                value={formatTo2Digits(amount, false)}
                prefix="$"
                onChange={() => {}}
                min={0}
                disabled
                pattern=""
              />
            </Stack>
            {payment.paymentMethod ? (
              <>
                <TextField
                  label="Instrucciones de pago"
                  multiline={4}
                  value={payment.paymentMethod?.title}
                  onChange={() => {}}
                  disabled
                />
                <DropZone
                  label=""
                  outline
                  labelHidden
                  accept={validReceipTypes.join(",")}
                  onDrop={handleDropZoneDrop}
                  disabled={loading}
                >
                  {fileUpload}
                  <DropzoneFiles
                    files={payment.files}
                    hasDelete
                    onDelete={() => handleChange("files", [])}
                    hasMargin={false}
                  />
                </DropZone>
              </>
            ) : null}
          </div>
        </Card>
      )}
    </Modal>
  );
}
