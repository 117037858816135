import {
  Card,
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  SkeletonThumbnail,
  Stack,
} from "@shopify/polaris";
import Layer from "../../Layout/Layout";

export const AnnotatedSkeleton = ({
  forbidden,
  rowsQuantity,
  textLines = 2,
  descriptionLines = 2,
}) => {
  return (
    <Layer title="Envío y entrega" forbidden={forbidden}>
      <SkeletonPage
        breadcrumbs
        title={<SkeletonDisplayText size="medium" />}
        primaryAction={<SkeletonDisplayText size="medium" />}
      >
        {Array.from(Array(rowsQuantity).keys()).map((item) => (
          <Layout key={item}>
            <Layout.AnnotatedSection
              title={<SkeletonDisplayText size="medium" />}
              description={<SkeletonBodyText lines={descriptionLines} />}
            >
              <div key={item} className="mb-8">
                <Card sectioned>
                  <Stack>
                    <SkeletonThumbnail size="small" />
                    <div style={{ minWidth: 300 }}>
                      <SkeletonBodyText lines={textLines} />
                    </div>
                  </Stack>
                </Card>
              </div>
            </Layout.AnnotatedSection>
          </Layout>
        ))}
      </SkeletonPage>
    </Layer>
  );
};
