import { useEffect, useState } from "react";
import ApiServiceComercios from "../services/ApiServiceComercios";

const useGetVendors = () => {
  const [vendors, setVendors] = useState([]);
  const [isLoadingVendors, setIsLoading] = useState(false);
  useEffect(() => {
    const getVendors = () => {
      setIsLoading(true);
      ApiServiceComercios.getVendors()
        .then(({ vendors }) => {
          //add values to autocomplete list
          const vendorsItems = vendors.map(({ name, _id, ...props }) => ({
            label: name,
            value: _id,
            ...props,
          }));

          setVendors(vendorsItems);
        })
        .finally(() => setIsLoading(false));
    };
    getVendors();
  }, []);

  return { vendors, isLoadingVendors, setVendors };
};

export default useGetVendors;
