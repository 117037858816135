import { Icon } from "@shopify/polaris";
import {
  CollectionsMajor,
  EmailMajor,
  HomeMajor,
  ProductsMajor,
} from "@shopify/polaris-icons";

export const BADGE_STATUS = {
  Activo: "success",
  Análisis: "warning",
  Aprobado: "info",
  Atrasado: "warning",
  Baja: "critical",
  Calificación: "warning",
  Cancelado: "critical",
  Cerrado: "default",
  Cotización: "warning",
  Demostración: "warning",
  Desarrollo: "attention",
  Diagnóstico: "warning",
  Ganada: "success",
  "En ruta": "attention",
  Inactivo: "critical",
  Invitado: "attention",
  Negociación: "warning",
  Pendiente: "attention",
  Perdida: "critical",
  "No visible": "warning",
  Programado: "warning",
  Surtido: "success",
  Visible: "success",
  "--": "default",
};

export const PEDIDO_STATUS = [
  { label: "Pendiente", value: "Pendiente" },
  { label: "Surtido", value: "Surtido" },
  { label: "Cancelado", value: "Cancelado" },
  { label: "Cerrado", value: "Cerrado" },
];

export const PRELIMINARES_STATUS = [
  { label: "Pendiente", value: "Pendiente" },
  { label: "Completado", value: "Completado" },
  { label: "Cancelado", value: "Cancelado" },
];

export const CREATED_BY_PRELIMINARES = [
  // DELETE THIS
  { label: "Microsip", value: "Microsip" },
  { label: "B2Bgo", value: "B2Bgo" },
];

export const ORDER_BY_PRELIMINARES = [
  // DELETE THIS
  { label: "Folio", value: "folio" },
  { label: "Fecha", value: "createdDate" },
  { label: "Total", value: "total" },
  { label: "Estatus", value: "estatus" },
  { label: "Notas", value: "notas" },
];

export const COTIZACION_STATUS = [
  { label: "Pendiente", value: "Pendiente" },
  { label: "Aprobado", value: "Aprobado" },
  { label: "Cancelado", value: "Cancelado" },
  { label: "Cerrado", value: "Cerrado" },
];

export const PAYMENT_STATUS = [
  { label: "Pagado", value: "succeeded" },
  { label: "Pendiente", value: "pending" },
  { label: "Fallido", value: "requires_payment_method" },
];

export const CREATED_BY = [
  { label: "Microsip", value: "Microsip" },
  { label: "B2Bgo", value: "B2Bgo" },
];

export const ORDER_BY = [
  { label: "Folio", value: "folio" },
  { label: "Fecha", value: "createdDate" },
  { label: "Total", value: "total" },
  { label: "Estatus", value: "estatus" },
  { label: "Notas", value: "notas" },
];

export const PAYMENT_ORDER_BY = [
  { label: "Referencia", value: "reference" },
  { label: "Fecha", value: "createdAt" },
  { label: "Total", value: "amount" },
  { label: "Estato del pago", value: "status" },
];

export const OPPORTUNITY_TYPE = [
  { label: "Cliente", value: "Cliente" },
  { label: "Prospecto", value: "Prospecto" },
];

export const OPPORTUNITY_ORDER_BY = [
  { label: "Folio", value: "folio" },
  { label: "Fecha de cierre", value: "closingDate" },
  { label: "Importe", value: "amount" },
  { label: "Tipo", value: "type" },
  { label: "Origen", value: "origin" },
];

export const ARTICLE_STATE = [
  { label: "Activo", value: "Activo" },
  { label: "Borrador", value: "Borrador" },
];

export const SALES_CHANNELS = [
  { label: "Online Store", value: "online_store" },
];

export const CONTINUE_SELLING = [
  { label: "Continuar vendiendo", value: true },
  { label: "No continuar vendiendo", value: false },
];

export const OBJ_SEARCH_ARTICLES = ["query", "tab"];

export const TABLE_VALUES = [
  { singular: "cotización", plural: "cotizaciones" },
  { singular: "pedido", plural: "pedidos" },
  { singular: "oportunidad", plural: "oportunidades" },
  { singular: "pago", plural: "pagos" },
];

export const PERCENTAGES = [
  { label: "0%", value: "0" },
  { label: "10%", value: "10" },
  { label: "20%", value: "20" },
  { label: "30%", value: "30" },
  { label: "40%", value: "40" },
  { label: "50%", value: "50" },
  { label: "60%", value: "60" },
  { label: "70%", value: "70" },
  { label: "80%", value: "80" },
  { label: "90%", value: "90" },
  { label: "100%", value: "100" },
];

export const validFileTypes = [
  "image/jpeg",
  "image/png",
  "image/jpg",
  "application/pdf",
  ".pdf",
  ".xlsx",
  ".xls",
  ".doc",
  ".docx",
];

export const validImageTypes = [
  "image/gif",
  "image/jpeg",
  "image/jpg",
  "image/png",
];

export const validReceipTypes = [
  "image/gif",
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/pdf",
  ".pdf",
];

export const contentPositions = [
  { label: "Arriba a la izquierda", value: "upToLeft" },
  { label: "Arriba al centro", value: "upToCenter" },
  { label: "Arriba a la derecha", value: "upToRight" },
  { label: "Centrado a la izquerda", value: "centerToLeft" },
  { label: "Centrado en el medio", value: "centerToCenter" },
  { label: "Centrado a la derecha", value: "centerToRight" },
  { label: "Abajo a la izquerda", value: "bottomToLeft" },
  { label: "Abajo al centro", value: "bottomToCenter" },
  { label: "Abajo a la derecha", value: "bottomToRight" },
];

export const rickTextPosition = [
  { label: "Izquerda", value: "left" },
  { label: "Centrado", value: "center" },
  { label: "Derecha", value: "right" },
];

export const PAGE_SECTIONS_LIST = [
  {
    label: "Banner slider",
    type: "banner-slider",
    icon: "SlideshowMajor",
  },
  {
    label: "Lista de colecciones",
    type: "collections-list",
    icon: "CollectionsMajor",
  },
  {
    label: "Texto enriquecido",
    type: "rich-text",
    icon: "TextBlockMajor",
  },
  {
    label: "Colección destacada",
    type: "outstanding-collection",
    icon: "CollectionsMajor",
  },
  {
    label: "Banner de imagen",
    type: "banner-image",
    icon: "ImageMajor",
  },
  {
    label: "Video",
    type: "video",
    icon: "PlayCircleMajor",
  },
];

export const SUB_SECTIONS_DEFAULT = [
  {
    label: "Banner",
    type: "banner-slider",
    id: "banner-slider",
    src: "/empty-banner.svg",
    title: "Texto del banner",
    subtitle: "Cuenta la historia de tu marca",
    textButton: "Comprar ahora",
    urlButton: "#",
    position: "centerToCenter",
    opacity: "100",
    textColor: "#000000",
    backgroundColor: "#FFFFFF",
    buttonColor: "#1C2260",
    textButtonColor: "#FFFFFF",
    visible: true,
  },
  {
    label: "Colección",
    id: "collections-list",
    type: "collections-list",
    handle: "",
    name: "Colección",
    src: "/collection-empty-1.png",
    visible: true,
  },
];

export const PAGE_SECTIONS_LIST_DEFAULT = [
  {
    label: "Banner slider",
    icon: "SlideshowMajor",
    id: "banner-slider",
    type: "banner-slider",
    visible: true,
    sortable: true,
    properties: {
      rotateSliders: true,
      hideOnMobile: false,
      sliderTime: 2,
      design: "full-width",
      sliderHeight: "medium",
    },
    showAddButton: true,
    subNavigationItems: [
      {
        label: "Banner 1",
        id: "banner-slider-1",
        src: "/empty-banner.svg",
        title: "Texto del banner",
        subtitle: "Cuenta la historia de tu marca",
        textButton: "Comprar ahora",
        urlButton: "#",
        position: "centerToCenter",
        opacity: "100",
        textColor: "#000000",
        backgroundColor: "#FFFFFF",
        buttonColor: "#1C2260",
        textButtonColor: "#FFFFFF",
        visible: true,
      },
      {
        label: "Banner 2",
        id: "banner-slider-2",
        src: "/empty-banner.svg",
        title: "Texto del banner",
        subtitle: "Cuenta la historia de tu marca",
        textButton: "Comprar ahora",
        urlButton: "#",
        position: "centerToCenter",
        opacity: "100",
        textColor: "#000000",
        backgroundColor: "#FFFFFF",
        buttonColor: "#1C2260",
        textButtonColor: "#FFFFFF",
        visible: true,
      },
      {
        label: "Banner 3",
        id: "banner-slider-3",
        src: "/empty-banner.svg",
        title: "Texto del banner",
        subtitle: "Cuenta la historia de tu marca",
        textButton: "Comprar ahora",
        urlButton: "#",
        position: "centerToCenter",
        opacity: "100",
        textColor: "#000000",
        backgroundColor: "#FFFFFF",
        buttonColor: "#1C2260",
        textButtonColor: "#FFFFFF",
        visible: true,
      },
    ],
  },
  {
    label: "Lista de colecciones",
    icon: "CollectionsMajor",
    id: "collections-list",
    type: "collections-list",
    visible: true,
    sortable: true,
    properties: {
      title: "Colecciones",
      titleSize: "medium",
      rowsNumberDesktop: 4,
      hideOnMobile: false,
      rowsNumberMobile: 2,
    },
    showAddButton: true,
    subNavigationItems: [
      {
        label: "Colección 1",
        id: "collections-list-1",
        handle: "",
        name: "Colección 1",
        src: "/collection-empty-1.png",
      },
      {
        label: "Colección 2",
        id: "collections-list-2",
        handle: "",
        name: "Colección 2",
        src: "/collection-empty-2.png",
      },
      {
        label: "Colección 3",
        id: "collections-list-3",
        handle: "",
        name: "Colección 3",
        src: "/collection-empty-3.png",
      },
      {
        label: "Colección 4",
        id: "collections-list-4",
        handle: "",
        name: "Colección 4",
        src: "/collection-empty-4.png",
      },
    ],
  },
  {
    label: "Texto enriquecido",
    icon: "TextBlockMajor",
    id: "rich-text",
    type: "rich-text",
    visible: true,
    sortable: true,
    showAddButton: false,
    properties: {
      title: "Habla de tu marca",
      subtitle: "Comparte información sobre tu marca con tus clientes.",
      textButton: "Comprar ahora",
      urlButton: "#",
      textColor: "#000000",
      backgroundColor: "#FFFFFF",
      buttonColor: "#1C2260",
      textButtonColor: "#FFFFFF",
      position: "center",
    },
  },
  {
    label: "Colección destacada",
    icon: "CollectionsMajor",
    id: "outstanding-collection",
    type: "outstanding-collection",
    visible: true,
    sortable: true,
    showAddButton: false,
    properties: {
      title: "Colecciones destacadas",
      titleSize: "medium",
      position: "left",
      handle: "",
      productsNumberInDesktop: 10,
      showKey: true,
      showAvailability: true,
      showDisccount: true,
      showUnitButton: true,
      showSeeAllButton: true,
      hideOnMobile: false,
      rowsNumberInMobile: 4,
    },
  },
  {
    label: "Banner de imagen",
    icon: "ImageMajor",
    id: "banner-image",
    type: "banner-image",
    visible: true,
    sortable: true,
    showAddButton: false,
    properties: {
      hideOnMobile: false,
      design: "full-width",
      sliderHeight: "medium",
      src: "/empty-banner.svg",
      title: "Texto del banner",
      subtitle: "Cuenta la historia de tu marca",
      textButton: "Comprar ahora",
      urlButton: "#",
      position: "centerToCenter",
      opacity: "100",
      textColor: "#000000",
      backgroundColor: "#FFFFFF",
      buttonColor: "#1C2260",
      textButtonColor: "#FFFFFF",
    },
  },
  {
    label: "Video",
    icon: "PlayCircleMajor",
    id: "video",
    type: "video",
    visible: true,
    sortable: true,
    showAddButton: false,
    properties: {
      title: "Video",
      titleSize: "medium",
      src: "",
      poster: "/empty-banner.svg",
      hideOnMobile: false,
    },
  },
];

export const enlaceOptions = [
  {
    value: "/",
    label: "Inicio",
    media: <Icon source={HomeMajor} />,
  },
  {
    value: "colecciones",
    label: "Colecciones",
    media: <Icon source={CollectionsMajor} />,
  },
  {
    value: "articulos",
    label: "Artículos",
    media: <Icon source={ProductsMajor} />,
  },
  {
    value: "/contact",
    label: "Contacto",
    media: <Icon source={EmailMajor} />,
  },
];

export const ORDERS_PROPS = {
  orders: {
    url: "/admin/pedidos",
    emptyHeading: "Tus pedidos aparecerán aquí",
    emptyContent: "Crear pedido",
    emptyBody:
      "Aquí podrás preparar pedidos, cobrar pagos y hacer seguimiento del progreso de los pedidos.",
    choicesStatus: PEDIDO_STATUS,
    placeholder: "Buscar pedido",
    resourceName: { singular: "pedido", plural: "pedidos" },
    title: "Pedido",
  },
  draft_orders: {
    url: "/admin/pedidos/preliminares",
    emptyHeading: "Los pedidos preliminares aparecerán aquí",
    emptyContent: "Crear preliminar",
    emptyBody:
      " Los pedidos preliminares son documentos que pueden guardarse, editarse, agregar más productos y finalmente convertir en un pedido.",
    choicesStatus: PRELIMINARES_STATUS,
    placeholder: "Buscar pedidos preliminares",
    resourceName: { singular: "preliminar", plural: "preliminares" },
    title: "Preliminar",
  },
};

export const RoleUserTabs = [
  {
    id: "Activo",
    content: "Activo",
    panelID: "Activo",
  },
  {
    id: "Invitado",
    content: "Invitado",
    panelID: "Invitado",
  },
];
