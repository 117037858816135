import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Card,
  Checkbox,
  ChoiceList,
  EmptyState,
  Filters,
  ResourceList,
  TextStyle,
} from "@shopify/polaris";
import { AntPagination, SkeletonTable } from "..";
import CustomBadge from "../CustomBadge";
import { changePage } from "../../actions/DescuentosActions";
import { Table } from "antd";
import "./DescuentosList.css";
import { useDispatch } from "react-redux";

export default function DescuentoList(props) {
  const dispatch = useDispatch();
  const router = useHistory();
  const [selectedItems, setSelectedItems] = useState([]);
  const handleQueryValueRemove = props.handleQueryValueRemove;
  const handleEstatusDescuentoRemove = props.handleEstatusDescuentoRemove;

  const handleFiltersClearAll = useCallback(() => {
    handleQueryValueRemove();
    handleEstatusDescuentoRemove();
  }, [handleQueryValueRemove, handleEstatusDescuentoRemove]);

  function handleSelect(selected, val) {
    if (selected) {
      setSelectedItems((state) => [...new Set(state.concat(val))]);
    } else {
      setSelectedItems(selectedItems.filter((sel) => val !== sel));
    }
  }

  function handleSelectAll(values = []) {
    setSelectedItems(values);
  }

  function validateUrl(tipo, id) {
    switch (tipo) {
      case 1:
        return `/admin/descuentos/by-cliente/${id}`;

      case 2:
        return `/admin/descuentos/by-producto/${id}`;

      case 3:
        return `/admin/descuentos/by-promocion/${id}`;

      case 4:
        return `/admin/descuentos/by-volumen/${id}`;

      case 5:
        return `/admin/descuentos/by-maximo/${id}`;

      default:
        return "";
    }
  }

  let appliedFilters = [];
  if (!isEmpty(props.estatusDescuento)) {
    const key = "estatusDescuento";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, props.estatusDescuento),
      onRemove: props.handleEstatusDescuentoRemove,
    });
  }

  const emptyStateMarkup =
    props.descuentos.length === 0 && !props.isLoading ? (
      <EmptyState
        heading="Personalizar la compra al mayoreo con descuentos."
        action={{
          content: "Agregar descuento",
          onAction: props.toggleModal,
        }}
        image="/discounts.png"
      >
        <p>
          Establece políticas de descuentos específicos para tus clientes de
          mayoreo.
        </p>
      </EmptyState>
    ) : undefined;

  const filters = [
    {
      key: "estatusDescuento",
      label: "Estado",
      filter: (
        <ChoiceList
          title="Estado"
          titleHidden
          choices={[
            { label: "Activo", value: "Activo" },
            { label: "Vencido", value: "Vencido" },
            { label: "Programado", value: "Programado" },
          ]}
          selected={props.estatusDescuento || []}
          onChange={props.handleEstatusDescuentoChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "orderSelected",
      label: "Ordenar",
      filter: (
        <div className="flex flex-col whitespace-nowrap">
          <ChoiceList
            title="Ordenar"
            titleHidden
            choices={[
              { label: "Título", value: "titulo" },
              { label: "Fecha de creación", value: "createdDate" },
              { label: "Fecha inicio", value: "fechaInicio" },
              { label: "Fecha fin", value: "fechaFin" },
              { label: "Estado", value: "estatus" },
              { label: "Uso", value: "usado" },
            ]}
            selected={props.orderSelected || []}
            onChange={props.handleOrderSelectedChange}
          />
          <div className="flex w-full border border-t-1 border-b-0 border-r-0 border-l-0 border-gray-400">
            <ChoiceList
              title="Ordenar"
              titleHidden
              choices={[
                { label: "Ascendente", value: "1" },
                { label: "Descendente", value: "-1" },
              ]}
              selected={props.orden}
              onChange={props.handleOrdenChange}
            />
          </div>
        </div>
      ),
      shortcut: true,
    },
  ];

  return (
    <Card>
      <Card.Section>
        <Filters
          queryPlaceholder="Buscar descuento"
          queryValue={props.queryValue}
          onQueryChange={props.handleFiltersQueryChange}
          onQueryClear={props.handleQueryValueRemove}
          onClearAll={handleFiltersClearAll}
          filters={filters}
          appliedFilters={appliedFilters}
        />
      </Card.Section>
      {props.isLoading ? (
        <SkeletonTable hasCheckbox headingsCount={5} rowsCount={5} />
      ) : (
        <div>
          <div className="selectable-items">
            <ResourceList
              totalItemsCount={props.pagination.total}
              resourceName={{ singular: "descuento", plural: "descuentos" }}
              items={props.descuentos}
              selectable
              selectedItems={selectedItems}
              onSelectionChange={handleSelectAll}
              renderItem={() => null}
            />
          </div>
          <div className="ant-table-styles">
            <Table
              onRow={(record) => ({
                onClick: () =>
                  handleSelect(
                    !Boolean(selectedItems.indexOf(record.id) >= 0),
                    record.id
                  ),
              })}
              rowKey="id"
              rowClassName="whitespace-nowrap"
              dataSource={props.descuentos}
              scroll={{ scrollToFirstRowOnChange: true, x: "100%" }}
              pagination={false}
              locale={{ emptyText: emptyStateMarkup }}
              columns={[
                {
                  title: "Título",
                  dataIndex: "titulo",
                  render: (titulo, record) => (
                    <div className="flex flex-row items-center">
                      <Checkbox
                        value={record.id}
                        checked={selectedItems.indexOf(record.id) >= 0}
                        onChange={(val) => handleSelect(val, record.id)}
                      />
                      <div
                        className="text-link"
                        onClick={() =>
                          router.push(validateUrl(record.tipo.tipo, record.id))
                        }
                      >
                        <TextStyle variation="strong">{titulo}</TextStyle>
                      </div>
                    </div>
                  ),
                },
                {
                  title: "Fecha de inicio",
                  dataIndex: "fechaInicio",
                  render: (fechaInicio) =>
                    fechaInicio ? (
                      <TextStyle>
                        {moment(fechaInicio).format("DD MMM")} a las{" "}
                        {moment(fechaInicio).format("HH:mm")}
                      </TextStyle>
                    ) : (
                      "--"
                    ),
                },
                {
                  title: "Fecha de fin",
                  dataIndex: "fechaFin",
                  render: (fechaFin) =>
                    fechaFin ? (
                      <TextStyle>
                        {moment(fechaFin).format("DD MMM")} a las{" "}
                        {moment(fechaFin).format("HH:mm")}
                      </TextStyle>
                    ) : (
                      "--"
                    ),
                },
                {
                  title: "Estatus",
                  dataIndex: "estatus",
                  render: (estatus) => <CustomBadge status={estatus.nombre} />,
                },
                {
                  title: "Usado",
                  dataIndex: "usado",
                  render: (usado) => (
                    <TextStyle>
                      {Number(usado || 0).toLocaleString("en", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </TextStyle>
                  ),
                },
              ]}
            />
          </div>
        </div>
      )}
      <div className="flex justify-center py-6 border pagination px-10">
        <AntPagination
          total={props.pagination.total}
          current={props.pagination.currentPage + 1}
          pageSize={props.pagination.sizePerPage}
          onChange={(page) => {
            dispatch(changePage(page - 1));
          }}
        />
      </div>
    </Card>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "estatusDescuento":
        return value.map((val) => `${val}`).join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
}

DescuentoList.propTypes = {
  queryValue: PropTypes.string,
  handleFiltersQueryChange: PropTypes.func,
  handleQueryValueRemove: PropTypes.func,
  estatusDescuento: PropTypes.array,
  handleEstatusDescuentoChange: PropTypes.func,
  handleEstatusDescuentoRemove: PropTypes.func,
  orderSelected: PropTypes.array,
  handleOrderSelectedChange: PropTypes.func,
  orden: PropTypes.array,
  handleOrdenChange: PropTypes.func,
  descuentos: PropTypes.array,
  isLoading: PropTypes.bool,
  pagination: PropTypes.object,
  toggleModal: PropTypes.func,
};

DescuentoList.defaultProps = {
  queryValue: "",
  handleFiltersQueryChange: () => {},
  handleQueryValueRemove: () => {},
  estatusDescuento: [],
  handleEstatusDescuentoChange: () => {},
  handleEstatusDescuentoRemove: () => {},
  orderSelected: [],
  handleOrderSelectedChange: () => {},
  orden: [],
  handleOrdenChange: PropTypes.func,
  descuentos: [],
  isLoading: false,
  pagination: {
    currentPage: 0,
    sizePerPage: 20,
    total: 0,
  },
  toggleModal: () => {},
};
