import React from 'react';
import { useSelector } from "react-redux";
import { getPaginationDescuentos, getDescuentos, isLoadingDescuentos } from "../../reducers";

export default function DescuentosContainer({children}) {
   const isLoading = useSelector(isLoadingDescuentos);
   const descuentos = useSelector(getDescuentos).map((i) => ({ ...i, id:i._id }));
   const pagination = useSelector(getPaginationDescuentos);

   return (<>{children({isLoading, descuentos, pagination})}</>
   )
}
