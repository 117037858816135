import React, { useState, useEffect } from 'react';
import { Badge, Card, Checkbox, FormLayout, Layout, Page, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, SkeletonThumbnail, Stack } from '@shopify/polaris';
import { Layer } from '../../components';
import ApiServiceComercios from '../../services/ApiServiceComercios';
import { useDispatch } from 'react-redux';
import { toggleToast } from '../../actions/InteractiveActions';

export default function PreferenciasScreen({ forbidden }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [preferencias, setPreferencias] = useState({
    impuesto: {
      cobrarEnvio: false,
      mostrarPrecioConImpuesto: false,
    },
    preferencia: {
      mostrarExistenciaProducto: false,
      startOnOnlineStore:false,
    },
  });

  useEffect(() => {
    ApiServiceComercios.obtenerPreferenciasComercio()
      .then(({ impuesto, preferencia }) => {
        setPreferencias({ impuesto, preferencia });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  function onChangeStateImpuesto(value, key) {
    setIsEditing(true);
    ApiServiceComercios.actualizarImpuestoComercio({
      ...preferencias.impuesto,
      [key]: value,
    })
      .then(({ ok, message }) => {
        if (ok) {
          dispatch(toggleToast({ message }));
          setPreferencias((prevState) => ({
            ...prevState,
            impuesto: {
              ...prevState?.impuesto,
              [key]: value,
            },
          }));
        }
      })
      .finally((err) => {
        setIsEditing(false);
      });
  }

  function onChangeStatePreferencia(value, key) {
    setIsEditing(true);
    ApiServiceComercios.actualizarPreferenciaComercio({
      ...preferencias.preferencia,
      [key]: value,
    })
      .then(({ ok, message }) => {
        if (ok) {
          dispatch(toggleToast({ message }));
          setPreferencias((s) => ({
            ...s,
            preferencia: { [key]: value },
          }));
        }
      })
      .finally((err) => {
        setIsEditing(false);
      });
  }

  if (isLoading) {
    return (
      <Layer title="Envío y entrega" forbidden={forbidden}>
        <SkeletonPage
          breadcrumbs
          title={<SkeletonDisplayText size="medium" />}
          primaryAction={<SkeletonDisplayText size="medium" />}
        >
          <Layout>
            <Layout.AnnotatedSection
              title={<SkeletonDisplayText size="medium" />}
              description={<SkeletonBodyText lines={2} />}
            >
              {Array.from(Array(1).keys()).map((item) => {
                return (
                  <div key={item} className="mb-8">
                    <Card sectioned>
                      <Stack>
                        <SkeletonThumbnail size="small" />
                        <div style={{ minWidth: 300 }}>
                          <SkeletonBodyText lines={2} />
                        </div>
                      </Stack>
                    </Card>
                  </div>
                );
              })}
            </Layout.AnnotatedSection>
          </Layout>
        </SkeletonPage>
      </Layer>
    );
  }
  const titleMenuData = (
    <div>
      Acceso a tu tienda <Badge status="info">Nuevo</Badge>
    </div>
  )
  return (
    <Layer title="Preferencias" forbidden={forbidden}>
      <Page title="Preferencias" separator>
        <Layout>
          {/* 
               <Layout.AnnotatedSection
                  title="Protección con contraseña"
                  description="Habilita la contraseña para restringir el acceso a tu tienda en línea."
               >
                  <Card sectioned>
                     <FormLayout>
                        <Checkbox
                           label="Habilitar contraseña"
                        // checked={preferencias.preferencia.habilitar}
                        />
                        <TextField
                           label="Contraseña"
                        />
                        <TextField
                           label="Mensaje para tus clientes"
                        />
                     </FormLayout>
                  </Card>
               </Layout.AnnotatedSection>
   */}
          <Layout.AnnotatedSection 
          title={titleMenuData}
          description="Habilita el acceso a tu tienda en línea."
          >
          <Card sectioned>
            <FormLayout>
            <Checkbox
                  label="Habilitar tienda en línea"
                  helpText="Si la tienda en línea no está activada, los clientes ingresarán a su panel de usuario automáticamente."
                  disabled={isEditing}
                  onChange={(value) =>
                    onChangeStatePreferencia(value, "startOnOnlineStore")
                  }
                  checked={preferencias.preferencia.startOnOnlineStore}
                />
            </FormLayout>
          </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title="Precios y existencias"
            description="Administra la forma de calcular y de mostrar los impuestos y existencias en tu tienda."
          >
            <Card sectioned>
              <FormLayout>
                <Checkbox
                  label="Mostrar los precios con los impuestos incluidos"
                  helpText="Los impuestos están incluidos en el precio que ve el cliente"
                  disabled={isEditing}
                  onChange={(value) =>
                    onChangeStateImpuesto(value, "mostrarPrecioConImpuesto")
                  }
                  checked={preferencias.impuesto.mostrarPrecioConImpuesto}
                />
                <Checkbox
                  label="Cobrar impuestos en tarifas de envío"
                  helpText="Incluye las tarifas de envío en el cálculo de impuestos"
                  disabled={isEditing}
                  onChange={(value) =>
                    onChangeStateImpuesto(value, "cobrarEnvio")
                  }
                  checked={preferencias.impuesto.cobrarEnvio}
                />
                <Checkbox
                  label="Mostrar las existencias disponibles de los productos"
                  helpText="Muestra las unidades disponibles de los productos, en su lugar mostrará Disponible o Bajo Pedido."
                  disabled={isEditing}
                  onChange={(value) =>
                    onChangeStatePreferencia(value, "mostrarExistenciaProducto")
                  }
                  checked={preferencias.preferencia.mostrarExistenciaProducto}
                />
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </Page>
    </Layer>
  );
}
