import React from 'react';
import {
   Card,
   TextStyle,
   ProgressBar,
   DataTable,
} from "@shopify/polaris";
import _ from 'lodash';
import { NumberFormat } from '../../utils/Formats';


export default function LimitCreditCard({ data }) {

   function validateProgressBar() {
      let total = _.divide((data?.saldo?.actual ?? 0) * (100), data?.limite_credito ?? 0);
      total = isNaN(total) ? 0 : total;
      return total > 100 ? 100 : total
   }
   if (!data.saldo || Object.keys(data.saldo).length === 0) {
      return (
         <div className="my-10">
            <Card sectioned>
               <div className="flex justify-between mb-4">
                  <TextStyle>Límite de crédito</TextStyle>
                  <TextStyle><TextStyle variation="strong">$0.0</TextStyle> / $0.0</TextStyle>
               </div>
               <ProgressBar progress={0} />
            </Card>
         </div>
      )
   }

   const rows = [
      [
         <TextStyle variation="strong">Saldo</TextStyle>,
         `$${Number(data.saldo?.actual || 0).toLocaleString('en', NumberFormat)}`,
         `$${Number(data.saldo?.anticipos || 0).toLocaleString('en', NumberFormat)}`,
         `$${Number(data.saldo?.pordepositar || 0).toLocaleString('en', NumberFormat)}`,
         `$${Number(data.saldo?.poracreditar || 0).toLocaleString('en', NumberFormat)}`,
      ], [
         "",
         "",
         <TextStyle variation="strong">1 a 30 días</TextStyle>,
         <TextStyle variation="strong">31 a 61 días</TextStyle>,
         <TextStyle variation="strong">61 días o más</TextStyle>
      ], [
         <TextStyle variation="strong">Vencido</TextStyle>,
         `$${Number(data.vencido?.actual || 0).toLocaleString('en', NumberFormat)}`,
         `$${Number(data.vencido?.dias1_30 || 0).toLocaleString('en', NumberFormat)}`,
         `$${Number(data.vencido?.dias31_60 || 0).toLocaleString('en', NumberFormat)}`,
         `$${Number(data.vencido?.dias61_mas || 0).toLocaleString('en', NumberFormat)}`,
      ], [
         "",
         `${data.vencido?.prctjactual || 0}%`,
         `${data.vencido?.prctj_dias1_30 || 0}%`,
         `${data.vencido?.prctj_dias31_60 || 0}%`,
         `${data.vencido?.prctj_dias61_mas || 0}%`
      ], [
         <TextStyle variation="strong">Por vencer</TextStyle>,
         `$${Number(data.porvencer?.actual || 0).toLocaleString('en', NumberFormat)}`,
         `$${Number(data.porvencer?.dias1_30 || 0).toLocaleString('en', NumberFormat)}`,
         `$${Number(data.porvencer?.dias31_60 || 0).toLocaleString('en', NumberFormat)}`,
         `$${Number(data.porvencer?.dias61_mas || 0).toLocaleString('en', NumberFormat)}`,
      ], [
         "",
         `${data.porvencer?.prctjactual || 0}%`,
         `${data.porvencer?.prctj_dias1_30 || 0}%`,
         `${data.porvencer?.prctj_dias31_60 || 0}%`,
         `${data.porvencer?.prctj_dias61_mas || 0}%`
      ]
   ]
   return (
      <div className="my-10">
         <Card sectioned>
            <div className="flex justify-between mb-4">
               <TextStyle>Límite de crédito</TextStyle>
               <TextStyle><TextStyle variation="strong">${Number(data.saldo.actual || 0).toLocaleString('en', NumberFormat)}</TextStyle> / ${Number(data.limite_credito || 0).toLocaleString('en', NumberFormat)}</TextStyle>
            </div>
            <ProgressBar progress={validateProgressBar()} />
            <DataTable
               columnContentTypes={['text', 'number', 'number', 'number', 'number']}
               headings={['', 'Actual', 'Anticipos', 'Por depositar', 'Por acreditar']}
               rows={rows}
            />
         </Card>
      </div>
   )
}
