import React from "react";
import { useSelector } from "react-redux";
import {
  getAllFilteredItems,
  getBrands,
  getFilteredItems,
  getOptions,
  getPaginationArticulos,
  getProductTypes,
  getTags,
  getTotalArticlesShoppingCart,
  isLoadingArticulos,
} from "../../reducers";

export default function ArticulosFiltradosContainer({ children }) {
  const isLoading = useSelector(isLoadingArticulos);
  const articulos = useSelector(getFilteredItems).map((item) => {
    return { ...item, id: item._id };
  });
  const totalArticles = useSelector(getAllFilteredItems);
  const pagination = useSelector(getPaginationArticulos);
  const productTypes = useSelector(getProductTypes);
  const brands = useSelector(getBrands);
  const tags = useSelector(getTags);
  const options = useSelector(getOptions);
  const totalShoppingCart = useSelector(getTotalArticlesShoppingCart);

  return (
    <React.Fragment>
      {children({
        isLoading,
        articulos,
        pagination,
        totalArticles,
        productTypes,
        brands,
        tags,
        options,
        totalShoppingCart,
      })}
    </React.Fragment>
  );
}
