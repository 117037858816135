import { useEffect, useState } from "react";
import ApiServiceComercios from "../services/ApiServiceComercios";

const useGetCollectors = () => {
  const [collectors, setCollectors] = useState([]);
  const [isLoadingCollectors, setIsLoading] = useState(false);
  useEffect(() => {
    const getCollectors = () => {
      setIsLoading(true);
      ApiServiceComercios.getCollectors()
        .then(({ collectors }) => {
          //add values to autocomplete list
          const collectorsItems = collectors.map(({ name, _id, ...props }) => ({
            label: name,
            value: _id,
            ...props,
          }));

          setCollectors(collectorsItems);
        })
        .finally(() => setIsLoading(false));
    };
    getCollectors();
  }, []);

  return {collectors, isLoadingCollectors, setCollectors };
};

export default useGetCollectors;
