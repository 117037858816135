import { makeQueryParams } from "../utils/formatData";

export default function onChangeURLSearch({ objSearch, pathname }) {
  const obj = {};
  objSearch.forEach((item) => {
    obj[item] = new URLSearchParams(window.location.search).get(item) || "";
  });

  const updateHistory = (objs, useStringify = true) => {
    for (const key in objs) {
      if (Object.hasOwnProperty.call(objs, key)) {
        const element = objs[key];
        obj[key] = useStringify ? JSON.stringify(element) : element;
      }
    }

    const url = makeQueryParams(obj);

    window.history.pushState({}, "", `${pathname}?${url}`);
    return;
  };

  const getSearch = (key, defaultValue = "", single = false) => {
    let storedKey = defaultValue;
    const resultKey = new URLSearchParams(window.location.search).get(key);
    if (resultKey) {
      storedKey = single ? resultKey : JSON.parse(resultKey);
    }
    return storedKey;
  };

  return { updateHistory, getSearch };
}
