import React, { useCallback } from "react";
import { ChoiceList, Spinner, TextField } from "@shopify/polaris";
import { useHistory } from "react-router";
import { ChoiceDate, Table } from "..";
import { changePage } from "../../actions/CotizacionesActions";
import { COTIZACION_STATUS, CREATED_BY, ORDER_BY } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { getAuthData } from "../../reducers";

export default function CotizacionesList({
  cotizacionesColumns,
  cotizaciones,
  estatusCotizacion,
  handleEstatusCotizacionChange,
  orderSelected,
  handleOrderSelectedChange,
  orden,
  handleOrdenChange,
  createdAt,
  handleCreatedAtChange,
  selectedCreatedAt,
  setSelectedCreatedAt,
  vendorCotizacion,
  shippingMethod,
  creatorSelected,
  vendors,
  envios,
  handleVendorCotizacionChange,
  handleCreatorSelectedChange,
  handleCreatorSelectedRemove,
  handleVendorCotizacionRemove,
  handleShippingMethodChange,
  handleShippingMethodRemove,
  handleOrderSelectedRemove,
  handleQueryValueRemove,
  handleEstatusCotizacionRemove,
  handleDateRemove,
  handleFiltersQueryChange,
  isLoading,
  pagination,
  queryValue,
  handleSelectAll,
  selectedItems,
  taggedWith,
  handleTaggedWithChange,
  handleTaggedWithRemove,
  updateTextContact = () => {},
  inputValueClient = "",
  clientOptions = [],
  clientSelected = [],
  deselectedClients = { current: [] },
  handleClientChange = () => {},
  loadingClients = false,
  allowClientFilter = true,
  handleDateCreatedRemove,
  useTagged = true,
}) {
  const dispatch = useDispatch();
  const router = useHistory();
  let { grant_type } = useSelector(getAuthData);

  const handleFiltersClearAll = useCallback(() => {
    handleOrderSelectedRemove();
    handleQueryValueRemove();
    handleVendorCotizacionRemove();
    handleEstatusCotizacionRemove();
    handleShippingMethodRemove();
    handleCreatorSelectedRemove();
    handleDateRemove();
    handleTaggedWithRemove();
    updateTextContact("");
    handleClientChange([]);
    handleDateCreatedRemove();
  }, [
    handleOrderSelectedRemove,
    handleQueryValueRemove,
    handleVendorCotizacionRemove,
    handleEstatusCotizacionRemove,
    handleShippingMethodRemove,
    handleCreatorSelectedRemove,
    handleDateRemove,
    handleTaggedWithRemove,
    updateTextContact,
    handleClientChange,
    handleDateCreatedRemove,
  ]);

  function navigate(id) {
    router.push(`/cliente/cotizaciones/${id}`);
  }

  let filters = [
    {
      key: "cliente",
      label: "Cliente",
      filter: (
        <>
          <TextField
            clearButton
            onClearButtonClick={() => updateTextContact("")}
            onChange={updateTextContact}
            value={inputValueClient}
            placeholder="Busca un cliente"
            autoComplete="off"
          />
          <div className="my-6">
            <ChoiceList
              title="Cliente"
              titleHidden
              choices={clientOptions}
              selected={clientSelected}
              onChange={handleClientChange}
              allowMultiple
            />
            {loadingClients && (
              <div className="flex justify-center">
                <Spinner size="small" />
              </div>
            )}
          </div>
        </>
      ),
      shortcut: true,
    },
    {
      key: "estatusCotizacion",
      label: "Estatus de cotización",
      filter: (
        <ChoiceList
          title="Estatus de cotización"
          titleHidden
          choices={COTIZACION_STATUS}
          selected={estatusCotizacion || []}
          onChange={handleEstatusCotizacionChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "shippingMethod",
      label: "Vía de embarque",
      filter: (
        <ChoiceList
          title="Vía de embarque"
          titleHidden
          choices={envios.map((envio) => ({
            label: envio.nombre,
            value: envio.nombre,
          }))}
          selected={shippingMethod || []}
          onChange={handleShippingMethodChange}
          allowMultiple
        />
      ),
    },
    {
      key: "createdBy",
      label: "Creado",
      filter: (
        <ChoiceList
          title="Creado"
          titleHidden
          choices={CREATED_BY}
          selected={creatorSelected || []}
          onChange={handleCreatorSelectedChange}
          allowMultiple
        />
      ),
      shortcut: false,
    },
    {
      key: "vendorCotizacion",
      label: "Vendedor",
      filter: (
        <ChoiceList
          title="Vendedor"
          titleHidden
          choices={vendors.map((vendor) => ({
            label: vendor.label,
            value: vendor.value,
          }))}
          selected={vendorCotizacion || []}
          onChange={handleVendorCotizacionChange}
          allowMultiple
        />
      ),
    },
    {
      key: "taggedWith",
      label: "Etiquetado con",
      filter: (
        <TextField
          label="Etiquetado con"
          value={taggedWith}
          onChange={handleTaggedWithChange}
        />
      ),
      shortcut: false,
    },
    {
      key: "orderSelected",
      label: "Ordenar",
      filter: (
        <div className="flex flex-col">
          <ChoiceList
            title="Ordenar"
            titleHidden
            choices={ORDER_BY}
            selected={orderSelected || []}
            onChange={handleOrderSelectedChange}
          />
          <div className="flex w-full border border-t-1 border-b-0 border-r-0 border-l-0 border-gray-400">
            <ChoiceList
              title="Ordenar"
              titleHidden
              choices={[
                { label: "Ascendente", value: "1" },
                { label: "Descendente", value: "-1" },
              ]}
              selected={orden}
              onChange={handleOrdenChange}
            />
          </div>
        </div>
      ),
      shortcut: true,
    },
    {
      shortcut: false,
      key: "dateStatus",
      label: "Fecha de creación",
      filter: (
        <ChoiceDate
          value={createdAt}
          handleChange={handleCreatedAtChange}
          selectedDates={selectedCreatedAt}
          setSelectedDates={setSelectedCreatedAt}
          past
        />
      ),
    },
  ];

  filters =
    grant_type === "AGENT"
      ? filters.filter((filter) => filter.key !== "vendorCotizacion")
      : filters;

  if (!useTagged) {
    filters = filters.filter((filter) => filter.key !== "taggedWith");
  }

  if (!allowClientFilter) {
    filters.shift();
  }

  return (
    <div>
      <Table
        handleSelectAll={handleSelectAll}
        selectedItems={selectedItems}
        columns={cotizacionesColumns}
        dataSource={cotizaciones}
        emptyContent="Crear cotización"
        filters={filters}
        vendors={vendors}
        envios={envios}
        handleFiltersClearAll={handleFiltersClearAll}
        handleFiltersQueryChange={handleFiltersQueryChange}
        handleQueryValueRemove={handleQueryValueRemove}
        isLoading={isLoading}
        navigate={navigate}
        pagination={pagination}
        queryValue={queryValue}
        tableName="cotizaciones"
        type="cotización"
        urlEmptyStateClient="/cliente/cotizaciones/agregar"
        urlEmptyStateShop="/admin/cotizaciones/agregar"
        appliedFiltersData={[
          {
            secondLabel: "Estatus",
            label: "estatusCotizacion",
            state: estatusCotizacion,
            handleRemove: handleEstatusCotizacionRemove,
            willMap: true,
          },
          {
            secondLabel: "Vía de embarque",
            label: "shippingMethod",
            state: shippingMethod,
            handleRemove: handleShippingMethodRemove,
            willMap: true,
          },
          {
            seconlabel: "",
            label: "dateStatus",
            state: createdAt,
            handleRemove: handleDateRemove,
          },
          {
            secondLabel: "Vendedor",
            label: "vendorCotizacion",
            state: vendors
              .filter((i) => vendorCotizacion.some((j) => j === i.value))
              .map((i) => i.label),
            handleRemove: handleVendorCotizacionRemove,
            willMap: true,
          },
          {
            secondLabel: "Cliente",
            label: "cliente",
            state: deselectedClients?.current
              .filter((client) =>
                clientSelected.some((val) => val === client.value)
              )
              .map((client) => client.label),
            handleRemove: () => handleClientChange([]),
            willMap: true,
          },
          {
            secondLabel: "Creado en",
            label: "createdBy",
            state: creatorSelected,
            handleRemove: handleCreatorSelectedRemove,
            willMap: true,
          },
          {
            secondLabel: "tagged",
            label: "taggedWith",
            handleRemove: handleTaggedWithRemove,
            willMap: true,
          },
          {
            secondLabel: "Fecha de creacion",
            label: "dateStatus",
            handleRemove: handleDateCreatedRemove,
            willMap: true,
          },
        ]}
        onChangePage={(page) => dispatch(changePage(page))}
      />
    </div>
  );
}
