import React from "react";
import {
  Card,
  EmptyState,
  Pagination,
  ResourceItem,
  ResourceList,
  Select,
  Stack,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";

import image from "./image.png";
import { getImageVideoUrl } from "../../utils/productValidators";

export const CollectionProducts = ({
  articles = [],
  pagination,
  setPagination,
  isLoading = true,
  valueSort,
  handleChange,
}) => {
  const nextPage = () => {
    setPagination(({ skip, limit, total }) => ({
      limit,
      total,
      skip: skip + limit,
    }));
  };

  const prevPage = () => {
    setPagination(({ skip, limit, total }) => ({
      limit,
      total,
      skip: skip - limit,
    }));
  };

  const emptyState = (
    <EmptyState
      image={image}
      footerContent="No hay productos en esta colección. Agrega o cambia las condiciones para
      agregar productos."
    />
  );

  return (
    <Card title="Productos">
      <Card.Section>
        <Select
          label="Ordenar:"
          labelInline
          value={valueSort}
          options={[
            { label: "Nombre", value: "nombre" },
            { label: "Clave", value: "clave" },
          ]}
          onChange={handleChange}
        />
      </Card.Section>
      <Card.Section>
        <ResourceList
          items={articles}
          showHeader
          totalItemsCount={pagination?.total}
          emptyState={emptyState}
          loading={isLoading}
          renderItem={(item, _, index) => {
            const { _id, nombre, imagen } = item;

            return (
              <ResourceItem id={_id}>
                <a
                  href={`/admin/articulos/${_id}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-black hover:text-black"
                >
                  <Stack alignment="center">
                    <div>{pagination?.skip + (index + 1)}</div>
                    <Thumbnail
                      size="medium"
                      source={
                        getImageVideoUrl(imagen, "image", false) ||
                        "/Default Photo.png"
                      }
                    />
                    <TextStyle>{nombre}</TextStyle>
                  </Stack>
                </a>
              </ResourceItem>
            );
          }}
        />

        <div className="flex justify-center">
          <Pagination
            hasPrevious={pagination?.skip > 0}
            onPrevious={prevPage}
            hasNext={pagination?.skip + pagination?.limit < pagination?.total}
            onNext={nextPage}
          />
        </div>
      </Card.Section>
    </Card>
  );
};
