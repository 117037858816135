import {
  Autocomplete,
  Card,
  Checkbox,
  FormLayout,
  RangeSlider,
  Select,
  SkeletonDisplayText,
  Tag,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import useGetCollections from "../../hooks/useGetCollections";
import useDebounce from "../../services/useDebounce";
import { rickTextPosition } from "../../utils/constants";

export default function OutstandingCollection({
  item,
  handleMainChange = () => {},
  handleDeleteSection = () => {},
  currentPage = "",
}) {
  const inputValueRef = useRef(null);
  const deselectedOptions = useRef([]);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);

  const [collection, setCollection] = useState({
    title: "",
    titleSize: "medium",
    position: "center",
    handle: "",
    productsNumberInDesktop: 10,
    showKey: true,
    showAvailability: true,
    showDisccount: true,
    showUnitButton: true,
    showSeeAllButton: true,
    hideOnMobile: false,
    rowsNumberInMobile: 4,
  });

  const { collections, loading, fetchCollections } = useGetCollections({
    query: item.properties.handle || "",
  });

  const debounceInputValue = useDebounce(inputValue, 900);

  useEffect(() => {
    inputValueRef.current = inputValue;
  });

  useDidMountEffect(() => {
    fetchCollections(inputValueRef.current);
  }, [debounceInputValue]);

  useEffect(() => {
    const optionsToAdd = collections.map((collection) => ({
      label: collection.name,
      value: collection.handle,
    }));
    let merged = _.unionBy(
      [...optionsToAdd, ...deselectedOptions.current],
      "value"
    );
    const filterRegex = new RegExp(inputValueRef.current, "i");

    merged = merged.filter((item) => item.label.match(filterRegex));

    setOptions(merged);
    deselectedOptions.current = [...deselectedOptions.current, ...merged];
  }, [collections]);

  useEffect(() => {
    setCollection((prevState) => ({ ...prevState, ...item.properties }));
  }, [item]);

  const updateText = useCallback(
    (value) => {
      setInputValue(value);

      if (value === "") {
        setOptions(options);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = options.filter((option) =>
        option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [options]
  );

  const handleChange = useCallback(
    (field, value) => {
      handleMainChange(
        "properties",
        { ...collection, [field]: value },
        item.id
      );
      setCollection((prevState) => ({ ...prevState, [field]: value }));
    },
    [collection, handleMainChange, item.id]
  );

  const updateSelection = useCallback(
    (selected) => {
      const selectedOption = deselectedOptions.current.find(
        (opt) => opt.value === selected[0]
      );
      const obj = {
        handle: selectedOption.value,
        title: selectedOption.label,
      };
      handleChange("handle", selected[0], obj);
      setInputValue("");
    },
    [handleChange]
  );

  const onRemoveTag = () => {
    handleChange("handle", "");
  };

  const {
    title,
    titleSize,
    position,
    handle,
    productsNumberInDesktop,
    showKey,
    showAvailability,
    showDisccount,
    showUnitButton,
    showSeeAllButton,
    hideOnMobile,
    rowsNumberInMobile,
  } = collection;

  const tagsMarkup = handle ? (
    <div className="flex mr-4 mb-4" key={`option${handle}`}>
      <Tag onRemove={onRemoveTag}>
        {
          deselectedOptions.current.find(
            (desOption) => desOption.value === handle
          )?.label
        }
      </Tag>
    </div>
  ) : null;

  const textField = (
    <Autocomplete.TextField
      label="Colección"
      onChange={updateText}
      value={inputValue}
      placeholder="Selecciona una colección"
      autoComplete="off"
    />
  );

  return (
    <Card
      primaryFooterAction={
        !["products-page"].includes(currentPage)
          ? {
              content: "Eliminar sección",
              destructive: true,
              outline: true,
              onAction: () => handleDeleteSection(item.id),
            }
          : null
      }
      footerActionAlignment="left"
    >
      <Card.Section>
        <FormLayout>
          <TextStyle variation="strong">Colección destacada</TextStyle>
          <TextField
            label="Título"
            value={title}
            onChange={(val) => handleChange("title", val)}
            placeholder="Colección destacada"
            autoComplete="off"
          />
          <Select
            label="Tamaño del título"
            placeholder="Selecciona un tamaño"
            value={titleSize}
            onChange={(val) => handleChange("titleSize", val)}
            options={[
              {
                label: "Pequeño",
                value: "small",
              },
              {
                label: "Mediano",
                value: "medium",
              },
              {
                label: "Grande",
                value: "large",
              },
            ]}
          />
          <Select
            label="Posición del título"
            placeholder="Selecciona una posición"
            value={position}
            onChange={(value) => handleChange("position", value)}
            options={rickTextPosition}
          />
          <Autocomplete
            options={options}
            selected={handle ? [handle] : []}
            onSelect={updateSelection}
            textField={textField}
            loading={loading}
            willLoadMoreResults
            listTitle="Colecciones sugeridas"
          />
          {loading ? (
            <div className="mt-8">
              <SkeletonDisplayText size="small" />
            </div>
          ) : (
            <div className="flex flex-wrap my-8">{tagsMarkup}</div>
          )}
          <div className="flex flex-row items-end max-items">
            <RangeSlider
              label="Número de productos a mostrar en escritorio"
              output
              min={1}
              max={8}
              step={1}
              value={productsNumberInDesktop}
              onChange={(val) => handleChange("productsNumberInDesktop", val)}
            />
            <div className="ml-6 whitespace-nowrap">
              <TextStyle>{productsNumberInDesktop}</TextStyle>
            </div>
          </div>
          <Checkbox
            label="Mostrar clave"
            checked={showKey}
            onChange={(val) => handleChange("showKey", val)}
          />
          <Checkbox
            label="Mostrar disponibles"
            checked={showAvailability}
            onChange={(val) => handleChange("showAvailability", val)}
          />
          <Checkbox
            label="Mostrar descuento"
            checked={showDisccount}
            onChange={(val) => handleChange("showDisccount", val)}
          />
          <Checkbox
            label="Mostrar el botón de unidades y agregar para compra rápida"
            checked={showUnitButton}
            onChange={(val) => handleChange("showUnitButton", val)}
          />
          <Checkbox
            label={`Habilitar el botón “Ver todos”`}
            checked={showSeeAllButton}
            onChange={(val) => handleChange("showSeeAllButton", val)}
          />
        </FormLayout>
      </Card.Section>
      <Card.Section title="DISEÑO PARA MÓVILES">
        <FormLayout>
          <Checkbox
            label="Ocultar en móviles"
            checked={hideOnMobile}
            onChange={(val) => handleChange("hideOnMobile", val)}
          />
          <div className="flex flex-row items-end max-items">
            <RangeSlider
              label="Número de columnas en la versión para móviles"
              output
              min={1}
              max={4}
              step={1}
              value={rowsNumberInMobile}
              onChange={(val) => handleChange("rowsNumberInMobile", val)}
            />
            <div className="ml-6 whitespace-nowrap">
              <TextStyle>{rowsNumberInMobile}</TextStyle>
            </div>
          </div>
        </FormLayout>
      </Card.Section>
    </Card>
  );
}
