import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Badge, Icon, TopBar } from "@shopify/polaris";
import { NotificationMajor } from "@shopify/polaris-icons";
import { NotifiactionItem } from "./NotifiactionItem";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { toggleToast } from "../../actions/InteractiveActions";

export const SecondaryMenuMarkup = ({
  notifications,
  isSecondaryMenuOpen,
  toggleIsSecondaryMenuOpen,
}) => (
  <TopBar.Menu
    activatorContent={
      <div className="relative flex">
        <div className="notification-icon">
          <Icon color="subdued" source={NotificationMajor} />
        </div>
        {notifications > 0 && (
          <div className="notif-badge absolute top-0">
            <Badge status="critical">{notifications}</Badge>
          </div>
        )}
      </div>
    }
    open={isSecondaryMenuOpen}
    onOpen={toggleIsSecondaryMenuOpen}
  />
);

export default function NavbarNotifications({
  isSecondaryMenuOpen,
  toggleIsSecondaryMenuOpen,
  notifications,
  setNotifications,
  token,
}) {
  const dispatch = useDispatch();

  const getNotifications = useCallback(() => {
    ApiServiceComercios.getNotifications().then(({ notifications }) =>
      setNotifications(notifications)
    );
  }, [setNotifications]);

  const handleDeleteNotification = useCallback(
    async (id) => {
      await ApiServiceComercios.deleteNotificationById(id)
        .then(() =>
          dispatch(toggleToast({ message: "Notificación eliminada" }))
        )
        .catch(() => {
          dispatch(
            toggleToast({
              message: "Ops, ocurrió un error, intente más tarde.",
            })
          );
        });
      getNotifications();
    },
    [getNotifications, dispatch]
  );

  useEffect(() => {
    if (token) {
      getNotifications();
    }
  }, [token, getNotifications]);

  return (
    <>
      {isSecondaryMenuOpen && (
        <>
          <div className="overlay-notif" onClick={toggleIsSecondaryMenuOpen} />
          <div className="modal">
            {notifications && notifications.length > 0 ? (
              notifications?.map(
                ({ _id, description, textButton, title, type, url }) => {
                  return (
                    <React.Fragment key={_id}>
                      <NotifiactionItem
                        id={_id}
                        description={description}
                        linkText={textButton}
                        title={title}
                        type={type}
                        link={url}
                        handleDelete={handleDeleteNotification}
                      />
                    </React.Fragment>
                  );
                }
              )
            ) : (
              <NotifiactionItem
                id="-empty-state"
                type="default"
                title="Notificaciones"
                description="No hay notificaciones. Aquí te informaremos de mejoras y avisos de tu cuenta."
                linkText={""}
                link={""}
                handleDelete={() => {}}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}
