export const NumberFormat = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export const formatTo2Digits = (number = 0, useSign = true) => {
  const formattedNumber = Number(number).toLocaleString("en-US", NumberFormat);

  return useSign ? `$${formattedNumber}` : formattedNumber;
};

export const NormalizeName = function (str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};
