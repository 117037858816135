import { useEffect, useState } from "react";
import ApiServiceComercios from "../services/ApiServiceComercios";

const useGetPhases = (selectedPhases) => {
  const [phases, setPhases] = useState([]);
  const [isLoadingPhases, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    ApiServiceComercios.getPhases()
      .then(({ phases }) => {
        //add values to autocomplete list
        const phasesItems = phases.map(({ name, _id }) => ({
          label: name,
          value: _id,
        }));

        setPhases(phasesItems);
      })
      .finally(() => setIsLoading(false));
  }, [selectedPhases]);

  const createPhase = async (name = "") => {
    return new Promise(async (resolve, reject) => {
      ApiServiceComercios.createPhase({ name })
        .then(({ phase = {} }) => {
          const { name, _id } = phase;
          setPhases((prevState) => [...prevState, { label: name, value: _id }]);
          setIsLoading(false);
          resolve(phase);
        })
        .catch(() => {
          setIsLoading(false);
          reject(null);
        });
    });
  };

  return { phases, isLoadingPhases, setPhases, createPhase };
};

export default useGetPhases;
