import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Icon } from "@shopify/polaris";
import { ArrowRightMinor } from "@shopify/polaris-icons";
import useGetElementSizes from "../../hooks/useGetElementSizes";

export default function CollectionsListStyled({ section, mode }) {
  const router = useHistory();
  const [isMobile, setIsMobile] = useState(false);
  const { properties } = section;
  const { measuredRef, width } = useGetElementSizes();
  const titleSize = {
    small: "24px",
    medium: "40px",
    large: "52px",
  };

  useEffect(() => {
    setIsMobile(width <= 768);
  }, [width]);

  const getStyles = (field) => {
    const styles = {
      container: {
        gridTemplateColumns: `repeat(${
          isMobile ? properties.rowsNumberMobile : properties.rowsNumberDesktop
        }, minmax(0, 1fr))`,
      },
      heading: {
        fontSize: titleSize[properties.titleSize],
        lineHeight: titleSize[properties.titleSize],
      },
    };
    return styles[field];
  };
  return (
    <div
      className="flex w-full"
      style={{ height: isMobile && properties.hideOnMobile ? 1 : "auto" }}
      ref={measuredRef}
    >
      <div
        className="flex flex-col py-10 bg-white w-full"
        style={{
          display: isMobile && properties.hideOnMobile ? "none" : "flex",
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <p className="mb-6" style={{ ...getStyles("heading") }}>
          {section.properties.title}
        </p>

        <div
          className="grid gap-4 collection-container self-center"
          style={{ ...getStyles("container") }}
        >
          {section.subNavigationItems
            .filter(({ visible }) => visible)
            .map((item, key) => (
              <div
                className="collection-section-container flex flex-col cursor-pointer"
                key={key}
                onClick={
                  mode === "view"
                    ? () => router.push(`/collections/${item.handle}`)
                    : () => {}
                }
              >
                <div className="collection-section-image-container relative w-full aspect-square">
                  <img
                    className="object-cover h-full"
                    src={item.src}
                    alt={item.name}
                  />
                </div>
                <div className="flex flex-row">
                  <p className="my-2.5">{item.name}</p>
                  <Icon source={ArrowRightMinor} />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
