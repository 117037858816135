import React, { useEffect, useState } from "react";
import {
  Page,
  Layout,
  Card,
  SkeletonPage,
  SkeletonDisplayText,
  SkeletonBodyText,
  SkeletonThumbnail,
  Stack,
} from "@shopify/polaris";
import { Layer, ConfigButton, HelpFooter } from "../../components";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { useHistory } from "react-router-dom";

export default function SucursalesScreen({ forbidden }) {
  const router = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [sucursales, setSucursales] = useState([]);

  useEffect(() => {
    ApiServiceComercios.obtenerSucursales()
      .then((result) => {
        if (result.ok) {
          setSucursales(result.sucursales);
        }
      })
      .catch((err) => {})
      .finally(() => setIsLoading(false));
    return () => null;
  }, []);

  if (isLoading) {
    return (
      <Layer title="Sucursales" forbidden={forbidden}>
        <SkeletonPage
          breadcrumbs
          title={<SkeletonDisplayText size="medium" />}
          primaryAction={<SkeletonDisplayText size="medium" />}>
          <Layout>
            <Layout.AnnotatedSection
              title={<SkeletonDisplayText size="medium" />}
              description={<SkeletonBodyText lines={2} />}>
              {Array.from(Array(3).keys()).map((item) => {
                return (
                  <div key={item} className="mb-8">
                    <Card sectioned>
                      <Stack>
                        <SkeletonThumbnail size="small" />
                        <div style={{ minWidth: 300 }}>
                          <SkeletonBodyText lines={2} />
                        </div>
                      </Stack>
                    </Card>
                  </div>
                );
              })}
            </Layout.AnnotatedSection>
          </Layout>
        </SkeletonPage>
      </Layer>
    );
  }

  return (
    <Layer title="Sucursales" forbidden={forbidden}>
      <Page
        title="Almacenes"
        breadcrumbs={[
          { content: "Configuración", url: "/admin/configuracion" },
        ]}
        /*
         primaryAction={{
        content: "Agregar almacén",
        url: "/admin/configuracion/sucursales/agregar",
         }}
         */
      >
        <Layout>
          <Layout.AnnotatedSection
            title="Almacenes"
            description="Administra los lugares donde vendes productos, envías pedidos y conservas el inventario.">
            {sucursales.map((sucursal) => {
              return (
                <div key={sucursal?._id} className="mb-8">
                  <Card>
                    <ConfigButton
                      fullWidth
                      onClick={() =>
                        router.push(
                          `/admin/configuracion/sucursales/${sucursal?._id}`
                        )
                      }
                      text={sucursal?.nombre}
                      secondary={`${
                        sucursal?.direccion?.length > 0
                          ? sucursal?.direccion + ","
                          : ""
                      } ${
                        sucursal?.colonia?.length > 0
                          ? sucursal?.colonia + ","
                          : ""
                      } ${
                        sucursal?.ciudad?.length > 0
                          ? sucursal?.ciudad + ","
                          : ""
                      } ${
                        sucursal?.estado?.length > 0
                          ? sucursal?.estado + ","
                          : ""
                      } ${
                        sucursal?.pais?.length > 0 ? sucursal?.pais + "." : ""
                      }`}
                      icon="LocationMajor"
                    />
                  </Card>
                </div>
              );
            })}
          </Layout.AnnotatedSection>
        </Layout>
        <HelpFooter
          title="almacenes"
          url="https://help.b2bgo.mx/configuracion/sucursales"
        />
      </Page>
    </Layer>
  );
}
