import { combineReducers } from "redux";
import { createSelector } from "reselect";
import Comercios, * as fromComercios from "./ComerciosReducer";
import Auth from "./AuthReducer";
import Usuarios, * as fromUsuarios from "./UsuariosReducer";
import Clientes, * as fromClientes from "./ClientesReducer";
import Interactive from "./InteractiveReducer";
import Articulos from "./ArticulosReducer";
import Anuncios, * as fromAnuncios from "./AnunciosReducer";
import Pedidos, * as fromPedidos from "./PedidosReducer";
import Historial, * as fromHistorial from "./SincronizacionReducer";
import Descuentos, * as fromDescuentos from "./DescuentosReducer";
import Facturas, * as fromFacturas from "./FacturasReducer";
import Cotizaciones, * as fromCotizaciones from "./CotizacionesReducer";
import Oportunidades, * as fromOportunidades from "./OpportunitiesReducer";
import Filters, * as fromFilters from "./FiltersReducer";
import Payments, * as fromPayments from "./PaymentReducer";
import ShoppingCart from "./ShoppingCartReducer";
import { makePermissions } from "../auth/ability";
import Preliminares, * as fromPreliminares from "./PreliminaresReducers";

const rootReducer = combineReducers({
  Comercios,
  Auth,
  Usuarios,
  Clientes,
  Interactive,
  Articulos,
  Anuncios,
  Pedidos,
  Preliminares,
  Historial,
  Descuentos,
  Facturas,
  Cotizaciones,
  Oportunidades,
  Filters,
  ShoppingCart,
  Payments,
});

// Auth

export const isAuthenticated = createSelector(
  (state) => state.Auth.loggedIn,
  (loggedIn) => loggedIn
);

export const getAuthData = createSelector(
  (state) => state.Auth.access_token,
  (state) => state.Auth.token_type,
  (state) => state.Auth.grant_type,
  (state) => state.Auth.loggedIn,
  (state) => state.Auth.refreshToken,
  (access_token, token_type, grant_type, loggedIn, refreshToken) => ({
    access_token,
    token_type,
    grant_type,
    loggedIn,
    refreshToken,
  })
);

export const getUserInfo = createSelector(
  (state) => state.Auth.userData,
  (userData) => userData
);

export const getChat = createSelector(
  (state) => state.Auth.chat,
  (chat) => chat
);

export const getNombreComercio = createSelector(
  (state) => state.Auth.nombre_comercio,
  (nombre_comercio) => nombre_comercio
);

export const getZonaHoraria = createSelector(
  (state) => state.Auth.zonaHoraria,
  (zonaHoraria) => zonaHoraria
);

export const getComercioData = createSelector(
  (state) => state.Auth.comercioData,
  (comercioData) => comercioData
);

export const getPermissions = createSelector(
  (state) => state.Auth.userData.role.permissions,
  (permissions) => ({
    permissions: makePermissions(
      permissions && permissions.length > 0 ? permissions : []
    ),
  })
);

// Interactive actions
export const getToast = createSelector(
  (state) => state.Interactive.activeToast,
  (state) => state.Interactive.message,
  (state) => state.Interactive.isLoading,
  (state) => state.Interactive.currentVisitors,
  (activeToast, message, isLoading, currentVisitors) => ({
    activeToast,
    message,
    isLoading,
    currentVisitors,
  })
);

export const getCurrentVisitors = createSelector(
  (state) => state.Interactive.currentVisitors,
  (currentVisitors) => currentVisitors
);

export const getIsLoadingProgressBar = createSelector(
  (state) => state.Interactive.isLoading,
  (isLoading) => isLoading
);

// Usuarios
export const isLoadingUsuarios = (state) =>
  fromUsuarios.isLoadingUsuarios(state.Usuarios);

export const getUsuarios = createSelector(
  (state) => state.Usuarios.allIds,
  (state) => state.Usuarios.byId,
  (allIds, byId) => allIds.map((id) => byId[id])
);

// Comercios
export const isLoadingComercios = (state) =>
  fromComercios.isLoadingComercios(state.Comercios);

export const getComercios = createSelector(
  (state) => state.Comercios.allIds,
  (state) => state.Comercios.byId,
  (allIds, byId) => allIds.map((id) => byId[id])
);

export const getPaginationComercios = createSelector(
  (state) => state.Comercios.currentPage,
  (state) => state.Comercios.sizePerPage,
  (state) => state.Comercios.total,
  (currentPage, sizePerPage, total) => ({ currentPage, sizePerPage, total })
);

// Clientes
export const isLoadingClientes = (state) =>
  fromClientes.isLoadingClientes(state.Clientes);

export const getClientes = createSelector(
  (state) => state.Clientes.allIds,
  (state) => state.Clientes.byId,
  (allIds, byId) => allIds.map((id) => byId[id])
);

export const getPaginationClientes = createSelector(
  (state) => state.Clientes.currentPage,
  (state) => state.Clientes.sizePerPage,
  (state) => state.Clientes.total,
  (currentPage, sizePerPage, total) => ({ currentPage, sizePerPage, total })
);

// Articulos
export const isLoadingArticulos = createSelector(
  (state) => state.Articulos.isLoading,
  (isLoading) => isLoading
);

export const getArticulos = createSelector(
  (state) => state.Articulos.allIds,
  (state) => state.Articulos.byId,
  (allIds, byId) => allIds.map((id) => byId[id])
);

export const getPaginationArticulos = createSelector(
  (state) => state.Articulos.currentPage,
  (state) => state.Articulos.sizePerPage,
  (state) => state.Articulos.total,
  (currentPage, sizePerPage, total) => ({ currentPage, sizePerPage, total })
);

export const getArticleTags = createSelector(
  (state) => state.Articulos.tags,
  (tags) => ({ tags })
);

//AriculosByFilter
export const getFilteredItems = createSelector(
  (state) => state.Articulos.allIds,
  (state) => state.Articulos.byId,
  (allIds, byId) => allIds.map((id) => byId[id])
);

export const getAllFilteredItems = createSelector(
  (state) => state.Articulos.total,
  (total) => total
);

export const getProductTypes = createSelector(
  (state) => state.Articulos.productTypes,
  (productTypes) => productTypes
);

export const getBrands = createSelector(
  (state) => state.Articulos.brands,
  (brands) => brands
);

export const getTags = createSelector(
  (state) => state.Articulos.tags,
  (tags) => tags
);

export const getOptions = createSelector(
  (state) => state.Articulos.options,
  (options) => options
);

// filtros

export const isLoadingFilters = (state) =>
  fromFilters.isLoadingFilters(state.Filters);

export const getFiltersPagination = createSelector(
  (state) => state.Filters.currentPage,
  (state) => state.Filters.sizePerPage,
  (state) => state.Filters.total,
  (currentPage, sizePerPage, total) => ({ currentPage, sizePerPage, total })
);

// Anuncios
export const isLoadingAnuncios = (state) =>
  fromAnuncios.isLoadingAnuncios(state.Anuncios);

export const getAnuncios = createSelector(
  (state) => state.Anuncios.allIds,
  (state) => state.Anuncios.byId,
  (allIds, byId) => allIds.map((id) => byId[id])
);

export const getPaginationAnuncios = createSelector(
  (state) => state.Anuncios.currentPage,
  (state) => state.Anuncios.sizePerPage,
  (state) => state.Anuncios.total,
  (currentPage, sizePerPage, total) => ({ currentPage, sizePerPage, total })
);
// Pedidos
export const isLoadingPedidos = (state) =>
  fromPedidos.isLoadingPedidos(state.Pedidos);

export const getPedidos = createSelector(
  (state) => state.Pedidos.allIds,
  (state) => state.Pedidos.byId,
  (allIds, byId) => allIds.map((id) => byId[id])
);

export const getPaginationPedidos = createSelector(
  (state) => state.Pedidos.currentPage,
  (state) => state.Pedidos.sizePerPage,
  (state) => state.Pedidos.total,
  (currentPage, sizePerPage, total) => ({ currentPage, sizePerPage, total })
);

// Preliminares
export const isLoadingPreliminares = (state) =>
  fromPreliminares.isLoadingPreliminares(state.Preliminares);

export const getPreliminares = createSelector(
  (state) => state.Preliminares.allIds,
  (state) => state.Preliminares.byId,
  (allIds, byId) => allIds.map((id) => byId[id])
);

export const getPaginationPreliminares = createSelector(
  (state) => state.Preliminares.currentPage,
  (state) => state.Preliminares.sizePerPage,
  (state) => state.Preliminares.total,
  (currentPage, sizePerPage, total) => ({ currentPage, sizePerPage, total })
);

// Cotizaciones
export const isLoadingCotizaciones = (state) =>
  fromCotizaciones.isLoadingCotizaciones(state.Cotizaciones);

export const getCotizaciones = createSelector(
  (state) => state.Cotizaciones.allIds,
  (state) => state.Cotizaciones.byId,
  (allIds, byId) => allIds.map((id) => byId[id])
);

export const getPaginationCotizaciones = createSelector(
  (state) => state.Cotizaciones.currentPage,
  (state) => state.Cotizaciones.sizePerPage,
  (state) => state.Cotizaciones.total,
  (currentPage, sizePerPage, total) => ({ currentPage, sizePerPage, total })
);

// Payments
export const isLoadingPayments = (state) =>
  fromPayments.isLoadingPayments(state.Payments);

export const getPayments = createSelector(
  (state) => state.Payments.allIds,
  (state) => state.Payments.byId,
  (allIds, byId) => allIds.map((id) => byId[id])
);

export const getPaginationPayments = createSelector(
  (state) => state.Payments.currentPage,
  (state) => state.Payments.sizePerPage,
  (state) => state.Payments.total,
  (currentPage, sizePerPage, total) => ({ currentPage, sizePerPage, total })
);

// Oportunidades
export const isLoadingOpportunities = (state) =>
  fromOportunidades.isLoadingOpportunities(state.Oportunidades);

export const getOpportunities = createSelector(
  (state) => state.Oportunidades.allIds,
  (state) => state.Oportunidades.byId,
  (allIds, byId) => allIds.map((id) => byId[id])
);

export const getPaginationOpportunities = createSelector(
  (state) => state.Oportunidades.currentPage,
  (state) => state.Oportunidades.sizePerPage,
  (state) => state.Oportunidades.total,
  (currentPage, sizePerPage, total) => ({ currentPage, sizePerPage, total })
);

// Historial
export const isLoadingHistorial = (state) =>
  fromHistorial.isLoadingHistorial(state.Historial);

export const getHistorial = createSelector(
  (state) => state.Historial.allIds,
  (state) => state.Historial.byId,
  (allIds, byId) => allIds.map((id) => byId[id])
);

export const getHistorialMetrics = createSelector(
  (state) => state.Historial.successful,
  (state) => state.Historial.failed,
  (state) => state.Historial.lastSync,
  (successful, failed, lastSync) => ({ successful, failed, lastSync })
);

export const getPaginationHistorial = createSelector(
  (state) => state.Historial.currentPage,
  (state) => state.Historial.sizePerPage,
  (state) => state.Historial.total,
  (currentPage, sizePerPage, total) => ({ currentPage, sizePerPage, total })
);

// Descuentos
export const isLoadingDescuentos = (state) =>
  fromDescuentos.isLoadingDescuentos(state.Descuentos);

export const getDescuentos = createSelector(
  (state) => state.Descuentos.allIds,
  (state) => state.Descuentos.byId,
  (allIds, byId) => allIds.map((id) => byId[id])
);

export const getPaginationDescuentos = createSelector(
  (state) => state.Descuentos.currentPage,
  (state) => state.Descuentos.sizePerPage,
  (state) => state.Descuentos.total,
  (currentPage, sizePerPage, total) => ({ currentPage, sizePerPage, total })
);

// Facturas
export const isLoadingFacturas = (state) =>
  fromFacturas.isLoadingFacturas(state.Facturas);

export const getFacturas = createSelector(
  (state) => state.Facturas.allIds,
  (state) => state.Facturas.byId,
  (allIds, byId) => allIds.map((id) => byId[id])
);

export const getBillCurrency = createSelector(
  (state) => state.Facturas.moneda,
  (moneda) => moneda
);

export const getPaginationFacturas = createSelector(
  (state) => state.Facturas.currentPage,
  (state) => state.Facturas.sizePerPage,
  (state) => state.Facturas.total,
  (currentPage, sizePerPage, total) => ({ currentPage, sizePerPage, total })
);

// Shopping Cart

export const getCustomerData = createSelector(
  (state) => state.ShoppingCart.customerData,
  (customerData) => customerData
);

export const getArticlesShoppingCart = createSelector(
  (state) => state.ShoppingCart.articles,
  (state) => state.ShoppingCart.descuentoMaximo,
  (state) => state.ShoppingCart.descuentoVolumen,
  (state) => state.ShoppingCart.descuentoPromocion,
  (state) => state.ShoppingCart.showTax,
  (state) => state.ShoppingCart.discounts,
  (state) => state.ShoppingCart.priceToUse,
  (state) => state.ShoppingCart.isActivePayment,
  (state) => state.ShoppingCart.usePaymentsInOrders,
  (
    articles,
    descuentoMaximo,
    descuentoVolumen,
    descuentoPromocion,
    showTax,
    discounts,
    priceToUse,
    isActivePayment,
    usePaymentsInOrders
  ) => ({
    articles,
    descuentoMaximo,
    descuentoVolumen,
    descuentoPromocion,
    showTax,
    discounts,
    priceToUse,
    isActivePayment,
    usePaymentsInOrders,
  })
);
export const getShippingFees = createSelector(
  (state) => state.ShoppingCart.shippingFees,
  (shippingFees) => shippingFees
);

export const getOrderInfo = createSelector(
  (state) => state.ShoppingCart.limiteCredito,
  (state) => state.ShoppingCart.clienteEnvio,
  (state) => state.ShoppingCart.impuesto,
  (state) => state.ShoppingCart.moneda,
  (limiteCredito, clienteEnvio, impuesto, moneda) => ({
    limiteCredito,
    clienteEnvio,
    impuesto,
    moneda,
  })
);

export const getDiscountsShoppingCart = createSelector(
  (state) => state.ShoppingCart.discounts,
  (state) => state.ShoppingCart.priceToUse,
  (discounts, priceToUse) => ({
    discounts,
    priceToUse,
  })
);

export const getTotalArticlesShoppingCart = createSelector(
  (state) => state.ShoppingCart.articles,
  (articles) =>
    articles.reduce((prev, current) => prev + Number(current.cantidad), 0)
);

export default rootReducer;
