import React, { useEffect, useState } from "react";
import {
  Page,
  Layout,
  Card,
  TextStyle,
  SkeletonPage,
  SkeletonDisplayText,
  SkeletonBodyText,
} from "@shopify/polaris";
import { HelpFooter, Layer } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { obtenerComercioConfigMicrosip } from "../../actions/ComerciosActions";
import { useHistory } from "react-router-dom";
import "./ComercioIntegracionesScreen.css";

export default function ComercioIntegracionesScreen({ forbidden }) {
  const router = useHistory();
  const dispatch = useDispatch();
  const [isEnabled, setIsEnabled] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const nombre = useSelector((state) => state.Auth.nombre_comercio);

  useEffect(() => {
    dispatch(obtenerComercioConfigMicrosip({ isEnabled: true }))
      .then(({ enabled }) => {
        setIsEnabled(enabled);
      })
      .finally(() => setIsLoading(false));
  }, [dispatch]);

  function seeHistoric() {
    router.push(`/admin/historial`, { comercio: { nombre } });
  }

  if (isLoading) {
    return (
      <Layer title="Integraciones" forbidden={forbidden}>
        <SkeletonPage breadcrumbs>
          <Layout>
            <Layout.AnnotatedSection
              description={<SkeletonBodyText lines={2} />}
              title={<SkeletonDisplayText size="small" />}
            >
              <Card>
                <Card.Section>
                  <SkeletonDisplayText size="small" />
                  <div className="my-8" />
                  <SkeletonBodyText lines={2} />
                </Card.Section>
              </Card>
            </Layout.AnnotatedSection>
          </Layout>
        </SkeletonPage>
      </Layer>
    );
  }

  return (
    <Layer title="Integraciones" forbidden={forbidden}>
      <Page
        title="Integraciones"
        breadcrumbs={[
          { content: "Configuración", url: "/admin/configuracion" },
        ]}
        separator
      >
        <Layout>
          <Layout.AnnotatedSection
            title="Sistemas administrativos"
            description="Integra B2Bgo con tu sistema administrativo."
          >
            <Card
              sectioned
              primaryFooterAction={{
                content: `${isEnabled ? "Ver detalles" : "Conectar cuenta"}`,
                url: "/admin/configuracion/integracion/microsip",
              }}
              secondaryFooterActions={[
                { content: "Ver historial", onAction: seeHistoric },
              ]}
            >
              <div style={{ width: "110px" }}>
                <img src="/microsip.png" alt="Microsip logo" />
              </div>
              <div className="my-8">
                <TextStyle>
                  Sistemas computacionales administrativos para empresas
                  familiares, de capital 100% mexicano
                </TextStyle>
              </div>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
        <HelpFooter
          title="integraciones"
          url="https://help.b2bgo.mx/configuracion/integraciones"
        />
      </Page>
    </Layer>
  );
}
