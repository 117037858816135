import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Page, Card, Tabs } from "@shopify/polaris";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchClientesComercio,
  resetPage,
} from "../../actions/ClientesActions";
import ClientesContainer from "../../containers/ClientesContainer";
import { Layer, ComercioClientesList, HelpFooter } from "../../components";
import { TabsComerciosClientesScreen as tabs } from "../../utils/Tabs";
import { getPaginationClientes } from "../../reducers";
import useDebounce from "../../services/useDebounce";
import useUrlSearch from "../../hooks/useUrlSearch";

const objSearch = [
  "query",
  "status",
  "tagged_with",
  "sort",
  "order",
  "tab_selected",
];
export default function ComerciosClientesScreen({ forbidden }) {
  const { pathname } = useLocation();
  const { updateHistory, getSearch } = useUrlSearch({ objSearch, pathname });
  const dispatch = useDispatch();
  const [tabSelected, setTabSelected] = useState(
    Number(getSearch("tab_selected", 0, true))
  );
  const [accountStatus, setAccountStatus] = useState(getSearch("status", []));
  const [queryValue, setQueryValue] = useState(
    decodeURIComponent(getSearch("query", "", true))
  );
  const pagination = useSelector(getPaginationClientes);
  const [taggedWith, setTaggedWith] = useState(
    decodeURIComponent(getSearch("tagged_with", "", true))
  );

  const [sort, setSort] = useState(getSearch("sort", ["nombre"]));
  const [orden, setOrder] = useState(getSearch("order", ["1"]));

  const queryValueRef = useRef(
    decodeURIComponent(getSearch("query", "", true))
  );
  const taggedRef = useRef();
  const debouncedSearchTerm = useDebounce(queryValue, 900);
  const debouncedTaggedTerm = useDebounce(taggedWith, 900);

  useEffect(() => {
    queryValueRef.current = queryValue;
    taggedRef.current = taggedWith;
  });

  useEffect(() => {
    function fetchClientes() {
      dispatch(
        fetchClientesComercio({
          sort: sort[0],
          query: decodeURIComponent(queryValueRef.current),
          orden: orden[0],
          estatus: JSON.stringify([
            ...new Set(
              accountStatus.concat(
                tabSelected === 1 ? [] : [tabs[tabSelected].panelID]
              )
            ),
          ]),
          forList: true,
          taggedWith: decodeURIComponent(taggedRef.current),
        })
      );
    }
    fetchClientes();
  }, [
    dispatch,
    sort,
    orden,
    accountStatus,
    tabSelected,
    debouncedSearchTerm,
    debouncedTaggedTerm,
    pagination.currentPage,
  ]);

  function handleChangeStates(
    value,
    setState,
    field,
    useStringify = true,
    resetTab = false
  ) {
    updateHistory(
      {
        [field]: value,
      },
      useStringify
    );
    resetPagination();
    setState(value);
    if (resetTab) {
      setTabSelected(0);
      updateHistory(
        {
          tab_selected: 0,
        },
        false
      );
    }
  }

  function resetPagination() {
    dispatch(resetPage());
  }

  return (
    <Layer title="Clientes" forbidden={forbidden} scrollToTop>
      <Page title="Clientes" fullWidth>
        <Card>
          <ClientesContainer>
            {({ isLoading, clientes, pagination }) => {
              return (
                <Tabs
                  tabs={tabs}
                  selected={tabSelected}
                  onSelect={(value) =>
                    handleChangeStates(
                      value,
                      setTabSelected,
                      "tab_selected",
                      false
                    )
                  }
                >
                  <ComercioClientesList
                    isLoading={isLoading}
                    clientes={clientes}
                    orden={orden}
                    pagination={pagination}
                    taggedWith={decodeURIComponent(taggedWith)}
                    handleTaggedWithChange={(value) =>
                      handleChangeStates(
                        value,
                        setTaggedWith,
                        "tagged_with",
                        false
                      )
                    }
                    handleTaggedWithRemove={() =>
                      handleChangeStates(
                        "",
                        setTaggedWith,
                        "tagged_with",
                        false
                      )
                    }
                    queryValue={decodeURIComponent(queryValue)}
                    handleFiltersQueryChange={(value) =>
                      handleChangeStates(
                        value ? encodeURIComponent(value) : value,
                        setQueryValue,
                        "query",
                        false
                      )
                    }
                    handleQueryValueRemove={() =>
                      handleChangeStates("", setQueryValue, "query", false)
                    }
                    orderSelected={sort}
                    handleOrdenChange={(value) =>
                      handleChangeStates(value, setOrder, "order")
                    }
                    handleOrderSelectedChange={(value) =>
                      handleChangeStates(value, setSort, "sort")
                    }
                    accountStatus={accountStatus}
                    handleAccountStatusChange={(value) =>
                      handleChangeStates(
                        value,
                        setAccountStatus,
                        "status",
                        true,
                        true
                      )
                    }
                    handleAccountStatusRemove={() =>
                      handleChangeStates(
                        [],
                        setAccountStatus,
                        "status",
                        true,
                        true
                      )
                    }
                  />
                </Tabs>
              );
            }}
          </ClientesContainer>
        </Card>
        <HelpFooter title="clientes" url="https://help.b2bgo.mx/clientes" />
      </Page>
    </Layer>
  );
}
