import React from "react";
import {
  ResourceItem,
  ResourceList,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";
import { NumberFormat } from "../../utils/Formats";
import { getImageVideoUrl } from "../../utils/productValidators";

export default function VariantList({
  items = [],
  sucursalSelected,
  sucursalSelectedName = "",
  onNavigate,
}) {
  function validateExistencia(existencia = []) {
    const existenciaFound =
      sucursalSelected === 0
        ? existencia
        : existencia.filter((i) => i.almacen_id === sucursalSelected);
    const total = existenciaFound.reduce(
      (prev, current) => prev + current.existencia,
      0
    );

    return sucursalSelected === 0
      ? `${total} disponible en ${existenciaFound.length} sucursal/es`
      : `${total} en existencia`;
  }

  function validateTotal() {
    let existencia = items
      .filter((i) => i.variantId)
      .map((i) => i.variantId)
      .map((i) => i.existencia)
      .flat();
    existencia =
      sucursalSelected === 0
        ? existencia
        : existencia.filter((i) => i.almacen_id === sucursalSelected);
    const total = existencia.reduce(
      (prev, current) => prev + current.existencia,
      0
    );

    return total;
  }

  function validateName(options) {
    return options.filter((i) => i).join(" / ");
  }

  return (
    <>
      <ResourceList
        resourceName={{ singular: "variante", plural: "variantes" }}
        items={items}
        renderItem={(item) => {
          const {
            option1 = "",
            option2 = "",
            option3 = "",
            option4 = "",
            option5 = "",
            option6 = "",
            option7 = "",
            option8 = "",
            option9 = "",
            option10 = "",
            variantId = {},
          } = item;
          const {
            imagen = "",
            sku = "",
            precios = [],
            existencia = [],
            _id,
          } = variantId || {};
          const nombre = validateName([
            option1,
            option2,
            option3,
            option4,
            option5,
            option6,
            option7,
            option8,
            option9,
            option10,
          ]);

          const media = (
            <Thumbnail
              source={
                getImageVideoUrl(imagen, "image", false) || "/Default Photo.png"
              }
              alt={nombre}
            />
          );

          return (
            <ResourceItem media={media} onClick={() => onNavigate(_id)}>
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <TextStyle variation="strong">{nombre}</TextStyle>
                  <TextStyle>{sku}</TextStyle>
                </div>
                <div className="flex flex-col items-end">
                  <TextStyle>
                    {" "}
                    {precios[0]?.moneda} $
                    {Number(precios[0]?.precio || 0).toLocaleString(
                      "en",
                      NumberFormat
                    )}
                  </TextStyle>
                  <TextStyle>{validateExistencia(existencia)}</TextStyle>
                </div>
              </div>
            </ResourceItem>
          );
        }}
      />
      <div className="flex justify-between p-8 border-t border-gray-400">
        <TextStyle>
          Inventario total en {sucursalSelectedName.toLowerCase()}
        </TextStyle>
        <TextStyle>{validateTotal()} en existencia</TextStyle>
      </div>
    </>
  );
}
