import {
  Card,
  Checkbox,
  FormLayout,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import ChooseColor from "../ChooseColor";

export default function FooterCard({ item, handleMainChange = () => {} }) {
  const [footer, setFooter] = useState({
    backgroundColor: "#FFFFFF",
    titleColor: "#121212",
    textColor: "#000000",
    title: "Comercio",
    subtitle:
      "Comparte información de contacto, detalles de la tienda y contenido de la marca con sus clientes.",
    showSocialNetworks: true,
    showPaymentMethods: true,
    facebook: "",
    instagram: "",
    tiktok: "",
    youtube: "",
  });

  useEffect(() => {
    setFooter((prevState) => ({ ...prevState, ...item.properties }));
  }, [item]);

  const handleChange = (field, value) => {
    handleMainChange("properties", { ...footer, [field]: value }, item.id);
    setFooter((prevState) => ({ ...prevState, [field]: value }));
  };

  const {
    backgroundColor,
    titleColor,
    textColor,
    title,
    subtitle,
    showSocialNetworks,
    showPaymentMethods,
    facebook,
    instagram,
    tiktok,
    youtube,
  } = footer;
  return (
    <Card>
      <Card.Section>
        <FormLayout>
          <TextStyle variation="strong">Pié de página</TextStyle>
          <TextStyle>Esquema de colores</TextStyle>
          <ChooseColor
            handleColorPick={(value) => {
              handleChange("backgroundColor", value);
            }}
            handleTextChange={(value) => handleChange("backgroundColor", value)}
            colorHex={backgroundColor}
            label="Color de fondo"
          />
          <ChooseColor
            handleColorPick={(value) => {
              handleChange("titleColor", value);
            }}
            handleTextChange={(value) => handleChange("titleColor", value)}
            colorHex={titleColor}
            label="Color de los títulos"
          />
          <ChooseColor
            handleColorPick={(value) => {
              handleChange("textColor", value);
            }}
            handleTextChange={(value) => handleChange("textColor", value)}
            colorHex={textColor}
            label="Color del texto"
          />
          <TextField
            label="Título"
            placeholder="Título"
            value={title}
            onChange={(value) => handleChange("title", value)}
          />
          <TextField
            label="Subtítulo"
            placeholder="Subtítulo"
            value={subtitle}
            onChange={(value) => handleChange("subtitle", value)}
          />
          <Checkbox
            label="Mostrar redes sociales"
            checked={showSocialNetworks}
            onChange={(val) => handleChange("showSocialNetworks", val)}
          />
          <Checkbox
            label="Mostrar métodos de pago"
            checked={showPaymentMethods}
            onChange={(val) => handleChange("showPaymentMethods", val)}
          />
        </FormLayout>
      </Card.Section>
      <Card.Section>
        <FormLayout>
          <TextField
            label="Facebook"
            placeholder=""
            value={facebook}
            onChange={(value) => handleChange("facebook", value)}
          />
          <TextField
            label="Instagram"
            placeholder=""
            value={instagram}
            onChange={(value) => handleChange("instagram", value)}
          />
          <TextField
            label="Tiktok"
            placeholder=""
            value={tiktok}
            onChange={(value) => handleChange("tiktok", value)}
          />
          <TextField
            label="YouTube"
            placeholder=""
            value={youtube}
            onChange={(value) => handleChange("youtube", value)}
          />
        </FormLayout>
      </Card.Section>
    </Card>
  );
}
