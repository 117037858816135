import {
  ActionList,
  Button,
  Card,
  ChoiceList,
  Icon,
  Popover,
  SkeletonBodyText,
  TextStyle,
} from "@shopify/polaris";
import {
  CircleAlertMajor,
  CircleTickOutlineMinor,
} from "@shopify/polaris-icons";
import SkeletonInput from "antd/lib/skeleton/Input";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import useAbility from "../../hooks/useAbility";
import useToggle from "../../hooks/useToggle";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { formatTo2Digits } from "../../utils/Formats";
import ManualPaymentModal from "../ManualPaymentModal/ManualPaymentModal";
import { StripeOrderPayment } from "./components/StripeOrderPayment";

export default function ApproveCard({
  cantidad,
  children,
  envio,
  forCotizacion = false,
  impuesto,
  limiteCredito,
  currency = {
    id: 1,
    nombre: "Moneda nacional",
    claveFiscal: "MXN",
    simbolo: "$",
    tipoCambio: 1,
  },
  selectedShippmentFee = null,
  setIsShowingFees,
  setSelectedShippmentFee,
  shippingFees = [],
  subtotal,
  showLimit,
  total,
  orderDetails = null,
  setIsBlocking = () => {},
  usePayment = false,
  buttonPosition = "justify-end",
  buttonFullWidth = false,
  quantityError = "",
  weight,
  shouldPay = false,
  orderData = null,
  setErrorArticulos = () => {},
  usePaymentsInOrders = false,
}) {
  const isForbiddenPayments = useAbility("read", "Pagos");
  const [activePopover, togglePopover] = useToggle();

  const [finalShippingFees, setFinalShippingFees] = useState([]);
  const [selected, setSelected] = useState([]);

  const [isActivePaymentModal, togglePaymentModal] = useToggle(false);
  const [isActiveManualPaymentModal, toggleManualPaymentModal] =
    useToggle(false);

  const [manualPayments, setManualPayments] = useState([]);
  const [loading, setLoading] = useState(false);

  const availableCredit = Number(
    formatTo2Digits(
      (limiteCredito.limite_credito || 0) -
        (limiteCredito.saldo?.actual || 0) -
        total,
      false
    )
      .replace(".00", "")
      .replace(new RegExp(","), "")
  );

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      ApiServiceComercios.getManualPayments()
        .then(({ manualPayments = [] }) => {
          setManualPayments(manualPayments);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    };
    fetchData();
  }, []);

  const totalAmountToPay = formatTo2Digits(availableCredit, false)
    .replace("-", "")
    .replace(new RegExp(","), "");

  const handleChange = useCallback(() => {
    setIsBlocking(false);
    if (isActivePaymentModal) {
      window.location.reload();
    } else {
      togglePaymentModal();
    }
  }, [setIsBlocking, togglePaymentModal, isActivePaymentModal]);

  useEffect(() => {
    if (shippingFees.length > 0 && subtotal > 0) {
      let finalPrices = shippingFees.filter((item) => {
        return item.maxPrice === 0
          ? subtotal >= item.minPrice
          : _.inRange(subtotal, item.minPrice, item.maxPrice);
      });

      if (finalPrices.length === 1) {
        setSelectedShippmentFee(finalPrices[0]._id);
        setSelected([finalPrices[0]._id]);
      } else if (finalPrices.length === 0) {
        setSelectedShippmentFee(null);
        setSelected([]);
      } else if (!finalPrices.some((fp) => fp._id === selectedShippmentFee)) {
        setSelectedShippmentFee(null);
        setSelected([]);
      }
      const dataToAdd = finalPrices.map(
        ({ price, feeTitle, _id }, shipment) => ({
          ...shipment,
          label: `Envío ${
            feeTitle.length ? feeTitle : "de"
          } + ${formatTo2Digits(price)}`,
          value: _id,
        })
      );

      setFinalShippingFees(dataToAdd);
      setIsShowingFees(dataToAdd.length > 1);
    }
  }, [
    shippingFees,
    subtotal,
    setSelectedShippmentFee,
    selectedShippmentFee,
    setIsShowingFees,
  ]);

  const handleChangeShippingFree = useCallback(
    (value) => {
      setSelected(value);
      setSelectedShippmentFee(value[0]);
    },
    [setSelectedShippmentFee]
  );

  const validateTitle = () => {
    if (!showLimit) {
      if (forCotizacion) return "Pre aprobado";
      else return "Aprobado";
    }

    return Object.keys(limiteCredito).length === 0
      ? "Asigna un cliente"
      : forCotizacion
      ? "Pre aprobado"
      : limiteCredito.limite_credito - limiteCredito.saldo.actual - total < 0
      ? "Excedido"
      : "Aprobado"
      ? "Aprobado"
      : "Aprobado";
  };

  const validateStyles = () => {
    if (!showLimit) return "border-green-300";
    return Object.keys(limiteCredito).length === 0
      ? "bg-green-50"
      : forCotizacion
      ? "border-green-300"
      : limiteCredito.limite_credito - limiteCredito.saldo.actual - total < 0
      ? "border-yellow-300"
      : "border-green-300";
  };

  const validateIcon = () => {
    if (!showLimit) return CircleTickOutlineMinor;
    return Object.keys(limiteCredito).length === 0
      ? CircleAlertMajor
      : limiteCredito.limite_credito - limiteCredito.saldo.actual - total < 0
      ? CircleAlertMajor
      : CircleTickOutlineMinor;
  };

  const showPaymentButtons =
    (manualPayments.length > 0 || usePayment) && usePaymentsInOrders;

  if (loading) {
    return (
      <Card>
        <Card.Section>
          <div className="flex flex-col gap-4">
            <SkeletonInput active size="default" />
            <SkeletonBodyText lines={4} />
          </div>
        </Card.Section>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Section>
        <div className="flex flex-col">
          <div className="flex items-center">
            <div
              className={`flex rounded-full border-4 mr-6 ${validateStyles()}`}
            >
              <Icon source={validateIcon()} color="subdued" />
            </div>
            <TextStyle variation="strong">{validateTitle()}</TextStyle>
          </div>
          <div className="flex justify-between my-4">
            <TextStyle>Subtotal {cantidad} productos</TextStyle>
            <TextStyle>{formatTo2Digits(subtotal, false)}</TextStyle>
          </div>
          <div>
            {finalShippingFees?.length < 2 || !finalShippingFees ? (
              <div className="flex justify-between my-4">
                <TextStyle>
                  Envío{" "}
                  <TextStyle variation="subdued">
                    {envio?.datos?.nombre ? `(${envio?.datos?.nombre})` : null}
                  </TextStyle>
                </TextStyle>
                <TextStyle>{formatTo2Digits(envio.cantidad, false)}</TextStyle>
              </div>
            ) : (
              <ChoiceList
                title={<div>Envío ({envio?.datos?.nombre})</div>}
                choices={finalShippingFees}
                selected={selected}
                onChange={handleChangeShippingFree}
              />
            )}
          </div>

          <div className="flex justify-between my-4">
            <TextStyle>Impuestos</TextStyle>

            <TextStyle>{formatTo2Digits(impuesto, false)}</TextStyle>
          </div>
          <div className="flex justify-between my-4">
            <TextStyle variation="strong">{`Total (${currency.claveFiscal})`}</TextStyle>

            <TextStyle variation="strong">
              {formatTo2Digits(total, false)}
            </TextStyle>
          </div>
          {currency.claveFiscal === "MXN" ? null : (
            <div className="flex justify-between my-4">
              <TextStyle>Tipo de cambio: {currency.tipoCambio}</TextStyle>
            </div>
          )}
        </div>
        <div className="flex justify-between my-4">
          <TextStyle>Peso del embarque (kgs)</TextStyle>

          <TextStyle>{formatTo2Digits(weight, false)}</TextStyle>
        </div>
      </Card.Section>
      {showLimit && (
        <Card.Section>
          <div className="flex flex-col">
            <div className="flex justify-between my-4">
              <TextStyle>Límite de crédito</TextStyle>
              <TextStyle>
                {formatTo2Digits(limiteCredito.limite_credito, false)}
              </TextStyle>
            </div>
            <div className="flex justify-between my-4">
              <TextStyle>Saldo</TextStyle>
              <TextStyle>
                {formatTo2Digits(limiteCredito.saldo?.actual, false)}
              </TextStyle>
            </div>
            <div className="flex justify-between my-4">
              <TextStyle variation="strong">{`Disponible (${currency.claveFiscal})`}</TextStyle>
              <TextStyle variation="strong">
                {formatTo2Digits(
                  (limiteCredito.limite_credito || 0) -
                    (limiteCredito.saldo?.actual || 0) -
                    total,
                  false
                )}
              </TextStyle>
            </div>
          </div>
        </Card.Section>
      )}
      {children}
      {(availableCredit < 0 || (shouldPay && total > 0)) &&
      showPaymentButtons &&
      !isForbiddenPayments &&
      showLimit ? (
        <Card.Section>
          <div className={`flex w-full ${buttonPosition}`}>
            <Popover
              onClose={togglePopover}
              active={activePopover}
              fullWidth
              activator={
                <Button
                  disclosure="down"
                  primary
                  fullWidth={buttonFullWidth}
                  disabled={quantityError.length}
                  onClick={togglePopover}
                >{`Pagar ${formatTo2Digits(
                  shouldPay ? total : parseFloat(totalAmountToPay)
                )}`}</Button>
              }
            >
              <div className="whitespace-nowrap">
                <ActionList
                  actionRole="menuitem"
                  items={[
                    {
                      content: "Pagar con tarjeta",
                      onAction: handleChange,
                      disabled: !usePayment,
                    },
                    {
                      content: "Pago manual",
                      onAction: toggleManualPaymentModal,
                      disabled:
                        !usePaymentsInOrders || manualPayments.length === 0,
                    },
                  ]}
                />
              </div>
            </Popover>
          </div>
          {isActivePaymentModal && (
            <StripeOrderPayment
              activeModal={isActivePaymentModal}
              totalAmountToPay={shouldPay ? total : totalAmountToPay}
              handleChange={handleChange}
              orderDetails={orderDetails}
            />
          )}
          {isActiveManualPaymentModal && (
            <ManualPaymentModal
              active={isActiveManualPaymentModal}
              onClose={toggleManualPaymentModal}
              amount={Number(shouldPay ? total : totalAmountToPay)}
              origin="orders"
              dataOrder={orderData}
              setIsBlocking={setIsBlocking}
              setErrorArticulos={setErrorArticulos}
            />
          )}
        </Card.Section>
      ) : null}
    </Card>
  );
}

ApproveCard.propTypes = {
  showLimit: PropTypes.bool,
  total: PropTypes.number,
  cantidad: PropTypes.number,
  limiteCredito: PropTypes.object,
  envio: PropTypes.object,
  weight: PropTypes.number,
};
ApproveCard.defaultProps = {
  showLimit: true,
  total: 0,
  cantidad: 0,
  limiteCredito: {},
  envio: {},
  weight: 0,
};
