import { useEffect, useRef } from "react";

/**
 * Custom hook to run functions when a variable is changed,
 * this is current used when user types something but the
 * action is not excecuted at the moment there is a delay
 * after the function is executed.
 * See: https://www.geeksforgeeks.org/debouncing-in-javascript/
 *
 * @param {function} func - Function to be excecuted when deeps change
 * @param {array} deps - Array of dependencies which changes
 */
const useDidMountEffect = (func, deps = []) => {
  const didMount = useRef(false);
  const funcRef = useRef();
  useEffect(() => {
    funcRef.current = func;
  });

  useEffect(() => {
    if (didMount.current) funcRef.current();
    else didMount.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useDidMountEffect;
