import React, { useCallback, useState } from "react";
import { ButtonGroup, Button, TextStyle, Autocomplete } from "@shopify/polaris";
import PropTypes from "prop-types";
import Flag from "react-country-flag";
import CountryPopover from "../CountryPopover";
import "./CountrySelectStyles.css";
import { countryCodes } from "../../utils/CountryCodes";

export default function CountrySelect({
  title,
  showCode,
  children,
  handleSelect,
  selectedFlag,
  toggleActive,
  selectedCountry,
  active,
  disabled = false,
}) {
  return (
    <div className="w-full">
      <div className="mb-1">
        <TextStyle>{title}</TextStyle>
      </div>
      <CountryPopover
        showCode={showCode}
        handleCallback={handleSelect}
        activator={
          <div className="action-group-button">
            <ButtonGroup segmented>
              <Button
                fullWidth={false}
                onClick={toggleActive}
                disabled={disabled}
              >
                <div
                  className={`${showCode && "flex mr-8 items-center w-auto"}`}
                >
                  {showCode && (
                    <p className="px-2 whitespace-pre">
                      {"+" + selectedFlag.phone_code}
                    </p>
                  )}
                  {selectedFlag.code && (
                    <Flag
                      countryCode={selectedFlag.code}
                      style={{ fontSize: "20px" }}
                    />
                  )}
                </div>
              </Button>
              {children ? (
                children
              ) : (
                <Button
                  textAlign="left"
                  fullWidth
                  onClick={toggleActive}
                  disabled={disabled}
                >
                  {selectedCountry}
                </Button>
              )}
            </ButtonGroup>
          </div>
        }
        active={active}
        toggleActive={toggleActive}
      />
    </div>
  );
}

export function CountrySelectTextField({
  value = "México",
  handleChangeText = () => {},
}) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedFlag, setSelectedFlag] = useState(
    countryCodes.some((i) => i.COUNTRY === value)
      ? countryCodes
          .find((i) => i.COUNTRY === value)
          ["ISO CODES"].split(" / ")[0]
      : "MX"
  );

  const deselectedOptions = countryCodes.map((country) => {
    return {
      label: (
        <div className="flex flex-row" key={country["COUNTRY CODE"]}>
          <Flag
            countryCode={country["ISO CODES"].split(" / ")[0]}
            style={{ fontSize: "2em", marginRight: "4px" }}
          />
          <div className="flex">
            <TextStyle>{country["COUNTRY"]}</TextStyle>
          </div>
        </div>
      ),
      value: country["COUNTRY"],
    };
  });

  const updateText = useCallback(
    (value) => {
      handleChangeText(value);
      if (value === "") {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) =>
        option.value.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [deselectedOptions, handleChangeText]
  );

  function updateSelection(selected) {
    const selectedValue = deselectedOptions.find(
      (i) => i.value === selected[0]
    )["value"];
    setSelectedOptions(selected);
    handleChangeText(selectedValue);
    setSelectedFlag(
      countryCodes
        .find((i) => i.COUNTRY === selectedValue)
        ["ISO CODES"].split(" / ")[0]
    );
  }

  const textFieldCity = (
    <Autocomplete.TextField
      onChange={updateText}
      label="País"
      value={value}
      placeholder="Selecciona un país"
      connectedLeft={
        <Button fullWidth={false}>
          <div>
            {selectedFlag && (
              <Flag countryCode={selectedFlag} style={{ fontSize: "20px" }} />
            )}
          </div>
        </Button>
      }
    />
  );

  return (
    <div className="w-full">
      <Autocomplete
        options={options}
        selected={selectedOptions}
        onSelect={updateSelection}
        textField={textFieldCity}
      />
    </div>
  );
}

CountrySelect.propTypes = {
  title: PropTypes.string,
  showCode: PropTypes.bool,
  handleSelect: PropTypes.func,
  selectedFlag: PropTypes.object,
  toggleActive: PropTypes.func,
  selectedCountry: PropTypes.string,
  active: PropTypes.bool,
};

CountrySelect.defaultProps = {
  title: "",
  showCode: false,
  handleSelect: () => {},
  selectedFlag: { code: "MX", phone_code: "52" },
  toggleActive: () => {},
  selectedCountry: "México",
  active: false,
};
