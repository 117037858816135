import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Banner,
  Button,
  Card,
  DropZone,
  FooterHelp,
  Icon,
  Layout,
  Link,
  List,
  Loading,
  Page,
  Select,
  Stack,
  TextField,
  Heading,
  Spinner,
} from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import _ from "lodash";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Layer,
  ArticuloItem,
  BuscarProductoModal,
  PreventTransitionPrompt,
  EditContactDirectionModal,
  DatePicker,
  CreateCollectionAutocomplete,
  ZoomImage,
  ClientSection,
  TagsAutocomplete,
  DeleteModal,
} from "../../components";
import { toggleToast } from "../../actions/InteractiveActions";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import ApiServiceConfig from "../../services/ApiServiceConfig";
import { clearArticulos } from "../../actions/ArticulosActions";
import useDebounce from "../../services/useDebounce";
import "./AgregarOportunidadScreen.css";
import { VendorAutocomplete } from "../../components/common/Index";
import useGetPhases from "../../hooks/useGetPhases";
import useGetOrigins from "../../hooks/useGetOrigins";
import {
  PERCENTAGES,
  validFileTypes,
  validImageTypes,
} from "../../utils/constants";
import DropzoneFiles from "../../components/DropzoneFiles";
import useGetCurrencies from "../../hooks/useGetCurrencies";
import { scrollToTop } from "../../utils/windowActions";
import useToggle from "../../hooks/useToggle";
import { getAuthData, getUserInfo } from "../../reducers";
import { getDiscounts } from "../../utils/orderValidations";

export default function AgregarOportunidadScreen({ forbidden }) {
  const router = useHistory();
  const dispatch = useDispatch();
  const params = useLocation().state;
  const selectArticlesRef = useRef(null);
  const handleModalChangeRef = useRef(null);
  const usuario = useSelector(getUserInfo);
  let { grant_type } = useSelector(getAuthData);

  const [isBlocking, setIsBlocking] = useState(false);

  const [isCreating, setIsCreating] = useState(false);
  const [isModalActive, setIsModalActive] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [articleCount, setArticleCount] = useState([]);
  const [initialSearch, setInitialSearch] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [articlesError, setArticlesError] = useState({
    message: "",
    articulos: [],
  });
  const [isOpenModal, handleDirectionModal] = useToggle(false);
  const [isOpenChangeCustomerModal, toggleChangeCustomerModal] =
    useToggle(false);
  const [isEditingDirection, setIsEditingDirection] = useState(false);
  const [openFileDialog, setOpenFileDialog] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [clientDB, setClient] = useState(null);
  const [totals, setTotals] = useState([]);
  const [byGeneralSales, setByGeneralSales] = useState(null);
  const [byVolume, setByVolume] = useState(null);
  const [byMaximum, setByMaximum] = useState(null);
  const [editDirectionName, setEditDirectionName] = useState("envío");

  const [amount, setAmount] = useState("0");
  const [selectedDates, setSelectedDates] = useState(new Date());
  const [typeSelected, setTypeSelected] = useState("Cliente");
  const [phaseSelected, setPhaseSelected] = useState("");
  const [originSelected, setOriginSelected] = useState("");
  const [probabilitySelected, setProbabilitySelected] = useState("0");
  const [description, setDescription] = useState("");
  const { phases, isLoadingPhases, createPhase } = useGetPhases();
  const { origins, isLoadingOrigins, createOrigin } = useGetOrigins();
  const [productDiscounts, setProductDiscounts] = useState([]);
  const [priceToUseId, setPriceToUseId] = useState(null);
  const { currencies } = useGetCurrencies();

  const [selectedVendors, setSelectedVendors] = useState([]);
  const [vendorInputValue, setVendorInputValue] = useState("");
  const [quantityError, setQuantityError] = useState([]);

  const [tagsSelected, setTags] = useState([]);
  const [blobFile, setBlobFile] = useState([]);
  const [isAddingFiles, setIsAddingFiles] = useState(false);

  const handleChangeAmount = useCallback((value) => {
    setAmount(value);
    setIsBlocking(true);
  }, []);
  const handleChangeType = useCallback((value) => {
    setTypeSelected(value);
    setIsBlocking(true);
  }, []);
  const handleChangePhase = useCallback((value) => {
    setPhaseSelected(value);
    setIsBlocking(true);
  }, []);
  const handleChangeOrigin = useCallback((value) => {
    setOriginSelected(value);
    setIsBlocking(true);
  }, []);
  const handleChangeProbability = useCallback((value) => {
    setProbabilitySelected(value);
    setIsBlocking(true);
  }, []);
  const handleChangeDescription = useCallback((newValue) => {
    setDescription(newValue);
    setIsBlocking(true);
  }, []);

  const toggleOpenFileDialog = useCallback(() => {
    setOpenFileDialog((openFileDialog) => !openFileDialog);
  }, []);

  const updateText = useCallback((value) => {
    setInputValue(value);
  }, []);

  const debouncedSearchTerm2 = useDebounce(inputValue, 900);

  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) => {
      const formData = new FormData();

      if (acceptedFiles.length > 0) {
        setIsAddingFiles(true);
        acceptedFiles.forEach((file) => {
          formData.append("files", file);
        });

        ApiServiceConfig.uploadFile(formData)
          .then(({ files = [] }) => {
            setBlobFile((state) => [...state, ...files]);
          })
          .finally(() => setIsAddingFiles(false));
      }
    },
    []
  );

  useEffect(() => {
    window.onbeforeunload = (e) => e;
    return () => (window.onbeforeunload = null);
  }, []);

  useEffect(() => {
    selectArticlesRef.current = selectArticulos;
    handleModalChangeRef.current = handleModalChange;
  });

  useEffect(() => {
    handleModalChangeRef.current();
  }, [debouncedSearchTerm2]);

  useEffect(() => {
    if (grant_type === "AGENT") {
      setSelectedVendors([usuario.vendor._id]);
      setVendorInputValue(usuario.vendor._id);
    }
    dispatch(clearArticulos());
    setInitialSearch(false);

    if (params) {
      if (params.articles && params.articles.length > 0) {
        const art = params.articles;
        const artIds = art.map((i) => i._id);
        ApiServiceComercios.obtenerArticulos({
          spectIds: artIds,
          sort: "nombre",
          query: "",
          existencia: -1,
          orden: 1,
          toCreateOrder: true,
          validateAvailability: false,
        })
          .then(({ articulos }) => {
            selectArticlesRef.current(
              params.articles.map((article) => ({
                impuestos: articulos.find((j) => j._id === article._id)
                  ?.impuestos,
                id: article._id,
                cantidad: article.cantidad,
              })),
              false
            );
          })
          .catch(() => {
            selectArticlesRef.current(
              params.articles.map((i) => ({
                ...i,
                id: i._id,
                cantidad: i.cantidad,
              })),
              false
            );
          })
          .finally(() => {
            params.clientId && updateSelection([params.clientId]);
          });
      }
      setIsLoading(true);
      params.amount && setAmount(params.amount);
      params.closingDate && setSelectedDates(params.closingDate);
      params.typeSelected && setTypeSelected(params.typeSelected);
      params.phaseSelected && setPhaseSelected(params.phaseSelected);
      params.originSelected && setOriginSelected(params.originSelected);
      params.probability && setProbabilitySelected(params.probability);
      params.description && setDescription(params.description);
      params.files && setBlobFile(params.files);
      params.tags && setTags(params.tags);
      params.vendor && setVendorInputValue(params.vendor);
    }
  }, [params, dispatch, grant_type, usuario.vendor]);

  function selectArticulos(items, block = true) {
    block && setIsBlocking(true);
    let merged = _.unionBy(selectedArticles, items, "_id");
    merged = merged.filter((i) => i.precios.length > 0);
    items = items.filter((i) => i.precios.length > 0);
    dispatch(clearArticulos());
    setSelectedArticles(merged);
    setArticleCount((state) => [
      ...new Set(
        state.concat(
          items.map((item) => ({
            id: item._id,
            precio: item.precios[0]?.precio || 0,
            cantidad: item.cantidad ? item.cantidad : "1",
            total:
              (item.cantidad ? item.cantidad : 1) *
              (item.precios[0]?.precio || 1),
          }))
        )
      ),
    ]);
  }

  async function onAddPhase(name) {
    const newPhase = await createPhase(name).catch((error) => error);
    if (newPhase) {
      setPhaseSelected([newPhase._id]);
    }
  }

  async function onAddOrigin(name) {
    const newOrigin = await createOrigin(name).catch((error) => error);
    if (newOrigin) {
      setOriginSelected([newOrigin._id]);
    }
  }

  function selectTags(values) {
    setTags(values);
  }

  function handleChangeDocuments(values = []) {
    setBlobFile(values);
  }

  function updateSelection(selected) {
    setIsLoading(true);
    ApiServiceComercios.obtenerPedidoDatos(selected[0])
      .then(({ ok, cliente, descuentos }) => {
        if (ok) {
          // Valores por default
          setClient(cliente);
          setIsBlocking(true);

          const { promos, maximos, volumenes, productsDiscount, priceToUse } =
            getDiscounts(descuentos, cliente);
          setByGeneralSales(promos);
          setByVolume(volumenes);
          setByMaximum(maximos);
          setProductDiscounts(productsDiscount);
          setPriceToUseId(priceToUse);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handleModalChange() {
    if (!initialSearch) {
      setIsModalActive(!isModalActive);
    }
  }

  function onChangeArticleValue(value, id, total) {
    let articles = [...articleCount];
    articles.forEach((obj) => {
      if (obj.id === id) {
        obj.cantidad = value;
      }
    });
    setArticleCount(articles);
    setTotals(totals.map((i) => (i.articuloId === id ? { ...i, total } : i)));
  }

  function onRemoveArticulo(id) {
    let articulosIds = articleCount.filter((item) => item.id !== id);
    let articulos = selectedArticles.filter((item) => item._id !== id);
    setSelectedArticles(articulos);
    setArticleCount(articulosIds);
    setTotals(totals.filter((i) => i.articuloId !== id));
  }

  function handleSaveOpportunity() {
    setIsCreating(true);
    dismissWarning();
    setIsBlocking(false);
    const articles = selectedArticles.map((ar) => {
      return {
        article: ar._id,
        notas: ar.notas || "",
        quantity: Number(
          articleCount.find((art) => art.id === ar._id).cantidad
        ),
      };
    });

    const data = {
      articles,
      clientId: clientDB ? clientDB?._id : "",
      vendorId: vendorInputValue,
      amount: Number(amount),
      closingDate: moment(selectedDates).format(),
      type: typeSelected,
      phase: phaseSelected[0],
      origin: originSelected[0],
      probability: Number(probabilitySelected),
      description: description,
      files: blobFile,
      tags: tagsSelected,
    };

    ApiServiceComercios.createOpportunity(data, clientDB?._id)
      .then(({ message }) => {
        router.push("/admin/oportunidades");
        dispatch(toggleToast({ message }));
      })
      .catch((err) => {
        setIsBlocking(true);
        if (err?.response?.data?.message) {
          setArticlesError({
            message: err.response?.data?.message || "",
            articulos: err.response?.data?.articulos || [],
          });
        }
        scrollToTop();
        setIsCreating(false);
      });
  }

  function dismissWarning() {
    setArticlesError({
      message: "",
      articulos: [],
    });
  }

  function removeClient() {
    setClient(null);
    setByGeneralSales(null);
    setByVolume(null);
    setByMaximum(null);
    setSelectedArticles([]);
    setArticleCount([]);
    setTotals([]);
    setProductDiscounts([]);
    setPriceToUseId(null);
    toggleChangeCustomerModal();
    if (grant_type !== "AGENT") {
      setSelectedVendors([]);
      setVendorInputValue("");
    }
  }

  const willReturnInitialData = useCallback((totalArt, articuloId) => {
    if (totalArt && totalArt > 0) {
      setTotals((prevState) =>
        prevState.find((i) => i.articuloId === articuloId)
          ? prevState.map((i) =>
              i.articuloId === articuloId ? { ...i, total: totalArt } : i
            )
          : [...prevState, { total: totalArt, articuloId }]
      );
    }
  }, []);

  const fileUpload = (
    <DropZone.FileUpload actionHint="o arrastra aquí para subir" />
  );

  function handleSaveDirection(directionEdited) {
    setIsEditingDirection(true);
    handleDirectionModal();
    const direction = clientDB.direcciones.find(
      (i) => i.consignatario_id === directionEdited.consignatario_id
    );
    const dirIndex = clientDB.direcciones.findIndex(
      (i) => i.consignatario_id === directionEdited.consignatario_id
    );
    const isEqual = _.isEqual(directionEdited, direction);
    let willUpdate = false;
    if (!isEqual) {
      willUpdate =
        String(directionEdited.consignatario).trim() ===
        String(direction.consignatario).trim();
    }

    /*
     * Si willUpdate = true, va a actualizar el consignatario,
     * sino va a crear un nuevo registro
     **/
    ApiServiceComercios.actualizaDireccionCliente({
      ...directionEdited,
      willUpdate,
      cliente_id: clientDB._id,
      editDirectionName,
    })
      .then(({ consignatario_id, message }) => {
        let direcciones = clientDB.direcciones;
        if (willUpdate) {
          direcciones[dirIndex] = directionEdited;
        } else {
          direcciones.push({
            ...directionEdited,
            consignatario_id:
              consignatario_id || directionEdited.consignatario_id,
          });
        }
        const direccionesId = {};
        direccionesId[
          editDirectionName === "envío"
            ? "direccionEnvioId"
            : "direccionFacturacionId"
        ] = willUpdate ? directionEdited.consignatario_id : consignatario_id;
        dispatch(toggleToast({ message }));
        setClient((state) => ({ ...state, direcciones, ...direccionesId }));
      })
      .finally(() => {
        setIsEditingDirection(false);
      });
  }

  function addNoteToArticle(note, id) {
    const selectedArt = [...selectedArticles];
    selectedArt.forEach((obj) => {
      if (obj.id === id) {
        obj["notas"] = note;
      }
    });
    setSelectedArticles(selectedArticles);
  }

  function handleOnBlur(value) {
    if (_.isNumber(value)) {
      setAmount("0");
    }
  }

  return (
    <Layer title="Oportunidades" forbidden={forbidden} scrollToTop>
      <Page
        fullWidth
        title="Oportunidades"
        breadcrumbs={[
          { content: "Oportunidades", url: "/admin/oportunidades" },
        ]}
        primaryAction={{
          content: "Agregar",
          onAction: handleSaveOpportunity,
          disabled:
            isCreating ||
            !phaseSelected ||
            !originSelected ||
            quantityError.length,
          loading: isCreating,
        }}
      >
        {isModalActive && clientDB && (
          <BuscarProductoModal
            primaryActionTitle="Agregar a la oportunidad"
            selected={articleCount.map((a) => a.id)}
            selectArticulos={selectArticulos}
            updateText={updateText}
            value={inputValue}
            isActive={isModalActive}
            handleChange={handleModalChange}
            idCliente={clientDB?._id ? clientDB._id : ""}
            mostrarExistenciaProducto={false}
            almacenId={clientDB?.almacen?.id}
            validateAvailability={false}
          />
        )}

        {isOpenChangeCustomerModal && (
          <DeleteModal
            title="Estás seguro de que quieres cambiar el cliente"
            body="Se quitarán los datos seleccionados"
            active={isOpenChangeCustomerModal}
            handleChange={toggleChangeCustomerModal}
            handleDelete={removeClient}
            primariActionContent="Continuar"
          />
        )}

        {isEditingDirection && <Loading />}

        {isOpenModal && (
          <EditContactDirectionModal
            direccionEnvio={
              clientDB.direcciones.find(
                (i) => i.consignatario_id === clientDB.direccionEnvioId
              ) || {}
            }
            direccionFacturacion={
              clientDB.direcciones.find(
                (i) => i.consignatario_id === clientDB.direccionFacturacionId
              ) || {}
            }
            title={editDirectionName}
            direcciones={clientDB?.direcciones}
            isOpen={isOpenModal}
            handleChange={handleDirectionModal}
            handleSave={handleSaveDirection}
          />
        )}

        <PreventTransitionPrompt
          when={isBlocking}
          setIsBlocking={setIsBlocking}
          router={router}
          title="Tienes cambios sin guardar en esta página"
          message="Si abandonas esta página, perderás todos los cambios no guardados. ¿Estás seguro de que quieres salir de esta página?"
        />
        <Layout>
          <Layout.Section>
            {articlesError.message && (
              <div className="mb-8">
                <Banner
                  title={articlesError.message}
                  status="warning"
                  onDismiss={dismissWarning}
                >
                  <List>
                    {articlesError.articulos?.map((i, key) => (
                      <List.Item key={key}>{i.nombre}</List.Item>
                    ))}
                  </List>
                </Banner>
              </div>
            )}
            <div className="selectClientSmall">
              <ClientSection
                clienteDB={clientDB}
                handleDirectionModal={handleDirectionModal}
                isLoading={isLoading}
                removeClient={removeClient}
                setEditDirectionName={setEditDirectionName}
                setIsBlocking={setIsBlocking}
                updateSelection={updateSelection}
                showBilligAddres={false}
              />
            </div>
            <Card title="Detalles de la oportunidad">
              <Card.Section>
                <div className="mb-6">
                  <Stack distribution="fillEvenly" wrap spacing="loose">
                    <TextField
                      label="Importe"
                      type="number"
                      value={amount}
                      onChange={handleChangeAmount}
                      onBlur={() => handleOnBlur(amount)}
                      pattern="^\d*(\.\d{0,2})?$"
                      prefix="$"
                    />
                    <DatePicker
                      date={selectedDates}
                      title="Fecha de cierre"
                      onSelectDate={({ start }) => {
                        setSelectedDates(start);
                      }}
                      requiredIndicator
                      disableBefore={new Date()}
                    />
                  </Stack>
                </div>
                <div className="mb-6">
                  <Stack distribution="fillEvenly" wrap spacing="loose">
                    <Select
                      label="Tipo"
                      options={["Cliente", "Prospecto"]}
                      value={typeSelected}
                      onChange={handleChangeType}
                      requiredIndicator
                    />
                    <CreateCollectionAutocomplete
                      listTitle="Etapas sugeridas"
                      loading={isLoadingPhases}
                      label="Etapa"
                      placeholder="Selecciona una etapa"
                      deselectedOptions={phases}
                      setSelectedOption={handleChangePhase}
                      selectedOptions={phaseSelected}
                      requiredIndicator
                      onAddNew={onAddPhase}
                    />
                  </Stack>
                </div>
                <div className="mb-6">
                  <Stack distribution="fillEvenly" wrap spacing="loose">
                    <CreateCollectionAutocomplete
                      listTitle="Orígines sugeridos"
                      loading={isLoadingOrigins}
                      label="Origen"
                      placeholder="Selecciona un origen"
                      deselectedOptions={origins}
                      setSelectedOption={handleChangeOrigin}
                      selectedOptions={originSelected}
                      requiredIndicator
                      onAddNew={onAddOrigin}
                    />
                    <Select
                      label="Probabilidad"
                      options={PERCENTAGES}
                      onChange={handleChangeProbability}
                      value={probabilitySelected}
                    />
                  </Stack>
                </div>

                <Stack distribution="fillEvenly" wrap>
                  <TextField
                    label="Descripción de la oportunidad"
                    value={description}
                    onChange={handleChangeDescription}
                    maxLength={2500}
                    autoComplete="off"
                    showCharacterCount
                    multiline={2}
                  />
                </Stack>
              </Card.Section>
            </Card>
            {clientDB ? (
              <Card title="Artículos de la oportunidad">
                <Card.Section>
                  <TextField
                    type="search"
                    onChange={updateText}
                    value={inputValue}
                    prefix={<Icon source={SearchMinor} color="inkLighter" />}
                    placeholder="Buscar productos"
                    disabled={!clientDB}
                    connectedRight={
                      <Button disabled={!clientDB} onClick={handleModalChange}>
                        Buscar
                      </Button>
                    }
                  />
                </Card.Section>
                {selectedArticles.length > 0 ? (
                  <Card.Section>
                    {selectedArticles.map((articulo) => {
                      return (
                        <div key={articulo._id}>
                          <ArticuloItem
                            descMaximos={byMaximum}
                            descVolumenAplicada={byVolume}
                            runOnInsertBegin={willReturnInitialData}
                            descPromoAplicada={byGeneralSales}
                            articulo={articulo}
                            quantity={Number(
                              articleCount.find((ar) => ar.id === articulo._id)
                                ?.cantidad || 1
                            )}
                            handleChange={onChangeArticleValue}
                            selectNote={addNoteToArticle}
                            onRemove={onRemoveArticulo}
                            isAdding
                            setQuantityError={(value, id) =>
                              setQuantityError((prevState) =>
                                value
                                  ? prevState.some((errorId) => errorId === id)
                                    ? prevState
                                    : [...prevState, id]
                                  : prevState.filter(
                                      (errorId) => errorId !== id
                                    )
                              )
                            }
                            currencies={currencies}
                            discounts={productDiscounts}
                            priceToUse={priceToUseId}
                          />
                        </div>
                      );
                    })}
                  </Card.Section>
                ) : null}
              </Card>
            ) : null}
            <Card
              title={
                <div className="flex items-center justify-between">
                  <div className="flex">
                    <Heading>Documentos</Heading>
                    <div className="mx-2" />
                    {isAddingFiles && <Spinner size="small" />}
                  </div>
                  {selectedFiles.length > 0 && (
                    <Button
                      plain
                      destructive
                      onClick={() => {
                        handleChangeDocuments(
                          blobFile.filter((i) => !selectedFiles.includes(i.url))
                        );
                        setSelectedFiles([]);
                      }}
                    >
                      Eliminar documentos
                    </Button>
                  )}
                </div>
              }
            >
              <Card.Section>
                <ZoomImage
                  version={2}
                  setSelectedFiles={setSelectedFiles}
                  selectedFiles={selectedFiles}
                  images={blobFile.filter((file) =>
                    validImageTypes.includes(file.mimetype)
                  )}
                >
                  <div className="flex flex-row w-full">
                    <DropZone
                      labelHidden
                      outline
                      onDrop={handleDropZone}
                      type="file"
                      accept={validFileTypes.join(",")}
                      openFileDialog={openFileDialog}
                      onFileDialogClose={toggleOpenFileDialog}
                      disabled={isAddingFiles}
                    >
                      {fileUpload}
                    </DropZone>
                  </div>
                </ZoomImage>
              </Card.Section>
              {blobFile.some(
                (file) => !validImageTypes.includes(file.mimetype)
              ) && (
                <Card.Section>
                  <div className="flex items-center">
                    <Heading>Documentos</Heading>
                    <div className="mx-2" />
                    {isAddingFiles && <Spinner size="small" />}
                  </div>
                  <div className="mt-8">
                    <DropzoneFiles
                      hasDelete
                      onDelete={(f) => {
                        handleChangeDocuments(
                          blobFile.filter((i) => i.url !== f.url)
                        );
                      }}
                      files={blobFile.filter(
                        (file) =>
                          !validImageTypes.includes(file.mimetype) &&
                          !selectedFiles.includes(file.url)
                      )}
                    />
                  </div>
                </Card.Section>
              )}
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <div className="selectClientLarge">
              <ClientSection
                clienteDB={clientDB}
                handleDirectionModal={handleDirectionModal}
                isLoading={isLoading}
                removeClient={toggleChangeCustomerModal}
                setEditDirectionName={setEditDirectionName}
                setIsBlocking={setIsBlocking}
                updateSelection={updateSelection}
                showBilligAddres={false}
              />
            </div>
            <Card title="Vendedor">
              <Card.Section>
                <VendorAutocomplete
                  disabled={grant_type === "AGENT"}
                  selectedVendors={selectedVendors}
                  setVendorInputValue={setVendorInputValue}
                  vendorInputValue={vendorInputValue}
                  placeholder="Buscar vendedor"
                  setSelectedVendors={setSelectedVendors}
                />
              </Card.Section>
            </Card>
            <TagsAutocomplete
              title="Etiquetas"
              subdued
              selected={tagsSelected}
              onSelect={selectTags}
              module="Oportunidades"
            />
          </Layout.Section>
        </Layout>
        <FooterHelp>
          Más información de{" "}
          <Link url="https://help.b2bgo.mx/oportunidades/crear-una-oportunidad">
            oportunidades
          </Link>
        </FooterHelp>
      </Page>
    </Layer>
  );
}
