import { useEffect, useState } from "react";
import {
  Avatar,
  Card,
  EmptyState,
  Heading,
  Layout,
  ResourceItem,
  ResourceList,
  Tabs,
  TextStyle,
} from "@shopify/polaris";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import ApiServiceUsers from "../../services/ApiServiceUsers";
import { RoleUserTabs } from "../../utils/constants";
import useAbility from "../../hooks/useAbility";
import { toggleToast } from "../../actions/InteractiveActions";

export default function UsersByRol({ role, canInviteUsers = true }) {
  const isForbiddenCreate = useAbility("write", "Usuarios y permisos");
  const router = useHistory();
  const dispatch = useDispatch();
  const [tabSelected, setTabSelected] = useState(0);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUsers = () => {
      ApiServiceUsers.getMerchantUsers(role._id)
        .then(({ users = [] }) => {
          setUsers(users);
        })
        .finally(() => setLoading(false));
    };
    getUsers();
  }, [role._id]);

  function reenviarInvitacion(id) {
    ApiServiceUsers.resendInvitation(id).then(({ ok, message }) => {
      if (ok) {
        dispatch(toggleToast({ message }));
      }
    });
  }

  return (
    <Layout.AnnotatedSection
      key={role._id}
      title={role.nombre}
      description={role.descripcion}
    >
      <Card
        secondaryFooterActions={[
          {
            content: `Invitar ${role.nombre}`,
            disabled: isForbiddenCreate || !canInviteUsers,
            onAction: () =>
              router.push(`/admin/configuracion/permisos/agregar/${role._id}`),
          },
        ]}
      >
        <Tabs
          tabs={RoleUserTabs}
          selected={tabSelected}
          onSelect={setTabSelected}
        >
          <ResourceList
            loading={loading}
            items={users.filter(
              (user) =>
                user?.estado?.nombre === RoleUserTabs[tabSelected].panelID
            )}
            emptyState={
              <EmptyState
                heading={`No hay ${role?.nombre.toLowerCase()}`}
                image=""
              >
                <TextStyle>
                  Actualmente no hay {role?.nombre.toLowerCase()}
                </TextStyle>
              </EmptyState>
            }
            renderItem={(item, idx) => {
              const { _id, name, last_name, last_active, email, estado } = item;
              const media = (
                <Avatar
                  size="medium"
                  initials={(name[0] + last_name[0]).toUpperCase()}
                />
              );

              const shortcutActions =
                estado.tipo === 2
                  ? [
                      {
                        content: "Reenviar invitación",
                        onAction: () => reenviarInvitacion(item._id),
                      },
                    ]
                  : null;
              return (
                <ResourceItem
                  id={idx}
                  onClick={() =>
                    router.push(
                      `/admin/configuracion/permisos/${role._id}/${_id}`
                    )
                  }
                  shortcutActions={shortcutActions}
                >
                  <div className="flex justify-between flex-wrap items-center">
                    <div className="flex flex-row items-center">
                      <div className="mr-6">{media}</div>
                      <div className="flex flex-col">
                        <Heading element="h4">
                          {name} {last_name}
                        </Heading>
                        <TextStyle variation="subdued">{email}</TextStyle>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <TextStyle variation="subdued">
                        {moment(last_active).fromNow()}
                      </TextStyle>
                    </div>
                  </div>
                </ResourceItem>
              );
            }}
          />
        </Tabs>
      </Card>
    </Layout.AnnotatedSection>
  );
}
