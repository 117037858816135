import { useState, useRef, useEffect } from "react";
import { Icon } from "@shopify/polaris";
import { PlayCircleMajor } from "@shopify/polaris-icons";
import ReactPlayer from "react-player";
import useToggle from "../../hooks/useToggle";
import useGetElementSizes from "../../hooks/useGetElementSizes";

export default function VideoStyled({ section }) {
  const player = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const { measuredRef, width } = useGetElementSizes();

  const { properties } = section;
  const titleSize = {
    small: "24px",
    medium: "40px",
    large: "52px",
  };

  useEffect(() => {
    setIsMobile(width <= 768);
  }, [width]);

  const [playing, togglePlaying] = useToggle();

  const getStyles = (field) => {
    const styles = {
      container: {
        gridTemplateColumns: `repeat(${properties.rowsNumberDesktop}, minmax(0, 1fr))`,
      },
      heading: {
        fontSize: titleSize[properties.titleSize],
        lineHeight: titleSize[properties.titleSize],
        marginLeft: 20,
        marginRight: 20,
      },
    };
    return styles[field];
  };

  return (
    <div
      className="flex"
      style={{ height: isMobile && properties.hideOnMobile ? 1 : "auto" }}
      ref={measuredRef}
    >
      <div
        className="flex flex-col mb-10 w-full"
        style={{
          display: isMobile && properties.hideOnMobile ? "none" : "flex",
          aspectRatio: "16 / 9",
        }}
      >
        {properties.title && (
          <p className="py-6" style={{ ...getStyles("heading") }}>
            {properties.title}
          </p>
        )}
        {playing ? (
          <ReactPlayer
            ref={player}
            playing={playing}
            width="100%"
            url={properties.src}
            controls
            height={"100%"}
          />
        ) : (
          <div
            style={{ aspectRatio: "16 / 9" }}
            className="relative w-full h-full bg-white"
          >
            <div className="h-full flex justify-center relative">
              <img
                className="object-cover w-full"
                src={properties.poster}
                alt={properties.title}
              />
            </div>
            {properties.src && (
              <button onClick={togglePlaying} className="play-button">
                <Icon source={PlayCircleMajor} />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
