import { getPaginationDescuentos } from "../reducers";
import ApiServiceComercios from "../services/ApiServiceComercios";
import normalizeById from "../utils/NormalizedById";

export const DESCUENTOS_BEGIN = "DESCUENTOS_BEGIN";
export const ADD_DESCUENTOS = "ADD_DESCUENTOS";
export const DESCUENTOS_FAILED = "DESCUENTOS_FAILED";
export const CLEAR_DESCUENTOS = "CLEAR_DESCUENTOS";
export const RESET_PAGE = "RESET_PAGE";
export const CHANGE_PAGE = "CHANGE_PAGE";

export function fetchDescuentos(params) {
  return async (dispatch, getState) => {
    let pagination = getPaginationDescuentos(getState());
    dispatch(clearDescuentos());
    dispatch(descuentosBegin());
    return ApiServiceComercios.obtenerDescuentos({
      ...params,
      limit: pagination.sizePerPage,
      skip: pagination.currentPage * pagination.sizePerPage,
    })
      .then((result) => {
        let data = normalizeById(result.descuentos, "_id");
        data["total"] = result.total;
        data["allIdsDB"] = result.allIds;
        dispatch(addDescuentos(data));
        return result;
      })
      .catch((err) => {
        dispatch(descuentosFailed());
      });
  };
}

export const descuentosBegin = () => ({
  type: DESCUENTOS_BEGIN,
});

export const addDescuentos = (data) => ({
  type: ADD_DESCUENTOS,
  payload: { data },
});

export const descuentosFailed = () => ({
  type: DESCUENTOS_FAILED,
});

export const clearDescuentos = () => ({
  type: CLEAR_DESCUENTOS,
});

export const changePage = (currentPage) => ({
  type: CHANGE_PAGE,
  payload: { data: { currentPage } },
});

export const resetPage = () => ({
  type: RESET_PAGE,
});
