import {
  ADD_DESCUENTOS,
  CLEAR_DESCUENTOS,
  DESCUENTOS_BEGIN,
  DESCUENTOS_FAILED,
  RESET_PAGE,
  CHANGE_PAGE,
} from "../actions/DescuentosActions";

const initialState = {
  byId: {},
  allIds: [],
  allIdsDB: [],
  isLoading: false,
  currentPage: 0,
  sizePerPage: 20,
  total: 0,
};

export default function DescuentosReducer(state = initialState, action) {
  switch (action.type) {
    case DESCUENTOS_BEGIN: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ADD_DESCUENTOS: {
      let allIds = [
        ...new Set(state.allIds.concat(action.payload.data.allIds)),
      ];
      let byId = {
        ...state.byId,
        ...action.payload.data.byId,
      };
      let allIdsDB = action.payload.data.allIdsDB;
      return {
        ...state,
        allIds,
        byId,
        allIdsDB,
        total: action.payload.data.total,
        isLoading: false,
      };
    }
    case CLEAR_DESCUENTOS: {
      return {
        ...state,
        byId: {},
        allIds: [],
        allIdsDB: [],
        isLoading: false,
      };
    }
    case DESCUENTOS_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case CHANGE_PAGE: {
      const currentPage = action.payload.data.currentPage;
      return {
        ...state,
        currentPage,
      };
    }
    case RESET_PAGE: {
      return {
        ...state,
        currentPage: 0,
        total: 0,
      };
    }
    default: {
      return state;
    }
  }
}

export const isLoadingDescuentos = (state) => {
  return state.isLoading;
};
