/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Page,
  Card,
  Layout,
  Icon,
  Button,
  TextField,
  Link,
  FooterHelp,
  ButtonGroup,
  SkeletonDisplayText,
  SkeletonBodyText,
  Banner,
  List,
  Loading,
} from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import _, { sum } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Layer,
  ArticuloItem,
  ApproveCard,
  BuscarProductoModal,
  PreventTransitionPrompt,
  EditContactDirectionModal,
  ClientSection,
  DeleteModal,
} from "../../components";
import { toggleToast } from "../../actions/InteractiveActions";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { clearArticulos } from "../../actions/ArticulosActions";
import useDebounce from "../../services/useDebounce";
import "./AgregarCotizacionComercioScreenStyles.css";
import { VendorAutocomplete } from "../../components/common/Index";
import useGetCurrencies from "../../hooks/useGetCurrencies";
import { scrollToTop } from "../../utils/windowActions";
import useToggle from "../../hooks/useToggle";
import { getAuthData, getUserInfo } from "../../reducers";
import { getDiscounts, getShippingFees } from "../../utils/orderValidations";

export default function AgregarCotizacionComercioScreen({ forbidden }) {
  const router = useHistory();
  const dispatch = useDispatch();
  const params = useLocation().state;
  const selectArticulosRef = useRef(null);
  const handleModalChangeRef = useRef(null);
  const usuario = useSelector(getUserInfo);
  let { grant_type } = useSelector(getAuthData);

  const [isBlocking, setIsBlocking] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isModalActive, setIsModalActive] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [vendorInputValue, setVendorInputValue] = useState("");

  const [selectedArticulos, setSelectedArticulos] = useState([]);
  const [articleCount, setArticleCount] = useState([]);
  const [initialSearch, setInitialSearch] = useState(true);
  const [selectedVendors, setSelectedVendors] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [errorArticulos, setErrorArticulos] = useState({
    message: "",
    articulos: [],
  });
  const [mostrarExistencia, setMostrarExistencia] = useState(false);
  const [isOpenModal, handleDirectionModal] = useToggle(false);
  const [isOpenChangeCustomerModal, toggleChangeCustomerModal] =
    useToggle(false);
  const [note, setNote] = useState("");
  const [isEditingDirection, setIsEditingDirection] = useState(false);

  const [clienteDB, setCliente] = useState(null);
  const [impuestoDB, setImpuestoDB] = useState(null);
  const [totales, setTotales] = useState([]);

  const [porPromosGen, setPorPromosGen] = useState([]);
  const [porVolumen, setPorVolumen] = useState([]);
  const [porMaximo, setPorMaximo] = useState([]);
  const [editDirectionName, setEditDirectionName] = useState("envío");
  const [quantityError, setQuantityError] = useState([]);
  const [shippingFees, setShippingFees] = useState([]);
  const [selectedShippmentFee, setSelectedShippmentFee] = useState();
  const [isShowingFees, setIsShowingFees] = useState(false);
  const [productDiscounts, setProductDiscounts] = useState([]);
  const [priceToUseId, setPriceToUseId] = useState(null);
  const { currencies } = useGetCurrencies();

  const handleChangeNote = useCallback((newValue) => {
    setNote(newValue);
    setIsBlocking(true);
  }, []);
  const updateText = useCallback((value) => setInputValue(value), []);

  const debouncedSearchTerm2 = useDebounce(inputValue, 900);

  useEffect(() => {
    window.onbeforeunload = (e) => e;
    return () => (window.onbeforeunload = null);
  }, []);

  function selectArticulos(items, block = true) {
    block && setIsBlocking(true);
    let merged = _.unionBy(selectedArticulos, items, "_id");
    merged = merged.filter((i) => i.precios.length > 0);
    items = items.filter((i) => i.precios.length > 0);
    dispatch(clearArticulos());
    setSelectedArticulos(merged);
    setArticleCount((state) => [
      ...new Set(
        state.concat(
          items.map((item) => ({
            id: item._id,
            precio: item.precios[0]?.precio || 0,
            cantidad: item.cantidad ? item.cantidad : "1",
            total:
              (item.cantidad ? item.cantidad : 1) *
              (item.precios[0]?.precio || 1),
          }))
        )
      ),
    ]);
  }

  useEffect(() => {
    selectArticulosRef.current = selectArticulos;
    handleModalChangeRef.current = handleModalChange;
  });

  function handleModalChange() {
    if (!initialSearch) {
      setIsModalActive(!isModalActive);
    }
  }

  useEffect(() => {
    handleModalChangeRef.current();
  }, [debouncedSearchTerm2]);

  useEffect(() => {
    if (grant_type === "AGENT") {
      setSelectedVendors([usuario.vendor._id]);
      setVendorInputValue(usuario.vendor._id);
    }
    dispatch(clearArticulos());
    setInitialSearch(false);
    if (params) {
      if (params.vendorId) {
        setVendorInputValue(params.vendorId);
      }
      const art = params.articulos;
      const artIds = art.map((i) => i._id);
      ApiServiceComercios.obtenerArticulos(
        {
          spectIds: artIds,
          sort: "nombre",
          query: "",
          existencia: -1,
          orden: 1,
          toCreateOrder: true,
          idCliente: params.clienteId,
          almacenId: params.almacenId,
          validateAvailability: false,
        },
        true
      )
        .then(({ articulos }) => {
          selectArticulosRef.current(
            art.map((i) => ({
              ...i,
              ...articulos.find((j) => j._id.toString() === i._id.toString()),
              impuestos: articulos.find(
                (j) => j._id.toString() === i._id.toString()
              )?.impuestos,
              id: i._id,
              _id: i._id,
              cantidad: i.cantidad,
              notas: i.notas || "",
            })),
            false
          );
        })
        .catch((err) => {
          selectArticulosRef.current(
            art.map((i) => ({
              ...i,
              id: i._id,
              _id: i._id,
              cantidad: i.cantidad,
              notas: i.notas || "",
            })),
            false
          );
        })
        .finally(() => {
          updateSelection([params.clienteId], false);
        });
      params.notas && setNote(params.notas);
    } else {
      setIsLoading(false);
    }
  }, [params, dispatch, grant_type, usuario.vendor]);

  const updateSelection = useCallback(
    (selected) => {
      setIsLoading(true);
      setIsBlocking(true);
      ApiServiceComercios.obtenerPedidoDatos(selected[0])
        .then(
          ({
            ok,
            cliente,
            descuentos,
            impuesto,
            mostrarExistenciaProducto,
          }) => {
            if (ok) {
              if (cliente?.vendedor?._id) {
                setSelectedVendors([cliente?.vendedor?._id]);
                setVendorInputValue(cliente?.vendedor?._id);
              }
              let currency = cliente.moneda;

              let shippingFees = getShippingFees(
                cliente?.envio?.shippingFees,
                currency
              );

              setCliente(cliente);
              setImpuestoDB(impuesto);
              setMostrarExistencia(mostrarExistenciaProducto);
              setShippingFees(shippingFees);

              const {
                promos,
                maximos,
                volumenes,
                productsDiscount,
                priceToUse,
              } = getDiscounts(descuentos, cliente);
              setPorPromosGen(promos);
              setPorVolumen(volumenes);
              setPorMaximo(maximos);
              setProductDiscounts(productsDiscount);
              setPriceToUseId(priceToUse);
            }
          }
        )
        .finally(() => {
          setIsLoading(false);
        });
    },
    [
      setPorMaximo,
      setIsLoading,
      setCliente,
      setImpuestoDB,
      setMostrarExistencia,
      setShippingFees,
      setPorPromosGen,
      setPorVolumen,
    ]
  );

  function onChangeArticleValue(value, id, total) {
    let articles = [...articleCount];
    articles = articles.map((obj) =>
      obj.id === id ? { ...obj, cantidad: Number(value) } : obj
    );
    setArticleCount(articles);
    setTotales(totales.map((i) => (i.articuloId === id ? { ...i, total } : i)));
  }

  function onRemoveArticulo(id) {
    let articulosIds = articleCount.filter((item) => item.id !== id);
    let articulos = selectedArticulos.filter((item) => item._id !== id);
    setSelectedArticulos(articulos);
    setArticleCount(articulosIds);
    setTotales(totales.filter((i) => i.articuloId !== id));
  }

  function addNoteToArticle(note, id) {
    const selectedArt = [...selectedArticulos];
    selectedArt.forEach((obj) => {
      if (obj.id === id) {
        obj["notas"] = note;
      }
    });
    setSelectedArticulos(selectedArticulos);
  }

  function crearCotizacion() {
    setIsCreating(true);
    dismissWarning();
    setIsBlocking(false);
    let shippingFeeId = null;

    if (selectedShippmentFee) {
      const priceShippingFee = shippingFees.find(
        (i) => i._id === selectedShippmentFee
      );
      shippingFeeId = priceShippingFee._id;
    }

    const articulos = selectedArticulos.map((ar) => {
      return {
        articulo: ar._id,
        notas: ar.notas || "",
        cantidad: Number(
          articleCount.find((art) => art.id === ar._id).cantidad
        ),
      };
    });

    const data = {
      articulos,
      notas: note,
      shippingFeeId,
      vendorId: vendorInputValue,
    };

    ApiServiceComercios.crearCotizacion(data, clienteDB._id)
      .then((result) => {
        if (result.ok) {
          router.push("/admin/cotizaciones");
          dispatch(toggleToast({ message: result.message }));
        } else {
          setIsCreating(false);
        }
      })
      .catch((err) => {
        setIsBlocking(true);
        if (err?.response?.data?.message) {
          setErrorArticulos({
            message: err.response?.data?.message || "",
            articulos: err.response?.data?.articulos || [],
          });
        }
        scrollToTop();
        setIsCreating(false);
      });
  }

  function dismissWarning() {
    setErrorArticulos({
      message: "",
      articulos: [],
    });
  }

  function removeClient() {
    setCliente(null);
    setImpuestoDB(null);
    setPorPromosGen([]);
    setPorVolumen([]);
    setPorMaximo([]);
    setSelectedArticulos([]);
    setArticleCount([]);
    setTotales([]);
    setShippingFees([]);
    setProductDiscounts([]);
    setPriceToUseId(null);
    toggleChangeCustomerModal();
    setNote("");
    if (grant_type !== "AGENT") {
      setSelectedVendors([]);
      setVendorInputValue("");
    }
  }

  const willReturnInitialData = useCallback((totalArt, articuloId) => {
    if (totalArt && totalArt > 0) {
      setTotales((prevState) =>
        prevState.find((i) => i.articuloId === articuloId)
          ? prevState.map((i) =>
              i.articuloId === articuloId ? { ...i, total: totalArt } : i
            )
          : [...prevState, { total: totalArt, articuloId }]
      );
    }
  }, []);

  function handleSubTotalProductos() {
    return totales.reduce((prev, current) => prev + current.total, 0);
  }

  const handleImpuesto = () => {
    // Precio de envío
    const clientePrecioEnvio = clienteDB?.envio?.precio || 0;

    // Impuesto del comercio
    const impuesto = impuestoDB?.impuesto || 16;

    // Valida si se va a cobrar impuesto en el costo de envío
    const cobrarEnvio = impuestoDB?.cobrarEnvio || false;

    // Obtiene los impuestos de los artículos seleccionados
    const totalesWithArticle = totales.map((i) => ({
      ...i,
      impuestos: selectedArticulos.find((j) => j.id === i.articuloId)[
        "impuestos"
      ],
    }));

    // Calcula los impuestos al producto y los agrega a subTotalEachProduct
    let subTotalEachProduct = [];
    totalesWithArticle.forEach((i) => {
      const total = i.total;
      const impuestos = i.impuestos?.filter((j) => j.porcentaje > 0) || [];
      let summa = 0;
      impuestos.forEach((j) => (summa = summa + total * (j.porcentaje / 100)));
      subTotalEachProduct.push(
        Math.round((summa + Number.EPSILON) * 100) / 100
      );
    });

    let precioEnvio = 0;
    if (selectedShippmentFee) {
      const priceShippingFee = shippingFees.find(
        (i) => i._id === selectedShippmentFee
      ).price;
      precioEnvio = cobrarEnvio
        ? priceShippingFee * (impuesto / 100)
        : priceShippingFee - handlePrecioEnvio();
    } else {
      // Valida el impuesto aplicado al precio de enío
      precioEnvio = cobrarEnvio
        ? clientePrecioEnvio * (impuesto / 100)
        : clientePrecioEnvio - handlePrecioEnvio();
    }

    // Regresa la suma de los impuestos de los productos y el precio de envío
    return sum(subTotalEachProduct) + precioEnvio;
  };

  const handlePrecioEnvio = () => {
    // Precio de envío
    const clientePrecioEnvio = clienteDB?.envio?.precio || 0;
    // Impuesto del comercio
    const impuesto = impuestoDB?.impuesto || 16;
    // Valida si se va a cobrar impuesto en el costo de envío
    const cobrarEnvio = impuestoDB?.cobrarEnvio || false;
    // Calcula el costo de envío sin impuesto aplicado

    let costoEnvio = 0;
    if (selectedShippmentFee) {
      const priceShippingFee = shippingFees.find(
        (i) => i._id === selectedShippmentFee
      ).price;

      costoEnvio = cobrarEnvio
        ? priceShippingFee
        : (1 * priceShippingFee) / (1 + impuesto / 100);
    } else {
      costoEnvio = cobrarEnvio
        ? clientePrecioEnvio
        : (1 * clientePrecioEnvio) / (1 + impuesto / 100);
    }

    return costoEnvio;
  };

  const handleTotal = () => {
    const subTotalCotizacion = handleSubTotalProductos();
    const precioEnvio = handlePrecioEnvio();
    const impuesto = handleImpuesto();
    const sumTotal = sum([subTotalCotizacion, precioEnvio, impuesto]);

    return sumTotal;
  };

  function calcularPesoTotal() {
    let pesoTotal = 0;
    for (let i = 0; i < selectedArticulos.length; i++) {
      const articulo = selectedArticulos[i];
      const cantidad =
        articleCount.find((art) => art.id === articulo._id)?.cantidad || 1;
      pesoTotal += (articulo?.peso || 0) * cantidad;
    }
    return pesoTotal;
  }

  const pesoTotal = calcularPesoTotal();

  function handleSaveDirection(directionEdited) {
    setIsEditingDirection(true);
    handleDirectionModal();
    const direction = clienteDB.direcciones.find(
      (i) => i.consignatario_id === directionEdited.consignatario_id
    );
    const dirIndex = clienteDB.direcciones.findIndex(
      (i) => i.consignatario_id === directionEdited.consignatario_id
    );
    const isEqual = _.isEqual(directionEdited, direction);
    let willUpdate = false;
    if (!isEqual) {
      willUpdate =
        String(directionEdited.consignatario).trim() ===
        String(direction.consignatario).trim();
    }

    // Si willUpdate = true, va a actualizar el consignatario, sino va a crear un nuevo registro
    ApiServiceComercios.actualizaDireccionCliente({
      ...directionEdited,
      willUpdate,
      cliente_id: clienteDB._id,
      editDirectionName,
    })
      .then(({ consignatario_id, message }) => {
        let direcciones = clienteDB.direcciones;
        if (willUpdate) {
          direcciones[dirIndex] = directionEdited;
        } else {
          direcciones.push({
            ...directionEdited,
            consignatario_id:
              consignatario_id || directionEdited.consignatario_id,
          });
        }
        const direccionesId = {};
        direccionesId[
          editDirectionName === "envío"
            ? "direccionEnvioId"
            : "direccionFacturacionId"
        ] = willUpdate ? directionEdited.consignatario_id : consignatario_id;
        dispatch(toggleToast({ message }));
        setCliente((state) => ({ ...state, direcciones, ...direccionesId }));
      })
      .finally(() => {
        setIsEditingDirection(false);
      });
  }

  return (
    <Layer title="Crear cotización" forbidden={forbidden} scrollToTop>
      <Page
        fullWidth
        title="Crear cotización"
        breadcrumbs={[{ content: "Cotizaciones", url: "/admin/cotizaciones" }]}
        primaryAction={{
          content: "Crear cotización",
          primary: true,
          onAction: crearCotizacion,
          disabled:
            selectedArticulos.length === 0 ||
            isCreating ||
            !clienteDB ||
            !clienteDB.almacen ||
            !clienteDB.clave ||
            quantityError.length > 0 ||
            (isShowingFees && !selectedShippmentFee),
          loading: isCreating || isEditingDirection,
        }}
      >
        {isModalActive && (
          <BuscarProductoModal
            primaryActionTitle="Agregar a la cotización"
            selected={articleCount.map((a) => a.id)}
            selectArticulos={selectArticulos}
            updateText={updateText}
            value={inputValue}
            isActive={isModalActive}
            handleChange={handleModalChange}
            idCliente={clienteDB._id}
            mostrarExistenciaProducto={mostrarExistencia}
            almacenId={clienteDB.almacen?.id}
            validateAvailability={false}
          />
        )}

        {isOpenChangeCustomerModal && (
          <DeleteModal
            title="Estás seguro de que quieres cambiar el cliente"
            body="Se quitarán los datos seleccionados"
            active={isOpenChangeCustomerModal}
            handleChange={toggleChangeCustomerModal}
            handleDelete={removeClient}
            primariActionContent="Continuar"
          />
        )}

        {isEditingDirection && <Loading />}

        <PreventTransitionPrompt
          when={isBlocking}
          setIsBlocking={setIsBlocking}
          router={router}
          title="Tienes cambios sin guardar en esta página"
          message="Si abandonas esta página, perderás todos los cambios no guardados. ¿Estás seguro de que quieres salir de esta página?"
        />

        {isOpenModal && (
          <EditContactDirectionModal
            direccionEnvio={
              clienteDB.direcciones.find(
                (i) => i.consignatario_id === clienteDB.direccionEnvioId
              ) || {}
            }
            direccionFacturacion={
              clienteDB.direcciones.find(
                (i) => i.consignatario_id === clienteDB.direccionFacturacionId
              ) || {}
            }
            title={editDirectionName}
            direcciones={clienteDB?.direcciones}
            isOpen={isOpenModal}
            handleChange={handleDirectionModal}
            handleSave={handleSaveDirection}
          />
        )}
        <Layout>
          <Layout.Section>
            {errorArticulos.message && (
              <div className="mb-8">
                <Banner
                  title={errorArticulos.message}
                  status="warning"
                  onDismiss={dismissWarning}
                >
                  <List>
                    {errorArticulos.articulos?.map((i, key) => (
                      <List.Item key={key}>{i.nombre}</List.Item>
                    ))}
                  </List>
                </Banner>
              </div>
            )}
            <div className="selectClientSmall">
              <ClientSection
                clienteDB={clienteDB}
                isLoading={isLoading}
                setIsBlocking={setIsBlocking}
                updateSelection={updateSelection}
                removeClient={removeClient}
                setEditDirectionName={setEditDirectionName}
                handleDirectionModal={handleDirectionModal}
              />
            </div>
            <Card title="Detalles de la cotización">
              <Card.Section>
                <TextField
                  type="search"
                  onChange={updateText}
                  value={inputValue}
                  prefix={<Icon source={SearchMinor} color="inkLighter" />}
                  placeholder="Buscar productos"
                  disabled={!clienteDB}
                  connectedRight={
                    <Button disabled={!clienteDB} onClick={handleModalChange}>
                      Buscar
                    </Button>
                  }
                />
              </Card.Section>
              {selectedArticulos.length > 0 ? (
                <Card.Section>
                  {selectedArticulos.map((articulo) => {
                    return (
                      <div key={articulo._id}>
                        <ArticuloItem
                          isQuotation
                          descMaximos={porMaximo}
                          descVolumenAplicada={porVolumen}
                          runOnInsertBegin={willReturnInitialData}
                          descPromoAplicada={porPromosGen}
                          selectNote={addNoteToArticle}
                          articulo={articulo}
                          quantity={Number(
                            articleCount.find((ar) => ar.id === articulo._id)
                              ?.cantidad || 1
                          )}
                          handleChange={onChangeArticleValue}
                          onRemove={onRemoveArticulo}
                          showTax={impuestoDB?.mostrarPrecioConImpuesto}
                          setQuantityError={(value, id) =>
                            setQuantityError((prevState) =>
                              value
                                ? prevState.some((errorId) => errorId === id)
                                  ? prevState
                                  : [...prevState, id]
                                : prevState.filter((errorId) => errorId !== id)
                            )
                          }
                          currencies={currencies}
                          clientCurrency={clienteDB?.moneda}
                          discounts={productDiscounts}
                          priceToUse={priceToUseId}
                        />
                      </div>
                    );
                  })}
                </Card.Section>
              ) : null}
            </Card>
            {isLoading ? (
              <Card>
                <Card.Section>
                  <SkeletonDisplayText size="small" />
                  <div className="my-10" />
                  <SkeletonBodyText lines={2} />
                </Card.Section>
                <Card.Section>
                  <SkeletonBodyText lines={2} />
                </Card.Section>
              </Card>
            ) : (
              <ApproveCard
                cantidad={selectedArticulos.length}
                currency={clienteDB?.moneda}
                envio={{
                  cantidad: handlePrecioEnvio(),
                  datos: clienteDB?.envio,
                }}
                forCotizacion={true}
                impuesto={handleImpuesto()}
                limiteCredito={clienteDB?.limite_credito || {}}
                selectedShippmentFee={selectedShippmentFee}
                setSelectedShippmentFee={setSelectedShippmentFee}
                setIsShowingFees={setIsShowingFees}
                shippingFees={shippingFees}
                showLimit={false}
                subtotal={handleSubTotalProductos()}
                total={handleTotal()}
                weight={pesoTotal}
              />
            )}
          </Layout.Section>
          <Layout.Section secondary>
            <div className="selectClientLarge">
              <ClientSection
                clienteDB={clienteDB}
                isLoading={isLoading}
                setIsBlocking={setIsBlocking}
                updateSelection={updateSelection}
                removeClient={toggleChangeCustomerModal}
                setEditDirectionName={setEditDirectionName}
                handleDirectionModal={handleDirectionModal}
              />
            </div>
            <Card title="Notas">
              <Card.Section>
                <TextField
                  type="text"
                  placeholder="Agrega una nota a la cotización"
                  value={note}
                  onChange={handleChangeNote}
                  showCharacterCount
                  maxLength={150}
                />
              </Card.Section>
            </Card>
            <Card title="Vendedores">
              <Card.Section>
                <VendorAutocomplete
                  disabled={grant_type === "AGENT"}
                  selected={selectedVendors}
                  vendorInputValue={vendorInputValue}
                  setSelectedVendors={setSelectedVendors}
                  setVendorInputValue={(value) => {
                    setVendorInputValue(value);
                    setIsBlocking(true);
                  }}
                  setIsBlocking={setIsBlocking}
                  placeholder="Buscar vendedores"
                />
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
        <div className="flex justify-end mt-8">
          <ButtonGroup>
            <Button
              onClick={() => {
                router.push("/admin/cotizaciones");
              }}
              disabled={isCreating || isEditingDirection}
            >
              Descartar
            </Button>
            <Button
              primary
              onClick={crearCotizacion}
              disabled={
                selectedArticulos.length === 0 ||
                isCreating ||
                !clienteDB ||
                !clienteDB.almacen ||
                !clienteDB.clave ||
                quantityError.length > 0 ||
                (isShowingFees && !selectedShippmentFee)
              }
              loading={isCreating || isEditingDirection}
            >
              Crear cotización
            </Button>
          </ButtonGroup>
        </div>
        <FooterHelp>
          Más información de{" "}
          <Link url="https://help.b2bgo.mx/cotizaciones/crear-una-cotizacion">
            cotizaciones
          </Link>
        </FooterHelp>
      </Page>
    </Layer>
  );
}
