import { useCallback, useEffect, useState } from "react";
import ApiServiceComercios from "../services/ApiServiceComercios";

export default function useGetCollections({ query = "" }) {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchCollections = useCallback((query = "") => {
    setLoading(true);
    ApiServiceComercios.getCollections({ query, limit: 20 })
      .then(({ collections }) => {
        setCollections(collections);
      })
      .catch(() => {
        setCollections([]);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    fetchCollections(query);
  }, [fetchCollections, query]);

  return { collections, loading, fetchCollections };
}
