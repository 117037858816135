import ApiServiceComercios from "../services/ApiServiceComercios";
import NormalizedById from "../utils/NormalizedById";
import { handleLoginComercio } from "../actions/AuthActions";
import { getPaginationComercios } from "../reducers";

export const ADD_COMERCIOS = "ADD_COMERCIOS";
export const ADD_COMERCIOS_BEGIN = "ADD_COMERCIOS_BEGIN";
export const CLEAR_COMERCIOS = "CLEAR_COMERCIOS";
export const ADD_COMERCIOS_FAILED = "ADD_COMERCIOS_FAILED";
export const RESET_PAGE = "RESET_PAGE";
export const CHANGE_PAGE = "CHANGE_PAGE";

export function obtenerComercios(params) {
  return async (dispatch, getState) => {
    let pagination = getPaginationComercios(getState());
    dispatch(clearComercios());
    dispatch(addComerciosBegin());
    return ApiServiceComercios.obtenerComercios({
      ...params,
      limit: pagination.sizePerPage,
      skip: pagination.currentPage * pagination.sizePerPage,
    })
      .then((res) => {
        let data = NormalizedById(res.comercios, "_id");
        data["total"] = res.total;
        dispatch(addComercios(data));
        return res;
      })
      .catch((error) => {
        dispatch(addComerciosFailed());
        throw error;
      });
  };
}

export function obtenerComercio(id) {
  return async (dispatch) => {
    return ApiServiceComercios.obtenerComercio(id)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function obtenerComercioConfig() {
  return async (dispatch) => {
    return ApiServiceComercios.obtenerComercioConfig()
      .then((res) => {
        return res.comercio;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function obtenerComercioConfigMicrosip(params) {
  return async (dispatch) => {
    return ApiServiceComercios.obtenerComercioConfigMicrosip(params)
      .then(({ configuracionComercio, series }) => {
        return { ...configuracionComercio, series };
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function obtenerPaginasComercio() {
  return async (dispatch) => {
    return ApiServiceComercios.obtenerPaginasComercio()
      .then((res) => {
        let data = NormalizedById(res.paginas, "_id");
        return data;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function actualizarPaginasComercio(paginas) {
  return async (dispatch) => {
    return ApiServiceComercios.actualizarPaginasComercio(paginas)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function editarComercioConfigMicrosip(configuracion, id) {
  return async (dispatch) => {
    return ApiServiceComercios.editarComercioConfigMicrosip(configuracion, id)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function obtenerHistorialComercios(id) {
  return async (dispatch) => {
    return ApiServiceComercios.obtenerHistorialComercios(id)
      .then((result) => {
        let data = NormalizedById(result.sincronizaciones, "_id");
        return {
          ok: result.ok,
          sincronizaciones: data.allIds.map((_id) => data.byId[_id]),
        };
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function editarComercio(params) {
  return async (dispatch) => {
    return ApiServiceComercios.editarComercio(params)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function crearComercio(params) {
  return async (dispatch) => {
    return ApiServiceComercios.crearComercio(params)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function invitarClienteComercio(ids) {
  return async (dispatch) => {
    return ApiServiceComercios.invitarClienteComercio(ids)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loginComercio(id) {
  return async (dispatch) => {
    return ApiServiceComercios.loginComercio(id)
      .then((res) => {
        if (res.ok) {
          dispatch(handleLoginComercio(res));
        }
        return res;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export const addComerciosBegin = () => ({
  type: ADD_COMERCIOS_BEGIN,
});

export const addComercios = (data) => ({
  type: ADD_COMERCIOS,
  payload: { data },
});

export const addComerciosFailed = () => ({
  type: ADD_COMERCIOS_FAILED,
});

export const clearComercios = () => ({
  type: CLEAR_COMERCIOS,
});

export const changePage = (currentPage) => ({
  type: CHANGE_PAGE,
  payload: { data: { currentPage } },
});

export const resetPage = () => ({
  type: RESET_PAGE,
});
