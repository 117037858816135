import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Page, Card, Tabs } from "@shopify/polaris";
import { Layer, AnunciosList, HelpFooter } from "../../components";
import AnunciosContainer from "../../containers/AnunciosContainer";
import { useDispatch, useSelector } from "react-redux";
import { fetchAnuncios, resetPage } from "../../actions/AnunciosActions";
import { TabsAnunciosScren as tabs } from "../../utils/Tabs";
import useDebounce from "../../services/useDebounce";
import useAbility from "../../hooks/useAbility";
import useUrlSearch from "../../hooks/useUrlSearch";
import { getPaginationAnuncios } from "../../reducers";

const objSearch = ["query", "tab_selected", "status", "sort", "order"];
export default function AnunciosScreen({ forbidden }) {
  const { pathname } = useLocation();
  const { updateHistory, getSearch } = useUrlSearch({ objSearch, pathname });
  const dispatch = useDispatch();
  const pagination = useSelector(getPaginationAnuncios);
  const queryValueRef = useRef(
    decodeURIComponent(getSearch("query", "", true))
  );
  const [queryValue, setQueryValue] = useState(
    decodeURIComponent(getSearch("query", "", true))
  );
  const isForbiddenCreate = useAbility("write", "Anuncios");
  const [tabSelected, setTabSelected] = useState(
    Number(getSearch("tab_selected", 0))
  );
  const [estatusAnuncio, setEstatusAnuncio] = useState(getSearch("status", []));
  const [sort, setSort] = useState(getSearch("sort", ["fecha_inicio"]));
  const [order, setOrder] = useState(getSearch("order", ["-1"]));

  const debouncedSearchTerm = useDebounce(queryValue, 900);
  useEffect(() => {
    queryValueRef.current = queryValue;
  });

  useEffect(() => {
    dispatch(
      fetchAnuncios({
        sort: sort[0],
        query: decodeURIComponent(queryValueRef.current),
        orden: order[0],
        estatus: JSON.stringify([
          ...new Set(
            estatusAnuncio.concat(
              tabSelected === 0 ? [] : [tabs[tabSelected].panelID]
            )
          ),
        ]),
      })
    );
    return () => null;
  }, [
    dispatch,
    sort,
    order,
    estatusAnuncio,
    tabSelected,
    debouncedSearchTerm,
    pagination.currentPage,
  ]);

  function handleChangeStates(
    value,
    setState,
    key,
    useStringify = true,
    resetTab = false
  ) {
    updateHistory(
      {
        [key]: value,
      },
      useStringify
    );
    resetPagination();
    setState(value);
    if (resetTab) {
      setTabSelected(0);
      updateHistory(
        {
          tab_selected: 0,
        },
        false
      );
    }
  }

  function resetPagination() {
    dispatch(resetPage());
  }

  return (
    <Layer title="Anuncios" forbidden={forbidden} scrollToTop>
      <Page
        title="Anuncios"
        fullWidth
        primaryAction={
          !isForbiddenCreate
            ? { content: "Agregar anuncio", url: "/admin/anuncios/agregar" }
            : undefined
        }
      >
        <Card>
          <AnunciosContainer>
            {({ isLoading, anuncios, pagination }) => {
              return (
                <Tabs
                  tabs={tabs}
                  selected={tabSelected}
                  onSelect={(value) =>
                    handleChangeStates(
                      value,
                      setTabSelected,
                      "tab_selected",
                      false
                    )
                  }
                >
                  <AnunciosList
                    orden={order}
                    orderSelected={sort}
                    anuncios={anuncios}
                    isLoading={isLoading}
                    pagination={pagination}
                    queryValue={queryValue}
                    estatusAnuncio={estatusAnuncio}
                    handleOrdenChange={(value) =>
                      handleChangeStates(value, setOrder, "order")
                    }
                    handleOrderSelectedRemove={() =>
                      handleChangeStates(["fecha_inicio"], setOrder, "order")
                    }
                    handleOrderSelectedChange={(value) =>
                      handleChangeStates(value, setSort, "sort")
                    }
                    handleFiltersQueryChange={(value) =>
                      handleChangeStates(value, setQueryValue, "query", false)
                    }
                    handleQueryValueRemove={() =>
                      handleChangeStates("", setQueryValue, "query", false)
                    }
                    handleEstatusAnuncioChange={(value) =>
                      handleChangeStates(
                        value,
                        setEstatusAnuncio,
                        "status",
                        true,
                        true
                      )
                    }
                    handleEstatusAnuncioRemove={() =>
                      handleChangeStates(
                        [],
                        setEstatusAnuncio,
                        "status",
                        true,
                        true
                      )
                    }
                  />
                </Tabs>
              );
            }}
          </AnunciosContainer>
        </Card>
        <HelpFooter title="anuncios" url="https://help.b2bgo.mx/anuncios" />
      </Page>
    </Layer>
  );
}
