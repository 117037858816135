import React, { useState, useCallback, useEffect, useRef } from "react";
import { Page, Card, Tabs } from "@shopify/polaris";
import {
  Layer,
  ImportPreliminaresModal,
  HelpFooter,
  PedidosList,
} from "../../components";
import { useLocation } from "react-router-dom";
import useUrlSearch from "../../hooks/useUrlSearch";
import { TabsPedidosPreliminaresScreen as tabs } from "../../utils/Tabs";
import {
  fetchPreliminares,
  resetPage,
} from "../../actions/PreliminaresActions";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../services/useDebounce";
import useAbility from "../../hooks/useAbility";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import PreliminaresContainer from "../../containers/PreliminaresContainer/PreliminaresContainer";
import useToggle from "../../hooks/useToggle";
import { getPaginationPreliminares } from "../../reducers";

const objSearch = [
  "query",
  "tab_selected",
  "customers",
  "status",
  "sort",
  "order",
  "shipping",
  "created_by",
  "vendors",
  "tagged_with",
  "selected_dates",
  "created_at",
];

export default function PreliminaresScreen({ forbidden }) {
  const { pathname } = useLocation();
  const { updateHistory, getSearch } = useUrlSearch({ objSearch, pathname });

  const dispatch = useDispatch();
  const queryValueRef = useRef(
    decodeURIComponent(getSearch("query", "", true))
  );
  const pagination = useSelector(getPaginationPreliminares);
  const queryClientRef = useRef(null);
  const taggedRef = useRef(
    decodeURIComponent(getSearch("tagged_with", "", true))
  );
  const deselectedClientOptions = useRef([]);

  const [createdAt, setCreatedAt] = useState(
    getSearch("created_at", "todos", true)
  );
  const [selectedCreatedAt, setSelectedCreatedAt] = useState(
    getSearch("selected_dates", {
      start: new Date(),
      end: new Date(),
    })
  );

  const isForbiddenCreate = useAbility("write", "Preliminares");
  const [isModalActive, handleChange] = useToggle(false);
  const [tabSelected, setTabSelected] = useState(
    Number(getSearch("tab_selected", 0, true))
  );
  const [queryValue, setQueryValue] = useState(
    decodeURIComponent(getSearch("query", "", true))
  );
  const [estatusPreliminar, setEstatusPreliminar] = useState(
    getSearch("status", [])
  );
  const [vendorPedido, setVendorPedido] = useState(getSearch("vendors", []));
  const [shippingMethod, setShippingMethod] = useState(
    getSearch("shipping", [])
  );
  const [creatorSelected, setCreatorSelected] = useState(
    getSearch("created_by", [])
  );
  const [taggedWith, setTaggedWith] = useState(
    decodeURIComponent(getSearch("tagged_with", "", true))
  );
  const [sort, setSort] = useState(getSearch("sort", ["folio"]));
  const [orden, setOrder] = useState(getSearch("order", ["-1"]));
  const [inputValueClient, setInputValueClient] = useState("");
  const [isLoadingClientes, setIsLoadingClientes] = useState(false);
  const [clientesOptions, setClientesOptions] = useState([]);
  const [clientSelected, setClientSelected] = useState(
    getSearch("customers", [])
  );

  const debouncedSearchTerm = useDebounce(queryValue, 900);
  const debouncedTaggedTerm = useDebounce(taggedWith, 900);
  const debouncedClientTerm = useDebounce(inputValueClient, 900);

  useEffect(() => {
    queryValueRef.current = queryValue;
    queryClientRef.current = debouncedClientTerm;
    taggedRef.current = taggedWith;
  });

  useEffect(() => {
    function onQueryEndChange() {
      setIsLoadingClientes(true);
      ApiServiceComercios.obtenerClientesComercio({
        limit: 20,
        skip: 0,
        sort: "nombre",
        query: queryClientRef.current,
        ordern: 1,
        status: JSON.stringify(["Activo"]),
      })
        .then(({ clientes }) => {
          const clientsFormatted = clientes.map((cliente) => ({
            label: cliente.nombre,
            value: cliente._id,
          }));

          const clientOptionsId = deselectedClientOptions.current.map(
            (clientOption) => clientOption.value.toString()
          );

          const newClients = clientsFormatted.filter(
            (client) => !clientOptionsId.includes(client.value)
          );

          deselectedClientOptions.current = [
            ...deselectedClientOptions.current,
            ...newClients,
          ];
          setClientesOptions(clientsFormatted);
        })
        .finally(() => setIsLoadingClientes(false));
    }
    onQueryEndChange();
  }, [debouncedClientTerm]);

  useEffect(() => {
    dispatch(
      fetchPreliminares({
        sort: sort[0],
        query: decodeURIComponent(queryValueRef.current),
        orden: orden[0],
        estatus: JSON.stringify([
          ...new Set(
            estatusPreliminar.concat(
              tabSelected === 1 ? [] : [tabs[tabSelected].panelID]
            )
          ),
        ]),
        vendors: JSON.stringify(vendorPedido),
        createdBy: JSON.stringify(creatorSelected),
        shippingMethod: JSON.stringify(shippingMethod),
        fecha: createdAt === "todos" ? undefined : selectedCreatedAt,
        taggedWith: decodeURIComponent(taggedRef.current),
        customers: JSON.stringify(clientSelected),
      })
    );
  }, [
    dispatch,
    sort,
    orden,
    estatusPreliminar,
    creatorSelected,
    vendorPedido,
    shippingMethod,
    tabSelected,
    createdAt,
    debouncedSearchTerm,
    debouncedTaggedTerm,
    selectedCreatedAt,
    clientSelected,
    pagination.currentPage,
  ]);

  const updateTextContact = useCallback((value) => {
    setInputValueClient(value);
    if (value === "") {
      setClientesOptions(deselectedClientOptions.current);
      return;
    }

    const filterRegex = new RegExp(value, "i");
    const resultOptions = deselectedClientOptions.current.filter((option) =>
      option.label.match(filterRegex)
    );
    setClientesOptions(resultOptions);
  }, []);

  function handleChangeStates(
    value,
    setState,
    key,
    useStringify = true,
    resetTab = false
  ) {
    updateHistory(
      {
        [key]: value,
      },
      useStringify
    );
    resetPagination();
    setState(value);
    if (resetTab) {
      setTabSelected(0);
      updateHistory(
        {
          tab_selected: 0,
        },
        false
      );
    }
  }

  function resetPagination() {
    dispatch(resetPage());
  }

  return (
    <Layer title="Preliminares" forbidden={forbidden} scrollToTop>
      <Page
        fullWidth
        title="Preliminares"
        secondaryActions={[
          {
            content: "Importar",
            onAction: handleChange,
          },
        ]}
        primaryAction={
          !isForbiddenCreate
            ? {
                content: "Crear preliminar",
                primary: true,
                url: "/admin/pedidos/preliminares/agregar",
              }
            : undefined
        }
      >
        {isModalActive && (
          <ImportPreliminaresModal
            isActive={isModalActive}
            handleChange={handleChange}
            type="comercio"
          />
        )}
        <Card>
          <PreliminaresContainer>
            {({ isLoading, preliminares, pagination }) => {
              return (
                <Tabs
                  tabs={tabs}
                  selected={tabSelected}
                  onSelect={(value) =>
                    handleChangeStates(
                      value,
                      setTabSelected,
                      "tab_selected",
                      false
                    )
                  }
                >
                  <PedidosList
                    createdAt={createdAt}
                    selectedCreatedAt={{
                      start: new Date(selectedCreatedAt.start),
                      end: new Date(selectedCreatedAt.end),
                    }}
                    setSelectedCreatedAt={(value) =>
                      handleChangeStates(
                        value,
                        setSelectedCreatedAt,
                        "selected_dates"
                      )
                    }
                    handleCreatedAtChange={(_, value) =>
                      handleChangeStates(
                        value,
                        setCreatedAt,
                        "created_at",
                        false
                      )
                    }
                    handleDateRemove={() =>
                      handleChangeStates(
                        "todos",
                        setCreatedAt,
                        "created_at",
                        false
                      )
                    }
                    taggedWith={decodeURIComponent(taggedWith)}
                    handleTaggedWithChange={(value) =>
                      handleChangeStates(
                        value ? encodeURIComponent(value) : value,
                        setTaggedWith,
                        "tagged_with",
                        false
                      )
                    }
                    handleTaggedWithRemove={() =>
                      handleChangeStates(
                        "",
                        setTaggedWith,
                        "tagged_with",
                        false
                      )
                    }
                    tabSelected={tabSelected}
                    orden={orden}
                    orderSelected={sort}
                    pedidos={preliminares}
                    isLoading={isLoading}
                    pagination={pagination}
                    queryValue={decodeURIComponent(queryValue)}
                    estatusPedido={estatusPreliminar}
                    vendorPedido={vendorPedido}
                    creatorSelected={creatorSelected}
                    shippingMethod={shippingMethod}
                    handleOrdenChange={(value) =>
                      handleChangeStates(value, setOrder, "order")
                    }
                    handleOrderSelectedChange={(value) =>
                      handleChangeStates(value, setSort, "sort")
                    }
                    handleOrderSelectedRemove={() =>
                      handleChangeStates(["folio"], setSort, "sort")
                    }
                    handleFiltersQueryChange={(value) =>
                      handleChangeStates(
                        value ? encodeURIComponent(value) : value,
                        setQueryValue,
                        "query",
                        false
                      )
                    }
                    handleQueryValueRemove={() =>
                      handleChangeStates("", setQueryValue, "query", false)
                    }
                    handleEstatusPedidoChange={(value) =>
                      handleChangeStates(
                        value,
                        setEstatusPreliminar,
                        "status",
                        true,
                        true
                      )
                    }
                    handleEstatusPedidoRemove={() =>
                      handleChangeStates(
                        [],
                        setEstatusPreliminar,
                        "status",
                        true,
                        true
                      )
                    }
                    handleVendorPedidoChange={(value) =>
                      handleChangeStates(value, setVendorPedido, "vendors")
                    }
                    handleVendorPedidoRemove={() =>
                      handleChangeStates([], setVendorPedido, "vendors")
                    }
                    handleShippingMethodChange={(value) =>
                      handleChangeStates(value, setShippingMethod, "shipping")
                    }
                    handleShippingMethodRemove={() =>
                      handleChangeStates([], setShippingMethod, "shipping")
                    }
                    handleCreatorSelectedChange={(value) =>
                      handleChangeStates(
                        value,
                        setCreatorSelected,
                        "created_by"
                      )
                    }
                    handleCreatorSelectedRemove={() =>
                      handleChangeStates([], setCreatorSelected, "created_by")
                    }
                    clientOptions={clientesOptions}
                    clientSelected={clientSelected}
                    handleClientChange={(value) =>
                      handleChangeStates(value, setClientSelected, "customers")
                    }
                    deselectedClients={deselectedClientOptions}
                    inputValueClient={inputValueClient}
                    updateTextContact={updateTextContact}
                    loadingClients={isLoadingClientes}
                    type="draft_orders"
                  />
                </Tabs>
              );
            }}
          </PreliminaresContainer>
        </Card>
        <HelpFooter
          title="Pedidos Preliminares"
          url="https://help.b2bgo.mx/preliminares"
        />
      </Page>
    </Layer>
  );
}
