import React, { useState, useCallback, useEffect } from "react";
import { Button, Card, DatePicker, Popover, TextStyle } from "@shopify/polaris";
import { CalendarMajor } from "@shopify/polaris-icons";
import moment from "moment";
import useToggle from "../../hooks/useToggle";

export default function DatePickerB2BGo({
  date,
  onSelectDate,
  title = "",
  disableAfter = null,
  disableBefore = null,
  requiredIndicator = false,
}) {
  const [popoverActive, togglePopoverActive] = useToggle(false);
  const [{ month, year }, setDate] = useState({ month: 0, year: 2018 });
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(),
  });

  useEffect(() => {
    const month = moment(date).month();
    const year = moment(date).year();
    setSelectedDates({
      start: new Date(date),
      end: new Date(date),
    });
    setDate({ month, year });
  }, [date]);

  const handleChangeMonth = useCallback(
    (month, year) => setDate({ month, year }),
    []
  );

  const handleDateChange = (date) => {
    setSelectedDates(date);
    onSelectDate(date);
    togglePopoverActive();
  };

  const activator = (
    <Button
      onClick={togglePopoverActive}
      disclosure
      fullWidth
      icon={CalendarMajor}
    >
      {moment(selectedDates.start).format("DD MMM YYYY")}
    </Button>
  );

  return (
    <>
      <TextStyle>
        {title}{" "}
        {requiredIndicator && <TextStyle variation="negative">*</TextStyle>}
      </TextStyle>
      <Popover
        active={popoverActive}
        activator={activator}
        onClose={togglePopoverActive}
      >
        <Card sectioned>
          <DatePicker
            month={month}
            year={year}
            onChange={handleDateChange}
            onMonthChange={handleChangeMonth}
            selected={selectedDates}
            disableDatesAfter={disableAfter}
            disableDatesBefore={disableBefore}
          />
        </Card>
      </Popover>
    </>
  );
}
