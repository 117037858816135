import {
   TOGGLE_TOAST,
   SET_IS_LOADING,
   UPDATE_VISITORS,
} from '../actions/InteractiveActions';

const initialState = {
   activeToast: false,
   message: "",
   isLoading: false,
   currentVisitors: 0,
}

export default function InteractiveReducer(state = initialState, action) {
   switch (action.type) {
      case TOGGLE_TOAST: {
         return {
            ...state,
            activeToast: !!action.payload.data.message,
            message: action.payload.data.message || "",
         }
      }
      case SET_IS_LOADING: {
         return {
            ...state,
            isLoading: action.payload.data.active
         }
      }
      case UPDATE_VISITORS: {
         return {
            ...state,
            currentVisitors: action.payload.data.visitors,
         }
      }
      default:
         return state;
   }
}

export const getCurrentVisitors = state => {
   return state.currentVisitors;
}