import React from "react";
import {
  SkeletonPage,
  Layout,
  Card,
  SkeletonDisplayText,
  SkeletonBodyText,
} from "@shopify/polaris";

export default function ComercioMicrosipSkeleton() {
  return (
    <SkeletonPage breadcrumbs>
      <Layout>
        <Layout.AnnotatedSection
          title={<SkeletonDisplayText size="small" />}
          description={<SkeletonBodyText lines={2} />}
        >
          <Card>
            <Card.Section>
              <SkeletonDisplayText size="small" />
              <div className="my-8" />
              <SkeletonBodyText lines={2} />
              <div className="my-10" />
              <SkeletonDisplayText size="small" />
              <div className="my-8" />
              <SkeletonBodyText lines={2} />
              <div className="my-10" />
              <SkeletonDisplayText size="small" />
              <div className="my-8" />
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </SkeletonPage>
  );
}
