import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Button, Checkbox, TextStyle } from "@shopify/polaris";
import {
  getAuthData,
  getOpportunities,
  getPaginationOpportunities,
  isLoadingOpportunities,
} from "../../reducers";
import { CustomBadge, CustomPopover } from "../../components";
import { useHistory } from "react-router";
import { NumberFormat } from "../../utils/Formats";

export default function OportunidadesContainer({
  children,
  selectedItems,
  handleSelect,
}) {
  const router = useHistory();
  const isLoading = useSelector(isLoadingOpportunities);
  const pagination = useSelector(getPaginationOpportunities);
  const { grant_type } = useSelector(getAuthData);
  const opportunities = useSelector(getOpportunities).map((item) => ({
    ...item,
    id: item._id,
  }));

  function navigate(id) {
    router.push(`/admin/oportunidades/${id}`);
  }

  function setStatus(envio) {
    const status = {
      Pendiente: "incomplete",
      Perdido: undefined,
      Ganado: "complete",
    };
    return status[envio];
  }

  let opportunitiesColumns = [
    {
      title: "Oportunidad",
      dataIndex: "folio",
      render: (folio, { id }) => (
        <div className="flex flex-row items-center cursor-pointer">
          <div className="pl-6">
            <Checkbox
              value={id}
              checked={selectedItems.indexOf(id) >= 0}
              onChange={(val) => handleSelect(val, id)}
            />
          </div>
          <div
            className="py-6 pr-6 w-auto h-full text-link"
            onClick={() => navigate(id)}
          >
            <TextStyle variation="strong">{folio}</TextStyle>
          </div>
        </div>
      ),
    },
    {
      title: "Fecha de cierre",
      dataIndex: "closingDate",
      render: (closingDate, { id }) => (
        <div
          className="w-full h-full cursor-pointer p-6 whitespace-nowrap"
          onClick={() => navigate(id)}
        >
          <TextStyle>{moment(closingDate).format("D MMM YYYY")}</TextStyle>
        </div>
      ),
    },
    {
      title: "Cliente",
      dataIndex: "clientId",
      render: (clientId, { _id }) => (
        <div
          className="w-full h-full cursor-pointer p-6 whitespace-nowrap"
          onClick={() => navigate(_id)}
        >
          <TextStyle>{clientId?.nombre || "--"}</TextStyle>
        </div>
      ),
    },
    {
      title: "Importe",
      dataIndex: "amount",
      render: (amount, { id }) => (
        <div
          className="w-full h-full cursor-pointer p-6 whitespace-nowrap"
          onClick={() => navigate(id)}
        >
          <TextStyle>
            ${Number(amount).toLocaleString("en", NumberFormat)}
          </TextStyle>
        </div>
      ),
    },
    {
      title: "Tipo",
      dataIndex: "type",
      render: (type, { id }) => (
        <div
          className="w-full h-full cursor-pointer p-6 whitespace-nowrap"
          onClick={() => navigate(id)}
        >
          <TextStyle>{type || "--"}</TextStyle>
        </div>
      ),
    },
    {
      title: "Origen",
      dataIndex: "origin",
      render: (origin, { id }) => (
        <div
          className="w-full h-full cursor-pointer p-6 whitespace-nowrap"
          onClick={() => navigate(id)}
        >
          <TextStyle>{origin?.name || "--"}</TextStyle>
        </div>
      ),
    },
    {
      title: "Etapa",
      dataIndex: "phase",
      render: (phase, { id }) => (
        <div
          className="w-full h-full cursor-pointer p-6 whitespace-nowrap"
          onClick={() => navigate(id)}
        >
          <CustomBadge
            status={phase?.name || ""}
            progress={setStatus(phase?.name)}
          />
        </div>
      ),
    },
    {
      title: "Vendedor",
      dataIndex: "vendor",
      render: (vendor, { id }) => (
        <div
          className="w-full h-full cursor-pointer p-6 whitespace-nowrap"
          onClick={() => navigate(id)}
        >
          <TextStyle>{vendor?.name || "--"}</TextStyle>
        </div>
      ),
    },
    {
      title: "Artículos",
      dataIndex: "articles",
      render: (articles) => {
        const articlesDB = articles.map((articulo) => {
          return {
            content:
              articulo.articulo?.nombre || articulo.articulo?.clave || "",
            onAction: () => {
              grant_type === "COMERCIO" &&
                router.push(`/admin/articulos/${articulo.articulo._id}`);
            },
          };
        });
        const Activator = ({ onClick }) => {
          return (
            <div className="no-underline text-gray-600 ">
              <Button
                onClick={onClick}
                disclosure
                plain
                textAlign="left"
                monochrome
              >
                {" "}
                {` ${articles.length} artículo${
                  articles.length > 1 ? "s" : ""
                }`}
              </Button>
            </div>
          );
        };
        return (
          <div className="w-full h-full cursor-pointer p-6  whitespace-nowrap">
            <CustomPopover items={articlesDB} activator={Activator} />
          </div>
        );
      },
    },
  ];

  return (
    <>
      {children({
        isLoading,
        pagination,
        oportunidades: opportunities,
        oportunidadesColumns: opportunitiesColumns,
      })}
    </>
  );
}
