import { b2bgoApiaxios, b2bgoAuthaxios } from "../utils/Axios";

const ApiServiceComercios = {
  API_ADMIN_COMERCIOS:
    process.env.NODE_ENV === "development" ? "3003" : "admin-comercios",

  /**
   * Comercios
   */

  async obtenerComercios(params) {
    const method = "GET";
    const url = "/comercios";
    return this.makeAuthorizedRequest({ method, url, params });
  },

  async obtenerComercio(id, forPlan) {
    const method = "GET";
    const paramsData = !forPlan ? null : this.makeQueryParams({ forPlan });
    const url = `/comercios/${id}?${paramsData}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerComercioConfig() {
    const method = "GET";
    const url = `/comercios/config`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerComercioConfigEmail(email) {
    const method = "GET";
    const url = `/comercios/config-email?email=${email}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerComercioConfigMicrosip(params = {}) {
    const method = "GET";
    let url = `/comercios/integraciones/microsip`;
    url += `?${this.makeQueryParams(params)}`;

    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerComercioConfigMicrosipSeries() {
    const method = "GET";
    let url = `/comercios/integraciones/microsip/series`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerTrackingList() {
    const method = "GET";
    const url = "/comercios/tracking-types";
    return this.makeAuthorizedRequest({ method, url });
  },

  async getImageData() {
    const method = "GET";
    let url = `/comercios/data`;
    return this.makeRequest({ method, url });
  },

  async getCustomization() {
    const method = "GET";
    let url = `/comercios/customization`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerClientesComercio(params) {
    const method = "GET";
    const url = "/comercios/clientes";
    return this.makeAuthorizedRequest({ method, url, params });
  },

  async obtenerClienteComercio(id) {
    const method = "GET";
    const url = `/comercios/clientes/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async updateClientContact(id, data) {
    const method = "PUT";
    const url = `/comercios/clientes/contacts/${id}`;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async obtenerPaginasComercio() {
    const method = "GET";
    const url = "/comercios/paginas";
    return this.makeAuthorizedRequest({ method, url });
  },

  async getCollections(params) {
    const method = "GET";
    const url = "/comercios/collections";
    return this.makeAuthorizedRequest({ method, url, params });
  },

  async getCollectionById(id, params) {
    const method = "GET";
    const url = `/comercios/collections/${id}`;
    return this.makeAuthorizedRequest({ method, url, params });
  },

  async getCollectionArticles(params) {
    const method = "GET";
    const url = "/comercios/collections-articles";
    return this.makeAuthorizedRequest({ method, url, params });
  },

  async getArticlesByCollectionsHandle(handle, params) {
    const method = "GET";
    const url = `/comercios/collections/handle/${handle}`;
    return this.makeAuthorizedRequest({ method, url, params });
  },

  async postCollections(data) {
    const method = "POST";
    const url = "/comercios/collections";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async putCollection(id, data) {
    const method = "PUT";
    const url = `/comercios/collections/${id}`;
    return this.makeAuthorizedRequest({ method, url, data });
  },
  async deleteArticle(id) {
    const method = "DELETE";
    const url = `/comercios/articulos/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },
  async deleteVariantArticle(id, variantId) {
    const method = "DELETE";
    const url = `/comercios/articulos/${id}/variants/${variantId}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async deleteCollection(id) {
    const method = "DELETE";
    const url = `/comercios/collections/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerHistorialComercios(params, id_comercio) {
    const method = "GET";
    const paramsData = !id_comercio
      ? null
      : this.makeQueryParams({ id_comercio });
    let url = `/comercios/historial`;
    if (paramsData) {
      url += `?${paramsData}`;
    }
    return this.makeAuthorizedRequest({ method, url, params });
  },

  async synchronizeValidate(id_comercio) {
    const method = "POST";
    const params = id_comercio ? this.makeQueryParams({ id_comercio }) : null;
    const url = `/comercios/synchronize-validate?${params}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerSucursales(params) {
    const method = "GET";
    const url = "/comercios/sucursales";
    return this.makeAuthorizedRequest({ method, url, params });
  },

  async obtenerSucursal(id) {
    const method = "GET";
    const url = `/comercios/sucursales/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerEnvios() {
    const method = "GET";
    const url = "/comercios/envios";
    return this.makeAuthorizedRequest({ method, url });
  },

  async getVendor(id) {
    const method = "GET";
    const url = `/comercios/vendedores/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },
  async getCollector(id) {
    const method = "GET";
    const url = `/comercios/collectors/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerArticulos(params, toCreateOrder) {
    const method = toCreateOrder ? "POST" : "GET";
    const url = toCreateOrder
      ? "/comercios/variants-order"
      : "/comercios/articulos";
    const data = { method, url };
    if (toCreateOrder) {
      data["data"] = params;
    } else {
      data["params"] = params;
    }
    return this.makeAuthorizedRequest(data);
  },

  async getArticlesForCSV(data) {
    const method = "POST";
    const url = "/comercios/article-validation-csv";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async getArticlesValidation(data) {
    const method = "POST";
    const url = "/comercios/article-validation";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async obtenerArticulo(id, handle = "") {
    const method = "GET";
    const url = `/comercios/articulos/${id}`;
    const params = { handle };
    return this.makeAuthorizedRequest({ method, url, params });
  },

  async obtenerVariante(id) {
    const method = "GET";
    const url = `/comercios/variantes/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async getCurrencies() {
    const method = "GET";
    const url = "/comercios/monedas/";
    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerAnuncios(params) {
    const method = "GET";
    const url = "/comercios/anuncios";
    return this.makeAuthorizedRequest({ method, url, params });
  },

  async obtenerAnunciosClientes() {
    const method = "GET";
    const url = "/comercios/anuncios/clientes";
    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerChatIntegracion() {
    const method = "GET";
    const url = "/comercios/chatIntegracion";
    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerPedidos(params) {
    const method = "GET";
    const url = "/comercios/pedidos";
    return this.makeAuthorizedRequest({ method, url, params });
  },

  /*Metodo para Obtener pedidos preliminares */
  async obtenerPreliminares(params) {
    const method = "GET";
    const url = "/comercios/draft_orders";
    return this.makeAuthorizedRequest({ method, url, params });
  },
  /*Fin del Metodo para Obtener pedidos preliminares */

  async obtenerCotizaciones(params) {
    const method = "GET";
    const url = "/comercios/cotizaciones";
    return this.makeAuthorizedRequest({ method, url, params });
  },

  async getOpportunities(params) {
    const method = "GET";
    const url = "/comercios/opportunities";
    return this.makeAuthorizedRequest({ method, url, params });
  },

  async getPayments(params) {
    const method = "GET";
    const url = "/comercios/payments";
    return this.makeAuthorizedRequest({ method, url, params });
  },

  async pedidoDetalle(id) {
    const method = "GET";
    const url = `/comercios/pedidos/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  /*Metodo de Detalle de pedido preliminar */
  async preliminarDetalle(id) {
    const method = "GET";
    const url = `/comercios/draft_orders/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },
  /*Fin del metodo de Detalle de pedido preliminar */

  async cotizacionDetalle(id) {
    const method = "GET";
    const url = `/comercios/cotizaciones/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async opportunityDetail(id) {
    const method = "GET";
    const url = `/comercios/opportunities/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerAnuncio(id) {
    const method = "GET";
    const url = `/comercios/anuncios/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerLineas() {
    const method = "GET";
    const url = `/comercios/lineas-articulo`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async getBrands() {
    const method = "GET";
    const url = `/comercios/marcas`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerEnvio(id) {
    const method = "GET";
    const url = `/comercios/envios/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerPrecioListas() {
    const method = "GET";
    const url = "/comercios/precio-listas";
    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerDescuentos(params) {
    const method = "GET";
    const url = "/comercios/descuentos";
    return this.makeAuthorizedRequest({ method, params, url });
  },

  async obtenerTiposCliente(params) {
    const method = "GET";
    const url = "/comercios/tipos-cliente";
    return this.makeAuthorizedRequest({ method, params, url });
  },

  async obtenerZonasCliente(params) {
    const method = "GET";
    const url = "/comercios/zonas-cliente";
    return this.makeAuthorizedRequest({ method, params, url });
  },

  async getCustomerStatus() {
    const method = "GET";
    const url = "/comercios/customer-status";
    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerPedidoDatos(id) {
    const method = "GET";
    const url = `/comercios/pedido-datos/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerImpuestoComercio() {
    const method = "GET";
    const url = "/comercios/impuestos";
    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerDescuento(id) {
    const method = "GET";
    const url = `/comercios/descuentos/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerSearchData(params) {
    const method = "GET";
    const url = "/comercios/search-bar";
    return this.makeAuthorizedRequest({ method, params, url });
  },

  async obtenerPagina(pagina) {
    const method = "GET";
    const url = `/comercios/paginas/${pagina}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerEstadoDeCuenta() {
    const method = "GET";
    const url = "/comercios/clientes/estado-de-cuenta";
    return this.makeAuthorizedRequest({ method, url });
  },

  async descargarEstadoDeCuenta() {
    const method = "GET";
    const url = "/comercios/clientes/estado-de-cuenta/descarga";
    return this.makeAuthorizedRequest({ method, url });
  },

  async obtenerFacturas(params) {
    const method = "GET";
    const url = "/comercios/facturas";
    return this.makeAuthorizedRequest({ method, url, params });
  },

  async descargarFactura(params) {
    const method = "GET";
    const url = "/comercios/facturas/descarga";
    return this.makeAuthorizedRequest({
      method,
      url,
      params,
      responseType: "blob",
    });
  },

  async obtenerPlanComercio() {
    const method = "GET";
    const url = "/comercios/plan";
    return this.makeAuthorizedRequest({ method, url });
  },

  async getFilters() {
    const method = "GET";
    const url = "/comercios/filtros";
    return this.makeAuthorizedRequest({ method, url });
  },

  async getItemsByFilters(params) {
    const method = "GET";
    const url = "/comercios/filtros/articulos";
    return this.makeAuthorizedRequest({ method, url, params });
  },

  async getTagsArticleFilters() {
    const method = "GET";
    const url = "/comercios/filtros-articulos-tags";
    return this.makeAuthorizedRequest({ method, url });
  },

  async getVendors() {
    const method = "GET";
    const url = "/comercios/vendedores";
    return this.makeAuthorizedRequest({ method, url });
  },

  async getCollectors() {
    const method = "GET";
    const url = "/comercios/collectors";
    return this.makeAuthorizedRequest({ method, url });
  },

  async getPhases() {
    const method = "GET";
    const url = "/comercios/phases";
    return this.makeAuthorizedRequest({ method, url });
  },

  async getOrigins() {
    const method = "GET";
    const url = "/comercios/origins";
    return this.makeAuthorizedRequest({ method, url });
  },

  async getMessages(parentMessageId) {
    const method = "GET";
    const url = `/admin/messages/${parentMessageId}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async getPageSectionByType(type) {
    const method = "GET";
    const url = `/comercios/pages/${type}`;
    return this.makeRequest({ method, url });
  },

  async getTags(params = {}) {
    const method = "GET";
    const newParams = this.makeQueryParams(params);
    const url = `/comercios/tags?${newParams}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async createTag(data) {
    const method = "POST";
    const url = "/comercios/tags";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async getMenuList() {
    const method = "GET";
    const url = "/comercios/menus";
    return this.makeAuthorizedRequest({ method, url });
  },
  async postMenuList(data) {
    const method = "POST";
    const url = "/comercios/menus";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async putMenuList(id, data) {
    const method = "PUT";
    const url = `/comercios/menus/${id}`;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async deleteMenuList(id) {
    const method = "DELETE";
    const url = `/comercios/menus/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async synchronizeCollections(id_comercio, data) {
    const method = "POST";
    const params = id_comercio ? this.makeQueryParams({ id_comercio }) : null;
    const url = `/comercios/synchronize-collections?${params}`;
    return this.makeAuthorizedRequest({
      method,
      url,
      data: { selected: data },
    });
  },

  async crearComercio(params) {
    const method = "POST";
    const url = "/comercios";
    const data = {
      ...params,
      url_comercio: params.url_comercio + ".b2bgo.mx",
      prefijo: "",
    };
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async loginComercio(id) {
    const method = "POST";
    const url = "/loggin";
    const data = { id };
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async checkIntegrationMicrosip(data) {
    const method = "POST";
    const url = "/comercios/integracion/microsip";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async crearSucursal(params) {
    const method = "POST";
    const url = "/comercios/sucursales";
    const data = { ...params };
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async crearEnvio(params) {
    const method = "POST";
    const url = "/comercios/envios";
    const data = { ...params };
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async crearAnuncio(params) {
    const method = "POST";
    const url = "/comercios/anuncios";
    const data = params;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async postFiles(formData) {
    const method = "POST";
    const url = "/admin/files";
    const data = formData;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async payBilling(data) {
    const method = "POST";
    const url = "/comercios/facturas/manual-payments";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async createContact(idClient, data) {
    const method = "POST";
    const url = `/comercios/clientes/${idClient}/contacts`;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async resendInvitationContact(idClient, idContact) {
    const method = "POST";
    const url = `/comercios/clientes/${idClient}/contacts/${idContact}/resend-invitation`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async deleteContact(idClient) {
    const method = "DELETE";
    const url = `/comercios/clientes/contacts/${idClient}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async clickAnuncio(id_anuncio) {
    const method = "POST";
    const url = "/comercios/anuncios/clicks";
    const data = { id_anuncio };
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async crearPedido(data, id = null, quotationId = null, draftOrderId = null) {
    const method = "POST";
    const params = this.makeQueryParams({ id, quotationId, draftOrderId });
    let url = `/comercios/pedidos?${params}`;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  /*Metodo para crear pedidos preliminares */
  async crearPreliminar(data, id = null) {
    const method = "POST";
    const params = this.makeQueryParams({ id });
    let url = `/comercios/draft_orders?${params}`;
    return this.makeAuthorizedRequest({ method, url, data });
  },
  /*Fin del Metodo para crear pedidos preliminares */

  async crearCotizacion(data, id = null) {
    const method = "POST";
    let url = `/comercios/cotizaciones`;
    if (id) url += `?id=${id}`;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async createOpportunity(data, id = null) {
    const method = "POST";
    let url = `/comercios/opportunities`;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async crearDescuento(data) {
    const method = "POST";
    const url = "/comercios/descuentos";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async eliminarDescuento(id) {
    const method = "DELETE";
    const url = `/comercios/descuentos/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async marcarAnuncioLeido(id) {
    const method = "POST";
    const url = `/comercios/anuncios/mark-as-read/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async crecerPlanComercio() {
    const method = "POST";
    const url = "/comercios/crecer-plan";
    return this.makeAuthorizedRequest({ method, url });
  },

  /**
   * @param {object} data - datos a inicializar
   * @param {boolean} data.catalogos - Indica si se incializarán los catálogos
   */
  async initializeDatabase(inicializar) {
    const method = "POST";
    const url = "/comercios/inicializar";
    const data = { inicializar };
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async setNewFilter(data) {
    const method = "POST";
    const url = `/comercios/filtros`;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async postMessage(articleData, parentMessageId, formData = null) {
    const method = "POST";
    const url = `/admin/messages/${parentMessageId}`;
    const data = formData ? formData : articleData;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async createSender(data) {
    const method = "POST";
    const url = "/comercios/senders";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async invitarClienteComercio(ids) {
    const method = "PUT";
    const url = `/comercios/clientes/invitacion`;
    const data = { ids };
    return this.makeAuthorizedRequest({ method, data, url });
  },

  async editarComercioConfigMicrosip(configuracion, id) {
    const method = "PUT";
    const url = `/comercios/integraciones/microsip/${id}`;
    const data = configuracion;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async editarComercio({ comercio, id, formData }) {
    delete comercio.imagen;
    const method = "PUT";
    const url = `/comercios/${id}`;
    let data = formData ? formData : comercio;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async putCustomization({ comercio, formData }) {
    delete comercio.imagen;
    const method = "PUT";
    const url = `/comercios/customization`;
    let data = formData ? formData : comercio;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async actualizarPaginasComercio(paginas) {
    const method = "PUT";
    const url = "/comercios/paginas/editar";
    const data = { paginas };
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async actualizarChatIntegracion(data) {
    const method = "PUT";
    const url = "/comercios/chatIntegracion";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async actualizarAnuncio(id, data) {
    const method = "PUT";
    const url = `/comercios/anuncios/${id}`;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async actualizarPedido(data, id) {
    const method = "PUT";
    const url = `/comercios/pedidos/${id}`;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  /* Metodo para actualizar pedidos preliminares */
  async actualizarPreliminar(data, id) {
    const method = "PUT";
    const url = `/comercios/draft_orders/${id}`;
    return this.makeAuthorizedRequest({ method, url, data });
  },
  async cancelDraftOrder(id) {
    const method = "PUT";
    const url = `/comercios/draft_orders/${id}/cancel`;
    return this.makeAuthorizedRequest({ method, url });
  },
  /*Fin del  Metodo para actualizar pedidos preliminares */

  async actualizarCotizacion(data, id) {
    const method = "PUT";
    const url = `/comercios/cotizaciones/${id}`;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async actualizarOpportunity(data, id) {
    const method = "PUT";
    const url = `/comercios/opportunities/${id}`;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async actualizarArticulo(articulo, id, formData) {
    const method = "PUT";
    const url = `/comercios/articulos/${id}`;
    const data = formData ? formData : articulo;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async actualizarCliente(data, id) {
    const method = "PUT";
    const url = `/comercios/clientes/${id}`;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async actualizarEnvio(data, id) {
    const method = "PUT";
    const url = `/comercios/envios/${id}`;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async actualizarImpuestoComercio(data) {
    const method = "PUT";
    const url = "/comercios/impuestos";
    return this.makeAuthorizedRequest({ method, data, url });
  },

  async actualizarPreferenciaComercio(data) {
    const method = "PUT";
    const url = "/comercios/preferencias";
    return this.makeAuthorizedRequest({ method, data, url });
  },

  async actualizarDescuento(data, id) {
    const method = "PUT";
    const url = `/comercios/descuentos/${id}`;
    return this.makeAuthorizedRequest({ method, data, url });
  },

  async actualizarSucursal(data, id) {
    const method = "PUT";
    const url = `/comercios/sucursales/${id}`;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async actualizaDireccionCliente(data) {
    const method = "PUT";
    const url = "/comercios/clientes/direcciones";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async actualizarClienteAlmacenes(data) {
    const method = "PUT";
    const url = "/comercios/clientes/almacenes";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async putShopFilterByList(data) {
    const method = "PUT";
    const url = "/comercios/filtros/by-list";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async putShopFilterById(filterId, data) {
    const method = "PUT";
    const url = `/comercios/filtros/${filterId}`;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async removeFilterTagById(id, data) {
    const method = "PUT";
    const url = `/comercios/filtros/etiquetas/${id}`;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async actualizarFiltroPreferencias(data) {
    const method = "PUT";
    const url = "/comercios/filtros-preferencias";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async resendInvitationSender(id) {
    const method = "PUT";
    const url = `/comercios/senders/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async eliminarAnuncio(id) {
    const method = "DELETE";
    const url = `/comercios/anuncios/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },
  async removeFilterById(id) {
    const method = "DELETE";
    const url = `/comercios/filtros/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },

  async getNotifications() {
    const method = "GET";
    const url = "/admin/comercios/notifications";
    return this.makeAuthorizedRequest({ method, url });
  },

  async deleteNotificationById(id) {
    const method = "POST";
    const url = `/admin/notifications/mark-as-read`;
    const data = { id };
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async obtenerPreferenciasComercio() {
    const method = "GET";
    const url = "/comercios/preferencias";
    return this.makeAuthorizedRequest({ method, url });
  },

  async setPreferenciasComercio(data) {
    const method = "PUT";
    const url = "/comercios/preferencias";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async createBrand(data) {
    const method = "POST";
    const url = "/comercios/marcas";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async createPhase(data) {
    const method = "POST";
    const url = "/comercios/phases";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async createOrigin(data) {
    const method = "POST";
    const url = "/comercios/origins";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async setPreferencesByItems(data) {
    const method = "PUT";
    const url = "/comercios/preferencias/articulos";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async updateArticlesByXlsx(data) {
    const method = "PUT";
    const url = "/comercios/articles-by-xlsx";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async getPaymentConfigurationDetails() {
    const method = "GET";
    const url = "/comercios/payment-configuration-details";
    return this.makeAuthorizedRequest({ method, url });
  },

  async getPaymentsConfig() {
    const method = "GET";
    const url = "/comercios/payment-configuration";
    return this.makeAuthorizedRequest({ method, url });
  },

  async setPaymentsConfig(data) {
    const method = "PUT";
    const url = "/comercios/payment-configuration";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async validateKey(data) {
    const method = "POST";
    const url = "/comercios/payment-configuration-validation";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async getPublicKey() {
    const method = "GET";
    const url = "/comercios/payment-keys";
    return this.makeAuthorizedRequest({ method, url });
  },

  async setPaymentAttempt(data) {
    const method = "POST";
    const url = "/comercios/payment-intent";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async setDraftOrder(data) {
    const method = "POST";
    const url = "/comercios/draft_orders";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async createAndConfirmPaymentIntent(data) {
    const method = "POST";
    const url = "/comercios/payment-intent-confirm";
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async updatePageSectionById(id, data) {
    const method = "PUT";
    const url = `/comercios/pages/${id}`;
    return this.makeAuthorizedRequest({ method, url, data });
  },

  makeQueryParams(params) {
    let queries = "";
    for (var key in params) {
      queries += `${key}=${params[key]}&`;
    }
    return queries;
  },

  async makeRequest(requestData = {}) {
    try {
      let res = await b2bgoApiaxios({
        ...requestData,
        api_uri: this.API_ADMIN_COMERCIOS,
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  async makeAuthorizedRequest(requestData = {}) {
    try {
      let res = await b2bgoAuthaxios({
        ...requestData,
        api_uri: this.API_ADMIN_COMERCIOS,
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  async synchronizeArticle(id_article) {
    const method = "POST";
    const url = `/comercios/synchronize-articles/${id_article}`;
    return this.makeAuthorizedRequest({ method, url });
  },
  //Metodos de pagos manuales
  async getManualPayments() {
    const method = "GET";
    const url = "/comercios/manual-payments";
    return this.makeAuthorizedRequest({ method, url });
  },
  async getManualPaymentById(id) {
    const method = "GET";
    const url = `/comercios/manual-payments/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },
  async createManualPayment(data) {
    const method = "POST";
    const url = "/comercios/manual-payments";
    return this.makeAuthorizedRequest({ method, url, data });
  },
  async updateManualPayment(id, data) {
    const method = "PUT";
    const url = `/comercios/manual-payments/${id}`;
    return this.makeAuthorizedRequest({ method, url, data });
  },
  async deleteManualPayment(id) {
    const method = "DELETE";
    const url = `/comercios/manual-payments/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },
  //Obtiene el detalle de un pago
  async getPaymentById(id) {
    const method = "GET";
    const url = `/comercios/payments/${id}`;
    return this.makeAuthorizedRequest({ method, url });
  },
  //Marcar como pagado
  async markAsPaid(id, data) {
    const method = "PUT";
    const url = `/comercios/payments/${id}`;
    return this.makeAuthorizedRequest({ method, url, data });
  },
  //Crea un cliente
  async createClient(data) {
    const method = "POST";
    const url = "/comercios/clientes";
    return this.makeAuthorizedRequest({ method, url, data });
  },
  //Obtener condiciones de pago
  async obtenerCondicionesPago() {
    const method = "GET";
    const url = "/comercios/payment-conditions";
    return this.makeAuthorizedRequest({ method, url });
  },
  //Validar nombre, email y clave del cliente
  async verifyAvailibility(data) {
    const method = "POST";
    const url = "/comercios/customers/verify-availibility";
    return this.makeAuthorizedRequest({ method, url, data });
  },
};

export default ApiServiceComercios;
