import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Banner, Card, Modal, Select, TextStyle } from '@shopify/polaris'
import ApiServiceComercios from '../../services/ApiServiceComercios';
import { toggleToast } from '../../actions/InteractiveActions';
import { fetchClientesComercio } from '../../actions/ClientesActions';

export default function AssignStock({isOpen, handleChange, clientes, handleSelectAll}) {
   const dispatch = useDispatch();
   const [almacenesDB, setAlmacenesDB] = useState([]);
   const [almacen, setAlmacen] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   
   useEffect(() => {
      ApiServiceComercios.obtenerSucursales({permitir_registro: true})
      .then(({ok, sucursales}) => {
         if(ok) {setAlmacenesDB(sucursales.map((a) => ({label: a.nombre, value: String(a._id)})))};
      });
   }, []);

   function setStock() {
      setIsLoading(true);
      ApiServiceComercios.actualizarClienteAlmacenes({clientes, almacen_id: almacen})
      .then(({message}) => {
         dispatch(toggleToast({message}));
         setIsLoading(false);
         dispatch(fetchClientesComercio({
            sort: "nombre", 
            query: "", 
            orden: 1, 
            estatus: JSON.stringify([]),
            forList: true,
            taggedWith: "",
         }));
         handleSelectAll([]);
         handleChange();
      }).catch(() => setIsLoading(false));
   }
   
   return (
      <Modal
         open={isOpen}
         title="Asignar almacén"
         onClose={handleChange}
         primaryAction={{content: "Guardar", onAction: setStock, disabled: !almacen, loading: isLoading}}
         secondaryActions={[{content: "Cancelar", onAction: handleChange, disabled: isLoading}]}
      >
         <Card sectioned>
            <Select
               placeholder="Selecciona un almacén"
               label="Almacén"
               value={almacen}
               options={almacenesDB}
               onChange={(value) => setAlmacen(value)}
            />
         </Card>
         <Banner status="info">
            <TextStyle>Las existencias disponibles y los pedidos de los clientes seleccionados serán para éste almacén.</TextStyle>
         </Banner>
         
      </Modal>
   )
}
