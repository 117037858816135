import {
  Badge,
  Banner,
  Button,
  Card,
  ChoiceList,
  ContextualSaveBar,
  DataTable,
  FormLayout,
  Layout,
  Page,
  Select,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import {
  DeleteMajor,
  EditMajor,
  MobileHorizontalDotsMajor,
} from "@shopify/polaris-icons";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toggleToast } from "../../actions/InteractiveActions";
import {
  ClienteDescuentoModal,
  ComercioDetalleSkeleton,
  CustomPopover,
  DatePicker,
  DeleteModal,
  HelpFooter,
  Layer,
  TipoClienteModal,
  ZonaClienteModal,
} from "../../components";
import { getZonaHoraria } from "../../reducers";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { scrollToTop } from "../../utils/windowActions";
import "./AddDiscountByClientScreenStyles.css";
const aplicaciones = [
  { label: "Todos", value: "Todos" },
  { label: "Tipos de clientes", value: "Tipos de clientes" },
  { label: "Zonas de clientes", value: "Zonas de clientes" },
  { label: "Clientes", value: "Clientes" },
];

function AddDiscountByClientScreen({ forbidden }) {
  const { id } = useParams();

  const router = useHistory();
  const dispatch = useDispatch();
  const zonaHoraria = useSelector(getZonaHoraria);
  const [descuento, setDescuento] = useState({
    titulo: "",
    precioLista: "",
    aplicacion: ["Todos"],
    porcentaje: "0",
    estatus: "Habilitado",
    descuentoArticulo: "",
    id: null,
    usado: 0,
    fechaInicio: new Date(),
    horaInicio: "00:00",
    fechaFin: new Date(),
    horaFin: "00:00",
  });
  const [isDirty, setIsDirty] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isCustom, setIsCustom] = useState(false);

  const [preciosLista, setPreciosLista] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [descuentosOptions, setDescuentos] = useState([]);
  const [isActiveTipoClienteModal, setIsActiveTipoClienteModal] =
    useState(false);
  const [active, setActive] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isActiveZonaClienteModal, setIsActiveZonaClienteModal] =
    useState(false);
  const [isActiveClientesModal, setIsActiveClientesModal] = useState(false);
  const [tiposCliente, setTiposCliente] = useState([]);
  const [zonasCliente, setZonasCliente] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [errorSaving, setErrorSaving] = useState("");
  const [initialLoading, setInitialLoading] = useState(false);

  const handleModalChange = useCallback(() => setActive(!active), [active]);

  const toggleTipoClienteModal = useCallback(
    () => setIsActiveTipoClienteModal(!isActiveTipoClienteModal),
    [isActiveTipoClienteModal]
  );
  const toggleZonaClienteModal = useCallback(
    () => setIsActiveZonaClienteModal(!isActiveZonaClienteModal),
    [isActiveZonaClienteModal]
  );
  const toggleClientesModal = useCallback(
    () => setIsActiveClientesModal(!isActiveClientesModal),
    [isActiveClientesModal]
  );

  useEffect(() => {
    function obtenerDescuento() {
      setInitialLoading(true);
      ApiServiceComercios.obtenerDescuento(id)
        .then(({ ok, descuento }) => {
          if (ok) {
            const { fechaInicio, fechaFin } = descuento;
            const horaInicio = !fechaInicio
              ? "00:00"
              : moment(fechaInicio).format("HH:mm");
            const horaFin = !fechaFin
              ? "00:00"
              : moment(fechaFin).format("HH:mm");

            setDescuento((s) => ({
              ...s,
              id: descuento.id,
              titulo: descuento.titulo,
              precioLista: descuento.precioListaId._id,
              estatus:
                descuento.estatus.tipo === 1 || descuento.estatus.tipo === 3
                  ? "Habilitado"
                  : "No habilitada",
              descuentoArticulo: descuento.descuento._id,
              usado: descuento.usado,
              fechaInicio,
              fechaFin,
              horaInicio,
              horaFin,
            }));
            setIsCustom([1, 3].includes(descuento.estatus.tipo) ? true : false);

            if (descuento.clientes.length > 0) {
              setClientes((s) => [
                ...s,
                ...descuento.clientes.map((i) => ({
                  editing: false,
                  clienteSelected: {
                    id: i.clienteId?._id || null,
                    nombre: i.clienteId?.nombre || "",
                  },
                  descuento: {
                    label: i.descuentoId.titulo,
                    value: i.descuentoId._id,
                  },
                  precioLista: {
                    id: i.precioListaId._id,
                    nombre: i.precioListaId.nombre,
                  },
                  tipoPrecio: i.precioListaId._id + i.clienteId?.nombre,
                })),
              ]);
            }
            if (descuento.zonasCliente.length > 0) {
              setZonasCliente((s) => [
                ...s,
                ...descuento.zonasCliente.map((i) => ({
                  editing: false,
                  descuento: {
                    label: i.descuentoId.titulo,
                    value: i.descuentoId._id,
                  },
                  precioLista: i.precioListaId
                    ? {
                        id: i.precioListaId._id,
                        nombre: i.precioListaId.nombre,
                      }
                    : { id: "", nombre: "" },
                  zonaCliente: {
                    id: i.zonaClienteId._id,
                    nombre: i.zonaClienteId.nombre,
                  },
                  tipoPrecio: i.precioListaId?._id + i.zonaClienteId.nombre,
                })),
              ]);
            }
            if (descuento.tiposCliente.length > 0) {
              setTiposCliente((s) => [
                ...s,
                ...descuento.tiposCliente.map((i) => ({
                  editing: false,
                  descuento: {
                    label: i.descuentoId.titulo,
                    value: i.descuentoId._id,
                  },
                  precioLista: {
                    id: i.precioListaId._id,
                    nombre: i.precioListaId.nombre,
                  },
                  tipoCliente: {
                    id: i.tipoClienteId._id,
                    nombre: i.tipoClienteId.nombre,
                  },
                  tipoPrecio: i.precioListaId._id + i.tipoClienteId.nombre,
                })),
              ]);
            }
          }
        })
        .finally(() => {
          setInitialLoading(false);
        });
    }
    ApiServiceComercios.obtenerPrecioListas()
      .then(({ ok, precioListas }) => {
        if (ok) {
          const willAdd = precioListas.map((i) => ({
            label: i.nombre,
            value: i._id,
          }));
          setPreciosLista(willAdd);
          if (!id) {
            setDescuento((s) => ({ ...s, precioLista: willAdd[0]["value"] }));
          }
        }
      })
      .finally(() => {
        ApiServiceComercios.obtenerDescuentos({
          sort: "titulo",
          query: "",
          orden: 1,
          estatus: JSON.stringify(["Activo"]),
          tipo: "Por producto",
          limit: 100,
        })
          .then(({ ok, descuentos }) => {
            if (ok) {
              setDescuentos(
                descuentos.map(({ titulo, _id }) => ({
                  label: titulo,
                  value: _id,
                }))
              );
              if (!id) {
                setDescuento((s) => ({
                  ...s,
                  descuentoArticulo: descuentos[0]["_id"],
                }));
              }
            }
          })
          .finally(() => setIsLoading(false));
      });
    if (id !== undefined) {
      obtenerDescuento();
    } else {
      setIsCustom(true);
    }
  }, [id]);

  function changeState(value, key) {
    if (key === "aplicacion" && value[0] === "Tipos de clientes")
      toggleTipoClienteModal();
    if (key === "aplicacion" && value[0] === "Zonas de clientes")
      toggleZonaClienteModal();
    if (key === "aplicacion" && value[0] === "Clientes") toggleClientesModal();
    if (key === "estatus" && value[0] === "No habilitada") setIsCustom(false);
    if (key === "estatus" && value[0] === "Habilitado") setIsCustom(true);
    if (["fechaInicio", "fechaFin"].includes(key)) {
      const dates = runDateTimeValidator(value, key);
      setDescuento((state) => ({
        ...state,
        ...dates,
      }));
    } else {
      setDescuento((state) => ({
        ...state,
        [key]: key === "estatus" ? value[0] : value,
      }));
    }
    value && setIsDirty(true);
  }

  function runDateTimeValidator(value, key) {
    let dates = {
      fechaInicio: descuento.fechaInicio,
      fechaFin: descuento.fechaFin,
      horaInicio: descuento.horaInicio,
      horaFin: descuento.horaFin,
    };
    dates[key] = value;

    let dateStart =
      moment(dates.fechaInicio).format("YYYY-MM-DD") + "T" + dates.horaInicio;
    dateStart = moment(dateStart).format();
    let dateEnd =
      moment(dates.fechaFin).format("YYYY-MM-DD") + "T" + dates.horaFin;
    dateEnd = moment(dateEnd).format();

    if (key === "fechaInicio") {
      if (moment(dateStart).isAfter(dateEnd)) {
        dateEnd = moment(dateStart).add(1, "day").format();
        dates["fechaFin"] = dateEnd;
      }
    }
    if (key === "fechaFin") {
      if (moment(dateStart).isAfter(dateEnd)) {
        dateStart = moment(dateEnd).subtract(1, "day").format();
        dates["fechaInicio"] = dateStart;
      }
    }
    return dates;
  }

  function handleSave() {
    let fechaInicio =
      moment(descuento.fechaInicio).format("YYYY-MM-DD") +
      "T" +
      descuento.horaInicio;
    fechaInicio = moment(fechaInicio).format();

    let fechaFin =
      moment(descuento.fechaFin).format("YYYY-MM-DD") + "T" + descuento.horaFin;
    fechaFin = moment(fechaFin).format();

    setIsCreating(true);
    setErrorSaving("");
    ApiServiceComercios.crearDescuento({
      titulo: descuento.titulo,
      fechaInicio: isCustom ? fechaInicio : null,
      fechaFin: isCustom ? fechaFin : null,
      estatus: isCustom ? 3 : 2,
      precioListaId: preciosLista.find(
        (i) => i.value === descuento.precioLista
      )["value"],
      descuento:
        descuentosOptions.find((i) => i.value === descuento.descuentoArticulo)[
          "value"
        ] || descuentosOptions[0]["value"],
      tiposCliente: tiposCliente.map((l) => ({
        tipoClienteId: l.tipoCliente.id,
        precioListaId: l.precioLista.id,
        descuentoId: l.descuento.value,
      })),
      zonasCliente: zonasCliente.map((l) => ({
        zonaClienteId: l.zonaCliente.id,
        precioListaId: l.precioLista.id,
        descuentoId: l.descuento.value,
      })),
      clientes: clientes.map((l) => ({
        clienteId: l.clienteSelected.id,
        precioListaId: l.precioLista.id,
        descuentoId: l.descuento.value,
      })),
      zona: zonaHoraria,
      tipo: 1,
      tipoAplicacion:
        zonasCliente.length === 0 &&
        tiposCliente.length === 0 &&
        clientes.length === 0
          ? 1
          : zonasCliente.length > 0 &&
            tiposCliente.length === 0 &&
            clientes.length === 0
          ? 2
          : zonasCliente.length === 0 &&
            tiposCliente.length > 0 &&
            clientes.length === 0
          ? 3
          : zonasCliente.length === 0 &&
            tiposCliente.length === 0 &&
            clientes.length > 0
          ? 4
          : 5,
    })
      .then(({ ok, message }) => {
        if (ok) {
          dispatch(toggleToast({ message }));
          setTimeout(() => {
            handleDiscard();
          }, 100);
        }
      })
      .catch((error) => {
        scrollToTop();
        if (error.response) {
          const { response } = error;
          const { data } = response;
          if (data) {
            setErrorSaving(data.error);
          }
        }
        setIsCreating(false);
      });
  }

  function eliminarDescuento() {
    setIsDeleting(true);
    ApiServiceComercios.eliminarDescuento(id)
      .then((result) => {
        if (result.ok) {
          dispatch(toggleToast({ message: result.message }));
          handleDiscard();
        }
      })
      .catch((err) => {});
  }

  function handleUpdate() {
    let fechaInicio =
      moment(descuento.fechaInicio).format("YYYY-MM-DD") +
      "T" +
      descuento.horaInicio;
    fechaInicio = moment(fechaInicio).format();

    let fechaFin =
      moment(descuento.fechaFin).format("YYYY-MM-DD") + "T" + descuento.horaFin;
    fechaFin = moment(fechaFin).format();

    setIsCreating(true);
    setErrorSaving("");
    const discount = descuentosOptions.find(
      (i) => i.value === descuento.descuentoArticulo
    );
    ApiServiceComercios.actualizarDescuento(
      {
        titulo: descuento.titulo,
        fechaInicio: isCustom ? fechaInicio : null,
        fechaFin: isCustom ? fechaFin : null,
        estatus: isCustom ? 3 : 2,
        precioListaId: preciosLista.find(
          (i) => i.value === descuento.precioLista
        )["value"],
        descuento: discount ? discount["value"] : descuentosOptions[0]["value"],
        tiposCliente: tiposCliente.map((l) => ({
          tipoClienteId: l.tipoCliente.id,
          precioListaId: l.precioLista.id,
          descuentoId: l.descuento.value,
        })),
        zonasCliente: zonasCliente.map((l) => ({
          zonaClienteId: l.zonaCliente.id,
          precioListaId: l.precioLista.id,
          descuentoId: l.descuento.value,
        })),
        clientes: clientes.map((l) => ({
          clienteId: l.clienteSelected.id,
          precioListaId: l.precioLista.id,
          descuentoId: l.descuento.value,
        })),
        zona: zonaHoraria,
        tipo: 1,
        tipoAplicacion:
          zonasCliente.length === 0 &&
          tiposCliente.length === 0 &&
          clientes.length === 0
            ? 1
            : zonasCliente.length > 0 &&
              tiposCliente.length === 0 &&
              clientes.length === 0
            ? 2
            : zonasCliente.length === 0 &&
              tiposCliente.length > 0 &&
              clientes.length === 0
            ? 3
            : zonasCliente.length === 0 &&
              tiposCliente.length === 0 &&
              clientes.length > 0
            ? 4
            : 5,
      },
      id
    )
      .then(({ ok, message }) => {
        if (ok) {
          dispatch(toggleToast({ message }));
          setTimeout(() => {
            handleDiscard();
          }, 100);
        }
      })
      .catch((error) => {
        scrollToTop();
        if (error.response) {
          const { response } = error;
          const { data } = response;
          if (data) {
            setErrorSaving(data.error);
          }
        }
        setIsCreating(false);
      });
  }

  function handleDiscard() {
    router.goBack();
  }

  function handleCustomDate() {
    setIsCustom(!isCustom);
    changeState(["Habilitado"], "estatus");
  }

  function onAddTipos(items) {
    let merged = _.unionBy(items, tiposCliente, "tipoPrecio");
    setTiposCliente(merged);
  }

  function onAddZonas(items) {
    let merged = _.unionBy(items, zonasCliente, "tipoPrecio");
    setZonasCliente(merged);
  }

  function onAddCliente(items) {
    let merged = _.unionBy(items, clientes, "tipoPrecio");
    setClientes(merged);
  }

  function onEditTipo(value) {
    let arr = [
      ...tiposCliente.map((i) => {
        return i.tipoPrecio === value ? { ...i, editing: true } : i;
      }),
    ];
    setTiposCliente(arr);
    toggleTipoClienteModal();
  }

  function onEditZona(value) {
    let arr = [
      ...zonasCliente.map((i) => {
        return i.tipoPrecio === value ? { ...i, editing: true } : i;
      }),
    ];
    setZonasCliente(arr);
    toggleZonaClienteModal();
  }

  function onEditCliente(value) {
    let arr = [
      ...clientes.map((i) => {
        return i.tipoPrecio === value ? { ...i, editing: true } : i;
      }),
    ];
    setClientes(arr);
    toggleClientesModal();
  }

  function onRemoveTipo(value) {
    setTiposCliente((s) => [...s.filter((i) => i.tipoPrecio !== value)]);
  }

  function onRemoveZona(value) {
    setZonasCliente((s) => [...s.filter((i) => i.tipoPrecio !== value)]);
  }

  function onRemoveCliente(value) {
    setClientes((s) => [...s.filter((i) => i.tipoPrecio !== value)]);
  }

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Cambios no guardados"
      saveAction={{
        content: id ? "Guardar" : "Guardar",
        onAction: id ? handleUpdate : handleSave,
        disabled: validateIsMicrosip() || isCreating || !descuento.titulo,
        loading: isCreating,
      }}
      discardAction={{
        onAction: handleDiscard,
        disabled: isCreating,
      }}
    />
  ) : null;

  if (isLoading || initialLoading) {
    return (
      <Layer
        title={id ? "Editar descuento" : "Agregar descuento"}
        forbidden={forbidden}
      >
        <ComercioDetalleSkeleton />
      </Layer>
    );
  }

  function validateIsMicrosip() {
    return id && descuento.id;
  }

  return (
    <Layer
      title={id ? "Editar descuento" : "Agregar descuento"}
      forbidden={forbidden}
    >
      <Page
        breadcrumbs={[{ content: "Descuentos", onAction: handleDiscard }]}
        title={
          id ? "Editar descuento por cliente" : "Agregar descuento por cliente"
        }
        subtitle={
          descuento.exclusive && <Badge status="success">Exclusiva</Badge>
        }
      >
        {contextualSaveBarMarkup}
        {isActiveTipoClienteModal && (
          <TipoClienteModal
            precioLista={preciosLista}
            isActive={isActiveTipoClienteModal}
            toggleModal={toggleTipoClienteModal}
            onAddTipo={onAddTipos}
            selected={tiposCliente}
            descuentos={descuentosOptions}
          />
        )}
        {isActiveZonaClienteModal && (
          <ZonaClienteModal
            precioLista={preciosLista}
            isActive={isActiveZonaClienteModal}
            toggleModal={toggleZonaClienteModal}
            onAddData={onAddZonas}
            selected={zonasCliente}
            descuentos={descuentosOptions}
          />
        )}
        {isActiveClientesModal && (
          <ClienteDescuentoModal
            precioLista={preciosLista}
            isActive={isActiveClientesModal}
            toggleModal={toggleClientesModal}
            onAddData={onAddCliente}
            selected={clientes}
            descuentos={descuentosOptions}
          />
        )}
        {validateIsMicrosip() && (
          <div className="mb-8">
            <Card>
              <Banner
                title="Descuento sincronizado desde Microsip"
                status="warning"
              >
                Para mantener la integridad de la información, los cambios de
                esta política de descuento la deberás hacer en Microsip. En la
                próxima sincronización se reflejarán los cambios.
              </Banner>
            </Card>
          </div>
        )}
        <Layout>
          <Layout.Section>
            {errorSaving && (
              <div className="mb-10">
                <Banner status="critical">{errorSaving}</Banner>
              </div>
            )}
            <Card sectioned>
              <TextField
                disabled={validateIsMicrosip()}
                label="Título"
                value={descuento.titulo}
                onChange={(value) => changeState(value, "titulo")}
                placeholder="Descuentos para mayoristas"
                helpText="Los clientes podrían ver esto al levantar sus pedidos."
              />
            </Card>
            <Card sectioned>
              <FormLayout>
                <TextStyle variation="strong">Condiciones generales</TextStyle>
                <Select
                  label="Lista de precio"
                  options={preciosLista}
                  disabled={validateIsMicrosip() || isLoading}
                  onChange={(value) => changeState(value, "precioLista")}
                  value={descuento.precioLista}
                />
                <Select
                  label="Descuento general por artículo"
                  options={descuentosOptions}
                  onChange={(value) => changeState(value, "descuentoArticulo")}
                  value={descuento.descuentoArticulo}
                  disabled={validateIsMicrosip()}
                />
              </FormLayout>
            </Card>
            <Card>
              <Card.Section>
                <FormLayout>
                  <TextStyle variation="strong">Se aplica a</TextStyle>
                  <ChoiceList
                    title=""
                    titleHidden
                    choices={aplicaciones}
                    selected={descuento.aplicacion}
                    onChange={(value) => changeState(value, "aplicacion")}
                  />
                </FormLayout>
              </Card.Section>
              {descuento.aplicacion[0] !== aplicaciones[0].value && (
                <Card.Section>
                  {descuento.aplicacion[0] === aplicaciones[1].value &&
                    tiposCliente.length > 0 && (
                      <DataTable
                        headings={[
                          "Tipo de cliente",
                          "Lista de precios",
                          "Política de descuento",
                        ]}
                        columnContentTypes={["text", "text", "text"]}
                        rows={tiposCliente.map(
                          ({
                            precioLista,
                            tipoPrecio,
                            tipoCliente,
                            descuento: { label },
                          }) => {
                            const Activator = ({ onClick }) => {
                              return (
                                <div className="flex items-center">
                                  <div className="mr-4">
                                    <TextStyle>{label}</TextStyle>
                                  </div>
                                  <Button
                                    plain
                                    icon={MobileHorizontalDotsMajor}
                                    onClick={onClick}
                                  />
                                </div>
                              );
                            };
                            const tipoCl = (
                              <TextStyle>{tipoCliente.nombre}</TextStyle>
                            );
                            const pre = (
                              <TextStyle>{precioLista.nombre}</TextStyle>
                            );
                            const desc = (
                              <CustomPopover
                                items={[
                                  {
                                    content: "Editar",
                                    onAction: () => onEditTipo(tipoPrecio),
                                    icon: EditMajor,
                                  },
                                  {
                                    content: "Quitar",
                                    onAction: () => onRemoveTipo(tipoPrecio),
                                    icon: DeleteMajor,
                                    destructive: true,
                                  },
                                ]}
                                activator={Activator}
                              />
                            );
                            return [tipoCl, pre, desc];
                          }
                        )}
                      />
                    )}

                  {descuento.aplicacion[0] === aplicaciones[2].value &&
                    zonasCliente.length > 0 && (
                      <DataTable
                        headings={[
                          "Zona de cliente",
                          "Lista de precios",
                          "Política de descuento",
                        ]}
                        columnContentTypes={["text", "text", "text"]}
                        rows={zonasCliente.map(
                          ({
                            precioLista,
                            tipoPrecio,
                            zonaCliente,
                            descuento: { label },
                          }) => {
                            const Activator = ({ onClick }) => {
                              return (
                                <div className="flex items-center">
                                  <div className="mr-4">
                                    <TextStyle>{label}</TextStyle>
                                  </div>
                                  <Button
                                    plain
                                    icon={MobileHorizontalDotsMajor}
                                    onClick={onClick}
                                  />
                                </div>
                              );
                            };
                            const tipoCl = (
                              <TextStyle>{zonaCliente.nombre}</TextStyle>
                            );
                            const pre = (
                              <TextStyle>{precioLista.nombre}</TextStyle>
                            );
                            const desc = (
                              <CustomPopover
                                items={[
                                  {
                                    content: "Editar",
                                    onAction: () => onEditZona(tipoPrecio),
                                    icon: EditMajor,
                                  },
                                  {
                                    content: "Quitar",
                                    onAction: () => onRemoveZona(tipoPrecio),
                                    icon: DeleteMajor,
                                    destructive: true,
                                  },
                                ]}
                                activator={Activator}
                              />
                            );
                            return [tipoCl, pre, desc];
                          }
                        )}
                      />
                    )}

                  {descuento.aplicacion[0] === aplicaciones[3].value &&
                    clientes.length > 0 && (
                      <DataTable
                        headings={[
                          "Cliente",
                          "Lista de precios",
                          "Política de descuento",
                        ]}
                        columnContentTypes={["text", "text", "text"]}
                        rows={clientes.map(
                          ({
                            precioLista,
                            tipoPrecio,
                            clienteSelected,
                            descuento: { label },
                          }) => {
                            const Activator = ({ onClick }) => {
                              return (
                                <div className="flex items-center">
                                  <div className="mr-4">
                                    <TextStyle>{label}</TextStyle>
                                  </div>
                                  <Button
                                    plain
                                    icon={MobileHorizontalDotsMajor}
                                    onClick={onClick}
                                  />
                                </div>
                              );
                            };
                            const tipoCl = (
                              <TextStyle>{clienteSelected.nombre}</TextStyle>
                            );
                            const pre = (
                              <TextStyle>{precioLista.nombre}</TextStyle>
                            );
                            const desc = (
                              <CustomPopover
                                items={[
                                  {
                                    content: "Editar",
                                    onAction: () => onEditCliente(tipoPrecio),
                                    icon: EditMajor,
                                  },
                                  {
                                    content: "Quitar",
                                    onAction: () => onRemoveCliente(tipoPrecio),
                                    icon: DeleteMajor,
                                    destructive: true,
                                  },
                                ]}
                                activator={Activator}
                              />
                            );
                            return [tipoCl, pre, desc];
                          }
                        )}
                      />
                    )}
                  <div className="mt-6">
                    {descuento.aplicacion[0] === aplicaciones[1].value && (
                      <Button monochrome onClick={toggleTipoClienteModal}>
                        Agregar tipos
                      </Button>
                    )}
                    {descuento.aplicacion[0] === aplicaciones[2].value && (
                      <Button monochrome onClick={toggleZonaClienteModal}>
                        Agregar zonas
                      </Button>
                    )}
                    {descuento.aplicacion[0] === aplicaciones[3].value && (
                      <Button monochrome onClick={toggleClientesModal}>
                        Agregar clientes
                      </Button>
                    )}
                  </div>
                </Card.Section>
              )}
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <Card sectioned title="Vigencia">
              <FormLayout>
                <div className="mb-10">
                  <ChoiceList
                    selected={descuento.estatus}
                    choices={[
                      { label: "Habilitado", value: "Habilitado" },
                      { label: "No habilitada", value: "No habilitada" },
                    ]}
                    onChange={(val) => {
                      changeState(val, "estatus");
                    }}
                    disabled={validateIsMicrosip()}
                  />
                </div>
                {isCustom || descuento.estatus === "Habilitado" ? (
                  <>
                    <DatePicker
                      date={descuento.fechaInicio}
                      title="Fecha de inicio"
                      onSelectDate={({ start }) =>
                        changeState(start, "fechaInicio")
                      }
                      disableBefore={moment().subtract(1, "day").toDate()}
                    />
                    <div className="timepicker-field mt-2">
                      <TextField
                        type="time"
                        value={descuento.horaInicio}
                        onChange={(value) => changeState(value, "horaInicio")}
                        prefix="GTM-5"
                      />
                    </div>
                    <div className="mt-4">
                      <DatePicker
                        date={descuento.fechaFin}
                        title="Fecha de fin"
                        onSelectDate={({ start }) =>
                          changeState(start, "fechaFin")
                        }
                        disableBefore={moment().subtract(1, "day").toDate()}
                      />
                      <div className="timepicker-field mt-2">
                        <TextField
                          type="time"
                          value={descuento.horaFin}
                          onChange={(value) => changeState(value, "horaFin")}
                          prefix="GTM-5"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <Button plain onClick={handleCustomDate}>
                    Fijar fecha de vigencia
                  </Button>
                )}
              </FormLayout>
            </Card>
            {id && (
              <Card sectioned title="Rendimiento">
                <TextStyle>
                  Se ha usado {descuento.usado} veces esta política de descuento
                </TextStyle>
              </Card>
            )}
          </Layout.Section>
        </Layout>
        <div className="flex flex-row flex-wrap justify-between my-10">
          {id && (
            <Button destructive onClick={handleModalChange}>
              Eliminar descuento
            </Button>
          )}
          <div className="flex flex-row">
            <Button disabled={isCreating} onClick={handleDiscard}>
              Descartar
            </Button>
            <div className="w-4" />
            <Button
              disabled={validateIsMicrosip() || isCreating || !descuento.titulo}
              loading={isCreating}
              primary
              onClick={id ? handleUpdate : handleSave}
            >
              {id ? "Guardar" : "Guardar"}
            </Button>
          </div>
        </div>
        <DeleteModal
          handleChange={handleModalChange}
          isLoading={isDeleting}
          handleDelete={eliminarDescuento}
          active={active}
          title="Eliminar descuento"
          body="¿Estás seguro de que deseas eliminar este descuento?"
        />
        <HelpFooter title="Descuentos" url="https://help.b2bgo.mx/descuentos" />
      </Page>
    </Layer>
  );
}

export default AddDiscountByClientScreen;
