import React from "react";
import { TextField, Button } from "@shopify/polaris";
import { ViewMajor, HideMinor } from "@shopify/polaris-icons";
import useToggle from "../../hooks/useToggle";

const PasswordField = ({
  disabled = false,
  error = null,
  value = "",
  onChange = () => {},
}) => {
  const [showPassword, togglePassword] = useToggle();
  return (
    <>
      <TextField
        autoFocus
        disabled={disabled}
        error={error}
        label="Contraseña"
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={onChange}
        placeholder="********"
        connectedRight={
          <Button
            icon={showPassword ? ViewMajor : HideMinor}
            onClick={togglePassword}
          />
        }
      />
    </>
  );
};

export default PasswordField;
