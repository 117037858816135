import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Button, Checkbox, Icon, TextStyle, Tooltip } from "@shopify/polaris";
import { NoteMajor } from "@shopify/polaris-icons";
import {
  getAuthData,
  getCotizaciones,
  getPaginationCotizaciones,
  isLoadingCotizaciones,
} from "../../reducers";
import { CustomBadge, CustomPopover } from "../../components";
import { useHistory } from "react-router";
import { NumberFormat } from "../../utils/Formats";

export default function CotizacionesContainer({
  children,
  selectedItems,
  handleSelect,
  showClientColumn = false,
}) {
  const router = useHistory();
  const isLoading = useSelector(isLoadingCotizaciones);
  const pagination = useSelector(getPaginationCotizaciones);
  const { grant_type } = useSelector(getAuthData);
  const cotizaciones = useSelector(getCotizaciones).map((item) => ({
    ...item,
    id: item._id,
    articulos:
      item.articuloFlete && item.articuloFlete.nombre
        ? item.articulos?.filter(
            (i) => i.articulo._id !== item.articuloFlete.id
          ) || []
        : item.articulos?.filter(
            (i) => i.articulo?.nombre.indexOf("Flete") < 0
          ) || [],
  }));

  function navigate(id) {
    if (showClientColumn) {
      router.push(`/admin/cotizaciones/${id}`);
    } else {
      router.push(`/cliente/cotizaciones/${id}`);
    }
  }

  function setStatus(envio) {
    const status = {
      Pendiente: "incomplete",
      Cancelado: undefined,
      Cerrado: "complete",
      Aprobado: "complete",
    };
    return status[envio];
  }

  let cotizacionesColumns = [
    {
      title: "Cotización",
      dataIndex: "folio",
      render: (folio, { id, notas }) => (
        <div className="flex flex-row items-center cursor-pointer">
          <div className="pl-6">
            <Checkbox
              value={id}
              checked={selectedItems.indexOf(id) >= 0}
              onChange={(val) => handleSelect(val, id)}
            />
          </div>
          <div
            className="py-6 pr-6 w-auto h-full no-underline"
            onClick={() => navigate(id)}
          >
            <div className="text-link">
              <TextStyle variation="strong">{folio}</TextStyle>
            </div>
          </div>
          {notas && (
            <div>
              <Tooltip content={notas}>
                <Icon color="subdued" source={NoteMajor} />
              </Tooltip>
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Fecha",
      dataIndex: "createdDate",
      render: (date, { id }) => (
        <div
          className="w-full h-full cursor-pointer p-6 whitespace-nowrap"
          onClick={() => navigate(id)}
        >
          <TextStyle>
            {moment(date).format("D MMM")} a las {moment(date).format("HH:mm")}
          </TextStyle>
        </div>
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      render: (total, { id }) => (
        <div
          className="w-full h-full cursor-pointer p-6 whitespace-nowrap"
          onClick={() => navigate(id)}
        >
          <TextStyle>
            ${Number(total).toLocaleString("en", NumberFormat)}
          </TextStyle>
        </div>
      ),
    },
    {
      title: "Envío",
      dataIndex: "envio",
      render: (envio, { id }) => (
        <div
          className="w-full h-full cursor-pointer p-6 whitespace-nowrap"
          onClick={() => navigate(id)}
        >
          <TextStyle>
            {envio ? (envio?.nombre?.length > 0 ? envio?.nombre : "--") : "--"}
          </TextStyle>
        </div>
      ),
    },
    {
      title: "Estatus",
      dataIndex: "estatus",
      render: (estatus, { id }) => (
        <div
          className="w-full h-full cursor-pointer p-6 whitespace-nowrap"
          onClick={() => navigate(id)}
        >
          <CustomBadge
            status={estatus?.nombre || ""}
            progress={setStatus(estatus?.nombre)}
          />
        </div>
      ),
    },
    {
      title: "Artículos",
      dataIndex: "articulos",
      render: (articulos) => {
        const articles = articulos.map((articulo) => {
          return {
            content:
              articulo.articulo?.nombre || articulo.articulo?.clave || "",
            onAction: () => {
              grant_type === "COMERCIO" &&
                router.push(`/admin/articulos/${articulo.articulo._id}`);
            },
          };
        });
        const Activator = ({ onClick }) => {
          return (
            <div className="no-underline text-gray-600 ">
              <Button
                onClick={onClick}
                disclosure
                plain
                textAlign="left"
                monochrome
              >
                {" "}
                {articulos.length} artículo
                {`${articulos.length > 1 ? "s" : ""}`}
              </Button>
            </div>
          );
        };
        return (
          <div className="w-full h-full cursor-pointer p-6  whitespace-nowrap">
            <CustomPopover items={articles} activator={Activator} />
          </div>
        );
      },
    },
  ];

  if (showClientColumn) {
    cotizacionesColumns.splice(2, 0, {
      title: "Cliente",
      dataIndex: "cliente",
      render: (cliente, { _id }) => (
        <div
          className="w-full h-full cursor-pointer p-6 whitespace-nowrap"
          onClick={() => navigate(_id)}
        >
          <TextStyle>{cliente?.nombre || "--"}</TextStyle>
        </div>
      ),
    });
  }

  return (
    <>
      {children({ isLoading, pagination, cotizaciones, cotizacionesColumns })}
    </>
  );
}
