export default function NotActiveStoreScreen() {
  return (
    <div className="h-screen flex flex-col justify-center items-center h-full pb-20 px-10 bg-white">
      <p
        style={{
          marginTop:125,
          fontSize: 28,
          fontWeight: 800,
          color: "#212B36",
          textAlign: "center",
        }}
      >
        Lo sentimos, esta tienda regresará pronto
      </p>
      <p
        style={{
          fontSize: 20,
          color: "#202123",
          marginTop: 20,
          marginBottom: 125,
          textAlign: "center",
        }}
      >
        Intenta ponerte en contacto con la tienda directamente.
      </p>
      <img
        style={{
          width: 534,
          maxHeight: 200,
        }}
        src="/not-store-page.png"
        alt="not-store-page"
      />
      <p
        style={{
          marginTop:70,
          fontSize: 18,
          fontWeight: 700,
          color: "#212B36",
          textAlign: "center",
        }}
      >
       ¿Eres el propietario de esta tienda?
      </p>
      <p
        style={{
          fontSize: 14,
          color: "#202123",
          marginTop: 8,
          marginBottom: 125,
          textAlign: "center",
        }}
      >
     Contacta a tu  consultor certificado Microsip.
      </p>
    </div>
  );
}
