import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Checkbox,
  FormLayout,
  Modal,
  TextField,
} from "@shopify/polaris";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { useDispatch } from "react-redux";
import { toggleToast } from "../../actions/InteractiveActions";

const SeguimientoModal = ({
  activatorModal,
  closeModal,
  pedidoId,
  tracking,
  updateTracking,
}) => {
  const dispatch = useDispatch();
  const [inputNumeroSeg, setInputNumeroSeg] = useState(tracking?.key || "");
  const [inputTransporte, setInputTransporte] = useState(
    tracking?.id?.name || ""
  );
  const [urlSeguimiento, setUrlSeguimiento] = useState(
    tracking?.id?.name === "Otros" ? tracking?.url : ""
  );
  const [mandarCorreo, setMandarCorreo] = useState(false);

  const [transportOptions, setTransportOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [transportSelected, setTransportSelected] = useState([
    tracking?.id?._id,
  ]);

  const [isLoading, setIsLoading] = useState(false);

  const updateTextField = (value) => {
    setInputTransporte(value);
    if (value === "") {
      setDeselectedOptions(transportOptions);
      return;
    }

    const filterRegex = new RegExp(value, "i");
    const result = transportOptions.filter((opt) =>
      opt.label.match(filterRegex)
    );
    setDeselectedOptions(result);
  };

  const textField = (
    <Autocomplete.TextField
      label="Empresa de transporte"
      value={inputTransporte}
      onChange={updateTextField}
    />
  );

  const updateAutocomplete = (value) => {
    setTransportSelected(value);
    // Manda el titulo del transporte elegido en el input
    setInputTransporte(
      transportOptions.find((item) => item.value === value[0]).label
    );
  };

  const updateSguimiento = () => {
    // Busca la url del transporte seleccionado
    let { url } = transportOptions.find(
      (item) => item.value === transportSelected[0]
    );

    let urlOtro = urlSeguimiento.includes("https://") ? urlSeguimiento : `https://${urlSeguimiento}`;

    // Estado a mandar en la DB
    const seguimiento = {
      sendEmail: mandarCorreo,
      tracking: {
        status: "supplied",
        key: inputNumeroSeg,
        id: transportSelected[0],
        url: inputTransporte === "Otros" ? urlOtro : url,
      },
    };

    // Actualizando en la DB
    setIsLoading(true);
    ApiServiceComercios.actualizarPedido(seguimiento, pedidoId)
      .then((res) => {
        updateTracking();
        dispatch(toggleToast({ message: res.message }));
        closeModal();
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    ApiServiceComercios.obtenerTrackingList().then(({ trackings }) => {
      let arr = trackings.map((item) => ({
        label: item.name,
        value: item._id,
        url: item.url,
      }));

      setTransportOptions(arr);
      setDeselectedOptions(arr);
    });
  }, []);

  return (
    <Modal
      title="Agregar número de seguimiento"
      open={activatorModal}
      onClose={closeModal}
      primaryAction={{
        content: "Agregar",
        onAction: updateSguimiento,
        loading: isLoading,
        disabled: inputNumeroSeg === "" || inputTransporte === "",
      }}
      secondaryActions={[
        {
          content: "Cancelar",
          onAction: closeModal,
        },
      ]}
    >
      <Modal.Section>
        <div className="pr-7 pb-4">
          <FormLayout.Group>
            <TextField
              label="Número de seguimiento"
              value={inputNumeroSeg}
              onChange={setInputNumeroSeg}
            />

            <Autocomplete
              textField={textField}
              listTitle="Empresa de transporte"
              selected={transportSelected}
              options={deselectedOptions}
              onSelect={updateAutocomplete}
            />
          </FormLayout.Group>

          <FormLayout.Group>
            {inputTransporte === "Otros" && (
              <TextField
                label="URL de seguimiento"
                helpText="Introduce el enlace de la página de seguimiento"
                value={urlSeguimiento}
                onChange={setUrlSeguimiento}
              />
            )}
          </FormLayout.Group>

          <FormLayout.Group>
            <Checkbox
              label="Enviar correo electrónico de notificación al cliente"
              checked={mandarCorreo}
              onChange={(value) => setMandarCorreo(value)}
            />
          </FormLayout.Group>
        </div>
      </Modal.Section>
    </Modal>
  );
};

export default SeguimientoModal;
