import React, { useState, useCallback, useEffect } from "react";
import {
  ResourceList,
  Filters,
  ChoiceList,
  EmptyState,
  TextStyle,
  Button,
  Checkbox,
  Card,
  Link,
  Tooltip,
  Icon,
} from "@shopify/polaris";
import { NoteMajor } from "@shopify/polaris-icons";
import moment from "moment";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import {
  CustomBadge,
  CustomPopover,
  ChoiceDate,
  AntPagination,
} from "../../components";
import { changePage } from "../../actions/PedidosActions";
import { NumberFormat } from "../../utils/Formats";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import useGetVendors from "../../hooks/useGetVendors";
import "./PreliminaresClienteList.css";
import { Table } from "antd";
import SkeletonTable from "../SkeletonTable";
import { PEDIDO_STATUS, CREATED_BY, ORDER_BY } from "../../utils/constants";
import { useDispatch } from "react-redux";

export default function PreliminaresClienteList({
  orderSelected,
  handleOrderSelectedChange,
  handleOrderSelectedRemove,
  pagination,
  preliminares,
  isLoading,
  queryValue,
  orden,
  handleFiltersQueryChange,
  handleQueryValueRemove,
  estatusPedido,
  vendorPedido,
  creatorSelected,
  shippingMethod,
  handleOrdenChange,
  handleEstatusPedidoChange,
  handleEstatusPedidoRemove,
  handleVendorPedidoChange,
  handleVendorPedidoRemove,
  handleShippingMethodChange,
  handleShippingMethodRemove,
  handleCreatorSelectedChange,
  handleCreatorSelectedRemove,
  fecha,
  handleDateRemove,
  handleRadioChange,
  selectedDates,
  setSelectedDates,
  disabledAddButton = false,
}) {
  const dispatch = useDispatch();
  const router = useHistory();
  const [selectedItems, setSelectedItems] = useState([]);
  const [envios, setEnvios] = useState([]);
  const { vendors } = useGetVendors();

  const handleFiltersClearAll = useCallback(() => {
    handleEstatusPedidoRemove();
    handleVendorPedidoRemove();
    handleQueryValueRemove();
    handleShippingMethodRemove();
    handleOrderSelectedRemove();
    handleCreatorSelectedRemove();
    handleDateRemove();
  }, [
    handleQueryValueRemove,
    handleEstatusPedidoRemove,
    handleVendorPedidoRemove,
    handleShippingMethodRemove,
    handleOrderSelectedRemove,
    handleCreatorSelectedRemove,
    handleDateRemove,
  ]);

  useEffect(() => {
    ApiServiceComercios.obtenerEnvios()
      .then((result) => {
        if (result.ok) {
          setEnvios(result.envios);
        }
      })
      .catch((err) => {});
    return () => null;
  }, []);

  function handleSelectAll(values = []) {
    setSelectedItems(values);
  }
  function handleSelect(selected, val) {
    if (selected) {
      setSelectedItems((state) => [...new Set(state.concat(val))]);
    } else {
      setSelectedItems(selectedItems.filter((sel) => val !== sel));
    }
  }

  function setStatus(envio) {
    const status = {
      Pendiente: "incomplete",
      Surtido: "complete",
      Cancelado: undefined,
      Cerrado: "complete",
    };
    return status[envio];
  }

  function navigate(id) {
    router.push(`/cliente/pedidos/preliminares/${id}`);
  }

  let appliedFilters = [];
  if (!isEmpty(shippingMethod)) {
    const key = "shippingMethod";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, shippingMethod),
      onRemove: handleShippingMethodRemove,
    });
  }

  if (!isEmpty(estatusPedido)) {
    const key = "estatusPedido";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, estatusPedido),
      onRemove: handleEstatusPedidoRemove,
    });
  }

  if (!isEmpty(creatorSelected)) {
    const key = "createdBy";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, creatorSelected),
      onRemove: handleCreatorSelectedRemove,
    });
  }

  if (!isEmpty(vendorPedido)) {
    const key = "vendorPedido";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, vendorPedido),
      onRemove: handleVendorPedidoRemove,
    });
  }

  if (!isEmpty(fecha)) {
    const key = "dateStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, fecha),
      onRemove: handleDateRemove,
    });
  }

  const emptyStateMarkup = (
    <EmptyState
      heading="Tus pedidos preliminares aparecerán aquí"
      action={{
        content: "Crear preliminar",
        url: "/cliente/pedidos/preliminares/agregar",
        disabled: disabledAddButton,
      }}
      image="/emptystate-files-n.png"
    >
      <p>
        Los pedidos preliminares son documentos que pueden guardarse, editarse,
        agregar más productos y finalmente convertir en un pedido.
      </p>
    </EmptyState>
  );

  const filters = [
    {
      key: "estatusPedido",
      label: "Estatus",
      filter: (
        <ChoiceList
          title="Estatus"
          titleHidden
          choices={PEDIDO_STATUS}
          selected={estatusPedido || []}
          onChange={handleEstatusPedidoChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "shippingMethod",
      label: "Vía de embarque",
      filter: (
        <ChoiceList
          title="Vía de embarque"
          titleHidden
          choices={envios.map((envio) => ({
            label: envio.nombre,
            value: envio.nombre,
          }))}
          selected={shippingMethod || []}
          onChange={handleShippingMethodChange}
          allowMultiple
        />
      ),
    },
    {
      key: "createdBy",
      label: "Creado",
      filter: (
        <ChoiceList
          title="Creado"
          titleHidden
          choices={CREATED_BY}
          selected={creatorSelected || []}
          onChange={handleCreatorSelectedChange}
          allowMultiple
        />
      ),
      shortcut: false,
    },
    {
      key: "vendorPedido",
      label: "Vendedor",
      filter: (
        <ChoiceList
          title="Vendedor"
          titleHidden
          choices={vendors.map((vendor) => ({
            label: vendor.label,
            value: vendor.value,
          }))}
          selected={vendorPedido || []}
          onChange={handleVendorPedidoChange}
          allowMultiple
        />
      ),
    },
    {
      key: "orderSelected",
      label: "Ordenar",
      filter: (
        <div className="flex flex-col">
          <ChoiceList
            title="Ordenar"
            titleHidden
            choices={ORDER_BY}
            selected={orderSelected || []}
            onChange={handleOrderSelectedChange}
          />
          <div className="flex w-full border border-t-1 border-b-0 border-r-0 border-l-0 border-gray-400">
            <ChoiceList
              title="Ordenar"
              titleHidden
              choices={[
                { label: "Ascendente", value: "1" },
                { label: "Descendente", value: "-1" },
              ]}
              selected={orden}
              onChange={handleOrdenChange}
            />
          </div>
        </div>
      ),
      shortcut: true,
    },
    {
      shortcut: false,
      key: "dateStatus",
      label: "Fecha",
      filter: (
        <ChoiceDate
          value={fecha}
          handleChange={handleRadioChange}
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          past
        />
      ),
    },
  ];

  return (
    <div>
      <Card>
        <Card.Section>
          <Filters
            queryPlaceholder="Buscar pedido"
            queryValue={queryValue}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
            filters={filters}
            appliedFilters={appliedFilters}
          />
        </Card.Section>
        <div>
          <div className="selectable-items">
            <ResourceList
              totalItemsCount={pagination.total}
              resourceName={{ singular: "preliminar", plural: "preliminares" }}
              items={preliminares}
              selectable
              selectedItems={selectedItems}
              onSelectionChange={handleSelectAll}
              renderItem={(_) => null}
            />
          </div>
          {isLoading ? (
            <SkeletonTable hasCheckbox headingsCount={5} rowsCount={5} />
          ) : (
            <div className="ant-table-styles-order">
              <Table
                rowKey="id"
                dataSource={preliminares}
                scroll={{ scrollToFirstRowOnChange: true, x: "100%" }}
                pagination={false}
                locale={{ emptyText: emptyStateMarkup }}
                columns={[
                  {
                    title: "Preliminar",
                    dataIndex: "folio",
                    render: (folio, { id, notas }) => (
                      <div className="flex flex-row items-center cursor-pointer">
                        <div className="pl-6">
                          <Checkbox
                            value={id}
                            checked={selectedItems.indexOf(id) >= 0}
                            onChange={(val) => handleSelect(val, id)}
                          />
                        </div>
                        <div
                          className="py-6 pr-6 h-full"
                          onClick={() => navigate(id)}
                        >
                          <Link>
                            <TextStyle variation="strong">{folio}</TextStyle>
                          </Link>
                        </div>
                        {notas && (
                          <div>
                            <Tooltip content={notas}>
                              <Icon color="subdued" source={NoteMajor} />
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    ),
                  },
                  {
                    title: "Fecha",
                    dataIndex: "createdDate",
                    render: (date, { id }) => (
                      <div
                        className="w-full h-full cursor-pointer p-6 whitespace-nowrap"
                        onClick={() => navigate(id)}
                      >
                        <TextStyle>
                          {moment(date).format("D MMM")} a las{" "}
                          {moment(date).format("HH:mm")}
                        </TextStyle>
                      </div>
                    ),
                  },
                  {
                    title: "Total",
                    dataIndex: "total",
                    render: (total, { id }) => (
                      <div
                        className="w-full h-full cursor-pointer p-6 whitespace-nowrap"
                        onClick={() => navigate(id)}
                      >
                        <TextStyle>
                          ${Number(total).toLocaleString("en", NumberFormat)}
                        </TextStyle>
                      </div>
                    ),
                  },
                  {
                    title: "Estatus",
                    dataIndex: "estatus",
                    render: (estatus, { id }) => (
                      <div
                        className="w-full h-full cursor-pointer p-6 whitespace-nowrap"
                        onClick={() => navigate(id)}
                      >
                        <CustomBadge
                          status={estatus.nombre}
                          progress={setStatus(estatus.nombre)}
                        />
                      </div>
                    ),
                  },
                  {
                    title: "Embarque",
                    dataIndex: "envio",
                    render: (envio, { id }) => (
                      <div
                        className="w-full h-full cursor-pointer p-6 whitespace-nowrap"
                        onClick={() => navigate(id)}
                      >
                        <TextStyle>
                          {envio
                            ? envio?.nombre?.length > 0
                              ? envio?.nombre
                              : "--"
                            : "--"}
                        </TextStyle>
                      </div>
                    ),
                  },
                  {
                    title: "Artículos",
                    dataIndex: "articulos",
                    render: (articulos) => {
                      const articles = articulos.map((articulo) => {
                        return {
                          content:
                            articulo.articulo?.nombre ||
                            articulo.articulo?.clave ||
                            "",
                          onAction: () => {},
                        };
                      });
                      const Activator = ({ onClick }) => {
                        return (
                          <div className="no-underline text-gray-600">
                            <Button
                              onClick={onClick}
                              disclosure
                              plain
                              textAlign="left"
                              monochrome
                            >
                              {" "}
                              {articulos.length} artículo
                              {`${articulos.length > 1 ? "s" : ""}`}
                            </Button>
                          </div>
                        );
                      };
                      return (
                        <div className="w-full h-full cursor-pointer p-6 whitespace-nowrap">
                          <CustomPopover
                            items={articles}
                            activator={Activator}
                          />
                        </div>
                      );
                    },
                  },
                ]}
              />
            </div>
          )}
        </div>
        <div className="flex justify-center py-6 pagination px-10">
          <AntPagination
            total={pagination.total}
            current={pagination.currentPage + 1}
            pageSize={pagination.sizePerPage}
            onChange={(page) => {
              dispatch(changePage(page - 1));
            }}
          />
        </div>
      </Card>
    </div>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "shippingMethod":
        return value.map((val) => `Vía de embarque ${val}`).join(", ");
      case "estatusPedido":
        return value.map((val) => `Estatus ${val}`).join(", ");
      case "creatorSelected":
        return value.map((val) => `Creado por ${val}`).join(", ");
      case "vendorPedido":
        return `Vendedor ${vendors
          .filter((i) => value.some((j) => j === i.value))
          .map((i) => i.label)
          .join(", ")}`;
      case "dateStatus":
        return `${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null || value === "todos";
    }
  }
}

PreliminaresClienteList.propTypes = {
  tabSelected: PropTypes.number,
  orden: PropTypes.array,
  orderSelected: PropTypes.array,
  pedidos: PropTypes.array,
  isLoading: PropTypes.bool,
  pagination: PropTypes.object,
  queryValue: PropTypes.string,
  estatusPedido: PropTypes.array,
  vendorPedido: PropTypes.array,
  shippingMethod: PropTypes.array,
  creatorSelected: PropTypes.array,
  handleOrdenChange: PropTypes.func,
  handleOrderSelectedChange: PropTypes.func,
  handleOrderSelectedRemove: PropTypes.func,
  handleFiltersQueryChange: PropTypes.func,
  handleQueryValueRemove: PropTypes.func,
  handleEstatusPedidoChange: PropTypes.func,
  handleEstatusPedidoRemove: PropTypes.func,
  handleVendorPedidoChange: PropTypes.func,
  handleVendorPedidoRemove: PropTypes.func,
  handleCreatorSelectedChange: PropTypes.func,
  handleCreatorSelectedRemove: PropTypes.func,
};

PreliminaresClienteList.defaultProps = {
  tabSelected: 0,
  orden: [],
  orderSelected: [],
  preliminares: [],
  isLoading: false,
  pagination: {},
  queryValue: "",
  estatusPedido: [],
  vendorPedido: [],
  shippingMethod: [],
  creatorSelected: [],
  handleOrdenChange: () => {},
  handleOrderSelectedChange: () => {},
  handleOrderSelectedRemove: () => {},
  handleFiltersQueryChange: () => {},
  handleQueryValueRemove: () => {},
  handleEstatusPedidoChange: () => {},
  handleEstatusPedidoRemove: () => {},
  handleVendorPedidoChange: () => {},
  handleVendorPedidoRemove: () => {},
  handleShippingMethodChange: () => {},
  handleShippingMethodRemove: () => {},
  handleCreatorSelectedChange: () => {},
  handleCreatorSelectedRemove: () => {},
};
