import {
  Banner,
  Button,
  ButtonGroup,
  Card,
  EmptyState,
  FooterHelp,
  Icon,
  Layout,
  Link,
  List,
  Loading,
  Page,
  TextField,
} from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import _, { sum } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { clearArticulos } from "../../actions/ArticulosActions";
import { toggleToast } from "../../actions/InteractiveActions";
import { addClientInfo } from "../../actions/ShoppingCartActions";
import {
  ApproveCard,
  ArticuloItem,
  BuscarProductoModal,
  ClientSection,
  ComercioDetalleSkeleton,
  CustomerStatusBanner,
  EditContactDirectionModal,
  FooterCliente,
  Layer,
  PreventTransitionPrompt,
} from "../../components";
import { VendorAutocomplete } from "../../components/common/Index";
import useGetCurrencies from "../../hooks/useGetCurrencies";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import useDebounce from "../../services/useDebounce";
import { getDiscounts, getShippingFees } from "../../utils/orderValidations";
import { scrollToTop } from "../../utils/windowActions";
import "./AddPedidoScreenStyles.css";

export default function AddPedidoScreen({ forbidden }) {
  const router = useHistory();
  const dispatch = useDispatch();
  const params = useLocation().state;
  const selectArticulosRef = useRef();
  const handleModalChangeRef = useRef();

  const [isBlocking, setIsBlocking] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isModalActive, setIsModalActive] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [vendorInputValue, setVendorInputValue] = useState("");

  const [selectedArticulos, setSelectedArticulos] = useState([]);
  const [articleCount, setArticleCount] = useState([]);
  const [initialSearch, setInitialSearch] = useState(true);
  const [selectedVendors, setSelectedVendors] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [errorArticulos, setErrorArticulos] = useState({
    message: "",
    articulos: [],
  });
  const [mostrarExistencia, setMostrarExistencia] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [note, setNote] = useState("");
  const [isEditingDirection, setIsEditingDirection] = useState(false);
  const [quotationId, setQuotationId] = useState(null);
  const [draftOrderId, setDraftOrderId] = useState(null);

  const [clienteDB, setCliente] = useState(null);
  const [impuestoDB, setImpuestoDB] = useState(null);
  const [totales, setTotales] = useState([]);

  const [porPromosGen, setPorPromosGen] = useState([]);
  const [porVolumen, setPorVolumen] = useState([]);
  const [porMaximo, setPorMaximo] = useState([]);
  const [editDirectionName, setEditDirectionName] = useState("envío");
  const [verifyLimitExceeded, setVerifyLimitExceeded] = useState(false);
  const [quantityError, setQuantityError] = useState([]);
  const [shippingFees, setShippingFees] = useState([]);
  const [selectedShippmentFee, setSelectedShippmentFee] = useState();
  const [isShowingFees, setIsShowingFees] = useState(false);
  const [productDiscounts, setProductDiscounts] = useState([]);
  const [priceToUseId, setPriceToUseId] = useState(null);
  const { currencies } = useGetCurrencies();
  const [isActivePayment, setIsActivePayment] = useState(false);
  const [usePaymentsInOrders, setUsePaymentsInOrders] = useState(false);
  const handleDirectionModal = useCallback(
    () => setIsOpenModal(!isOpenModal),
    [isOpenModal]
  );
  const handleChangeNote = useCallback((newValue) => {
    setNote(newValue);
    setIsBlocking(true);
  }, []);
  const updateText = useCallback((value) => setInputValue(value), []);

  const debouncedSearchTerm = useDebounce(inputValue, 900);

  useEffect(() => {
    window.onbeforeunload = (e) => e;
    return () => (window.onbeforeunload = null);
  }, []);

  function selectArticulos(items, block = true) {
    block && setIsBlocking(true);
    let merged = _.unionBy(selectedArticulos, items, "_id");
    merged = merged.filter((i) => i.precios && i.precios.length > 0);
    items = items.filter((i) => i.precios && i.precios.length > 0);
    dispatch(clearArticulos());
    setSelectedArticulos(merged);
    setArticleCount((state) => [
      ...new Set(
        state.concat(
          items.map((item) => ({
            id: item._id,
            precio: item.precios[0]?.precio || 0,
            cantidad: item.cantidad ? item.cantidad : "1",
            total:
              (item.cantidad ? item.cantidad : 1) *
              (item.precios[0]?.precio || 1),
          }))
        )
      ),
    ]);
  }

  useEffect(() => {
    selectArticulosRef.current = selectArticulos;
    handleModalChangeRef.current = handleModalChange;
  });

  function handleModalChange() {
    if (!initialSearch) {
      dispatch(clearArticulos());
      setIsModalActive(!isModalActive);
    }
  }

  useEffect(() => handleModalChangeRef.current(), [debouncedSearchTerm]);

  useEffect(() => {
    dispatch(clearArticulos());
    setInitialSearch(false);

    function fetchPedidoDatos() {
      ApiServiceComercios.obtenerPedidoDatos()
        .then(
          ({
            ok,
            cliente,
            descuentos,
            impuesto,
            mostrarExistenciaProducto,
            verifyLimitExceeded,
            isActivePayment,
            usePaymentsInOrders,
          }) => {
            if (ok) {
              setIsActivePayment(isActivePayment);
              setUsePaymentsInOrders(usePaymentsInOrders);
              if (cliente?.vendedor?._id) {
                setSelectedVendors([cliente?.vendedor?._id]);
                setVendorInputValue(cliente?.vendedor?._id);
              }
              let currency = cliente.moneda;

              let shippingFees = getShippingFees(
                cliente?.envio?.shippingFees,
                currency
              );

              setCliente(cliente);
              setImpuestoDB(impuesto);
              setMostrarExistencia(mostrarExistenciaProducto);
              setVerifyLimitExceeded(verifyLimitExceeded);
              setShippingFees(shippingFees);

              const {
                promos,
                maximos,
                volumenes,
                productsDiscount,
                priceToUse,
              } = getDiscounts(descuentos, cliente);
              setPorPromosGen(promos);
              setPorVolumen(volumenes);
              setPorMaximo(maximos);
              setProductDiscounts(productsDiscount);
              setPriceToUseId(priceToUse);

              dispatch(
                addClientInfo({
                  limiteCredito: cliente?.limite_credito,
                  clienteEnvio: cliente?.envio,
                  impuesto,
                  moneda: currency,
                  customerData: cliente,
                  shippingFees,
                  discounts: productsDiscount,
                  priceToUse,
                  isActivePayment,
                  vendedor: cliente?.vendedor,
                  status_microsip: cliente?.status_microsip,
                })
              );
            }
          }
        )
        .finally(() => setIsLoading(false));
    }

    setIsBlocking(false);
    if (params) {
      async function fetchData() {
        if (params.quotationId) {
          setQuotationId(params.quotationId);
        }
        if (params.draftOrderId) {
          setDraftOrderId(params.draftOrderId);
        }

        if (params.shippingFeeId) {
          setSelectedShippmentFee(params.shippingFeeId);
        }

        if (params.vendorId) {
          setVendorInputValue(params.vendorId);
        }

        const art = params.articulos;
        const artIds = art.map((i) => i._id);
        ApiServiceComercios.obtenerArticulos(
          {
            spectIds: artIds,
            sort: "nombre",
            query: "",
            existencia: -1,
            orden: 1,
            limit: artIds.length,
            toCreateOrder: true,
            idCliente: params.clienteId,
            almacenId: params.almacenId,
          },
          true
        )
          .then(({ articulos }) => {
            selectArticulosRef.current(
              params.articulos.map((i) => ({
                ...i,
                ...articulos.find((j) => j._id.toString() === i._id.toString()),
                impuestos: articulos.find(
                  (j) => j._id.toString() === i._id.toString()
                )?.impuestos,
                id: i._id,
                _id: i._id,
                cantidad: i.cantidad,
                notas: i.notas || "",
              })),
              false
            );
          })
          .catch((err) => {
            selectArticulosRef.current(
              params.articulos.map((i) => ({
                ...i,
                id: i._id,
                _id: i._id,
                cantidad: i.cantidad,
                notas: i.notas || "",
              })),
              false
            );
          })
          .finally(() => {
            fetchPedidoDatos();
          });
      }
      fetchData();
      params.notas && setNote(params.notas);
      setIsLoading(true);
    } else {
      fetchPedidoDatos();
    }
  }, [params, dispatch]);

  function onChangeArticleValue(value, id, total) {
    let articles = [...articleCount];
    articles.forEach((obj) => {
      if (obj.id === id) {
        obj.cantidad = value;
      }
    });
    setArticleCount(articles);
    setTotales(totales.map((i) => (i.articuloId === id ? { ...i, total } : i)));
  }

  function onRemoveArticulo(id) {
    let articulosIds = articleCount.filter((item) => item.id !== id);
    let articulos = selectedArticulos.filter((item) => item._id !== id);
    setSelectedArticulos(articulos);
    setArticleCount(articulosIds);
    setTotales(totales.filter((i) => i.articuloId !== id));
  }

  function addNoteToArticle(note, id) {
    const selectedArt = [...selectedArticulos];
    selectedArt.forEach((obj) => {
      if (obj.id === id) {
        obj["notas"] = note;
      }
    });
    setSelectedArticulos(selectedArticulos);
  }

  function getOrderData() {
    let shippingFeeId = null;

    if (selectedShippmentFee) {
      const priceShippingFee = shippingFees.find(
        (i) => i._id === selectedShippmentFee
      );
      shippingFeeId = priceShippingFee._id;
    }

    const articulos = selectedArticulos.map((ar) => ({
      articulo: ar._id,
      notas: ar.notas || "",
      cantidad: Number(articleCount.find((art) => art.id === ar._id).cantidad),
    }));
    const data = {
      articulos,
      notas: note,
      vendorId: vendorInputValue,
      shippingFeeId,
    };

    return {
      orderData: data,
      quotationId,
      draftOrderId,
    };
  }

  function crearPedido() {
    setIsCreating(true);
    dismissWarning();
    setIsBlocking(false);
    let shippingFeeId = null;

    if (selectedShippmentFee) {
      const priceShippingFee = shippingFees.find(
        (i) => i._id === selectedShippmentFee
      );
      shippingFeeId = priceShippingFee._id;
    }

    const articulos = selectedArticulos.map((ar) => ({
      articulo: ar._id,
      notas: ar.notas || "",
      cantidad: Number(articleCount.find((art) => art.id === ar._id).cantidad),
    }));

    const data = {
      articulos,
      notas: note,
      vendorId: vendorInputValue,
      shippingFeeId,
    };

    ApiServiceComercios.crearPedido(data, null, quotationId, draftOrderId)
      .then((result) => {
        if (result.ok) {
          dispatch(toggleToast({ message: result.message }));
          router.push(`/cliente/pedidos/${result.id}/gracias`);
        } else {
          setIsCreating(false);
        }
      })
      .catch((err) => {
        setIsBlocking(true);
        if (err?.response?.data?.message) {
          setErrorArticulos({
            message: err.response?.data?.message || "",
            articulos: err.response?.data?.articulos || [],
          });
        }
        scrollToTop();
        setIsCreating(false);
      });
  }

  function dismissWarning() {
    setErrorArticulos({
      message: "",
      articulos: [],
    });
  }

  const willReturnInitialData = useCallback((totalArt, articuloId) => {
    if (totalArt && totalArt > 0) {
      setTotales((prevState) =>
        prevState.find((i) => i.articuloId === articuloId)
          ? prevState.map((i) =>
              i.articuloId === articuloId ? { ...i, total: totalArt } : i
            )
          : [...prevState, { total: totalArt, articuloId }]
      );
    }
  }, []);

  function handleSubTotalProductos() {
    return totales.reduce((prev, current) => prev + current.total, 0);
  }

  function handleImpuesto() {
    // Precio de envío
    const clientePrecioEnvio = clienteDB?.envio?.precio || 0;

    // Impuesto del comercio
    const impuesto = impuestoDB?.impuesto || 16;

    // Valida si se va a cobrar impuesto en el costo de envío
    const cobrarEnvio = impuestoDB?.cobrarEnvio || false;

    // Obtiene los impuestos de los artículos seleccionados
    const totalesWithArticle = totales?.map((i) => ({
      ...i,
      impuestos: selectedArticulos?.find((j) => j.id === i.articuloId)
        ?.impuestos,
    }));
    // Calcula los impuestos al producto y los agrega a subTotalEachProduct
    let subTotalEachProduct = [];
    totalesWithArticle.forEach((i) => {
      const total = i.total;
      const impuestos = i.impuestos?.filter((j) => j.porcentaje > 0) || [];
      let summa = 0;
      impuestos.forEach((j) => (summa = summa + total * (j.porcentaje / 100)));
      subTotalEachProduct.push(
        Math.round((summa + Number.EPSILON) * 100) / 100
      );
    });

    // Calcula el impuesto aplicado al precio de envío
    let precioEnvio = 0;
    if (selectedShippmentFee) {
      const priceShippingFee = shippingFees.find(
        (i) => i._id === selectedShippmentFee
      ).price;
      precioEnvio = cobrarEnvio
        ? priceShippingFee * (impuesto / 100)
        : priceShippingFee - handlePrecioEnvio();
    } else {
      // Valida el impuesto aplicado al precio de envío
      precioEnvio = cobrarEnvio
        ? clientePrecioEnvio * (impuesto / 100)
        : clientePrecioEnvio - handlePrecioEnvio();
    }

    // Regresa la suma de los impuestos de los productos y el precio de envío
    return sum(subTotalEachProduct) + precioEnvio;
  }

  function handlePrecioEnvio() {
    // Precio de envío
    const clientePrecioEnvio = clienteDB?.envio?.precio || 0;
    // Impuesto del comercio
    const impuesto = impuestoDB?.impuesto || 16;
    // Valida si se va a cobrar impuesto en el costo de envío
    const cobrarEnvio = impuestoDB?.cobrarEnvio || false;
    // Calcula el costo de envío sin impuesto aplicado
    let costoEnvio = 0;
    if (selectedShippmentFee) {
      const priceShippingFee = shippingFees.find(
        (i) => i._id === selectedShippmentFee
      ).price;

      costoEnvio = cobrarEnvio
        ? priceShippingFee
        : (1 * priceShippingFee) / (1 + impuesto / 100);
    } else {
      costoEnvio = cobrarEnvio
        ? clientePrecioEnvio
        : (1 * clientePrecioEnvio) / (1 + impuesto / 100);
    }

    return costoEnvio;
  }

  function handleTotal() {
    const subTotalPedido = handleSubTotalProductos();
    const precioEnvio = handlePrecioEnvio();
    const impuesto = handleImpuesto();
    const sumTotal = sum([subTotalPedido, precioEnvio, impuesto]);
    return sumTotal;
  }

  function handleSaveDirection(directionEdited) {
    setIsEditingDirection(true);
    handleDirectionModal();
    const direction = clienteDB.direcciones.find(
      (i) => i.consignatario_id === directionEdited.consignatario_id
    );
    const dirIndex = clienteDB.direcciones.findIndex(
      (i) => i.consignatario_id === directionEdited.consignatario_id
    );
    const isEqual = _.isEqual(directionEdited, direction);
    let willUpdate = false;
    if (!isEqual) {
      willUpdate =
        String(directionEdited.consignatario).trim() ===
        String(direction.consignatario).trim();
    }
    // Si willUpdate = true, va a actualizar el consignatario, sino va a crear un nuevo registro
    ApiServiceComercios.actualizaDireccionCliente({
      ...directionEdited,
      willUpdate,
      cliente_id: clienteDB._id,
      editDirectionName,
    })
      .then(({ consignatario_id, message }) => {
        let direcciones = clienteDB.direcciones;
        if (willUpdate) {
          direcciones[dirIndex] = directionEdited;
        } else {
          direcciones.push({
            ...directionEdited,
            consignatario_id:
              consignatario_id || directionEdited.consignatario_id,
          });
        }
        const direccionesId = {};
        direccionesId[
          editDirectionName === "envío"
            ? "direccionEnvioId"
            : "direccionFacturacionId"
        ] = willUpdate ? directionEdited.consignatario_id : consignatario_id;
        dispatch(toggleToast({ message }));
        setCliente((state) => ({ ...state, direcciones, ...direccionesId }));
      })
      .finally(() => {
        setIsEditingDirection(false);
      });
  }

  function calculateAvailable() {
    return (
      (clienteDB.limite_credito.limite_credito || 0) -
      (clienteDB.limite_credito.saldo?.actual || 0) -
      handleTotal()
    );
  }

  if (isLoading) {
    return (
      <Layer title="Crear pedido" forbidden={forbidden}>
        <ComercioDetalleSkeleton fullWidth />
      </Layer>
    );
  }

  if (clienteDB === null) {
    return (
      <Layer title={`Crear pedido`} forbidden={forbidden} scrollToTop>
        <EmptyState
          heading="Oops! No se puede crear el pedido en este momento"
          action={{
            content: "Volver a intentar",
            onAction: () => window.location.reload(),
          }}
          secondaryAction={{
            content: "Regresar a pedidos",
            url: "/admin/pedidos",
          }}
          image="/Ad.svg"
        >
          <p>Intenta de nuevo más tarde</p>
        </EmptyState>
      </Layer>
    );
  }

  function calcularPesoTotal() {
    let weight = 0;
    for (let i = 0; i < selectedArticulos.length; i++) {
      const articulo = selectedArticulos[i];
      const cantidad =
        articleCount.find((art) => art.id === articulo._id)?.cantidad || 1;
      weight += (articulo?.peso || 0) * cantidad;
    }
    return weight;
  }

  const weight = calcularPesoTotal();

  return (
    <Layer title="Crear pedido" forbidden={forbidden}>
      <Page
        fullWidth
        title="Crear pedido"
        breadcrumbs={[{ content: "Pedidos", url: "/cliente/pedidos" }]}
        primaryAction={{
          content: "Crear pedido",
          primary: true,
          onAction: crearPedido,
          disabled:
            selectedArticulos.length === 0 ||
            isCreating ||
            (verifyLimitExceeded && calculateAvailable() < 0) ||
            !clienteDB.almacen ||
            !clienteDB.clave ||
            quantityError.length ||
            (isShowingFees && !selectedShippmentFee) ||
            ["V", "C"].includes(clienteDB.status_microsip),
          loading: isCreating || isEditingDirection,
        }}
      >
        {isModalActive && (
          <BuscarProductoModal
            primaryActionTitle="Agregar al pedido"
            selected={articleCount.map((a) => a.id)}
            selectArticulos={selectArticulos}
            updateText={updateText}
            value={inputValue}
            isActive={isModalActive}
            handleChange={handleModalChange}
            mostrarExistenciaProducto={mostrarExistencia}
            almacenId={clienteDB.almacen?.id}
          />
        )}

        {isEditingDirection && <Loading />}

        <PreventTransitionPrompt
          when={isBlocking}
          setIsBlocking={setIsBlocking}
          router={router}
          title="Tienes cambios sin guardar en esta página"
          message="Si abandonas esta página, perderás todos los cambios no guardados. ¿Estás seguro de que quieres salir de esta página?"
        />

        {isOpenModal && (
          <EditContactDirectionModal
            direccionEnvio={
              clienteDB.direcciones.find(
                (i) => i.consignatario_id === clienteDB.direccionEnvioId
              ) || {}
            }
            direccionFacturacion={
              clienteDB.direcciones.find(
                (i) => i.consignatario_id === clienteDB.direccionFacturacionId
              ) || {}
            }
            title={editDirectionName}
            direcciones={clienteDB?.direcciones}
            isOpen={isOpenModal}
            handleChange={handleDirectionModal}
            handleSave={handleSaveDirection}
          />
        )}
        <Layout>
          <Layout.Section>
            <CustomerStatusBanner status={clienteDB?.status_microsip} />
            {errorArticulos.message && (
              <div className="mb-8">
                <Banner
                  title={errorArticulos.message}
                  status="warning"
                  onDismiss={dismissWarning}
                >
                  <List>
                    {errorArticulos?.articulos?.map((i, key) => (
                      <List.Item key={key}>{i.nombre}</List.Item>
                    ))}
                  </List>
                </Banner>
              </div>
            )}
            <Card title="Detalles del pedido">
              <Card.Section>
                <TextField
                  type="text"
                  autoComplete="name"
                  onChange={updateText}
                  value={inputValue}
                  prefix={<Icon source={SearchMinor} color="inkLighter" />}
                  placeholder="Buscar productos"
                  connectedRight={
                    <Button onClick={handleModalChange}>Buscar</Button>
                  }
                />
              </Card.Section>
              {selectedArticulos.length > 0 ? (
                <Card.Section>
                  {selectedArticulos.map((articulo) => {
                    return (
                      <div key={articulo._id}>
                        <ArticuloItem
                          descMaximos={porMaximo}
                          descVolumenAplicada={porVolumen}
                          descPromoAplicada={porPromosGen}
                          runOnInsertBegin={willReturnInitialData}
                          articulo={articulo}
                          quantity={Number(
                            articleCount.find((ar) => ar.id === articulo._id)
                              .cantidad
                          )}
                          selectNote={addNoteToArticle}
                          handleChange={onChangeArticleValue}
                          onRemove={onRemoveArticulo}
                          showTax={impuestoDB.mostrarPrecioConImpuesto}
                          quantityError={quantityError}
                          setQuantityError={(value, id) =>
                            setQuantityError((prevState) =>
                              value
                                ? prevState.some((errorId) => errorId === id)
                                  ? prevState
                                  : [...prevState, id]
                                : prevState.filter((errorId) => errorId !== id)
                            )
                          }
                          currencies={currencies}
                          clientCurrency={clienteDB?.moneda}
                          discounts={productDiscounts}
                          priceToUse={priceToUseId}
                        />
                      </div>
                    );
                  })}
                </Card.Section>
              ) : null}
            </Card>
            <ApproveCard
              cantidad={selectedArticulos.length}
              currency={clienteDB?.moneda}
              envio={{
                cantidad: handlePrecioEnvio(),
                datos: clienteDB.envio,
              }}
              forCotizacion={false}
              impuesto={handleImpuesto()}
              limiteCredito={clienteDB.limite_credito}
              selectedShippmentFee={selectedShippmentFee}
              setIsShowingFees={setIsShowingFees}
              setSelectedShippmentFee={setSelectedShippmentFee}
              shippingFees={shippingFees}
              showLimit={verifyLimitExceeded}
              subtotal={handleSubTotalProductos()}
              total={handleTotal()}
              weight={weight}
              orderDetails={{
                articulos: selectedArticulos.map((ar) => ({
                  articulo: ar._id,
                  notas: ar.notas || "",
                  cantidad: Number(
                    articleCount.find((art) => art.id === ar._id).cantidad
                  ),
                })),
                notas: note,
                vendorId: vendorInputValue,
              }}
              setIsBlocking={setIsBlocking}
              usePayment={isActivePayment}
              shouldPay={clienteDB.status_microsip === "C"}
              orderData={getOrderData()}
              usePaymentsInOrders={usePaymentsInOrders}
            />
          </Layout.Section>
          <Layout.Section secondary>
            <ClientSection
              clienteDB={clienteDB}
              isLoading={isLoading}
              setIsBlocking={setIsBlocking}
              handleDirectionModal={handleDirectionModal}
              hasRemoveItem={false}
              setEditDirectionName={setEditDirectionName}
            />
            <Card sectioned title="Notas">
              <TextField
                type="text"
                placeholder="Agrega una nota al pedido"
                value={note}
                onChange={handleChangeNote}
                showCharacterCount
                maxLength={150}
              />
            </Card>
            <Card title="Vendedores">
              <Card.Section>
                <VendorAutocomplete
                  selected={selectedVendors}
                  vendorInputValue={vendorInputValue}
                  setSelectedVendors={setSelectedVendors}
                  setVendorInputValue={(value) => {
                    setVendorInputValue(value);
                    setIsBlocking(true);
                  }}
                  setIsBlocking={setIsBlocking}
                  placeholder="Buscar vendedores"
                />
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
        <div className="flex justify-end mt-8">
          <ButtonGroup>
            <Button
              onClick={() => {
                router.goBack();
              }}
              disabled={isCreating || isEditingDirection}
            >
              Descartar
            </Button>
            <Button
              primary
              onClick={crearPedido}
              disabled={
                selectedArticulos.length === 0 ||
                isCreating ||
                (verifyLimitExceeded && calculateAvailable() < 0) ||
                !clienteDB.almacen ||
                quantityError.length ||
                (isShowingFees && !selectedShippmentFee) ||
                ["V", "C"].includes(clienteDB?.status_microsip)
              }
              loading={isCreating || isEditingDirection}
            >
              Crear pedido
            </Button>
          </ButtonGroup>
        </div>
        <FooterHelp>
          Más información de <Link>pedidos</Link>
        </FooterHelp>
      </Page>
      <FooterCliente />
    </Layer>
  );
}
