import { Card, Layout, SkeletonBodyText, SkeletonPage } from "@shopify/polaris";
import React from "react";
import { Layer } from "..";

export default function OrderSkeleton() {
  return (
    <Layer title={`Cotización`}>
      <SkeletonPage breadcrumbs fullWidth>
        <Layout>
          <Layout.Section>
            <Card sectioned title="Detalles de la cotización ">
              <SkeletonBodyText lines={3} />
            </Card>
            <Card sectioned title="Aprobado">
              <SkeletonBodyText lines={3} />
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <Card title="Contacto">
              <Card.Section>
                <SkeletonBodyText lines={2} />
              </Card.Section>
              <Card.Section title="Dirección de envio">
                <SkeletonBodyText lines={2} />
              </Card.Section>
              <Card.Section title="Dirección de facturación">
                <SkeletonBodyText lines={2} />
              </Card.Section>
              <Card.Section title="Notas">
                <SkeletonBodyText lines={2} />
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    </Layer>
  );
}
