import React from "react";
import {
  AnnouncementBarCard,
  HeaderSection,
  BannerSliderCard,
  BannerSliderItem,
  CollectionsContainer,
  RichTextCard,
  OutstandingCollection,
  BannerImage,
  VideoSection,
  FooterCard,
  ProductInformation,
  BannerCollection,
  ProductsGrid,
  BannerProduct,
  NotFoundCard,
} from "../../components/SectionsEditors";
import CollectionsListItem from "../SectionsEditors/CollectionsListItem";

export default function ChooseSection({
  selectedSection = null,
  selectedSubSection = null,
  handleMainChange = () => {},
  handleDeleteSection = () => {},
  handleDeleteSubSection = () => {},
  currentPage = "",
}) {
  if (selectedSection === null) {
    return <></>;
  }
  const { type } = selectedSection;
  if (type === "announcement-bar") {
    return (
      <AnnouncementBarCard
        handleMainChange={handleMainChange}
        item={selectedSection}
      />
    );
  }
  if (type === "heading") {
    return (
      <HeaderSection
        handleMainChange={handleMainChange}
        item={selectedSection}
      />
    );
  }
  if (type === "banner-slider" && !selectedSubSection) {
    return (
      <BannerSliderCard
        handleMainChange={handleMainChange}
        item={selectedSection}
        handleDeleteSection={handleDeleteSection}
      />
    );
  }

  if (type === "banner-slider" && selectedSubSection) {
    return (
      <BannerSliderItem
        handleMainChange={handleMainChange}
        item={selectedSection}
        subItem={selectedSubSection}
        handleDeleteSubSection={handleDeleteSubSection}
      />
    );
  }

  if (type === "collections-list" && !selectedSubSection) {
    return (
      <CollectionsContainer
        handleMainChange={handleMainChange}
        item={selectedSection}
        handleDeleteSection={handleDeleteSection}
        currentPage={currentPage}
      />
    );
  }
  if (type === "collections-list" && selectedSubSection) {
    return (
      <CollectionsListItem
        handleMainChange={handleMainChange}
        item={selectedSection}
        subItem={selectedSubSection}
        handleDeleteSubSection={handleDeleteSubSection}
      />
    );
  }
  if (type === "rich-text") {
    return (
      <RichTextCard
        handleMainChange={handleMainChange}
        item={selectedSection}
        handleDeleteSection={handleDeleteSection}
      />
    );
  }

  if (type === "outstanding-collection") {
    return (
      <OutstandingCollection
        handleMainChange={handleMainChange}
        item={selectedSection}
        handleDeleteSection={handleDeleteSection}
        currentPage={currentPage}
      />
    );
  }

  if (type === "banner-image") {
    return (
      <BannerImage
        handleMainChange={handleMainChange}
        item={selectedSection}
        handleDeleteSection={handleDeleteSection}
      />
    );
  }
  if (type === "video") {
    return (
      <VideoSection
        handleMainChange={handleMainChange}
        item={selectedSection}
        handleDeleteSection={handleDeleteSection}
      />
    );
  }
  if (type === "footer") {
    return (
      <FooterCard handleMainChange={handleMainChange} item={selectedSection} />
    );
  }

  if (type === "product-information") {
    return (
      <ProductInformation
        handleMainChange={handleMainChange}
        item={selectedSection}
      />
    );
  }

  if (type === "banner-collection") {
    return (
      <BannerCollection
        handleMainChange={handleMainChange}
        item={selectedSection}
      />
    );
  }
  if (type === "banner-product") {
    return (
      <BannerProduct
        handleMainChange={handleMainChange}
        item={selectedSection}
      />
    );
  }

  if (type === "products-grid" || type === "products-collection-grid") {
    return (
      <ProductsGrid
        handleMainChange={handleMainChange}
        item={selectedSection}
      />
    );
  }
  if (type === "not-found") {
    return (
      <NotFoundCard
        handleMainChange={handleMainChange}
        item={selectedSection}
      />
    );
  }

  return <div>ChooseSection</div>;
}
