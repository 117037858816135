export const imagesFormat = ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG", "gif"];
export const videosFormat = [
  "mp4",
  "MP4",
  "mov",
  "MOV",
  "youtube",
  "vimeo",
  "youtu.be",
];

export const validImageTypes = [
  "image/jpeg",
  "image/png",
  "image/jpg",
  "video/mp4",
  "video/quicktime",
];
