import React from "react";
import { useSelector } from "react-redux";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { SocketProvider } from "../context/SocketContext";
import { isAuthenticated } from "../reducers";
import {
  AddCollectionScreen,
  AddDiscountByClientScreen,
  AddDiscountByMaximoScreen,
  AddDiscountByProductScreen,
  AddDiscountByPromoScreen,
  AddDiscountByVolumenScreen,
  AddPedidoComercioScreen,
  AddPedidoScreen,
  AddPedidosPreliminaresClienteScreen,
  AddPedidosPreliminaresScreen,
  AddPlanPermisosScreen,
  AgregarAnuncioScreen,
  AgregarCotizacionClienteScreen,
  AgregarCotizacionComercioScreen,
  AgregarEnvioScreen,
  AgregarOportunidadScreen,
  AgregarSucursalScreen,
  AnunciosScreen,
  ArticuloDetalleScreen,
  ArticulosScreen,
  ChatIntegracionScreen,
  ClientProfileScreen,
  ClienteArticuloDetalleScreen,
  ClienteArticulosScreen,
  ClienteDetalleScreen,
  // ClienteConfiguracionScreen,
  ClienteEstadoCuentaScreen,
  CollectionDetailPage,
  CollectionsListPage,
  CollectionsScreen,
  CollectorDetailScreen,
  ComercioClientesScreen,
  ComercioConfigGeneralScreen,
  ComercioConfigMicrosipDataScreen,
  ComercioConfiguracionScreen,
  ComercioInteMicrosipScreen,
  ComercioIntegracionesScreen,
  ComerciosPaginasScreen,
  ConfigEmailScreen,
  CotizacionClienteDetalleScreen,
  CotizacionComercioDetalleScreen,
  CotizacionesClienteScreen,
  CotizacionesComercioScreen,
  CurrenciesScreen,
  DashboardClienteScreen,
  DashboardComercioScreen,
  DescuentosScreen,
  DetailProductPage,
  EnvioDetalleScreen,
  EnvioEntregaScreen,
  FacturasScreen,
  FiltroMenuScreen,
  GraciasScreen,
  HelpCenterDetailScreen,
  HelpCenterScreen,
  HistorialScreen,
  HomePage,
  ImpuestoDetalleScreen,
  ImpuestosScreen,
  LoginScreen,
  NavegacionScreen,
  NavegationMenuScreen,
  NotActiveStoreScreen,
  NotFoundPage,
  NotStoreFoundScreen,
  OportunidadesComercioScreen,
  Page404Screen,
  PageCustomizationScreen,
  PaymentDetailScreen,
  PaymentsConfigScreen,
  PaymentsDetailScreen,
  PaymentsScreen,
  PedidosClienteDetalleScreen,
  PedidosClienteScreen,
  PedidosDetalleScreen,
  PedidosPreliminaresClienteScreen,
  PedidosPreliminaresClientesDetalleScreen,
  PedidosPreliminaresDetalleScreen,
  PedidosPreliminaresScreen,
  PedidosScreen,
  PermisosScreen,
  PersonalizarScreen,
  PlanesScreen,
  PoliticasScreen,
  PreferenciasScreen,
  ProductsPage,
  RecoveryPasswordScreen,
  RegistroScreen,
  ReportesScreen,
  ScheduledStorePage,
  //NotActiveStoreScreen,
  StoreValidatorScreen,
  SucursalesScreen,
  UtilieriasScreen,
  VariantsScreen,
  VendorDetailScreen,
  VendorsScreen,
} from "../screens";
import OportunidadDetalleScreen from "../screens/OportunidadDetalleScreen";
import PreferencesScreen from "../screens/PreferencesScreen";
import PrivateRoute from "./PrivateRoute";

export default function Navigation() {
  const isLoggedIn = useSelector(isAuthenticated);
  const grantType = useSelector((state) => state.Auth.grant_type);

  const getUserConfirmation = (dialogKey, callback) => {
    // use "message" as Symbol-based key
    const dialogTrigger = window[Symbol.for(dialogKey)];
    if (dialogTrigger) {
      // pass the callback to delegate
      // to the invoked dialog
      return dialogTrigger(callback);
    }

    // Callback to allowing navigation
    callback(true);
  };

  return (
    <Router getUserConfirmation={getUserConfirmation}>
      <StoreValidatorScreen />
      <Switch>
        {/* <Route exact path="/" render={() => <Redirect to="/login" />} /> */}
        <Route exact path="/logout" render={() => <Redirect to="/login" />} />
        <Route
          exact
          path="/not-store"
          render={(props) => <NotStoreFoundScreen {...props} />}
        />
        <Route
          exact
          path="/not-available"
          render={(props) => <NotActiveStoreScreen {...props} />}
        />
        <Route
          path="/login"
          exact
          render={(props) => (
            <LoginScreen {...props} data={{ grantType, isLoggedIn }} />
          )}
        />
        <Route path="/register" exact component={RegistroScreen} />
        <Route
          path="/recovery-password"
          exact
          component={RecoveryPasswordScreen}
        />
        {["COMERCIO", "AGENT"].includes(grantType) && (
          <SocketProvider>
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/login" />} />
              <PrivateRoute
                path="/admin/configuracion/general"
                exact
                component={ComercioConfigGeneralScreen}
                can="General"
                access="read"
              />
              <PrivateRoute
                path="/admin/centro-de-ayuda"
                exact
                component={HelpCenterScreen}
                can="Centro de ayuda"
                access="read"
              />
              <PrivateRoute
                path="/admin/centro-de-ayuda/correo-electrónico"
                exact
                component={HelpCenterDetailScreen}
                can="Centro de ayuda"
                access="read"
              />
              <PrivateRoute
                path="/admin/configuracion/verifica-email"
                exact
                component={ConfigEmailScreen}
                can="General"
                access="read"
              />
              <PrivateRoute
                path="/admin/configuracion/permisos/agregar/:role"
                exact
                component={AddPlanPermisosScreen}
                can="Usuarios y permisos"
                access="write"
              />
              <PrivateRoute
                path="/admin/configuracion/permisos/:role/:id"
                exact
                component={AddPlanPermisosScreen}
                can="Usuarios y permisos"
                access="read"
              />
              <PrivateRoute
                path="/admin/configuracion/permisos"
                exact
                component={PermisosScreen}
                can="Usuarios y permisos"
                access="read"
              />
              <PrivateRoute
                path="/admin/configuracion/planes"
                exact
                component={PlanesScreen}
                can="Plan"
                access="read"
              />
              <PrivateRoute
                path="/admin/configuracion/preferencias"
                exact
                component={PreferencesScreen}
                can="Preferencias"
                access="read"
              />
              <PrivateRoute
                path="/admin/configuracion/paginas"
                exact
                component={ComerciosPaginasScreen}
                can="Páginas"
                access="read"
              />
              <PrivateRoute
                path="/admin/configuracion/monedas"
                exact
                component={CurrenciesScreen}
                can="Monedas"
                access="read"
              />
              <PrivateRoute
                path="/admin/configuracion/pagos"
                exact
                component={PaymentsConfigScreen}
                can="Pagos"
                access="read"
              />
              <PrivateRoute
                path="/admin/pagos"
                exact
                component={PaymentsScreen}
                can="Pagos"
                access="read"
              />
              <PrivateRoute
                path="/admin/pagos/:id"
                exact
                component={PaymentsDetailScreen}
                can="Pagos"
                access="read"
              />
              <PrivateRoute
                path="/admin/configuracion/pagos/stripe"
                exact
                component={PaymentDetailScreen}
                can="Pagos"
                access="read"
              />
              <PrivateRoute
                path="/admin/configuracion/chats"
                exact
                component={ChatIntegracionScreen}
                can="Chats"
                access="read"
              />
              <PrivateRoute
                path="/admin/configuracion/vendedores"
                exact
                component={VendorsScreen}
                can="Vendedores"
                access="read"
              />
              <PrivateRoute
                path="/admin/configuracion/vendedores/:id"
                exact
                component={VendorDetailScreen}
                can="Vendedores"
                access="read"
              />
              <PrivateRoute
                path="/admin/configuracion/cobradores/:id"
                exact
                component={CollectorDetailScreen}
                can="Cobradores"
                access="read"
              />
              <PrivateRoute
                path="/admin/configuracion/integracion/microsip"
                exact
                component={ComercioInteMicrosipScreen}
                can="Integraciones"
                access="read"
              />
              <PrivateRoute
                path="/admin/configuracion/integracion/microsip/datos"
                exact
                component={ComercioConfigMicrosipDataScreen}
                can="Integraciones"
                access="read"
              />
              <PrivateRoute
                path="/admin/configuracion/integraciones"
                exact
                component={ComercioIntegracionesScreen}
                can="Integraciones"
                access="read"
              />
              <PrivateRoute
                path="/admin/configuracion/envio-entregas/agregar"
                exact
                component={AgregarEnvioScreen}
                can="Envío y entrega"
                access="write"
              />
              <PrivateRoute
                path="/admin/configuracion/envio-entregas/:id"
                exact
                component={EnvioDetalleScreen}
                can="Envío y entrega"
                access="read"
              />
              <PrivateRoute
                path="/admin/configuracion/impuestos/detalle"
                exact
                component={ImpuestoDetalleScreen}
                can="Impuestos"
                access="read"
              />
              <PrivateRoute
                path="/admin/configuracion/impuestos"
                exact
                component={ImpuestosScreen}
                can="Impuestos"
                access="read"
              />
              <PrivateRoute
                path="/admin/configuracion/envio-entregas"
                exact
                component={EnvioEntregaScreen}
                can="Envío y entrega"
                access="read"
              />
              <PrivateRoute
                path="/admin/configuracion/sucursales/:id"
                exact
                component={AgregarSucursalScreen}
                can="Sucursales"
                access="read"
              />
              <PrivateRoute
                path="/admin/configuracion/sucursales/agregar"
                exact
                component={AgregarSucursalScreen}
                can="Sucursales"
                access="write"
              />
              <PrivateRoute
                path="/admin/configuracion/sucursales"
                exact
                component={SucursalesScreen}
                can="Sucursales"
                access="read"
              />
              <PrivateRoute
                path="/admin/configuracion/utilerias"
                exact
                component={UtilieriasScreen}
                can="Utilerías"
                access="write"
              />
              <PrivateRoute
                path="/admin/configuracion"
                exact
                component={ComercioConfiguracionScreen}
                can="Configuración"
                access="read"
              />
              <PrivateRoute
                path="/admin/historial"
                exact
                component={HistorialScreen}
                can="Historial"
                access="read"
              />
              <PrivateRoute
                path="/admin/descuentos"
                exact
                component={DescuentosScreen}
                can="Descuentos"
                access="read"
              />
              <PrivateRoute
                path="/admin/descuentos/by-cliente/:id"
                exact
                component={AddDiscountByClientScreen}
                can="Descuentos"
                access="read"
              />
              <PrivateRoute
                path="/admin/descuentos/agregar-by-cliente"
                exact
                component={AddDiscountByClientScreen}
                can="Descuentos"
                access="write"
              />
              <PrivateRoute
                path="/admin/descuentos/by-producto/:id"
                exact
                component={AddDiscountByProductScreen}
                can="Descuentos"
                access="read"
              />
              <PrivateRoute
                path="/admin/descuentos/agregar-by-producto"
                exact
                component={AddDiscountByProductScreen}
                can="Descuentos"
                access="write"
              />
              <PrivateRoute
                path="/admin/descuentos/by-promocion/:id"
                exact
                component={AddDiscountByPromoScreen}
                can="Descuentos"
                access="read"
              />
              <PrivateRoute
                path="/admin/descuentos/agregar-by-promocion"
                exact
                component={AddDiscountByPromoScreen}
                can="Descuentos"
                access="write"
              />
              <PrivateRoute
                path="/admin/descuentos/by-volumen/:id"
                exact
                component={AddDiscountByVolumenScreen}
                can="Descuentos"
                access="read"
              />
              <PrivateRoute
                path="/admin/descuentos/agregar-by-volumen"
                exact
                component={AddDiscountByVolumenScreen}
                can="Descuentos"
                access="write"
              />
              <PrivateRoute
                path="/admin/descuentos/by-maximo/:id"
                exact
                component={AddDiscountByMaximoScreen}
                can="Descuentos"
                access="read"
              />
              <PrivateRoute
                path="/admin/descuentos/agregar-by-maximo"
                exact
                component={AddDiscountByMaximoScreen}
                can="Descuentos"
                access="write"
              />
              <PrivateRoute
                path="/admin/tienda_online/personalizar"
                exact
                component={PersonalizarScreen}
                can="Personalizar"
                access="read"
              />
              <PrivateRoute
                path="/admin/tienda_online/page-customization"
                exact
                component={PageCustomizationScreen}
                can="Personalizar"
                access="read"
              />
              <PrivateRoute
                path="/admin/tienda_online/preferencias"
                exact
                component={PreferenciasScreen}
                can="Preferencias"
                access="read"
              />
              <PrivateRoute
                path="/admin/tienda_online/navegacion"
                exact
                component={FiltroMenuScreen}
                can="Navegación"
                access="read"
              />
              <PrivateRoute
                path="/admin/tienda_online/filtros"
                exact
                component={NavegacionScreen}
                can="Navegación"
                access="read"
              />
              <PrivateRoute
                path="/admin/tienda_online/menus"
                exact
                component={NavegationMenuScreen}
                can="Menús"
                access="read"
              />

              {/* Rutas de pedidos preliminares */}

              <PrivateRoute
                path="/admin/pedidos/preliminares/agregar"
                exact
                component={AddPedidosPreliminaresScreen}
                can="Preliminares"
                access="read"
              />
              <PrivateRoute
                path="/admin/pedidos/preliminares/:id"
                exact
                component={PedidosPreliminaresDetalleScreen}
                can="Preliminares"
                access="read"
              />

              <PrivateRoute
                path="/admin/pedidos/preliminares"
                exact
                component={PedidosPreliminaresScreen}
                can="Preliminares"
                access="read"
              />

              {/* Fin de  Rutas de pedidos preliminares */}

              <PrivateRoute
                path="/admin/pedidos/agregar"
                exact
                component={AddPedidoComercioScreen}
                can="Pedidos"
                access="write"
              />
              <PrivateRoute
                path="/admin/pedidos/:id"
                exact
                component={PedidosDetalleScreen}
                can="Pedidos"
                access="read"
              />
              <PrivateRoute
                path="/admin/pedidos"
                exact
                component={PedidosScreen}
                can="Pedidos"
                access="read"
              />
              <PrivateRoute
                path="/admin/clientes/:id"
                exact
                component={ClienteDetalleScreen}
                can="Clientes"
                access="read"
              />
              <PrivateRoute
                path="/admin/clientes"
                exact
                component={ComercioClientesScreen}
                can="Clientes"
                access="read"
              />
              <PrivateRoute
                path="/admin/reportes"
                exact
                component={ReportesScreen}
                can="Reportes"
                access="read"
              />
              <PrivateRoute
                path="/admin/articulos/colecciones"
                exact
                component={CollectionsScreen}
                can="Colecciones"
                access="read"
              />
              <PrivateRoute
                path="/admin/articulos/colecciones/agregar"
                exact
                component={AddCollectionScreen}
                can="Colecciones"
                access="read"
              />
              <PrivateRoute
                path="/admin/articulos/colecciones/:id"
                exact
                component={AddCollectionScreen}
                can="Colecciones"
                access="read"
              />
              <PrivateRoute
                path="/admin/articulos/:id/variant/:variantId"
                exact
                component={VariantsScreen}
                can="Artículos"
                access="read"
              />
              <PrivateRoute
                path="/admin/articulos/:id"
                exact
                component={ArticuloDetalleScreen}
                can="Artículos"
                access="read"
              />
              <PrivateRoute
                path="/admin/articulos"
                exact
                component={ArticulosScreen}
                can="Artículos"
                access="read"
              />
              <PrivateRoute
                path="/admin/anuncios/:id"
                exact
                component={AgregarAnuncioScreen}
                can="Anuncios"
                access="read"
              />
              <PrivateRoute
                path="/admin/anuncios/agregar"
                exact
                component={AgregarAnuncioScreen}
                can="Anuncios"
                access="write"
              />
              <PrivateRoute
                path="/admin/anuncios"
                exact
                component={AnunciosScreen}
                can="Anuncios"
                access="read"
              />
              <PrivateRoute
                path="/admin/cotizaciones/agregar"
                exact
                component={AgregarCotizacionComercioScreen}
                can="Cotizaciones"
                access="write"
              />
              <PrivateRoute
                path="/admin/cotizaciones/:id"
                exact
                component={CotizacionComercioDetalleScreen}
                can="Cotizaciones"
                access="read"
              />
              <PrivateRoute
                path="/admin/cotizaciones"
                exact
                component={CotizacionesComercioScreen}
                can="Cotizaciones"
                access="write"
              />
              <PrivateRoute
                path="/admin/oportunidades/agregar"
                exact
                component={AgregarOportunidadScreen}
                can="Oportunidades"
                access="write"
              />
              <PrivateRoute
                path="/admin/oportunidades/:id"
                exact
                component={OportunidadDetalleScreen}
                can="Oportunidades"
                access="read"
              />
              <PrivateRoute
                path="/admin/oportunidades"
                exact
                component={OportunidadesComercioScreen}
                can="Oportunidades"
                access="write"
              />
              <PrivateRoute
                path="/admin"
                exact
                component={DashboardComercioScreen}
                can="Inicio"
                access="read"
              />
              <Route
                path="*"
                render={(props) => <Page404Screen {...props} />}
              />
            </Switch>
          </SocketProvider>
        )}
        {["CLIENTE", "CONTACT"].includes(grantType) && (
          <>
            <SocketProvider>
              <Switch>
                <Route
                  exact
                  path="/not-service"
                  render={(props) => <ScheduledStorePage {...props} />}
                />
                <PrivateRoute
                  path="/"
                  exact
                  component={HomePage}
                  can="Inicio"
                  access="read"
                />
                <PrivateRoute
                  path="/products/:handle"
                  exact
                  component={DetailProductPage}
                  can="Artículos"
                  access="read"
                />
                <PrivateRoute
                  path="/products"
                  exact
                  component={ProductsPage}
                  can="Artículos"
                  access="read"
                />
                <PrivateRoute
                  path="/collections/:handle"
                  exact
                  component={CollectionDetailPage}
                  can="Colecciones"
                  access="read"
                />
                <PrivateRoute
                  path="/collections"
                  exact
                  component={CollectionsListPage}
                  can="Colecciones"
                  access="read"
                />

                {/* Rutas de pedidos preliminares  Cliente*/}

                <PrivateRoute
                  path="/cliente/pedidos/preliminares/agregar"
                  exact
                  component={AddPedidosPreliminaresClienteScreen}
                  can="Preliminares"
                  access="write"
                />
                <PrivateRoute
                  path="/cliente/pedidos/preliminares/:id"
                  exact
                  component={PedidosPreliminaresClientesDetalleScreen}
                  can="Preliminares"
                  access="read"
                />
                <PrivateRoute
                  path="/cliente/pedidos/preliminares"
                  exact
                  component={PedidosPreliminaresClienteScreen}
                  can="Pedidos"
                  access="read"
                />

                {/* Fin de  Rutas de pedidos preliminares Cliente */}

                <PrivateRoute
                  path="/cliente/pedidos/agregar"
                  exact
                  component={AddPedidoScreen}
                  can="Pedidos"
                  access="write"
                />
                <PrivateRoute
                  path="/cliente/pedidos/:id"
                  exact
                  component={PedidosClienteDetalleScreen}
                  can="Pedidos"
                  access="read"
                />
                <PrivateRoute
                  path="/cliente/pedidos/:id/gracias"
                  exact
                  component={GraciasScreen}
                  can="Gracias"
                  access="read"
                />
                <PrivateRoute
                  path="/cliente/pedidos"
                  exact
                  component={PedidosClienteScreen}
                  can="Pedidos"
                  access="read"
                />
                <PrivateRoute
                  path="/cliente/facturas"
                  exact
                  component={FacturasScreen}
                  can="Facturas"
                  access="read"
                />
                <PrivateRoute
                  path="/cliente/estado-de-cuenta"
                  exact
                  component={ClienteEstadoCuentaScreen}
                  can="Estado de cuenta"
                  access="read"
                />
                <PrivateRoute
                  path="/cliente/paginas/:id"
                  exact
                  component={PoliticasScreen}
                  can="Políticas"
                  access="read"
                />
                <PrivateRoute
                  path="/cliente/cotizaciones/agregar"
                  exact
                  component={AgregarCotizacionClienteScreen}
                  can="Cotizaciones"
                  access="write"
                />
                <PrivateRoute
                  path="/cliente/cotizaciones/:id"
                  exact
                  component={CotizacionClienteDetalleScreen}
                  can="Cotizaciones"
                  access="read"
                />
                <PrivateRoute
                  path="/cliente/cotizaciones"
                  exact
                  component={CotizacionesClienteScreen}
                  can="Cotizaciones"
                  access="read"
                />
                <PrivateRoute
                  path="/cliente/articulos"
                  exact
                  component={ClienteArticulosScreen}
                  can="Artículos"
                  access="read"
                />
                <PrivateRoute
                  path="/cliente/articulos/:id"
                  exact
                  component={ClienteArticuloDetalleScreen}
                  can="Artículos"
                  access="read"
                />
                <PrivateRoute
                  path="/cliente/perfil"
                  exact
                  component={ClientProfileScreen}
                  can="Perfil"
                  access="read"
                />
                {/* <PrivateRoute path="/cliente/configuracion" exaxt component={ClienteConfiguracionScreen} can="cliente-configuracion" /> */}
                <PrivateRoute
                  path="/cliente"
                  exact
                  component={DashboardClienteScreen}
                  can="Inicio"
                  access="read"
                />
                <Route
                  path="*"
                  render={(props) => <NotFoundPage {...props} />}
                />
              </Switch>
            </SocketProvider>
          </>
        )}
        <Route path="*" render={() => <Redirect to="/login" />} />
      </Switch>
    </Router>
  );
}
