import React, { useState, useRef, useCallback } from 'react';
import {
   Page,
   Layout,
   Card,
   TextField,
   Badge,
   FormLayout,
} from "@shopify/polaris";
import {
   Layer,
} from "../../components";
import { useHistory } from 'react-router-dom';
import ApiServiceComercios from '../../services/ApiServiceComercios';
import { useDispatch } from 'react-redux';
import { toggleToast } from '../../actions/InteractiveActions';

export default function AgregarEnvioScreen({ forbidden }) {
   const router = useHistory();
   const dispatch = useDispatch();
   const defaultState = useRef({ nombre: '', precio: '0.00' })
   const [envio, setEnvio] = useState({ ...defaultState.current });
   const [isCreatingEnvio, setIsCreatingEnvio] = useState(false);
   const [error, setError] = useState("");

   const handleFieldChange = useCallback((value, key) => {
      if (key === 'precio' && !isNaN(value)) {
         setEnvio((state) => ({ ...state, [key]: value }));
      } else {
         setEnvio((state) => ({ ...state, [key]: value }));
      }
   }, []);

   function handleSave() {
      setIsCreatingEnvio(true);
      ApiServiceComercios.crearEnvio({ ...envio, precio: Number(envio.precio) })
         .then(({ ok, message }) => {
            if (ok) {
               dispatch(toggleToast({ message }))
               setIsCreatingEnvio(false);
               handleDiscard();
            }
         }).catch((err) => {
            setIsCreatingEnvio(false);
            if (err.response.data) {
               setError(err.response.data.message)
            }
         });
   }

   function handleDiscard() {
      router.push('/admin/configuracion/envio-entregas');
   }

   return (
      <Layer title="Agregar envío" forbidden={forbidden}>
         <Page
            breadcrumbs={[{ content: 'Envíos', url: '/admin/configuracion/envio-entregas' }]}
            title="Agregar envío"
            primaryAction={{
               content: "Agregar",
               onAction: handleSave,
               disabled: isCreatingEnvio || !envio.nombre,
               loading: isCreatingEnvio
            }}
         >
            <Layout>
               <Layout.AnnotatedSection
                  title="Detalles del envío"
                  description="Dale a este envío un nombre para identificarla fácilmente y un precio. Los clientes verán esta información en sus pedidos."
               >
                  <Card sectioned>
                     <FormLayout>
                        <TextField
                           label="Nombre del envío"
                           type='text'
                           autoComplete={false}
                           placeholder="Envio"
                           value={envio.nombre}
                           onChange={(text) => handleFieldChange(text, 'nombre')}
                           error={error}
                        />
                        <TextField
                           label="Precio"
                           type="number"
                           min={0}
                           step={1}
                           value={envio.precio}
                           onChange={((value) => handleFieldChange(value, 'precio'))}
                           prefix="$"
                           suffix={Number(envio.precio) === 0 && <Badge>Gratis</Badge>}
                        />
                     </FormLayout>
                  </Card>
               </Layout.AnnotatedSection>
            </Layout>
         </Page>
      </Layer>
   )
}
