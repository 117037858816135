import React from "react";
import { TextStyle, Link, Page } from "@shopify/polaris";
import { useSelector } from "react-redux";
import { getComercioData } from "../../reducers";
import useToggle from "../../hooks/useToggle";
import SendEmailModal from "../SendEmailModal";

export default function FooterCliente() {
  const {
    direccion = "",
    ciudad = "",
    estado = "",
    pais = "",
    telefono = "",
    codigo_postal = "",
    email = "",
  } = useSelector(getComercioData);
  const nombre_comercio = useSelector((state) => state.Auth.nombre_comercio);

  const [isActive, handleChange] = useToggle(false);

  function formatPhone() {
    const countryCode = `${telefono.split(" ")[0]}`;
    const countryCodeFormated = `${countryCode}`;
    const phone = telefono.split(countryCode)[1] || "";
    return (
      countryCodeFormated + phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
    );
  }

  return (
    <div>
      <div className="w-full Polaris-Page-Header--separator" />
      <Page>
        <div className="flex flex-row flex-wrap p-12">
          <div className="flex-1 flex-col py-6 pr-8 align-start">
            <TextStyle variation="strong">{nombre_comercio}</TextStyle>
            <div className="my-3">
              <TextStyle variation="subdued">{direccion}</TextStyle>
            </div>
            <div className="my-3">
              <TextStyle variation="subdued">
                {ciudad ? ciudad + "," : ""} {estado ? estado + "." : ""}
              </TextStyle>
            </div>
            <div className="my-3">
              <TextStyle variation="subdued">
                {pais ? pais + "." : ""}{" "}
                {codigo_postal ? codigo_postal + "." : ""}
              </TextStyle>
            </div>
            <TextStyle variation="subdued">
              {telefono && telefono.split(" ").length > 1 ? formatPhone() : ""}{" "}
            </TextStyle>
          </div>
          <div className="flex-1 flex-col py-6 pr-8 align-start">
            <TextStyle variation="strong">Políticas</TextStyle>
            <div className="my-3 no-underline">
              <Link
                url="/cliente/paginas/terminos-y-condiciones"
                removeUnderline
              >
                <TextStyle variation="subdued">
                  Términos y condiciones
                </TextStyle>
              </Link>
            </div>
            <div className="my-3">
              <Link url="/cliente/paginas/politica-de-envios" removeUnderline>
                <TextStyle variation="subdued">Política de envíos</TextStyle>
              </Link>
            </div>
            <div className="mt-3 mb-3">
              <Link
                url="/cliente/paginas/politica-de-privacidad"
                removeUnderline
              >
                <TextStyle variation="subdued">
                  Política de privacidad
                </TextStyle>
              </Link>
            </div>
            <Link url="/cliente/paginas/politica-de-cobros" removeUnderline>
              <TextStyle variation="subdued">Política de cobros</TextStyle>
            </Link>
          </div>
          <div className="flex-1 flex-col py-6 align-start">
            <TextStyle variation="strong">Ayuda</TextStyle>
            <div className="my-3">
              {" "}
              {email ? (
                <Link onClick={handleChange} removeUnderline>
                  <TextStyle variation="subdued">Correo</TextStyle>
                </Link>
              ) : (
                <TextStyle variation="subdued" removeUnderline>
                  Correo
                </TextStyle>
              )}{" "}
            </div>
            <div className="mt-3 mb-3">
              <Link
                url={
                  telefono && telefono.split(" ").length > 1
                    ? `tel:${formatPhone()}`
                    : ""
                }
                removeUnderline
              >
                <TextStyle variation="subdued">Teléfono</TextStyle>
              </Link>
            </div>
            <Link
              url={
                telefono && telefono.split(" ").length > 1
                  ? `https://api.whatsapp.com/send?phone=${formatPhone()}&text=Necesito%20ayuda&source=&data=&app_absent=`
                  : ""
              }
              removeUnderline
            >
              <TextStyle variation="subdued">WhatsApp</TextStyle>
            </Link>
          </div>
        </div>
        {isActive && (
          <SendEmailModal isActive={isActive} onClose={handleChange} />
        )}
      </Page>
    </div>
  );
}
