import {
  ADD_COMERCIOS,
  ADD_COMERCIOS_BEGIN,
  CLEAR_COMERCIOS,
  ADD_COMERCIOS_FAILED,
  RESET_PAGE,
  CHANGE_PAGE,
} from "../actions/ComerciosActions";

const initialState = {
  byId: {},
  allIds: [],
  isLoading: false,
  currentPage: 0,
  sizePerPage: 20,
  total: 0,
};

export default function ComerciosReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_COMERCIOS_BEGIN:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_COMERCIOS: {
      let allIds = [
        ...new Set(state.allIds.concat(action.payload.data.allIds)),
      ];
      let byId = {
        ...state.byId,
        ...action.payload.data.byId,
      };
      return {
        ...state,
        allIds,
        byId,
        total: action.payload.data.total,
        isLoading: false,
      };
    }
    case CLEAR_COMERCIOS: {
      return {
        ...state,
        byId: {},
        allIds: [],
        isLoading: false,
      };
    }
    case ADD_COMERCIOS_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case CHANGE_PAGE: {
      const currentPage = action.payload.data.currentPage;
      return {
        ...state,
        currentPage,
      };
    }
    case RESET_PAGE: {
      return {
        ...state,
        currentPage: 0,
        total: 0,
      };
    }
    default:
      return state;
  }
}

export const isLoadingComercios = (state) => {
  return state.isLoading;
};
