import React from "react";
import { Button, TextStyle } from "@shopify/polaris";
import { CancelSmallMinor } from "@shopify/polaris-icons";

export const CustomTag = ({
  id = "",
  name = "",
  type = "",
  showButton = false,
  onClick = () => {},
}) => {
  // Here you register your custom color
  const colors = {
    auto: "#A4E8F2",
    manual: "#DFE3E8",
  };

  return (
    <div
      key={id}
      className="p-2 rounded"
      style={{
        backgroundColor: colors[type],
      }}
    >
      <div className="flex justify-center">
        <TextStyle>{name}</TextStyle>
        {showButton && (
          <div className="pl-2">
            <Button plain fullWidth icon={CancelSmallMinor} onClick={onClick} />
          </div>
        )}
      </div>
    </div>
  );
};
