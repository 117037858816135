import React from 'react';
import CustomBadge from '../CustomBadge';

export default function ClienteListItem({status}) {
   return (
      <div className="flex flex-row">
         <CustomBadge status={status} />
      </div>
   )
}
