import React, { useState, useEffect, useCallback } from "react";
import {
  Page,
  Badge,
  Card,
  Layout,
  TextStyle,
  Link,
  Button,
  EmptyState,
  Subheading,
  Icon,
  Spinner,
} from "@shopify/polaris";
import {
  //DuplicateMinor,
  //PageDownMajor,
  // PrintMinor,
  // ImportMinor,
  //MobileCancelMajor,
  OrdersMajor,
} from "@shopify/polaris-icons";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Layer,
  ArticuloItem,
  ApproveCard,
  CustomBadge,
  ComercioDetalleSkeleton,
  GeneralModal,
  Timeline,
  TagsAutocomplete,
  HelpFooter,
  CustomerStatusBanner,
} from "../../components";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { toggleToast } from "../../actions/InteractiveActions";
import {
  getPaginationCotizaciones,
  getUserInfo,
  getNombreComercio,
  getComercioData,
} from "../../reducers";
import "./CotizacionComercioDetalleScreenStyles.css";
import { extractInitials } from "../../utils/stringModifiers";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CotizacionPDF from "../../components/common/CotizacionPDF";
import useAbility from "../../hooks/useAbility";
import useGetCurrencies from "../../hooks/useGetCurrencies";

export default function CotizacionClienteDetalleScreen({ forbidden }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const router = useHistory();
  const usuario = useSelector(getUserInfo);
  const [cotizacion, setCotizacion] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddingTag, setIsAddingTag] = useState(false);
  const pageState = useSelector((state) => state.Cotizaciones.allIdsDB);
  const pagination = useSelector(getPaginationCotizaciones);
  const [showHistorial, setShowHistorial] = useState(true);
  const [fieldErrors, setFieldErrors] = useState({
    createOrder: "",
  });
  const isForbiddenReadPedidos = useAbility("read", "Pedidos");
  const [showTax, setShowTax] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isActiveModal, setIsActiveModal] = useState(false);

  const comercio = useSelector(getNombreComercio);
  const comercioData = useSelector(getComercioData);
  const url_imagen = useSelector((state) => state.Auth.url_imagen);
  const data = { ...cotizacion, comercio, comercioData, url_imagen };
  const { currencies } = useGetCurrencies();

  const handleChange = useCallback(
    () => setIsActiveModal(!isActiveModal),
    [isActiveModal]
  );

  useEffect(() => {
    const fetchData = () => {
      // dispatch(setIsLoadingProgressBar({ active: false }));
      ApiServiceComercios.cotizacionDetalle(id)
        .then(({ ok, cotizacion }) => {
          if (ok) {
            setCotizacion({
              ...cotizacion,
              articulos: cotizacion?.articuloFlete?.nombre
                ? cotizacion.articulos.filter(
                    (i) => i.articulo._id !== cotizacion.articuloFlete.id
                  )
                : cotizacion.articulos.filter(
                    (i) => i.articulo?.nombre.indexOf("Flete") < 0
                  ),
            });
            setSelectedOptions(cotizacion.etiquetas);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
      ApiServiceComercios.obtenerPreferenciasComercio().then(({ impuesto }) => {
        setShowTax(impuesto.mostrarPrecioConImpuesto);
      });
    };
    fetchData();
  }, [id]);

  function updateCotizacion() {
    const estatus = {
      tipo: 3,
      nombre: "Cancelado",
    };
    setIsUpdating(true);
    ApiServiceComercios.actualizarCotizacion(
      {
        estatus,
      },
      id
    )
      .then(({ message }) => {
        setCotizacion((state) => ({ ...state, estatus }));
        dispatch(toggleToast({ message }));
      })
      .finally(() => {
        setIsUpdating(true);
      });
  }

  function setStatus(envio) {
    switch (envio) {
      case "Enviado":
        return "complete";
      case "Pendiente":
        return "incomplete";
      case "En ruta":
        return "partiallyComplete";
      default:
        return null;
    }
  }

  function selectTags(values) {
    setIsAddingTag(true);
    ApiServiceComercios.actualizarCotizacion({ etiquetas: values }, id)
      .then(({ ok, message }) => {
        if (ok) {
          dispatch(toggleToast({ message }));
          setSelectedOptions(values);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsAddingTag(false);
      });
  }

  function duplicarCotizacion() {
    router.push("/admin/cotizaciones/agregar", {
      articulos: cotizacion.articulos.map((i) => ({
        ...i.articulo,
        cantidad: i.cantidad,
      })),
      clienteId: cotizacion.cliente._id,
      notas: cotizacion.notas,
      vendorId: cotizacion.vendor._id ?? null,
      almacenId: cotizacion.cliente?.almacen?.id,
    });
  }

  function previousPage() {
    const prevId = pageState[pageState.indexOf(id) - 1];
    router.push(`/admin/cotizaciones/${prevId}`);
  }

  function nextPage() {
    const nextId = pageState[pageState.indexOf(id) + 1];
    router.push(`/admin/cotizaciones/${nextId}`);
  }

  function craeteOrderFromQuotation() {
    setIsUpdating(true);
    setFieldErrors({
      createOrder: "",
    });
    const data = {
      articlesWithClave: cotizacion.articulos
        .map((i) => ({
          cantidad: i.cantidad,
          clave: String(i.articulo.clave).replace(new RegExp('"', "ig"), ""),
        }))
        .filter((i) => i.clave && i.clave !== "undefined"),
      clave: cotizacion.cliente.clave,
    };
    ApiServiceComercios.getArticlesForCSV(data)
      .then(({ articles = [] }) => {
        articles = articles.filter(({ isParent }) => !isParent);
        if (articles.length > 0) {
          router.push("/admin/pedidos/agregar", {
            articulos: articles,
            clienteId: cotizacion.cliente._id,
            notas: cotizacion.notas,
            quotationId: id,
            almacenId: cotizacion.cliente.almacen.id,
          });
        } else {
          setIsUpdating(false);
          setFieldErrors({
            createOrder: "No se encontraron artículos para crear el pedido",
          });
        }
      })
      .catch((error) => {
        setFieldErrors({
          createOrder: error?.response?.data?.message || "",
        });
        setIsUpdating(false);
      });
  }

  function readDirectionEnvio(direction) {
    return (
      <div className="flex flex-col">
        <TextStyle>
          {direction.direccion && direction.direccion + ", "}
          {direction.ciudad && direction.ciudad + ", "}
          {direction.estado && direction.estado + ", "}
          {direction.codigo_postal && "C.P. " + direction.codigo_postal}.
        </TextStyle>
        <TextStyle variation="subdued">{direction.telefono}</TextStyle>
      </div>
    );
  }

  function readDirectionFacturacion(direction) {
    if (
      cotizacion.direccionEnvioId &&
      cotizacion.direccionEnvioId === cotizacion.direccionFacturacionId
    ) {
      return <TextStyle>La misma dirección de envío</TextStyle>;
    } else {
      return (
        <div className="flex flex-col">
          <TextStyle>
            {direction.direccion && direction.direccion + ", "}
            {direction.ciudad && direction.ciudad + ", "}
            {direction.estado && direction.estado + ", "}
            {direction.codigo_postal && "C.P. " + direction.codigo_postal}.
          </TextStyle>
          <TextStyle variation="subdued">{direction.telefono}</TextStyle>
        </div>
      );
    }
  }

  if (isLoading) {
    return (
      <Layer title="Detalle cotización" forbidden={forbidden}>
        <ComercioDetalleSkeleton fullWidth />
      </Layer>
    );
  }

  if (!cotizacion) {
    return (
      <Layer title="Detalle cotización" forbidden={forbidden}>
        <EmptyState
          heading="Oops! Hubo un problema al intentar obtener esta cotización"
          action={{
            content: "Volver a intentar",
            onAction: () => window.location.reload(),
          }}
          secondaryAction={{
            content: "Regresar a cotizaciones",
            onAction: () => router.goBack(),
          }}
          image="/Ad.svg"
        >
          <p>Intenta de nuevo más tarde</p>
        </EmptyState>
      </Layer>
    );
  }

  const titleMetada = (
    <div className="flex items-center">
      <div className="mr-6">
        <TextStyle variation="subdued">
          {moment(cotizacion?.createdDate).format("D ")} de{" "}
          {moment(cotizacion?.createdDate).format("MMMM YYYY, HH:mm")}
          {cotizacion.isFromMicrosip ? ", Microsip" : ", B2BGo"}
        </TextStyle>
      </div>
      <CustomBadge
        progress={setStatus(cotizacion?.estatus.nombre)}
        status={cotizacion?.estatus.nombre}
      />
      <Badge status={cotizacion.isInExsim ? "success" : "warning"}>
        {cotizacion.isInExsim ? "Sincronizado" : "No sincronizado"}
      </Badge>
    </div>
  );

  const clienteData = (
    <Card fullWidth>
      <Card.Section>
        <div className="flex justify-between items-center">
          <h3 className="Polaris-Subheading">CLIENTE</h3>
          <div className="relative" style={{ width: 40, top: -10 }}>
            <img
              className="rounded-full"
              src="/clienteAvatar.png"
              alt="Cliente"
            />
          </div>
        </div>
        <div className="flex flex-col">
          <Link external url={`/admin/clientes/${cotizacion.cliente._id}`}>
            {cotizacion?.cliente?.nombre}
          </Link>
          <TextStyle>{`${cotizacion?.cliente?.cotizaciones} ${
            cotizacion?.cliente?.cotizaciones > 1
              ? "cotizaciones"
              : "cotización"
          }`}</TextStyle>
        </div>
      </Card.Section>
      <Card.Section title="Información de contacto">
        <div className="flex flex-col">
          <TextStyle variation="subdued">
            {cotizacion.cliente.nombre || ""}
          </TextStyle>
          <Link>{cotizacion.cliente.email}</Link>
        </div>
      </Card.Section>
      {!cotizacion.direccionEnvioId ? (
        <Card.Section title="Dirección">
          <div className="flex flex-col">
            <TextStyle variation="subdued">
              No se suministró la dirección de envío
            </TextStyle>
          </div>
        </Card.Section>
      ) : (
        <Card.Section title="Dirección de envío">
          {cotizacion.direccionEnvioId &&
          cotizacion.cliente.direcciones.some(
            (i) => i.consignatario_id === cotizacion.direccionEnvioId
          ) ? (
            readDirectionEnvio(
              cotizacion.cliente.direcciones.find(
                (i) => i.consignatario_id === cotizacion.direccionEnvioId
              )
            )
          ) : (
            <TextStyle>Aún no hay una dirección de envío asignada</TextStyle>
          )}
        </Card.Section>
      )}
      {!cotizacion.direccionFacturacionId ? (
        <Card.Section title="Dirección">
          <div className="flex flex-col">
            <TextStyle variation="subdued">
              No se suministró la dirección de facturación
            </TextStyle>
          </div>
        </Card.Section>
      ) : (
        <Card.Section title="Dirección de facturación">
          {cotizacion.direccionFacturacionId &&
          cotizacion.cliente.direcciones.some(
            (i) => i.consignatario_id === cotizacion.direccionFacturacionId
          ) ? (
            readDirectionFacturacion(
              cotizacion.cliente.direcciones.find(
                (i) => i.consignatario_id === cotizacion.direccionFacturacionId
              )
            )
          ) : (
            <TextStyle>
              Aún no hay una dirección de facturación asignada
            </TextStyle>
          )}
        </Card.Section>
      )}
      {cotizacion.moneda ? (
        <Card.Section title="Moneda">
          <TextStyle>
            {cotizacion.moneda.nombre}
            {" ("}
            {cotizacion.moneda.claveFiscal}
            {")"}
          </TextStyle>
        </Card.Section>
      ) : null}
    </Card>
  );

  return (
    <Layer
      title={`Detalle cotización ${cotizacion.folio}`}
      forbidden={forbidden}
    >
      <Page
        fullWidth
        title={`${cotizacion.folio}`}
        titleMetadata={titleMetada}
        breadcrumbs={[
          { content: "Cotizaciones", onAction: () => router.goBack() },
        ]}
        pagination={{
          hasPrevious:
            pageState.indexOf(id) !== 0 ||
            pagination.currentPage * pagination.sizePerPage !== 0,
          onPrevious: previousPage,
          nextTooltip: "Siguiente cotización",
          hasNext: pageState.indexOf(id) + 1 < pageState.length,
          onNext: nextPage,
          previousTooltip: "Cotización anterior",
        }}
        secondaryActions={[
          {
            content: "Duplicar",
            //icon: DuplicateMinor,
            onAction: duplicarCotizacion,
            disabled: !cotizacion.cliente?.almacen?.id,
          },
          {
            content: (
              <div className="relative z-50">
                <PDFDownloadLink
                  document={<CotizacionPDF data={data} />}
                  fileName={`${comercio} - Cotización ${cotizacion.folio}`}
                >
                  <TextStyle variation="subdued">Descargar</TextStyle>
                  {({ loading }) =>
                    loading ? (
                      <div className="inline-flex mx-8">
                        <Spinner size="small" />
                      </div>
                    ) : (
                      <div className="flex text-gray-600 no-underline hover:text-gray-800">
                        <TextStyle variation="strong">Descargar</TextStyle>
                      </div>
                    )
                  }
                </PDFDownloadLink>
              </div>
            ),
            //icon: PageDownMajor,
          },
          {
            content: "Cancelar cotización",
            //icon: MobileCancelMajor,
            onAction: handleChange,
            disabled: isUpdating || cotizacion.estatus.tipo !== 1,
            loading: isUpdating,
          },
        ]}
      >
        {isActiveModal && (
          <GeneralModal
            open={isActiveModal}
            body="¿Estás seguro de cancelar esta cotización?"
            onAction={() => {
              handleChange();
              updateCotizacion();
            }}
            onClose={handleChange}
            primary="Continuar"
            secondary="Cancelar"
            title="Cancelar cotización"
            destructive={true}
          />
        )}
        <Layout>
          <Layout.Section>
            <CustomerStatusBanner
              status={cotizacion.cliente?.status_microsip}
            />

            <div className="selectClientSmall">{clienteData}</div>
            <Card title="Detalles de la cotización">
              <Card.Section>
                {cotizacion.articulos
                  .slice(
                    0,
                    showAll && cotizacion.articulos.length > 5
                      ? cotizacion.articulos.length
                      : 5
                  )
                  .map((articulo, index) => {
                    return (
                      <div
                        key={
                          articulo._id ||
                          "_" + Math.random().toString(36).substr(2, 9)
                        }
                      >
                        {articulo.articulo ? (
                          <ArticuloItem
                            disabled
                            articulo={{
                              ...articulo.articulo,
                              precioDeLista: articulo.precioDeLista,
                              totalPorcentajeDescuento:
                                articulo.totalPorcentajeDescuento,
                              totalPrecioConDescuento:
                                articulo.totalPrecioConDescuento,
                              notas: articulo.notas,
                            }}
                            showAsDetail
                            quantity={Number(articulo.cantidad)}
                            handleChange={() => {}}
                            onRemove={() => {}}
                            isLast={cotizacion.articulos.length - 1 === index}
                            currencies={currencies}
                            clientCurrency={cotizacion?.moneda}
                            showTax={showTax}
                          />
                        ) : null}
                      </div>
                    );
                  })}
              </Card.Section>
              {cotizacion.articulos.length > 5 && (
                <Card.Section>
                  <div className="flex justify-center">
                    <Button plain onClick={() => setShowAll((s) => !s)}>
                      {showAll
                        ? "Ver menos"
                        : `Ver todos los artículos (${cotizacion.articulos.length})`}
                    </Button>
                  </div>
                </Card.Section>
              )}
            </Card>
            <ApproveCard
              subtotal={cotizacion.subtotalCotizacion}
              impuesto={cotizacion.impuestoSubtotalCotizacion}
              envio={{
                cantidad: cotizacion?.envio?.precio,
                datos: { ...cotizacion.envio },
              }}
              cantidad={cotizacion.articulos.length}
              total={cotizacion.total}
              weight={cotizacion?.weight}
              showLimit={false}
              forCotizacion={true}
              currency={cotizacion.moneda ? cotizacion.moneda : undefined}
            >
              {!isForbiddenReadPedidos ? (
                <Card.Section>
                  <div className="flex flex-col">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <div className="mr-4">
                          <Icon source={OrdersMajor} color="subdued" />
                        </div>
                        <Subheading>Crear pedido</Subheading>
                      </div>
                      <Button
                        primary
                        onClick={craeteOrderFromQuotation}
                        disabled={
                          cotizacion?.estatus.nombre === "Aprobado" ||
                          isUpdating ||
                          cotizacion?.cliente?.status_microsip !== "A"
                        }
                        loading={isUpdating}
                      >
                        Crear pedido
                      </Button>
                    </div>
                  </div>
                  {fieldErrors.createOrder && (
                    <div className="flex justify-end my-4">
                      <TextStyle variation="negative">
                        {fieldErrors.createOrder}
                      </TextStyle>
                    </div>
                  )}
                </Card.Section>
              ) : null}
            </ApproveCard>
            <div className="my-8">
              <Timeline
                visible={showHistorial}
                onChange={setShowHistorial}
                initials={extractInitials(usuario.name, usuario.last_name)}
                parentMessageId={id}
                subtitle="Todos pueden ver los comentarios."
              />
            </div>
          </Layout.Section>
          <Layout.Section secondary>
            <div className="selectClientLarge">{clienteData}</div>
            <Card sectioned title="Notas">
              <TextStyle>{cotizacion.notas}</TextStyle>
            </Card>
            <TagsAutocomplete
              title="Etiquetas"
              subdued
              selected={selectedOptions}
              onSelect={selectTags}
              outLoading={isAddingTag}
              module="Cotizaciones"
            />
            <Card sectioned title="Vendedor">
              <TextStyle>{cotizacion?.vendedor?.name}</TextStyle>
            </Card>
          </Layout.Section>
        </Layout>
        <HelpFooter
          title="Cotizaciones"
          url="https://help.b2bgo.mx/cotizaciones"
        />
      </Page>
    </Layer>
  );
}
