import {
  Card,
  Checkbox,
  FormLayout,
  Layout,
  Page,
  TextStyle,
} from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toggleToast } from "../../actions/InteractiveActions";
import { HelpFooter, Layer, ManualPaymentsCard } from "../../components";
import { AnnotatedSkeleton } from "../../components/common/AnnotatedSkeleton/AnnotatedSkeleton";
import PaymentsContainer from "../../containers/PaymentsContainer";
import ApiServiceComercios from "../../services/ApiServiceComercios";

const PaymentsScreen = ({ forbidden }) => {
  const router = useHistory();
  const dispatch = useDispatch();

  const [isPaymentOnOrder, setIsPaymentOnOrder] = useState(false);
  const [overdueBillsPaid, setOverdueBillsPaid] = useState(false);
  const [overdueBallancePaid, setOverDueBalancePaid] = useState(false);
  const [publicKey, setPublicKey] = useState("");
  const [secretKey, setPrivateKey] = useState("");
  const onSavePaymentType = useCallback(
    (key, value, setState) => {
      const params = {
        usePaymentsInOrders: isPaymentOnOrder,
        usePaymentsInBills: overdueBillsPaid,
        usePaymentsInDueBalance: overdueBallancePaid,
        publicKey,
        secretKey,
      };
      params[key] = value;
      setState(value);
      ApiServiceComercios.setPaymentsConfig(params).then(({ ok }) => {
        if (ok)
          dispatch(
            toggleToast({
              message: "Captura de pago actualizada correctamente",
            })
          );
      });
    },
    [
      isPaymentOnOrder,
      overdueBillsPaid,
      overdueBallancePaid,
      publicKey,
      secretKey,
      dispatch,
    ]
  );

  return (
    <PaymentsContainer
      setIsPaymentOnOrder={setIsPaymentOnOrder}
      setOverdueBillsPaid={setOverdueBillsPaid}
      setOverDueBalancePaid={setOverDueBalancePaid}
      setPublicKey={setPublicKey}
      setPrivateKey={setPrivateKey}
    >
      {({ isLoading, isActive }) => {
        if (isLoading) {
          return <AnnotatedSkeleton rowsQuantity={2} />;
        }
        return (
          <Layer title="Pagos" forbidden={forbidden}>
            <Page
              title="Proveedores de pago"
              fullWidth
              breadcrumbs={[
                { content: "Configuración", url: "/admin/configuracion" },
              ]}
            >
              <Layout>
                <Layout.AnnotatedSection
                  title="Proveedores de pago"
                  description="Acepta pagos en tu tienda, usando proveedores externos."
                >
                  <Card
                    sectioned
                    title={
                      <img src="/stripe-logo.png" alt="paypal" width="108" />
                    }
                    secondaryFooterActions={[
                      {
                        content: isActive ? "Ver Detalles" : "Activar Stripe",
                        onAction: () =>
                          router.push("/admin/configuracion/pagos/stripe"),
                      },
                    ]}
                  >
                    <TextStyle>
                      Millones de empresas de todos los tamaños, desde startups
                      hasta grandes coorporaciones, usan Stripe para aceptar
                      pagos, hacer transferencias y gestionar sus negocios en
                      Internet.
                    </TextStyle>
                  </Card>
                </Layout.AnnotatedSection>
                <Layout.AnnotatedSection
                  title="Captura de pago"
                  description="Elige donde puede pagarte tu cliente, al terminar un pedido, al ver sus facturas o bien al momento de ver su estado de cuenta."
                >
                  <Card sectioned>
                    <FormLayout>
                      <Checkbox
                        label="Pagar en los pedidos"
                        helpText="Le muestra la opción de pago al cliente para pagar su límite de crédito excedido."
                        checked={isPaymentOnOrder}
                        onChange={(value) => {
                          onSavePaymentType(
                            "usePaymentsInOrders",
                            value,
                            setIsPaymentOnOrder
                          );
                        }}
                      />
                      <Checkbox
                        label="Pagar facturas vencidas"
                        helpText="Le muestra la opción de pago al cliente al consultar sus facturas vencidas"
                        checked={overdueBillsPaid}
                        onChange={(value) => {
                          onSavePaymentType(
                            "usePaymentsInBills",
                            value,
                            setOverdueBillsPaid
                          );
                        }}
                      />
                      <Checkbox
                        label="Pagar saldo vencido"
                        helpText="Le muestra la opción de pago al cliente al consultar su estado de cuenta"
                        checked={overdueBallancePaid}
                        onChange={(value) => {
                          onSavePaymentType(
                            "usePaymentsInDueBalance",
                            value,
                            setOverDueBalancePaid
                          );
                        }}
                      />
                    </FormLayout>
                  </Card>
                </Layout.AnnotatedSection>

                <ManualPaymentsCard />
              </Layout>
              <HelpFooter
                title="pagos"
                url="https://help.b2bgo.mx/configuracion/pagos"
              />
            </Page>
          </Layer>
        );
      }}
    </PaymentsContainer>
  );
};

export default PaymentsScreen;
