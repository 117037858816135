import React, { useState, useEffect, useCallback } from "react";
import {
  Page,
  Badge,
  EmptyState,
  TextStyle,
  Layout,
  Card,
  Link,
  Icon,
  Button,
  Subheading,
  Spinner,
} from "@shopify/polaris";
import { OrdersMajor } from "@shopify/polaris-icons";
import moment from "moment";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { PDFDownloadLink } from "@react-pdf/renderer";

import {
  ApproveCard,
  ArticuloItem,
  CustomBadge,
  CustomerStatusBanner,
  GeneralModal,
  Layer,
  OrderSkeleton,
  Timeline,
} from "../../components";
import {
  getPaginationCotizaciones,
  getUserInfo,
  getNombreComercio,
  getComercioData,
} from "../../reducers";
import {
  setIsLoadingProgressBar,
  toggleToast,
} from "../../actions/InteractiveActions";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { extractInitials } from "../../utils/stringModifiers";
import CotizacionPDF from "../../components/common/CotizacionPDF";
import useAbility from "../../hooks/useAbility";
import useGetCurrencies from "../../hooks/useGetCurrencies";

export default function CotizacionClienteDetalleScreen({ forbidden }) {
  const { id } = useParams();
  const router = useHistory();
  const dispatch = useDispatch();
  const usuario = useSelector(getUserInfo);
  const grant_type = useSelector((state) => state.Auth.grant_type);
  const [cotizacionDB, setCotizacionDB] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const pageState = useSelector((state) => state.Cotizaciones.allIdsDB);
  const pagination = useSelector(getPaginationCotizaciones);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [showHistorial, setShowHistorial] = useState(true);
  const [fieldErrors, setFieldErrors] = useState({
    createOrder: "",
  });
  const isForbiddenReadPedidos = useAbility("read", "Pedidos");
  const { currencies } = useGetCurrencies();
  const [showTax, setShowTax] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const comercio = useSelector(getNombreComercio);
  const comercioData = useSelector(getComercioData);
  const url_imagen = useSelector((state) => state.Auth.url_imagen);
  const data = { ...cotizacionDB, comercio, comercioData, url_imagen };

  const handleChange = useCallback(
    () => setIsActiveModal(!isActiveModal),
    [isActiveModal]
  );

  useEffect(() => {
    const fetchData = () => {
      dispatch(setIsLoadingProgressBar({ active: false }));
      ApiServiceComercios.cotizacionDetalle(id)
        .then(({ cotizacion }) => {
          setCotizacionDB({
            ...cotizacion,
            articulos:
              cotizacion.articuloFlete && cotizacion.articuloFlete.nombre
                ? cotizacion.articulos.filter(
                    (i) => i.articulo._id !== cotizacion.articuloFlete.id
                  )
                : cotizacion.articulos.filter(
                    (i) => i.articulo.nombre.indexOf("Flete") < 0
                  ),
          });
        })
        .catch((err) => {})
        .finally(() => setIsLoading(false));
      ApiServiceComercios.obtenerPreferenciasComercio().then(({ impuesto }) => {
        setShowTax(impuesto.mostrarPrecioConImpuesto);
      });
    };
    fetchData();
  }, [id, dispatch]);

  function updateCotizacion() {
    const estatus = {
      tipo: 3,
      nombre: "Cancelado",
    };
    setIsUpdating(true);
    ApiServiceComercios.actualizarCotizacion(
      {
        estatus,
      },
      id
    )
      .then(({ message }) => {
        setCotizacionDB((state) => ({ ...state, estatus }));
        dispatch(toggleToast({ message }));
      })
      .finally(() => {
        setIsUpdating(true);
      });
  }

  function duplicarCotizacion() {
    router.push("/cliente/cotizaciones/agregar", {
      articulos: cotizacionDB.articulos.map((i) => ({
        ...i.articulo,
        cantidad: i.cantidad,
      })),
      notas: cotizacionDB.notas,
      vendorId: cotizacionDB.vendor._id ?? null,
      almacenId: cotizacionDB.cliente.almacen.id,
    });
  }

  function previousPage() {
    const prevId = pageState[pageState.indexOf(id) - 1];
    router.push(`/cliente/cotizaciones/${prevId}`);
  }

  function nextPage() {
    const nextId = pageState[pageState.indexOf(id) + 1];
    router.push(`/cliente/cotizaciones/${nextId}`);
  }

  function craeteOrderFromQuotation() {
    setIsUpdating(true);
    setFieldErrors({
      createOrder: "",
    });
    const data = {
      articlesWithClave: cotizacionDB.articulos
        .map((i) => ({
          cantidad: i.cantidad,
          clave: String(i.articulo.clave).replace(new RegExp('"', "ig"), ""),
        }))
        .filter((i) => i.clave && i.clave !== "undefined"),
      clave: cotizacionDB.cliente.clave,
    };
    ApiServiceComercios.getArticlesForCSV(data)
      .then(({ articles = [] }) => {
        articles = articles.filter(({ isParent }) => !isParent);
        if (articles.length > 0) {
          router.push("/cliente/pedidos/agregar", {
            articulos: articles,
            notas: cotizacionDB.notas,
            quotationId: id,
            almacenId: cotizacionDB.cliente.almacen.id,
          });
        } else {
          setIsUpdating(false);
          setFieldErrors({
            createOrder: "No se encontraron artículos para crear el pedido",
          });
        }
      })
      .catch((error) => {
        setFieldErrors({
          createOrder: error?.response?.data?.message || "",
        });
        setIsUpdating(false);
      });
  }

  function readDirectionEnvio(direction) {
    return (
      <div className="flex flex-col">
        <TextStyle>
          {direction.direccion && direction.direccion + ", "}
          {direction.ciudad && direction.ciudad + ", "}
          {direction.estado && direction.estado + ", "}
          {direction.codigo_postal && "C.P. " + direction.codigo_postal}.
        </TextStyle>
        <TextStyle variation="subdued">{direction.telefono}</TextStyle>
      </div>
    );
  }

  function readDirectionFacturacion(direction) {
    if (
      cotizacionDB.direccionEnvioId &&
      cotizacionDB.direccionEnvioId === cotizacionDB.direccionFacturacionId
    ) {
      return <TextStyle>La misma dirección de envío</TextStyle>;
    } else {
      return (
        <div className="flex flex-col">
          <TextStyle>
            {direction.direccion && direction.direccion + ", "}
            {direction.ciudad && direction.ciudad + ", "}
            {direction.estado && direction.estado + ", "}
            {direction.codigo_postal && "C.P. " + direction.codigo_postal}.
          </TextStyle>
          <TextStyle variation="subdued">{direction.telefono}</TextStyle>
        </div>
      );
    }
  }

  if (isLoading) {
    return <OrderSkeleton />;
  }

  if (cotizacionDB === null) {
    return (
      <Layer title={`Cotización`} forbidden={forbidden}>
        <EmptyState
          heading="Oops! Hubo un problema al intentar obtener esta cotización"
          action={{
            content: "Volver a intentar",
            onAction: () => window.location.reload(),
          }}
          secondaryAction={{
            content: "Regresar a cotizaciones",
            onAction: () => router.goBack(),
          }}
          image="/Ad.svg"
        >
          <p>Intenta de nuevo más tarde</p>
        </EmptyState>
      </Layer>
    );
  }

  const titleMetada = (
    <div className="flex items-center">
      <div className="mr-6">
        <TextStyle variation="subdued">
          {moment(cotizacionDB.createdDate).format("D ")} de{" "}
          {moment(cotizacionDB.createdDate).format("MMMM YYYY, HH:mm")}
          {cotizacionDB.isFromMicrosip ? ", Microsip" : ", B2BGo"}
        </TextStyle>
      </div>
      <CustomBadge status={cotizacionDB.estatus.nombre} />
      <Badge status={cotizacionDB.isInExsim ? "success" : "warning"}>
        {cotizacionDB.isInExsim ? "Sincronizado" : "No sincronizado"}
      </Badge>
    </div>
  );

  return (
    <Layer
      title={`Detalle cotización ${cotizacionDB.folio}`}
      forbidden={forbidden}
    >
      <Page
        title={`${cotizacionDB.folio}`}
        titleMetadata={titleMetada}
        fullWidth
        breadcrumbs={[
          { content: "Cotizaciones", onAction: () => router.goBack() },
        ]}
        pagination={{
          hasPrevious:
            pageState.indexOf(id) !== 0 ||
            pagination.currentPage * pagination.sizePerPage !== 0,
          onPrevious: previousPage,
          nextTooltip: "Siguiente cotización",
          hasNext: pageState.indexOf(id) + 1 < pageState.length,
          onNext: nextPage,
          previousTooltip: "Cotización anterior",
        }}
        secondaryActions={[
          {
            content: "Duplicar",
            onAction: duplicarCotizacion,
            disabled: !cotizacionDB.cliente?.almacen?.id,
          },

          {
            content: (
              <div className="relative z-50">
                <PDFDownloadLink
                  document={<CotizacionPDF data={data} />}
                  fileName={`${comercio} - Cotización ${cotizacionDB.folio}`}
                >
                  <TextStyle variation="subdued">Descargar</TextStyle>
                  {({ loading }) =>
                    loading ? (
                      <div className="inline-flex mx-8">
                        <Spinner size="small" />
                      </div>
                    ) : (
                      <div className="flex text-gray-600 no-underline hover:text-gray-800">
                        <TextStyle variation="strong">Descargar</TextStyle>
                      </div>
                    )
                  }
                </PDFDownloadLink>
              </div>
            ),
          },
          {
            content: "Cancelar cotización",
            //icon: MobileCancelMajor,
            onAction: handleChange,
            disabled: isUpdating || cotizacionDB.estatus.tipo !== 1,
            loading: isUpdating,
          },
        ]}
      >
        {isActiveModal && (
          <GeneralModal
            open={isActiveModal}
            body="¿Estás seguro de cancelar esta cotización?"
            onAction={() => {
              handleChange();
              updateCotizacion();
            }}
            onClose={handleChange}
            primary="Continuar"
            secondary="Cancelar"
            title="Cancelar cotización"
            destructive={true}
          />
        )}
        <Layout>
          <Layout.Section>
            <CustomerStatusBanner
              status={cotizacionDB?.cliente?.status_microsip}
            />
            <Card title="Detalles de la cotización">
              <Card.Section>
                {cotizacionDB.articulos
                  ?.filter(
                    (articulo) =>
                      articulo.articulo?._id !== cotizacionDB.articuloFlete?.id
                  )
                  .slice(
                    0,
                    showAll && cotizacionDB.articulos.length > 5
                      ? cotizacionDB.articulos.length
                      : 5
                  )
                  .map((articulo, index) => {
                    return (
                      <div
                        key={
                          articulo._id ||
                          "_" + Math.random().toString(36).substr(2, 9)
                        }
                      >
                        {articulo.articulo ? (
                          <ArticuloItem
                            disabled
                            articulo={{
                              ...articulo.articulo,
                              precioDeLista: articulo.precioDeLista,
                              totalPorcentajeDescuento:
                                articulo.totalPorcentajeDescuento,
                              totalPrecioConDescuento:
                                articulo.totalPrecioConDescuento,
                              notas: articulo.notas,
                            }}
                            showAsDetail
                            quantity={Number(articulo.cantidad)}
                            handleChange={() => {}}
                            onRemove={() => {}}
                            isLast={cotizacionDB.articulos.length - 1 === index}
                            currencies={currencies}
                            clientCurrency={cotizacionDB?.moneda}
                            showTax={showTax}
                          />
                        ) : null}
                      </div>
                    );
                  })}
              </Card.Section>
              {cotizacionDB.articulos.length > 5 && (
                <Card.Section>
                  <div className="flex justify-center">
                    <Button plain onClick={() => setShowAll((s) => !s)}>
                      {showAll
                        ? "Ver menos"
                        : `Ver todos los artículos (${cotizacionDB.articulos.length})`}
                    </Button>
                  </div>
                </Card.Section>
              )}
            </Card>
            <ApproveCard
              subtotal={cotizacionDB.subtotalCotizacion}
              impuesto={cotizacionDB.impuestoSubtotalCotizacion}
              envio={{
                cantidad: cotizacionDB.envio.precio,
                datos: { ...cotizacionDB.envio },
              }}
              cantidad={cotizacionDB.articulos.length}
              total={cotizacionDB.total}
              weight={cotizacionDB?.weight}
              showLimit={false}
              forCotizacion={true}
              currency={cotizacionDB.moneda ? cotizacionDB.moneda : undefined}
            >
              {!isForbiddenReadPedidos ? (
                <Card.Section>
                  <div className="flex flex-col">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <div className="mr-4">
                          <Icon source={OrdersMajor} color="subdued" />
                        </div>
                        <Subheading>Crear pedido</Subheading>
                      </div>
                      <Button
                        primary
                        onClick={craeteOrderFromQuotation}
                        disabled={
                          cotizacionDB?.estatus.nombre === "Aprobado" ||
                          isUpdating ||
                          ["B", "V"].includes(
                            cotizacionDB?.cliente?.status_microsip
                          )
                        }
                        loading={isUpdating}
                      >
                        Crear pedido
                      </Button>
                    </div>
                  </div>
                  {fieldErrors.createOrder && (
                    <div className="flex justify-end my-4">
                      <TextStyle variation="negative">
                        {fieldErrors.createOrder}
                      </TextStyle>
                    </div>
                  )}
                </Card.Section>
              ) : null}
            </ApproveCard>
            <div className="my-8">
              <Timeline
                visible={showHistorial}
                onChange={setShowHistorial}
                initials={extractInitials(
                  grant_type === "CONTACT"
                    ? usuario.name
                    : usuario.nombre.split(" ")[0],
                  grant_type === "CONTACT"
                    ? usuario.last_name
                    : usuario.nombre.split(" ")[1]
                )}
                parentMessageId={id}
                subtitle="Todos pueden ver los comentarios."
              />
            </div>
          </Layout.Section>
          <Layout.Section secondary>
            <Card fullWidth>
              <Card.Section title="Contacto">
                <div className="flex flex-col">
                  <TextStyle variation="subdued">
                    {cotizacionDB.cliente.nombre || ""}
                  </TextStyle>
                  <Link>{cotizacionDB.cliente.email || ""}</Link>
                </div>
              </Card.Section>
              {!cotizacionDB.direccionEnvioId ? (
                <Card.Section title="Dirección">
                  <div className="flex flex-col">
                    <TextStyle variation="subdued">
                      No se suministró la dirección de envío
                    </TextStyle>
                  </div>
                </Card.Section>
              ) : (
                <Card.Section title="Dirección de envío">
                  {cotizacionDB.direccionEnvioId &&
                  cotizacionDB.cliente.direcciones.some(
                    (i) => i.consignatario_id === cotizacionDB.direccionEnvioId
                  ) ? (
                    readDirectionEnvio(
                      cotizacionDB.cliente.direcciones.find(
                        (i) =>
                          i.consignatario_id === cotizacionDB.direccionEnvioId
                      )
                    )
                  ) : (
                    <TextStyle>
                      Aún no hay una dirección de envío asignada
                    </TextStyle>
                  )}
                </Card.Section>
              )}
              {!cotizacionDB.direccionFacturacionId ? (
                <Card.Section title="Dirección">
                  <div className="flex flex-col">
                    <TextStyle variation="subdued">
                      No se suministró la dirección de facturación
                    </TextStyle>
                  </div>
                </Card.Section>
              ) : (
                <Card.Section title="Dirección de facturación">
                  {cotizacionDB.direccionFacturacionId &&
                  cotizacionDB.cliente.direcciones.some(
                    (i) =>
                      i.consignatario_id === cotizacionDB.direccionFacturacionId
                  ) ? (
                    readDirectionFacturacion(
                      cotizacionDB.cliente.direcciones.find(
                        (i) =>
                          i.consignatario_id ===
                          cotizacionDB.direccionFacturacionId
                      )
                    )
                  ) : (
                    <TextStyle>
                      Aún no hay una dirección de facturación asignada
                    </TextStyle>
                  )}
                </Card.Section>
              )}
              {cotizacionDB.moneda ? (
                <Card.Section title="Moneda">
                  <TextStyle>
                    {cotizacionDB.moneda.nombre}
                    {" ("}
                    {cotizacionDB.moneda.claveFiscal}
                    {")"}
                  </TextStyle>
                </Card.Section>
              ) : null}
            </Card>
            <Card sectioned title="Notas">
              <TextStyle>{cotizacionDB.notas}</TextStyle>
            </Card>
            <Card sectioned title="Vendedor">
              <TextStyle>{cotizacionDB?.vendedor?.name}</TextStyle>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </Layer>
  );
}
