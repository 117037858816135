import React from "react";
import {
  Page,
  Layout,
  Card,
  SkeletonPage,
  SkeletonDisplayText,
  SkeletonBodyText,
  SkeletonThumbnail,
  Stack,
  DataTable,
} from "@shopify/polaris";
import { Layer, HelpFooter } from "../../components";
import { useHistory } from "react-router-dom";
import useGetVendors from "../../hooks/useGetVendors";
import useGetCollectors from "../../hooks/useGetCollectors";

export default function VendorsScreen({ forbidden }) {
  const { vendors, isLoadingVendors } = useGetVendors();
  const { collectors, isLoadingCollectors } = useGetCollectors();
  const router = useHistory();

  const collectors_rows = [
    [
      collectors.map((collector) => {
        return (
          <div
            onClick={() =>
              router.push(`/admin/configuracion/cobradores/${collector.value}`)
            }
            className="flex justify-between p-4 cursor-pointer hover:bg-gray-50"
          >
            {collector.label}
            <div>
              {collector.comission <= 0
                ? "Sin comisión"
                : `${collector.comission} %`}
            </div>
          </div>
        );
      }),
    ],
  ];

  const vendors_rows = [
    [
      vendors.map((vendor) => {
        return (
          <div
            onClick={() =>
              router.push(`/admin/configuracion/vendedores/${vendor.value}`)
            }
            className="flex justify-between p-4 cursor-pointer hover:bg-gray-50"
          >
            {vendor.label}
            <div>
              {vendor.comission <= 0 ? "Sin comisión" : `${vendor.comission} %`}
            </div>
          </div>
        );
      }),
    ],
  ];

  if (isLoadingVendors) {
    return (
      <Layer title="Vendedores" forbidden={forbidden}>
        <SkeletonPage
          breadcrumbs
          title={<SkeletonDisplayText size="medium" />}
          primaryAction={<SkeletonDisplayText size="medium" />}
        >
          <Layout>
            <Layout.AnnotatedSection
              title={<SkeletonDisplayText size="medium" />}
              description={<SkeletonBodyText lines={2} />}
            >
              {Array.from(Array(3).keys()).map((item) => {
                return (
                  <div key={item} className="mb-8">
                    <Card sectioned>
                      <Stack>
                        <SkeletonThumbnail size="small" />
                        <div style={{ minWidth: 300 }}>
                          <SkeletonBodyText lines={2} />
                        </div>
                      </Stack>
                    </Card>
                  </div>
                );
              })}
            </Layout.AnnotatedSection>
          </Layout>
        </SkeletonPage>
      </Layer>
    );
  }

  if (isLoadingCollectors) {
    return (
      <Layer title="Cobradores" forbidden={forbidden}>
        <SkeletonPage
          breadcrumbs
          title={<SkeletonDisplayText size="medium" />}
          primaryAction={<SkeletonDisplayText size="medium" />}
        >
          <Layout>
            <Layout.AnnotatedSection
              title={<SkeletonDisplayText size="medium" />}
              description={<SkeletonBodyText lines={2} />}
            >
              {Array.from(Array(3).keys()).map((item) => {
                return (
                  <div key={item} className="mb-8">
                    <Card sectioned>
                      <Stack>
                        <SkeletonThumbnail size="small" />
                        <div style={{ minWidth: 300 }}>
                          <SkeletonBodyText lines={2} />
                        </div>
                      </Stack>
                    </Card>
                  </div>
                );
              })}
            </Layout.AnnotatedSection>
          </Layout>
        </SkeletonPage>
      </Layer>
    );
  }
  return (
    <Layer title="Vendedores y cobradores" forbidden={forbidden}>
      <Page
        title="Vendedores y cobradores"
        breadcrumbs={[
          { content: "Configuración", url: "/admin/configuracion" },
        ]}
      >
        <Layout>
          <Layout.AnnotatedSection
            title="Vendedores"
            description="Gestiona a los vendedores que atienden a tus clientes."
          >
            <Card>
              <DataTable
                columnContentTypes={["text", "numeric"]}
                headings={["Nombre", "Comisión"]}
                rows={vendors_rows}
                hoverable={false}
              />
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title="Cobradores"
            description="Gestiona a los cobradores que atienden a tus clientes."
          >
            <Card>
              <DataTable
                columnContentTypes={["text", "numeric"]}
                headings={["Nombre", "Comisión"]}
                rows={collectors_rows}
                hoverable={false}
              />
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
        <HelpFooter
          title="vendedores y cobradores"
          url="https://help.b2bgo.mx/configuracion/vendedores-y-cobradores"
        />
      </Page>
    </Layer>
  );
}
