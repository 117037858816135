import {
  Card,
  Checkbox,
  FormLayout,
  RangeSlider,
  TextStyle,
} from "@shopify/polaris";
import { useEffect, useState } from "react";

export default function ProductsGrid({
  item = null,
  handleMainChange = () => {},
}) {
  const [productGrid, setProductGrid] = useState({
    rowsNumberDesktop: 4,
    showKey: true,
    showAvailability: true,
    showDisccount: true,
    showUnitButton: true,
    rowsNumberInMobile: 2,
  });

  useEffect(() => {
    setProductGrid((prevState) => ({
      ...prevState,
      ...item.properties,
    }));
  }, [item]);

  const handleChange = (field, value) => {
    handleMainChange("properties", { ...productGrid, [field]: value }, item.id);
    setProductGrid((prevState) => ({ ...prevState, [field]: value }));
  };

  const {
    rowsNumberDesktop,
    showKey,
    showAvailability,
    showDisccount,
    showUnitButton,
    rowsNumberInMobile,
  } = productGrid;

  return (
    <Card>
      <Card.Section>
        <FormLayout>
          <TextStyle variation="strong">Cuadrícula de productos</TextStyle>

          <div className="flex flex-row items-end max-items">
            <RangeSlider
              label="Número de columnas en el escritorio"
              output
              min={1}
              max={5}
              value={rowsNumberDesktop}
              onChange={(val) => handleChange("rowsNumberDesktop", val)}
            />
            <div className="ml-6 whitespace-nowrap">
              <TextStyle>{rowsNumberDesktop}</TextStyle>
            </div>
          </div>
          <Checkbox
            label="Mostrar clave"
            checked={showKey}
            onChange={(val) => handleChange("showKey", val)}
          />
          <Checkbox
            label="Mostrar disponibles"
            checked={showAvailability}
            onChange={(val) => handleChange("showAvailability", val)}
          />
          <Checkbox
            label="Mostrar descuento"
            checked={showDisccount}
            onChange={(val) => handleChange("showDisccount", val)}
          />
          <Checkbox
            label="Mostrar el botón de unidades y agregar para compra rápida"
            checked={showUnitButton}
            onChange={(val) => handleChange("showUnitButton", val)}
          />
        </FormLayout>
      </Card.Section>
      <Card.Section title="Diseño para móviles">
        <div className="flex flex-row items-end max-items">
          <RangeSlider
            label="Número de columnas en el escritorio"
            output
            min={1}
            max={2}
            value={rowsNumberInMobile}
            onChange={(val) => handleChange("rowsNumberInMobile", val)}
          />
          <div className="ml-6 whitespace-nowrap">
            <TextStyle>{rowsNumberInMobile}</TextStyle>
          </div>
        </div>
      </Card.Section>
    </Card>
  );
}
