import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../../reducers";
import ApiServiceComercios from "../../../services/ApiServiceComercios";
import { errorsTraslations } from "../../../constants/errorTraslations";
export default function StripeOrderCheckoutContainer({
  children,
  setIsProcessing,
  draftorderId,
  setHasSuccessPayment,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [hasSuccessResponse, setHasSuccessResponse] = useState(false);
  const [confirmPaymentData, setConfirmPaymentData] = useState(null);

  const userData = useSelector(getUserInfo);

  const handleSubmit = useCallback(
    async (event) => {
      const { email, nombre } = userData;
      event.preventDefault();

      try {
        if (!stripe || !elements) {
          return;
        }
        setIsProcessing(true);
        //getUserData selector
        const response = await stripe.confirmPayment({
          elements,
          confirmParams: {
            payment_method_data: {
              billing_details: {
                email: email,
                name: nombre,
              },
            },
            receipt_email: email,
          },
          redirect: "if_required",
        });
        const { error, paymentIntent } = response;

        if (error) {
          const data = {
            paymentIntentId: error?.payment_intent?.id,
            origin: "orders",
            draftOrderId: draftorderId,
          };
          await ApiServiceComercios.createAndConfirmPaymentIntent(data);
          if (error?.decline_code) {
            setMessage(errorsTraslations(error?.decline_code)[0]?.messageES);
          } else {
            setMessage(errorsTraslations(error?.code)[0]?.messageES);
          }
          setIsProcessing(false);
        } else if (paymentIntent && paymentIntent.status === "succeeded") {
          const data = {
            paymentIntentId: response?.paymentIntent?.id,
            origin: "orders",
            draftOrderId: draftorderId,
          };
          await ApiServiceComercios.createAndConfirmPaymentIntent(data).then(
            (res) => {
              if (res?.ok) {
                setIsProcessing(false);
                setConfirmPaymentData({
                  orderId: res?.orderId,
                  folio: res?.folio,
                  paymentMethod: res?.paymentMethod,
                });
                setHasSuccessResponse(true);
                setHasSuccessPayment(true);
              }
            }
          );
        }
      } catch (error) {
        setIsProcessing(false);
      }
    },
    [
      elements,
      stripe,
      userData,
      draftorderId,
      setIsProcessing,
      setHasSuccessPayment,
    ]
  );

  return (
    <>
      {children({
        handleSubmit,
        message,
        hasSuccessResponse,
        confirmPaymentData,
      })}
    </>
  );
}
