import ApiServiceComercios from "../services/ApiServiceComercios";
import NormalizeById from "../utils/NormalizedById";
import { getPaginationPedidos } from "../reducers";

export const PEDIDOS_BEGIN = "PEDIDOS_BEGIN";
export const ADD_PEDIDOS = "ADD_PEDIDOS";
export const PEDIDOS_FAILED = "PEDIDOS_FAILED";
export const CLEAR_PEDIDOS = "CLEAR_PEDIDOS";
export const RESET_PAGE = "RESET_PAGE";
export const ADD_ID_PEDIDO = "ADD_ID_PEDIDO";
export const CHANGE_PAGE = "CHANGE_PAGE";

export function fetchPedidos(params) {
  return async (dispatch, getState) => {
    let pagination = getPaginationPedidos(getState());
    dispatch(clearPedidos());
    dispatch(pedidosBegin());
    return ApiServiceComercios.obtenerPedidos({
      ...params,
      limit: pagination.sizePerPage,
      skip: pagination.currentPage * pagination.sizePerPage,
    })
      .then((result) => {
        let data = NormalizeById(result.pedidos, "_id");
        data["total"] = result.total;
        data["allIdsDB"] = result.allIds;
        dispatch(addPedidos(data));
        return result;
      })
      .catch((err) => {
        dispatch(pedidosFailed());
      });
  };
}

export const pedidosBegin = () => ({
  type: PEDIDOS_BEGIN,
});

export const addPedidos = (data) => ({
  type: ADD_PEDIDOS,
  payload: { data },
});

export const pedidosFailed = () => ({
  type: PEDIDOS_FAILED,
});

export const clearPedidos = () => ({
  type: CLEAR_PEDIDOS,
});

export const changePage = (currentPage) => ({
  type: CHANGE_PAGE,
  payload: { data: { currentPage } },
});

export const resetPage = () => ({
  type: RESET_PAGE,
});

export const addIdPedido = (id) => ({
  type: ADD_ID_PEDIDO,
  payload: { id },
});
