import React, { useState } from "react";
import { Popover, ActionList } from "@shopify/polaris";

export default function CustomPopover({ activator: Activator, items }) {
  const [popoverActive, setPopoverActive] = useState(false);
  function togglePopoverActive() {
    setPopoverActive(!popoverActive);
  }
  return (
    <Popover
      preferredAlignment="left"
      activator={<Activator onClick={togglePopoverActive} />}
      active={popoverActive}
      onClose={togglePopoverActive}
    >
      <ActionList
        items={items.map((i) => ({
          ...i,
          onAction: () => {
            i.onAction && i.onAction();
            togglePopoverActive();
          },
        }))}
      />
    </Popover>
  );
}
