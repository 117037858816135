import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { obtenerComercios, editarComercio } from '../../actions/ComerciosActions';
import { getComercios, getPaginationComercios, isLoadingComercios } from '../../reducers';

export default function ComerciosContainer({children}) {
   const dispatch = useDispatch();
   const isLoading = useSelector(isLoadingComercios);
   const [isEditing, setIsEditing] = useState(false);
   const pagination = useSelector(getPaginationComercios);
   const comercios = useSelector(getComercios).map(item => { return { ...item, id: item._id }});

   function dispatchMore(params) {
      dispatch(obtenerComercios(params));
   }
   
   function modificarComercio({comercio}, id, formData) {
      setIsEditing(true);
      return dispatch(editarComercio({comercio, id, formData}))
      .then((res) => {
         return res;
      }).finally(() => setIsEditing(false));
   }

   return (
      <React.Fragment>{children({comercios: comercios, pagination, isLoading, dispatchMore, modificarComercio, isEditing })}</React.Fragment>
   )
}
