export const TOGGLE_TOAST = 'TOGGLE_TOAST';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const UPDATE_VISITORS = 'UPDATE_VISITORS';


export const toggleToast = (data = { message: "" }) => ({
   type: TOGGLE_TOAST,
   payload: { data },
});

export const setIsLoadingProgressBar = (data = { active: false }) => ({
   type: SET_IS_LOADING,
   payload: { data }
});

export const updateVisitors = (data = { visitors: 0 }) => ({
   type: UPDATE_VISITORS,
   payload: { data },
});
