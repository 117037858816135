import React from "react";
import { TextStyle, DisplayText, Spinner } from "@shopify/polaris";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
} from "recharts";
import DoubleText from "../DoubleText";
import { NumberFormat } from "../../utils/Formats";

export default function ReportBarChar({
  classes,
  data,
  color,
  title,
  subtitle,
  total,
  value,
  isLoading,
  variant,
  dataKey,
}) {
  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="p-8 bg-white bg-opacity-75">
          <p className="text-gray-700 font-bold">{`${label}`}</p>
          <p className="font-extrabold" style={{ color }}>
            Total:{" "}
            {`${variant === "number" ? "$" : ""}${Number(
              payload ? payload[0].value : 0
            ).toLocaleString("en", NumberFormat)}`}
          </p>
          <p className="font-light text-gray-600">
            De{" "}
            {data.some((i) => i.name === label)
              ? data.find((i) => i.name === label)[dataKey]
              : ""}{" "}
            {dataKey}
          </p>
        </div>
      );
    }
    return null;
  };
  return (
    <div className={`${classes} p-6 border-separator`}>
      <DoubleText title={title} subtitle={subtitle} />

      {!isLoading ? (
        <div>
          <div className="flex flex-row items-center justify-between my-4">
            <DisplayText element="h1" size="large">
              {total}
            </DisplayText>
            <TextStyle variation="subdued">{value}</TextStyle>
          </div>
          <div className="w-full" style={{ height: 150 }}>
            <ResponsiveContainer>
              <BarChart
                data={data}
                margin={{
                  top: 5,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="0" vertical={false} />
                <XAxis dataKey="name" />
                <YAxis type="number" dy={0} axisLine={false} />
                {data.length > 0 && <Tooltip content={<CustomTooltip />} />}
                <Bar dataKey="total" fill={color} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-full">
          <div style={{ height: 202 }} />
          <Spinner />
        </div>
      )}
    </div>
  );
}
