import React from "react";
import { Button } from "antd";
import { TickSmallMinor } from "@shopify/polaris-icons";

const GhostButton = ({ isSaved, label, ...restProps }) => {
  return (
    <div className="flex justify-end">
      <Button
        ghost
        disabled
        loading={isSaved}
        icon={
          <TickSmallMinor
            style={{
              fill: "#86b2ef",
              inlineSize: "20px",
              margin: "auto",
            }}
          />
        }
      >
        {isSaved ? label : restProps.secondLabel}
      </Button>
    </div>
  );
};
export default GhostButton;
