import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Checkbox, Icon } from "@shopify/polaris";
import { CircleDownMajor, DragHandleMinor } from "@shopify/polaris-icons";
import React, { useEffect, useRef, useState } from "react";
import ImageZoom from "react-medium-image-zoom";
import ReactPlayer from "react-player";
import "./ZoomImageStyles.css";

export default function ZoomImage({
  images = [],
  videos = [],
  children,
  handleSelectImage = () => {},
  selectedImages = [],
  setSelectedFiles = () => {},
  selectedFiles = [],
  version = 1,
  saveImages = () => {},
  onChangeImages = () => {},
  edit = false,
  toggleEdit = () => {},
}) {
  function RenderZoomImage({ img }) {
    const imageZoomRef = useRef(null);
    const [height, setHeight] = useState(0);
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id: img?.id });

    const style = {
      transition,
      transform: CSS.Transform.toString(transform),
    };

    useEffect(() => {
      const handleResize = () => setHeight(imageZoomRef?.current?.offsetWidth);

      handleResize();

      window.addEventListener("resize", handleResize);

      return () => window.removeEventListener("resize", handleResize);
    }, [imageZoomRef]);

    function handleSelect(value, image) {
      handleSelectImage(value, image);
    }

    const desingMarkup = (
      <>
        <div
          className={`bg-white  ${
            selectedImages.some((image) => image === img?.url)
              ? "border-purple-400"
              : "border-gray-400"
          }`}
        >
          {isOver || edit || selectedImages.length > 0 ? (
            <div className="flex flex-row justify-between items-center absolute top-0 left-0 right-0">
              <div className="p-2 zoom-checkbox ">
                {version === 1 ? (
                  <Checkbox
                    checked={selectedImages.some((image) => image === img?.url)}
                    onChange={(value) => handleSelect(value, img?.url)}
                  />
                ) : (
                  <Checkbox
                    onChange={(val) => {
                      if (val) {
                        setSelectedFiles((prevState) => [
                          ...new Set([...prevState, img?.url]),
                        ]);
                      } else {
                        setSelectedFiles((prevState) =>
                          prevState.filter((url) => url !== img?.url)
                        );
                      }
                    }}
                    checked={selectedFiles.some((image) => image === img?.url)}
                  />
                )}
              </div>

              <div
                className="p-2  zoom-checkbox "
                onMouseDown={() => longPress()}
              >
                {/* <Button
            external
            url={version === 1 ? img : img.url}
            icon={DragHandleMinor}
            plain
          /> */}
                <Icon source={DragHandleMinor} color="base" />
              </div>
            </div>
          ) : undefined}
        </div>
        <div
          ref={imageZoomRef}
          style={{ height }}
          className="overflow-hidden justify-center align-center"
        >
          <ImageZoom
            onZoom={() => {
              setTimeout(() => {
                let zoomImage = document.querySelector(".zoom-img");
                if (zoomImage) {
                  let zoomImageParent = zoomImage.parentElement;
                  zoomImageParent.classList = ["img-zoom-container"];
                }
              }, 50);
            }}
            image={{
              src: version === 1 ? img?.url : img.url,
              alt: "",
              className: "static-image self-center bg-white",
            }}
            zoomImage={{
              src: version === 1 ? img?.url : img.url,
              className: "zoom-img",
            }}
          />
        </div>
      </>
    );

    return (
      <div
        className="flex flex-col rounded-lg border-2 justify-center items-center"
        onMouseOver={() => setIsOver(true)}
        onMouseLeave={() => setIsOver(false)}
      >
        <div className={"flex flex-col w-full justify-between relative"}>
          {edit ? (
            <div
              ref={setNodeRef}
              {...attributes}
              {...listeners}
              style={style}
              className="z-10"
            >
              {desingMarkup}
            </div>
          ) : (
            desingMarkup
          )}
        </div>
      </div>
    );
  }

  function RenderVideo({ vid }) {
    const player = useRef(null);

    function handleSelect(value, image) {
      handleSelectImage(value, image);
    }

    return (
      <div
        className={`relative border-2 rounded flex overflow-hidden ${
          selectedImages.some((image) => image === vid)
            ? "border-purple-400"
            : "border-gray-400"
        }`}
      >
        <ReactPlayer
          ref={player}
          url={version === 1 ? vid : vid.url}
          height={100}
          width={100}
          controls
          style={{ aspectRatio: "1/1" }}
        />

        <div className="absolute p-2 top-0 right-0 zoom-checkbox">
          <Button
            external
            url={version === 1 ? vid : vid.url}
            icon={CircleDownMajor}
            plain
          />
        </div>
        <div className="absolute p-2 top-0 left-0 zoom-checkbox">
          {version === 1 ? (
            <Checkbox
              checked={selectedImages.some((image) => image === vid)}
              onChange={(value) => handleSelect(value, vid)}
            />
          ) : (
            <Checkbox
              onChange={(val) => {
                if (val) {
                  setSelectedFiles((prevState) => [
                    ...new Set([...prevState, vid.url]),
                  ]);
                } else {
                  setSelectedFiles((prevState) =>
                    prevState.filter((url) => url !== vid.url)
                  );
                }
              }}
              checked={selectedFiles.some((image) => image === vid.url)}
            />
          )}
        </div>
      </div>
    );
  }

  const [isOver, setIsOver] = useState(false);
  const [imagesFormat, setImagesFormat] = useState([]);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    const imgFormat = [];
    images.forEach((i, index) => {
      imgFormat.push({
        id: index + 1,
        url: i,
      });
    });
    setImagesFormat(imgFormat);
  }, [images]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  useEffect(() => {
    if (dirty) {
      saveImages();
      setDirty(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty]);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      const oldIndex = imagesFormat.findIndex((row) => row?.id === active?.id);
      const newIndex = imagesFormat.findIndex((row) => row?.id === over?.id);

      const newData = arrayMove(imagesFormat, oldIndex, newIndex);
      setImagesFormat(newData);
      onChangeImages(newData.map((i) => i.url));
      setDirty(true);
    }

    edit && toggleEdit();
  };

  const longPress = () => {
    !edit && toggleEdit();
  };

  return (
    <div
      className={images.length > 0 ? "zoomContainer" : "zoomContainerDefault"}
    >
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <SortableContext
          items={imagesFormat}
          strategy={horizontalListSortingStrategy}
        >
          {imagesFormat.map((img, index) => (
            <RenderZoomImage key={index} img={img} />
          ))}
        </SortableContext>
      </DndContext>

      {videos.map((vid, index) => (
        <RenderVideo key={index} vid={vid} />
      ))}
      {children}
    </div>
  );
}
