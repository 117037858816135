import React from "react";
import { useSelector } from "react-redux";
import {
  getPaginationFacturas,
  getFacturas,
  isLoadingFacturas,
} from "../../reducers";
import { BillRegExp } from "../../utils/RegEx";

export default function FacturasContainer({ children }) {
  const isLoading = useSelector(isLoadingFacturas);
  let facturas = useSelector(getFacturas).map((item) => ({
    ...item,
    id: item._id,
    shouldPay:
      BillRegExp.test(item.folioMicrosip) && [1, 2].includes(item.estatus.tipo),
  }));

  const pagination = useSelector(getPaginationFacturas);

  let hasUnpaidBills = facturas.some(({ shouldPay }) => shouldPay);

  return <>{children({ isLoading, facturas, pagination, hasUnpaidBills })}</>;
}
