import {
  ADD_FACTURAS,
  ADD_ID_FACTURA,
  CLEAR_FACTURAS,
  FACTURAS_BEGIN,
  FACTURAS_FAILED,
  RESET_PAGE,
  CHANGE_PAGE,
} from "../actions/FacturasActions";

const initialState = {
  byId: {},
  allIds: [],
  allIdsDB: [],
  isLoading: false,
  currentPage: 0,
  sizePerPage: 20,
  total: 0,
  moneda: "",
};

export default function facturasReducer(state = initialState, action) {
  switch (action.type) {
    case FACTURAS_BEGIN: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ADD_FACTURAS: {
      let allIds = [
        ...new Set(state.allIds.concat(action.payload.data.allIds)),
      ];
      let byId = {
        ...state.byId,
        ...action.payload.data.byId,
      };
      let allIdsDB = action.payload.data.allIdsDB;
      return {
        ...state,
        allIds,
        byId,
        allIdsDB,
        total: action.payload.data.total,
        moneda: action.payload.data.moneda,
        isLoading: false,
      };
    }
    case CLEAR_FACTURAS: {
      return {
        ...state,
        byId: {},
        allIds: [],
        allIdsDB: [],
        isLoading: false,
      };
    }
    case FACTURAS_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case CHANGE_PAGE: {
      const currentPage = action.payload.data.currentPage;
      return {
        ...state,
        currentPage,
      };
    }
    case RESET_PAGE: {
      return {
        ...state,
        currentPage: 0,
        total: 0,
      };
    }

    case ADD_ID_FACTURA: {
      let allIdsDB = [...new Set(state.allIdsDB.concat([action.payload.id]))];
      return {
        ...state,
        allIdsDB,
      };
    }
    default: {
      return state;
    }
  }
}

export const isLoadingFacturas = (state) => {
  return state.isLoading;
};

export const getFacturas = (state) => {
  return state.allIds.map((id) => state.byId[id]);
};
