import {
  Autocomplete,
  Card,
  Icon,
  SkeletonBodyText,
  Tag,
  TextStyle,
} from "@shopify/polaris";
import { CirclePlusMajor, SearchMinor } from "@shopify/polaris-icons";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import useGetTags from "../../hooks/useGetTags";
import useDebounce from "../../services/useDebounce";

export default function TagsAutocomplete({
  subdued = true,
  title = "Etiquetas",
  selected = [],
  onSelect = () => {},
  outLoading = false,
  module = "Artículos",
  useCard = true,
  placeholder = "Selecciona una etiqueta",
  createTags = true,
  listTitle = "Etiquetas sugeridas",
}) {
  const [selectedOptions, setSelectedOptions] = useState(selected);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const { tags, loading, createTag, fetchTags } = useGetTags({
    module,
    query: "",
  });

  const debounceInputValue = useDebounce(inputValue, 900);

  useEffect(() => {
    const tagsFormatted = tags.map((tag) => ({
      label: tag.name,
      value: tag.name,
    }));

    setOptions(tagsFormatted);
  }, [tags]);

  useEffect(() => {
    fetchTags(debounceInputValue.trim());
  }, [debounceInputValue, fetchTags]);

  const updateSelection = useCallback(
    (selected) => {
      onSelect(selected);
      setSelectedOptions(selected);
      setInputValue("");
    },
    [onSelect]
  );

  const onAddNew = () => {
    try {
      const tag = inputValue.trim();
      createTag(tag).then(() => {
        const selectedData = [...selectedOptions, tag];
        updateSelection(selectedData);
        fetchTags(debounceInputValue);
      });
    } catch (error) {}
  };

  const onRemoveTag = (option) => {
    const selected = selectedOptions.filter((i) => i !== option);
    setSelectedOptions(selected);
    onSelect(selected);
  };

  const validateExistence = () => {
    const currentOption = options.find(
      (item) => item.label === inputValue.trim()
    );
    return currentOption ? currentOption.label : "";
  };

  const tagsMarkup = selectedOptions?.map((option) => {
    return (
      <div className="flex mr-4 mb-4" key={`option${option}`}>
        <Tag onRemove={() => onRemoveTag(option)}>{option}</Tag>
      </div>
    );
  });

  const textField = (
    <Autocomplete.TextField
      onChange={setInputValue}
      value={inputValue}
      prefix={<Icon source={SearchMinor} color="base" />}
      placeholder={placeholder}
      autoComplete="off"
      label={!useCard && title}
    />
  );

  const autocomplete = (
    <>
      <Autocomplete
        options={loading || outLoading ? [] : _.sortBy(options, "label")}
        selected={selectedOptions}
        onSelect={updateSelection}
        textField={textField}
        loading={loading}
        willLoadMoreResults
        listTitle={listTitle}
        actionBefore={
          createTags
            ? !validateExistence() && inputValue.trim()
              ? {
                  content: (
                    <>
                      <div className="flex flex-row items-center cursor-pointer text-link">
                        <div className="mr-2">
                          <Icon source={CirclePlusMajor} color="interactive" />
                        </div>
                        <TextStyle>Agregar {inputValue}</TextStyle>
                      </div>
                    </>
                  ),
                  onAction: onAddNew,
                }
              : null
            : null
        }
        allowMultiple
      />
      {loading || outLoading ? (
        <div className="mt-8">
          <SkeletonBodyText lines={3} />
        </div>
      ) : tagsMarkup.length ? (
        <div className="flex flex-wrap my-8">{tagsMarkup}</div>
      ) : null}
    </>
  );

  return useCard ? (
    <Card title={title} sectioned subdued={subdued}>
      {autocomplete}
    </Card>
  ) : (
    autocomplete
  );
}
