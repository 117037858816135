export const FirstCollectionCondition = [
  { label: "Nombre del producto", value: "nombre" },
  { label: "Marca del producto", value: "marca" },
  { label: "Tipo del producto", value: "linea_articulo" },
  { label: "Etiqueta del producto", value: "etiquetas" },
  { label: "Precio", value: "precios" },
  { label: "Existencia del inventario", value: "existencia" },
  { label: "Creación del producto", value: "createdDate" },
];

export const SecondCollectionCondition = [
  { label: "Es igual", value: "equalsTo" },
  { label: "No es igual", value: "notEquals" },
  { label: "Es mayor que", value: "greaterThan" },
  { label: "Es menor que", value: "lowerThan" },
  { label: "Comienza con", value: "startsWith" },
  { label: "Termina en", value: "endsWith" },
  { label: "Contiene", value: "contains" },
  { label: "No contiene", value: "notContains" },
];
