import { Card, FooterHelp, Link, Page, Tabs } from "@shopify/polaris";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchFacturas, resetPage } from "../../actions/FacturasActions";
import { FacturasList, FooterCliente, Layer } from "../../components";
import ManualPaymentModal from "../../components/ManualPaymentModal/ManualPaymentModal";
import StripePaymentModal from "../../components/StripeBillingModal/components/StripePaymentModal";
import FacturasContainer from "../../containers/FacturasContainer";
import useAbility from "../../hooks/useAbility";
import useToggle from "../../hooks/useToggle";
import useUrlSearch from "../../hooks/useUrlSearch";
import { getBillCurrency, getPaginationFacturas } from "../../reducers";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import useDebounce from "../../services/useDebounce";
import { TabsFacturasScreen as tabs } from "../../utils/Tabs";

const objSearch = [
  "query",
  "tab_selected",
  "status",
  "created_at",
  "selected_dates",
  "sort",
  "order",
];
export default function FacturasScreen({ forbidden }) {
  const { pathname } = useLocation();
  const isForbiddenPayments = useAbility("read", "Pagos");
  const dispatch = useDispatch();
  const [isActiveModal, toggleModal] = useToggle(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const pagination = useSelector(getPaginationFacturas);
  const currency = useSelector(getBillCurrency);
  const { updateHistory, getSearch } = useUrlSearch({ objSearch, pathname });

  const queryValueRef = useRef(
    decodeURIComponent(getSearch("query", "", true))
  );

  const [amount, setAmount] = useState(0);
  const [usePaymentsInBills, setUsePaymentsInBills] = useState(false);
  const [sort, setSort] = useState(getSearch("sort", ["folioMicrosip"]));
  const [orden, setOrder] = useState(getSearch("order", ["-1"]));
  const [paymentStatus, setPaymentStatus] = useState(getSearch("status", []));
  const [createdAt, setCreatedAt] = useState(
    getSearch("created_at", "todos", true)
  );
  const [preferences, setPreferences] = useState({
    showReferrals: false,
  });
  const [tabSelected, setTabSelected] = useState(
    Number(getSearch("tab_selected", 0, true))
  );
  const [queryValue, setQueryValue] = useState(
    decodeURIComponent(getSearch("query", "", true))
  );
  const [selectedCreatedAt, setSelectedCreatedAt] = useState(
    getSearch("selected_dates", {
      start: new Date(),
      end: new Date(),
    })
  );
  const [isActiveManualPaymentModal, toggleManualPaymentModal] =
    useToggle(false);

  const debouncedSearchTerm = useDebounce(queryValue, 900);
  useEffect(() => {
    queryValueRef.current = queryValue;
  });

  useEffect(() => {
    dispatch(
      fetchFacturas({
        sort: sort[0],
        query: decodeURIComponent(queryValueRef.current),
        orden: orden[0],
        estatus: JSON.stringify([
          ...new Set(
            paymentStatus.concat(
              tabSelected === 0 ? [] : [tabs[tabSelected].panelID]
            )
          ),
        ]),
        fecha: createdAt === "todos" ? undefined : selectedCreatedAt,
      })
    );
    ApiServiceComercios.getPaymentConfigurationDetails().then(
      ({ usePaymentsInBills }) => {
        setUsePaymentsInBills(usePaymentsInBills);
      }
    );
    return () => null;
  }, [
    dispatch,
    sort,
    orden,
    tabSelected,
    createdAt,
    paymentStatus,
    debouncedSearchTerm,
    selectedCreatedAt,
    pagination.currentPage,
  ]);

  useEffect(() => {
    ApiServiceComercios.obtenerPreferenciasComercio().then(
      ({ preferencia }) => {
        const { showReferrals = false } = preferencia;
        setPreferences({ showReferrals });
      }
    );
  }, []);

  function handleChangeStates(
    value,
    setState,
    key,
    useStringify = true,
    resetTab = false
  ) {
    updateHistory(
      {
        [key]: value,
      },
      useStringify
    );
    resetPagination();
    setState(value);
    if (resetTab) {
      setTabSelected(0);
      updateHistory(
        {
          tab_selected: 0,
        },
        false
      );
    }
  }

  function resetPagination() {
    dispatch(resetPage());
  }

  const handlePayment = (amount, ids = [], manual) => {
    if (ids.length > 0) {
      setSelectedItems(ids);
    }
    setAmount(amount);
    if (manual) {
      toggleManualPaymentModal();
    } else {
      toggleModal();
    }
  };

  const onClose = (reload = false, manual = false) => {
    reload &&
      dispatch(
        fetchFacturas({
          sort: sort[0],
          query: decodeURIComponent(queryValueRef.current),
          orden: orden[0],
          estatus: JSON.stringify([
            ...new Set(
              paymentStatus.concat(
                tabSelected === 0 ? [] : [tabs[tabSelected].panelID]
              )
            ),
          ]),
          fecha: createdAt === "todos" ? undefined : selectedCreatedAt,
        })
      );
    setAmount(0);
    setSelectedItems([]);
    if (manual) {
      toggleManualPaymentModal();
    } else {
      toggleModal();
    }
  };

  return (
    <Layer
      title={preferences.showReferrals ? "Facturas y remisiones" : "Facturas"}
      forbidden={forbidden}
      scrollToTop
    >
      <Page
        title={preferences.showReferrals ? "Facturas y remisiones" : "Facturas"}
        fullWidth
      >
        <Card>
          <FacturasContainer>
            {({ isLoading, facturas, pagination, hasUnpaidBills }) => {
              return (
                <Tabs
                  tabs={tabs}
                  selected={tabSelected}
                  onSelect={(value) =>
                    handleChangeStates(
                      value,
                      setTabSelected,
                      "tab_selected",
                      false
                    )
                  }
                >
                  <FacturasList
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    onPay={handlePayment}
                    createdAt={createdAt}
                    selectedCreatedAt={{
                      start: new Date(selectedCreatedAt.start),
                      end: new Date(selectedCreatedAt.end),
                    }}
                    setSelectedCreatedAt={(value) =>
                      handleChangeStates(
                        value,
                        setSelectedCreatedAt,
                        "selected_dates"
                      )
                    }
                    handleCreatedAtChange={(_, value) =>
                      handleChangeStates(
                        value,
                        setCreatedAt,
                        "created_at",
                        false
                      )
                    }
                    handleDateRemove={() =>
                      handleChangeStates(
                        "todos",
                        setCreatedAt,
                        "created_at",
                        false
                      )
                    }
                    handleFiltersQueryChange={(value) =>
                      handleChangeStates(
                        value ? encodeURIComponent(value) : value,
                        setQueryValue,
                        "query",
                        false
                      )
                    }
                    handleOrdenChange={(value) =>
                      handleChangeStates(value, setOrder, "order")
                    }
                    handleOrderSelectedChange={(value) =>
                      handleChangeStates(value, setSort, "sort")
                    }
                    handleOrderSelectedRemove={() =>
                      handleChangeStates(["folio"], setSort, "sort")
                    }
                    handleQueryValueRemove={() =>
                      handleChangeStates("", setQueryValue, "query", false)
                    }
                    isLoading={isLoading}
                    orden={orden}
                    orderSelected={sort}
                    pagination={pagination}
                    queryValue={queryValue}
                    data={facturas}
                    paymentStatus={paymentStatus}
                    handlePaymentStatusChange={(value) =>
                      handleChangeStates(
                        value,
                        setPaymentStatus,
                        "status",
                        true,
                        true
                      )
                    }
                    handlePaymentStatusRemove={() =>
                      handleChangeStates(
                        [],
                        setPaymentStatus,
                        "status",
                        true,
                        true
                      )
                    }
                    hasUnpaidBills={hasUnpaidBills}
                    usePaymentsInBills={usePaymentsInBills}
                  />
                  {!isForbiddenPayments &&
                    usePaymentsInBills &&
                    hasUnpaidBills &&
                    isActiveModal && (
                      <StripePaymentModal
                        activeModal={isActiveModal}
                        amount={amount}
                        onClose={onClose}
                        origin="billing"
                        currency={currency}
                        billId={selectedItems}
                      />
                    )}
                  {!isForbiddenPayments &&
                    usePaymentsInBills &&
                    hasUnpaidBills &&
                    isActiveManualPaymentModal && (
                      <ManualPaymentModal
                        active={isActiveManualPaymentModal}
                        onClose={toggleManualPaymentModal}
                        amount={amount}
                        origin="billing"
                        dataOrder={{}}
                        setIsBlocking={() => {}}
                        setErrorArticulos={() => {}}
                        ids={selectedItems}
                      />
                    )}
                </Tabs>
              );
            }}
          </FacturasContainer>
        </Card>
        <FooterHelp>
          Más información de{" "}
          <Link url="https://help.b2bgo.mx/docs/actualizaciones/recientes/descarga-de-facturas/">
            facturas
          </Link>
        </FooterHelp>
      </Page>
      <FooterCliente />
    </Layer>
  );
}
