import React, { useEffect, useState } from "react";
import {
  Card,
  DataTable,
  Layout,
  Link,
  Page,
  TextStyle,
} from "@shopify/polaris";
import { useDispatch } from "react-redux";
import { FiltroMenuSkeleton, Layer } from "../../components";
import { fecthFilters } from "../../actions/FiltersActions";
import "./FiltroMenuScreen.css";
import ApiServiceComercios from "../../services/ApiServiceComercios";

export default function FiltroMenuScreen({ forbidden }) {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState([]);
  const [menus, setMenus] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(fecthFilters())
      .then(({ filters = [] }) => {
        setFilters(filters);
      })
      .finally(() => setIsLoading(false));
  }, [dispatch]);

  useEffect(() => {
    ApiServiceComercios.getMenuList().then(({ menus }) => {
      setMenus(menus);
    });
  }, []);

  if (isLoading) {
    return (
      <Layer title="Navegación" forbidden={forbidden}>
        <FiltroMenuSkeleton />
      </Layer>
    );
  }

  const titleMenuData = (
    <div>
      Menús
    </div>
  )

  const menuData = [
    {
      title: "Menú principal",
      url: "/admin/tienda_online/menus",
      options: menus,
    },
  ];

  const filtersData = [
    { title: "Filtros", url: "/admin/tienda_online/filtros", options: filters },
  ];

  //borrar

  return (
    <Layer title="Navegación" forbidden={forbidden}>
      <Page title="Navegación" separator fullWidth>
        <Layout>
          <Layout.AnnotatedSection
            title={titleMenuData}
            description="Los menús ayudan a tus clientes a navegar en tu tienda online. También puedes crear menús anidados para mostrar menús desplegables."
          >
            <Card>
              <DataTable
                columnContentTypes={["text", "text"]}
                headings={["Título", "Elementos del menú"]}
                rows={menuData.map(({ url, title, options }) => {
                  const titleText = <Link url={url}>{title}</Link>;
                  const optionsText = (
                    <TextStyle>
                      {options.map((i) => i.title).join(", ")}
                    </TextStyle>
                  );

                  return [titleText, optionsText];
                })}
              />
            </Card>
          </Layout.AnnotatedSection>

          <Layout.AnnotatedSection
            title="Filtros"
            description="Permite a tus clientes filtrar colecciones y resultados de búsqueda por tipo de productos, precio, color y otros atributos personalizados."
          >
            <Card>
              <DataTable
                columnContentTypes={["text", "text"]}
                headings={["Título", "Elementos del filtro"]}
                rows={filtersData.map(({ url, title, options }) => {
                  const titleText = <Link url={url}>{title}</Link>;
                  const optionsText = (
                    <TextStyle>
                      {" "}
                      {options.map((i) => i.title).join(", ")}
                    </TextStyle>
                  );

                  return [titleText, optionsText];
                })}
              />
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </Page>
    </Layer>
  );
}
