import { b2bgoApiaxios, b2bgoAuthaxios } from "../utils/Axios";

const ApiServiceAuth = {
  API_AUTH: process.env.NODE_ENV === "development" ? "3001" : "auth",
  /**
   * AUTH
   */
  async loginEmail(email) {
    const method = "GET";
    const url = "/auth/login";
    let host =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_CURRENT_HOST
        : window.location.hostname;
    const params = { email, host };
    return this.makeRequest({ method, url, params });
  },

  async login() {
    const method = "POST";
    const url = "/auth/login";
    const host =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_CURRENT_HOST
        : window.location.hostname;
    const params = { host };
    return this.makeAuthorizedRequest({ method, url, params });
  },

  async logout(id) {
    const method = "POST";
    const url = "/auth/logout";
    const data = { id };
    return this.makeAuthorizedRequest({ method, url, data });
  },

  async recoveryPassword({ email, grantType }) {
    const method = "POST";
    const url = "/auth/recovery_password";
    const host =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_CURRENT_HOST
        : window.location.hostname;
    const data = { email, host, grantType };
    return this.makeRequest({ method, url, data });
  },

  async resetPassword({ token, password, idComercio }) {
    const method = "POST";
    const params = this.makeQueryParams({ token });
    const url = `/auth/reset_password?${params}`;
    const data = { password, idComercio };
    return this.makeRequest({ method, url, data });
  },

  async validateStore() {
    const method = "GET";
    const url = "/auth/validate-store";
    return this.makeRequest({ method, url });
  },

  async suscribeForRefreshAuth(refreshToken) {
    const method = "POST";
    const url = "/auth/refresh";
    const data = { refreshToken };
    const host =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_CURRENT_HOST
        : window.location.hostname;
    const params = { host };
    return this.makeAuthorizedRequest({ method, url, data, params });
  },

  makeQueryParams(params) {
    let queries = "";
    for (var key in params) {
      queries += `${key}=${params[key]}&`;
    }
    return queries;
  },

  async makeRequest(requestData = {}) {
    try {
      let res = await b2bgoApiaxios({ ...requestData, api_uri: this.API_AUTH });
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  async makeAuthorizedRequest(requestData = {}) {
    try {
      let res = await b2bgoAuthaxios({
        ...requestData,
        api_uri: this.API_AUTH,
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  },
};

export default ApiServiceAuth;
