import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Card, Page, Tabs } from "@shopify/polaris";
import { useDispatch, useSelector } from "react-redux";
import { CotizacionesList, Layer } from "../../components";
import CotizacionesContainer from "../../containers/CotizacionesContainer";
import { TabsCotizacionesClienteScreen as tabs } from "../../utils/Tabs";
import {
  fetchCotizaciones,
  resetPage,
} from "../../actions/CotizacionesActions";
import useDebounce from "../../services/useDebounce";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import useGetVendors from "../../hooks/useGetVendors";
import useAbility from "../../hooks/useAbility";
import useUrlSearch from "../../hooks/useUrlSearch";
import { getPaginationCotizaciones } from "../../reducers";

const objSearch = [
  "query",
  "tab_selected",
  "status",
  "sort",
  "order",
  "shipping",
  "created_by",
  "vendors",
  "selected_dates",
  "created_at",
];
export default function CotizacionesClienteScreen({ forbidden }) {
  const isForbiddenCreate = useAbility("write", "Cotizaciones");
  const { pathname } = useLocation();
  const { updateHistory, getSearch } = useUrlSearch({ objSearch, pathname });
  const pagination = useSelector(getPaginationCotizaciones);

  const dispatch = useDispatch();
  const queryValueRef = useRef(
    decodeURIComponent(getSearch("query", "", true))
  );

  const [createdAt, setCreatedAt] = useState(
    getSearch("created_at", "todos", true)
  );
  const [selectedCreatedAt, setSelectedCreatedAt] = useState(
    getSearch("selected_dates", {
      start: new Date(),
      end: new Date(),
    })
  );

  const [tabSelected, setTabSelected] = useState(
    Number(getSearch("tab_selected", 0, true))
  );
  const [queryValue, setQueryValue] = useState(
    decodeURIComponent(getSearch("query", "", true))
  );
  const [estatusCotizacion, setEstatusCotizacion] = useState(
    getSearch("status", [])
  );
  const [vendorCotizacion, setVendorCotizacion] = useState(
    getSearch("vendors", [])
  );
  const [creatorSelected, setCreatorSelected] = useState(
    getSearch("created_by", [])
  );

  const [orden, setOrder] = useState(getSearch("order", ["-1"]));
  const [sort, setSort] = useState(getSearch("sort", ["folio"]));
  const [selectedItems, setSelectedItems] = useState([]);
  const { vendors } = useGetVendors();
  const [shippingMethod, setShippingMethod] = useState(
    getSearch("shipping", [])
  );
  const [envios, setEnvios] = useState([]);

  const debouncedSearchTerm = useDebounce(queryValue, 1500);

  useEffect(() => {
    queryValueRef.current = queryValue;
  });

  useEffect(() => {
    ApiServiceComercios.obtenerEnvios()
      .then((result) => {
        if (result.ok) {
          setEnvios(result.envios);
        }
      })
      .catch((err) => {});
    return () => null;
  }, []);

  useEffect(() => {
    function fetchData() {
      dispatch(
        fetchCotizaciones({
          byCliente: true,
          sort: sort[0],
          query: decodeURIComponent(queryValueRef.current),
          orden: orden[0],
          estatus: JSON.stringify([
            ...new Set(
              estatusCotizacion.concat(
                tabSelected === 0 ? [] : [tabs[tabSelected].panelID]
              )
            ),
          ]),
          vendors: JSON.stringify(vendorCotizacion),
          createdBy: JSON.stringify(creatorSelected),
          shippingMethod: JSON.stringify(shippingMethod),
          fecha: createdAt === "todos" ? undefined : selectedCreatedAt,
        })
      );
    }
    fetchData();
  }, [
    dispatch,
    sort,
    orden,
    estatusCotizacion,
    creatorSelected,
    vendorCotizacion,
    shippingMethod,
    tabSelected,
    createdAt,
    debouncedSearchTerm,
    selectedCreatedAt,
    pagination.currentPage,
  ]);

  function handleChangeStates(
    value,
    setState,
    field,
    useStringify = true,
    resetTab = false
  ) {
    updateHistory(
      {
        [field]: value,
      },
      useStringify
    );
    resetPagination();
    setState(value);
    if (resetTab) {
      setTabSelected(0);
      updateHistory(
        {
          tab_selected: 0,
        },
        false
      );
    }
  }

  function resetPagination() {
    dispatch(resetPage());
  }

  function handleSelectAll(values = []) {
    setSelectedItems(values);
  }

  function handleSelect(selected, val) {
    if (selected) {
      setSelectedItems((state) => [...new Set(state.concat(val))]);
    } else {
      setSelectedItems(selectedItems.filter((sel) => val !== sel));
    }
  }

  return (
    <Layer title="Cotizaciones" forbidden={forbidden} scrollToTop>
      <Page
        fullWidth
        title="Cotizaciones"
        primaryAction={
          !isForbiddenCreate
            ? {
                content: "Crear cotización",
                url: "/cliente/cotizaciones/agregar",
              }
            : undefined
        }
      >
        <CotizacionesContainer
          selectedItems={selectedItems}
          handleSelect={handleSelect}
        >
          {({ isLoading, pagination, cotizaciones, cotizacionesColumns }) => {
            return (
              <Card>
                <Tabs
                  tabs={tabs}
                  selected={tabSelected}
                  onSelect={(value) =>
                    handleChangeStates(
                      value,
                      setTabSelected,
                      "tab_selected",
                      false
                    )
                  }
                >
                  <CotizacionesList
                    useTagged={false}
                    allowClientFilter={false}
                    selectedItems={selectedItems}
                    handleSelectAll={handleSelectAll}
                    cotizacionesColumns={cotizacionesColumns}
                    cotizaciones={cotizaciones}
                    estatusCotizacion={estatusCotizacion}
                    creatorSelected={creatorSelected}
                    orden={orden}
                    orderSelected={sort}
                    pagination={pagination}
                    queryValue={decodeURIComponent(queryValue)}
                    vendors={vendors}
                    envios={envios}
                    vendorCotizacion={vendorCotizacion}
                    shippingMethod={shippingMethod}
                    createdAt={createdAt}
                    selectedCreatedAt={{
                      start: new Date(selectedCreatedAt.start),
                      end: new Date(selectedCreatedAt.end),
                    }}
                    setSelectedCreatedAt={(value) =>
                      handleChangeStates(
                        value,
                        setSelectedCreatedAt,
                        "selected_dates"
                      )
                    }
                    handleDateCreatedRemove={() =>
                      handleChangeStates(
                        "todos",
                        setCreatedAt,
                        "created_at",
                        false
                      )
                    }
                    handleCreatedAtChange={(_, value) =>
                      handleChangeStates(
                        value,
                        setCreatedAt,
                        "created_at",
                        false
                      )
                    }
                    isLoading={isLoading}
                    handleDateRemove={() =>
                      handleChangeStates(
                        "todos",
                        setCreatedAt,
                        "created_at",
                        false
                      )
                    }
                    handleEstatusCotizacionChange={(value) =>
                      handleChangeStates(
                        value,
                        setEstatusCotizacion,
                        "status",
                        true,
                        true
                      )
                    }
                    handleEstatusCotizacionRemove={() =>
                      handleChangeStates(
                        [],
                        setEstatusCotizacion,
                        "status",
                        true,
                        true
                      )
                    }
                    handleVendorCotizacionChange={(value) =>
                      handleChangeStates(value, setVendorCotizacion, "vendors")
                    }
                    handleVendorCotizacionRemove={() =>
                      handleChangeStates([], setVendorCotizacion, "vendors")
                    }
                    handleShippingMethodChange={(value) =>
                      handleChangeStates(value, setShippingMethod, "shipping")
                    }
                    handleShippingMethodRemove={() =>
                      handleChangeStates([], setShippingMethod, "shipping")
                    }
                    handleCreatorSelectedChange={(value) =>
                      handleChangeStates(
                        value,
                        setCreatorSelected,
                        "created_by"
                      )
                    }
                    handleCreatorSelectedRemove={() =>
                      handleChangeStates([], setCreatorSelected, "created_by")
                    }
                    handleFiltersQueryChange={(value) =>
                      handleChangeStates(
                        value ? encodeURIComponent(value) : value,
                        setQueryValue,
                        "query",
                        false
                      )
                    }
                    handleOrdenChange={(value) =>
                      handleChangeStates(value, setOrder, "order")
                    }
                    handleOrderSelectedChange={(value) =>
                      handleChangeStates(value, setSort, "sort")
                    }
                    handleOrderSelectedRemove={() =>
                      handleChangeStates(["folio"], setSort, "sort")
                    }
                    handleQueryValueRemove={() =>
                      handleChangeStates("", setQueryValue, "query", false)
                    }
                  />
                </Tabs>
              </Card>
            );
          }}
        </CotizacionesContainer>
      </Page>
    </Layer>
  );
}
