import {
  Button,
  ButtonGroup,
  Button as ButtonPolaris,
  Card,
  Checkbox,
  ChoiceList,
  EmptyState,
  Layout,
  Page,
} from "@shopify/polaris";
import {
  AddMajor,
  ChevronDownMinor,
  ChevronUpMinor,
  DragHandleMinor,
  TickSmallMinor,
} from "@shopify/polaris-icons";
import { Table } from "antd";
import arrayMove from "array-move";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { fecthFilters } from "../../actions/FiltersActions";
import { toggleToast } from "../../actions/InteractiveActions";
import { EditFiltroModal, FiltroMenuSkeleton, Layer } from "../../components";
import useToggle from "../../hooks/useToggle";
import { isLoadingFilters } from "../../reducers";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { Colors } from "../../styles";
import "./NavegationStyles.css";

const DragHandle = sortableHandle(() => (
  <DragHandleMinor
    style={{
      cursor: "grab",
      fill: "#5c6ac4",
      inlineSize: "20px",
      margin: "auto",
    }}
  />
));

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

export default function PreferenciasScreen() {
  const columns = [
    {
      title: "",
      width: 50,
      dataIndex: "_id",
      className: "sort-drag-visible",
      ellipsis: true,
      render: () => <DragHandle />,
    },
    {
      title: "Título",
      ellipsis: true,
      dataIndex: "title",
      width: 200,
      className: "whitespace-nowrap",
    },
    {
      title: "Opciones",
      dataIndex: "tags",
      className: "options-drag-visible",
      ellipsis: true,
      render: (tags) => tags?.join(", "),
    },
    {
      title: () => (
        <ButtonPolaris
          plain
          icon={
            <TickSmallMinor
              style={{
                fill: Colors.subduedButton,
                inlineSize: "20px",
                margin: "auto",
              }}
            />
          }
          disabled
          className="pl-0"
          loading={isSaved}
        >
          {isSaved ? "Guardando" : "Guardado"}
        </ButtonPolaris>
      ),
      width: 110,
      dataIndex: "index",
      className: "actions-drag-visible",
      render: (index, data) => (
        <div className="flex justify-end">
          <ButtonGroup className="filter-group" segmented>
            <ButtonPolaris size="slim" onClick={() => onHandleEdit(data)}>
              Editar
            </ButtonPolaris>
            <ButtonPolaris
              size="slim"
              onClick={() => handleRemoveFilterById(data)}
            >
              Eliminar
            </ButtonPolaris>
          </ButtonGroup>
        </div>
      ),
    },
  ];
  const dispatch = useDispatch();
  const navigate = useHistory();
  const isLoading = useSelector(isLoadingFilters);
  const [dataSource, setDataSource] = useState([]);
  const [isExpanable, setExpanable] = useState(false);
  const [isSaved, setSaveStatus] = useState(false);
  const [currentIdFilter, setCurrentIdFilter] = useState(null);
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState([]);
  const [isOpenAddFilter, setOpenAddFilter] = useState(false);
  const [isOpen, toggleOpen] = useToggle();
  const [collapseOnEnter, setCollapseOnEnter] = useState(false);
  const [sortByQuantity, setSortByQuantity] = useState(false);
  const [suggestedTags, setSuggestedTags] = useState([]);
  const [optionsFilterSelected, setOptionsFilterSelected] = useState([]);
  const [articleOpts, setArticleOpts] = useState([]);
  const [articleOptsSelected, setArticleOptsSelected] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const getFiltersData = useCallback(() => {
    dispatch(fecthFilters()).then(
      ({
        filters,
        collapseOnEnter = false,
        sortByQuantity = false,
        optionsFilters,
        articleOptions,
      }) => {
        for (const key in optionsFilters) {
          if (optionsFilters[key]) {
            setOptionsFilterSelected((prev) => [...prev, key]);
          }
        }

        setArticleOptsSelected(
          articleOptions
            .filter((item) => item.active)
            .map((item) => item.id._id)
        );

        setArticleOpts(articleOptions);
        setCollapseOnEnter(collapseOnEnter);
        setSortByQuantity(sortByQuantity);
        setDataSource(filters);
      }
    );
  }, [dispatch]);

  useEffect(() => {
    getFiltersData();
  }, [getFiltersData]);

  useEffect(() => {
    fetchFiltersArticleTags();
  }, []);

  const onHandleEdit = (data) => {
    toggleOpen();
    setTitle(data.title);
    setCurrentIdFilter(data._id);
    setTags(data.tags || []);
  };

  const handleOnClose = () => {
    toggleOpen();
    setCurrentIdFilter(null);
    setTitle("");
    setTags([]);
    getFiltersData();
    if (isOpenAddFilter) setOpenAddFilter(false);
  };

  const handleCancel = () => {
    toggleOpen();
    setTitle("");
    setTags([]);
  };

  const fetchFiltersArticleTags = () => {
    ApiServiceComercios.getTagsArticleFilters().then(
      ({ articlesTags, filterTags }) => {
        setSuggestedTags([...new Set(articlesTags.concat(filterTags))]);
      }
    );
  };

  const handleError = (err) => {
    dispatch(
      toggleToast({ message: "Ops, ocurrió un error, intente más tarde." })
    );
  };

  const DraggableContainer = (props) => {
    return (
      <>
        <SortableContainer
          useDragHandle
          disableAutoscroll
          helperClass="row-dragging"
          onSortEnd={onSortEnd}
          {...props}
        />
      </>
    );
  };
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      setSaveStatus(true);
      const newData = arrayMove(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      newData.forEach((data, index) => {
        newData["title"] = data.allTags[index].name;
      });
      newData.map((data, index) => (data["order"] = index));
      ApiServiceComercios.putShopFilterByList({ filters: newData })
        .then((res) => setSaveStatus(false))
        .catch((err) => handleError(err));
      setDataSource(newData);
    }
  };

  const handleSaveById = () => {
    const arr = [...dataSource];
    let currentFilter = arr.find((i) => i._id === currentIdFilter);

    const index = _.findIndex(arr, { _id: currentIdFilter });
    arr.splice(index, 1, {
      ...currentFilter,
      title: title,
      tags: tags,
    });

    currentFilter = arr.find((i) => i._id === currentIdFilter);

    setDataSource(arr);

    ApiServiceComercios.putShopFilterById(currentIdFilter, currentFilter)
      .then(() => {
        dispatch(toggleToast({ message: "Filtro actualizado correctamente" }));
        handleOnClose();
      })
      .catch((err) => handleError(err));
  };

  const handleAddFilter = () => {
    toggleOpen();
    setOpenAddFilter(true);
  };

  const handleSetFilter = () => {
    ApiServiceComercios.setNewFilter({
      title,
      tags: tags,
      order: dataSource.length,
    })
      .then(() => {
        handleOnClose();
        getFiltersData();
        dispatch(toggleToast({ message: "Filtro agregado correctamente" }));
      })
      .catch((err) => handleError(err));
  };

  const handleRemoveFilterById = (data) => {
    ApiServiceComercios.removeFilterById(data._id)
      .then(() => {
        dispatch(toggleToast({ message: "Correctamente eliminado" }));
        return getFiltersData();
      })
      .catch((err) => handleError(err));
  };

  const saveChanges = () => {
    setIsSaving(true);
    let optionsFilters = {
      useBrand: optionsFilterSelected.includes("useBrand"),
      usePrice: optionsFilterSelected.includes("usePrice"),
      useProductType: optionsFilterSelected.includes("useProductType"),
    };

    let articleOptions = articleOpts
      .map((item) => {
        item.active = articleOptsSelected.includes(item.id._id);
        return item;
      })
      .map((i) => ({ ...i, id: i.id._id }));

    ApiServiceComercios.actualizarFiltroPreferencias({
      collapseOnEnter,
      optionsFilters,
      articleOptions,
      sortByQuantity,
    })
      .then(({ message }) => {
        dispatch(toggleToast({ message }));
        setCollapseOnEnter(collapseOnEnter);
        setSortByQuantity(sortByQuantity);
      })
      .finally(() => setIsSaving(false));
  };

  const emptyStateMarkup =
    !isLoading && dataSource.length === 0 ? (
      <EmptyState
        heading={`¡Oops! aún no hay filtros aún`}
        action={{
          content: "Intenta crear un filtro nuevo",
          onAction: handleAddFilter,
        }}
        image="/emptystate-files.png"
      />
    ) : undefined;

  if (isLoading) {
    return (
      <Layer title="Filtros">
        <FiltroMenuSkeleton />
      </Layer>
    );
  }

  return (
    <Layer title="Filtros">
      <Page
        title="Filtros"
        separator
        fullWidth
        breadcrumbs={[
          { content: "Navegación", url: "/admin/tienda_online/navegacion" },
        ]}
      >
        <Layout>
          <Layout.AnnotatedSection
            title="Filtros"
            description="Los menús ayudan a tus clientes a navegar en tu tienda en línea de mayoreo. Estos filtros están predeterminados en los valores de tus productos."
          >
            <Card title="Filtros">
              <Card.Section title="PRODUCTOS">
                <ChoiceList
                  allowMultiple
                  choices={[
                    { label: "Tipo de producto", value: "useProductType" },
                    { label: "Marca", value: "useBrand" },
                    { label: "Precio", value: "usePrice" },
                  ]}
                  selected={optionsFilterSelected}
                  onChange={setOptionsFilterSelected}
                />
              </Card.Section>

              <Card.Section title="OPCIONES DE PRODUCTOS">
                <ChoiceList
                  allowMultiple
                  choices={articleOpts.map(({ id }) => ({
                    label: id.name,
                    value: id._id,
                  }))}
                  selected={articleOptsSelected}
                  onChange={setArticleOptsSelected}
                />
              </Card.Section>

              {/* <Card.Section title="Hola mundo"></Card.Section> */}
            </Card>
          </Layout.AnnotatedSection>

          <Layout.AnnotatedSection
            title="Filtros personalizados"
            description="Puedes crear filtros para agrupar productos basados en sus etiquetas."
          >
            <Card>
              <div className="flex navigation-table">
                <Table
                  columns={columns}
                  pagination={false}
                  dataSource={dataSource}
                  components={
                    !isExpanable && {
                      body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                      },
                    }
                  }
                  locale={{ emptyText: emptyStateMarkup }}
                  expandable={{
                    expandIcon: ({ expanded, onExpand, record }) =>
                      expanded ? (
                        <ButtonPolaris
                          plain
                          icon={ChevronUpMinor}
                          onClick={(e) => onExpand(record, e)}
                        />
                      ) : (
                        <ButtonPolaris
                          plain
                          icon={ChevronDownMinor}
                          onClick={(e) => onExpand(record, e)}
                        />
                      ),
                    expandedRowRender: (data) => {
                      return data.dragSubTags();
                    },
                    onExpandedRowsChange: () =>
                      setExpanable((prevState) => !prevState),
                  }}
                  footer={() => (
                    <div className="flex flex-row items-center bg-white p-8">
                      <div
                        className="px-3 cursor-pointer"
                        onClick={handleAddFilter}
                      >
                        <AddMajor
                          style={{
                            fill: Colors.linkButton,
                            inlineSize: "20px",
                            margin: "auto",
                          }}
                        />
                      </div>
                      <div className="px-2 self-center">
                        <ButtonPolaris plain onClick={handleAddFilter}>
                          Agregar una opción al filtro
                        </ButtonPolaris>
                      </div>
                    </div>
                  )}
                />
              </div>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title="Visualización"
            description="Personaliza la vista y orden de los filtros."
          >
            <Card sectioned>
              <div class="flex flex-col justify-center">
                <Checkbox
                  label="Colapsar al entrar"
                  checked={collapseOnEnter}
                  onChange={setCollapseOnEnter}
                />
                <Checkbox
                  label="Ordenar por el recuento de productos del filtros"
                  checked={sortByQuantity}
                  onChange={setSortByQuantity}
                />
              </div>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>

        <div className="flex justify-end mt-7">
          <ButtonGroup>
            <Button onClick={() => navigate.goBack()} disabled={isSaving}>
              Descartar
            </Button>
            <Button primary onClick={saveChanges} loading={isSaving}>
              Guardar
            </Button>
          </ButtonGroup>
        </div>
      </Page>
      {isOpen && (
        <EditFiltroModal
          handleOnClose={handleCancel}
          handleSaveById={handleSaveById}
          handleSetFilter={handleSetFilter}
          isOpen={isOpen}
          isOpenAddFilter={isOpenAddFilter}
          setTags={setTags}
          setTitle={setTitle}
          tags={tags}
          title={title}
          suggestions={suggestedTags}
        />
      )}
    </Layer>
  );
}
