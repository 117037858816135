import React, { useState } from "react";
import { Card, FormLayout, Modal, TextField } from "@shopify/polaris";

import { useDispatch, useSelector } from "react-redux";
import { getComercioData } from "../../reducers";
import { EmailEx } from "../../utils/RegEx";
import ApiServiceReportes from "../../services/ApiServiceReportes";
import { toggleToast } from "../../actions/InteractiveActions";

export const SendEmailModal = ({ isActive, onClose }) => {
  const { email } = useSelector(getComercioData)
  const dispatch = useDispatch();

  const [isSending, setIsSending] = useState(false);
  const [emailStructure, setEmailStructure] = useState({
    subject: "",
    to: email,
    message: "",
  });
  const [fieldsError, setFieldsError] = useState({ email: "" });

  const handleEmailOnBlur = () => {
    if (!EmailEx.test(emailStructure.to)) {
      setFieldsError((state) => ({
        ...state,
        email: "Ingresa una dirección de correo electrónico válida",
      }));
      return false;
    } else {
      setFieldsError((state) => ({ ...state, email: "" }));
      return true;
    }
  }

  const handleSendEmail = () => {
    if (handleEmailOnBlur()) {
      setIsSending(true);
      ApiServiceReportes.contactMerchant(emailStructure)
        .then(({ message }) => {
          dispatch(toggleToast({ message }));
        })
        .finally(() => {
          setIsSending(false);
          onClose();
        });
    }
  }

  const handleChangeText = (value, key) => {
    setEmailStructure((s) => ({ ...s, [key]: value }));
  }

  return (
    <Modal
      title="Contactar"
      open={isActive}
      onClose={onClose}
      primaryAction={{
        content: "Enviar",
        loading: isSending,
        onAction: handleSendEmail,
        disabled:
          fieldsError.email ||
          !emailStructure.subject ||
          !emailStructure.to ||
          !emailStructure.message,
      }}
      secondaryActions={[
        {
          content: "Cancelar",
          onAction: onClose,
          disabled: isSending,
        },
      ]}
    >
      <Modal.Section>
        <Card.Section>
          <FormLayout>
            <TextField
              label="Asunto"
              autoComplete="off"
              value={emailStructure.subject}
              onChange={(value) => handleChangeText(value, "subject")}
            />
            <TextField
              label="Mensaje"
              multiline={8}
              autoComplete="off"
              value={emailStructure.message}
              onChange={(value) => handleChangeText(value, "message")}
            />
          </FormLayout>
        </Card.Section>
      </Modal.Section>
    </Modal>
  );
};
