import ApiServiceComercios from "../services/ApiServiceComercios";
import NormalizeById from "../utils/NormalizedById";

export const FILTERS_BEGIN = "FILTERS_BEGIN";
export const ADD_FILTERS = "ADD_FILTERS";
export const FILTERS_FAILED = "FILTERS_FAILED";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const RESET_PAGE = "RESET_PAGE";

export function fecthFilters(params) {
  return async (dispatch, getState) => {
    dispatch(clearFilters());
    dispatch(filtersBegin());
    return ApiServiceComercios.getFilters({
      ...params,
    })
      .then((result) => {
        let data = NormalizeById(result.filters, "_id");
        data["total"] = result.total;
        data["allIdsDB"] = result.allIds;
        dispatch(addFilters(data));
        return result;
      })
      .catch((err) => {
        dispatch(filtersFailed());
      });
  };
}

export const filtersBegin = () => ({
  type: FILTERS_BEGIN,
});

export const addFilters = (data) => ({
  type: ADD_FILTERS,
  payload: { data },
});

export const filtersFailed = () => ({
  type: FILTERS_FAILED,
});

export const clearFilters = () => ({
  type: CLEAR_FILTERS,
});
