import React, { useState, useCallback } from "react";
import { Stack, RadioButton, DatePicker } from "@shopify/polaris";
import moment from "moment";

export default function ChoiceDate({
  value,
  handleChange,
  selectedDates,
  setSelectedDates,
  past,
}) {
  const [{ month, year }, setDate] = useState({
    month: moment().month(),
    year: moment().year(),
  });

  function setDates(val, label) {
    let start = new Date();
    let end = new Date();
    if (label === "Personalizada") {
      setSelectedDates(val, label);
    } else {
      switch (label) {
        case "Hoy": {
          start = new Date();
          break;
        }
        case "Últimos 7 días": {
          start = new Date(start.setDate(start.getDate() - 7));
          break;
        }
        case "Próximos 7 días": {
          end = new Date(end.setDate(end.getDate() + 7));
          break;
        }
        case "Últimos 30 días": {
          start = new Date(start.setMonth(start.getMonth() - 1));
          break;
        }
        case "Próximos 30 días": {
          end = new Date(end.setMonth(end.getMonth() + 1));
          break;
        }
        case "Últimos 90 días": {
          start = new Date(start.setMonth(start.getMonth() - 3));
          break;
        }
        case "Próximos 90 días": {
          end = new Date(end.setMonth(end.getMonth() + 3));
          break;
        }
        case "Últimos 12 meses": {
          start = new Date(start.setFullYear(start.getFullYear() - 1));
          break;
        }
        case "Atrasados": {
          start = new Date(start.setFullYear(start.getFullYear() - 50));
          end = new Date(start.setDate(start.getDate() - 1));
          break;
        }
        default:
          start = new Date();
          break;
      }
      setSelectedDates({ start, end }, label);
    }
  }

  function onChangeChoice(cb, val, label) {
    cb(val[0], val[1]);
    setDates(val, label);
  }

  const handleChangeMonth = useCallback(
    (month, year) => setDate({ month, year }),
    []
  );

  if (past) {
    return (
      <Stack vertical spacing="none">
        <RadioButton
          label="Todos"
          checked={value === "todos"}
          id="todos"
          name="todos"
          onChange={(_checked, newValue) =>
            onChangeChoice(handleChange, [_checked, newValue], "todos")
          }
        />
        <RadioButton
          label="Hoy"
          checked={value === "Hoy"}
          id="Hoy"
          name="Hoy"
          onChange={(_checked, newValue) =>
            onChangeChoice(handleChange, [_checked, newValue], "Hoy")
          }
        />
        <RadioButton
          label="Últimos 7 días"
          checked={value === "Últimos 7 días"}
          id="Últimos 7 días"
          name="Últimos 7 días"
          onChange={(_checked, newValue) =>
            onChangeChoice(handleChange, [_checked, newValue], "Últimos 7 días")
          }
        />
        <RadioButton
          label="Últimos 30 días"
          checked={value === "Últimos 30 días"}
          id="Últimos 30 días"
          name="Últimos 30 días"
          onChange={(_checked, newValue) =>
            onChangeChoice(
              handleChange,
              [_checked, newValue],
              "Últimos 30 días"
            )
          }
        />
        <RadioButton
          label="Últimos 90 días"
          checked={value === "Últimos 90 días"}
          id="Últimos 90 días"
          name="Últimos 90 días"
          onChange={(_checked, newValue) =>
            onChangeChoice(
              handleChange,
              [_checked, newValue],
              "Últimos 90 días"
            )
          }
        />
        <RadioButton
          label="Últimos 12 meses"
          checked={value === "Últimos 12 meses"}
          id="Últimos 12 meses"
          name="Últimos 12 meses"
          onChange={(_checked, newValue) =>
            onChangeChoice(
              handleChange,
              [_checked, newValue],
              "Últimos 12 meses"
            )
          }
        />
        <RadioButton
          label="Personalizada"
          checked={value === "Personalizada"}
          id="Personalizada"
          name="Personalizada"
          onChange={handleChange}
        />{" "}
        {value === "Personalizada" && (
          <DatePicker
            disableDatesAfter={new Date()}
            allowRange
            weekStartsOn
            month={month}
            year={year}
            onChange={(values) => setDates(values, "Personalizada")}
            onMonthChange={handleChangeMonth}
            selected={selectedDates}
          />
        )}
      </Stack>
    );
  } else {
    return (
      <Stack vertical spacing="none">
        <RadioButton
          label="Todos"
          checked={value === "todos"}
          id="todos"
          name="todos"
          onChange={(_checked, newValue) =>
            onChangeChoice(handleChange, [_checked, newValue], "todos")
          }
        />
        <RadioButton
          label="Atrasados"
          checked={value === "Atrasados"}
          id="Atrasados"
          name="Atrasados"
          onChange={(_checked, newValue) =>
            onChangeChoice(handleChange, [_checked, newValue], "Atrasados")
          }
        />
        <RadioButton
          label="Hoy"
          checked={value === "Hoy"}
          id="Hoy"
          name="Hoy"
          onChange={(_checked, newValue) =>
            onChangeChoice(handleChange, [_checked, newValue], "Hoy")
          }
        />
        <RadioButton
          label="Próximos 7 días"
          checked={value === "Próximos 7 días"}
          id="Próximos 7 días"
          name="Próximos 7 días"
          onChange={(_checked, newValue) =>
            onChangeChoice(
              handleChange,
              [_checked, newValue],
              "Próximos 7 días"
            )
          }
        />
        <RadioButton
          label="Próximos 30 días"
          checked={value === "Próximos 30 días"}
          id="Próximos 30 días"
          name="Próximos 30 días"
          onChange={(_checked, newValue) =>
            onChangeChoice(
              handleChange,
              [_checked, newValue],
              "Próximos 30 días"
            )
          }
        />
        <RadioButton
          label="Próximos 90 días"
          checked={value === "Próximos 90 días"}
          id="Próximos 90 días"
          name="Próximos 90 días"
          onChange={(_checked, newValue) =>
            onChangeChoice(
              handleChange,
              [_checked, newValue],
              "Próximos 90 días"
            )
          }
        />
        <RadioButton
          label="Personalizada"
          checked={value === "Personalizada"}
          id="Personalizada"
          name="Personalizada"
          onChange={handleChange}
        />{" "}
        {value === "Personalizada" && (
          <DatePicker
            disableDatesBefore={new Date()}
            allowRange
            weekStartsOn
            month={month}
            year={year}
            onChange={(values) => setDates(values, "Personalizada")}
            onMonthChange={handleChangeMonth}
            selected={selectedDates}
          />
        )}
      </Stack>
    );
  }
}
