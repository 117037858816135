import { PDFDownloadLink } from "@react-pdf/renderer";
import {
  ActionList,
  Badge,
  Button,
  Card,
  EmptyState,
  Layout,
  Link,
  Page,
  Popover,
  Spinner,
  TextStyle,
} from "@shopify/polaris";
import { MobileHorizontalDotsMajor } from "@shopify/polaris-icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  setIsLoadingProgressBar,
  toggleToast,
} from "../../actions/InteractiveActions";
import {
  ApproveCard,
  ArticuloItem,
  ComercioDetalleSkeleton,
  CustomBadge,
  DeleteModal,
  HelpFooter,
  Layer,
  SeguimientoModal,
  TagsAutocomplete,
  Timeline,
} from "../../components";
import PedidoPDF from "../../components/common/PedidoPDF";
import useGetCurrencies from "../../hooks/useGetCurrencies";
import useToggle from "../../hooks/useToggle";
import {
  getComercioData,
  getNombreComercio,
  getPaginationPedidos,
  getUserInfo,
} from "../../reducers";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { extractInitials } from "../../utils/stringModifiers";
import "./PedidosDetalleScreenStyles.css";
import iconPending from "./pending.svg";
import iconSupplied from "./supplied.svg";

export default function PedidosClienteDetalleScreen({ forbidden }) {
  const router = useHistory();
  const usuario = useSelector(getUserInfo);
  const [pedido, setPedido] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddingTag, setIsAddingTag] = useState(false);
  const pageState = useSelector((state) => state.Pedidos.allIdsDB);
  const pagination = useSelector(getPaginationPedidos);
  const [showHistorial, setShowHistorial] = useState(true);
  const [activePopover, togglePopover] = useToggle();
  const [activeAddShipment, toggleAddShipment] = useToggle();
  const [activeDeleteModal, toggleDeleteModal] = useToggle();
  const [imagesBase64, setImagesBase64] = useState([]);
  const { currencies } = useGetCurrencies();
  const [showTax, setShowTax] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();

  const [selectedOptions, setSelectedOptions] = useState([]);
  const comercio = useSelector(getNombreComercio);
  const comercioData = useSelector(getComercioData);
  const url_imagen = useSelector((state) => state.Auth.url_imagen);

  // Estados para el seguimiento de envios
  const [isRemovingTracking, setIsRemovingTracking] = useState(false);

  const getPedido = () => {
    dispatch(setIsLoadingProgressBar({ active: false }));
    ApiServiceComercios.pedidoDetalle(id)
      .then(({ ok, pedido }) => {
        if (ok) {
          setPedido({
            ...pedido,
            articulos:
              pedido.articuloFlete && pedido.articuloFlete.nombre
                ? pedido.articulos.filter(
                    (i) => i.articulo._id !== pedido.articuloFlete.id
                  )
                : pedido.articulos.filter(
                    (i) => i.articulo.nombre.indexOf("Flete") < 0
                  ),
          });
          setSelectedOptions(pedido.etiquetas);
        }
      })
      .catch((err) => {})
      .finally(() => setIsLoading(false));
    ApiServiceComercios.obtenerPreferenciasComercio().then(({ impuesto }) => {
      setShowTax(impuesto.mostrarPrecioConImpuesto);
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getPedido(), []);

  if (isLoading) {
    return (
      <Layer title={`Comercio`} forbidden={forbidden}>
        <ComercioDetalleSkeleton fullWidth />
      </Layer>
    );
  }

  if (pedido === null) {
    return (
      <Layer title={`Comercio`} forbidden={forbidden}>
        <EmptyState
          heading="Oops! Hubo un problema al intentar obtener este pedido"
          action={{
            content: "Volver a intentar",
            onAction: () => window.location.reload(),
          }}
          secondaryAction={{
            content: "Regresar a pedidos",
            onAction: () => router.goBack(),
          }}
          image="/Ad.svg"
        >
          <p>Intenta de nuevo más tarde</p>
        </EmptyState>
      </Layer>
    );
  }

  function setStatus(envio) {
    switch (envio) {
      case "Enviado":
        return "complete";
      case "Pendiente":
        return "incomplete";
      case "En ruta":
        return "partiallyComplete";
      default:
        return null;
    }
  }

  function selectTags(values) {
    setIsAddingTag(true);
    ApiServiceComercios.actualizarPedido({ etiquetas: values }, id)
      .then(({ ok, message }) => {
        if (ok) {
          dispatch(toggleToast({ message }));
          setSelectedOptions(values);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsAddingTag(false);
      });
  }

  function duplicarPedido() {
    router.push("/admin/pedidos/agregar", {
      articulos: pedido.articulos.map((i) => ({ ...i, ...i.articulo })),
      clienteId: pedido.cliente._id,
      almacenId: pedido.cliente.almacen.id,
      notas: pedido.notas,
    });
  }

  function previousPage() {
    const prevId = pageState[pageState.indexOf(id) - 1];
    router.push(`/admin/pedidos/${prevId}`);
  }

  function nextPage() {
    const nextId = pageState[pageState.indexOf(id) + 1];
    router.push(`/admin/pedidos/${nextId}`);
  }

  function readDirectionEnvio(direction) {
    return (
      <div className="flex flex-col">
        <TextStyle>
          {direction.direccion && direction.direccion + ", "}
          {direction.ciudad && direction.ciudad + ", "}
          {direction.estado && direction.estado + ", "}
          {direction.codigo_postal && "C.P. " + direction.codigo_postal}.
        </TextStyle>
        <TextStyle variation="subdued">{direction.telefono}</TextStyle>
      </div>
    );
  }

  function readDirectionFacturacion(direction) {
    if (
      pedido.direccionEnvioId &&
      pedido.direccionEnvioId === pedido.direccionFacturacionId
    ) {
      return <TextStyle>La misma dirección de envío</TextStyle>;
    } else {
      return (
        <div className="flex flex-col">
          <TextStyle>
            {direction.direccion && direction.direccion + ", "}
            {direction.ciudad && direction.ciudad + ", "}
            {direction.estado && direction.estado + ", "}
            {direction.codigo_postal && "C.P. " + direction.codigo_postal}.
          </TextStyle>
          <TextStyle variation="subdued">{direction.telefono}</TextStyle>
        </div>
      );
    }
  }

  const titleMetada = (
    <div className="flex items-center">
      <CustomBadge
        progress={setStatus(pedido.estatus.nombre)}
        status={pedido.estatus.nombre}
      />
      <Badge status={pedido.isInExsim ? "success" : "warning"}>
        {pedido.isInExsim ? "Sincronizado" : "No sincronizado"}
      </Badge>
    </div>
  );

  const subtitleMetada = (
    <>
      {moment(pedido.createdDate).format("D ")} de{" "}
      {moment(pedido.createdDate).format("MMMM YYYY, HH:mm")}
      {pedido.contact
        ? `, ${pedido.contact?.name} ${pedido.contact?.last_name}`
        : ""}
      {pedido.isFromMicrosip ? ", Microsip" : ", B2BGo"}
    </>
  );

  const clienteData = (
    <Card fullWidth>
      <Card.Section>
        <div className="flex justify-between items-center">
          <h3 className="Polaris-Subheading">CLIENTE</h3>
          <div className="relative" style={{ width: 40, top: -10 }}>
            <img
              className="rounded-full"
              src="/clienteAvatar.png"
              alt="Cliente"
            />
          </div>
        </div>
        <div className="flex flex-col">
          <Link external url={`/admin/clientes/${pedido.cliente._id}`}>
            {pedido.cliente.nombre}
          </Link>
          <TextStyle>{`${pedido.cliente.pedidos} ${
            pedido.cliente.pedidos > 1 ? "pedidos" : "pedido"
          }`}</TextStyle>
        </div>
      </Card.Section>
      <Card.Section title="Información de contacto">
        <div className="flex flex-col">
          <TextStyle variation="subdued">
            {pedido.cliente.nombre || ""}
          </TextStyle>
          <Link>{pedido.cliente.email}</Link>
        </div>
      </Card.Section>
      {!pedido.direccionEnvioId ? (
        <Card.Section title="Dirección">
          <div className="flex flex-col">
            <TextStyle variation="subdued">
              No se suministró la dirección de envío
            </TextStyle>
          </div>
        </Card.Section>
      ) : (
        <Card.Section title="Dirección de envío">
          {pedido.direccionEnvioId &&
          pedido.cliente.direcciones.some(
            (i) => i.consignatario_id === pedido.direccionEnvioId
          ) ? (
            readDirectionEnvio(
              pedido.cliente.direcciones.find(
                (i) => i.consignatario_id === pedido.direccionEnvioId
              )
            )
          ) : (
            <TextStyle>Aún no hay una dirección de envío asignada</TextStyle>
          )}
        </Card.Section>
      )}
      {!pedido.direccionFacturacionId ? (
        <Card.Section title="Dirección">
          <div className="flex flex-col">
            <TextStyle variation="subdued">
              No se suministró la dirección de facturación
            </TextStyle>
          </div>
        </Card.Section>
      ) : (
        <Card.Section title="Dirección de facturación">
          {pedido.direccionFacturacionId &&
          pedido.cliente.direcciones.some(
            (i) => i.consignatario_id === pedido.direccionFacturacionId
          ) ? (
            readDirectionFacturacion(
              pedido.cliente.direcciones.find(
                (i) => i.consignatario_id === pedido.direccionFacturacionId
              )
            )
          ) : (
            <TextStyle>
              Aún no hay una dirección de facturación asignada
            </TextStyle>
          )}
        </Card.Section>
      )}
      {pedido.moneda ? (
        <Card.Section title="Moneda">
          <TextStyle>
            {pedido.moneda.nombre}
            {" ("}
            {pedido.moneda.claveFiscal}
            {")"}
          </TextStyle>
        </Card.Section>
      ) : null}
    </Card>
  );

  const data = { ...pedido, comercio, comercioData, url_imagen };

  const deleteTracking = () => {
    let orderRomoved = {
      tracking: {
        status: "pending",
      },
    };

    setIsRemovingTracking(true);
    ApiServiceComercios.actualizarPedido(orderRomoved, pedido._id)
      .then((res) => {
        dispatch(toggleToast({ message: res.message }));
        toggleDeleteModal();
        getPedido();
      })
      .finally(() => setIsRemovingTracking(false));
  };

  const trackingContainer = (
    <div className="flex justify-between pb-4">
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-1">
          <TextStyle variation="subdued">Sucursal</TextStyle>
          <TextStyle>{pedido?.cliente?.almacen?.nombre || "--"}</TextStyle>
        </div>

        <div className="flex flex-col gap-1">
          <TextStyle variation="subdued">
            {pedido?.tracking?.id?.name}
          </TextStyle>

          <div className="flex">
            <Link
              url={
                pedido?.tracking && pedido?.tracking?.url
                  ? `${pedido?.tracking?.url}${
                      pedido?.tracking?.url.charAt(
                        pedido?.tracking?.url.length - 1
                      ) === "/"
                        ? ""
                        : "/"
                    }${pedido?.tracking?.key}`
                  : ""
              }
            >
              {pedido?.tracking?.key}
            </Link>
          </div>
        </div>
      </div>

      <div className="relative -top-16">
        <Popover
          activator={
            <Button
              icon={MobileHorizontalDotsMajor}
              plain
              onClick={togglePopover}
            />
          }
          active={activePopover}
          onClose={activePopover}
        >
          <ActionList
            onActionAnyItem={togglePopover}
            items={[
              {
                content: "Editar seguimiento",
                onAction: toggleAddShipment,
              },
              {
                content: "Eliminar envío",
                onAction: toggleDeleteModal,
                destructive: true,
              },
            ]}
          />
        </Popover>

        {activeDeleteModal && (
          <DeleteModal
            active={activeDeleteModal}
            title="Eliminar envío"
            body="Cuando eliminas el envío del pedido, puedes agregar un nuevo número de guía para el pedido. Ponte en contacto con tu proveedor logístico para cancelar el servicio."
            handleChange={toggleDeleteModal}
            handleDelete={deleteTracking}
            isLoading={isRemovingTracking}
          />
        )}
      </div>
    </div>
  );

  function validateIcon() {
    return pedido?.estatus?.nombre === "Surtido" ? iconSupplied : iconPending;
  }

  function validateTitle() {
    return pedido?.estatus?.nombre || "";
  }

  const trackingStatus = (
    <div className="flex items-center gap-4">
      <div>
        <img src={validateIcon()} alt="iconStatus" width="32" />
      </div>
      <TextStyle variation="strong">{validateTitle()}</TextStyle>
    </div>
  );

  return (
    <Layer title={`Detalle pedido ${pedido.folio}`} forbidden={forbidden}>
      <Page
        fullWidth
        title={`${pedido.folio}`}
        titleMetadata={titleMetada}
        subtitle={subtitleMetada}
        breadcrumbs={[{ content: "Pedidos", onAction: () => router.goBack() }]}
        pagination={{
          hasPrevious:
            pageState.indexOf(id) !== 0 ||
            pagination.currentPage * pagination.sizePerPage !== 0,
          onPrevious: previousPage,
          nextTooltip: "Siguiente pedido",
          hasNext: pageState.indexOf(id) + 1 < pageState.length,
          onNext: nextPage,
          previousTooltip: "Pedido anterior",
        }}
        secondaryActions={[
          {
            content: "Duplicar",
            outline: true,
            onAction: duplicarPedido,
          },
          {
            content: (
              <div className="relative z-50">
                <PDFDownloadLink
                  document={<PedidoPDF data={data} />}
                  fileName={`${comercio} - Pedido ${pedido.folio}`}
                >
                  {({ loading }) =>
                    loading ? (
                      <div className="inline-flex mx-8">
                        <Spinner size="small" />
                      </div>
                    ) : (
                      <TextStyle>Descargar</TextStyle>
                    )
                  }
                </PDFDownloadLink>
              </div>
            ),
          },
        ]}
      >
        <Layout>
          <Layout.Section>
            <div className="selectClientSmall">{clienteData}</div>
            <Card title={trackingStatus}>
              <Card.Section>
                {pedido?.tracking?.status === "supplied" && trackingContainer}

                {pedido.articulos
                  .slice(
                    0,
                    showAll && pedido.articulos.length > 5
                      ? pedido.articulos.length
                      : 5
                  )
                  .map((articulo, index) => {
                    return (
                      <div
                        key={
                          articulo._id ||
                          "_" + Math.random().toString(36).substr(2, 9)
                        }
                      >
                        {articulo.articulo ? (
                          <ArticuloItem
                            disabled
                            articulo={{
                              ...articulo.articulo,
                              precioDeLista: articulo.precioDeLista,
                              totalPorcentajeDescuento:
                                articulo.totalPorcentajeDescuento,
                              totalPrecioConDescuento:
                                articulo.totalPrecioConDescuento,
                              notas: articulo.notas,
                            }}
                            showAsDetail
                            quantity={Number(articulo.cantidad)}
                            handleChange={() => {}}
                            onRemove={() => {}}
                            isLast={pedido.articulos.length - 1 === index}
                            currencies={currencies}
                            clientCurrency={pedido?.moneda}
                            showTax={showTax}
                          />
                        ) : null}
                      </div>
                    );
                  })}
                {pedido.articulos.length > 5 && (
                  <Card.Section>
                    <div className="flex justify-center">
                      <Button plain onClick={() => setShowAll((s) => !s)}>
                        {showAll
                          ? "Ver menos"
                          : `Ver todos los artículos (${pedido.articulos.length})`}
                      </Button>
                    </div>
                  </Card.Section>
                )}

                {(pedido?.tracking?.status === "pending" ||
                  !pedido?.tracking) && (
                  <div className="flex justify-end">
                    <Button primary onClick={toggleAddShipment}>
                      Agregar número de guía
                    </Button>
                  </div>
                )}

                {activeAddShipment && (
                  <SeguimientoModal
                    activatorModal={activeAddShipment}
                    closeModal={toggleAddShipment}
                    pedidoId={pedido?._id}
                    tracking={pedido?.tracking}
                    updateTracking={getPedido}
                  />
                )}
              </Card.Section>
            </Card>
            <ApproveCard
              subtotal={pedido.subtotalPedido}
              impuesto={pedido.impuestoSubtotalPedido}
              envio={{
                cantidad: pedido?.envio?.precio,
                datos: { ...pedido.envio },
              }}
              cantidad={pedido.articulos.length}
              total={pedido.total}
              weight={pedido?.weight}
              showLimit={false}
              currency={pedido.moneda ? pedido.moneda : undefined}
            />

            <div className="my-8">
              <Timeline
                visible={showHistorial}
                onChange={setShowHistorial}
                initials={extractInitials(usuario.name, usuario.last_name)}
                parentMessageId={id}
                subtitle="Todos pueden ver los comentarios."
              />
            </div>
          </Layout.Section>
          <Layout.Section secondary>
            <div className="selectClientLarge">{clienteData}</div>
            <Card sectioned title="Notas">
              <TextStyle>{pedido.notas}</TextStyle>
            </Card>
            <Card sectioned title="Vendedor">
              <TextStyle>{pedido?.vendedor?.name}</TextStyle>
            </Card>
            <TagsAutocomplete
              title="Etiquetas"
              subdued
              selected={selectedOptions}
              onSelect={selectTags}
              outLoading={isAddingTag}
              module="Pedidos"
            />
          </Layout.Section>
        </Layout>
        <HelpFooter title="Pedidos" url="https://help.b2bgo.mx/pedidos" />
      </Page>
    </Layer>
  );
}
