import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, ChoiceList, Page, Tabs } from "@shopify/polaris";
import { CollectionsList, HelpFooter, Layer } from "../../components";
import { TabsCollectionsScreen as tabs } from "../../utils/Tabs";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import useDebounce from "../../services/useDebounce";
import useUrlSearch from "../../hooks/useUrlSearch";

const objSearch = ["query", "type"];
export default function CollectionsScreen({ forbidden }) {
  const [collections, setCollections] = useState([]);
  const { pathname } = useLocation();
  const { updateHistory, getSearch } = useUrlSearch({ objSearch, pathname });
  const [query, setQuery] = useState(
    decodeURIComponent(getSearch("query", "", true))
  );
  const [collectionsSelected, setCollectionsSelected] = useState(
    getSearch("type", [])
  );

  const [isLoading, setIsLoading] = useState(true);
  const [sortSelected, setSortSelected] = useState(["name"]);
  const [pagination, setPagination] = useState({
    limit: 20,
    skip: 0,
  });

  const queryDebounce = useDebounce(query, 900);

  useEffect(() => {
    setIsLoading(true);
    ApiServiceComercios.getCollections({
      query: decodeURIComponent(queryDebounce),
      type: JSON.stringify(collectionsSelected),
      sort: sortSelected[0],
      limit: pagination.limit,
      skip: pagination.skip,
    }).then(({ collections: data }) => {
      setIsLoading(false);
      setCollections(
        data.map((item) => {
          return {
            key: item._id,
            name: item.name,
            articles: item?.articles ?? 0,
            collectionTypes: item.type,
          };
        })
      );
    });
  }, [queryDebounce, collectionsSelected, sortSelected, pagination]);

  const filters = [
    {
      key: "collectionTypes",
      label: "Tipos de colección",
      shortcut: true,
      filter: (
        <ChoiceList
          title="collectionTypes"
          titleHidden
          allowMultiple
          choices={[
            {
              label: "Automática",
              value: "auto",
            },
            {
              label: "Manual",
              value: "manual",
            },
          ]}
          selected={collectionsSelected}
          onChange={(value) =>
            handleChangeStates(value, setCollectionsSelected, "type")
          }
        />
      ),
    },
    {
      key: "sort",
      label: "Ordenar",
      shortcut: true,
      filter: (
        <ChoiceList
          title="sort"
          titleHidden
          selected={sortSelected}
          onChange={setSortSelected}
          choices={[
            { label: "Nombre", value: "name" },
            { label: "Tipo de colección", value: "type" },
            { label: "Fecha de creación", value: "createdDate" },
          ]}
        />
      ),
    },
  ];

  function handleChangeStates(value, setState, field, useStringify = true) {
    updateHistory(
      {
        [field]: value,
      },
      useStringify
    );
    resetPagination();
    setState(value);
  }

  const resetPagination = () => {
    setPagination({ limit: 20, skip: 0 });
  };

  return (
    <Layer title="Colecciones" forbidden={forbidden}>
      <Page
        title="Colecciones"
        fullWidth
        primaryAction={{
          content: "Crear colección",
          url: "/admin/articulos/colecciones/agregar",
        }}
      >
        <Card>
          <Tabs tabs={tabs} selected={0}>
            <CollectionsList
              collections={collections}
              filters={filters}
              query={decodeURIComponent(query)}
              handleQuery={(value) =>
                handleChangeStates(
                  value ? encodeURIComponent(value) : value,
                  setQuery,
                  "query",
                  false
                )
              }
              collectionsSelected={collectionsSelected}
              clearCollectionTypes={() =>
                handleChangeStates([], setCollectionsSelected, "type")
              }
              isLoading={isLoading}
              pagination={pagination}
              handlePagination={setPagination}
            />
          </Tabs>
        </Card>
        <HelpFooter
          title="colecciones"
          url="https://help.b2bgo.mx/articulos/colecciones"
        />
      </Page>
    </Layer>
  );
}
