import React from "react";
import { Popover } from "@shopify/polaris";
import { NimblePicker } from "emoji-mart";
import data from "emoji-mart/data/google.json";
import { timelineTraslation } from "../../utils/timelineTraslation";

export default function TimelineEmoji({
  popoverActive,
  emojiActivator,
  togglePopoverActive,
  onEmojiClick,
}) {
  return (
    <Popover
      active={popoverActive}
      activator={emojiActivator}
      onClose={togglePopoverActive}
    >
      <NimblePicker
        onSelect={onEmojiClick}
        useButton={false}
        showSkinTones={false}
        emojiTooltip={false}
        showPreview={false}
        sheetSize={32}
        data={data}
        i18n={timelineTraslation}
        enableFrequentEmojiSort={false}
      />
    </Popover>
  );
}
