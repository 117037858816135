import { Card, Modal, Spinner, TextStyle } from "@shopify/polaris";
import { Elements } from "@stripe/react-stripe-js";
import { useEffect, useMemo, useState } from "react";
import ApiServiceComercios from "../../../services/ApiServiceComercios";
import CustomOrderForm from "./CustomOrderForm";
import StripeOrderCheckoutContainer from "./StripeOrderCheckoutContainer";
import { SuccessOrderPayment } from "./SuccessOrderPayment";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import { getOrderInfo } from "../../../reducers";

// JUST FOR ORDER PAYMENTS
export const StripeOrderPayment = ({
  activeModal,
  handleChange,
  totalAmountToPay,
  orderDetails,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [secretKey, setPrivateKey] = useState("");
  const [draftorderId, setDraftOrderId] = useState("");
  const [stripePromise, setStripePromise] = useState(null);
  const [processingPayment, setIsProcessing] = useState(false);
  const [hasSuccessPayment, setHasSuccessPayment] = useState(false);
  const { moneda } = useSelector(getOrderInfo);
  const customerCurrency = moneda ? moneda.claveFiscal : "MXN";

  const options = useMemo(() => {
    return {
      clientSecret: secretKey,
    };
  }, [secretKey]);

  useEffect(() => {
    setIsLoading(true);
    ApiServiceComercios.getPublicKey().then(({ publicKey }) => {
      const stripe = loadStripe(publicKey);
      setStripePromise(stripe);
    });
  }, [setStripePromise, setIsLoading]);

  useEffect(() => {
    const data = {
      amount: Math.abs(totalAmountToPay),
      currency: customerCurrency,
    };
    //crear pedido preeliminar antes de crear un intento de pago
    ApiServiceComercios.setDraftOrder(orderDetails).then((res) => {
      if (res?.ok) {
        setDraftOrderId(res?.id);
        ApiServiceComercios.setPaymentAttempt(data)
          .then(({ clientSecret }) => {
            setPrivateKey(clientSecret);
          })
          .finally(() => setIsLoading(false));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPrivateKey, setDraftOrderId]);

  const onClose = () => {
    handleChange();
  };

  return (
    <Modal
      title={
        hasSuccessPayment
          ? "Pago exitoso"
          : "Introducir tarjeta de crédito o débito"
      }
      open={activeModal}
      onClose={processingPayment ? () => {} : onClose}
    >
      <Modal.Section>
        {isLoading || !secretKey ? (
          <Card>
            <div className="p-16 flex flex-col text-center items-center">
              <Spinner size="large" />
              <div>
                <p className="font-bold text-3xl my-8">
                  Creando pedido preliminar
                </p>
                <TextStyle variant="bodyMd" as="p" fontWeight="regular">
                  Por favor espera mientras creamos un pedido preliminar,
                  deberás pagar el límite de crédito excedido.
                </TextStyle>
              </div>
            </div>
          </Card>
        ) : (
          <div className="p-8">
            {stripePromise && secretKey ? (
              <Elements stripe={stripePromise} options={options}>
                <StripeOrderCheckoutContainer
                  draftorderId={draftorderId}
                  setIsProcessing={setIsProcessing}
                  setHasSuccessPayment={setHasSuccessPayment}
                >
                  {({
                    handleSubmit,
                    hasSuccessResponse,
                    message,
                    confirmPaymentData,
                  }) => {
                    return (
                      <>
                        {hasSuccessResponse ? (
                          <SuccessOrderPayment
                            totalAmountToPay={totalAmountToPay}
                            confirmPaymentData={confirmPaymentData}
                            onCloseModal={handleChange}
                          />
                        ) : (
                          <CustomOrderForm
                            message={message}
                            processingPayment={processingPayment}
                            handleSubmit={handleSubmit}
                            totalAmountToPay={totalAmountToPay}
                            onCancel={handleChange}
                          />
                        )}
                      </>
                    );
                  }}
                </StripeOrderCheckoutContainer>
              </Elements>
            ) : null}
          </div>
        )}
      </Modal.Section>
    </Modal>
  );
};
