import React, { useEffect, useState } from "react";
import { Page, Layout, Card, Banner } from "@shopify/polaris";
import {
  Layer,
  ConfigButton,
  AnnotatedSectionSkeleton,
  HelpFooter,
} from "../../components";
import ApiServiceComercios from "../../services/ApiServiceComercios";

export default function CurrenciesScreen({ forbidden }) {
  const [isLoading, setIsLoading] = useState(true);
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    ApiServiceComercios.getCurrencies().then(({ monedas }) => {
      setCurrencies(monedas);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <AnnotatedSectionSkeleton />;
  }

  return (
    <Layer title="Monedas" forbidden={forbidden}>
      <Page
        title="Monedas"
        breadcrumbs={[
          { content: "Configuración", url: "/admin/configuracion" },
        ]}
      >
        <div className="mb-20">
          <Layout>
            <Layout.AnnotatedSection
              title="Monedas"
              description="Gestiona las monedas en la que se venden tus productos."
            >
              {currencies.map(({ id, nombre, claveFiscal, tipoCambio }) => {
                return (
                  <div key={id} className="mb-8">
                    <Card>
                      <ConfigButton
                        fullWidth
                        text={`${nombre} (${claveFiscal})`}
                        secondary={`Tipo de cambio : ${tipoCambio}`}
                        icon="CashDollarMajor"
                      />
                    </Card>
                  </div>
                );
              })}

              <Banner
                title="Actualizar automáticamente el tipo de cambio"
                status="info"
              >
                <p>
                  Deberás indicar en Microsip que si deseas que el tipo de
                  cambio de la moneda se actualice automáticamente todos los
                  días en el catálogo de monedas.
                </p>
              </Banner>
            </Layout.AnnotatedSection>
          </Layout>
        </div>

        <HelpFooter
          title="monedas"
          url="https://help.b2bgo.mx/configuracion/monedas"
        />
      </Page>
    </Layer>
  );
}
